import * as _ from "lodash";
import { isNumber } from "lodash";
import * as React from "react";
import InputAction, {
  ControlProps,
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  GroupBase,
  IndicatorSeparatorProps,
  MultiValueProps,
  StylesConfig,
  ValueContainerProps,
} from "react-select";
import { Collapse } from "reactstrap";
import { Translation } from "../../App/reducer";
import { Checkbox } from "../../Common";
import FormSearchSelect from "../../Common/components/FormSearchSelect";
import {
  ComplexSet,
  getCombinedIdCustomerId,
  getPartsFromCombinedIdCustomerId,
} from "../../Common/utils";
import * as gqltypes from "../../gqltypes";
import * as sentry from "../../sentry";
import { getSettings } from "../../settings";
import { ChangeSelected } from "./SchoolUnitTable";

const allYearsOptionValue = -1;

interface SelectValue {
  value: number;
  label: string;
  isFixed: boolean;
}

export interface ActionMeta {
  action: typeof InputAction;
  removedValue?: SelectValue;
  option?: SelectValue;
}

const selectStyles: StylesConfig = {
  container: (base: CSSObjectWithLabel) => base,
  valueContainer: (
    base: CSSObjectWithLabel,
    state: ValueContainerProps<unknown, boolean, GroupBase<unknown>>
  ) => (state.isDisabled ? { ...base, padding: undefined } : base),
  option: (base: CSSObjectWithLabel, state: any) => {
    const newStyle = { ...base };
    const { isFixed } = state.data;
    const { isFocused, value } = state;
    if (isFocused) {
      newStyle.textDecoration = "underline";
    }
    if (isFixed && value !== allYearsOptionValue) {
      newStyle.backgroundColor = "#BDBDBA";
      newStyle.color = "white";
    }
    return newStyle;
  },
  control: (
    base: CSSObjectWithLabel,
    state: ControlProps<unknown, boolean, GroupBase<unknown>>
  ) =>
    state.isDisabled
      ? {
          ...base,
          backgroundColor: "transparent",
          borderWidth: 0,
          minHeight: undefined,
        }
      : base,
  dropdownIndicator: (
    base: CSSObjectWithLabel,
    state: DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>
  ) => (state.isDisabled ? { ...base, display: "none" } : base),
  indicatorSeparator: (
    base: CSSObjectWithLabel,
    state: IndicatorSeparatorProps<unknown, boolean, GroupBase<unknown>>
  ) => (state.isDisabled ? { ...base, display: "none" } : base),
  multiValue: (base: CSSObjectWithLabel, state: MultiValueProps<any>) =>
    state.data.isFixed ? { ...base, backgroundColor: "gray" } : base,
  multiValueLabel: (base: CSSObjectWithLabel, state: MultiValueProps<any>) => {
    return state.data.isFixed
      ? {
          ...base,
          fontWeight: "bold",
          color: "white",
          paddingRight: 6,
        }
      : base;
  },
  multiValueRemove: (base: CSSObjectWithLabel, state: MultiValueProps<any>) => {
    return state.data.isFixed ? { ...base, display: "none" } : base;
  },
};

const yearToOption = (
  lockedStudentGroupYearFilter: number[] | undefined | null,
  year: number
): SelectValue => {
  return {
    value: year,
    label: schoolYearToLabel(year),
    isFixed: lockedStudentGroupYearFilter
      ? lockedStudentGroupYearFilter.some((lockedYear) => lockedYear === year)
      : false,
  };
};

const settings = getSettings();

interface Props {
  tr: Translation["tr"];
  schoolUnit: gqltypes.SchoolUnits;
  selectedLocked: { id: string; customerId: string }[] | undefined;
  selected: { id: string; customerId: string }[] | undefined;
  studentGroupYearFilters: { [sgid: string]: number[] | undefined | null };
  lockedStudentGroupYearFilter: { [sgid: string]: number[] | undefined | null };
  addSelectedStudentGroups: (change: ChangeSelected) => void;
  removeSelectedStudentGroups: (change: ChangeSelected) => void;
  changeSgYearFilter: (change: {
    sgid: string;
    customerId: string;
    filter: number[] | null;
  }) => void;
}

export const schoolYearToLabel = (year: number) => {
  return year === 0 ? settings.regionSettings.FSLetter : year.toString();
};

export const SchoolYears = (props: {
  tr: Translation["tr"];
  schoolYearFilter: number[];
}) => {
  return (
    <small className="text-info">
      ({props.tr("CcaSchoolYear")}{" "}
      {props.schoolYearFilter &&
        props.schoolYearFilter.map(schoolYearToLabel).join(", ")}
      )
    </small>
  );
};

const numberTest = (num: string) => /^\d+$/.test(num);

const schoolYearRange = (from: string, to: string, displayName: string) => {
  let fromNum = numberTest(from) ? parseInt(from, 10) : undefined;
  const toNum = numberTest(to) ? parseInt(to, 10) : undefined;

  if (toNum) {
    if (from.toUpperCase() === settings.regionSettings.FSLetter) {
      fromNum = 0;
    }

    if (!isNumber(fromNum) || !isNumber(toNum)) {
      return null;
    }

    if (fromNum >= toNum) {
      return null;
    }

    return _.range(fromNum, toNum + 1).map((num) => num);
  }

  sentry.captureMessage(
    `Failed to get schoolYearRange from suspected range, ${displayName}`
  );

  return null;
};

const precschoolLowerAndUpper =
  settings.regionSettings.FSLetter.toLowerCase() +
  settings.regionSettings.FSLetter.toUpperCase();
const schoolYearRegex = new RegExp(
  `([${precschoolLowerAndUpper}\\d]-\\d)[a-zA-Z]?$`
);

const getPotentialSchoolYearRange = (displayName: string) => {
  const match = displayName.match(schoolYearRegex);

  const multiYearParts = match ? match[1].split("-") : null;

  const range = multiYearParts
    ? schoolYearRange(
        multiYearParts[0],
        multiYearParts[1],
        match ? match[1] : ""
      )
    : null;
  return range;
};

interface State {
  open: boolean;
}

const noBorder = { borderBottom: 0 };

export class ClassCheckboxArray extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { open: false };
  }

  public render() {
    const { tr } = this.props;
    if (this.props.schoolUnit.classes.length === 0) {
      return (
        <React.Fragment>
          <tr>
            <td style={noBorder}>
              <Checkbox
                id={`${getCombinedIdCustomerId(this.props.schoolUnit)}_all`}
                label={tr(
                  "CcaSchoolUnitNoClasses",
                  this.props.schoolUnit.displayName
                )}
                disabled
                checked={false}
              />
            </td>
            <td style={noBorder} />
            <td style={noBorder} />
          </tr>
          <tr>
            <td colSpan={4} />
          </tr>
        </React.Fragment>
      );
    }

    const filteredSgs = new ComplexSet(
      getCombinedIdCustomerId,
      this.props.schoolUnit.classes
    );

    const selectedSgs = this.getSelectedSgs();
    const lockedSgs = this.getLockedSgs();

    const numFilteredSelected = this.props.selected
      ? this.props.selected.filter((s) => filteredSgs.has(s)).length
      : 0;
    const numFilteredLocked = this.props.selectedLocked
      ? this.props.selectedLocked.filter((s) => filteredSgs.has(s)).length
      : 0;

    const numFilteredSG = this.props.schoolUnit.classes.length;
    const allSelected =
      numFilteredSelected + numFilteredLocked === numFilteredSG;
    const allLocked = numFilteredLocked === numFilteredSG;

    const selectedAndLocked = numFilteredSelected + numFilteredLocked;

    const allYearsOptionLabel = tr("CcaAllSchoolYearsPlaceholder");
    const allYearsOption = {
      value: allYearsOptionValue,
      label: allYearsOptionLabel,
      isFixed: true,
    };

    return (
      <React.Fragment>
        <tr>
          <td style={noBorder}>
            <Checkbox
              id={`${getCombinedIdCustomerId(this.props.schoolUnit)}_all`}
              label={this.props.schoolUnit.displayName}
              disabled={numFilteredLocked === numFilteredSG}
              checked={allSelected}
              indeterminate={
                !allSelected && numFilteredSelected + numFilteredLocked !== 0
              }
              onChange={this.handleMultiChange}
            />
          </td>
          <td style={noBorder}>{numFilteredSG}</td>
          <td style={noBorder}>
            {selectedAndLocked !== 0
              ? `${selectedAndLocked}/${numFilteredSG}`
              : "-"}
          </td>
          <td
            style={noBorder}
            className="clickable"
            onClick={this.handleToggle}
          >
            <div className="ml-auto">
              <i
                className={`fa fa-chevron-${this.state.open ? "up" : "down"}`}
                id="collapsed-chevron"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={4}>
            <Collapse className="" isOpen={this.state.open}>
              <div className="row">
                {this.props.schoolUnit.classes.map((sg) => {
                  const combinedId = getCombinedIdCustomerId(sg);
                  const locked = lockedSgs.has(sg);

                  const checked = selectedSgs.has(sg) || locked;

                  const selectedYears =
                    this.props.studentGroupYearFilters[combinedId];
                  const lockedYears =
                    this.props.lockedStudentGroupYearFilter[combinedId] || [];
                  const yearFilter = _.orderBy(
                    _.concat(selectedYears || [], lockedYears)
                  );

                  const classYears =
                    !sg.schoolType ||
                    sg.schoolType === "GR" ||
                    sg.schoolType === "GRS"
                      ? getPotentialSchoolYearRange(sg.displayName)
                      : null;

                  const selectValue =
                    selectedYears !== null && yearFilter.length
                      ? yearFilter.map(
                          yearToOption.bind(
                            null,
                            this.props.lockedStudentGroupYearFilter[combinedId]
                          )
                        )
                      : [allYearsOption];

                  return (
                    <div key={combinedId} className="col-6 col-md-4 col-lg-3">
                      <Checkbox
                        id={combinedId}
                        label={`${sg.displayName} (${sg.memberCount})`}
                        disabled={locked}
                        checked={checked}
                        onChange={this.handleChange}
                      />
                      {checked && classYears && (
                        <div className="row">
                          <div className="col-12 pb-3">
                            <FormSearchSelect
                              aria-label={tr("viewAnswersUsageDescription")}
                              placeholder={allYearsOptionLabel}
                              isMulti
                              isDisabled={locked && yearFilter.length === 0}
                              isSearchable={false}
                              isClearable={false}
                              hideSelectedOptions={false}
                              noOptionsMessage={() =>
                                tr("CcaAllSchoolYearsChecked")
                              }
                              closeMenuOnSelect={false}
                              value={selectValue as any}
                              styles={selectStyles}
                              onChange={this.handleYearSelectChange.bind(
                                this,
                                combinedId
                              )}
                              options={
                                [allYearsOption].concat(
                                  classYears.map(
                                    yearToOption.bind(
                                      null,
                                      this.props.lockedStudentGroupYearFilter[
                                        combinedId
                                      ]
                                    )
                                  )
                                ) as any
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </Collapse>
          </td>
        </tr>
      </React.Fragment>
    );
  }

  private handleYearSelectChange = (
    sg: string,
    changed: SelectValue[] | undefined,
    actionMeta: ActionMeta
  ) => {
    const { action } = actionMeta;
    const { id, customerId } = getPartsFromCombinedIdCustomerId(sg);

    if (!changed) {
      return;
    }
    if (
      ((actionMeta.removedValue && actionMeta.removedValue.isFixed) ||
        (actionMeta.option && actionMeta.option.isFixed)) &&
      (action.valueOf() === "pop-value" ||
        action.valueOf() === "remove-value" ||
        action.valueOf() === "deselect-option")
    ) {
      return;
    }

    if (actionMeta.option && actionMeta.option.value === allYearsOptionValue) {
      this.props.changeSgYearFilter({
        sgid: id,
        customerId,
        filter: null,
      });
    } else if (Array.isArray(changed)) {
      this.props.changeSgYearFilter({
        sgid: id,
        customerId,
        filter: _.difference(
          changed.map((c) => c.value).filter((v) => v !== allYearsOptionValue),
          this.props.lockedStudentGroupYearFilter[sg] || []
        ),
      });
    }
  };

  private getSelectedSgs = () => {
    return new ComplexSet(getCombinedIdCustomerId, this.props.selected);
  };

  private getLockedSgs = () => {
    return new ComplexSet(getCombinedIdCustomerId, this.props.selectedLocked);
  };

  private handleToggle = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  private handleMultiChange = (id: string, checked: boolean) => {
    const su = this.props.schoolUnit;
    const suid = { id: su.id, customerId: su.customerId };
    const sgs = this.props.schoolUnit.classes;

    if (checked) {
      this.props.addSelectedStudentGroups({
        suid,
        sgs,
      });
    } else {
      this.props.removeSelectedStudentGroups({
        suid,
        sgs,
      });
    }
  };

  private handleChange = (combined: string, checked: boolean) => {
    const { id: sgid, customerId } = getPartsFromCombinedIdCustomerId(combined);
    const suid = this.props.schoolUnit.id;

    if (checked) {
      this.props.addSelectedStudentGroups({
        suid: { id: suid, customerId },
        sgs: [{ id: sgid, customerId }],
      });
    } else {
      this.props.removeSelectedStudentGroups({
        suid: { id: suid, customerId },
        sgs: [{ id: sgid, customerId }],
      });
    }
  };
}
