import gql from "graphql-tag";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useAdminContext, useTranslation } from "../../App/reducer";
import { Button, ISTContainer, Loading } from "../../Common";
import { GenericError } from "../../Common/components/GenericError";
import { MutationButton } from "../../Common/components/MutationButton";
import { useLocationState } from "../../Common/hooks";
import * as gqltypes from "../../gqltypes";

const publicationQuery = gql`
  query demoPublicationQuery($context: Context!) {
    organisation(context: $context) {
      id
      publications {
        id
        name
        form {
          id
          name
        }
        creator {
          id
          name
        }
        created
        sendDate
        validFrom
        validTo
        lastDryrun
        lastNotified
        sent
        stats {
          total
          groups
          sent
          answered
          unanswered
          undeliverableNotifications
        }
        recipients {
          groups {
            edges {
              id
              type
              displayName
              removed
              memberCount
            }
          }
        }
      }
    }
  }
`;

const createResponseMutation = gql`
  mutation createResponseMutation($publicationId: ID!, $count: Int!) {
    demoCreateResponses(
      input: { publicationId: $publicationId, count: $count }
    ) {
      code
    }
  }
`;

const setPublicationAsSent = gql`
  mutation setPublicationAsSent($publicationId: ID!) {
    demoMarkPublicationSent(id: $publicationId) {
      id
      sent
    }
  }
`;

export const DemoContainer = (props: {}) => {
  const [selectedPublicationId, setSelectedPublicationId] = useLocationState<
    string | null
  >("selectedPublicationId", null);
  const [count, setCount] = useState(1);
  const [done, setDone] = useState(false);

  const { tr } = useTranslation();
  const adminContext = useAdminContext();

  const { data, loading, error, refetch } = useQuery<
    gqltypes.demoPublicationQuery,
    gqltypes.demoPublicationQueryVariables
  >(publicationQuery, {
    variables: {
      context: adminContext,
    },
    fetchPolicy: "cache-and-network",
  });

  if (!data && loading) {
    return <Loading />;
  }

  const publications = data && data.organisation.publications;

  if (!publications) {
    return <GenericError />;
  }

  const selectedPublication =
    publications && publications.find((p) => selectedPublicationId === p.id);

  return (
    <ISTContainer header={tr("demoHeader")}>
      <div className="p-content">
        <div>
          <h2>{tr("demoPublicationsTitle")}</h2>

          {publications.map((p) => (
            <Button
              key={p.id}
              label={p.name}
              onClick={() => {
                setSelectedPublicationId(p.id);
                setDone(false);
                setCount(1);
              }}
              level={selectedPublicationId === p.id ? "primary" : "secondary"}
            />
          ))}

          {selectedPublication ? (
            selectedPublication.sent ? (
              <div className="mt-content">
                <div className="p-content">
                  <h3>{tr("demoCreateAnswersTitle")}</h3>
                  <p className="row col-12 col-md-9">
                    {tr("demoCreateAnswersDescription")}
                  </p>
                  {tr("demoCount")}:
                  <input
                    type="range"
                    min="1"
                    max={selectedPublication.stats.sent}
                    value={count}
                    onChange={(event) =>
                      setCount(parseInt(event.currentTarget.value, 10))
                    }
                    id="myRange"
                  />
                  <br />
                  <MutationButton
                    label={tr("demoCreateAnswersLabel", count)}
                    workingLabel={tr("demoCreatingAnswersLabel")}
                    disabled={done}
                    mutation={createResponseMutation}
                    variables={{
                      publicationId: selectedPublication.id,
                      count,
                    }}
                    onSuccess={() => setDone(true)}
                  />
                  {done && <p>{tr("demoCreatedAnswers")}</p>}
                </div>
              </div>
            ) : (
              <div>
                <MutationButton
                  label={tr("demoMarkAsSentLabel")}
                  workingLabel={tr("demoMarkAsSentWorkingLabel")}
                  mutation={setPublicationAsSent}
                  variables={{
                    publicationId: selectedPublication.id,
                  }}
                  onlyOnce
                  onSuccess={refetch}
                />
              </div>
            )
          ) : null}
        </div>
      </div>
    </ISTContainer>
  );
};
