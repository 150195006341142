import * as React from "react";
import { Button } from "./Button";

interface Props {
  wrapperClassName?: string;
  className?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  id: string;
  invalid?: boolean;
  label?: string;
  onChange: (event: React.FormEvent<HTMLTextAreaElement>) => void;
  onClick?: (event: React.FormEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: React.FormEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FormEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  validationMessage?: (input: string) => string;
  rows?: number;
  value: string;
  subText?: string;
}

export function FormTextArea(props: Props) {
  const {
    id,
    label,
    invalid,
    rows = 3,
    validationMessage,
    wrapperClassName,
    subText,
    ...attributes
  } = props;
  const invalidClass = invalid ? "is-invalid" : "";
  const invalidMessage = validationMessage
    ? validationMessage(props.value)
    : undefined;
  const wrapperClassName2 = "form-group " + (props.wrapperClassName || "");
  return (
    <div className={wrapperClassName2}>
      {label && (
        <label htmlFor={id} className={invalidClass}>
          {label}
        </label>
      )}
      <textarea
        {...attributes}
        id={id}
        aria-describedby={invalid ? id + "_error" : undefined}
        name={id}
        className={
          "form-control " +
          invalidClass +
          " " +
          (props.className ? props.className : "")
        }
        rows={rows}
      />
      {invalid ? (
        <div id={id + "_error"} className="invalid-feedback">
          {invalidMessage}
        </div>
      ) : null}
      {subText && <span className="text-muted">{subText}</span>}
    </div>
  );
}

export function FormTextAreaHideLabelWhenClicked(
  props: Props & { buttonClass?: string }
) {
  const [isClicked, setIsClicked] = React.useState(false);
  const { buttonClass, ...formTextAreaProps } = props;
  return isClicked ? (
    <FormTextArea
      {...formTextAreaProps}
      onBlur={(_) => setIsClicked(false)}
      label=""
      autoFocus={true}
    />
  ) : (
    <Button
      level="secondary"
      className={"flat " + (props.buttonClass ? props.buttonClass : "")}
      onClick={(_) => setIsClicked(true)}
      onFocus={(_) => setIsClicked(true)}
      label={props.label || ""}
    />
  );
}
