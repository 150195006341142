import { defaultTo } from "lodash";
import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import { useRandomId } from "../hooks";

interface Props {
  tooltipText: string;
  placement?:
    | "auto"
    | "auto-start"
    | "auto-end"
    | "top"
    | "top-start"
    | "top-end"
    | "right"
    | "right-start"
    | "right-end"
    | "bottom"
    | "bottom-start"
    | "bottom-end"
    | "left"
    | "left-start"
    | "left-end";
  faIconClass?: string;
}

export function HelpTooltip(props: Props) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const tooltipId = useRandomId("tooltip");

  const { faIconClass } = props;
  return (
    <React.Fragment>
      <span id={tooltipId} data-toggle="tooltip">
        <i className={faIconClass ? faIconClass : "fa fa-question-circle"} />
      </span>
      {tooltipId && (
        <Tooltip
          placement={defaultTo(props.placement, "right")}
          autohide={false}
          toggle={() => {
            setTooltipOpen(!tooltipOpen);
          }}
          target={tooltipId}
          isOpen={tooltipOpen}
        >
          {props.tooltipText}
        </Tooltip>
      )}
    </React.Fragment>
  );
}
