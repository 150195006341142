import React from "react";

export const ErrorBox = (props: { title: string; message?: string }) => (
  <main className="d-flex flex-grow-1">
    <div className="start-box">
      <h1>{props.title}</h1>
      {props.message ? <p>{props.message}</p> : null}
    </div>
  </main>
);
