import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "../../App/reducer";
import { Loading } from "../../Common";
import { GenericError } from "../../Common/components/GenericError";
import {
  CREATE_RESPONSE_MUTATION,
  CREATE_RESPONSE_MUTATION_TYPES,
} from "../../Common/mutations";
import {
  ADMIN_PUBLICATION_RECIPIENT_QUERY,
  ADMIN_PUBLICATION_RECIPIENT_TYPES,
  emptyPublicationResponse,
} from "../../Common/queries";
import { deepOmit } from "../../Utils/functional";
import { PublicationResponseStatus } from "../../gqltypes";
import { links } from "../links";

const usePublicationResponseMutations = () => {
  const [createResponse] = useMutation<
    CREATE_RESPONSE_MUTATION_TYPES["data"],
    CREATE_RESPONSE_MUTATION_TYPES["variables"]
  >(CREATE_RESPONSE_MUTATION);

  return {
    createResponse,
  };
};

export const CreatePublicationResponse = () => {
  const { tr } = useTranslation();
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const recipientId = params.id;

  const publicationRecipientData = useQuery<
    ADMIN_PUBLICATION_RECIPIENT_TYPES["data"],
    ADMIN_PUBLICATION_RECIPIENT_TYPES["variables"]
  >(ADMIN_PUBLICATION_RECIPIENT_QUERY, {
    variables: { id: recipientId! },
    skip: !recipientId,
  });

  const mutations = usePublicationResponseMutations();

  const publicationRecipient =
    publicationRecipientData.data?.publicationRecipient;

  const triggered = useRef(false);

  useEffect(() => {
    if (triggered.current) return;
    if (!publicationRecipient?.id) return;

    triggered.current = true;
    const draftResponse = publicationRecipient.responses.find(
      (r) => r.status !== PublicationResponseStatus.valid
    );
    const validResponse = publicationRecipient.responses.find(
      (r) => r.status === PublicationResponseStatus.valid
    );

    if (draftResponse) {
      navigate(links.admin.publication.response(draftResponse.id), {
        replace: true,
      });
    } else {
      const escaped = validResponse
        ? deepOmit(validResponse, (key) =>
            ["__typename", "redacted"].includes(key)
          )
        : undefined;

      const run = async () => {
        const res = await mutations.createResponse({
          variables: {
            recipientId: recipientId!,
            response: escaped?.response || emptyPublicationResponse,
          },
        });

        const id = res.data?.createPublicationResponse.id;
        if (!id) {
          setError(true);
          return;
        }
        navigate(links.admin.publication.response(id), { replace: true });
      };
      run();
    }
    // TODO: Seems to work fine but might want to fix this lint issue some day
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicationRecipient, history]);

  if (
    !publicationRecipientData ||
    publicationRecipientData.loading ||
    publicationRecipientData.data === undefined ||
    !publicationRecipient
  ) {
    return <Loading />;
  }

  if (error) return <GenericError />;

  if (!publicationRecipient.modifyPermission) {
    return <GenericError title={tr("createAnswerMissingPermissions")} />;
  }

  return <Loading />;
};
