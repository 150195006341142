import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { info } from "./buildinfo";
import { getSettings } from "./settings";

const environment = getSettings().releaseStage;

Sentry.init({
  dsn: "https://fcd84c48479744e1a7a7b37bd6e2e7e3@sentry.ist.com/5",
  integrations: [
    new Integrations.BrowserTracing({ traceXHR: false, traceFetch: false }),
  ],
  normalizeDepth: 10,

  environment,
  release: `consent-portal@${info.version}`,

  // debug: environment !== "production",

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: environment !== "production" ? 1.0 : 0.2,

  enabled: environment !== "local",
});

export function setUser(user: Sentry.User) {
  Sentry.setUser(user);
}

export function setOrganisation(org: {
  id: string | null;
  name: string | null;
}) {
  Sentry.setContext("org", org);
}

export function setLanguage(language: string) {
  Sentry.setTag("ui_language", language);
}

export const captureException = Sentry.captureException;

export const captureMessage = Sentry.captureMessage;

export const enum SeverityLevel {
  debug = "debug",
  fatal = "fatal",
  error = "error",
  warning = "warning",
  log = "log",
  info = "info",
}
