import * as React from "react";

const INFOCAPTION_URL: {
  [key: string]: string;
} = {
  se: "https://ist.infocaption.com/",
  no: "https://ist.guidecloud.se/",
};

// Code generated from https://ist.infocaption.com/api/contexthelp/script/Build.jsp
const initInfoCaption = (selector: string, region: string): any => {
  const serverUrl = INFOCAPTION_URL[region];
  (window as any).InfoCaptionContextHelp = { serverURL: serverUrl };
  const a = document.createElement("script");
  const m = document.getElementsByTagName("script")[0];
  a.async = true;
  a.src = `${serverUrl}api/contexthelp/script`;
  m.parentNode?.insertBefore(a, m);
  a.onload = function () {
    const ic: any = (window as any).InfoCaptionContextHelp;
    ic.show({
      position: "customButton",
      customButtonSelector: selector,
      customButtonCloseVisible: false,
    });
  };
};

export const InfoCaption = (props: { region: string }) => {
  const [isInfoCaptionOpen, setIsInfoCaptionOpen] = React.useState(false);
  React.useEffect(() => {
    if (INFOCAPTION_URL[props.region] !== undefined) {
      initInfoCaption("#infoCaption", props.region);
    }
  }, [props.region]);
  const toggleInfoCaptionIcon = () => {
    setIsInfoCaptionOpen(!isInfoCaptionOpen);
  };
  if (INFOCAPTION_URL[props.region] === undefined) {
    return null;
  }
  return (
    <div
      className="d-flex align-self-left align-self-lg-center py-3 p-lg-3"
      style={{ fontSize: "1.3em" }}
    >
      <button
        className="icon-button flat"
        type="button"
        id="infoCaption"
        onClick={toggleInfoCaptionIcon}
      >
        <i
          className={
            "fa " +
            (isInfoCaptionOpen ? "fa-times-circle" : "fa-question-circle")
          }
        />
      </button>
    </div>
  );
};
