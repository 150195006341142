import * as React from "react";
import { Translation } from "../../App/reducer";
import * as f from "../../Utils/functional";
import * as gqltypes from "../../gqltypes";
import { FormComponentQuestionView, answerForQuestion } from "..";

interface Props extends Translation {
  style?: any;
  updateComponentAnswer?: (component: gqltypes.ComponentAnswerInput) => void;
  answer?: gqltypes.FormComponentAnswer;
  component: gqltypes.FormPredicateComponent;
  uiLanguage: string;
  translation?:
    | NonNullable<
        gqltypes.FullTranslation["componentData"]["predicateComponents"]
      >[0]
    | null;
  validateAnswers?: boolean;
}

const updateAnswer = (props: Props, answer: gqltypes.QuestionAnswerUnion) => {
  if (props.updateComponentAnswer === undefined) {
    return;
  }

  if (!f.definedNotNull(props.answer)) {
    return props.updateComponentAnswer({
      componentId: props.component.id,
      questions: [answer],
    });
  }

  const questions = props.answer.questions || [];
  const indexToUpdate = questions.findIndex(
    (q) => q.questionId === answer.questionId
  );

  const sensitiveInformationConsent = f.undefinedToDefault(
    props.answer.sensitiveInformationConsent,
    false
  );

  if (indexToUpdate === -1) {
    return props.updateComponentAnswer({
      componentId: props.component.id,
      questions: [...questions, answer],
      sensitiveInformationConsent,
      redacted: props.answer?.redacted ?? null,
    });
  }
  props.updateComponentAnswer({
    componentId: props.component.id,
    questions: f.replaceInArray(questions, indexToUpdate, answer),
    sensitiveInformationConsent,
    redacted: props.answer?.redacted ?? null,
  });
};

export const FormPredicateComponentView = (props: Props) => {
  return (
    <div style={props.style}>
      {props.component.questions.map((q) => {
        const hasOptions =
          q.type === gqltypes.FormQuestionType.checkboxes ||
          q.type === gqltypes.FormQuestionType.multiChoice ||
          q.type === gqltypes.FormQuestionType.ranking;

        const questionTranslation = (props.translation &&
          props.translation.questions &&
          props.translation.questions.find((tq) => tq.id === q.id)) || {
          id: q.id,
          question: "",
          shortName: "",
          options: hasOptions ? [] : null,
          __typename: "FormTranslateQuestion",
        };

        return (
          <FormComponentQuestionView
            key={q.id}
            {...props}
            updateAnswer={(a) => {
              updateAnswer(props, a);
            }}
            validateAnswers={props.validateAnswers}
            translation={questionTranslation}
            answer={answerForQuestion(q, props.answer)}
            data={q}
          />
        );
      })}
    </div>
  );
};
