import gql from "graphql-tag";
import * as React from "react";
import { ISTContainer } from "../../Common";
import { MutationButton } from "../../Common/components/MutationButton";
import { showConfirmDialog } from "../../Utils/dialogs/showDialog";

export const Advanced = () => {
  return (
    <div>
      <h1>Superadmin</h1>
      <p className="col-12 col-md-9 p-0 pb-3">Superadmin start</p>
      <ISTContainer header="Start">
        <div className="row p-content">
          <div className="col-12 mb-content">
            <MutationButton
              mutation={gql`
                mutation adminForceUpdatePublicationGroupNames {
                  adminForceUpdatePublicationGroupNames {
                    code
                  }
                }
              `}
              label="Uppdatera alla utskicks gruppnamn"
              successLabel="Alla utskicks gruppnamn uppdaterade"
              onlyOnce
              onClick={() => {
                return showConfirmDialog({
                  title: "Uppdatera gruppnamn?",
                  message:
                    "Du håller på att uppdatera alla studentgruppers namn som har fått utskick. Är du säker?",
                  cancelText: "Avbryt",
                  proceedText: "OK",
                });
              }}
            />
          </div>
          <div className="col-12 mb-content">
            <MutationButton<{ adminPing: { code: string } }>
              mutation={gql`
                mutation adminPing {
                  adminPing {
                    code
                  }
                }
              `}
              label="Ping"
              successLabel="Pingat"
              onlyOnce
            />
          </div>
          <div className="col-12 mb-content">
            <MutationButton
              mutation={gql`
                mutation adminClearEducloudCache {
                  adminClearEducloudCache {
                    code
                  }
                }
              `}
              label="Rensa EduCloud cache"
              successLabel="Cache rensad"
              onlyOnce
              onClick={() => {
                return showConfirmDialog({
                  title: "Rensa EduCloud cache?",
                  message:
                    "Du håller på att tömma educloud cachen. Är du säker?",
                  cancelText: "Avbryt",
                  proceedText: "OK",
                });
              }}
            />
          </div>
          <div className="col-12 mb-content">
            <MutationButton
              mutation={gql`
                mutation updateEmptyActorNames {
                  updateEmptyActorNames
                }
              `}
              label="Uppdatera saknade namn"
              successLabel="Saknade namn har blivit uppdaterade"
              onlyOnce
              onClick={() => {
                return showConfirmDialog({
                  title: "Uppdatera saknade namn?",
                  message:
                    "Du håller på att uppdatera alla namn där det saknas namn i actors tabellen. Är du säker?",
                  cancelText: "Avbryt",
                  proceedText: "OK",
                });
              }}
            />
          </div>
        </div>
      </ISTContainer>
    </div>
  );
};
