import gql from "graphql-tag";

export const fullHistoryRecordFragment = gql`
  fragment FullHistoryRecord on HistoryRecord {
    id
    label
    ... on ClassResponsesHistoryRecord {
      type
      data {
        customerId
        schoolUnitId
        studentGroupId
      }
    }
    ... on FormHistoryRecord {
      type
      data {
        formId
      }
    }
  }
`;

export const formMetadataFragment = gql`
  fragment FormMetadata on Form {
    id
    type
    name
    creator {
      id
      name
    }
    created
    locked
  }
`;

export const fullFormQuestionFragment = gql`
  fragment FullComponentQuestion on FormQuestion {
    id
    templateQuestionId
    question
    shortName
    predicates {
      predicate
      componentId
    }
    type
    compulsory
    ... on FormQuestionRanking {
      minSelectedOptions
      maxSelectedOptions
      options {
        id
        label
        externalId
      }
    }
    ... on FormQuestionShortText {
      validationType
    }
    ... on FormQuestionCheckboxes {
      options {
        id
        label
        externalId
      }
    }
    ... on FormQuestionMultiChoice {
      dropdownView
      options {
        id
        label
        externalId
      }
    }
  }
`;

export const fullFormComponentFragment = gql`
  fragment FullFormComponent on FormComponent {
    id
    applicationFeedbackId
    language
    title
    description
    sensitive
    permission
    questions {
      ...FullComponentQuestion
    }
    templateId
    attachments {
      name
      key
      url
    }
  }
  ${fullFormQuestionFragment}
`;

export const fullFormPredicateComponentFragment = gql`
  fragment FullFormPredicateComponent on FormPredicateComponent {
    id
    language
    sensitive
    permission
    questions {
      ...FullComponentQuestion
    }
  }
  ${fullFormQuestionFragment}
`;

export const formComponentDataFragment = gql`
  fragment FormComponentData on ComponentData {
    components {
      ...FullFormComponent
    }
    predicateComponents {
      ...FullFormPredicateComponent
    }
  }
  ${fullFormComponentFragment}
  ${fullFormPredicateComponentFragment}
`;

export const fullFormAccessFragment = gql`
  fragment FullFormAccess on FormAccess {
    public
    organisation
    schoolUnits {
      id
      customerId
      displayName
    }
  }
`;

export const fullFormAnswerFragment = gql`
  fragment FullFormAnswer on FormAnswer {
    components {
      componentId
      redacted
      sensitiveInformationConsent
      questions {
        ... on AnswerMultiChoice {
          questionId
          multiChoice
        }
        ... on AnswerCheckboxes {
          questionId
          checkboxes
        }
        ... on AnswerRanking {
          questionId
          rankings {
            order
            value
          }
        }
        ... on AnswerShortText {
          questionId
          shortText
        }
        ... on AnswerLongText {
          questionId
          longText
        }
        ... on AnswerYesOrNo {
          questionId
          yesOrNo
        }
        ... on AnswerDate {
          questionId
          date
        }
      }
    }
  }
`;

export const fullResponseFragment = gql`
  fragment FullResponse on PublicationResponse {
    id
    status
    signRole
    signatures {
      signerId
      customerId
      signer {
        id
        source
        customerId
        idp
        idpSub
        name
      }
      educloudUser {
        id
        source
        customerId
        name
      }
      educloudUserId
    }
    modified
    language
    response {
      ...FullFormAnswer
    }
    lockHolder {
      id
      firstName
    }
    lockExpire
    validIfSigned
  }
  ${fullFormAnswerFragment}
`;

export const fullResponseWithEmailsFragment = gql`
  fragment FullResponseWithEmail on PublicationResponse {
    id
    status
    signatures {
      signerId
      signer {
        id
        source
        customerId
        idp
        idpSub
        name
        emails {
          value
          preferredHome
        }
      }
      educloudUser {
        id
        source
        customerId
        name
      }
      educloudUserId
    }
    modified
    language
    response {
      ...FullFormAnswer
    }
    lockHolder {
      id
      firstName
    }
    lockExpire
    validIfSigned
  }
  ${fullFormAnswerFragment}
`;

export const fullApplicationResponseFragment = gql`
  fragment FullApplicationResponse on ApplicationResponse {
    id
    status
    modified
    seenByOrg
    language
    lastAction {
      id
      action
      newStatus
      comment
      errorCode
      created
    }
    actions {
      id
      action
      newStatus
      comment
      errorCode
      created
    }
    signatures {
      signerId
      signedAs
      customerId
    }
    feedbackSignatures {
      signerId
      signedAs
      customerId
    }
    response {
      ...FullFormAnswer
    }
    creator {
      id
      name
      source
      verifiedRelation {
        isVerifiedRelation
        customerId
      }
      orgId
    }
    subject {
      userId
      customerId
      name
    }
    lockHolder {
      id
      firstName
    }
    feedbackId
    feedbackDescription
    lockExpire
    schoolUnit {
      id
      customerId
      displayName
    }
  }
  ${fullFormAnswerFragment}
`;

export const standardNameFragment = gql`
  fragment StandardName on User {
    name
    firstName
    lastName
  }
`;

export const fullPublicationFragment = gql`
  fragment FullPublication on Publication {
    id
    form {
      id
      type
      name
      description
      language
      componentData {
        components {
          ...FullFormComponent
        }
        predicateComponents {
          ...FullFormPredicateComponent
        }
      }
      owner {
        id
        displayName
      }
    }
    recipients {
      groups {
        edges {
          id
          groupId
          customerId
          schoolYearFilter
          type
          displayName
          removed
        }
      }
    }
    name
    validFrom
    validTo
    sendDate
    sent
    singleGuardian
    childResponse
    skipEmails
    tags
  }
  ${fullFormComponentFragment}
  ${fullFormPredicateComponentFragment}
`;

export const SchoolUnitsFragment = gql`
  fragment SchoolUnits on SchoolUnit {
    id
    customerId
    displayName
    schoolTypes
    classes {
      id
      customerId
      schoolType
      displayName
      memberCount
      groupType
    }
  }
`;

export const fullFormTranslationFragment = gql`
  fragment FullTranslation on TranslationForm {
    formId
    name
    description
    language
    componentData {
      predicateComponents {
        id
        language
        questions {
          id
          question
          shortName
          options {
            id
            label
          }
        }
      }
      components {
        id
        language
        title
        description
        questions {
          id
          question
          shortName
          options {
            id
            label
          }
        }
        attachments {
          name
          key
          url
        }
      }
    }
  }
`;
