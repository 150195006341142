import { findIndex } from "lodash";
import * as React from "react";
import FlipMove from "react-flip-move";
import { UncontrolledTooltip } from "reactstrap";
import { Rest } from "../../Admin/components/CreateForm";
import {
  makeDefaultOption,
  makeQuestion,
  questionTypeHasOptions,
} from "../../Admin/form";
import { Translation } from "../../App/reducer";
import { showConfirmDialog } from "../../Utils/dialogs/showDialog";
import {
  definedNotNull,
  move,
  omit,
  replaceInArray,
} from "../../Utils/functional";
import * as f from "../../Utils/functional";
import * as preds from "../../Utils/predicates";
import { createPseudoID } from "../../Utils/pseudoID";
import { assertUnreachable } from "../../Utils/typeHelpers";
import curry from "../../curry";
import * as gqltypes from "../../gqltypes";
import { defaultDialogCancel } from "../../translation/strings";
import { FormQuestionDataTyped, ShortTextValidationTypes } from "../../types";
import { FormInput } from "./FormInput";
import { FormSelect } from "./FormSelect";
import { FormTextAreaHideLabelWhenClicked } from "./FormTextArea";
import {
  Button,
  Checkbox,
  CommonFormEditorProps,
  FormPredicateComponentEditor,
  Radiobutton,
} from "..";

interface Props {
  tr: Translation["tr"];
  uiLanguage: Translation["uiLanguage"];
  predicateComponentCreator?: Rest<
    gqltypes.FormPredicateComponent,
    gqltypes.FormPredicateComponentInput,
    gqltypes.TranslatePredicateComponent
  >;
  updateQuestion: (data: gqltypes.FormQuestionInput) => void;
  updateTranslation: (data: gqltypes.FormTranslateQuestion) => void;
  removeQuestion: (question: gqltypes.FormQuestionInput) => void;
  data:
    | gqltypes.FormQuestionWithOptionsUnion
    | gqltypes.FormQuestionWithoutOptionsUnion;
  formLanguage: gqltypes.ISO6391Language;
  validate?: boolean;
  actionsContainer: React.ReactNode;
  translation?:
    | gqltypes.FullTranslation["componentData"]["components"][0]["questions"][0]
    | null;
  translationLanguage?: gqltypes.ISO6391Language | null;
  translating: boolean;
  disabled?: boolean;
  advancedMode: boolean;
}
interface State {
  recentAddedOption?: string;
}

interface PredicatesProps extends Translation, CommonFormEditorProps {
  predicates: gqltypes.FormQuestionPredicate[];
  data:
    | gqltypes.FormQuestionWithOptionsUnion
    | gqltypes.FormQuestionWithoutOptionsUnion;
  predicateComponentCreator: Rest<
    gqltypes.FormPredicateComponent,
    gqltypes.FormPredicateComponentInput,
    gqltypes.TranslatePredicateComponent
  >;
}

enum PredicateKind {
  Option,
  YesNo,
}

export function PredicatesComponent(props: PredicatesProps) {
  return (
    <React.Fragment>
      {props.data.predicates
        ? [...new Set(props.predicates.map((p) => p.componentId))].map(
            (componentId: string) => {
              const c = props.predicateComponentCreator.get(componentId);

              const updateComponent = curry(
                props.predicateComponentCreator.update,
                componentId
              );

              const trans = props.predicateComponentCreator.getTranslation(
                c.id
              ) as
                | NonNullable<
                    gqltypes.FullTranslation["componentData"]["predicateComponents"]
                  >[0]
                | null;

              return (
                <FormPredicateComponentEditor
                  key={componentId}
                  component={c}
                  {...props}
                  updateComponent={updateComponent}
                  translation={trans}
                />
              );
            }
          )
        : null}
    </React.Fragment>
  );
}

export class FormComponentQuestionEditor extends React.PureComponent<
  Props,
  State
> {
  private shortNameId: string;
  private inputId: string;
  private compulsoryOptionId: string;
  private tempSavedOptions?: gqltypes.FormQuestionOptions[];
  constructor(props: Props) {
    super(props);
    this.inputId = createPseudoID();
    this.shortNameId = createPseudoID();
    this.compulsoryOptionId = createPseudoID();
    this.state = {};
  }

  public render() {
    const containerClasses = "question-container";
    const { tr, translation, data, translating, disabled } = this.props;
    const question = translating
      ? translation
        ? translation.question
        : ""
      : data.question;
    const shortName = translating
      ? translation
        ? translation.shortName
        : ""
      : data.shortName;

    // const { strings } = this.state;

    const questionTypeOptions: {
      label: string;
      value: gqltypes.FormQuestionType;
    }[] = [
      {
        label: tr("formQuestionTypeCheckboxes"),
        value: gqltypes.FormQuestionType.checkboxes,
      },
      {
        label: tr("formQuestionTypeMultiChoice"),
        value: gqltypes.FormQuestionType.multiChoice,
      },
      {
        label: tr("formQuestionTypeRanking"),
        value: gqltypes.FormQuestionType.ranking,
      },
      {
        label: tr("formQuestionTypeShortText"),
        value: gqltypes.FormQuestionType.shortText,
      },
      {
        label: tr("formQuestionTypeLongText"),
        value: gqltypes.FormQuestionType.longText,
      },
      {
        label: tr("formQuestionTypeYesNo"),
        value: gqltypes.FormQuestionType.yesOrNo,
      },
      {
        label: tr("formQuestionTypeDate"),
        value: gqltypes.FormQuestionType.date,
      },
    ];

    return (
      <div className={containerClasses}>
        <div className="question-header">
          <div className="row">
            <div className="col-12 col-sm-8 col-md-6 col-lg-5">
              <FormInput
                id={this.inputId}
                className="question"
                value={question}
                disabled={disabled}
                placeholder={
                  this.props.predicateComponentCreator
                    ? tr("formQuestion")
                    : tr("formFollowupQuestion")
                }
                onChange={
                  translating
                    ? this.handleChangeTranslationQuestion
                    : this.handleChangeQuestion
                }
                label={
                  this.props.predicateComponentCreator
                    ? tr("formQuestion")
                    : tr("formFollowupQuestion")
                }
                feedback={this.getRequiredInputFeedback(question)}
                subText={
                  translating
                    ? this.getTranslationLanguagePrefix() + data.question
                    : undefined
                }
              />
            </div>
            <div className="col-12 col-sm-4 col-md-3 col-lg-2">
              <FormInput
                id={this.shortNameId}
                className="short-name-input"
                disabled={disabled}
                label={
                  <span id={"shortNameTooltip" + data.id} data-toggle="tooltip">
                    {tr("formShortName")}&nbsp;
                    <i className="fa fa-question-circle" />
                  </span>
                }
                aria-label={tr("formShortNameDescription")}
                placeholder={tr("formShortName")}
                value={shortName || ""}
                onChange={
                  translating
                    ? this.handleUpdateTranslationShortName
                    : this.handleUpdateShortName
                }
                subText={
                  translating
                    ? this.getTranslationLanguagePrefix() +
                      (data.shortName || "")
                    : undefined
                }
              />
              <UncontrolledTooltip target={"shortNameTooltip" + data.id}>
                {tr("formShortNameDescription")}
              </UncontrolledTooltip>
            </div>
            {!translating && (
              <React.Fragment>
                <div className="col-6 col-md-3 col-lg-2">
                  <FormSelect
                    label={tr("formAnswerType")}
                    disabled={disabled}
                    value={this.props.data.type}
                    onChange={this.handleChangeQuestionType}
                    options={questionTypeOptions}
                  />
                </div>
                <div className="col-auto">
                  <div className="form-group d-flex flex-column align-items-center">
                    <label
                      htmlFor={this.compulsoryOptionId}
                      className="form-input-label"
                    >
                      {tr("formRequiresAnswer")}
                    </label>
                    <Checkbox
                      id={this.compulsoryOptionId}
                      disabled={
                        this.props.data.type === "checkboxes" || disabled
                      }
                      checked={this.props.data.compulsory}
                      onChange={this.handleChangeCompulsory}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}

            {this.props.actionsContainer}
          </div>
        </div>
        <div className="question-body">{this.renderQuestion()}</div>
        <hr />
      </div>
    );
  }

  private getTranslationLanguagePrefix = () => this.props.formLanguage + ": ";

  private getRequiredInputFeedback = (input?: string | null) => {
    if (!this.props.validate) {
      return "";
    }
    if (!definedNotNull(input) || input === "") {
      return this.props.tr("validationAnswerCompulsoryButMissing");
    }
  };

  private renderOptionActions = (
    optionId: string,
    first: boolean,
    last: boolean,
    lastOption: boolean,
    hidePredicates?: boolean
  ) => {
    const { disabled } = this.props;
    return (
      <div className="option-delete-container">
        {hidePredicates !== true && this.props.predicateComponentCreator && (
          <>
            <Button
              level="secondary"
              //              backIcon={<i className="fa fa-plus" />}
              className="flat thin"
              onClick={curry(
                this.handleAddPredicate,
                this.props.predicateComponentCreator,
                preds.createMatchingOptionPredicate,
                { option: { id: optionId } }
              )}
              tabIndex={-1}
              label={this.props.tr("addFollowUpQuestion")}
            />
            &nbsp;
          </>
        )}
        <i
          onClick={(e: React.MouseEvent<HTMLDivElement>) =>
            !first && this.handleMoveOption(optionId, -1)
          }
          className={
            "fas fa-arrow-up icon-button p-1" +
            (first || disabled ? " disabled" : "")
          }
        />
        &nbsp;
        <i
          onClick={(e: React.MouseEvent<HTMLDivElement>) =>
            !last && this.handleMoveOption(optionId, 1)
          }
          className={
            "fas fa-arrow-down icon-button p-1" +
            (last || disabled ? " disabled" : "")
          }
        />
        {lastOption && (
          <React.Fragment>
            &nbsp;
            <i
              onClick={() => this.handleRemoveOption(optionId)}
              className={
                "fas fa-times icon-button p-1" + (disabled ? " disabled" : "")
              }
            />
          </React.Fragment>
        )}
      </div>
    );
  };

  private renderCheckboxes(data: FormQuestionDataTyped<"checkboxes">) {
    const { tr, translating, translation, disabled } = this.props;
    const translationOptionMap =
      (translation &&
        translation.options!.reduce((out, item) => {
          out[item.id] = item;
          return out;
        }, {} as { [id: string]: { id: string; label: string } })) ||
      {};

    return (
      <React.Fragment>
        <div className="row">
          {!translating && this.props.advancedMode ? (
            <div className="offset-6 col-3 d-flex justify-content-end align-items-end">
              <span id={"externalIdTooltip" + data.id} data-toggle="tooltip">
                {tr("formExternalId")}&nbsp;
                <i className="fa fa-question-circle" />
              </span>
              <UncontrolledTooltip target={"externalIdTooltip" + data.id}>
                {tr("formExternalIdDescription")}
              </UncontrolledTooltip>
            </div>
          ) : null}
          {!translating && data.options.length > 5 ? (
            <div className="col-auto ml-auto">
              <Button
                className="form-group"
                label={this.props.tr(
                  "formComponentQuestionEditorClearOptionsLabel"
                )}
                level="secondary"
                onClick={this.handleClearOptions}
              />
            </div>
          ) : null}
        </div>
        <FlipMove
          duration={350}
          easing="ease-out"
          appearAnimation={"none"}
          enterAnimation={"fade"}
          leaveAnimation={
            null as any /* workaround for animations at bottom of page */
          }
        >
          {data.options.map((option, i) => {
            const optionTranslation = translationOptionMap[option.id];
            const label = translating
              ? optionTranslation
                ? optionTranslation.label
                : ""
              : option.label;

            const matchingPreds =
              this.props.data.predicates &&
              this.props.data.predicates.filter((p) => {
                return preds.resolvePred(p.predicate, {
                  option: { id: option.id },
                });
              });

            return (
              <div key={option.id}>
                <div className="option-row pl-content">
                  <Checkbox id={option.id} checked={false} tabIndex={-1} />
                  <FormInput
                    autoFocus={this.state.recentAddedOption === option.id}
                    className="option-label"
                    type="text"
                    onChange={
                      translating
                        ? this.updateTranslationOption
                        : this.updateOption
                    }
                    id={option.id}
                    value={label}
                    disabled={disabled}
                    placeholder={this.props.tr("formOption") + " " + (i + 1)}
                    feedback={this.getRequiredInputFeedback(label)}
                    subText={
                      translating
                        ? this.getTranslationLanguagePrefix() + option.label
                        : undefined
                    }
                  />
                  {!translating && this.props.advancedMode ? (
                    <div className="col-4">
                      <FormInput
                        id={option.id}
                        type="text"
                        className="option-label"
                        value={option.externalId || ""}
                        onChange={this.updateOptionExternalId}
                      />
                    </div>
                  ) : null}
                  {!translating &&
                    this.renderOptionActions(
                      option.id,
                      i === 0,
                      i === data.options.length - 1,
                      data.options.length > 1
                    )}
                  {/* {data.options.length > 1 && this.renderOptionDelete(option.id)} */}
                </div>
                {this.props.predicateComponentCreator &&
                  matchingPreds &&
                  f.notEmpty(matchingPreds) && (
                    <div className="p-content predicates-box">
                      <PredicatesComponent
                        predicateComponentCreator={
                          this.props.predicateComponentCreator
                        }
                        predicates={matchingPreds}
                        {...this.props}
                      />
                    </div>
                  )}
              </div>
            );
          })}
        </FlipMove>
        {!translating && (
          <div key="new-option-row" className="option-row pl-content">
            <Checkbox id="new-checkbox-id" checked={false} tabIndex={-1} />

            <FormTextAreaHideLabelWhenClicked
              wrapperClassName="option-label"
              buttonClass="alternative-like"
              onChange={this.handleAddOptionEventWithValue}
              id={"newCheckbox"}
              value={""}
              rows={1}
              disabled={disabled}
              label={this.props.tr("formAddOption")}
            />
          </div>
        )}
      </React.Fragment>
    );
  }

  private renderMultiChoice(
    data: FormQuestionDataTyped<"multiChoice">,
    answer?: gqltypes.AnswerMultiChoice
  ) {
    const { tr, translating, translation, disabled } = this.props;
    const translationOptionMap =
      (translation &&
        translation.options &&
        translation.options!.reduce((out, item) => {
          out[item.id] = item;
          return out;
        }, {} as { [id: string]: { id: string; label: string } })) ||
      {};

    return (
      <React.Fragment>
        <div className="row">
          {!translating ? (
            <React.Fragment>
              <div className="col-6">
                <Checkbox
                  id={"multiChoiceDropdownView_" + data.id}
                  label={tr("formMultiChoiceShowDropdown")}
                  checked={data.dropdownView || false}
                  containerClassName="form-group"
                  onChange={(id, checked) => {
                    data.dropdownView = checked;
                    this.props.updateQuestion(data);
                  }}
                />
              </div>
              {this.props.advancedMode ? (
                <div className="offset-6 col-3 d-flex justify-content-end align-items-end">
                  <span
                    id={"externalIdTooltip" + data.id}
                    data-toggle="tooltip"
                  >
                    {tr("formExternalId")}&nbsp;
                    <i className="fa fa-question-circle" />
                  </span>
                  <UncontrolledTooltip target={"externalIdTooltip" + data.id}>
                    {tr("formExternalIdDescription")}
                  </UncontrolledTooltip>
                </div>
              ) : null}
            </React.Fragment>
          ) : null}
          {!translating && data.options.length > 5 ? (
            <div className="col-auto ml-auto">
              <Button
                className="form-group"
                label={this.props.tr(
                  "formComponentQuestionEditorClearOptionsLabel"
                )}
                level="secondary"
                onClick={this.handleClearOptions}
              />
            </div>
          ) : null}
        </div>
        <FlipMove
          duration={350}
          easing="ease-out"
          appearAnimation={"none"}
          enterAnimation={"fade"}
          leaveAnimation={
            null as any /* workaround for animations at bottom of page */
          }
        >
          {data.options.map((option, i) => {
            const optionTranslation = translationOptionMap[option.id];
            const label = translating
              ? optionTranslation
                ? optionTranslation.label
                : ""
              : option.label;

            const matchingPreds =
              this.props.data.predicates &&
              this.props.data.predicates.filter((p) => {
                return preds.resolvePred(p.predicate, {
                  option: { id: option.id },
                });
              });

            return (
              <div key={option.id}>
                <div className="option-row pl-content">
                  <Radiobutton
                    id={`${option.id}_mock`}
                    name={`${option.id}_mock`}
                    value="on"
                    tabIndex={-1}
                    checked={false}
                    readOnly
                  />
                  <FormInput
                    autoFocus={this.state.recentAddedOption === option.id}
                    className="option-label"
                    type="text"
                    onChange={
                      translating
                        ? this.updateTranslationOption
                        : this.updateOption
                    }
                    id={option.id}
                    value={label}
                    disabled={disabled}
                    placeholder={this.props.tr("formOption") + " " + (i + 1)}
                    feedback={this.getRequiredInputFeedback(label)}
                    subText={
                      translating
                        ? this.getTranslationLanguagePrefix() + option.label
                        : undefined
                    }
                  />
                  {!translating && this.props.advancedMode ? (
                    <div className="col-4">
                      <FormInput
                        id={option.id}
                        type="text"
                        className="option-label"
                        value={option.externalId || ""}
                        onChange={this.updateOptionExternalId}
                      />
                    </div>
                  ) : null}
                  {!translating &&
                    this.renderOptionActions(
                      option.id,
                      i === 0,
                      i === data.options.length - 1,
                      data.options.length > 1
                    )}
                </div>
                {this.props.predicateComponentCreator &&
                  matchingPreds &&
                  f.notEmpty(matchingPreds) && (
                    <div className="p-content predicates-box">
                      <PredicatesComponent
                        predicateComponentCreator={
                          this.props.predicateComponentCreator
                        }
                        predicates={matchingPreds}
                        {...this.props}
                      />
                    </div>
                  )}
              </div>
            );
          })}
        </FlipMove>
        {!translating && (
          <div key="new-option-row" className="option-row pl-content">
            <Radiobutton
              id={`${data.id}_mock`}
              name={`${data.id}_mock`}
              value="on"
              tabIndex={-1}
              checked={false}
            />

            <FormTextAreaHideLabelWhenClicked
              wrapperClassName="option-label"
              buttonClass="alternative-like"
              onChange={this.handleAddOptionEventWithValue}
              id={"newCheckbox"}
              value={""}
              rows={1}
              disabled={disabled}
              label={this.props.tr("formAddOption")}
            />
          </div>
        )}
      </React.Fragment>
    );
  }

  private renderRanking(data: FormQuestionDataTyped<"ranking">) {
    const { tr, translating, translation, disabled } = this.props;

    const translationOptionMap =
      (translation &&
        (translation.options || []).reduce((out, item) => {
          out[item.id] = item;
          return out;
        }, {} as { [id: string]: { id: string; label: string } })) ||
      {};

    const widthStyle = {
      width: Math.max(Math.log10(data.options.length) * 14, 14),
    };

    const warning =
      (data.minSelectedOptions || 0) >
      (data.maxSelectedOptions || Number.MAX_SAFE_INTEGER);

    return (
      <React.Fragment>
        {!translating ? (
          <div className="row align-items-end">
            <div className={`col-6 col-md-3 ${warning ? "bg-warning" : ""}`}>
              <div className="form-group d-flex flex-column align-items-center">
                <label htmlFor="minSelectOptions" className="form-input-label">
                  {tr("FormComponentQuestionEditorMinChoices")}
                </label>

                <span id="minSelectOptions" style={{ fontSize: "20px" }}>
                  <i
                    className="fas fa-minus icon-button mr-2"
                    onClick={() => {
                      if (!data.minSelectedOptions) {
                        // do nothing
                      } else if (data.minSelectedOptions === 1) {
                        data.minSelectedOptions = undefined;
                        data.compulsory = false;
                      } else {
                        data.minSelectedOptions--;
                        data.compulsory = true;
                      }
                      this.props.updateQuestion(data);
                    }}
                  />
                  {data.minSelectedOptions || "0"}
                  <i
                    className="fas fa-plus icon-button ml-2"
                    onClick={() => {
                      if (!data.minSelectedOptions) {
                        data.minSelectedOptions = 1;
                      } else {
                        data.minSelectedOptions++;
                      }
                      data.compulsory = true;
                      this.props.updateQuestion(data);
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="form-group d-flex flex-column align-items-center">
                <label htmlFor="maxSelectOptions" className="form-input-label">
                  {tr("FormComponentQuestionEditorMaxChoices")}
                </label>

                <span id="maxSelectOptions" style={{ fontSize: "18px" }}>
                  <i
                    className="fas fa-minus icon-button mr-2"
                    onClick={() => {
                      if (!data.maxSelectedOptions) {
                        // do nothing
                      } else if (
                        data.maxSelectedOptions === 1 ||
                        data.maxSelectedOptions === data.minSelectedOptions
                      ) {
                        data.maxSelectedOptions = undefined;
                      } else {
                        data.maxSelectedOptions--;
                      }
                      this.props.updateQuestion(data);
                    }}
                  />
                  <strong>
                    {data.maxSelectedOptions || (
                      <i
                        style={{ fontSize: "13px" }}
                        className="fas fa-infinity"
                      />
                    )}
                  </strong>
                  <i
                    className="fas fa-plus icon-button ml-2"
                    onClick={() => {
                      if (!data.maxSelectedOptions) {
                        data.maxSelectedOptions = data.minSelectedOptions || 1;
                      } else {
                        data.maxSelectedOptions++;
                      }
                      this.props.updateQuestion(data);
                    }}
                  />
                  <i
                    className={`fas fa-infinity ml-2 ${
                      data.maxSelectedOptions ? "icon-button" : "invisible"
                    }`}
                    onClick={() => {
                      data.maxSelectedOptions = undefined;
                      this.props.updateQuestion(data);
                    }}
                  />
                </span>
              </div>
            </div>
            {!translating && this.props.advancedMode ? (
              <div className="col-3 d-flex justify-content-center">
                <span id={"externalIdTooltip" + data.id} data-toggle="tooltip">
                  {tr("formExternalId")}&nbsp;
                  <i className="fa fa-question-circle" />
                </span>
                <UncontrolledTooltip target={"externalIdTooltip" + data.id}>
                  {tr("formExternalIdDescription")}
                </UncontrolledTooltip>
              </div>
            ) : null}
            {data.options.length > 5 ? (
              <div className="col-auto ml-auto">
                <Button
                  className="form-group"
                  label={tr("formComponentQuestionEditorClearOptionsLabel")}
                  level="secondary"
                  onClick={this.handleClearOptions}
                />
              </div>
            ) : null}
          </div>
        ) : null}
        {data.options.map((option, i) => {
          const optionTranslation = translationOptionMap[option.id];
          const label = translating
            ? optionTranslation
              ? optionTranslation.label
              : ""
            : option.label;

          const className = translating
            ? "col-11"
            : this.props.advancedMode
            ? "col-6"
            : "col-10";

          return (
            <div
              key={option.id}
              className="option-row pl-content align-items-baseline"
            >
              <span style={widthStyle}>
                <strong>{i + 1}</strong>.
              </span>
              <div className={className}>
                {/* <Checkbox id={option.id} checked={false} tabIndex={-1} /> */}
                <FormInput
                  autoFocus={this.state.recentAddedOption === option.id}
                  className="option-label"
                  type="text"
                  onChange={
                    translating
                      ? this.updateTranslationOption
                      : this.updateOption
                  }
                  id={option.id}
                  value={label}
                  disabled={disabled}
                  placeholder={this.props.tr("formOption") + " " + (i + 1)}
                  feedback={this.getRequiredInputFeedback(label)}
                  subText={
                    translating
                      ? this.getTranslationLanguagePrefix() + option.label
                      : undefined
                  }
                />

                {/* {data.options.length > 1 && this.renderOptionDelete(option.id)} */}
              </div>
              {!translating && this.props.advancedMode ? (
                <div className="col-4">
                  <FormInput
                    id={option.id}
                    type="text"
                    className="option-label"
                    value={option.externalId || ""}
                    onChange={this.updateOptionExternalId}
                  />
                </div>
              ) : null}
              {!translating &&
                this.renderOptionActions(
                  option.id,
                  i === 0,
                  i === data.options.length - 1,
                  data.options.length > 1,
                  true
                )}
            </div>
          );
        })}
        {!translating && (
          <div
            key="new-option-row"
            className="option-row pl-content align-items-baseline"
          >
            <strong style={widthStyle}>+</strong>
            <div className="col-11">
              <FormTextAreaHideLabelWhenClicked
                wrapperClassName="option-label"
                buttonClass="alternative-like"
                onChange={this.handleAddOptionEventWithValue}
                id={"newRankingOption"}
                value={""}
                rows={1}
                disabled={disabled}
                label={this.props.tr("formAddOption")}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  private renderShortText(data: FormQuestionDataTyped<"shortText">) {
    const { tr } = this.props;
    return (
      <div className="row">
        <div className="col-12 col-md-5">
          <FormSelect
            id="shortText"
            label={tr("formShortTextValidation")}
            options={[
              {
                label: tr("formShortTextValidationNone"),
                value: "none",
              },
              {
                label: tr("formShortTextValidationNationalId"),
                value: "nationalId",
              },
              {
                label: tr("formShortTextValidationEmail"),
                value: "email",
              },
              {
                label: tr("formShortTextValidationPhone"),
                value: "phone",
              },
            ]}
            value={data.validationType || "none"}
            onChange={(e) => {
              const type: ShortTextValidationTypes =
                e.currentTarget.value === "none"
                  ? null
                  : (e.currentTarget.value as ShortTextValidationTypes);
              data.validationType = type;
              this.props.updateQuestion(data);
            }}
          />
        </div>
      </div>
    );
  }

  private renderYesOrNo(data: FormQuestionDataTyped<"yesOrNo">) {
    const { tr } = this.props;

    const getMatches = (yn: boolean) =>
      this.props.data.predicates &&
      this.props.data.predicates.filter((p) =>
        preds.resolvePred(p.predicate, {
          value: yn,
          question: { id: data.id },
        })
      );

    const yesMatches = getMatches(true);
    const noMatches = getMatches(false);

    return (
      <>
        <div className="option-row pl-content thick">
          <Radiobutton
            id={data.id + "_yes_mock"}
            name={data.id + "_yes_mock"}
            value="on"
            checked={false}
            tabIndex={-1}
            label={tr("formYes")}
            readOnly
          />
          {this.props.predicateComponentCreator && (
            <div className="option-delete-container">
              <Button
                level="secondary"
                //                backIcon={<i className="fa fa-plus" />}
                className="flat thin"
                onClick={curry(
                  this.handleAddPredicate,
                  this.props.predicateComponentCreator,
                  preds.createMatchingYesNoPredicate,
                  { question: { id: data.id }, value: true }
                )}
                label={tr("addFollowUpQuestion")}
              />
            </div>
          )}
        </div>
        {this.props.predicateComponentCreator &&
          this.props.data.predicates &&
          yesMatches &&
          f.notEmpty(yesMatches) && (
            <div className="p-content predicates-box">
              <PredicatesComponent
                predicateComponentCreator={this.props.predicateComponentCreator}
                predicates={yesMatches}
                {...this.props}
              />
            </div>
          )}

        <div className="option-row pl-content thick">
          <Radiobutton
            id={data.id + "_no_mock"}
            name={data.id + "_no_mock"}
            value="on"
            checked={false}
            tabIndex={-1}
            label={tr("formNo")}
            readOnly
          />
          {this.props.predicateComponentCreator && (
            <div className="option-delete-container">
              <Button
                level="secondary"
                //                backIcon={<i className="fa fa-plus" />}
                className="flat thin"
                onClick={curry(
                  this.handleAddPredicate,
                  this.props.predicateComponentCreator,
                  preds.createMatchingYesNoPredicate,
                  { question: { id: data.id }, value: false }
                )}
                label={tr("addFollowUpQuestion")}
              />
            </div>
          )}
        </div>

        {this.props.predicateComponentCreator &&
          this.props.data.predicates &&
          noMatches &&
          f.notEmpty(noMatches) && (
            <div className="p-content predicates-box">
              <PredicatesComponent
                predicateComponentCreator={this.props.predicateComponentCreator}
                predicates={noMatches}
                {...this.props}
              />
            </div>
          )}
      </>
    );
  }

  private renderQuestion() {
    switch (this.props.data.type) {
      case gqltypes.FormQuestionType.multiChoice:
        return this.renderMultiChoice(this.props.data as any);
      case gqltypes.FormQuestionType.checkboxes:
        return this.renderCheckboxes(this.props.data as any);
      case gqltypes.FormQuestionType.ranking:
        return this.renderRanking(this.props.data as any);
      case gqltypes.FormQuestionType.shortText:
        return this.renderShortText(this.props.data as any);
      case gqltypes.FormQuestionType.yesOrNo:
        return this.renderYesOrNo(this.props.data as any);
      case gqltypes.FormQuestionType.longText:
      case gqltypes.FormQuestionType.date:
        return undefined;
      default:
        assertUnreachable(this.props.data.type);
    }
  }
  private getData() {
    const data = omit(
      this.props.data,
      "__typename"
    ) as gqltypes.FormQuestionWithOptionsUnion;
    if (definedNotNull(data.options)) {
      data.options = data.options.map((opts) => omit(opts, "__typename"));
      return data as gqltypes.FormQuestionWithOptionsUnion;
    }
    return data as gqltypes.FormQuestionWithoutOptionsUnion;
  }

  private getTranslationData() {
    const data = omit(this.props.translation!, "__typename");
    if (definedNotNull(data.options)) {
      data.options = data.options.map((opts) => omit(opts, "__typename"));
    }
    return data;
  }

  private handleAddOptionEventWithValue = (e: any) => {
    const value: string = e.currentTarget.value;

    const rows = value.split("\n").filter((v) => v.length > 0);

    if (rows.length === 1) {
      this.handleAddOption(value);
      return;
    }

    this.handleAddOptions(rows);
  };

  private handleAddOption = (value?: string) => {
    const data = this.getData() as gqltypes.FormQuestionWithOptionsUnion;
    const newOption = value
      ? makeDefaultOption(data.options.length + 1, { id: "", label: value })
      : makeDefaultOption(data.options.length);
    data.options = [...data.options, newOption];

    this.setState({ recentAddedOption: newOption.id });

    this.props.updateQuestion(data);
  };

  private handleAddOptions = (values: string[]) => {
    const data = this.getData() as gqltypes.FormQuestionWithOptionsUnion;

    values.forEach((val) => {
      const foo = val.split("\t");
      if (foo.length === 0) return;
      const label = foo[0];
      const externalId = foo.length > 1 ? foo[1] : null;

      const newOption = makeDefaultOption(data.options.length + 1, {
        id: "",
        label,
        externalId,
      });
      data.options = [...data.options, newOption];
    });

    this.setState({ recentAddedOption: undefined });

    this.props.updateQuestion(data);
  };

  private handleRemoveOption = (optionId: string) => {
    const data = this.getData() as gqltypes.FormQuestionWithOptionsUnion;
    data.options = data.options.filter((option) => option.id !== optionId);
    // Remove predicates attached to option
    if (data.predicates) {
      data.predicates = data.predicates.filter(
        (pred) =>
          preds.parseMatchingOptionPredicateJson(pred).preset.option.id !==
          optionId
      );
    }
    this.props.updateQuestion(data);
  };

  private handleClearOptions = async () => {
    const data = this.getData() as gqltypes.FormQuestionWithOptionsUnion;
    const confirmed = await showConfirmDialog({
      title: this.props.tr("clearOptions"),
      content: (
        <React.Fragment>
          <p>
            {this.props.tr(
              "formComponentQuestionEditorClearAllOptions",
              data.options.length,
              data.question
            )}
          </p>
          <p>
            {this.props.tr("formComponentQuestionEditorRemoveOptionWarning")}
          </p>
        </React.Fragment>
      ),
      proceedText: this.props.tr("remove"),
      cancelText: defaultDialogCancel(this.props.tr),
    });
    if (!confirmed) {
      return;
    }
    data.options = [];
    this.props.updateQuestion(data);
  };

  private handleMoveOption = (optionId: string, step: number) => {
    const data = this.getData() as gqltypes.FormQuestionWithOptionsUnion;
    const options = data.options;
    const index = options.findIndex((q) => q.id === optionId);
    if (index < 0) {
      throw new Error("Could not find index of option");
    }
    data.options = move(options, index, index + step);
    this.props.updateQuestion(data);
  };

  private handleUpdateShortName = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const value = event.currentTarget.value;
    const data = this.getData();
    if (typeof value === "string" && value.trim() !== "") {
      data.shortName = value;
    } else {
      delete data.shortName;
    }
    this.props.updateQuestion(data);
  };

  private handleUpdateTranslationShortName = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const value = event.currentTarget.value;
    const data = this.getTranslationData();
    if (typeof value === "string" && value.trim() !== "") {
      data.shortName = value;
    } else {
      data.shortName = null;
    }
    this.props.updateTranslation(data);
  };

  private handleAddPredicate = <T extends {}>(
    rest: Rest<
      gqltypes.FormPredicateComponent,
      gqltypes.FormPredicateComponentInput,
      gqltypes.TranslatePredicateComponent
    >,
    createPred: (data: T) => any,
    inData: T,
    _: React.MouseEvent<HTMLElement>
  ) => {
    const data = this.getData();

    const component = f.undefinedToDefault(
      f.throwWhenError(
        f.safePipe(
          data.predicates,
          (ps) => ps.find((pc) => preds.resolvePred(pc.predicate, inData)),
          (pc) => rest.get(pc.componentId)
        )
      ),
      rest.create
    );

    const question = makeQuestion({
      type: gqltypes.FormQuestionType.checkboxes,
    });

    component.questions = [...component.questions, question];

    rest.update(component.id, component);

    const predicates = data.predicates || [];

    const newPred: gqltypes.FormQuestionPredicate = {
      predicate: JSON.stringify(createPred(inData)),
      componentId: component.id,
    };

    if (
      !predicates.find(
        (p) =>
          p.predicate === newPred.predicate &&
          p.componentId === newPred.componentId
      )
    ) {
      data.predicates = [...(data.predicates || []), newPred];
    }

    this.props.updateQuestion(data);
  };

  private handleChangeQuestionType = (
    event: React.FormEvent<HTMLSelectElement>
  ) => {
    const value = event.currentTarget.value;
    const data = this.getData();
    data.type = value as any;

    if (data.type === "checkboxes") {
      data.compulsory = false;
    }

    if (data.type === "ranking") {
      const rankData = data as gqltypes.FormQuestionRanking;
      data.compulsory = rankData.minSelectedOptions
        ? rankData.minSelectedOptions > 0
        : false;
    }

    if (questionTypeHasOptions(data)) {
      if (!definedNotNull(data.options)) {
        data.options = definedNotNull(this.tempSavedOptions)
          ? this.tempSavedOptions
          : [makeDefaultOption(1)];
      }
    } else {
      if (definedNotNull((data as any).options)) {
        this.tempSavedOptions = (data as any).options;
        delete (data as any).options;
      }
    }

    data.predicates = undefined;

    this.props.updateQuestion(data);
  };

  private handleChangeQuestion = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    const data = this.getData();
    data.question = value;
    this.props.updateQuestion(data);
  };

  private handleChangeTranslationQuestion = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const value = event.currentTarget.value;
    const data = this.getTranslationData();
    data.question = value;
    this.props.updateTranslation(data);
  };

  private handleChangeCompulsory = (_: any, checked: boolean) => {
    const data = this.getData();
    const isRankingComponent = data.type === gqltypes.FormQuestionType.ranking;
    if (isRankingComponent) {
      const rankData = data as gqltypes.FormQuestionRanking;
      rankData.minSelectedOptions = checked ? 1 : null;
      data.compulsory = Boolean(rankData.minSelectedOptions);
    } else {
      data.compulsory = checked;
    }
    this.props.updateQuestion(data);
  };

  private updateOption = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    const id = event.currentTarget.id;
    const data = this.getData() as gqltypes.FormQuestionWithOptionsUnion;
    const updateIndex = findIndex(data.options, (option) => option.id === id);
    if (updateIndex === -1) {
      throw new Error("expected to find option of id " + id + " in array");
    }
    const editedItem = { ...data.options[updateIndex], label: value };
    data.options = replaceInArray(data.options, updateIndex, editedItem);
    this.props.updateQuestion(data);
  };

  private updateOptionExternalId = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const value = event.currentTarget.value;
    const id = event.currentTarget.id;
    const data = this.getData() as gqltypes.FormQuestionWithOptionsUnion;
    const updateIndex = findIndex(data.options, (option) => option.id === id);
    if (updateIndex === -1) {
      throw new Error("expected to find option of id " + id + " in array");
    }
    const editedItem = { ...data.options[updateIndex], externalId: value };
    data.options = replaceInArray(data.options, updateIndex, editedItem);
    this.props.updateQuestion(data);
  };

  private updateTranslationOption = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const value = event.currentTarget.value;
    const id = event.currentTarget.id;
    const data = this.getTranslationData();
    if (!definedNotNull(data)) {
      throw new Error("data missing for question");
    }
    if (!definedNotNull(data.options)) {
      //      throw new Error("Options not present on translation");
      data.options = (
        this.getData() as gqltypes.FormQuestionWithOptionsUnion
      ).options!.map((o) => ({
        id: o.id,
        label: "",
        __typename: "TranslateFormQuestionOptions",
      }));
    }
    const updateIndex = findIndex(data.options, (option) => option.id === id);
    if (updateIndex === -1) {
      const origin = this.getData() as gqltypes.FormQuestionWithOptionsUnion;
      data.options!.push({
        id,
        label: value,
        __typename: "TranslateFormQuestionOptions",
      });
    } else {
      const editedItem = { ...data.options![updateIndex], label: value };
      data.options = replaceInArray(data.options!, updateIndex, editedItem);
    }
    this.props.updateTranslation(data);
  };
}
