import _ from "lodash";
import * as React from "react";
import FlipMove from "react-flip-move";
import { Rest } from "../../Admin/components/CreateForm";
import { getComponentQuestions } from "../../Admin/form";
import { Translation } from "../../App/reducer";
import * as f from "../../Utils/functional";
import curry from "../../curry";
import * as gqltypes from "../../gqltypes";
import {
  CommonComponentProps,
  CommonFormEditorProps,
  FormComponentQuestionEditor,
  handleRemoveQuestion,
  handleUpdateQuestion,
  handleUpdateTranslateQuestion,
  moveQuestion,
  renderActionsContainer,
} from "..";

interface Props
  extends Translation,
    CommonFormEditorProps,
    CommonComponentProps<
      gqltypes.FormPredicateComponent,
      gqltypes.FormPredicateComponentInput
    > {
  predicateComponentCreator: Rest<
    gqltypes.FormPredicateComponent,
    gqltypes.FormPredicateComponentInput,
    gqltypes.TranslatePredicateComponent
  >;
  translation?:
    | NonNullable<
        gqltypes.FullTranslation["componentData"]["predicateComponents"]
      >[0]
    | null;
}

function getData(
  component: gqltypes.FormPredicateComponent
): gqltypes.FormPredicateComponentInput {
  return {
    id: component.id,
    questions: getComponentQuestions(component),
    permission: component.permission,
    sensitive: component.sensitive,
  };
}

const getTranslationData = (
  translation: NonNullable<
    gqltypes.FullTranslation["componentData"]["predicateComponents"]
  >[0]
): gqltypes.TranslatePredicateComponent => {
  return {
    id: translation.id,
    questions: _.cloneDeep(translation.questions),
  };
};

export const FormPredicateComponentEditor = (props: Props) => {
  const removeQuestion = (q: gqltypes.FormQuestionInput) =>
    handleRemoveQuestion(
      q,
      props.tr,
      getData(props.component),
      props.updateComponent
    );

  const updateQuestion = (updatedQuestion: gqltypes.FormQuestionInput) => {
    handleUpdateQuestion(
      getData(props.component),
      updatedQuestion.id,
      updatedQuestion,
      props.updateComponent
    );
  };

  return (
    <FlipMove
      duration={350}
      easing="ease-out"
      appearAnimation={"fade"}
      enterAnimation={"fade"}
      leaveAnimation={"fade"}
      maintainContainerHeight={true}
    >
      {f.map(f.get(props, "component", "questions"), (q, i) => {
        const hasOptions =
          q.type === gqltypes.FormQuestionType.checkboxes ||
          q.type === gqltypes.FormQuestionType.multiChoice ||
          q.type === gqltypes.FormQuestionType.ranking;

        const questionTranslation = (props.translation &&
          props.translation.questions &&
          props.translation.questions.find((tq) => tq.id === q.id)) || {
          id: q.id,
          question: "",
          shortName: "",
          options: hasOptions ? [] : null,
          __typename: "FormTranslateQuestion",
        };

        return (
          <FormComponentQuestionEditor
            {...props}
            translation={questionTranslation}
            key={q.id}
            data={q}
            actionsContainer={
              props.translating
                ? null
                : renderActionsContainer(
                    q,
                    i,
                    props.component.questions.length,
                    props.disabled,
                    curry(
                      moveQuestion,
                      getData(props.component),
                      props.updateComponent
                    ),
                    removeQuestion
                  )
            }
            updateTranslation={(updatedTranslation) => {
              if (!props.translation) {
                throw new Error("no translation");
              }

              handleUpdateTranslateQuestion(
                getTranslationData(props.translation),
                q.id,
                updatedTranslation,
                (data) =>
                  props.predicateComponentCreator.updateTranslation(
                    data,
                    props.translationLanguage!
                  )
              );
            }}
            updateQuestion={updateQuestion}
            removeQuestion={removeQuestion}
            predicateComponentCreator={undefined}
          />
        );
      })}
    </FlipMove>
  );
};
