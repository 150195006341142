import gql from "graphql-tag";
import _, { head, last } from "lodash";
import React from "react";
import { ApolloProvider, MutationFunction, QueryResult } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import { graphql, DataValue } from "@apollo/client/react/hoc";
import { NavigateFunction, RouterProps } from "react-router";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";
import { Collapse } from "reactstrap";
import {
  AdminContext,
  Translation,
  withAdminContext,
  withTranslation,
} from "../../App/reducer";
import {
  Button,
  Checkbox,
  FormTextArea,
  ISTContainer,
  Loading,
  Table,
} from "../../Common";
import { ClipboardCopyButton } from "../../Common/components/ClipboardCopyButton";
import { EmailPreview } from "../../Common/components/EmailPreview";
import { MutationButton } from "../../Common/components/MutationButton";
import { Time } from "../../Common/components/Time";
import { NameWithPotentialLink, WrapLink } from "../../Common/components/Utils";
import { getUserInfoUrl } from "../../Common/utils";
import {
  showAlertDialog,
  showConfirmDialog,
} from "../../Utils/dialogs/showDialog";
import { definedNotNull } from "../../Utils/functional";
import { client } from "../../api";
import * as gqltypes from "../../gqltypes";
import {
  defaultDialogCancel,
  defaultDialogProceed,
  getEmailTemplateStrings,
} from "../../translation/strings";
import { Progress } from "../../types";
import { links } from "../links";
import { SchoolYears } from "./ClassCheckboxArray";
import { PublicationApplicationMetaInfo } from "./PublicationMetaInfo";
import withRouter from "../../Utils/withRouter";

const tableRowStyleMap = {
  added: "info",
  removed: "danger",
  changed: "success",
};

const publicationUserToRowData = (
  row: gqltypes.RecipientUpdatePublicationUser,
  status: "added" | "removed" | "changed",
  groupMap: Map<
    string,
    gqltypes.orgData["organisation"]["publications"][0]["recipients"]["groups"]["edges"][0]
  >,
  tr: Translation["tr"]
) => {
  return {
    userId: row.user ? row.user.id : null,
    customerId: row.user ? row.user.customerId : null,
    userName: row.user ? row.user.name : <i>{tr("failedToFetchUser")}</i>,
    status,
    groups: row.groupIds
      .map((group) =>
        groupMap.get(group)
          ? groupMap.get(group)!.displayName
          : tr("viewPublicationsGroupNotFoundPlaceholder")
      )
      .join(", "),
  };
};

const ChangedMembersTable = (props: {
  tr: Translation["tr"];
  recipientUpdateChanges: gqltypes.ChangedRecipients["publication"]["recipientUpdateChanges"];
  groupMap: Map<
    string,
    gqltypes.orgData["organisation"]["publications"][0]["recipients"]["groups"]["edges"][0]
  >;
  updateList: () => void;
  updatingList: boolean;
  style?: any;
}) => {
  const { tr } = props;
  const tableRowStatusNameMap = {
    added: tr("viewPublicationChangedMembersAdded"),
    removed: tr("viewPublicationChangedMembersRemoved"),
    changed: tr("viewPublicationChangedMembersChanged"),
  };

  const tableData = _.orderBy(
    props.recipientUpdateChanges.added
      .map((row) => publicationUserToRowData(row, "added", props.groupMap, tr))
      .concat(
        props.recipientUpdateChanges.removed.map((row) =>
          publicationUserToRowData(row, "removed", props.groupMap, tr)
        )
      )
      .concat(
        props.recipientUpdateChanges.changed.map((row) =>
          publicationUserToRowData(row, "changed", props.groupMap, tr)
        )
      ),
    ["status", "groups", "userName"]
  );

  if (tableData.length === 0) {
    return (
      <div className="alert alert-warning">
        {tr("viewPublicationChangedMembersNoChanges")}
      </div>
    );
  }
  return (
    <React.Fragment>
      <div style={props.style}>
        <table
          className={`table ${
            tableData.length >= 10 ? "table-sticky-header" : ""
          }`}
        >
          <thead>
            <tr>
              <th scope="col">
                {tr("viewPublicationChangedMembersNameColumn")}
              </th>
              <th scope="col">
                {tr("viewPublicationChangedMembersClassesColumn")}
              </th>
              <th scope="col">
                {tr("viewPublicationChangedMembersStatusColumn")}
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, i) => (
              <tr
                key={row.userId || i}
                className={"table-" + tableRowStyleMap[row.status]}
              >
                <td>
                  <NameWithPotentialLink
                    name={row.userName}
                    user={
                      row.userId
                        ? {
                            id: row.userId,
                            source: gqltypes.UserSource.educloud,
                            customerId: row.customerId,
                          }
                        : null
                    }
                  />
                </td>
                <td>{row.groups}</td>
                <td>{tableRowStatusNameMap[row.status]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mb-3 ml-2 mt-3">
        <Button
          className="button-transparent-rounded-big"
          disabled={props.updatingList}
          label={
            props.updatingList
              ? tr("viewPublicationPerformingChanges")
              : tr("viewPublicationPerformChanges")
          }
          onClick={props.updateList}
        />
      </div>
    </React.Fragment>
  );
};

const FILTERED_NOTIFICATION_QUERY = gql`
  query FilteredNotificationQuery($id: ID!) {
    publication(id: $id) {
      id
      notifications(filter: undeliverable) {
        filteredByPermissions
        edges {
          recipients {
            user {
              id
              customerId
              name
            }
            available
          }
          child {
            id
            customerId
            name
          }
          childGroupIds
        }
      }
    }
  }
`;

const PENDING_RECIPIENTS_QUERY = gql`
  query NonRespondents($id: ID!) {
    publication(id: $id) {
      id
      nonRespondents {
        filteredByPermissions
        edges {
          groupIds
          student {
            id
            customerId
            name
          }
        }
      }
    }
  }
`;

const recipientUpdatePublicationUserFragment = gql`
  fragment RecipientUpdatePublicationUser on PublicationUser {
    groupIds
    manuallyAdded
    excludedFromPublication
    user {
      id
      customerId
      name
    }
  }
`;

const PROMPT_UPDATE_UNCONTACTABLE_MUTATION = gql`
  mutation PromptUpdateUncontactable($id: ID!, $context: Context!) {
    promptUpdateUncontactable(id: $id, context: $context) {
      code
      message
    }
  }
`;

const CHANGED_RECIPIENTS_QUERY = gql`
  query ChangedRecipients($id: ID!) {
    publication(id: $id) {
      id
      recipientUpdateChanges {
        added {
          ...RecipientUpdatePublicationUser
        }
        removed {
          ...RecipientUpdatePublicationUser
        }
        changed {
          ...RecipientUpdatePublicationUser
        }
      }
    }
  }
  ${recipientUpdatePublicationUserFragment}
`;

interface Props extends RouterProps, AdminContext, QueryResponse, Translation {
  deletePublication: MutationFunction<
    gqltypes.DeletePublication,
    gqltypes.DeletePublicationVariables
  >;
  navigate: NavigateFunction;
  params: { id: string };
}

enum MutationStatus {
  IDLE,
  WORKING,
}

interface State {
  selectedPublicationId?: string;
  sendUnsentSuccess?: string;
  sendUnsentProgress: Progress;
  showGroups: boolean;
  showPendingRespondents: boolean;
  showUncontactable: boolean;
  showRecipientUpdates: boolean;
  showDangerZone: boolean;
  remindedPublications: Set<string>;
  remindingPublicationStatus: MutationStatus;
  updatePublicationRecipientListStatus: MutationStatus;
  dryranPublications: Set<string>;
  dryrunningPublicationStatus: MutationStatus;
  revokeConfirmCheckbox: boolean;
  revokeReason: string;
  showUpdatesToRecipientListExpandable: boolean;
  showTotalRecipient: number;
  updatingPublicationsList: Set<string>;
}
class ViewPublications extends React.Component<Props, State> {
  private undeliverablesTable: React.RefObject<HTMLTableElement>;
  private nonRespondentsTable: React.RefObject<HTMLTableElement>;
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedPublicationId: undefined,
      sendUnsentProgress: Progress.NotStarted,
      showGroups: false,
      showPendingRespondents: false,
      showUncontactable: false,
      showRecipientUpdates: false,
      showDangerZone: false,
      showUpdatesToRecipientListExpandable: false,
      revokeReason: "",
      revokeConfirmCheckbox: false,
      remindedPublications: new Set(),
      remindingPublicationStatus: MutationStatus.IDLE,
      updatePublicationRecipientListStatus: MutationStatus.IDLE,
      dryranPublications: new Set(),
      dryrunningPublicationStatus: MutationStatus.IDLE,
      updatingPublicationsList: new Set(),
      showTotalRecipient: 0,
    };
    this.undeliverablesTable = React.createRef();
    this.nonRespondentsTable = React.createRef();
    props.orgData.refetch();
  }

  public componentDidMount() {
    if (this.props.params.id !== undefined) {
      this.handleSelectPublication(this.props.params.id);
    }
  }

  public render() {
    const { tr } = this.props;
    const publications = this.props.orgData.organisation
      ? this.props.orgData.organisation.publications
      : null;
    const selectedPublication =
      publications && this.state.selectedPublicationId
        ? publications.find((p) => p.id === this.state.selectedPublicationId)
        : undefined;

    const groups = selectedPublication
      ? _.orderBy(selectedPublication.recipients.groups.edges, "displayName")
      : [];

    const selectedPubId = selectedPublication
      ? selectedPublication.id
      : undefined;

    const groupMap = new Map<
      string,
      gqltypes.orgData["organisation"]["publications"][0]["recipients"]["groups"]["edges"][0]
    >();
    if (selectedPublication) {
      selectedPublication.recipients.groups.edges.forEach((group) => {
        groupMap.set(group.id, group);
      });
    }

    const newDate = new Date().getTime();
    const canSendReminder =
      selectedPublication && selectedPublication.lastReminderSent
        ? newDate - new Date(selectedPublication.lastReminderSent).getTime() >=
          1000 * 3600 * 20
        : selectedPublication?.sendDate
        ? newDate - new Date(selectedPublication.sendDate).getTime() >=
          1000 * 3600 * 20
        : false;

    const hasUpdatesToRecipientList = !selectedPublication
      ? false
      : selectedPublication.recipientUpdateChanges.addedCount +
          selectedPublication.recipientUpdateChanges.removedCount +
          selectedPublication.recipientUpdateChanges.changedCount !==
        0;
    const canPrompyDryrun =
      selectedPublication && selectedPublication.lastDryrun
        ? new Date().getTime() -
            new Date(selectedPublication.lastDryrun).getTime() >=
          1000 * 3600 * 5
        : true;

    const displayGroupName = (id: string) => {
      const group = groupMap.get(id);
      return group ? group.displayName : tr("viewPublicationsUnknownClass");
    };

    let showTotalRecipient;
    if (selectedPublication && selectedPublication.recipientUpdateChanges) {
      showTotalRecipient =
        selectedPublication?.recipientUpdateChanges.changedCount +
        selectedPublication?.recipientUpdateChanges.addedCount +
        selectedPublication?.recipientUpdateChanges.removedCount;
    }

    return (
      <React.Fragment>
        <h1>{tr("viewPublicationsTitle")}</h1>
        <p className="col-12 col-md-9 p-0 pb-3">
          {tr("viewPublicationsDescription")}
        </p>
        <ISTContainer header={tr("viewPublicationsHeader")}>
          {this.props.orgData.loading && !publications ? (
            <Loading />
          ) : !publications || publications.length === 0 ? (
            <div className="alert alert-info m-content">
              {tr("viewPublicationsNoPublicationsAvailable")}
            </div>
          ) : (
            <Table
              clickableRows
              noEndLine
              initialOrder="publicationDate"
              headers={[
                {
                  key: "warning",
                  element: null,
                  unsortable: true,
                },
                {
                  key: "publicationName",
                  element: tr("viewPublicationsColumnPublicationName"),
                },
                {
                  key: "formName",
                  element: tr("viewPublicationsColumnFormName"),
                },
                {
                  key: "publicationDate",
                  element: tr("viewPublicationsColumnPublicationDate"),
                  usingSortValue: true,
                },
                {
                  key: "classesCount",
                  element: tr("viewPublicationsColumnGroupCount"),
                },
                {
                  key: "recipientCount",
                  element: tr("viewPublicationsColumnRecipientCount"),
                },
                {
                  key: "uncontactableCount",
                  element: tr("viewPublicationsColumnUncontactableCount"),
                  usingSortValue: true,
                },
                {
                  key: "answerCount",
                  element: tr("viewPublicationsColumnAnswerCount"),
                },
                {
                  key: "remove",
                  element: null,
                  unsortable: true,
                },
              ]}
              rows={publications.map((publication) => {
                const updateChanges = Object.values(
                  publication.recipientUpdateChanges
                ).reduce(
                  (sum, item) => (typeof item === "number" ? sum + item : sum),
                  0
                );
                const requiresAttention = updateChanges > 0;
                return {
                  key: publication.id,
                  onClick: () => {
                    if (this.state.revokeReason !== "") {
                      this.setState({
                        revokeReason: "",
                      });
                    }
                    if (this.state.revokeConfirmCheckbox !== false) {
                      this.setState({
                        revokeConfirmCheckbox:
                          !this.state.revokeConfirmCheckbox,
                      });
                    }
                    this.props.navigate(
                      links.admin.publication.listAfterSave(publication.id)
                    );
                    this.handleSelectPublication(publication.id);
                  },
                  selected: selectedPubId === publication.id,
                  columns: {
                    warning: {
                      content: requiresAttention ? (
                        <i className="fas fa-exclamation-triangle text-warning" />
                      ) : null,
                    },
                    publicationName: { content: publication.name },
                    formName: { content: publication.form.name },
                    publicationDate: {
                      content: <Time date={publication.sendDate} />,
                      sortValue: publication.sendDate,
                    },
                    classesCount: { content: publication.stats.groups },
                    recipientCount: { content: publication.stats.total },
                    uncontactableCount: {
                      content: (
                        <span className="text-danger">
                          {publication.stats.undeliverableNotifications}
                        </span>
                      ),
                      sortValue: publication.stats.undeliverableNotifications,
                    },
                    answerCount: { content: publication.stats.answered },
                    remove: {
                      content: !publication.sent ? (
                        <div
                          aria-label={tr(
                            "viewPublicationsRemovePublication",
                            publication.name
                          )}
                          onClick={() =>
                            this.handleRemovePublication(publication)
                          }
                        >
                          <i aria-hidden className="fas fa-trash icon-button" />
                          <span className="sr-only">
                            {tr(
                              "viewPublicationsRemovePublicationSr",
                              publication.name
                            )}
                          </span>
                        </div>
                      ) : null,
                    },
                  },
                };
              })}
            />
          )}
        </ISTContainer>
        {definedNotNull(this.state.selectedPublicationId) &&
          selectedPublication && (
            <ISTContainer
              id="manage-reminders"
              key="manage-reminders"
              header={selectedPublication.name}
            >
              <section>
                <PublicationApplicationMetaInfo
                  style={{ marginBottom: "0" }}
                  publication={selectedPublication}
                  fields={[
                    "created",
                    "creator",
                    "lastNotified",
                    "sendDate",
                    "validFrom",
                    "validTo",
                  ]}
                />
              </section>

              <section className="container-fluid publication-section-style">
                <div
                  className="row h-100"
                  style={
                    this.state.showGroups
                      ? { background: "var(--blue-xx-light)" }
                      : { background: "var(--light)" }
                  }
                >
                  <div
                    className="col-md-12 col-sm-12 my-auto d-flex justify-content-between clickable"
                    onClick={() => {
                      this.setState((state) => ({
                        showGroups: !state.showGroups,
                      }));
                    }}
                    style={{ height: "45px" }}
                  >
                    <h4
                      className="my-auto text-truncate"
                      style={
                        this.state.showGroups
                          ? { fontWeight: "bold" }
                          : { fontWeight: "normal" }
                      }
                    >
                      {tr("viewPublicationsGroupsTitle")}
                      <span className="ml-1 my-auto span-text">{`(${groups.length})`}</span>
                      <i
                        className={`fas fa-caret-${
                          this.state.showGroups ? "up" : "down"
                        } ml-1`}
                      />
                    </h4>
                    <Button
                      className={`${
                        this.state.showGroups
                          ? "button-transparent-rounded-onclick"
                          : "button-transparent-rounded"
                      } my-auto`}
                      label={tr("viewPublicationEditPublicationButtonLabel")}
                      level="primary"
                      onClick={() => {
                        this.handleEditPublication(selectedPublication.id);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12 pl-0 pr-0 h-100 bg-white">
                    <Collapse
                      isOpen={this.state.showGroups}
                      className="bg-white mt-2"
                    >
                      <div style={{ maxHeight: "50vh", overflowX: "auto" }}>
                        <table
                          className={`table ${
                            groups.length >= 10 ? "table-sticky-header" : ""
                          } table-last-row`}
                        >
                          <thead>
                            <tr>
                              <th scope="col">
                                {tr("viewPublicationGroupColumnName")}
                              </th>
                              <th scope="col">
                                {tr("viewPublicationGroupColumnStudents")}
                              </th>
                              <th scope="col">
                                {tr("viewPublicationGroupColumnStatus")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {groups.map((group) => (
                              <tr key={group.id}>
                                <td>
                                  {group.displayName}
                                  {group.schoolYearFilter && (
                                    <SchoolYears
                                      tr={tr}
                                      schoolYearFilter={group.schoolYearFilter}
                                    />
                                  )}
                                </td>
                                <td>{group.memberCount}</td>
                                <td>
                                  {group.removed
                                    ? tr("viewPublicationGroupStatusRemoved")
                                    : tr(
                                        "viewPublicationGroupStatusNotRemoved"
                                      )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </section>

              <section className="container-fluid publication-section-style">
                <div
                  className="row h-100"
                  style={
                    this.state.showUncontactable
                      ? { background: "var(--blue-xx-light)" }
                      : { background: "var(--light)" }
                  }
                >
                  <div
                    className="col-md-12 col-sm-12 d-flex justify-content-between clickable"
                    onClick={() => {
                      this.setState({
                        showUncontactable: !this.state.showUncontactable,
                      });
                    }}
                    style={{ height: "45px" }}
                  >
                    <h4
                      className="my-auto"
                      style={
                        this.state.showUncontactable
                          ? { fontWeight: "bold" }
                          : { fontWeight: "normal" }
                      }
                    >
                      {tr("viewPublicationUncontactableGuardians")}
                      <span className="ml-1 my-auto span-text">{`(${selectedPublication.stats.undeliverableNotifications})`}</span>
                      <i
                        className={`fas fa-caret-${
                          this.state.showUncontactable ? "up" : "down"
                        } ml-1`}
                      />
                    </h4>
                    <small
                      className="my-auto text-truncate"
                      style={
                        this.state.showUncontactable
                          ? { fontWeight: "bold", color: "#007e94" }
                          : { fontWeight: "normal" }
                      }
                    >
                      <span className="mr-1">{tr("updatedRelativeTime")}</span>
                      <Time
                        date={selectedPublication.lastDryrun}
                        type="relative"
                      />
                      .
                    </small>
                  </div>
                  <div className="row bg-white ml-0 mr-0 w-100">
                    {this.state.showUncontactable && (
                      <>
                        <div className="col-12 col-md-12 col-sd-12 d-flex flex-row p-content">
                          <MutationButton<
                            gqltypes.PromptUpdateUncontactable,
                            gqltypes.PromptUpdateUncontactableVariables
                          >
                            className="button-transparent-rounded my-auto"
                            label={tr("update")}
                            mutation={PROMPT_UPDATE_UNCONTACTABLE_MUTATION}
                            variables={{
                              id: selectedPublication.id,
                              context: this.props.adminContext,
                            }}
                            disabled={
                              !canPrompyDryrun ||
                              this.state.updatingPublicationsList.has(
                                selectedPublication.id
                              )
                            }
                            onClick={() => {
                              const newUpdatedPublicationList = new Set(
                                this.state.updatingPublicationsList
                              );
                              newUpdatedPublicationList.add(
                                selectedPublication.id
                              );
                              this.setState({
                                updatingPublicationsList:
                                  newUpdatedPublicationList,
                              });
                            }}
                            onSuccess={() => {
                              this.setState((state) => {
                                const newDryranPublications = new Set(
                                  state.dryranPublications
                                );
                                newDryranPublications.add(
                                  selectedPublication.id
                                );
                                return {
                                  dryranPublications: newDryranPublications,
                                };
                              });
                            }}
                          />
                          {!canPrompyDryrun ||
                          this.state.updatingPublicationsList.has(
                            selectedPublication.id
                          ) ? (
                            <>
                              <p className="ml-2 text-center mt-3">
                                {tr(
                                  "viewPublicationsUnreachableRecipentUpdateInProgress"
                                )}
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="ml-2 text-center mt-3">
                                {tr(
                                  "viewPublicationsUnreachableRecipentUpdateLimit"
                                )}
                              </p>
                            </>
                          )}
                        </div>
                        <div className="col-md-12 col-sm-12 pl-0 pr-0">
                          <Collapse
                            isOpen={this.state.showUncontactable}
                            className="bg-white"
                          >
                            <p className="col-12 col-md-12 p-content">
                              {tr(
                                "viewPublicationsMissingContactInfoDescription"
                              )}
                            </p>
                            <Query<
                              gqltypes.FilteredNotificationQuery,
                              gqltypes.FilteredNotificationQueryVariables
                            >
                              query={FILTERED_NOTIFICATION_QUERY}
                              variables={{ id: selectedPublication.id }}
                              skip={!this.state.showUncontactable}
                              fetchPolicy="cache-first"
                            >
                              {({ loading, error, data }) => {
                                if (loading) {
                                  return <Loading />;
                                }
                                if (error) {
                                  return (
                                    <div className="alert alert-warning m-content">
                                      {tr("failedToFetchData")}
                                    </div>
                                  );
                                }

                                const undeliverables = data
                                  ? _.orderBy(
                                      data.publication.notifications.edges.map(
                                        (row) => ({
                                          ...row,
                                          groups: row.childGroupIds.map(
                                            (groupId) =>
                                              groupMap.get(groupId)
                                                ? groupMap.get(groupId)!
                                                    .displayName
                                                : tr("unknownGroup")
                                          ),
                                        })
                                      ),
                                      ["groups", "child.name"]
                                    )
                                  : [];

                                const missingCount =
                                  selectedPublication.stats
                                    .undeliverableNotifications -
                                  undeliverables.length;
                                const undeliverableCount =
                                  selectedPublication.stats
                                    .undeliverableNotifications;

                                return (
                                  <React.Fragment>
                                    {data &&
                                    undeliverables.length !==
                                      selectedPublication.stats
                                        .undeliverableNotifications ? (
                                      <div className="alert alert-warning m-content">
                                        {data.publication.notifications
                                          .filteredByPermissions
                                          ? tr(
                                              "viewPublicationsNotAllUndeliverablesFilteredByPermissions",
                                              undeliverables.length
                                            )
                                          : tr(
                                              "viewPublicationsNotAllUndeliverablesMissingPeople",
                                              missingCount,
                                              undeliverables.length,
                                              undeliverableCount
                                            )}
                                      </div>
                                    ) : null}
                                    {undeliverables.length > 0 && (
                                      <div className="mx-3 mb-4">
                                        <ClipboardCopyButton
                                          getElementToCopy={() =>
                                            this.undeliverablesTable.current
                                          }
                                          tooltipText={tr(
                                            "copyToClipboardTooltip"
                                          )}
                                        />
                                      </div>
                                    )}
                                    <div
                                      style={{
                                        maxHeight: "50vh",
                                        overflowX: "auto",
                                      }}
                                    >
                                      <table
                                        id="undeliverables-table"
                                        ref={this.undeliverablesTable}
                                        className={`table ${
                                          undeliverables.length >= 10
                                            ? "table-sticky-header"
                                            : ""
                                        } table-last-row`}
                                      >
                                        <thead>
                                          <tr>
                                            <th scope="col">
                                              {tr(
                                                "viewPublicationsUncontactableColumnStudent"
                                              )}
                                            </th>
                                            <th scope="col">
                                              {tr(
                                                "viewPublicationsUncontactableColumnClass"
                                              )}
                                            </th>
                                            <th scope="col">
                                              {tr(
                                                "viewPublicationsUncontactableColumnRecipient"
                                              )}
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {undeliverables.map(
                                            (undeliverable, i) => (
                                              <tr
                                                key={
                                                  undeliverable.child
                                                    ? undeliverable.child.id
                                                    : i
                                                }
                                              >
                                                <td>
                                                  {undeliverable.child ? (
                                                    <Link
                                                      to={getUserInfoUrl({
                                                        id: undeliverable.child
                                                          .id,
                                                        source:
                                                          gqltypes.UserSource
                                                            .educloud,
                                                        customerId:
                                                          undeliverable.child
                                                            .customerId,
                                                      })}
                                                    >
                                                      {undeliverable.child.name}
                                                    </Link>
                                                  ) : (
                                                    <i className="text-danger">
                                                      {tr(
                                                        "failedToFetchPerson"
                                                      )}
                                                    </i>
                                                  )}
                                                </td>
                                                <td>
                                                  <React.Fragment>
                                                    {undeliverable.groups.map(
                                                      (name, j) => (
                                                        <span key={j}>
                                                          {name} <br />
                                                        </span>
                                                      )
                                                    )}
                                                  </React.Fragment>
                                                </td>
                                                <td>
                                                  {undeliverable.recipients
                                                    .length ? (
                                                    undeliverable.recipients.map(
                                                      (info, j) => {
                                                        if (!info) {
                                                          return null;
                                                        }

                                                        const name = info.user
                                                          ? info.user.name
                                                          : tr(
                                                              "failedToFetchPerson"
                                                            );
                                                        const available =
                                                          info.available
                                                            .length === 0;
                                                        const first = j === 0;

                                                        return (
                                                          <span
                                                            key={
                                                              info.user
                                                                ? info.user.id
                                                                : j
                                                            }
                                                            className={
                                                              (available
                                                                ? "text-danger"
                                                                : "text-muted") +
                                                              (first
                                                                ? ""
                                                                : " ml-content")
                                                            }
                                                          >
                                                            {
                                                              <WrapLink
                                                                use={Boolean(
                                                                  info.user
                                                                )}
                                                                url={getUserInfoUrl(
                                                                  info.user
                                                                    ? {
                                                                        id: info
                                                                          .user
                                                                          .id,
                                                                        source:
                                                                          gqltypes
                                                                            .UserSource
                                                                            .educloud,
                                                                        customerId:
                                                                          info
                                                                            .user
                                                                            .customerId,
                                                                      }
                                                                    : null
                                                                )}
                                                                style={{
                                                                  color:
                                                                    "inherit",
                                                                }}
                                                              >
                                                                {first
                                                                  ? ""
                                                                  : " "}
                                                                {name}
                                                              </WrapLink>
                                                            }
                                                          </span>
                                                        );
                                                      }
                                                    )
                                                  ) : (
                                                    <i>
                                                      {tr(
                                                        "missingInfoFromSourceSystem"
                                                      )}
                                                    </i>
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </React.Fragment>
                                );
                              }}
                            </Query>
                          </Collapse>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </section>

              <section className="container-fluid publication-section-style">
                <div
                  className="row h-100"
                  style={
                    this.state.showPendingRespondents
                      ? { background: "var(--blue-xx-light)" }
                      : { background: "var(--light)" }
                  }
                >
                  <div
                    className="col-md-12 col-sm-12 d-flex justify-content-between clickable"
                    onClick={() => {
                      this.setState({
                        showPendingRespondents:
                          !this.state.showPendingRespondents,
                      });
                    }}
                    style={{ height: "45px" }}
                  >
                    <h4
                      className="my-auto"
                      style={
                        this.state.showPendingRespondents
                          ? { fontWeight: "bold" }
                          : { fontWeight: "normal" }
                      }
                    >
                      {tr("viewPublicationsReminderTitle")}
                      <span className="my-auto span-text">
                        {" "}
                        {`(${selectedPublication.stats.unanswered}
                       ${tr("viewPublicationReminderMissingAnswers")})`}
                      </span>
                      <i
                        className={`fas fa-caret-${
                          this.state.showPendingRespondents ? "up" : "down"
                        } ml-1`}
                      />
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12 pl-0 pr-0 bg-white">
                    {this.state.showPendingRespondents && (
                      <>
                        <div className="d-flex">
                          <div className="col-md-3 p-content">
                            <Button
                              className={`${
                                this.state.remindingPublicationStatus ===
                                MutationStatus.WORKING
                                  ? "button-transparent-rounded-bigger"
                                  : "button-transparent-rounded-big"
                              }`}
                              type="button"
                              level="secondary"
                              label={
                                this.state.remindingPublicationStatus ===
                                MutationStatus.WORKING
                                  ? tr("viewPublicationsSendingReminderLabel")
                                  : tr("viewPublicationsSendReminderLabel")
                              }
                              disabled={
                                this.state.remindingPublicationStatus ===
                                  MutationStatus.WORKING ||
                                this.state.remindedPublications.has(
                                  selectedPublication.id
                                ) ||
                                !canSendReminder
                              }
                              onClick={() =>
                                this.remindPublication(selectedPublication.id)
                              }
                            />
                          </div>
                          <div className="col-md-9">
                            {!canSendReminder && (
                              <p className="p-content">
                                {tr("viewPublicationsReminderTooSoon")}
                              </p>
                            )}

                            {this.state.remindedPublications.has(
                              selectedPublication.id
                            ) && (
                              <p className="mt-content">
                                {tr("viewPublicationsReminderAllReminded")}
                              </p>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    <Collapse isOpen={this.state.showPendingRespondents}>
                      <Query<
                        gqltypes.NonRespondents,
                        gqltypes.NonRespondentsVariables
                      >
                        query={PENDING_RECIPIENTS_QUERY}
                        variables={{ id: selectedPublication.id }}
                        skip={!this.state.showPendingRespondents}
                        fetchPolicy="cache-first"
                      >
                        {({ loading, error, data }) => {
                          if (loading) {
                            return <Loading />;
                          }
                          if (error) {
                            return (
                              <div className="alert alert-warning m-content">
                                {tr("failedToFetch")}
                              </div>
                            );
                          }
                          if (!data || !data.publication) {
                            return (
                              <div className="alert alert-warning m-content">
                                {tr("noDataAvailable")}
                              </div>
                            );
                          }

                          const fetchCount =
                            data.publication.nonRespondents.edges.length;
                          const missingChildCount =
                            selectedPublication.stats.unanswered - fetchCount;

                          const people = data.publication.nonRespondents.edges
                            .map((respondent) => {
                              const student = respondent.student;
                              return {
                                student,
                                groupNames:
                                  respondent.groupIds.map(displayGroupName),
                              };
                            })
                            .sort((a, b) =>
                              a.groupNames > b.groupNames ? 1 : -1
                            );
                          return (
                            <React.Fragment>
                              {missingChildCount !== 0 && (
                                <div className="alert alert-warning m-content">
                                  {data.publication.nonRespondents
                                    .filteredByPermissions
                                    ? tr(
                                        "viewPublicationsFailedToFetchNStudentsNotPermissions",
                                        fetchCount
                                      )
                                    : tr(
                                        "viewPublicationsFailedToFetchNStudents",
                                        missingChildCount,
                                        selectedPublication.stats.unanswered,
                                        fetchCount
                                      )}
                                </div>
                              )}
                              {fetchCount !== 0 && (
                                <React.Fragment>
                                  <div className="mx-4 mb-4">
                                    <ClipboardCopyButton
                                      getElementToCopy={() =>
                                        this.nonRespondentsTable.current
                                      }
                                      tooltipText={tr("copyToClipboardTooltip")}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      maxHeight: "50vh",
                                      overflowX: "auto",
                                    }}
                                  >
                                    <table
                                      className={`table ${
                                        people.length >= 10
                                          ? "table-sticky-header"
                                          : ""
                                      } table-last-row`}
                                      ref={this.nonRespondentsTable}
                                    >
                                      <thead>
                                        <tr>
                                          <th scope="col">
                                            {tr(
                                              "viewPublicationsRemindColumnStudent"
                                            )}
                                          </th>
                                          <th scope="col">
                                            {tr(
                                              "viewPublicationsRemindColumnGroup"
                                            )}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {_.orderBy(people, [
                                          "groupNames",
                                          "student.name",
                                        ]).map((entry, i) => {
                                          const userId = entry.student
                                            ? entry.student.id
                                            : `missingUser_${i}`;
                                          const userName = entry.student ? (
                                            entry.student.name
                                          ) : (
                                            <i>{tr("failedToFetchUser")}</i>
                                          );
                                          return (
                                            <tr key={userId}>
                                              <td>
                                                <WrapLink
                                                  use={Boolean(entry.student)}
                                                  url={getUserInfoUrl(
                                                    entry.student
                                                      ? {
                                                          id: entry.student.id,
                                                          source:
                                                            gqltypes.UserSource
                                                              .educloud,
                                                          customerId:
                                                            entry.student
                                                              .customerId,
                                                        }
                                                      : null
                                                  )}
                                                >
                                                  {userName}
                                                </WrapLink>
                                              </td>
                                              <td>
                                                <React.Fragment>
                                                  {entry.groupNames.map(
                                                    (name, j) => (
                                                      <span key={j}>
                                                        {name} <br />
                                                      </span>
                                                    )
                                                  )}
                                                </React.Fragment>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          );
                        }}
                      </Query>
                    </Collapse>
                  </div>
                </div>
              </section>

              <section className="container-fluid publication-section-style">
                <div
                  className="row h-100"
                  style={
                    this.state.showUpdatesToRecipientListExpandable
                      ? { background: "var(--blue-xx-light)" }
                      : { background: "var(--light)" }
                  }
                >
                  <div
                    className="col-md-12 col-sm-12 my-auto d-flex justify-content-betweem clickable"
                    onClick={() =>
                      this.setState((state) => ({
                        showUpdatesToRecipientListExpandable:
                          !state.showUpdatesToRecipientListExpandable,
                        showRecipientUpdates: !state.showRecipientUpdates,
                      }))
                    }
                    style={{ height: "45px" }}
                  >
                    <h4
                      className="my-auto"
                      style={
                        this.state.showUpdatesToRecipientListExpandable
                          ? { fontWeight: "bold" }
                          : { fontWeight: "normal" }
                      }
                    >
                      {tr("viewPublicationsRecipientChangesTitle")}
                      <span className="ml-1 my-auto span-text">{`(${showTotalRecipient})`}</span>
                      <i
                        className={`fas fa-caret-${
                          this.state.showUpdatesToRecipientListExpandable
                            ? "up"
                            : "down"
                        } ml-1`}
                      />
                    </h4>
                  </div>
                  <div className="col-md-12 col-sm-12 bg-white">
                    {this.state.showUpdatesToRecipientListExpandable && (
                      <React.Fragment>
                        {showTotalRecipient && showTotalRecipient > 0 ? (
                          <>
                            <p className="pt-3">
                              <strong className="mr-1">
                                {
                                  selectedPublication.recipientUpdateChanges
                                    .addedCount
                                }
                              </strong>
                              {tr(
                                "viewPublicationsRecipientChangesMissingPeople"
                              )}
                              <br />
                              <strong className="mr-1">
                                {
                                  selectedPublication.recipientUpdateChanges
                                    .removedCount
                                }
                              </strong>
                              {tr(
                                "viewPublicationsRecipientChangesRemovedPeople"
                              )}
                              <br />
                              <strong className="mr-1">
                                {
                                  selectedPublication.recipientUpdateChanges
                                    .changedCount
                                }
                              </strong>
                              {tr(
                                "viewPublicationsRecipientChangesChangedPeople"
                              )}
                            </p>
                            <p>
                              {tr(
                                "viewPublicationsRecipientChangesDescription"
                              )}
                            </p>
                            {this.state.sendUnsentProgress ===
                              Progress.Error && (
                              <div className="alert alert-danger">
                                {tr(
                                  "viewPublicationsRecipientChangesSendFailed"
                                )}
                              </div>
                            )}
                            {this.state.sendUnsentProgress ===
                              Progress.Success && (
                              <div className="alert alert-success">
                                {this.state.sendUnsentSuccess}
                              </div>
                            )}
                            <>
                              <div className="row">
                                <div className="col-md-12 col-sm-12 bg-white pl-0 pr-0">
                                  <Collapse
                                    isOpen={
                                      this.state.showRecipientUpdates &&
                                      hasUpdatesToRecipientList
                                    }
                                  >
                                    <Query<
                                      gqltypes.ChangedRecipients,
                                      gqltypes.ChangedRecipientsVariables
                                    >
                                      query={CHANGED_RECIPIENTS_QUERY}
                                      variables={{ id: selectedPublication.id }}
                                      skip={!this.state.showRecipientUpdates}
                                    >
                                      {({ loading, error, data }) => {
                                        if (loading) {
                                          return <Loading />;
                                        }
                                        if (error) {
                                          return (
                                            <div className="alert alert-warning m-content">
                                              {tr("failedToFetchData")}
                                            </div>
                                          );
                                        }
                                        if (!data || !data.publication) {
                                          return (
                                            <div className="alert alert-warning m-content">
                                              {tr("noDataAvailable")}
                                            </div>
                                          );
                                        }
                                        return (
                                          <ChangedMembersTable
                                            tr={tr}
                                            style={{
                                              maxHeight: "50vh",
                                              overflowX: "auto",
                                            }}
                                            groupMap={groupMap}
                                            recipientUpdateChanges={
                                              data.publication
                                                .recipientUpdateChanges
                                            }
                                            updateList={() =>
                                              this.updatePublicationRecipientList(
                                                selectedPublication.id
                                              )
                                            }
                                            updatingList={
                                              this.state
                                                .updatePublicationRecipientListStatus !==
                                              MutationStatus.IDLE
                                            }
                                          />
                                        );
                                      }}
                                    </Query>
                                  </Collapse>
                                </div>
                              </div>
                            </>
                          </>
                        ) : (
                          <p className="public-section-p-content">
                            {tr("viewPublicationsRecipientChangesAllGood")}
                          </p>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </section>

              {selectedPublication.sent && (
                <>
                  <section className="container-fluid publication-section-style-no-border-bottom">
                    <div
                      className="row h-100"
                      style={
                        this.state.showDangerZone
                          ? { background: "var(--blue-xx-light)" }
                          : { background: "var(--light)" }
                      }
                    >
                      <div
                        className="col-md-12 col-sm-12 d-flex justify-content-between clickable"
                        onClick={() => {
                          this.setState({
                            showDangerZone: !this.state.showDangerZone,
                          });
                        }}
                        style={{ height: "45px" }}
                      >
                        <h4
                          className="my-auto publication-section-danger-text"
                          style={
                            this.state.showDangerZone
                              ? { fontWeight: "bold" }
                              : { fontWeight: "normal" }
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-exclamation-circle-fill publication-section-danger-text mb-1 mr-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                          </svg>
                          {tr("viewPublicationsDangerZoneTitle")}
                          <i
                            className={`fas fa-caret-${
                              this.state.showDangerZone ? "up" : "down"
                            } ml-1`}
                          />
                        </h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 bg-white">
                        <Collapse isOpen={this.state.showDangerZone}>
                          <p className="px-0 col-md-8 mt-3 font-weight-light">
                            {tr("viewPublicationsRevokePublicationInfo1")}
                          </p>
                          <p className="px-0 col-md-6">
                            <strong>
                              {tr("viewPublicationsRevokePublicationInfo2")}
                            </strong>
                          </p>
                          <FormTextArea
                            className="form-control-text-area-publication"
                            id="foobar"
                            label={tr(
                              "viewPublicationsRevokePublicationReasonLabel"
                            )}
                            rows={4}
                            value={this.state.revokeReason}
                            onChange={(e) =>
                              this.setState({
                                revokeReason: e.currentTarget.value,
                              })
                            }
                            placeholder={tr("formTextAreaPlaceholder")}
                          />
                          <div className="mb-5 ml-0">
                            <Checkbox
                              style={{ paddingLeft: "1.6rem" }}
                              id="revokeUnderstand"
                              label={tr(
                                "viewPublicationsRevokePublicationCheckboxLabel"
                              )}
                              checked={this.state.revokeConfirmCheckbox}
                              onChange={(id, checked) =>
                                this.setState({
                                  revokeConfirmCheckbox: checked,
                                })
                              }
                            />
                          </div>
                          <div>
                            <Button
                              className={`button-${
                                !this.state.revokeConfirmCheckbox
                                  ? "danger-checked"
                                  : "danger"
                              }`}
                              label={tr(
                                "viewPublicationsRevokePublicationButtonLabel"
                              )}
                              level="danger"
                              disabled={!this.state.revokeConfirmCheckbox}
                              onClick={() => {
                                this.handleRevokePublication(
                                  selectedPublication
                                );
                              }}
                            />
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </section>
                </>
              )}
            </ISTContainer>
          )}
      </React.Fragment>
    );
  }

  private renderToggleUncontactable = (props: { count: number }) => (
    <Button
      label={
        this.state.showUncontactable
          ? this.props.tr("viewPublicationsHideUncontactable", props.count)
          : this.props.tr("viewPublicationsShowUncontactable", props.count)
      }
      disabled={props.count === 0}
      level="secondary"
      onClick={() => {
        this.setState((state) => ({
          showUncontactable: !state.showUncontactable,
        }));
      }}
    />
  );

  private handleRemovePublication = async (
    publication: gqltypes.orgData["organisation"]["publications"][0]
  ) => {
    await showConfirmDialog({
      title: this.props.tr("viewPublicationsRemovePublicationTitle"),
      message: this.props.tr(
        "viewPublicationsRemovePublicationMessage",
        publication.name
      ),
      proceedText: this.props.tr("viewPublicationsRemovePublicationProceed"),
      cancelText: defaultDialogCancel(this.props.tr),
      proceedFunc: async (hostComponent) => {
        try {
          const variables = {
            id: publication.id,
            context: this.props.adminContext,
          };
          await this.props.deletePublication({
            variables,
            update: (
              store,
              response: {
                data: {
                  deletePublication: gqltypes.DeletePublication["deletePublication"];
                };
              }
            ) => {
              const data = store.readQuery<gqltypes.orgData>({
                query: orgDataQuery,
                variables: {
                  context: this.props.adminContext,
                },
              });
              if (data === null) {
                throw new Error("Could not read query 'orgDataQuery'");
              }
              const returnedRecord = response.data.deletePublication;
              if (!returnedRecord) {
                throw new Error("no response for deletePublication mutation");
              }
              data.organisation.publications =
                data.organisation.publications.filter(
                  (p) => p.id !== returnedRecord.id
                );
              store.writeQuery({
                query: orgDataQuery,
                data,
                variables: { context: this.props.adminContext },
              });
            },
          });
        } catch (e) {
          hostComponent.dismiss();
          await showAlertDialog({
            title: this.props.tr("error"),
            message: this.props.tr("viewPublicationsFailedToRemovePublication"),
            proceedText: defaultDialogProceed(this.props.tr),
            cancelText: defaultDialogCancel(this.props.tr),
          });
          return;
        }
        hostComponent.dismiss();
      },
      proceedFuncFooter: (
        <span>
          <i className="fa fa-spinner fa-spin mr-2" />
          {this.props.tr("publicationRemoving")}
        </span>
      ),
    });
  };

  private handleEditPublication = (publicationId: string) => {
    this.props.navigate(links.admin.publication.edit(publicationId));
  };

  private handleSelectPublication = (id: string) => {
    // TODO: Save this history entry when we handle publications in this view, instead of sent forms
    /*
    this.props.recordHistory({
      variables: {
        context: this.props.adminContext,
        input: {
          label: this.props.formData.displayName,
          type: gqltypes.RecordHistoryType.publication,
          path: "admin/publications/" + id
        }
      }
    });
    */
    setTimeout(() => {
      scroller.scrollTo("manage-reminders", {
        duration: 600,
        delay: 70,
        smooth: true,
        offset: -30,
      });
    }, 0);
    this.setState({
      selectedPublicationId: id,
      showPendingRespondents: false,
      showGroups: false,
      showUncontactable: false,
      showRecipientUpdates: false,
    });
  };

  private remindPublication = async (id: string) => {
    try {
      this.setState({ remindingPublicationStatus: MutationStatus.WORKING });

      const result = await client.mutate<gqltypes.SendPublicationReminder>({
        mutation: gql`
          mutation SendPublicationReminder($id: ID!, $context: Context!) {
            sendPublicationReminder(id: $id, context: $context) {
              code
              message
            }
          }
        `,
        variables: {
          id,
          context: this.props.adminContext,
        },
      });

      const status = result.data
        ? result.data.sendPublicationReminder.code === "ok"
        : false;

      await this.props.orgData.refetch();

      const newRemindedPublications = new Set(this.state.remindedPublications);
      newRemindedPublications.add(id);

      this.setState({ remindedPublications: newRemindedPublications });
    } finally {
      this.setState({ remindingPublicationStatus: MutationStatus.IDLE });
    }
  };

  private updatePublicationRecipientList = async (id: string) => {
    try {
      this.setState({
        updatePublicationRecipientListStatus: MutationStatus.WORKING,
      });

      const result =
        await client.mutate<gqltypes.UpdatePublicationRecipientList>({
          mutation: gql`
            mutation UpdatePublicationRecipientList(
              $id: ID!
              $context: Context!
            ) {
              updatePublicationRecipients(id: $id, context: $context) {
                id
                recipientUpdateChanges {
                  addedCount
                  removedCount
                  added {
                    ...RecipientUpdatePublicationUser
                  }
                  removed {
                    ...RecipientUpdatePublicationUser
                  }
                  changed {
                    ...RecipientUpdatePublicationUser
                  }
                }
              }
            }
            ${recipientUpdatePublicationUserFragment}
          `,
          variables: {
            id,
            context: this.props.adminContext,
          },
        });
      await this.props.orgData.refetch();
    } finally {
      this.setState({
        updatePublicationRecipientListStatus: MutationStatus.IDLE,
      });
    }
  };

  private dryrunPublication = async (id: string) => {
    try {
      this.setState({ dryrunningPublicationStatus: MutationStatus.WORKING });

      const result = await client.mutate({
        mutation: PROMPT_UPDATE_UNCONTACTABLE_MUTATION,
        variables: {
          id,
          context: this.props.adminContext,
        },
      });

      const newDryranPublications = new Set(this.state.dryranPublications);
      newDryranPublications.add(id);

      this.setState({ dryranPublications: newDryranPublications });
    } finally {
      this.setState({ dryrunningPublicationStatus: MutationStatus.IDLE });
    }
  };

  private handleRevokePublication = async (
    publication: gqltypes.orgData["organisation"]["publications"][0]
  ) => {
    const Content = (props: {
      value: string;
      update: (val: string) => void;
    }) => {
      const substitutions = getEmailTemplateStrings(this.props.tr);
      return (
        <div>
          <p className="col-md-9">
            {this.props.tr("viewPublicationsRevokePublicationContentMessage")}
          </p>
          <ApolloProvider client={client}>
            <EmailPreview
              type={gqltypes.EmailTemplateType.revokedPublication}
              language={this.props.uiLanguage}
              org={this.props.adminContext.org}
              substitutions={{
                message: props.value,
                formName: publication.form.name,
                name: substitutions.exampleNameGuardian,
                child: substitutions.exampleNameChild,
              }}
            />
          </ApolloProvider>
        </div>
      );
    };

    await showConfirmDialog({
      title: this.props.tr("viewPublicationsRevokePublicationTitle"),
      proceedText: this.props.tr("viewPublicationsRevokePublicationProceed"),
      proceedLevel: "danger",
      size: "lg",
      cancelText: defaultDialogCancel(this.props.tr),
      content: (hostComponent: any) => {
        return (
          <Content
            value={this.state.revokeReason}
            update={(val) => {
              this.setState({ revokeReason: val }, () => {
                hostComponent.forceUpdate();
              });
            }}
          />
        );
      },
      proceedFunc: async (hostComponent) => {
        try {
          this.setState({
            updatePublicationRecipientListStatus: MutationStatus.WORKING,
          });

          const result = await client.mutate<
            gqltypes.RevokePublication,
            gqltypes.RevokePublicationVariables
          >({
            mutation: gql`
              mutation RevokePublication(
                $id: ID!
                $reason: String!
                $context: Context!
              ) {
                revokePublication(id: $id, reason: $reason, context: $context) {
                  id
                }
              }
            `,
            variables: {
              id: publication.id,
              reason: this.state.revokeReason,
              context: this.props.adminContext,
            },
          });
          await this.props.orgData.refetch();
        } finally {
          this.setState({
            updatePublicationRecipientListStatus: MutationStatus.IDLE,
          });
        }
      },
      proceedFuncFooter: (
        <span>
          <i className="fa fa-spinner fa-spin mr-2" />
          {this.props.tr("publicationRevoking")}
        </span>
      ),
    });
  };
}

interface QueryResponse {
  orgData: DataValue<gqltypes.orgData> & QueryResult;
}

const orgDataQuery = gql`
  query orgData($context: Context!) {
    organisation(context: $context) {
      id
      publications(accessRole: send) {
        id
        name
        form {
          id
          name
        }
        creator {
          id
          name
        }
        created
        sendDate
        validFrom
        validTo
        lastDryrun
        lastNotified
        lastReminderSent
        sent
        stats {
          total
          groups
          sent
          answered
          unanswered
          undeliverableNotifications
        }
        recipientUpdateChanges {
          addedCount
          removedCount
          changedCount
        }
        recipients {
          groups {
            edges {
              id
              type
              displayName
              removed
              memberCount
              schoolYearFilter
            }
          }
        }
      }
    }
  }
`;

const withPublications = graphql<
  Props,
  gqltypes.orgData,
  gqltypes.orgDataVariables,
  any
>(orgDataQuery, {
  name: "orgData",
  options: (props) => ({
    variables: {
      context: props.adminContext,
    },
  }),
});

const withDeletePublication = graphql<
  gqltypes.DeletePublication,
  gqltypes.DeletePublicationVariables
>(
  gql`
    mutation DeletePublication($id: ID!, $context: Context!) {
      deletePublication(id: $id, context: $context) {
        id
      }
    }
  `,
  { name: "deletePublication" }
);

export const SentFormsContainer = _.flowRight(
  withTranslation,
  withAdminContext,
  withRouter,
  withPublications,
  withDeletePublication
)(ViewPublications);
