import gql from "graphql-tag";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Query } from "@apollo/client/react/components";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import {
  AdminContext,
  Translation,
  withAdminContext,
  withTranslation,
} from "../../App/reducer";
import { ISTContainer, Loading } from "../../Common";
import { GenericError } from "../../Common/components/GenericError";
import { Time } from "../../Common/components/Time";
import { WrapLink } from "../../Common/components/Utils";
import { getSourceFromShortSource, getUserInfoUrl } from "../../Common/utils";
import { definedNotNull } from "../../Utils/functional";
import * as gqltypes from "../../gqltypes";
import { getEmploymentRoleName } from "../../permissions";
import { getSettings } from "../../settings";
import { getRelationTypeLabel } from "../../translation/strings";
import { links } from "../links";
import { Params } from "react-router";
import withRouter from "../../Utils/withRouter";

const readableNationalId = getSettings().regionSettings.readableNationalId;
const skolidIdp = getSettings().skolidIdp;

const userInfoQuery = gql`
  query userInfo($input: UserIdInput!, $context: Context!) {
    user(input: $input, context: $context) {
      id
      source
      customerId
      name
      idp
      birthDate
      nationalId
      skolidOrganization {
        id
        name
        country
      }
      connectedUsers {
        id
        source
        customerId
        idp
        idpSub
        name
        skolidOrganization {
          id
          name
          country
        }
      }
      emails {
        value
        type
        weight
        source
        preferredWork
        preferredHome
      }
      addresses {
        source
        type
        streetAddress
        locality
        region
        postalCode
        country
      }
      enrolments {
        schoolUnit {
          id
          customerId
          displayName
        }
        schoolYear
        schoolType
        startDate
        endDate
      }
      placements {
        schoolUnit {
          id
          customerId
          displayName
        }
        schoolType
        startDate
        endDate
      }
      studentGroupMemberships {
        startDate
        endDate
        studentGroup {
          id
          customerId
          displayName
          groupType
          schoolUnit {
            id
            displayName
          }
        }
      }
      relations {
        edges {
          type
          node {
            id
            source
            customerId
            idp
            idpSub
            name
          }
        }
      }
      publicationRecipients {
        id
        form {
          id
          name
        }
        publication {
          id
          name
        }
        created
        validResponse {
          id
          modified
        }
      }
      employments {
        id
        startDate
        endDate
        employmentRole
        hoursPerYear
        employmentPercent
        employedAt {
          id
          displayName
        }
      }
    }
  }
`;

const AreaContainer = (props: {
  tr: Translation["tr"];
  name: string;
  children: any;
  isEmpty: boolean;
  notAvailable?: boolean;
}) => {
  if (props.notAvailable) return null;
  return (
    <div className="pb-content">
      <hr />
      <h3>{props.name}</h3>
      {props.isEmpty ? (
        <span className="pl-4 text-muted">
          {props.tr("userInfoNoResource", props.name.toLowerCase())}
        </span>
      ) : (
        props.children
      )}
    </div>
  );
};

const getDateInterval = (tr: Translation["tr"], sd: any, ed: any) => {
  const startDate = sd ? <Time date={sd} /> : null;
  const endDate = ed ? <Time date={ed} /> : null;

  const isValid =
    (sd ? new Date(sd).getTime() < Date.now() : true) &&
    (ed ? new Date(ed).getTime() > Date.now() : true);

  const validText = tr("userInfoValidDateRange");

  return startDate || endDate ? (
    <span className={`${isValid ? "text-success" : "text-danger"}`}>
      ({startDate} - {endDate})
    </span>
  ) : (
    `(${validText})`
  );
};

const getAccountSourceLabelName = (
  tr: Translation["tr"],
  source: gqltypes.UserSource,
  skolidOrganization: NonNullable<
    gqltypes.userInfo["user"]
  >["skolidOrganization"]
): string => {
  switch (source) {
    case gqltypes.UserSource.educloud:
      return tr("userInfoAccountSourceLabelEducloud");
    case gqltypes.UserSource.db:
      return tr("userInfoAccountSourceLabelDb");
    case gqltypes.UserSource.skolid:
      return (
        tr("userInfoAccountSourceLabelSkolid") +
        ` (${skolidOrganization?.name})`
      );
  }
};

const getIdpNameWithSep = (
  tr: Translation["tr"],
  user: { idp: string | null }
) => {
  return user.idp && user.idp !== skolidIdp ? ` (${user.idp})` : null;
};

const getEmailSourceLabelName = (
  tr: Translation["tr"],
  source: gqltypes.EmailSource
): string => {
  switch (source) {
    case gqltypes.EmailSource.educloud:
      return tr("userInfoEmailSourceLabelEducloud");
    case gqltypes.EmailSource.idp:
      return tr("userInfoEmailSourceLabelIdp");
    case gqltypes.EmailSource.db_setting:
      return tr("userInfoEmailSourceLabelDbSetting");
    case gqltypes.EmailSource.skolid:
      return tr("userInfoEmailSourceLabelSkolid");
  }
};

const getEmailTypeLabel = (tr: Translation["tr"], type: string) => {
  switch (type) {
    case "home":
      return tr("userInfoEmailHome");
    case "work":
      return tr("userInfoEmailWork");
    case "other":
      return tr("userInfoEmailOther");

    default:
      return "_" + type + "_";
  }
};

const getAddressSourceLabelName = (
  tr: Translation["tr"],
  source: gqltypes.AddressSource
): string => {
  switch (source) {
    case gqltypes.AddressSource.educloud:
      return tr("userInfoAddressSourceLabelEducloud");
    case gqltypes.AddressSource.skolid:
      return tr("userInfoAddressSourceLabelSkolid");
  }
};

interface UserInfoProps extends Translation {
  user: gqltypes.userInfo["user"];
  accessErrors: ReadonlyArray<string | number>;
}

class UserInfo extends React.PureComponent<UserInfoProps> {
  public render() {
    const { tr, user } = this.props;
    if (!user) {
      return tr("userInfoDidNotFindUser");
    }

    const NoAccess = () => (
      <span className="text-danger">{tr("userInfoNoAccess")}</span>
    );

    const additionalInfoFromType: {
      [key: string]: string | undefined;
    } = {
      FS: tr("userInfoFS"),
      FTH: tr("userInfoFTH"),
    };

    const age = user.birthDate
      ? moment().diff(moment(user.birthDate, "YYYY-MM-DD"), "years")
      : null;

    const unknownInfo = tr("userInfoUnknown");
    const nationalId = this.props.accessErrors.includes("nationalId") ? (
      <NoAccess />
    ) : user.nationalId ? (
      readableNationalId(user.nationalId)
    ) : (
      unknownInfo
    );

    return (
      <div className="p-content">
        <p>
          <strong>
            {tr(
              "userInfoSource",
              getAccountSourceLabelName(
                tr,
                user.idp === skolidIdp
                  ? gqltypes.UserSource.skolid
                  : user.source,
                user.skolidOrganization
              )
            )}
            {getIdpNameWithSep(tr, user)}
          </strong>
        </p>
        <p>{tr("userInfoName", user.name)}</p>
        <p>
          {tr("userInfoNationalId")}
          {nationalId}
        </p>
        <p>
          {tr("userInfoBirthDate")}:{" "}
          {user.birthDate ? (
            <Time date={user.birthDate} />
          ) : (
            <i>{unknownInfo}</i>
          )}
          {age ? " (" + tr("userInfoAge", age) + ")" : null}
        </p>

        <p>{tr("userInfoConnectedAccounts")}:</p>
        <ul>
          {user.connectedUsers ? (
            user.connectedUsers.length ? (
              user.connectedUsers.map((u, i) => (
                <li key={i}>
                  <Link to={getUserInfoUrl(u)}>
                    {u.name} -{" "}
                    {getAccountSourceLabelName(
                      tr,
                      u.source,
                      u.skolidOrganization
                    )}
                    {getIdpNameWithSep(tr, u)}
                  </Link>
                </li>
              ))
            ) : (
              <span className="">{tr("userInfoNoConnectedAccounts")}</span>
            )
          ) : (
            <NoAccess />
          )}
        </ul>
        <p>{tr("userInfoEmails")}:</p>
        <ul>
          {user.emails ? (
            user.emails.length ? (
              _.orderBy(user.emails, "weight", "desc").map((email, i) => {
                const { value, type, source, preferredHome, preferredWork } =
                  email;
                const Preferred =
                  preferredHome || preferredWork ? (
                    <span>
                      {" "}
                      {preferredHome ? (
                        <React.Fragment>
                          <span
                            id={"email-home-tooltip_" + i}
                            className="badge badge-success"
                          >
                            {getEmailTypeLabel(tr, "home")}
                          </span>
                          <UncontrolledTooltip
                            target={"email-home-tooltip_" + i}
                          >
                            {tr("userInfoEmailHomeDescription")}
                          </UncontrolledTooltip>
                        </React.Fragment>
                      ) : null}
                      {preferredHome ? " " : ""}
                      {preferredWork ? (
                        <React.Fragment>
                          <span
                            id={"email-work-tooltip_" + i}
                            className="badge badge-success"
                          >
                            {getEmailTypeLabel(tr, "work")}
                          </span>
                          <UncontrolledTooltip
                            target={"email-work-tooltip_" + i}
                          >
                            {tr("userInfoEmailWorkDescription")}
                          </UncontrolledTooltip>
                        </React.Fragment>
                      ) : null}
                    </span>
                  ) : null;
                return (
                  <li key={i}>
                    {value} ({getEmailTypeLabel(tr, type)}) -{" "}
                    {getEmailSourceLabelName(tr, source)} {Preferred}
                  </li>
                );
              })
            ) : (
              <span className="pl-5">{tr("userInfoNoEmails")}</span>
            )
          ) : (
            <NoAccess />
          )}
        </ul>

        <p>{tr("userInfoAddresses")}:</p>
        <div className="row">
          {user.addresses ? (
            user.addresses.length ? (
              _.orderBy(user.addresses, "source", "desc").map((address, i) => {
                const {
                  type,
                  source,
                  country,
                  locality,
                  postalCode,
                  region,
                  streetAddress,
                } = address;

                return (
                  <div className="mb-content mx-content col-12" key={i}>
                    <span className="badge badge-info">
                      {getAddressSourceLabelName(tr, source)}
                    </span>
                    <br />
                    <span>
                      <i>
                        {streetAddress}
                        <br /> {postalCode}
                        <br /> {locality}
                      </i>
                    </span>
                  </div>
                );
              })
            ) : (
              <span className="pl-5">{tr("userInfoNoAddress")}</span>
            )
          ) : (
            <div className="mx-content">
              <NoAccess />
            </div>
          )}
        </div>

        <AreaContainer
          tr={tr}
          name={tr("userInfoEnrolments")}
          isEmpty={user.enrolments ? user.enrolments.length === 0 : false}
          notAvailable={user.source !== gqltypes.UserSource.educloud}
        >
          <ul>
            {user.enrolments ? (
              (
                _.orderBy(user.enrolments, [
                  "startDate",
                  "endDate",
                  "displayName",
                ]) as gqltypes.userInfo_user_enrolments[]
              ).map((enrolment, i) => {
                const schoolYear =
                  !enrolment.schoolYear || enrolment.schoolYear < 0
                    ? "?"
                    : enrolment.schoolYear;

                const su = enrolment.schoolUnit;

                return (
                  <li key={i}>
                    {su ? su.displayName : "???"}{" "}
                    {getDateInterval(
                      tr,
                      enrolment.startDate,
                      enrolment.endDate
                    )}{" "}
                    -{" "}
                    {additionalInfoFromType[enrolment.schoolType || ""] ||
                      tr("userInfoSchoolYear", schoolYear)}
                  </li>
                );
              })
            ) : (
              <NoAccess />
            )}
          </ul>
        </AreaContainer>
        <AreaContainer
          tr={tr}
          name={tr("userInfoPlacements")}
          isEmpty={user.placements ? user.placements.length === 0 : false}
          notAvailable={user.source !== gqltypes.UserSource.educloud}
        >
          <ul>
            {user.placements ? (
              (
                _.orderBy(user.placements, [
                  "startDate",
                  "endDate",
                  "displayName",
                ]) as gqltypes.userInfo_user_placements[]
              ).map((placement, i) => {
                const su = placement.schoolUnit;

                return (
                  <li key={i}>
                    {su ? su.displayName : "???"}{" "}
                    {getDateInterval(
                      tr,
                      placement.startDate,
                      placement.endDate
                    )}{" "}
                    - {additionalInfoFromType[placement.schoolType || ""]}
                  </li>
                );
              })
            ) : (
              <NoAccess />
            )}
          </ul>
        </AreaContainer>
        <AreaContainer
          tr={tr}
          name={tr("userInfoClasses")}
          isEmpty={
            user.studentGroupMemberships
              ? user.studentGroupMemberships.length === 0
              : false
          }
          notAvailable={user.source !== gqltypes.UserSource.educloud}
        >
          <ul>
            {user.studentGroupMemberships ? (
              _.orderBy(
                user.studentGroupMemberships,
                ["startDate", "endDate"],
                "desc"
              ).map((membership) => {
                const name =
                  membership.studentGroup?.displayName ?? "Okänt namn";

                const type = membership.studentGroup?.groupType ?? "Okänd typ";

                const suName = membership.studentGroup?.schoolUnit?.displayName;

                const isClassOrDepartment =
                  (type === "Klass" || type === "Avdelning") &&
                  Boolean(membership.studentGroup);

                if (!isClassOrDepartment) {
                  return null;
                }

                const sg = membership.studentGroup;

                const answerHref = isClassOrDepartment
                  ? links.admin.publication.answers({
                      customerId: sg!.customerId,
                      schoolUnitId: sg!.schoolUnit.id,
                      studentGroupId: sg!.id,
                    })
                  : "";

                return (
                  <li key={membership.studentGroup!.id}>
                    <WrapLink url={answerHref} use={isClassOrDepartment}>
                      {suName || "Okänd skola"} - {name}
                    </WrapLink>{" "}
                    {getDateInterval(
                      tr,
                      membership.startDate,
                      membership.endDate
                    )}
                  </li>
                );
              })
            ) : (
              <NoAccess />
            )}
          </ul>
        </AreaContainer>
        <AreaContainer
          tr={tr}
          name={tr("userInfoRelations")}
          isEmpty={user.relations ? user.relations.edges.length === 0 : false}
        >
          <ul>
            {user.relations ? (
              (
                _.orderBy(
                  user.relations.edges,
                  "node.name"
                ) as gqltypes.userInfo_user_relations_edges[]
              ).map((edge, i) => {
                const node = edge.node ? edge.node : undefined;
                if (!node) {
                  return <li key={i}>{tr("failedToFetchUser")}</li>;
                }

                const url = getUserInfoUrl(node);
                const name = node.name;
                return (
                  <li key={i}>
                    <Link to={url}>{name}</Link> (
                    {getRelationTypeLabel(edge.type, tr)})
                  </li>
                );
              })
            ) : (
              <NoAccess />
            )}
          </ul>
        </AreaContainer>
        <AreaContainer
          tr={tr}
          name={tr("userInfoPublications")}
          isEmpty={
            user.publicationRecipients
              ? user.publicationRecipients.length === 0
              : false
          }
          notAvailable={user.source !== gqltypes.UserSource.educloud}
        >
          <ul>
            {user.publicationRecipients ? (
              user.publicationRecipients.map((recipient) => {
                const name =
                  recipient.publication.name + " (" + recipient.form.name + ")";
                const url = recipient.validResponse
                  ? links.admin.publication.response(recipient.validResponse.id)
                  : undefined;
                return (
                  <li key={recipient.id}>
                    {name}
                    {" - "}
                    {url ? (
                      <Link to={url}>{tr("userInfoShowAnswer")}</Link>
                    ) : (
                      <i>{tr("userInfoNotAnswered")}</i>
                    )}
                  </li>
                );
              })
            ) : (
              <NoAccess />
            )}
          </ul>
        </AreaContainer>
        <AreaContainer
          tr={tr}
          name={tr("userInfoEmployments")}
          isEmpty={user.employments ? user.employments.length === 0 : false}
          notAvailable={user.source !== gqltypes.UserSource.educloud}
        >
          <ul>
            {user.employments ? (
              _.orderBy(user.employments, "startDate", "desc").map(
                (employment, i) => {
                  const name = employment.employedAt
                    ? employment.employedAt.displayName
                    : "??";

                  return (
                    <li key={i}>
                      {name}{" "}
                      {getDateInterval(
                        tr,
                        employment.startDate,
                        employment.endDate
                      )}{" "}
                      - {getEmploymentRoleName(tr, employment.employmentRole)}
                    </li>
                  );
                }
              )
            ) : (
              <NoAccess />
            )}
          </ul>
        </AreaContainer>
      </div>
    );
  }
}

interface Props extends Translation, AdminContext {
  params: Params;
}

class UserProfileInner extends React.PureComponent<Props> {
  public render() {
    const { tr } = this.props;
    const id = decodeURIComponent(this.props.params.id!);

    const customerId = this.props.params.customerId;
    const source = getSourceFromShortSource(this.props.params.source!);

    const user = { id, source, customerId };

    const input = user;

    if (!input) {
      return "ingen input";
    }

    return (
      <div>
        <h1>{tr("userInfoTitle")}</h1>
        <p className="col-12 col-md-9 p-0 pb-3">{tr("userInfoDescription")}</p>
        <ISTContainer header={tr("userInfoHeader")}>
          <Query<gqltypes.userInfo>
            query={userInfoQuery}
            fetchPolicy="cache-first"
            variables={{
              input,
              context: this.props.adminContext,
            }}
          >
            {({ loading, error, data }) => {
              if (loading || !data) {
                return <Loading />;
              }

              if (error && error.networkError) {
                return <GenericError title={tr("genericLoadError")} />;
              }

              const accessErrors =
                error &&
                error.graphQLErrors
                  .filter((e) => e.extensions && e.extensions.code === "ACCESS")
                  .map((e) => (e.path && e.path.length >= 2 ? e.path[1] : null))
                  .filter(definedNotNull);

              return (
                <UserInfo
                  {...this.props}
                  user={data.user}
                  accessErrors={accessErrors || []}
                />
              );
            }}
          </Query>
        </ISTContainer>
      </div>
    );
  }
}

export const UserProfile = withAdminContext(
  withTranslation(withRouter(UserProfileInner))
);
