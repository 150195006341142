/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: applicationReportDetails
// ====================================================

export interface applicationReportDetails_application_responses_actions {
  __typename: "ApplicationResponseEvent";
  action: ApplicationResponseAction;
  comment: string | null;
}

export interface applicationReportDetails_application_responses_creator {
  __typename: "ApplicationCreator";
  id: string;
  name: string;
  source: UserSource;
}

export interface applicationReportDetails_application_responses_subject_user_emails {
  __typename: "UserEmail";
  value: string;
  preferredHome: boolean;
}

export interface applicationReportDetails_application_responses_subject_user_addresses {
  __typename: "UserAddress";
  source: AddressSource;
  type: string | null;
  streetAddress: string | null;
  locality: string | null;
  region: string | null;
  postalCode: string | null;
  country: string | null;
}

export interface applicationReportDetails_application_responses_subject_user {
  __typename: "User";
  id: string;
  source: UserSource;
  name: string | null;
  emails: applicationReportDetails_application_responses_subject_user_emails[] | null;
  addresses: applicationReportDetails_application_responses_subject_user_addresses[] | null;
}

export interface applicationReportDetails_application_responses_subject {
  __typename: "SubjectCoSigner";
  userId: string | null;
  name: string;
  nationalId: string;
  user: applicationReportDetails_application_responses_subject_user | null;
}

export interface applicationReportDetails_application_responses_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface applicationReportDetails_application_responses_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface applicationReportDetails_application_responses_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface applicationReportDetails_application_responses_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: applicationReportDetails_application_responses_response_components_questions_AnswerRanking_rankings[];
}

export interface applicationReportDetails_application_responses_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface applicationReportDetails_application_responses_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface applicationReportDetails_application_responses_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface applicationReportDetails_application_responses_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type applicationReportDetails_application_responses_response_components_questions = applicationReportDetails_application_responses_response_components_questions_AnswerMultiChoice | applicationReportDetails_application_responses_response_components_questions_AnswerCheckboxes | applicationReportDetails_application_responses_response_components_questions_AnswerRanking | applicationReportDetails_application_responses_response_components_questions_AnswerShortText | applicationReportDetails_application_responses_response_components_questions_AnswerLongText | applicationReportDetails_application_responses_response_components_questions_AnswerYesOrNo | applicationReportDetails_application_responses_response_components_questions_AnswerDate;

export interface applicationReportDetails_application_responses_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: applicationReportDetails_application_responses_response_components_questions[];
}

export interface applicationReportDetails_application_responses_response {
  __typename: "FormAnswer";
  components: applicationReportDetails_application_responses_response_components[];
}

export interface applicationReportDetails_application_responses_signatures_signer_emails {
  __typename: "UserEmail";
  value: string;
  preferredHome: boolean;
}

export interface applicationReportDetails_application_responses_signatures_signer_addresses {
  __typename: "UserAddress";
  source: AddressSource;
  type: string | null;
  streetAddress: string | null;
  locality: string | null;
  region: string | null;
  postalCode: string | null;
  country: string | null;
}

export interface applicationReportDetails_application_responses_signatures_signer {
  __typename: "User";
  id: string;
  name: string | null;
  nationalId: string | null;
  emails: applicationReportDetails_application_responses_signatures_signer_emails[] | null;
  addresses: applicationReportDetails_application_responses_signatures_signer_addresses[] | null;
}

export interface applicationReportDetails_application_responses_signatures {
  __typename: "ApplicationResponseSignature";
  signer: applicationReportDetails_application_responses_signatures_signer | null;
}

export interface applicationReportDetails_application_responses {
  __typename: "ApplicationResponse";
  id: string;
  actions: applicationReportDetails_application_responses_actions[];
  feedbackId: string | null;
  feedbackDescription: string | null;
  creator: applicationReportDetails_application_responses_creator;
  subject: applicationReportDetails_application_responses_subject;
  created: any;
  modified: any;
  status: ApplicationResponseStatus;
  seenByOrg: any | null;
  response: applicationReportDetails_application_responses_response | null;
  signatures: applicationReportDetails_application_responses_signatures[];
}

export interface applicationReportDetails_application_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface applicationReportDetails_application_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: applicationReportDetails_application_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface applicationReportDetails_application_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface applicationReportDetails_application_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface applicationReportDetails_application_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: applicationReportDetails_application_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: applicationReportDetails_application_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface applicationReportDetails_application_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface applicationReportDetails_application_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: applicationReportDetails_application_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface applicationReportDetails_application_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface applicationReportDetails_application_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface applicationReportDetails_application_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: applicationReportDetails_application_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: applicationReportDetails_application_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface applicationReportDetails_application_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface applicationReportDetails_application_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface applicationReportDetails_application_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: applicationReportDetails_application_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: applicationReportDetails_application_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type applicationReportDetails_application_form_componentData_components_questions = applicationReportDetails_application_form_componentData_components_questions_FormQuestionYesNo | applicationReportDetails_application_form_componentData_components_questions_FormQuestionRanking | applicationReportDetails_application_form_componentData_components_questions_FormQuestionShortText | applicationReportDetails_application_form_componentData_components_questions_FormQuestionCheckboxes | applicationReportDetails_application_form_componentData_components_questions_FormQuestionMultiChoice;

export interface applicationReportDetails_application_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface applicationReportDetails_application_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: applicationReportDetails_application_form_componentData_components_questions[];
  templateId: string | null;
  attachments: applicationReportDetails_application_form_componentData_components_attachments[] | null;
}

export interface applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type applicationReportDetails_application_form_componentData_predicateComponents_questions = applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionYesNo | applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionRanking | applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionShortText | applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | applicationReportDetails_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface applicationReportDetails_application_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: applicationReportDetails_application_form_componentData_predicateComponents_questions[];
}

export interface applicationReportDetails_application_form_componentData {
  __typename: "ComponentData";
  components: applicationReportDetails_application_form_componentData_components[];
  predicateComponents: applicationReportDetails_application_form_componentData_predicateComponents[] | null;
}

export interface applicationReportDetails_application_form {
  __typename: "Form";
  id: string;
  name: string;
  componentData: applicationReportDetails_application_form_componentData;
}

export interface applicationReportDetails_application {
  __typename: "Application";
  id: string;
  name: string;
  responses: applicationReportDetails_application_responses[];
  form: applicationReportDetails_application_form;
}

export interface applicationReportDetails {
  application: applicationReportDetails_application;
}

export interface applicationReportDetailsVariables {
  applicationId: string;
  statusFilters?: ApplicationResponseStatus[] | null;
  context?: Context | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: viewOrgApplicationReport
// ====================================================

export interface viewOrgApplicationReport_organisation_applications_form {
  __typename: "Form";
  id: string;
  name: string;
  subType: FormSubType;
}

export interface viewOrgApplicationReport_organisation_applications_creator {
  __typename: "User" | "Client";
  id: string;
  name: string | null;
}

export interface viewOrgApplicationReport_organisation_applications_stats {
  __typename: "ApplicationStatistics";
  answered: number;
  open: number;
  closed: number;
}

export interface viewOrgApplicationReport_organisation_applications {
  __typename: "Application";
  id: string;
  name: string;
  form: viewOrgApplicationReport_organisation_applications_form;
  creator: viewOrgApplicationReport_organisation_applications_creator | null;
  created: any;
  modified: any;
  sendDate: any;
  singleGuardianSend: boolean;
  singleGuardianAcceptOffer: boolean;
  schoolunitSpecific: boolean;
  sent: boolean;
  closed: boolean;
  stats: viewOrgApplicationReport_organisation_applications_stats;
}

export interface viewOrgApplicationReport_organisation {
  __typename: "Organisation";
  id: string;
  applications: viewOrgApplicationReport_organisation_applications[];
}

export interface viewOrgApplicationReport {
  organisation: viewOrgApplicationReport_organisation;
}

export interface viewOrgApplicationReportVariables {
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminApplicationResponseAction
// ====================================================

export interface AdminApplicationResponseAction_performApplicationResponseAction_lastAction {
  __typename: "ApplicationResponseEvent";
  id: string;
  action: ApplicationResponseAction;
  newStatus: ApplicationResponseStatus;
  comment: string | null;
  errorCode: string | null;
  created: any;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_actions_actor {
  __typename: "User" | "Client";
  id: string;
  name: string | null;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_actions {
  __typename: "ApplicationResponseEvent";
  id: string;
  action: ApplicationResponseAction;
  newStatus: ApplicationResponseStatus;
  comment: string | null;
  errorCode: string | null;
  created: any;
  actor: AdminApplicationResponseAction_performApplicationResponseAction_actions_actor | null;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_signatures {
  __typename: "ApplicationResponseSignature";
  signerId: string;
  signedAs: string;
  customerId: string | null;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_feedbackSignatures {
  __typename: "ApplicationResponseFeedbackSignature";
  signerId: string;
  signedAs: string;
  customerId: string | null;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: AdminApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerRanking_rankings[];
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type AdminApplicationResponseAction_performApplicationResponseAction_response_components_questions = AdminApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerMultiChoice | AdminApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerCheckboxes | AdminApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerRanking | AdminApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerShortText | AdminApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerLongText | AdminApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerYesOrNo | AdminApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerDate;

export interface AdminApplicationResponseAction_performApplicationResponseAction_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: AdminApplicationResponseAction_performApplicationResponseAction_response_components_questions[];
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_response {
  __typename: "FormAnswer";
  components: AdminApplicationResponseAction_performApplicationResponseAction_response_components[];
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_creator_verifiedRelation {
  __typename: "VerifiedRelation";
  isVerifiedRelation: boolean;
  customerId: string | null;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_creator {
  __typename: "ApplicationCreator";
  id: string;
  name: string;
  source: UserSource;
  verifiedRelation: AdminApplicationResponseAction_performApplicationResponseAction_creator_verifiedRelation;
  orgId: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_subject {
  __typename: "SubjectCoSigner";
  userId: string | null;
  customerId: string | null;
  name: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions = AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionYesNo | AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionRanking | AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionShortText | AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionCheckboxes | AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions_FormQuestionMultiChoice;

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_questions[];
  templateId: string | null;
  attachments: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components_attachments[] | null;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions = AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionYesNo | AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionRanking | AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionShortText | AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents_questions[];
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData {
  __typename: "ComponentData";
  components: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_components[];
  predicateComponents: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData_predicateComponents[] | null;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_translations_componentData_predicateComponents_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_translations_componentData_predicateComponents_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: AdminApplicationResponseAction_performApplicationResponseAction_application_form_translations_componentData_predicateComponents_questions_options[] | null;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_translations_componentData_predicateComponents {
  __typename: "TranslatePredicateComponent";
  id: string;
  language: ISO6391Language | null;
  questions: AdminApplicationResponseAction_performApplicationResponseAction_application_form_translations_componentData_predicateComponents_questions[];
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_translations_componentData_components_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_translations_componentData_components_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: AdminApplicationResponseAction_performApplicationResponseAction_application_form_translations_componentData_components_questions_options[] | null;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_translations_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_translations_componentData_components {
  __typename: "TranslateComponent";
  id: string;
  language: ISO6391Language | null;
  title: string;
  description: string;
  questions: AdminApplicationResponseAction_performApplicationResponseAction_application_form_translations_componentData_components_questions[];
  attachments: AdminApplicationResponseAction_performApplicationResponseAction_application_form_translations_componentData_components_attachments[];
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_translations_componentData {
  __typename: "TranslationComponentData";
  predicateComponents: AdminApplicationResponseAction_performApplicationResponseAction_application_form_translations_componentData_predicateComponents[] | null;
  components: AdminApplicationResponseAction_performApplicationResponseAction_application_form_translations_componentData_components[];
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_translations {
  __typename: "TranslationForm";
  formId: string;
  name: string;
  description: string;
  language: ISO6391Language;
  componentData: AdminApplicationResponseAction_performApplicationResponseAction_application_form_translations_componentData;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form_owner {
  __typename: "Organisation";
  id: string;
  displayName: string;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application_form {
  __typename: "Form";
  id: string;
  type: FormType;
  subType: FormSubType;
  name: string;
  description: string | null;
  language: ISO6391Language;
  componentData: AdminApplicationResponseAction_performApplicationResponseAction_application_form_componentData;
  translations: AdminApplicationResponseAction_performApplicationResponseAction_application_form_translations[];
  owner: AdminApplicationResponseAction_performApplicationResponseAction_application_form_owner;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction_application {
  __typename: "Application";
  id: string;
  name: string;
  singleGuardianSend: boolean;
  singleGuardianAcceptOffer: boolean;
  form: AdminApplicationResponseAction_performApplicationResponseAction_application_form;
}

export interface AdminApplicationResponseAction_performApplicationResponseAction {
  __typename: "ApplicationResponse";
  id: string;
  status: ApplicationResponseStatus;
  modified: any;
  seenByOrg: any | null;
  language: ISO6391Language;
  lastAction: AdminApplicationResponseAction_performApplicationResponseAction_lastAction;
  actions: AdminApplicationResponseAction_performApplicationResponseAction_actions[];
  signatures: AdminApplicationResponseAction_performApplicationResponseAction_signatures[];
  feedbackSignatures: AdminApplicationResponseAction_performApplicationResponseAction_feedbackSignatures[];
  response: AdminApplicationResponseAction_performApplicationResponseAction_response | null;
  creator: AdminApplicationResponseAction_performApplicationResponseAction_creator;
  subject: AdminApplicationResponseAction_performApplicationResponseAction_subject;
  lockHolder: AdminApplicationResponseAction_performApplicationResponseAction_lockHolder | null;
  feedbackId: string | null;
  feedbackDescription: string | null;
  lockExpire: any | null;
  schoolUnit: AdminApplicationResponseAction_performApplicationResponseAction_schoolUnit | null;
  application: AdminApplicationResponseAction_performApplicationResponseAction_application;
}

export interface AdminApplicationResponseAction {
  performApplicationResponseAction: AdminApplicationResponseAction_performApplicationResponseAction;
}

export interface AdminApplicationResponseActionVariables {
  input: ActionInput;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminSignApplicationResponse
// ====================================================

export interface AdminSignApplicationResponse_signApplicationResponse_lastAction {
  __typename: "ApplicationResponseEvent";
  id: string;
  action: ApplicationResponseAction;
  newStatus: ApplicationResponseStatus;
  comment: string | null;
  errorCode: string | null;
  created: any;
}

export interface AdminSignApplicationResponse_signApplicationResponse_actions_actor {
  __typename: "User" | "Client";
  id: string;
  name: string | null;
}

export interface AdminSignApplicationResponse_signApplicationResponse_actions {
  __typename: "ApplicationResponseEvent";
  id: string;
  action: ApplicationResponseAction;
  newStatus: ApplicationResponseStatus;
  comment: string | null;
  errorCode: string | null;
  created: any;
  actor: AdminSignApplicationResponse_signApplicationResponse_actions_actor | null;
}

export interface AdminSignApplicationResponse_signApplicationResponse_signatures {
  __typename: "ApplicationResponseSignature";
  signerId: string;
  signedAs: string;
  customerId: string | null;
}

export interface AdminSignApplicationResponse_signApplicationResponse_feedbackSignatures {
  __typename: "ApplicationResponseFeedbackSignature";
  signerId: string;
  signedAs: string;
  customerId: string | null;
}

export interface AdminSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface AdminSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface AdminSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface AdminSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: AdminSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerRanking_rankings[];
}

export interface AdminSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface AdminSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface AdminSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface AdminSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type AdminSignApplicationResponse_signApplicationResponse_response_components_questions = AdminSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerMultiChoice | AdminSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerCheckboxes | AdminSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerRanking | AdminSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerShortText | AdminSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerLongText | AdminSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerYesOrNo | AdminSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerDate;

export interface AdminSignApplicationResponse_signApplicationResponse_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: AdminSignApplicationResponse_signApplicationResponse_response_components_questions[];
}

export interface AdminSignApplicationResponse_signApplicationResponse_response {
  __typename: "FormAnswer";
  components: AdminSignApplicationResponse_signApplicationResponse_response_components[];
}

export interface AdminSignApplicationResponse_signApplicationResponse_creator_verifiedRelation {
  __typename: "VerifiedRelation";
  isVerifiedRelation: boolean;
  customerId: string | null;
}

export interface AdminSignApplicationResponse_signApplicationResponse_creator {
  __typename: "ApplicationCreator";
  id: string;
  name: string;
  source: UserSource;
  verifiedRelation: AdminSignApplicationResponse_signApplicationResponse_creator_verifiedRelation;
  orgId: string;
}

export interface AdminSignApplicationResponse_signApplicationResponse_subject {
  __typename: "SubjectCoSigner";
  userId: string | null;
  customerId: string | null;
  name: string;
}

export interface AdminSignApplicationResponse_signApplicationResponse_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface AdminSignApplicationResponse_signApplicationResponse_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface AdminSignApplicationResponse_signApplicationResponse {
  __typename: "ApplicationResponse";
  id: string;
  status: ApplicationResponseStatus;
  modified: any;
  seenByOrg: any | null;
  language: ISO6391Language;
  lastAction: AdminSignApplicationResponse_signApplicationResponse_lastAction;
  actions: AdminSignApplicationResponse_signApplicationResponse_actions[];
  signatures: AdminSignApplicationResponse_signApplicationResponse_signatures[];
  feedbackSignatures: AdminSignApplicationResponse_signApplicationResponse_feedbackSignatures[];
  response: AdminSignApplicationResponse_signApplicationResponse_response | null;
  creator: AdminSignApplicationResponse_signApplicationResponse_creator;
  subject: AdminSignApplicationResponse_signApplicationResponse_subject;
  lockHolder: AdminSignApplicationResponse_signApplicationResponse_lockHolder | null;
  feedbackId: string | null;
  feedbackDescription: string | null;
  lockExpire: any | null;
  schoolUnit: AdminSignApplicationResponse_signApplicationResponse_schoolUnit | null;
}

export interface AdminSignApplicationResponse {
  signApplicationResponse: AdminSignApplicationResponse_signApplicationResponse;
}

export interface AdminSignApplicationResponseVariables {
  responseId: string;
  response: FormAnswerInput;
  language: ISO6391Language;
  context?: Context | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminViewApplicationResponse
// ====================================================

export interface AdminViewApplicationResponse_applicationResponse_lastAction {
  __typename: "ApplicationResponseEvent";
  id: string;
  action: ApplicationResponseAction;
  newStatus: ApplicationResponseStatus;
  comment: string | null;
  errorCode: string | null;
  created: any;
}

export interface AdminViewApplicationResponse_applicationResponse_actions_actor {
  __typename: "User" | "Client";
  id: string;
  name: string | null;
}

export interface AdminViewApplicationResponse_applicationResponse_actions {
  __typename: "ApplicationResponseEvent";
  id: string;
  action: ApplicationResponseAction;
  newStatus: ApplicationResponseStatus;
  comment: string | null;
  errorCode: string | null;
  created: any;
  actor: AdminViewApplicationResponse_applicationResponse_actions_actor | null;
}

export interface AdminViewApplicationResponse_applicationResponse_signatures {
  __typename: "ApplicationResponseSignature";
  signerId: string;
  signedAs: string;
  customerId: string | null;
}

export interface AdminViewApplicationResponse_applicationResponse_feedbackSignatures {
  __typename: "ApplicationResponseFeedbackSignature";
  signerId: string;
  signedAs: string;
  customerId: string | null;
}

export interface AdminViewApplicationResponse_applicationResponse_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface AdminViewApplicationResponse_applicationResponse_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface AdminViewApplicationResponse_applicationResponse_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface AdminViewApplicationResponse_applicationResponse_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: AdminViewApplicationResponse_applicationResponse_response_components_questions_AnswerRanking_rankings[];
}

export interface AdminViewApplicationResponse_applicationResponse_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface AdminViewApplicationResponse_applicationResponse_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface AdminViewApplicationResponse_applicationResponse_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface AdminViewApplicationResponse_applicationResponse_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type AdminViewApplicationResponse_applicationResponse_response_components_questions = AdminViewApplicationResponse_applicationResponse_response_components_questions_AnswerMultiChoice | AdminViewApplicationResponse_applicationResponse_response_components_questions_AnswerCheckboxes | AdminViewApplicationResponse_applicationResponse_response_components_questions_AnswerRanking | AdminViewApplicationResponse_applicationResponse_response_components_questions_AnswerShortText | AdminViewApplicationResponse_applicationResponse_response_components_questions_AnswerLongText | AdminViewApplicationResponse_applicationResponse_response_components_questions_AnswerYesOrNo | AdminViewApplicationResponse_applicationResponse_response_components_questions_AnswerDate;

export interface AdminViewApplicationResponse_applicationResponse_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: AdminViewApplicationResponse_applicationResponse_response_components_questions[];
}

export interface AdminViewApplicationResponse_applicationResponse_response {
  __typename: "FormAnswer";
  components: AdminViewApplicationResponse_applicationResponse_response_components[];
}

export interface AdminViewApplicationResponse_applicationResponse_creator_verifiedRelation {
  __typename: "VerifiedRelation";
  isVerifiedRelation: boolean;
  customerId: string | null;
}

export interface AdminViewApplicationResponse_applicationResponse_creator {
  __typename: "ApplicationCreator";
  id: string;
  name: string;
  source: UserSource;
  verifiedRelation: AdminViewApplicationResponse_applicationResponse_creator_verifiedRelation;
  orgId: string;
}

export interface AdminViewApplicationResponse_applicationResponse_subject {
  __typename: "SubjectCoSigner";
  userId: string | null;
  customerId: string | null;
  name: string;
}

export interface AdminViewApplicationResponse_applicationResponse_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface AdminViewApplicationResponse_applicationResponse_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface AdminViewApplicationResponse_applicationResponse_completeSubject_user_enrolments_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface AdminViewApplicationResponse_applicationResponse_completeSubject_user_enrolments {
  __typename: "Enrolment";
  schoolUnit: AdminViewApplicationResponse_applicationResponse_completeSubject_user_enrolments_schoolUnit | null;
  schoolType: Code_SchoolType;
  startDate: any;
  endDate: any | null;
}

export interface AdminViewApplicationResponse_applicationResponse_completeSubject_user_placements_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface AdminViewApplicationResponse_applicationResponse_completeSubject_user_placements {
  __typename: "Placement";
  schoolUnit: AdminViewApplicationResponse_applicationResponse_completeSubject_user_placements_schoolUnit | null;
  schoolType: Code_SchoolType | null;
  startDate: any | null;
  endDate: any | null;
}

export interface AdminViewApplicationResponse_applicationResponse_completeSubject_user {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  enrolments: AdminViewApplicationResponse_applicationResponse_completeSubject_user_enrolments[] | null;
  placements: AdminViewApplicationResponse_applicationResponse_completeSubject_user_placements[] | null;
}

export interface AdminViewApplicationResponse_applicationResponse_completeSubject {
  __typename: "SubjectCoSigner";
  user: AdminViewApplicationResponse_applicationResponse_completeSubject_user | null;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions = AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionYesNo | AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionRanking | AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionShortText | AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionCheckboxes | AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions_FormQuestionMultiChoice;

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_questions[];
  templateId: string | null;
  attachments: AdminViewApplicationResponse_applicationResponse_application_form_componentData_components_attachments[] | null;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions = AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionYesNo | AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionRanking | AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionShortText | AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents_questions[];
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_componentData {
  __typename: "ComponentData";
  components: AdminViewApplicationResponse_applicationResponse_application_form_componentData_components[];
  predicateComponents: AdminViewApplicationResponse_applicationResponse_application_form_componentData_predicateComponents[] | null;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_translations_componentData_predicateComponents_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_translations_componentData_predicateComponents_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: AdminViewApplicationResponse_applicationResponse_application_form_translations_componentData_predicateComponents_questions_options[] | null;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_translations_componentData_predicateComponents {
  __typename: "TranslatePredicateComponent";
  id: string;
  language: ISO6391Language | null;
  questions: AdminViewApplicationResponse_applicationResponse_application_form_translations_componentData_predicateComponents_questions[];
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_translations_componentData_components_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_translations_componentData_components_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: AdminViewApplicationResponse_applicationResponse_application_form_translations_componentData_components_questions_options[] | null;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_translations_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_translations_componentData_components {
  __typename: "TranslateComponent";
  id: string;
  language: ISO6391Language | null;
  title: string;
  description: string;
  questions: AdminViewApplicationResponse_applicationResponse_application_form_translations_componentData_components_questions[];
  attachments: AdminViewApplicationResponse_applicationResponse_application_form_translations_componentData_components_attachments[];
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_translations_componentData {
  __typename: "TranslationComponentData";
  predicateComponents: AdminViewApplicationResponse_applicationResponse_application_form_translations_componentData_predicateComponents[] | null;
  components: AdminViewApplicationResponse_applicationResponse_application_form_translations_componentData_components[];
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_translations {
  __typename: "TranslationForm";
  formId: string;
  name: string;
  description: string;
  language: ISO6391Language;
  componentData: AdminViewApplicationResponse_applicationResponse_application_form_translations_componentData;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form_owner {
  __typename: "Organisation";
  id: string;
  displayName: string;
}

export interface AdminViewApplicationResponse_applicationResponse_application_form {
  __typename: "Form";
  id: string;
  type: FormType;
  subType: FormSubType;
  name: string;
  description: string | null;
  language: ISO6391Language;
  componentData: AdminViewApplicationResponse_applicationResponse_application_form_componentData;
  translations: AdminViewApplicationResponse_applicationResponse_application_form_translations[];
  owner: AdminViewApplicationResponse_applicationResponse_application_form_owner;
}

export interface AdminViewApplicationResponse_applicationResponse_application {
  __typename: "Application";
  id: string;
  name: string;
  singleGuardianSend: boolean;
  singleGuardianAcceptOffer: boolean;
  schoolunitSpecific: boolean;
  form: AdminViewApplicationResponse_applicationResponse_application_form;
}

export interface AdminViewApplicationResponse_applicationResponse {
  __typename: "ApplicationResponse";
  id: string;
  status: ApplicationResponseStatus;
  modified: any;
  seenByOrg: any | null;
  language: ISO6391Language;
  lastAction: AdminViewApplicationResponse_applicationResponse_lastAction;
  actions: AdminViewApplicationResponse_applicationResponse_actions[];
  signatures: AdminViewApplicationResponse_applicationResponse_signatures[];
  feedbackSignatures: AdminViewApplicationResponse_applicationResponse_feedbackSignatures[];
  response: AdminViewApplicationResponse_applicationResponse_response | null;
  creator: AdminViewApplicationResponse_applicationResponse_creator;
  subject: AdminViewApplicationResponse_applicationResponse_subject;
  lockHolder: AdminViewApplicationResponse_applicationResponse_lockHolder | null;
  feedbackId: string | null;
  feedbackDescription: string | null;
  lockExpire: any | null;
  schoolUnit: AdminViewApplicationResponse_applicationResponse_schoolUnit | null;
  completeSubject: AdminViewApplicationResponse_applicationResponse_completeSubject;
  application: AdminViewApplicationResponse_applicationResponse_application;
}

export interface AdminViewApplicationResponse {
  applicationResponse: AdminViewApplicationResponse_applicationResponse;
}

export interface AdminViewApplicationResponseVariables {
  id: string;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: applicationTableOrgApplications
// ====================================================

export interface applicationTableOrgApplications_organisation_applications_form {
  __typename: "Form";
  id: string;
  name: string;
  subType: FormSubType;
}

export interface applicationTableOrgApplications_organisation_applications_creator {
  __typename: "User" | "Client";
  id: string;
  name: string | null;
}

export interface applicationTableOrgApplications_organisation_applications_stats {
  __typename: "ApplicationStatistics";
  answered: number;
  open: number;
  closed: number;
}

export interface applicationTableOrgApplications_organisation_applications {
  __typename: "Application";
  id: string;
  name: string;
  closed: boolean;
  form: applicationTableOrgApplications_organisation_applications_form;
  creator: applicationTableOrgApplications_organisation_applications_creator | null;
  created: any;
  sendDate: any;
  singleGuardianSend: boolean;
  singleGuardianAcceptOffer: boolean;
  sent: boolean;
  stats: applicationTableOrgApplications_organisation_applications_stats;
}

export interface applicationTableOrgApplications_organisation {
  __typename: "Organisation";
  id: string;
  applications: applicationTableOrgApplications_organisation_applications[];
}

export interface applicationTableOrgApplications {
  organisation: applicationTableOrgApplications_organisation;
}

export interface applicationTableOrgApplicationsVariables {
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ClientWithPermissions
// ====================================================

export interface ClientWithPermissions_client_permissions_organisation_roles {
  __typename: "PermissionRole";
  roleId: PermissionType;
  sources: PermissionSource[];
}

export interface ClientWithPermissions_client_permissions_organisation {
  __typename: "OrganisationPermissionElement";
  resourceId: string;
  displayName: string | null;
  roles: ClientWithPermissions_client_permissions_organisation_roles[];
}

export interface ClientWithPermissions_client_permissions_schoolUnits_roles {
  __typename: "PermissionRole";
  roleId: PermissionType;
  sources: PermissionSource[];
}

export interface ClientWithPermissions_client_permissions_schoolUnits {
  __typename: "SchoolUnitPermissionElement";
  resourceId: string;
  displayName: string | null;
  employmentRoles: EmploymentRole[];
  roles: ClientWithPermissions_client_permissions_schoolUnits_roles[];
}

export interface ClientWithPermissions_client_permissions {
  __typename: "SubjectPermissions";
  id: string | null;
  organisation: ClientWithPermissions_client_permissions_organisation;
  schoolUnits: ClientWithPermissions_client_permissions_schoolUnits[];
}

export interface ClientWithPermissions_client {
  __typename: "Client";
  id: string;
  name: string | null;
  permissions: ClientWithPermissions_client_permissions;
}

export interface ClientWithPermissions_me_permissions_organisation_roles {
  __typename: "PermissionRole";
  roleId: PermissionType;
  sources: PermissionSource[];
}

export interface ClientWithPermissions_me_permissions_organisation {
  __typename: "OrganisationPermissionElement";
  resourceId: string;
  displayName: string | null;
  roles: ClientWithPermissions_me_permissions_organisation_roles[];
}

export interface ClientWithPermissions_me_permissions_schoolUnits_roles {
  __typename: "PermissionRole";
  roleId: PermissionType;
  sources: PermissionSource[];
}

export interface ClientWithPermissions_me_permissions_schoolUnits {
  __typename: "SchoolUnitPermissionElement";
  resourceId: string;
  displayName: string | null;
  employmentRoles: EmploymentRole[];
  roles: ClientWithPermissions_me_permissions_schoolUnits_roles[];
}

export interface ClientWithPermissions_me_permissions {
  __typename: "SubjectPermissions";
  id: string | null;
  organisation: ClientWithPermissions_me_permissions_organisation;
  schoolUnits: ClientWithPermissions_me_permissions_schoolUnits[];
}

export interface ClientWithPermissions_me {
  __typename: "User";
  id: string;
  name: string | null;
  permissions: ClientWithPermissions_me_permissions;
}

export interface ClientWithPermissions {
  client: ClientWithPermissions_client;
  me: ClientWithPermissions_me;
}

export interface ClientWithPermissionsVariables {
  id: string;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetClientPermission
// ====================================================

export interface SetClientPermission_setClientPermission_organisation_roles {
  __typename: "PermissionRole";
  roleId: PermissionType;
  sources: PermissionSource[];
}

export interface SetClientPermission_setClientPermission_organisation {
  __typename: "OrganisationPermissionElement";
  resourceId: string;
  displayName: string | null;
  roles: SetClientPermission_setClientPermission_organisation_roles[];
}

export interface SetClientPermission_setClientPermission_schoolUnits_roles {
  __typename: "PermissionRole";
  roleId: PermissionType;
  sources: PermissionSource[];
}

export interface SetClientPermission_setClientPermission_schoolUnits {
  __typename: "SchoolUnitPermissionElement";
  resourceId: string;
  displayName: string | null;
  employmentRoles: EmploymentRole[];
  roles: SetClientPermission_setClientPermission_schoolUnits_roles[];
}

export interface SetClientPermission_setClientPermission {
  __typename: "SubjectPermissions";
  id: string | null;
  organisation: SetClientPermission_setClientPermission_organisation;
  schoolUnits: SetClientPermission_setClientPermission_schoolUnits[];
}

export interface SetClientPermission {
  setClientPermission: SetClientPermission_setClientPermission;
}

export interface SetClientPermissionVariables {
  input: SetClientPermissionInput;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateApplication
// ====================================================

export interface CreateApplication_createApplication_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface CreateApplication_createApplication_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: CreateApplication_createApplication_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface CreateApplication_createApplication_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface CreateApplication_createApplication_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface CreateApplication_createApplication_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: CreateApplication_createApplication_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: CreateApplication_createApplication_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface CreateApplication_createApplication_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface CreateApplication_createApplication_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: CreateApplication_createApplication_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface CreateApplication_createApplication_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface CreateApplication_createApplication_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface CreateApplication_createApplication_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: CreateApplication_createApplication_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: CreateApplication_createApplication_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface CreateApplication_createApplication_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface CreateApplication_createApplication_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface CreateApplication_createApplication_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: CreateApplication_createApplication_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: CreateApplication_createApplication_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type CreateApplication_createApplication_form_componentData_components_questions = CreateApplication_createApplication_form_componentData_components_questions_FormQuestionYesNo | CreateApplication_createApplication_form_componentData_components_questions_FormQuestionRanking | CreateApplication_createApplication_form_componentData_components_questions_FormQuestionShortText | CreateApplication_createApplication_form_componentData_components_questions_FormQuestionCheckboxes | CreateApplication_createApplication_form_componentData_components_questions_FormQuestionMultiChoice;

export interface CreateApplication_createApplication_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface CreateApplication_createApplication_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: CreateApplication_createApplication_form_componentData_components_questions[];
  templateId: string | null;
  attachments: CreateApplication_createApplication_form_componentData_components_attachments[] | null;
}

export interface CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type CreateApplication_createApplication_form_componentData_predicateComponents_questions = CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionYesNo | CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionRanking | CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionShortText | CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | CreateApplication_createApplication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface CreateApplication_createApplication_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: CreateApplication_createApplication_form_componentData_predicateComponents_questions[];
}

export interface CreateApplication_createApplication_form_componentData {
  __typename: "ComponentData";
  components: CreateApplication_createApplication_form_componentData_components[];
  predicateComponents: CreateApplication_createApplication_form_componentData_predicateComponents[] | null;
}

export interface CreateApplication_createApplication_form_owner {
  __typename: "Organisation";
  id: string;
  displayName: string;
}

export interface CreateApplication_createApplication_form {
  __typename: "Form";
  id: string;
  type: FormType;
  subType: FormSubType;
  name: string;
  description: string | null;
  language: ISO6391Language;
  componentData: CreateApplication_createApplication_form_componentData;
  owner: CreateApplication_createApplication_form_owner;
}

export interface CreateApplication_createApplication {
  __typename: "Application";
  id: string;
  name: string;
  sent: boolean;
  sendDate: any;
  singleGuardianSend: boolean;
  singleGuardianAcceptOffer: boolean;
  schoolunitSpecific: boolean;
  closed: boolean;
  subscribable: boolean;
  form: CreateApplication_createApplication_form;
}

export interface CreateApplication {
  createApplication: CreateApplication_createApplication;
}

export interface CreateApplicationVariables {
  input: CreateApplicationInput;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveApplication
// ====================================================

export interface SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type SaveApplication_saveApplication_form_componentData_components_questions = SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionYesNo | SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionRanking | SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionShortText | SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionCheckboxes | SaveApplication_saveApplication_form_componentData_components_questions_FormQuestionMultiChoice;

export interface SaveApplication_saveApplication_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface SaveApplication_saveApplication_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: SaveApplication_saveApplication_form_componentData_components_questions[];
  templateId: string | null;
  attachments: SaveApplication_saveApplication_form_componentData_components_attachments[] | null;
}

export interface SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type SaveApplication_saveApplication_form_componentData_predicateComponents_questions = SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionYesNo | SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionRanking | SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionShortText | SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | SaveApplication_saveApplication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface SaveApplication_saveApplication_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: SaveApplication_saveApplication_form_componentData_predicateComponents_questions[];
}

export interface SaveApplication_saveApplication_form_componentData {
  __typename: "ComponentData";
  components: SaveApplication_saveApplication_form_componentData_components[];
  predicateComponents: SaveApplication_saveApplication_form_componentData_predicateComponents[] | null;
}

export interface SaveApplication_saveApplication_form_owner {
  __typename: "Organisation";
  id: string;
  displayName: string;
}

export interface SaveApplication_saveApplication_form {
  __typename: "Form";
  id: string;
  type: FormType;
  subType: FormSubType;
  name: string;
  description: string | null;
  language: ISO6391Language;
  componentData: SaveApplication_saveApplication_form_componentData;
  owner: SaveApplication_saveApplication_form_owner;
}

export interface SaveApplication_saveApplication {
  __typename: "Application";
  id: string;
  name: string;
  sent: boolean;
  sendDate: any;
  singleGuardianSend: boolean;
  singleGuardianAcceptOffer: boolean;
  schoolunitSpecific: boolean;
  closed: boolean;
  subscribable: boolean;
  form: SaveApplication_saveApplication_form;
}

export interface SaveApplication {
  saveApplication: SaveApplication_saveApplication;
}

export interface SaveApplicationVariables {
  input: SaveApplicationInput;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: publishApplicationData
// ====================================================

export interface publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionRanking_options[];
}

export interface publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type publishApplicationData_organisation_sendableForms_componentData_components_questions = publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionYesNo | publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionRanking | publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionShortText | publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionCheckboxes | publishApplicationData_organisation_sendableForms_componentData_components_questions_FormQuestionMultiChoice;

export interface publishApplicationData_organisation_sendableForms_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface publishApplicationData_organisation_sendableForms_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: publishApplicationData_organisation_sendableForms_componentData_components_questions[];
  templateId: string | null;
  attachments: publishApplicationData_organisation_sendableForms_componentData_components_attachments[] | null;
}

export interface publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions = publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionYesNo | publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionRanking | publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionShortText | publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionCheckboxes | publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface publishApplicationData_organisation_sendableForms_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: publishApplicationData_organisation_sendableForms_componentData_predicateComponents_questions[];
}

export interface publishApplicationData_organisation_sendableForms_componentData {
  __typename: "ComponentData";
  components: publishApplicationData_organisation_sendableForms_componentData_components[];
  predicateComponents: publishApplicationData_organisation_sendableForms_componentData_predicateComponents[] | null;
}

export interface publishApplicationData_organisation_sendableForms_owner {
  __typename: "Organisation";
  id: string;
  displayName: string;
}

export interface publishApplicationData_organisation_sendableForms {
  __typename: "Form";
  id: string;
  type: FormType;
  subType: FormSubType;
  name: string;
  description: string | null;
  language: ISO6391Language;
  componentData: publishApplicationData_organisation_sendableForms_componentData;
  owner: publishApplicationData_organisation_sendableForms_owner;
}

export interface publishApplicationData_organisation {
  __typename: "Organisation";
  id: string;
  sendableForms: publishApplicationData_organisation_sendableForms[];
}

export interface publishApplicationData {
  organisation: publishApplicationData_organisation;
}

export interface publishApplicationDataVariables {
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: application
// ====================================================

export interface application_application_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface application_application_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: application_application_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface application_application_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface application_application_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface application_application_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: application_application_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: application_application_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface application_application_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface application_application_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: application_application_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface application_application_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface application_application_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface application_application_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: application_application_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: application_application_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface application_application_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface application_application_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface application_application_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: application_application_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: application_application_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type application_application_form_componentData_components_questions = application_application_form_componentData_components_questions_FormQuestionYesNo | application_application_form_componentData_components_questions_FormQuestionRanking | application_application_form_componentData_components_questions_FormQuestionShortText | application_application_form_componentData_components_questions_FormQuestionCheckboxes | application_application_form_componentData_components_questions_FormQuestionMultiChoice;

export interface application_application_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface application_application_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: application_application_form_componentData_components_questions[];
  templateId: string | null;
  attachments: application_application_form_componentData_components_attachments[] | null;
}

export interface application_application_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface application_application_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: application_application_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface application_application_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface application_application_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface application_application_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: application_application_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: application_application_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface application_application_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface application_application_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: application_application_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface application_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface application_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface application_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: application_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: application_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface application_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface application_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface application_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: application_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: application_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type application_application_form_componentData_predicateComponents_questions = application_application_form_componentData_predicateComponents_questions_FormQuestionYesNo | application_application_form_componentData_predicateComponents_questions_FormQuestionRanking | application_application_form_componentData_predicateComponents_questions_FormQuestionShortText | application_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | application_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface application_application_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: application_application_form_componentData_predicateComponents_questions[];
}

export interface application_application_form_componentData {
  __typename: "ComponentData";
  components: application_application_form_componentData_components[];
  predicateComponents: application_application_form_componentData_predicateComponents[] | null;
}

export interface application_application_form_owner {
  __typename: "Organisation";
  id: string;
  displayName: string;
}

export interface application_application_form {
  __typename: "Form";
  id: string;
  type: FormType;
  subType: FormSubType;
  name: string;
  description: string | null;
  language: ISO6391Language;
  componentData: application_application_form_componentData;
  owner: application_application_form_owner;
}

export interface application_application {
  __typename: "Application";
  id: string;
  name: string;
  sent: boolean;
  sendDate: any;
  singleGuardianSend: boolean;
  singleGuardianAcceptOffer: boolean;
  schoolunitSpecific: boolean;
  closed: boolean;
  subscribable: boolean;
  form: application_application_form;
}

export interface application {
  application: application_application;
}

export interface applicationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFormTranslation
// ====================================================

export interface CreateFormTranslation_createFormTranslation_componentData_predicateComponents_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface CreateFormTranslation_createFormTranslation_componentData_predicateComponents_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: CreateFormTranslation_createFormTranslation_componentData_predicateComponents_questions_options[] | null;
}

export interface CreateFormTranslation_createFormTranslation_componentData_predicateComponents {
  __typename: "TranslatePredicateComponent";
  id: string;
  language: ISO6391Language | null;
  questions: CreateFormTranslation_createFormTranslation_componentData_predicateComponents_questions[];
}

export interface CreateFormTranslation_createFormTranslation_componentData_components_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface CreateFormTranslation_createFormTranslation_componentData_components_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: CreateFormTranslation_createFormTranslation_componentData_components_questions_options[] | null;
}

export interface CreateFormTranslation_createFormTranslation_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface CreateFormTranslation_createFormTranslation_componentData_components {
  __typename: "TranslateComponent";
  id: string;
  language: ISO6391Language | null;
  title: string;
  description: string;
  questions: CreateFormTranslation_createFormTranslation_componentData_components_questions[];
  attachments: CreateFormTranslation_createFormTranslation_componentData_components_attachments[];
}

export interface CreateFormTranslation_createFormTranslation_componentData {
  __typename: "TranslationComponentData";
  predicateComponents: CreateFormTranslation_createFormTranslation_componentData_predicateComponents[] | null;
  components: CreateFormTranslation_createFormTranslation_componentData_components[];
}

export interface CreateFormTranslation_createFormTranslation {
  __typename: "TranslationForm";
  formId: string;
  name: string;
  description: string;
  language: ISO6391Language;
  componentData: CreateFormTranslation_createFormTranslation_componentData;
}

export interface CreateFormTranslation {
  createFormTranslation: CreateFormTranslation_createFormTranslation;
}

export interface CreateFormTranslationVariables {
  formId: string;
  language: ISO6391Language;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteFormTranslation
// ====================================================

export interface DeleteFormTranslation_deleteFormTranslation_componentData_predicateComponents_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface DeleteFormTranslation_deleteFormTranslation_componentData_predicateComponents_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: DeleteFormTranslation_deleteFormTranslation_componentData_predicateComponents_questions_options[] | null;
}

export interface DeleteFormTranslation_deleteFormTranslation_componentData_predicateComponents {
  __typename: "TranslatePredicateComponent";
  id: string;
  language: ISO6391Language | null;
  questions: DeleteFormTranslation_deleteFormTranslation_componentData_predicateComponents_questions[];
}

export interface DeleteFormTranslation_deleteFormTranslation_componentData_components_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface DeleteFormTranslation_deleteFormTranslation_componentData_components_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: DeleteFormTranslation_deleteFormTranslation_componentData_components_questions_options[] | null;
}

export interface DeleteFormTranslation_deleteFormTranslation_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface DeleteFormTranslation_deleteFormTranslation_componentData_components {
  __typename: "TranslateComponent";
  id: string;
  language: ISO6391Language | null;
  title: string;
  description: string;
  questions: DeleteFormTranslation_deleteFormTranslation_componentData_components_questions[];
  attachments: DeleteFormTranslation_deleteFormTranslation_componentData_components_attachments[];
}

export interface DeleteFormTranslation_deleteFormTranslation_componentData {
  __typename: "TranslationComponentData";
  predicateComponents: DeleteFormTranslation_deleteFormTranslation_componentData_predicateComponents[] | null;
  components: DeleteFormTranslation_deleteFormTranslation_componentData_components[];
}

export interface DeleteFormTranslation_deleteFormTranslation {
  __typename: "TranslationForm";
  formId: string;
  name: string;
  description: string;
  language: ISO6391Language;
  componentData: DeleteFormTranslation_deleteFormTranslation_componentData;
}

export interface DeleteFormTranslation {
  deleteFormTranslation: DeleteFormTranslation_deleteFormTranslation;
}

export interface DeleteFormTranslationVariables {
  formId: string;
  language: ISO6391Language;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LockForm
// ====================================================

export interface LockForm_lockForm_creator {
  __typename: "User" | "Client";
  id: string;
  name: string | null;
}

export interface LockForm_lockForm {
  __typename: "Form";
  id: string;
  type: FormType;
  name: string;
  creator: LockForm_lockForm_creator | null;
  created: any;
  locked: boolean;
}

export interface LockForm {
  lockForm: LockForm_lockForm;
}

export interface LockFormVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: templates
// ====================================================

export interface templates_templates_componentData_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface templates_templates_componentData_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: templates_templates_componentData_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface templates_templates_componentData_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface templates_templates_componentData_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface templates_templates_componentData_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: templates_templates_componentData_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: templates_templates_componentData_questions_FormQuestionRanking_options[];
}

export interface templates_templates_componentData_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface templates_templates_componentData_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: templates_templates_componentData_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface templates_templates_componentData_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface templates_templates_componentData_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface templates_templates_componentData_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: templates_templates_componentData_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: templates_templates_componentData_questions_FormQuestionCheckboxes_options[];
}

export interface templates_templates_componentData_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface templates_templates_componentData_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface templates_templates_componentData_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: templates_templates_componentData_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: templates_templates_componentData_questions_FormQuestionMultiChoice_options[];
}

export type templates_templates_componentData_questions = templates_templates_componentData_questions_FormQuestionYesNo | templates_templates_componentData_questions_FormQuestionRanking | templates_templates_componentData_questions_FormQuestionShortText | templates_templates_componentData_questions_FormQuestionCheckboxes | templates_templates_componentData_questions_FormQuestionMultiChoice;

export interface templates_templates_componentData_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface templates_templates_componentData {
  __typename: "ComponentTemplate";
  id: string;
  title: string;
  description: string;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: templates_templates_componentData_questions[];
  attachments: templates_templates_componentData_attachments[];
}

export interface templates_templates_translations_componentData_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface templates_templates_translations_componentData_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: templates_templates_translations_componentData_questions_options[] | null;
}

export interface templates_templates_translations_componentData_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface templates_templates_translations_componentData {
  __typename: "TranslationComponentTemplate";
  id: string;
  title: string;
  description: string;
  questions: templates_templates_translations_componentData_questions[];
  attachments: templates_templates_translations_componentData_attachments[];
}

export interface templates_templates_translations {
  __typename: "TemplateTranslation";
  id: string;
  language: ISO6391Language;
  published: boolean;
  componentData: templates_templates_translations_componentData;
}

export interface templates_templates {
  __typename: "Template";
  id: string;
  language: ISO6391Language;
  componentData: templates_templates_componentData;
  translations: templates_templates_translations[];
}

export interface templates {
  templates: templates_templates[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: form
// ====================================================

export interface form_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface form_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: form_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface form_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface form_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface form_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: form_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: form_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface form_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface form_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: form_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface form_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface form_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface form_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: form_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: form_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface form_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface form_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface form_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: form_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: form_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type form_form_componentData_components_questions = form_form_componentData_components_questions_FormQuestionYesNo | form_form_componentData_components_questions_FormQuestionRanking | form_form_componentData_components_questions_FormQuestionShortText | form_form_componentData_components_questions_FormQuestionCheckboxes | form_form_componentData_components_questions_FormQuestionMultiChoice;

export interface form_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface form_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: form_form_componentData_components_questions[];
  templateId: string | null;
  attachments: form_form_componentData_components_attachments[] | null;
}

export interface form_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface form_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: form_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface form_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface form_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface form_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: form_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: form_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface form_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface form_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: form_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface form_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface form_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface form_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: form_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: form_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface form_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface form_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface form_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: form_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: form_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type form_form_componentData_predicateComponents_questions = form_form_componentData_predicateComponents_questions_FormQuestionYesNo | form_form_componentData_predicateComponents_questions_FormQuestionRanking | form_form_componentData_predicateComponents_questions_FormQuestionShortText | form_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | form_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface form_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: form_form_componentData_predicateComponents_questions[];
}

export interface form_form_componentData {
  __typename: "ComponentData";
  components: form_form_componentData_components[];
  predicateComponents: form_form_componentData_predicateComponents[] | null;
}

export interface form_form_access_schoolUnits {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface form_form_access {
  __typename: "FormAccess";
  public: boolean;
  organisation: boolean;
  schoolUnits: (form_form_access_schoolUnits | null)[];
}

export interface form_form_owner {
  __typename: "Organisation";
  id: string;
  displayName: string;
}

export interface form_form_translations_componentData_predicateComponents_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface form_form_translations_componentData_predicateComponents_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: form_form_translations_componentData_predicateComponents_questions_options[] | null;
}

export interface form_form_translations_componentData_predicateComponents {
  __typename: "TranslatePredicateComponent";
  id: string;
  language: ISO6391Language | null;
  questions: form_form_translations_componentData_predicateComponents_questions[];
}

export interface form_form_translations_componentData_components_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface form_form_translations_componentData_components_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: form_form_translations_componentData_components_questions_options[] | null;
}

export interface form_form_translations_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface form_form_translations_componentData_components {
  __typename: "TranslateComponent";
  id: string;
  language: ISO6391Language | null;
  title: string;
  description: string;
  questions: form_form_translations_componentData_components_questions[];
  attachments: form_form_translations_componentData_components_attachments[];
}

export interface form_form_translations_componentData {
  __typename: "TranslationComponentData";
  predicateComponents: form_form_translations_componentData_predicateComponents[] | null;
  components: form_form_translations_componentData_components[];
}

export interface form_form_translations {
  __typename: "TranslationForm";
  formId: string;
  name: string;
  description: string;
  language: ISO6391Language;
  componentData: form_form_translations_componentData;
}

export interface form_form {
  __typename: "Form";
  id: string;
  type: FormType;
  subType: FormSubType;
  archived: boolean;
  locked: boolean;
  name: string;
  description: string | null;
  language: ISO6391Language;
  translationLanguages: ISO6391Language[];
  tags: string[];
  componentData: form_form_componentData;
  access: form_form_access;
  owner: form_form_owner;
  translations: form_form_translations[];
}

export interface form {
  form: form_form;
}

export interface formVariables {
  id: string;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveForm
// ====================================================

export interface SaveForm_saveForm_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface SaveForm_saveForm_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: SaveForm_saveForm_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface SaveForm_saveForm_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface SaveForm_saveForm_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface SaveForm_saveForm_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: SaveForm_saveForm_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: SaveForm_saveForm_componentData_components_questions_FormQuestionRanking_options[];
}

export interface SaveForm_saveForm_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface SaveForm_saveForm_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: SaveForm_saveForm_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface SaveForm_saveForm_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface SaveForm_saveForm_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface SaveForm_saveForm_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: SaveForm_saveForm_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: SaveForm_saveForm_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface SaveForm_saveForm_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface SaveForm_saveForm_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface SaveForm_saveForm_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: SaveForm_saveForm_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: SaveForm_saveForm_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type SaveForm_saveForm_componentData_components_questions = SaveForm_saveForm_componentData_components_questions_FormQuestionYesNo | SaveForm_saveForm_componentData_components_questions_FormQuestionRanking | SaveForm_saveForm_componentData_components_questions_FormQuestionShortText | SaveForm_saveForm_componentData_components_questions_FormQuestionCheckboxes | SaveForm_saveForm_componentData_components_questions_FormQuestionMultiChoice;

export interface SaveForm_saveForm_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface SaveForm_saveForm_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: SaveForm_saveForm_componentData_components_questions[];
  templateId: string | null;
  attachments: SaveForm_saveForm_componentData_components_attachments[] | null;
}

export interface SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type SaveForm_saveForm_componentData_predicateComponents_questions = SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionYesNo | SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionRanking | SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionShortText | SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionCheckboxes | SaveForm_saveForm_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface SaveForm_saveForm_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: SaveForm_saveForm_componentData_predicateComponents_questions[];
}

export interface SaveForm_saveForm_componentData {
  __typename: "ComponentData";
  components: SaveForm_saveForm_componentData_components[];
  predicateComponents: SaveForm_saveForm_componentData_predicateComponents[] | null;
}

export interface SaveForm_saveForm_access_schoolUnits {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface SaveForm_saveForm_access {
  __typename: "FormAccess";
  public: boolean;
  organisation: boolean;
  schoolUnits: (SaveForm_saveForm_access_schoolUnits | null)[];
}

export interface SaveForm_saveForm {
  __typename: "Form";
  id: string;
  type: FormType;
  name: string;
  description: string | null;
  modified: any;
  language: ISO6391Language;
  tags: string[];
  componentData: SaveForm_saveForm_componentData;
  access: SaveForm_saveForm_access;
}

export interface SaveForm {
  saveForm: SaveForm_saveForm;
}

export interface SaveFormVariables {
  input: SaveFormInput;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFormTranslation
// ====================================================

export interface UpdateFormTranslation_updateFormTranslation_componentData_predicateComponents_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface UpdateFormTranslation_updateFormTranslation_componentData_predicateComponents_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: UpdateFormTranslation_updateFormTranslation_componentData_predicateComponents_questions_options[] | null;
}

export interface UpdateFormTranslation_updateFormTranslation_componentData_predicateComponents {
  __typename: "TranslatePredicateComponent";
  id: string;
  language: ISO6391Language | null;
  questions: UpdateFormTranslation_updateFormTranslation_componentData_predicateComponents_questions[];
}

export interface UpdateFormTranslation_updateFormTranslation_componentData_components_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface UpdateFormTranslation_updateFormTranslation_componentData_components_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: UpdateFormTranslation_updateFormTranslation_componentData_components_questions_options[] | null;
}

export interface UpdateFormTranslation_updateFormTranslation_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface UpdateFormTranslation_updateFormTranslation_componentData_components {
  __typename: "TranslateComponent";
  id: string;
  language: ISO6391Language | null;
  title: string;
  description: string;
  questions: UpdateFormTranslation_updateFormTranslation_componentData_components_questions[];
  attachments: UpdateFormTranslation_updateFormTranslation_componentData_components_attachments[];
}

export interface UpdateFormTranslation_updateFormTranslation_componentData {
  __typename: "TranslationComponentData";
  predicateComponents: UpdateFormTranslation_updateFormTranslation_componentData_predicateComponents[] | null;
  components: UpdateFormTranslation_updateFormTranslation_componentData_components[];
}

export interface UpdateFormTranslation_updateFormTranslation {
  __typename: "TranslationForm";
  formId: string;
  name: string;
  description: string;
  language: ISO6391Language;
  componentData: UpdateFormTranslation_updateFormTranslation_componentData;
}

export interface UpdateFormTranslation {
  updateFormTranslation: UpdateFormTranslation_updateFormTranslation;
}

export interface UpdateFormTranslationVariables {
  input: UpdateFormTranslationInput;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFormTranslations
// ====================================================

export interface UpdateFormTranslations_updateFormTranslations_componentData_predicateComponents_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface UpdateFormTranslations_updateFormTranslations_componentData_predicateComponents_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: UpdateFormTranslations_updateFormTranslations_componentData_predicateComponents_questions_options[] | null;
}

export interface UpdateFormTranslations_updateFormTranslations_componentData_predicateComponents {
  __typename: "TranslatePredicateComponent";
  id: string;
  language: ISO6391Language | null;
  questions: UpdateFormTranslations_updateFormTranslations_componentData_predicateComponents_questions[];
}

export interface UpdateFormTranslations_updateFormTranslations_componentData_components_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface UpdateFormTranslations_updateFormTranslations_componentData_components_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: UpdateFormTranslations_updateFormTranslations_componentData_components_questions_options[] | null;
}

export interface UpdateFormTranslations_updateFormTranslations_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface UpdateFormTranslations_updateFormTranslations_componentData_components {
  __typename: "TranslateComponent";
  id: string;
  language: ISO6391Language | null;
  title: string;
  description: string;
  questions: UpdateFormTranslations_updateFormTranslations_componentData_components_questions[];
  attachments: UpdateFormTranslations_updateFormTranslations_componentData_components_attachments[];
}

export interface UpdateFormTranslations_updateFormTranslations_componentData {
  __typename: "TranslationComponentData";
  predicateComponents: UpdateFormTranslations_updateFormTranslations_componentData_predicateComponents[] | null;
  components: UpdateFormTranslations_updateFormTranslations_componentData_components[];
}

export interface UpdateFormTranslations_updateFormTranslations {
  __typename: "TranslationForm";
  formId: string;
  name: string;
  description: string;
  language: ISO6391Language;
  componentData: UpdateFormTranslations_updateFormTranslations_componentData;
}

export interface UpdateFormTranslations {
  updateFormTranslations: UpdateFormTranslations_updateFormTranslations[];
}

export interface UpdateFormTranslationsVariables {
  input: UpdateFormTranslationInput[];
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: replaceablePublications
// ====================================================

export interface replaceablePublications_replaceablePublications_form {
  __typename: "Form";
  id: string;
  name: string;
  language: ISO6391Language;
}

export interface replaceablePublications_replaceablePublications {
  __typename: "Publication";
  id: string;
  name: string;
  form: replaceablePublications_replaceablePublications_form;
  validTo: any;
}

export interface replaceablePublications {
  replaceablePublications: replaceablePublications_replaceablePublications[];
}

export interface replaceablePublicationsVariables {
  formId: string;
  minimumValidTo: any;
  timeZoneName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: publishFormData
// ====================================================

export interface publishFormData_organisation_schoolUnits_classes {
  __typename: "StudentGroup";
  id: string;
  customerId: string;
  schoolType: Code_SchoolType | null;
  displayName: string;
  memberCount: number;
  groupType: Code_GroupType | null;
}

export interface publishFormData_organisation_schoolUnits {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
  schoolTypes: Code_SchoolType[] | null;
  classes: publishFormData_organisation_schoolUnits_classes[];
}

export interface publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionRanking_options[];
}

export interface publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type publishFormData_organisation_sendableForms_componentData_components_questions = publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionYesNo | publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionRanking | publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionShortText | publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionCheckboxes | publishFormData_organisation_sendableForms_componentData_components_questions_FormQuestionMultiChoice;

export interface publishFormData_organisation_sendableForms_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface publishFormData_organisation_sendableForms_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: publishFormData_organisation_sendableForms_componentData_components_questions[];
  templateId: string | null;
  attachments: publishFormData_organisation_sendableForms_componentData_components_attachments[] | null;
}

export interface publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type publishFormData_organisation_sendableForms_componentData_predicateComponents_questions = publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionYesNo | publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionRanking | publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionShortText | publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionCheckboxes | publishFormData_organisation_sendableForms_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface publishFormData_organisation_sendableForms_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: publishFormData_organisation_sendableForms_componentData_predicateComponents_questions[];
}

export interface publishFormData_organisation_sendableForms_componentData {
  __typename: "ComponentData";
  components: publishFormData_organisation_sendableForms_componentData_components[];
  predicateComponents: publishFormData_organisation_sendableForms_componentData_predicateComponents[] | null;
}

export interface publishFormData_organisation_sendableForms_owner {
  __typename: "Organisation";
  id: string;
  displayName: string;
}

export interface publishFormData_organisation_sendableForms {
  __typename: "Form";
  id: string;
  type: FormType;
  name: string;
  description: string | null;
  language: ISO6391Language;
  componentData: publishFormData_organisation_sendableForms_componentData;
  owner: publishFormData_organisation_sendableForms_owner;
}

export interface publishFormData_organisation {
  __typename: "Organisation";
  id: string;
  schoolUnits: publishFormData_organisation_schoolUnits[];
  sendableForms: publishFormData_organisation_sendableForms[];
}

export interface publishFormData {
  organisation: publishFormData_organisation;
}

export interface publishFormDataVariables {
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Save
// ====================================================

export interface Save_savePublication_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface Save_savePublication_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: Save_savePublication_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface Save_savePublication_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface Save_savePublication_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface Save_savePublication_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: Save_savePublication_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: Save_savePublication_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface Save_savePublication_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface Save_savePublication_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: Save_savePublication_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface Save_savePublication_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface Save_savePublication_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface Save_savePublication_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: Save_savePublication_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: Save_savePublication_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface Save_savePublication_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface Save_savePublication_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface Save_savePublication_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: Save_savePublication_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: Save_savePublication_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type Save_savePublication_form_componentData_components_questions = Save_savePublication_form_componentData_components_questions_FormQuestionYesNo | Save_savePublication_form_componentData_components_questions_FormQuestionRanking | Save_savePublication_form_componentData_components_questions_FormQuestionShortText | Save_savePublication_form_componentData_components_questions_FormQuestionCheckboxes | Save_savePublication_form_componentData_components_questions_FormQuestionMultiChoice;

export interface Save_savePublication_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface Save_savePublication_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: Save_savePublication_form_componentData_components_questions[];
  templateId: string | null;
  attachments: Save_savePublication_form_componentData_components_attachments[] | null;
}

export interface Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type Save_savePublication_form_componentData_predicateComponents_questions = Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionYesNo | Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionRanking | Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionShortText | Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | Save_savePublication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface Save_savePublication_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: Save_savePublication_form_componentData_predicateComponents_questions[];
}

export interface Save_savePublication_form_componentData {
  __typename: "ComponentData";
  components: Save_savePublication_form_componentData_components[];
  predicateComponents: Save_savePublication_form_componentData_predicateComponents[] | null;
}

export interface Save_savePublication_form_owner {
  __typename: "Organisation";
  id: string;
  displayName: string;
}

export interface Save_savePublication_form {
  __typename: "Form";
  id: string;
  type: FormType;
  name: string;
  description: string | null;
  language: ISO6391Language;
  componentData: Save_savePublication_form_componentData;
  owner: Save_savePublication_form_owner;
}

export interface Save_savePublication_recipients_groups_edges {
  __typename: "PublicationGroupStudentGroup";
  id: string;
  groupId: string;
  customerId: string;
  schoolYearFilter: number[] | null;
  type: PublicationGroupType;
  displayName: string;
  removed: boolean;
}

export interface Save_savePublication_recipients_groups {
  __typename: "PublicationGroupConnection";
  edges: Save_savePublication_recipients_groups_edges[];
}

export interface Save_savePublication_recipients {
  __typename: "PublicationRecipientList";
  groups: Save_savePublication_recipients_groups;
}

export interface Save_savePublication {
  __typename: "Publication";
  id: string;
  form: Save_savePublication_form;
  recipients: Save_savePublication_recipients;
  name: string;
  validFrom: any;
  validTo: any;
  sendDate: any;
  sent: boolean;
  singleGuardian: boolean;
  childResponse: boolean;
  skipEmails: boolean;
  tags: string[];
}

export interface Save {
  savePublication: Save_savePublication;
}

export interface SaveVariables {
  input: SavePublicationInput;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Publish
// ====================================================

export interface Publish_createPublication_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface Publish_createPublication_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: Publish_createPublication_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface Publish_createPublication_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface Publish_createPublication_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface Publish_createPublication_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: Publish_createPublication_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: Publish_createPublication_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface Publish_createPublication_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface Publish_createPublication_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: Publish_createPublication_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface Publish_createPublication_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface Publish_createPublication_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface Publish_createPublication_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: Publish_createPublication_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: Publish_createPublication_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface Publish_createPublication_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface Publish_createPublication_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface Publish_createPublication_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: Publish_createPublication_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: Publish_createPublication_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type Publish_createPublication_form_componentData_components_questions = Publish_createPublication_form_componentData_components_questions_FormQuestionYesNo | Publish_createPublication_form_componentData_components_questions_FormQuestionRanking | Publish_createPublication_form_componentData_components_questions_FormQuestionShortText | Publish_createPublication_form_componentData_components_questions_FormQuestionCheckboxes | Publish_createPublication_form_componentData_components_questions_FormQuestionMultiChoice;

export interface Publish_createPublication_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface Publish_createPublication_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: Publish_createPublication_form_componentData_components_questions[];
  templateId: string | null;
  attachments: Publish_createPublication_form_componentData_components_attachments[] | null;
}

export interface Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type Publish_createPublication_form_componentData_predicateComponents_questions = Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionYesNo | Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionRanking | Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionShortText | Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | Publish_createPublication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface Publish_createPublication_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: Publish_createPublication_form_componentData_predicateComponents_questions[];
}

export interface Publish_createPublication_form_componentData {
  __typename: "ComponentData";
  components: Publish_createPublication_form_componentData_components[];
  predicateComponents: Publish_createPublication_form_componentData_predicateComponents[] | null;
}

export interface Publish_createPublication_form_owner {
  __typename: "Organisation";
  id: string;
  displayName: string;
}

export interface Publish_createPublication_form {
  __typename: "Form";
  id: string;
  type: FormType;
  name: string;
  description: string | null;
  language: ISO6391Language;
  componentData: Publish_createPublication_form_componentData;
  owner: Publish_createPublication_form_owner;
}

export interface Publish_createPublication_recipients_groups_edges {
  __typename: "PublicationGroupStudentGroup";
  id: string;
  groupId: string;
  customerId: string;
  schoolYearFilter: number[] | null;
  type: PublicationGroupType;
  displayName: string;
  removed: boolean;
}

export interface Publish_createPublication_recipients_groups {
  __typename: "PublicationGroupConnection";
  edges: Publish_createPublication_recipients_groups_edges[];
}

export interface Publish_createPublication_recipients {
  __typename: "PublicationRecipientList";
  groups: Publish_createPublication_recipients_groups;
}

export interface Publish_createPublication {
  __typename: "Publication";
  id: string;
  form: Publish_createPublication_form;
  recipients: Publish_createPublication_recipients;
  name: string;
  validFrom: any;
  validTo: any;
  sendDate: any;
  sent: boolean;
  singleGuardian: boolean;
  childResponse: boolean;
  skipEmails: boolean;
  tags: string[];
}

export interface Publish {
  createPublication: Publish_createPublication;
}

export interface PublishVariables {
  input: CreatePublicationInput;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: publication
// ====================================================

export interface publication_publication_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publication_publication_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publication_publication_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface publication_publication_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publication_publication_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publication_publication_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publication_publication_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: publication_publication_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface publication_publication_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publication_publication_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publication_publication_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface publication_publication_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publication_publication_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publication_publication_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publication_publication_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: publication_publication_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface publication_publication_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publication_publication_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publication_publication_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publication_publication_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: publication_publication_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type publication_publication_form_componentData_components_questions = publication_publication_form_componentData_components_questions_FormQuestionYesNo | publication_publication_form_componentData_components_questions_FormQuestionRanking | publication_publication_form_componentData_components_questions_FormQuestionShortText | publication_publication_form_componentData_components_questions_FormQuestionCheckboxes | publication_publication_form_componentData_components_questions_FormQuestionMultiChoice;

export interface publication_publication_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface publication_publication_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: publication_publication_form_componentData_components_questions[];
  templateId: string | null;
  attachments: publication_publication_form_componentData_components_attachments[] | null;
}

export interface publication_publication_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publication_publication_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publication_publication_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface publication_publication_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publication_publication_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publication_publication_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publication_publication_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: publication_publication_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface publication_publication_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publication_publication_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publication_publication_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface publication_publication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publication_publication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publication_publication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publication_publication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: publication_publication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface publication_publication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publication_publication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publication_publication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publication_publication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: publication_publication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type publication_publication_form_componentData_predicateComponents_questions = publication_publication_form_componentData_predicateComponents_questions_FormQuestionYesNo | publication_publication_form_componentData_predicateComponents_questions_FormQuestionRanking | publication_publication_form_componentData_predicateComponents_questions_FormQuestionShortText | publication_publication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | publication_publication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface publication_publication_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: publication_publication_form_componentData_predicateComponents_questions[];
}

export interface publication_publication_form_componentData {
  __typename: "ComponentData";
  components: publication_publication_form_componentData_components[];
  predicateComponents: publication_publication_form_componentData_predicateComponents[] | null;
}

export interface publication_publication_form_owner {
  __typename: "Organisation";
  id: string;
  displayName: string;
}

export interface publication_publication_form {
  __typename: "Form";
  id: string;
  type: FormType;
  name: string;
  description: string | null;
  language: ISO6391Language;
  componentData: publication_publication_form_componentData;
  owner: publication_publication_form_owner;
}

export interface publication_publication_recipients_groups_edges {
  __typename: "PublicationGroupStudentGroup";
  id: string;
  groupId: string;
  customerId: string;
  schoolYearFilter: number[] | null;
  type: PublicationGroupType;
  displayName: string;
  removed: boolean;
}

export interface publication_publication_recipients_groups {
  __typename: "PublicationGroupConnection";
  edges: publication_publication_recipients_groups_edges[];
}

export interface publication_publication_recipients {
  __typename: "PublicationRecipientList";
  groups: publication_publication_recipients_groups;
}

export interface publication_publication {
  __typename: "Publication";
  id: string;
  form: publication_publication_form;
  recipients: publication_publication_recipients;
  name: string;
  validFrom: any;
  validTo: any;
  sendDate: any;
  sent: boolean;
  singleGuardian: boolean;
  childResponse: boolean;
  skipEmails: boolean;
  tags: string[];
}

export interface publication {
  publication: publication_publication;
}

export interface publicationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: demoPublicationQuery
// ====================================================

export interface demoPublicationQuery_organisation_publications_form {
  __typename: "Form";
  id: string;
  name: string;
}

export interface demoPublicationQuery_organisation_publications_creator {
  __typename: "User" | "Client";
  id: string;
  name: string | null;
}

export interface demoPublicationQuery_organisation_publications_stats {
  __typename: "PublicationStatistics";
  total: number;
  groups: number;
  sent: number;
  answered: number;
  unanswered: number;
  undeliverableNotifications: number;
}

export interface demoPublicationQuery_organisation_publications_recipients_groups_edges {
  __typename: "PublicationGroupStudentGroup";
  id: string;
  type: PublicationGroupType;
  displayName: string;
  removed: boolean;
  memberCount: number;
}

export interface demoPublicationQuery_organisation_publications_recipients_groups {
  __typename: "PublicationGroupConnection";
  edges: demoPublicationQuery_organisation_publications_recipients_groups_edges[];
}

export interface demoPublicationQuery_organisation_publications_recipients {
  __typename: "PublicationRecipientList";
  groups: demoPublicationQuery_organisation_publications_recipients_groups;
}

export interface demoPublicationQuery_organisation_publications {
  __typename: "Publication";
  id: string;
  name: string;
  form: demoPublicationQuery_organisation_publications_form;
  creator: demoPublicationQuery_organisation_publications_creator | null;
  created: any;
  sendDate: any;
  validFrom: any;
  validTo: any;
  lastDryrun: any | null;
  lastNotified: any | null;
  sent: boolean;
  stats: demoPublicationQuery_organisation_publications_stats;
  recipients: demoPublicationQuery_organisation_publications_recipients;
}

export interface demoPublicationQuery_organisation {
  __typename: "Organisation";
  id: string;
  publications: demoPublicationQuery_organisation_publications[];
}

export interface demoPublicationQuery {
  organisation: demoPublicationQuery_organisation;
}

export interface demoPublicationQueryVariables {
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createResponseMutation
// ====================================================

export interface createResponseMutation_demoCreateResponses {
  __typename: "Response";
  code: ReturnCode | null;
}

export interface createResponseMutation {
  demoCreateResponses: createResponseMutation_demoCreateResponses;
}

export interface createResponseMutationVariables {
  publicationId: string;
  count: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setPublicationAsSent
// ====================================================

export interface setPublicationAsSent_demoMarkPublicationSent {
  __typename: "Publication";
  id: string;
  sent: boolean;
}

export interface setPublicationAsSent {
  demoMarkPublicationSent: setPublicationAsSent_demoMarkPublicationSent;
}

export interface setPublicationAsSentVariables {
  publicationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminSearchUsers
// ====================================================

export interface AdminSearchUsers_searchUsers_users_studentGroupMemberships_studentGroup_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  displayName: string;
}

export interface AdminSearchUsers_searchUsers_users_studentGroupMemberships_studentGroup {
  __typename: "StudentGroup";
  id: string;
  displayName: string;
  schoolUnit: AdminSearchUsers_searchUsers_users_studentGroupMemberships_studentGroup_schoolUnit;
}

export interface AdminSearchUsers_searchUsers_users_studentGroupMemberships {
  __typename: "StudentGroupMembership";
  studentGroup: AdminSearchUsers_searchUsers_users_studentGroupMemberships_studentGroup | null;
}

export interface AdminSearchUsers_searchUsers_users {
  __typename: "User";
  id: string;
  name: string | null;
  source: UserSource;
  customerId: string | null;
  studentGroupMemberships: AdminSearchUsers_searchUsers_users_studentGroupMemberships[] | null;
}

export interface AdminSearchUsers_searchUsers {
  __typename: "SearchUsersResult";
  hasMoreResults: boolean;
  users: AdminSearchUsers_searchUsers_users[];
}

export interface AdminSearchUsers {
  searchUsers: AdminSearchUsers_searchUsers;
}

export interface AdminSearchUsersVariables {
  key: string;
  page: number;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminViewFormResponse
// ====================================================

export interface AdminViewFormResponse_publicationResponse_user {
  __typename: "User";
  id: string;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface AdminViewFormResponse_publicationResponse_signatures_signer {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  idp: string | null;
  idpSub: string | null;
  name: string | null;
}

export interface AdminViewFormResponse_publicationResponse_signatures_educloudUser {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
}

export interface AdminViewFormResponse_publicationResponse_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  customerId: string | null;
  signer: AdminViewFormResponse_publicationResponse_signatures_signer | null;
  educloudUser: AdminViewFormResponse_publicationResponse_signatures_educloudUser | null;
  educloudUserId: string | null;
}

export interface AdminViewFormResponse_publicationResponse_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface AdminViewFormResponse_publicationResponse_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface AdminViewFormResponse_publicationResponse_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface AdminViewFormResponse_publicationResponse_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: AdminViewFormResponse_publicationResponse_response_components_questions_AnswerRanking_rankings[];
}

export interface AdminViewFormResponse_publicationResponse_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface AdminViewFormResponse_publicationResponse_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface AdminViewFormResponse_publicationResponse_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface AdminViewFormResponse_publicationResponse_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type AdminViewFormResponse_publicationResponse_response_components_questions = AdminViewFormResponse_publicationResponse_response_components_questions_AnswerMultiChoice | AdminViewFormResponse_publicationResponse_response_components_questions_AnswerCheckboxes | AdminViewFormResponse_publicationResponse_response_components_questions_AnswerRanking | AdminViewFormResponse_publicationResponse_response_components_questions_AnswerShortText | AdminViewFormResponse_publicationResponse_response_components_questions_AnswerLongText | AdminViewFormResponse_publicationResponse_response_components_questions_AnswerYesOrNo | AdminViewFormResponse_publicationResponse_response_components_questions_AnswerDate;

export interface AdminViewFormResponse_publicationResponse_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: AdminViewFormResponse_publicationResponse_response_components_questions[];
}

export interface AdminViewFormResponse_publicationResponse_response {
  __typename: "FormAnswer";
  components: AdminViewFormResponse_publicationResponse_response_components[];
}

export interface AdminViewFormResponse_publicationResponse_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type AdminViewFormResponse_publicationResponse_form_componentData_components_questions = AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionYesNo | AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionRanking | AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionShortText | AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionCheckboxes | AdminViewFormResponse_publicationResponse_form_componentData_components_questions_FormQuestionMultiChoice;

export interface AdminViewFormResponse_publicationResponse_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: AdminViewFormResponse_publicationResponse_form_componentData_components_questions[];
  templateId: string | null;
  attachments: AdminViewFormResponse_publicationResponse_form_componentData_components_attachments[] | null;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions = AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionYesNo | AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionRanking | AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionShortText | AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents_questions[];
}

export interface AdminViewFormResponse_publicationResponse_form_componentData {
  __typename: "ComponentData";
  components: AdminViewFormResponse_publicationResponse_form_componentData_components[];
  predicateComponents: AdminViewFormResponse_publicationResponse_form_componentData_predicateComponents[] | null;
}

export interface AdminViewFormResponse_publicationResponse_form_translations_componentData_predicateComponents_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface AdminViewFormResponse_publicationResponse_form_translations_componentData_predicateComponents_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: AdminViewFormResponse_publicationResponse_form_translations_componentData_predicateComponents_questions_options[] | null;
}

export interface AdminViewFormResponse_publicationResponse_form_translations_componentData_predicateComponents {
  __typename: "TranslatePredicateComponent";
  id: string;
  language: ISO6391Language | null;
  questions: AdminViewFormResponse_publicationResponse_form_translations_componentData_predicateComponents_questions[];
}

export interface AdminViewFormResponse_publicationResponse_form_translations_componentData_components_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface AdminViewFormResponse_publicationResponse_form_translations_componentData_components_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: AdminViewFormResponse_publicationResponse_form_translations_componentData_components_questions_options[] | null;
}

export interface AdminViewFormResponse_publicationResponse_form_translations_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface AdminViewFormResponse_publicationResponse_form_translations_componentData_components {
  __typename: "TranslateComponent";
  id: string;
  language: ISO6391Language | null;
  title: string;
  description: string;
  questions: AdminViewFormResponse_publicationResponse_form_translations_componentData_components_questions[];
  attachments: AdminViewFormResponse_publicationResponse_form_translations_componentData_components_attachments[];
}

export interface AdminViewFormResponse_publicationResponse_form_translations_componentData {
  __typename: "TranslationComponentData";
  predicateComponents: AdminViewFormResponse_publicationResponse_form_translations_componentData_predicateComponents[] | null;
  components: AdminViewFormResponse_publicationResponse_form_translations_componentData_components[];
}

export interface AdminViewFormResponse_publicationResponse_form_translations {
  __typename: "TranslationForm";
  formId: string;
  name: string;
  description: string;
  language: ISO6391Language;
  componentData: AdminViewFormResponse_publicationResponse_form_translations_componentData;
}

export interface AdminViewFormResponse_publicationResponse_form_owner {
  __typename: "Organisation";
  id: string;
  displayName: string;
}

export interface AdminViewFormResponse_publicationResponse_form {
  __typename: "Form";
  id: string;
  type: FormType;
  name: string;
  description: string | null;
  language: ISO6391Language;
  componentData: AdminViewFormResponse_publicationResponse_form_componentData;
  translations: AdminViewFormResponse_publicationResponse_form_translations[];
  owner: AdminViewFormResponse_publicationResponse_form_owner;
}

export interface AdminViewFormResponse_publicationResponse_publicationRecipient_responses {
  __typename: "PublicationResponse";
  id: string;
  modified: any;
}

export interface AdminViewFormResponse_publicationResponse_publicationRecipient {
  __typename: "PublicationRecipient";
  id: string;
  responses: AdminViewFormResponse_publicationResponse_publicationRecipient_responses[];
}

export interface AdminViewFormResponse_publicationResponse {
  __typename: "PublicationResponse";
  id: string;
  user: AdminViewFormResponse_publicationResponse_user | null;
  status: PublicationResponseStatus;
  signRole: PublicationResponseSignRole | null;
  signatures: AdminViewFormResponse_publicationResponse_signatures[] | null;
  modified: any;
  language: ISO6391Language;
  response: AdminViewFormResponse_publicationResponse_response | null;
  lockHolder: AdminViewFormResponse_publicationResponse_lockHolder | null;
  lockExpire: any | null;
  validIfSigned: boolean;
  form: AdminViewFormResponse_publicationResponse_form;
  publicationRecipient: AdminViewFormResponse_publicationResponse_publicationRecipient;
}

export interface AdminViewFormResponse {
  publicationResponse: AdminViewFormResponse_publicationResponse | null;
}

export interface AdminViewFormResponseVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CopyForm
// ====================================================

export interface CopyForm_copyForm {
  __typename: "Form";
  id: string;
  name: string;
}

export interface CopyForm {
  copyForm: CopyForm_copyForm;
}

export interface CopyFormVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteForm
// ====================================================

export interface DeleteForm_deleteForm {
  __typename: "Response";
  code: ReturnCode | null;
  message: string | null;
}

export interface DeleteForm {
  deleteForm: DeleteForm_deleteForm;
}

export interface DeleteFormVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: forms
// ====================================================

export interface forms_organisation_forms {
  __typename: "Form";
  id: string;
  name: string;
  type: FormType;
  subType: FormSubType;
  locked: boolean;
  created: any;
  modified: any;
}

export interface forms_organisation {
  __typename: "Organisation";
  id: string;
  forms: forms_organisation_forms[];
}

export interface forms {
  organisation: forms_organisation;
}

export interface formsVariables {
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminFormView
// ====================================================

export interface AdminFormView_form_translations_componentData_predicateComponents_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface AdminFormView_form_translations_componentData_predicateComponents_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: AdminFormView_form_translations_componentData_predicateComponents_questions_options[] | null;
}

export interface AdminFormView_form_translations_componentData_predicateComponents {
  __typename: "TranslatePredicateComponent";
  id: string;
  language: ISO6391Language | null;
  questions: AdminFormView_form_translations_componentData_predicateComponents_questions[];
}

export interface AdminFormView_form_translations_componentData_components_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface AdminFormView_form_translations_componentData_components_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: AdminFormView_form_translations_componentData_components_questions_options[] | null;
}

export interface AdminFormView_form_translations_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface AdminFormView_form_translations_componentData_components {
  __typename: "TranslateComponent";
  id: string;
  language: ISO6391Language | null;
  title: string;
  description: string;
  questions: AdminFormView_form_translations_componentData_components_questions[];
  attachments: AdminFormView_form_translations_componentData_components_attachments[];
}

export interface AdminFormView_form_translations_componentData {
  __typename: "TranslationComponentData";
  predicateComponents: AdminFormView_form_translations_componentData_predicateComponents[] | null;
  components: AdminFormView_form_translations_componentData_components[];
}

export interface AdminFormView_form_translations {
  __typename: "TranslationForm";
  formId: string;
  name: string;
  description: string;
  language: ISO6391Language;
  componentData: AdminFormView_form_translations_componentData;
}

export interface AdminFormView_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminFormView_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminFormView_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface AdminFormView_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminFormView_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminFormView_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminFormView_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: AdminFormView_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface AdminFormView_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminFormView_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminFormView_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface AdminFormView_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminFormView_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminFormView_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminFormView_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: AdminFormView_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface AdminFormView_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminFormView_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminFormView_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminFormView_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: AdminFormView_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type AdminFormView_form_componentData_components_questions = AdminFormView_form_componentData_components_questions_FormQuestionYesNo | AdminFormView_form_componentData_components_questions_FormQuestionRanking | AdminFormView_form_componentData_components_questions_FormQuestionShortText | AdminFormView_form_componentData_components_questions_FormQuestionCheckboxes | AdminFormView_form_componentData_components_questions_FormQuestionMultiChoice;

export interface AdminFormView_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface AdminFormView_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: AdminFormView_form_componentData_components_questions[];
  templateId: string | null;
  attachments: AdminFormView_form_componentData_components_attachments[] | null;
}

export interface AdminFormView_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminFormView_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminFormView_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface AdminFormView_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminFormView_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminFormView_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminFormView_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: AdminFormView_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface AdminFormView_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminFormView_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminFormView_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface AdminFormView_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminFormView_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminFormView_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminFormView_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: AdminFormView_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface AdminFormView_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminFormView_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminFormView_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminFormView_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: AdminFormView_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type AdminFormView_form_componentData_predicateComponents_questions = AdminFormView_form_componentData_predicateComponents_questions_FormQuestionYesNo | AdminFormView_form_componentData_predicateComponents_questions_FormQuestionRanking | AdminFormView_form_componentData_predicateComponents_questions_FormQuestionShortText | AdminFormView_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | AdminFormView_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface AdminFormView_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: AdminFormView_form_componentData_predicateComponents_questions[];
}

export interface AdminFormView_form_componentData {
  __typename: "ComponentData";
  components: AdminFormView_form_componentData_components[];
  predicateComponents: AdminFormView_form_componentData_predicateComponents[] | null;
}

export interface AdminFormView_form_access_schoolUnits {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface AdminFormView_form_access {
  __typename: "FormAccess";
  public: boolean;
  organisation: boolean;
  schoolUnits: (AdminFormView_form_access_schoolUnits | null)[];
}

export interface AdminFormView_form_owner {
  __typename: "Organisation";
  id: string;
  displayName: string;
}

export interface AdminFormView_form {
  __typename: "Form";
  id: string;
  type: FormType;
  archived: boolean;
  locked: boolean;
  name: string;
  description: string | null;
  language: ISO6391Language;
  translationLanguages: ISO6391Language[];
  translations: AdminFormView_form_translations[];
  componentData: AdminFormView_form_componentData;
  access: AdminFormView_form_access;
  owner: AdminFormView_form_owner;
}

export interface AdminFormView {
  form: AdminFormView_form;
}

export interface AdminFormViewVariables {
  id: string;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganisationSettings
// ====================================================

export interface OrganisationSettings_organisation_dataSources {
  __typename: "DataSource";
  customerId: string;
  name: string;
  description: string;
}

export interface OrganisationSettings_organisation_settings_employmentPermissions {
  __typename: "OrganisationEmploymentPermission";
  employmentType: EmploymentRole;
  roles: PermissionType[];
}

export interface OrganisationSettings_organisation_settings {
  __typename: "OrganisationSettings";
  autoUpdateRecipients: boolean;
  employmentPermissions: OrganisationSettings_organisation_settings_employmentPermissions[];
}

export interface OrganisationSettings_organisation {
  __typename: "Organisation";
  id: string;
  displayName: string;
  dataSources: OrganisationSettings_organisation_dataSources[];
  settings: OrganisationSettings_organisation_settings;
}

export interface OrganisationSettings {
  organisation: OrganisationSettings_organisation;
}

export interface OrganisationSettingsVariables {
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveOrganisationSettings
// ====================================================

export interface SaveOrganisationSettings_updateOrganisationSettings_dataSources {
  __typename: "DataSource";
  customerId: string;
  name: string;
  description: string;
}

export interface SaveOrganisationSettings_updateOrganisationSettings_settings_employmentPermissions {
  __typename: "OrganisationEmploymentPermission";
  employmentType: EmploymentRole;
  roles: PermissionType[];
}

export interface SaveOrganisationSettings_updateOrganisationSettings_settings {
  __typename: "OrganisationSettings";
  autoUpdateRecipients: boolean;
  employmentPermissions: SaveOrganisationSettings_updateOrganisationSettings_settings_employmentPermissions[];
}

export interface SaveOrganisationSettings_updateOrganisationSettings {
  __typename: "Organisation";
  id: string;
  displayName: string;
  dataSources: SaveOrganisationSettings_updateOrganisationSettings_dataSources[];
  settings: SaveOrganisationSettings_updateOrganisationSettings_settings;
}

export interface SaveOrganisationSettings {
  updateOrganisationSettings: SaveOrganisationSettings_updateOrganisationSettings;
}

export interface SaveOrganisationSettingsVariables {
  input: UpdateOrganisationSettingsInput;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrgPublicationReport
// ====================================================

export interface OrgPublicationReport_publication_recipients_groups_edges {
  __typename: "PublicationGroupStudentGroup";
  id: string;
  displayName: string;
}

export interface OrgPublicationReport_publication_recipients_groups {
  __typename: "PublicationGroupConnection";
  edges: OrgPublicationReport_publication_recipients_groups_edges[];
}

export interface OrgPublicationReport_publication_recipients_users_edges_responses_signatures_educloudUser {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface OrgPublicationReport_publication_recipients_users_edges_responses_signatures_signer {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface OrgPublicationReport_publication_recipients_users_edges_responses_signatures {
  __typename: "PublicationResponseSignature";
  educloudUserId: string | null;
  educloudUser: OrgPublicationReport_publication_recipients_users_edges_responses_signatures_educloudUser | null;
  signerId: string;
  signer: OrgPublicationReport_publication_recipients_users_edges_responses_signatures_signer | null;
}

export interface OrgPublicationReport_publication_recipients_users_edges_responses_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface OrgPublicationReport_publication_recipients_users_edges_responses_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface OrgPublicationReport_publication_recipients_users_edges_responses_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface OrgPublicationReport_publication_recipients_users_edges_responses_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: OrgPublicationReport_publication_recipients_users_edges_responses_response_components_questions_AnswerRanking_rankings[];
}

export interface OrgPublicationReport_publication_recipients_users_edges_responses_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface OrgPublicationReport_publication_recipients_users_edges_responses_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface OrgPublicationReport_publication_recipients_users_edges_responses_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface OrgPublicationReport_publication_recipients_users_edges_responses_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type OrgPublicationReport_publication_recipients_users_edges_responses_response_components_questions = OrgPublicationReport_publication_recipients_users_edges_responses_response_components_questions_AnswerMultiChoice | OrgPublicationReport_publication_recipients_users_edges_responses_response_components_questions_AnswerCheckboxes | OrgPublicationReport_publication_recipients_users_edges_responses_response_components_questions_AnswerRanking | OrgPublicationReport_publication_recipients_users_edges_responses_response_components_questions_AnswerShortText | OrgPublicationReport_publication_recipients_users_edges_responses_response_components_questions_AnswerLongText | OrgPublicationReport_publication_recipients_users_edges_responses_response_components_questions_AnswerYesOrNo | OrgPublicationReport_publication_recipients_users_edges_responses_response_components_questions_AnswerDate;

export interface OrgPublicationReport_publication_recipients_users_edges_responses_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: OrgPublicationReport_publication_recipients_users_edges_responses_response_components_questions[];
}

export interface OrgPublicationReport_publication_recipients_users_edges_responses_response {
  __typename: "FormAnswer";
  components: OrgPublicationReport_publication_recipients_users_edges_responses_response_components[];
}

export interface OrgPublicationReport_publication_recipients_users_edges_responses {
  __typename: "PublicationResponse";
  id: string;
  modified: any;
  status: PublicationResponseStatus;
  signRole: PublicationResponseSignRole | null;
  signatures: OrgPublicationReport_publication_recipients_users_edges_responses_signatures[] | null;
  response: OrgPublicationReport_publication_recipients_users_edges_responses_response | null;
}

export interface OrgPublicationReport_publication_recipients_users_edges_user {
  __typename: "User";
  id: string;
  name: string | null;
  birthDate: any | null;
}

export interface OrgPublicationReport_publication_recipients_users_edges {
  __typename: "PublicationRecipient";
  id: string;
  responses: OrgPublicationReport_publication_recipients_users_edges_responses[];
  user: OrgPublicationReport_publication_recipients_users_edges_user | null;
  groupIds: string[];
}

export interface OrgPublicationReport_publication_recipients_users {
  __typename: "PublicationUserConnection";
  edges: OrgPublicationReport_publication_recipients_users_edges[];
}

export interface OrgPublicationReport_publication_recipients {
  __typename: "PublicationRecipientList";
  groups: OrgPublicationReport_publication_recipients_groups;
  users: OrgPublicationReport_publication_recipients_users;
}

export interface OrgPublicationReport_publication {
  __typename: "Publication";
  id: string;
  name: string;
  recipients: OrgPublicationReport_publication_recipients;
}

export interface OrgPublicationReport {
  publication: OrgPublicationReport_publication;
}

export interface OrgPublicationReportVariables {
  publicationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrgReportPublications
// ====================================================

export interface OrgReportPublications_organisation_publications_creator {
  __typename: "User" | "Client";
  id: string;
  name: string | null;
}

export interface OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type OrgReportPublications_organisation_publications_form_componentData_components_questions = OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionYesNo | OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionRanking | OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionShortText | OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionCheckboxes | OrgReportPublications_organisation_publications_form_componentData_components_questions_FormQuestionMultiChoice;

export interface OrgReportPublications_organisation_publications_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface OrgReportPublications_organisation_publications_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: OrgReportPublications_organisation_publications_form_componentData_components_questions[];
  templateId: string | null;
  attachments: OrgReportPublications_organisation_publications_form_componentData_components_attachments[] | null;
}

export interface OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions = OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionYesNo | OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionRanking | OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionShortText | OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface OrgReportPublications_organisation_publications_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: OrgReportPublications_organisation_publications_form_componentData_predicateComponents_questions[];
}

export interface OrgReportPublications_organisation_publications_form_componentData {
  __typename: "ComponentData";
  components: OrgReportPublications_organisation_publications_form_componentData_components[];
  predicateComponents: OrgReportPublications_organisation_publications_form_componentData_predicateComponents[] | null;
}

export interface OrgReportPublications_organisation_publications_form {
  __typename: "Form";
  id: string;
  name: string;
  componentData: OrgReportPublications_organisation_publications_form_componentData;
}

export interface OrgReportPublications_organisation_publications {
  __typename: "Publication";
  id: string;
  name: string;
  created: any;
  creator: OrgReportPublications_organisation_publications_creator | null;
  validFrom: any;
  validTo: any;
  sendDate: any;
  sent: boolean;
  lastNotified: any | null;
  form: OrgReportPublications_organisation_publications_form;
}

export interface OrgReportPublications_organisation {
  __typename: "Organisation";
  id: string;
  displayName: string;
  publications: OrgReportPublications_organisation_publications[];
}

export interface OrgReportPublications {
  organisation: OrgReportPublications_organisation;
}

export interface OrgReportPublicationsVariables {
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganisationPermissions
// ====================================================

export interface OrganisationPermissions_organisation_permissions_organisation_user_connectedUsers {
  __typename: "User";
  id: string;
  source: UserSource;
}

export interface OrganisationPermissions_organisation_permissions_organisation_user_skolidOrganization {
  __typename: "SkolidOrganization";
  id: string;
  name: string;
  country: ISO3166Country;
}

export interface OrganisationPermissions_organisation_permissions_organisation_user {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
  idp: string | null;
  connectedUsers: OrganisationPermissions_organisation_permissions_organisation_user_connectedUsers[] | null;
  skolidOrganization: OrganisationPermissions_organisation_permissions_organisation_user_skolidOrganization | null;
}

export interface OrganisationPermissions_organisation_permissions_organisation_client {
  __typename: "Client";
  id: string;
  clientId: string;
  name: string | null;
}

export interface OrganisationPermissions_organisation_permissions_organisation_roles {
  __typename: "PermissionRole";
  roleId: PermissionType;
  sources: PermissionSource[];
}

export interface OrganisationPermissions_organisation_permissions_organisation {
  __typename: "SubjectPermissionElement";
  user: OrganisationPermissions_organisation_permissions_organisation_user | null;
  client: OrganisationPermissions_organisation_permissions_organisation_client | null;
  roles: OrganisationPermissions_organisation_permissions_organisation_roles[];
}

export interface OrganisationPermissions_organisation_permissions {
  __typename: "OrganisationPermssions";
  resourceId: string;
  displayName: string | null;
  organisation: OrganisationPermissions_organisation_permissions_organisation[];
}

export interface OrganisationPermissions_organisation {
  __typename: "Organisation";
  id: string;
  displayName: string;
  skolidOrgId: string | null;
  permissions: OrganisationPermissions_organisation_permissions;
}

export interface OrganisationPermissions {
  organisation: OrganisationPermissions_organisation;
}

export interface OrganisationPermissionsVariables {
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindUserQuery
// ====================================================

export interface FindUserQuery_findUsers_skolidOrganization {
  __typename: "SkolidOrganization";
  id: string;
  name: string;
  country: ISO3166Country;
}

export interface FindUserQuery_findUsers {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
  idp: string | null;
  skolidOrganization: FindUserQuery_findUsers_skolidOrganization | null;
}

export interface FindUserQuery {
  findUsers: FindUserQuery_findUsers[];
}

export interface FindUserQueryVariables {
  key: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPermissionUsersQuery
// ====================================================

export interface GetPermissionUsersQuery_permissionUsers_educloud_user_connectedUsers {
  __typename: "User";
  id: string;
  source: UserSource;
}

export interface GetPermissionUsersQuery_permissionUsers_educloud_user_skolidOrganization {
  __typename: "SkolidOrganization";
  id: string;
  name: string;
  country: ISO3166Country;
}

export interface GetPermissionUsersQuery_permissionUsers_educloud_user {
  __typename: "User";
  connectedUsers: GetPermissionUsersQuery_permissionUsers_educloud_user_connectedUsers[] | null;
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
  idp: string | null;
  skolidOrganization: GetPermissionUsersQuery_permissionUsers_educloud_user_skolidOrganization | null;
}

export interface GetPermissionUsersQuery_permissionUsers_educloud {
  __typename: "PermissionUsers";
  userId: string;
  source: string;
  user: GetPermissionUsersQuery_permissionUsers_educloud_user;
}

export interface GetPermissionUsersQuery_permissionUsers_skolid_user_connectedUsers {
  __typename: "User";
  id: string;
  source: UserSource;
}

export interface GetPermissionUsersQuery_permissionUsers_skolid_user_skolidOrganization {
  __typename: "SkolidOrganization";
  id: string;
  name: string;
  country: ISO3166Country;
}

export interface GetPermissionUsersQuery_permissionUsers_skolid_user {
  __typename: "User";
  connectedUsers: GetPermissionUsersQuery_permissionUsers_skolid_user_connectedUsers[] | null;
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
  idp: string | null;
  skolidOrganization: GetPermissionUsersQuery_permissionUsers_skolid_user_skolidOrganization | null;
}

export interface GetPermissionUsersQuery_permissionUsers_skolid {
  __typename: "PermissionUsers";
  userId: string;
  source: string;
  user: GetPermissionUsersQuery_permissionUsers_skolid_user;
}

export interface GetPermissionUsersQuery_permissionUsers {
  __typename: "PermissionUsersSources";
  educloud: GetPermissionUsersQuery_permissionUsers_educloud[];
  skolid: GetPermissionUsersQuery_permissionUsers_skolid[];
}

export interface GetPermissionUsersQuery_organisation {
  __typename: "Organisation";
  id: string;
  displayName: string;
  skolidOrgId: string | null;
}

export interface GetPermissionUsersQuery {
  permissionUsers: GetPermissionUsersQuery_permissionUsers;
  organisation: GetPermissionUsersQuery_organisation;
}

export interface GetPermissionUsersQueryVariables {
  educloudUserIds?: string[] | null;
  skolidUserIds?: string[] | null;
  customerId: string;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: publications
// ====================================================

export interface publications_schoolUnit_publications_creator {
  __typename: "User" | "Client";
  id: string;
  name: string | null;
}

export interface publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type publications_schoolUnit_publications_form_componentData_components_questions = publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionYesNo | publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionRanking | publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionShortText | publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionCheckboxes | publications_schoolUnit_publications_form_componentData_components_questions_FormQuestionMultiChoice;

export interface publications_schoolUnit_publications_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface publications_schoolUnit_publications_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: publications_schoolUnit_publications_form_componentData_components_questions[];
  templateId: string | null;
  attachments: publications_schoolUnit_publications_form_componentData_components_attachments[] | null;
}

export interface publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type publications_schoolUnit_publications_form_componentData_predicateComponents_questions = publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionYesNo | publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionRanking | publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionShortText | publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | publications_schoolUnit_publications_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface publications_schoolUnit_publications_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: publications_schoolUnit_publications_form_componentData_predicateComponents_questions[];
}

export interface publications_schoolUnit_publications_form_componentData {
  __typename: "ComponentData";
  components: publications_schoolUnit_publications_form_componentData_components[];
  predicateComponents: publications_schoolUnit_publications_form_componentData_predicateComponents[] | null;
}

export interface publications_schoolUnit_publications_form {
  __typename: "Form";
  id: string;
  name: string;
  componentData: publications_schoolUnit_publications_form_componentData;
}

export interface publications_schoolUnit_publications {
  __typename: "Publication";
  id: string;
  name: string;
  created: any;
  creator: publications_schoolUnit_publications_creator | null;
  validFrom: any;
  validTo: any;
  sendDate: any;
  sent: boolean;
  lastNotified: any | null;
  form: publications_schoolUnit_publications_form;
}

export interface publications_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  publications: publications_schoolUnit_publications[];
}

export interface publications {
  schoolUnit: publications_schoolUnit;
}

export interface publicationsVariables {
  id: string;
  customerId: string;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: publicationReport
// ====================================================

export interface publicationReport_schoolUnit_classes_publicationRecipients_user {
  __typename: "User";
  id: string;
  name: string | null;
  birthDate: any | null;
}

export interface publicationReport_schoolUnit_classes_publicationRecipients_responses_signatures_educloudUser {
  __typename: "User";
  id: string;
  customerId: string | null;
  name: string | null;
}

export interface publicationReport_schoolUnit_classes_publicationRecipients_responses_signatures_signer {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface publicationReport_schoolUnit_classes_publicationRecipients_responses_signatures {
  __typename: "PublicationResponseSignature";
  educloudUserId: string | null;
  educloudUser: publicationReport_schoolUnit_classes_publicationRecipients_responses_signatures_educloudUser | null;
  signerId: string;
  signer: publicationReport_schoolUnit_classes_publicationRecipients_responses_signatures_signer | null;
}

export interface publicationReport_schoolUnit_classes_publicationRecipients_responses_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface publicationReport_schoolUnit_classes_publicationRecipients_responses_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface publicationReport_schoolUnit_classes_publicationRecipients_responses_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface publicationReport_schoolUnit_classes_publicationRecipients_responses_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: publicationReport_schoolUnit_classes_publicationRecipients_responses_response_components_questions_AnswerRanking_rankings[];
}

export interface publicationReport_schoolUnit_classes_publicationRecipients_responses_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface publicationReport_schoolUnit_classes_publicationRecipients_responses_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface publicationReport_schoolUnit_classes_publicationRecipients_responses_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface publicationReport_schoolUnit_classes_publicationRecipients_responses_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type publicationReport_schoolUnit_classes_publicationRecipients_responses_response_components_questions = publicationReport_schoolUnit_classes_publicationRecipients_responses_response_components_questions_AnswerMultiChoice | publicationReport_schoolUnit_classes_publicationRecipients_responses_response_components_questions_AnswerCheckboxes | publicationReport_schoolUnit_classes_publicationRecipients_responses_response_components_questions_AnswerRanking | publicationReport_schoolUnit_classes_publicationRecipients_responses_response_components_questions_AnswerShortText | publicationReport_schoolUnit_classes_publicationRecipients_responses_response_components_questions_AnswerLongText | publicationReport_schoolUnit_classes_publicationRecipients_responses_response_components_questions_AnswerYesOrNo | publicationReport_schoolUnit_classes_publicationRecipients_responses_response_components_questions_AnswerDate;

export interface publicationReport_schoolUnit_classes_publicationRecipients_responses_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: publicationReport_schoolUnit_classes_publicationRecipients_responses_response_components_questions[];
}

export interface publicationReport_schoolUnit_classes_publicationRecipients_responses_response {
  __typename: "FormAnswer";
  components: publicationReport_schoolUnit_classes_publicationRecipients_responses_response_components[];
}

export interface publicationReport_schoolUnit_classes_publicationRecipients_responses {
  __typename: "PublicationResponse";
  id: string;
  modified: any;
  status: PublicationResponseStatus;
  signRole: PublicationResponseSignRole | null;
  signatures: publicationReport_schoolUnit_classes_publicationRecipients_responses_signatures[] | null;
  response: publicationReport_schoolUnit_classes_publicationRecipients_responses_response | null;
}

export interface publicationReport_schoolUnit_classes_publicationRecipients {
  __typename: "PublicationRecipient";
  user: publicationReport_schoolUnit_classes_publicationRecipients_user | null;
  responses: publicationReport_schoolUnit_classes_publicationRecipients_responses[];
}

export interface publicationReport_schoolUnit_classes {
  __typename: "StudentGroup";
  id: string;
  customerId: string;
  displayName: string;
  publicationRecipients: publicationReport_schoolUnit_classes_publicationRecipients[];
}

export interface publicationReport_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
  classes: publicationReport_schoolUnit_classes[];
}

export interface publicationReport {
  schoolUnit: publicationReport_schoolUnit;
}

export interface publicationReportVariables {
  id: string;
  customerId: string;
  publicationId: string;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: schoolUnits
// ====================================================

export interface schoolUnits_organisation_schoolUnits_classes {
  __typename: "StudentGroup";
  id: string;
  customerId: string;
}

export interface schoolUnits_organisation_schoolUnits {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
  classes: schoolUnits_organisation_schoolUnits_classes[];
}

export interface schoolUnits_organisation {
  __typename: "Organisation";
  id: string;
  schoolUnits: schoolUnits_organisation_schoolUnits[];
}

export interface schoolUnits {
  organisation: schoolUnits_organisation;
}

export interface schoolUnitsVariables {
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SchoolUnits
// ====================================================

export interface SchoolUnits_organisation_permissions_schoolUnits_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  organisationCode: string | null;
  schoolUnitCode: string | null;
}

export interface SchoolUnits_organisation_permissions_schoolUnits_permissions_client {
  __typename: "Client";
  id: string;
  clientId: string;
  name: string | null;
}

export interface SchoolUnits_organisation_permissions_schoolUnits_permissions_roles {
  __typename: "PermissionRole";
  roleId: PermissionType;
  sources: PermissionSource[];
}

export interface SchoolUnits_organisation_permissions_schoolUnits_permissions {
  __typename: "SubjectPermissionElement";
  employmentRoles: EmploymentRole[];
  displayName: string | null;
  source: string | null;
  userId: string | null;
  client: SchoolUnits_organisation_permissions_schoolUnits_permissions_client | null;
  roles: SchoolUnits_organisation_permissions_schoolUnits_permissions_roles[];
}

export interface SchoolUnits_organisation_permissions_schoolUnits {
  __typename: "SchoolUnitPermissions";
  resourceId: string;
  schoolUnit: SchoolUnits_organisation_permissions_schoolUnits_schoolUnit;
  displayName: string;
  permissions: SchoolUnits_organisation_permissions_schoolUnits_permissions[];
}

export interface SchoolUnits_organisation_permissions {
  __typename: "OrganisationPermssions";
  schoolUnits: SchoolUnits_organisation_permissions_schoolUnits[];
}

export interface SchoolUnits_organisation {
  __typename: "Organisation";
  id: string;
  skolidOrgId: string | null;
  permissions: SchoolUnits_organisation_permissions;
}

export interface SchoolUnits {
  organisation: SchoolUnits_organisation;
}

export interface SchoolUnitsVariables {
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserHistory
// ====================================================

export interface UserHistory_me_history_ClassResponsesHistoryRecord_data {
  __typename: "ClassResponsesHistoryData";
  customerId: string;
  schoolUnitId: string;
  studentGroupId: string;
}

export interface UserHistory_me_history_ClassResponsesHistoryRecord {
  __typename: "ClassResponsesHistoryRecord";
  id: string;
  label: string;
  type: RecordHistoryType;
  data: UserHistory_me_history_ClassResponsesHistoryRecord_data;
}

export interface UserHistory_me_history_FormHistoryRecord_data {
  __typename: "FormHistoryData";
  formId: string;
}

export interface UserHistory_me_history_FormHistoryRecord {
  __typename: "FormHistoryRecord";
  id: string;
  label: string;
  type: RecordHistoryType;
  data: UserHistory_me_history_FormHistoryRecord_data;
}

export type UserHistory_me_history = UserHistory_me_history_ClassResponsesHistoryRecord | UserHistory_me_history_FormHistoryRecord;

export interface UserHistory_me_favorites_ClassResponsesHistoryRecord_data {
  __typename: "ClassResponsesHistoryData";
  customerId: string;
  schoolUnitId: string;
  studentGroupId: string;
}

export interface UserHistory_me_favorites_ClassResponsesHistoryRecord {
  __typename: "ClassResponsesHistoryRecord";
  id: string;
  label: string;
  type: RecordHistoryType;
  data: UserHistory_me_favorites_ClassResponsesHistoryRecord_data;
}

export interface UserHistory_me_favorites_FormHistoryRecord_data {
  __typename: "FormHistoryData";
  formId: string;
}

export interface UserHistory_me_favorites_FormHistoryRecord {
  __typename: "FormHistoryRecord";
  id: string;
  label: string;
  type: RecordHistoryType;
  data: UserHistory_me_favorites_FormHistoryRecord_data;
}

export type UserHistory_me_favorites = UserHistory_me_favorites_ClassResponsesHistoryRecord | UserHistory_me_favorites_FormHistoryRecord;

export interface UserHistory_me {
  __typename: "User";
  id: string;
  history: UserHistory_me_history[];
  favorites: UserHistory_me_favorites[];
}

export interface UserHistory {
  me: UserHistory_me;
}

export interface UserHistoryVariables {
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: newResponseStats
// ====================================================

export interface newResponseStats_newResponseStats_publication {
  __typename: "Publication";
  id: string;
  name: string;
}

export interface newResponseStats_newResponseStats_group_node_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface newResponseStats_newResponseStats_group_node {
  __typename: "StudentGroup";
  id: string;
  customerId: string;
  schoolUnit: newResponseStats_newResponseStats_group_node_schoolUnit;
}

export interface newResponseStats_newResponseStats_group {
  __typename: "PublicationGroupStudentGroup";
  id: string;
  displayName: string;
  type: PublicationGroupType;
  node: newResponseStats_newResponseStats_group_node;
}

export interface newResponseStats_newResponseStats {
  __typename: "NewResponseStats";
  count: number;
  lastActivity: any;
  publication: newResponseStats_newResponseStats_publication;
  group: newResponseStats_newResponseStats_group;
}

export interface newResponseStats_updatedResponses_user {
  __typename: "User";
  id: string;
  customerId: string | null;
  name: string | null;
}

export interface newResponseStats_updatedResponses_publicationRecipient_publication {
  __typename: "Publication";
  id: string;
  name: string;
}

export interface newResponseStats_updatedResponses_publicationRecipient_groups {
  __typename: "PublicationGroupStudentGroup";
  id: string;
  customerId: string;
  displayName: string;
}

export interface newResponseStats_updatedResponses_publicationRecipient {
  __typename: "PublicationRecipient";
  id: string;
  publication: newResponseStats_updatedResponses_publicationRecipient_publication;
  groups: newResponseStats_updatedResponses_publicationRecipient_groups[];
}

export interface newResponseStats_updatedResponses {
  __typename: "PublicationResponse";
  id: string;
  modified: any;
  user: newResponseStats_updatedResponses_user | null;
  publicationRecipient: newResponseStats_updatedResponses_publicationRecipient;
}

export interface newResponseStats {
  newResponseStats: newResponseStats_newResponseStats[];
  updatedResponses: newResponseStats_updatedResponses[];
}

export interface newResponseStatsVariables {
  since?: any | null;
  onlyFavorites: boolean;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: groupPublicationRecipients
// ====================================================

export interface groupPublicationRecipients_studentGroup_publicationRecipients_user {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
}

export interface groupPublicationRecipients_studentGroup_publicationRecipients_responses_signatures_signer {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  idp: string | null;
  idpSub: string | null;
  name: string | null;
}

export interface groupPublicationRecipients_studentGroup_publicationRecipients_responses_signatures_educloudUser {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
}

export interface groupPublicationRecipients_studentGroup_publicationRecipients_responses_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  customerId: string | null;
  signer: groupPublicationRecipients_studentGroup_publicationRecipients_responses_signatures_signer | null;
  educloudUser: groupPublicationRecipients_studentGroup_publicationRecipients_responses_signatures_educloudUser | null;
  educloudUserId: string | null;
}

export interface groupPublicationRecipients_studentGroup_publicationRecipients_responses_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface groupPublicationRecipients_studentGroup_publicationRecipients_responses_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface groupPublicationRecipients_studentGroup_publicationRecipients_responses_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface groupPublicationRecipients_studentGroup_publicationRecipients_responses_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: groupPublicationRecipients_studentGroup_publicationRecipients_responses_response_components_questions_AnswerRanking_rankings[];
}

export interface groupPublicationRecipients_studentGroup_publicationRecipients_responses_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface groupPublicationRecipients_studentGroup_publicationRecipients_responses_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface groupPublicationRecipients_studentGroup_publicationRecipients_responses_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface groupPublicationRecipients_studentGroup_publicationRecipients_responses_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type groupPublicationRecipients_studentGroup_publicationRecipients_responses_response_components_questions = groupPublicationRecipients_studentGroup_publicationRecipients_responses_response_components_questions_AnswerMultiChoice | groupPublicationRecipients_studentGroup_publicationRecipients_responses_response_components_questions_AnswerCheckboxes | groupPublicationRecipients_studentGroup_publicationRecipients_responses_response_components_questions_AnswerRanking | groupPublicationRecipients_studentGroup_publicationRecipients_responses_response_components_questions_AnswerShortText | groupPublicationRecipients_studentGroup_publicationRecipients_responses_response_components_questions_AnswerLongText | groupPublicationRecipients_studentGroup_publicationRecipients_responses_response_components_questions_AnswerYesOrNo | groupPublicationRecipients_studentGroup_publicationRecipients_responses_response_components_questions_AnswerDate;

export interface groupPublicationRecipients_studentGroup_publicationRecipients_responses_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: groupPublicationRecipients_studentGroup_publicationRecipients_responses_response_components_questions[];
}

export interface groupPublicationRecipients_studentGroup_publicationRecipients_responses_response {
  __typename: "FormAnswer";
  components: groupPublicationRecipients_studentGroup_publicationRecipients_responses_response_components[];
}

export interface groupPublicationRecipients_studentGroup_publicationRecipients_responses_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface groupPublicationRecipients_studentGroup_publicationRecipients_responses {
  __typename: "PublicationResponse";
  id: string;
  status: PublicationResponseStatus;
  signRole: PublicationResponseSignRole | null;
  signatures: groupPublicationRecipients_studentGroup_publicationRecipients_responses_signatures[] | null;
  modified: any;
  language: ISO6391Language;
  response: groupPublicationRecipients_studentGroup_publicationRecipients_responses_response | null;
  lockHolder: groupPublicationRecipients_studentGroup_publicationRecipients_responses_lockHolder | null;
  lockExpire: any | null;
  validIfSigned: boolean;
}

export interface groupPublicationRecipients_studentGroup_publicationRecipients {
  __typename: "PublicationRecipient";
  user: groupPublicationRecipients_studentGroup_publicationRecipients_user | null;
  id: string;
  modifyPermission: boolean;
  responses: groupPublicationRecipients_studentGroup_publicationRecipients_responses[];
}

export interface groupPublicationRecipients_studentGroup {
  __typename: "StudentGroup";
  publicationRecipients: groupPublicationRecipients_studentGroup_publicationRecipients[];
  id: string;
}

export interface groupPublicationRecipients {
  studentGroup: groupPublicationRecipients_studentGroup;
}

export interface groupPublicationRecipientsVariables {
  groupId: string;
  customerId: string;
  publicationId: string;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: studentGroupForms
// ====================================================

export interface studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type studentGroupForms_studentGroup_publications_form_componentData_components_questions = studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionYesNo | studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionRanking | studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionShortText | studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionCheckboxes | studentGroupForms_studentGroup_publications_form_componentData_components_questions_FormQuestionMultiChoice;

export interface studentGroupForms_studentGroup_publications_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: studentGroupForms_studentGroup_publications_form_componentData_components_questions[];
  templateId: string | null;
  attachments: studentGroupForms_studentGroup_publications_form_componentData_components_attachments[] | null;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions = studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionYesNo | studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionRanking | studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionShortText | studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface studentGroupForms_studentGroup_publications_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: studentGroupForms_studentGroup_publications_form_componentData_predicateComponents_questions[];
}

export interface studentGroupForms_studentGroup_publications_form_componentData {
  __typename: "ComponentData";
  components: studentGroupForms_studentGroup_publications_form_componentData_components[];
  predicateComponents: studentGroupForms_studentGroup_publications_form_componentData_predicateComponents[] | null;
}

export interface studentGroupForms_studentGroup_publications_form {
  __typename: "Form";
  id: string;
  name: string;
  componentData: studentGroupForms_studentGroup_publications_form_componentData;
}

export interface studentGroupForms_studentGroup_publications {
  __typename: "Publication";
  id: string;
  name: string;
  sendDate: any;
  form: studentGroupForms_studentGroup_publications_form;
}

export interface studentGroupForms_studentGroup {
  __typename: "StudentGroup";
  id: string;
  customerId: string;
  displayName: string;
  publications: studentGroupForms_studentGroup_publications[];
}

export interface studentGroupForms {
  studentGroup: studentGroupForms_studentGroup;
}

export interface studentGroupFormsVariables {
  groupId: string;
  customerId: string;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userInfo
// ====================================================

export interface userInfo_user_skolidOrganization {
  __typename: "SkolidOrganization";
  id: string;
  name: string;
  country: ISO3166Country;
}

export interface userInfo_user_connectedUsers_skolidOrganization {
  __typename: "SkolidOrganization";
  id: string;
  name: string;
  country: ISO3166Country;
}

export interface userInfo_user_connectedUsers {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  idp: string | null;
  idpSub: string | null;
  name: string | null;
  skolidOrganization: userInfo_user_connectedUsers_skolidOrganization | null;
}

export interface userInfo_user_emails {
  __typename: "UserEmail";
  value: string;
  type: string;
  weight: number;
  source: EmailSource;
  preferredWork: boolean;
  preferredHome: boolean;
}

export interface userInfo_user_addresses {
  __typename: "UserAddress";
  source: AddressSource;
  type: string | null;
  streetAddress: string | null;
  locality: string | null;
  region: string | null;
  postalCode: string | null;
  country: string | null;
}

export interface userInfo_user_enrolments_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface userInfo_user_enrolments {
  __typename: "Enrolment";
  schoolUnit: userInfo_user_enrolments_schoolUnit | null;
  schoolYear: number | null;
  schoolType: Code_SchoolType;
  startDate: any;
  endDate: any | null;
}

export interface userInfo_user_placements_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface userInfo_user_placements {
  __typename: "Placement";
  schoolUnit: userInfo_user_placements_schoolUnit | null;
  schoolType: Code_SchoolType | null;
  startDate: any | null;
  endDate: any | null;
}

export interface userInfo_user_studentGroupMemberships_studentGroup_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  displayName: string;
}

export interface userInfo_user_studentGroupMemberships_studentGroup {
  __typename: "StudentGroup";
  id: string;
  customerId: string;
  displayName: string;
  groupType: Code_GroupType | null;
  schoolUnit: userInfo_user_studentGroupMemberships_studentGroup_schoolUnit;
}

export interface userInfo_user_studentGroupMemberships {
  __typename: "StudentGroupMembership";
  startDate: any | null;
  endDate: any | null;
  studentGroup: userInfo_user_studentGroupMemberships_studentGroup | null;
}

export interface userInfo_user_relations_edges_node {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  idp: string | null;
  idpSub: string | null;
  name: string | null;
}

export interface userInfo_user_relations_edges {
  __typename: "Relation";
  type: RelationType;
  node: userInfo_user_relations_edges_node | null;
}

export interface userInfo_user_relations {
  __typename: "RelationConnection";
  edges: userInfo_user_relations_edges[];
}

export interface userInfo_user_publicationRecipients_form {
  __typename: "Form";
  id: string;
  name: string;
}

export interface userInfo_user_publicationRecipients_publication {
  __typename: "Publication";
  id: string;
  name: string;
}

export interface userInfo_user_publicationRecipients_validResponse {
  __typename: "PublicationResponse";
  id: string;
  modified: any;
}

export interface userInfo_user_publicationRecipients {
  __typename: "PublicationRecipient";
  id: string;
  form: userInfo_user_publicationRecipients_form;
  publication: userInfo_user_publicationRecipients_publication;
  created: any;
  validResponse: userInfo_user_publicationRecipients_validResponse | null;
}

export interface userInfo_user_employments_employedAt {
  __typename: "SchoolUnit";
  id: string;
  displayName: string;
}

export interface userInfo_user_employments {
  __typename: "Employment";
  id: string;
  startDate: any | null;
  endDate: any | null;
  employmentRole: EmploymentRole;
  hoursPerYear: number | null;
  employmentPercent: number | null;
  employedAt: userInfo_user_employments_employedAt | null;
}

export interface userInfo_user {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
  idp: string | null;
  birthDate: any | null;
  nationalId: string | null;
  skolidOrganization: userInfo_user_skolidOrganization | null;
  connectedUsers: userInfo_user_connectedUsers[] | null;
  emails: userInfo_user_emails[] | null;
  addresses: userInfo_user_addresses[] | null;
  enrolments: userInfo_user_enrolments[] | null;
  placements: userInfo_user_placements[] | null;
  studentGroupMemberships: userInfo_user_studentGroupMemberships[] | null;
  relations: userInfo_user_relations | null;
  publicationRecipients: userInfo_user_publicationRecipients[] | null;
  employments: userInfo_user_employments[] | null;
}

export interface userInfo {
  user: userInfo_user | null;
}

export interface userInfoVariables {
  input: UserIdInput;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserWithPermissions
// ====================================================

export interface UserWithPermissions_user_skolidOrganization {
  __typename: "SkolidOrganization";
  id: string;
}

export interface UserWithPermissions_user_permissions_organisation_roles {
  __typename: "PermissionRole";
  roleId: PermissionType;
  sources: PermissionSource[];
}

export interface UserWithPermissions_user_permissions_organisation {
  __typename: "OrganisationPermissionElement";
  resourceId: string;
  displayName: string | null;
  roles: UserWithPermissions_user_permissions_organisation_roles[];
}

export interface UserWithPermissions_user_permissions_schoolUnits_roles {
  __typename: "PermissionRole";
  roleId: PermissionType;
  sources: PermissionSource[];
}

export interface UserWithPermissions_user_permissions_schoolUnits {
  __typename: "SchoolUnitPermissionElement";
  resourceId: string;
  displayName: string | null;
  employmentRoles: EmploymentRole[];
  roles: UserWithPermissions_user_permissions_schoolUnits_roles[];
}

export interface UserWithPermissions_user_permissions {
  __typename: "SubjectPermissions";
  id: string | null;
  organisation: UserWithPermissions_user_permissions_organisation;
  schoolUnits: UserWithPermissions_user_permissions_schoolUnits[];
}

export interface UserWithPermissions_user {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  idp: string | null;
  idpSub: string | null;
  name: string | null;
  skolidOrganization: UserWithPermissions_user_skolidOrganization | null;
  permissions: UserWithPermissions_user_permissions;
}

export interface UserWithPermissions_me_permissions_organisation_roles {
  __typename: "PermissionRole";
  roleId: PermissionType;
  sources: PermissionSource[];
}

export interface UserWithPermissions_me_permissions_organisation {
  __typename: "OrganisationPermissionElement";
  resourceId: string;
  displayName: string | null;
  roles: UserWithPermissions_me_permissions_organisation_roles[];
}

export interface UserWithPermissions_me_permissions_schoolUnits_roles {
  __typename: "PermissionRole";
  roleId: PermissionType;
  sources: PermissionSource[];
}

export interface UserWithPermissions_me_permissions_schoolUnits {
  __typename: "SchoolUnitPermissionElement";
  resourceId: string;
  displayName: string | null;
  employmentRoles: EmploymentRole[];
  roles: UserWithPermissions_me_permissions_schoolUnits_roles[];
}

export interface UserWithPermissions_me_permissions {
  __typename: "SubjectPermissions";
  id: string | null;
  organisation: UserWithPermissions_me_permissions_organisation;
  schoolUnits: UserWithPermissions_me_permissions_schoolUnits[];
}

export interface UserWithPermissions_me {
  __typename: "User";
  id: string;
  name: string | null;
  permissions: UserWithPermissions_me_permissions;
}

export interface UserWithPermissions_organisation {
  __typename: "Organisation";
  id: string;
  skolidOrgId: string | null;
}

export interface UserWithPermissions {
  user: UserWithPermissions_user | null;
  me: UserWithPermissions_me;
  organisation: UserWithPermissions_organisation;
}

export interface UserWithPermissionsVariables {
  input: UserIdInput;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserPermission
// ====================================================

export interface SetUserPermission_setUserPermission_organisation_roles {
  __typename: "PermissionRole";
  roleId: PermissionType;
  sources: PermissionSource[];
}

export interface SetUserPermission_setUserPermission_organisation {
  __typename: "OrganisationPermissionElement";
  resourceId: string;
  displayName: string | null;
  roles: SetUserPermission_setUserPermission_organisation_roles[];
}

export interface SetUserPermission_setUserPermission_schoolUnits_roles {
  __typename: "PermissionRole";
  roleId: PermissionType;
  sources: PermissionSource[];
}

export interface SetUserPermission_setUserPermission_schoolUnits {
  __typename: "SchoolUnitPermissionElement";
  resourceId: string;
  displayName: string | null;
  employmentRoles: EmploymentRole[];
  roles: SetUserPermission_setUserPermission_schoolUnits_roles[];
}

export interface SetUserPermission_setUserPermission {
  __typename: "SubjectPermissions";
  id: string | null;
  organisation: SetUserPermission_setUserPermission_organisation;
  schoolUnits: SetUserPermission_setUserPermission_schoolUnits[];
}

export interface SetUserPermission {
  setUserPermission: SetUserPermission_setUserPermission;
}

export interface SetUserPermissionVariables {
  input: SetUserPermissionInput;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ClassesOnSchoolUnit
// ====================================================

export interface ClassesOnSchoolUnit_schoolUnit_classes_sentFormsMetadata {
  __typename: "FormsSentToGroupMetadata";
  publications: number;
  numFormsSent: number;
  numCompleteAnswers: number;
}

export interface ClassesOnSchoolUnit_schoolUnit_classes {
  __typename: "StudentGroup";
  id: string;
  customerId: string;
  displayName: string;
  groupType: Code_GroupType | null;
  sentFormsMetadata: ClassesOnSchoolUnit_schoolUnit_classes_sentFormsMetadata;
}

export interface ClassesOnSchoolUnit_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  classes: ClassesOnSchoolUnit_schoolUnit_classes[];
}

export interface ClassesOnSchoolUnit {
  schoolUnit: ClassesOnSchoolUnit_schoolUnit;
}

export interface ClassesOnSchoolUnitVariables {
  id: string;
  customerId: string;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: schools
// ====================================================

export interface schools_organisation_schoolUnits_classes {
  __typename: "StudentGroup";
  id: string;
  customerId: string;
}

export interface schools_organisation_schoolUnits {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
  classes: schools_organisation_schoolUnits_classes[];
}

export interface schools_organisation {
  __typename: "Organisation";
  id: string;
  schoolUnits: schools_organisation_schoolUnits[];
}

export interface schools_me_favorites_ClassResponsesHistoryRecord_data {
  __typename: "ClassResponsesHistoryData";
  customerId: string;
  schoolUnitId: string;
  studentGroupId: string;
}

export interface schools_me_favorites_ClassResponsesHistoryRecord {
  __typename: "ClassResponsesHistoryRecord";
  id: string;
  label: string;
  type: RecordHistoryType;
  data: schools_me_favorites_ClassResponsesHistoryRecord_data;
}

export interface schools_me_favorites_FormHistoryRecord_data {
  __typename: "FormHistoryData";
  formId: string;
}

export interface schools_me_favorites_FormHistoryRecord {
  __typename: "FormHistoryRecord";
  id: string;
  label: string;
  type: RecordHistoryType;
  data: schools_me_favorites_FormHistoryRecord_data;
}

export type schools_me_favorites = schools_me_favorites_ClassResponsesHistoryRecord | schools_me_favorites_FormHistoryRecord;

export interface schools_me {
  __typename: "User";
  id: string;
  favorites: schools_me_favorites[];
}

export interface schools {
  organisation: schools_organisation;
  me: schools_me;
}

export interface schoolsVariables {
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: viewApplicationAnswerDetails
// ====================================================

export interface viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type viewApplicationAnswerDetails_application_form_componentData_components_questions = viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionYesNo | viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionRanking | viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionShortText | viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionCheckboxes | viewApplicationAnswerDetails_application_form_componentData_components_questions_FormQuestionMultiChoice;

export interface viewApplicationAnswerDetails_application_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface viewApplicationAnswerDetails_application_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: viewApplicationAnswerDetails_application_form_componentData_components_questions[];
  templateId: string | null;
  attachments: viewApplicationAnswerDetails_application_form_componentData_components_attachments[] | null;
}

export interface viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions = viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionYesNo | viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionRanking | viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionShortText | viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface viewApplicationAnswerDetails_application_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: viewApplicationAnswerDetails_application_form_componentData_predicateComponents_questions[];
}

export interface viewApplicationAnswerDetails_application_form_componentData {
  __typename: "ComponentData";
  components: viewApplicationAnswerDetails_application_form_componentData_components[];
  predicateComponents: viewApplicationAnswerDetails_application_form_componentData_predicateComponents[] | null;
}

export interface viewApplicationAnswerDetails_application_form_translations_componentData_predicateComponents_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface viewApplicationAnswerDetails_application_form_translations_componentData_predicateComponents_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: viewApplicationAnswerDetails_application_form_translations_componentData_predicateComponents_questions_options[] | null;
}

export interface viewApplicationAnswerDetails_application_form_translations_componentData_predicateComponents {
  __typename: "TranslatePredicateComponent";
  id: string;
  language: ISO6391Language | null;
  questions: viewApplicationAnswerDetails_application_form_translations_componentData_predicateComponents_questions[];
}

export interface viewApplicationAnswerDetails_application_form_translations_componentData_components_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface viewApplicationAnswerDetails_application_form_translations_componentData_components_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: viewApplicationAnswerDetails_application_form_translations_componentData_components_questions_options[] | null;
}

export interface viewApplicationAnswerDetails_application_form_translations_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface viewApplicationAnswerDetails_application_form_translations_componentData_components {
  __typename: "TranslateComponent";
  id: string;
  language: ISO6391Language | null;
  title: string;
  description: string;
  questions: viewApplicationAnswerDetails_application_form_translations_componentData_components_questions[];
  attachments: viewApplicationAnswerDetails_application_form_translations_componentData_components_attachments[];
}

export interface viewApplicationAnswerDetails_application_form_translations_componentData {
  __typename: "TranslationComponentData";
  predicateComponents: viewApplicationAnswerDetails_application_form_translations_componentData_predicateComponents[] | null;
  components: viewApplicationAnswerDetails_application_form_translations_componentData_components[];
}

export interface viewApplicationAnswerDetails_application_form_translations {
  __typename: "TranslationForm";
  formId: string;
  name: string;
  description: string;
  language: ISO6391Language;
  componentData: viewApplicationAnswerDetails_application_form_translations_componentData;
}

export interface viewApplicationAnswerDetails_application_form_owner {
  __typename: "Organisation";
  id: string;
  displayName: string;
}

export interface viewApplicationAnswerDetails_application_form {
  __typename: "Form";
  id: string;
  type: FormType;
  subType: FormSubType;
  name: string;
  description: string | null;
  language: ISO6391Language;
  componentData: viewApplicationAnswerDetails_application_form_componentData;
  translations: viewApplicationAnswerDetails_application_form_translations[];
  owner: viewApplicationAnswerDetails_application_form_owner;
}

export interface viewApplicationAnswerDetails_application_responses_creator_verifiedRelation {
  __typename: "VerifiedRelation";
  isVerifiedRelation: boolean;
  customerId: string | null;
}

export interface viewApplicationAnswerDetails_application_responses_creator_tempNationalIds {
  __typename: "TempNationalId";
  org: string;
  value: string;
}

export interface viewApplicationAnswerDetails_application_responses_creator {
  __typename: "ApplicationCreator";
  id: string;
  name: string;
  source: UserSource;
  nationalId: string | null;
  verifiedRelation: viewApplicationAnswerDetails_application_responses_creator_verifiedRelation;
  tempNationalIds: viewApplicationAnswerDetails_application_responses_creator_tempNationalIds[] | null;
  orgId: string;
}

export interface viewApplicationAnswerDetails_application_responses_subject_user {
  __typename: "User";
  id: string;
  name: string | null;
  source: UserSource;
  customerId: string | null;
  idp: string | null;
  idpSub: string | null;
}

export interface viewApplicationAnswerDetails_application_responses_subject {
  __typename: "SubjectCoSigner";
  userId: string | null;
  name: string;
  nationalId: string;
  user: viewApplicationAnswerDetails_application_responses_subject_user | null;
}

export interface viewApplicationAnswerDetails_application_responses_lastAction {
  __typename: "ApplicationResponseEvent";
  id: string;
  action: ApplicationResponseAction;
  newStatus: ApplicationResponseStatus;
  comment: string | null;
  created: any;
}

export interface viewApplicationAnswerDetails_application_responses_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  displayName: string;
}

export interface viewApplicationAnswerDetails_application_responses {
  __typename: "ApplicationResponse";
  id: string;
  creator: viewApplicationAnswerDetails_application_responses_creator;
  subject: viewApplicationAnswerDetails_application_responses_subject;
  created: any;
  modified: any;
  status: ApplicationResponseStatus;
  seenByOrg: any | null;
  lastAction: viewApplicationAnswerDetails_application_responses_lastAction;
  schoolUnit: viewApplicationAnswerDetails_application_responses_schoolUnit | null;
}

export interface viewApplicationAnswerDetails_application {
  __typename: "Application";
  id: string;
  name: string;
  schoolunitSpecific: boolean;
  form: viewApplicationAnswerDetails_application_form;
  responses: viewApplicationAnswerDetails_application_responses[];
}

export interface viewApplicationAnswerDetails {
  application: viewApplicationAnswerDetails_application;
}

export interface viewApplicationAnswerDetailsVariables {
  applicationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminPerformMultiApplicationResponseActions
// ====================================================

export interface AdminPerformMultiApplicationResponseActions_performMultiApplicationResponseActions_creator {
  __typename: "ApplicationCreator";
  id: string;
  name: string;
  source: UserSource;
}

export interface AdminPerformMultiApplicationResponseActions_performMultiApplicationResponseActions_subject_user {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
}

export interface AdminPerformMultiApplicationResponseActions_performMultiApplicationResponseActions_subject {
  __typename: "SubjectCoSigner";
  userId: string | null;
  name: string;
  user: AdminPerformMultiApplicationResponseActions_performMultiApplicationResponseActions_subject_user | null;
}

export interface AdminPerformMultiApplicationResponseActions_performMultiApplicationResponseActions_lastAction {
  __typename: "ApplicationResponseEvent";
  id: string;
  action: ApplicationResponseAction;
  newStatus: ApplicationResponseStatus;
  comment: string | null;
  created: any;
}

export interface AdminPerformMultiApplicationResponseActions_performMultiApplicationResponseActions_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  displayName: string;
}

export interface AdminPerformMultiApplicationResponseActions_performMultiApplicationResponseActions {
  __typename: "ApplicationResponse";
  id: string;
  creator: AdminPerformMultiApplicationResponseActions_performMultiApplicationResponseActions_creator;
  subject: AdminPerformMultiApplicationResponseActions_performMultiApplicationResponseActions_subject;
  created: any;
  modified: any;
  status: ApplicationResponseStatus;
  seenByOrg: any | null;
  lastAction: AdminPerformMultiApplicationResponseActions_performMultiApplicationResponseActions_lastAction;
  schoolUnit: AdminPerformMultiApplicationResponseActions_performMultiApplicationResponseActions_schoolUnit | null;
}

export interface AdminPerformMultiApplicationResponseActions {
  performMultiApplicationResponseActions: AdminPerformMultiApplicationResponseActions_performMultiApplicationResponseActions[];
}

export interface AdminPerformMultiApplicationResponseActionsVariables {
  input: MultiActionInput;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: viewOrgApplicationAnswer
// ====================================================

export interface viewOrgApplicationAnswer_organisation_applications_form {
  __typename: "Form";
  id: string;
  name: string;
  subType: FormSubType;
}

export interface viewOrgApplicationAnswer_organisation_applications_creator {
  __typename: "User" | "Client";
  id: string;
  name: string | null;
}

export interface viewOrgApplicationAnswer_organisation_applications_stats {
  __typename: "ApplicationStatistics";
  answered: number;
  open: number;
  closed: number;
}

export interface viewOrgApplicationAnswer_organisation_applications {
  __typename: "Application";
  id: string;
  name: string;
  closed: boolean;
  form: viewOrgApplicationAnswer_organisation_applications_form;
  creator: viewOrgApplicationAnswer_organisation_applications_creator | null;
  created: any;
  modified: any;
  sendDate: any;
  singleGuardianSend: boolean;
  singleGuardianAcceptOffer: boolean;
  schoolunitSpecific: boolean;
  sent: boolean;
  stats: viewOrgApplicationAnswer_organisation_applications_stats;
}

export interface viewOrgApplicationAnswer_organisation {
  __typename: "Organisation";
  id: string;
  applications: viewOrgApplicationAnswer_organisation_applications[];
}

export interface viewOrgApplicationAnswer {
  organisation: viewOrgApplicationAnswer_organisation;
}

export interface viewOrgApplicationAnswerVariables {
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteApplication
// ====================================================

export interface DeleteApplication_deleteApplication {
  __typename: "Application";
  id: string;
}

export interface DeleteApplication {
  deleteApplication: DeleteApplication_deleteApplication;
}

export interface DeleteApplicationVariables {
  id: string;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: viewApplicationsData
// ====================================================

export interface viewApplicationsData_organisation_applications_form {
  __typename: "Form";
  id: string;
  name: string;
  subType: FormSubType;
}

export interface viewApplicationsData_organisation_applications_creator {
  __typename: "User" | "Client";
  id: string;
  name: string | null;
}

export interface viewApplicationsData_organisation_applications_stats {
  __typename: "ApplicationStatistics";
  answered: number;
  open: number;
  closed: number;
}

export interface viewApplicationsData_organisation_applications {
  __typename: "Application";
  id: string;
  name: string;
  form: viewApplicationsData_organisation_applications_form;
  creator: viewApplicationsData_organisation_applications_creator | null;
  created: any;
  modified: any;
  sendDate: any;
  singleGuardianSend: boolean;
  singleGuardianAcceptOffer: boolean;
  closed: boolean;
  sent: boolean;
  stats: viewApplicationsData_organisation_applications_stats;
}

export interface viewApplicationsData_organisation {
  __typename: "Organisation";
  id: string;
  applications: viewApplicationsData_organisation_applications[];
}

export interface viewApplicationsData {
  organisation: viewApplicationsData_organisation;
}

export interface viewApplicationsDataVariables {
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FilteredNotificationQuery
// ====================================================

export interface FilteredNotificationQuery_publication_notifications_edges_recipients_user {
  __typename: "User";
  id: string;
  customerId: string | null;
  name: string | null;
}

export interface FilteredNotificationQuery_publication_notifications_edges_recipients {
  __typename: "PublicationNotificationRecipientInfo";
  user: FilteredNotificationQuery_publication_notifications_edges_recipients_user | null;
  available: string[];
}

export interface FilteredNotificationQuery_publication_notifications_edges_child {
  __typename: "User";
  id: string;
  customerId: string | null;
  name: string | null;
}

export interface FilteredNotificationQuery_publication_notifications_edges {
  __typename: "PublicationNotificationUser";
  recipients: FilteredNotificationQuery_publication_notifications_edges_recipients[];
  child: FilteredNotificationQuery_publication_notifications_edges_child | null;
  childGroupIds: string[];
}

export interface FilteredNotificationQuery_publication_notifications {
  __typename: "PublicationNotificationUserConnection";
  filteredByPermissions: boolean;
  edges: FilteredNotificationQuery_publication_notifications_edges[];
}

export interface FilteredNotificationQuery_publication {
  __typename: "Publication";
  id: string;
  notifications: FilteredNotificationQuery_publication_notifications;
}

export interface FilteredNotificationQuery {
  publication: FilteredNotificationQuery_publication;
}

export interface FilteredNotificationQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: NonRespondents
// ====================================================

export interface NonRespondents_publication_nonRespondents_edges_student {
  __typename: "User";
  id: string;
  customerId: string | null;
  name: string | null;
}

export interface NonRespondents_publication_nonRespondents_edges {
  __typename: "NonRespondent";
  groupIds: string[];
  student: NonRespondents_publication_nonRespondents_edges_student | null;
}

export interface NonRespondents_publication_nonRespondents {
  __typename: "PublicationNonRespondentUserConnection";
  filteredByPermissions: boolean;
  edges: NonRespondents_publication_nonRespondents_edges[];
}

export interface NonRespondents_publication {
  __typename: "Publication";
  id: string;
  nonRespondents: NonRespondents_publication_nonRespondents;
}

export interface NonRespondents {
  publication: NonRespondents_publication;
}

export interface NonRespondentsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PromptUpdateUncontactable
// ====================================================

export interface PromptUpdateUncontactable_promptUpdateUncontactable {
  __typename: "Response";
  code: ReturnCode | null;
  message: string | null;
}

export interface PromptUpdateUncontactable {
  promptUpdateUncontactable: PromptUpdateUncontactable_promptUpdateUncontactable;
}

export interface PromptUpdateUncontactableVariables {
  id: string;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ChangedRecipients
// ====================================================

export interface ChangedRecipients_publication_recipientUpdateChanges_added_user {
  __typename: "User";
  id: string;
  customerId: string | null;
  name: string | null;
}

export interface ChangedRecipients_publication_recipientUpdateChanges_added {
  __typename: "PublicationUser";
  groupIds: string[];
  manuallyAdded: boolean;
  excludedFromPublication: boolean;
  user: ChangedRecipients_publication_recipientUpdateChanges_added_user | null;
}

export interface ChangedRecipients_publication_recipientUpdateChanges_removed_user {
  __typename: "User";
  id: string;
  customerId: string | null;
  name: string | null;
}

export interface ChangedRecipients_publication_recipientUpdateChanges_removed {
  __typename: "PublicationUser";
  groupIds: string[];
  manuallyAdded: boolean;
  excludedFromPublication: boolean;
  user: ChangedRecipients_publication_recipientUpdateChanges_removed_user | null;
}

export interface ChangedRecipients_publication_recipientUpdateChanges_changed_user {
  __typename: "User";
  id: string;
  customerId: string | null;
  name: string | null;
}

export interface ChangedRecipients_publication_recipientUpdateChanges_changed {
  __typename: "PublicationUser";
  groupIds: string[];
  manuallyAdded: boolean;
  excludedFromPublication: boolean;
  user: ChangedRecipients_publication_recipientUpdateChanges_changed_user | null;
}

export interface ChangedRecipients_publication_recipientUpdateChanges {
  __typename: "PublicationRecipientListChanges";
  added: ChangedRecipients_publication_recipientUpdateChanges_added[];
  removed: ChangedRecipients_publication_recipientUpdateChanges_removed[];
  changed: ChangedRecipients_publication_recipientUpdateChanges_changed[];
}

export interface ChangedRecipients_publication {
  __typename: "Publication";
  id: string;
  recipientUpdateChanges: ChangedRecipients_publication_recipientUpdateChanges;
}

export interface ChangedRecipients {
  publication: ChangedRecipients_publication;
}

export interface ChangedRecipientsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendPublicationReminder
// ====================================================

export interface SendPublicationReminder_sendPublicationReminder {
  __typename: "Response";
  code: ReturnCode | null;
  message: string | null;
}

export interface SendPublicationReminder {
  sendPublicationReminder: SendPublicationReminder_sendPublicationReminder;
}

export interface SendPublicationReminderVariables {
  id: string;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePublicationRecipientList
// ====================================================

export interface UpdatePublicationRecipientList_updatePublicationRecipients_recipientUpdateChanges_added_user {
  __typename: "User";
  id: string;
  customerId: string | null;
  name: string | null;
}

export interface UpdatePublicationRecipientList_updatePublicationRecipients_recipientUpdateChanges_added {
  __typename: "PublicationUser";
  groupIds: string[];
  manuallyAdded: boolean;
  excludedFromPublication: boolean;
  user: UpdatePublicationRecipientList_updatePublicationRecipients_recipientUpdateChanges_added_user | null;
}

export interface UpdatePublicationRecipientList_updatePublicationRecipients_recipientUpdateChanges_removed_user {
  __typename: "User";
  id: string;
  customerId: string | null;
  name: string | null;
}

export interface UpdatePublicationRecipientList_updatePublicationRecipients_recipientUpdateChanges_removed {
  __typename: "PublicationUser";
  groupIds: string[];
  manuallyAdded: boolean;
  excludedFromPublication: boolean;
  user: UpdatePublicationRecipientList_updatePublicationRecipients_recipientUpdateChanges_removed_user | null;
}

export interface UpdatePublicationRecipientList_updatePublicationRecipients_recipientUpdateChanges_changed_user {
  __typename: "User";
  id: string;
  customerId: string | null;
  name: string | null;
}

export interface UpdatePublicationRecipientList_updatePublicationRecipients_recipientUpdateChanges_changed {
  __typename: "PublicationUser";
  groupIds: string[];
  manuallyAdded: boolean;
  excludedFromPublication: boolean;
  user: UpdatePublicationRecipientList_updatePublicationRecipients_recipientUpdateChanges_changed_user | null;
}

export interface UpdatePublicationRecipientList_updatePublicationRecipients_recipientUpdateChanges {
  __typename: "PublicationRecipientListChanges";
  addedCount: number;
  removedCount: number;
  added: UpdatePublicationRecipientList_updatePublicationRecipients_recipientUpdateChanges_added[];
  removed: UpdatePublicationRecipientList_updatePublicationRecipients_recipientUpdateChanges_removed[];
  changed: UpdatePublicationRecipientList_updatePublicationRecipients_recipientUpdateChanges_changed[];
}

export interface UpdatePublicationRecipientList_updatePublicationRecipients {
  __typename: "Publication";
  id: string;
  recipientUpdateChanges: UpdatePublicationRecipientList_updatePublicationRecipients_recipientUpdateChanges;
}

export interface UpdatePublicationRecipientList {
  updatePublicationRecipients: UpdatePublicationRecipientList_updatePublicationRecipients;
}

export interface UpdatePublicationRecipientListVariables {
  id: string;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RevokePublication
// ====================================================

export interface RevokePublication_revokePublication {
  __typename: "Publication";
  id: string;
}

export interface RevokePublication {
  revokePublication: RevokePublication_revokePublication;
}

export interface RevokePublicationVariables {
  id: string;
  reason: string;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: orgData
// ====================================================

export interface orgData_organisation_publications_form {
  __typename: "Form";
  id: string;
  name: string;
}

export interface orgData_organisation_publications_creator {
  __typename: "User" | "Client";
  id: string;
  name: string | null;
}

export interface orgData_organisation_publications_stats {
  __typename: "PublicationStatistics";
  total: number;
  groups: number;
  sent: number;
  answered: number;
  unanswered: number;
  undeliverableNotifications: number;
}

export interface orgData_organisation_publications_recipientUpdateChanges {
  __typename: "PublicationRecipientListChanges";
  addedCount: number;
  removedCount: number;
  changedCount: number;
}

export interface orgData_organisation_publications_recipients_groups_edges {
  __typename: "PublicationGroupStudentGroup";
  id: string;
  type: PublicationGroupType;
  displayName: string;
  removed: boolean;
  memberCount: number;
  schoolYearFilter: number[] | null;
}

export interface orgData_organisation_publications_recipients_groups {
  __typename: "PublicationGroupConnection";
  edges: orgData_organisation_publications_recipients_groups_edges[];
}

export interface orgData_organisation_publications_recipients {
  __typename: "PublicationRecipientList";
  groups: orgData_organisation_publications_recipients_groups;
}

export interface orgData_organisation_publications {
  __typename: "Publication";
  id: string;
  name: string;
  form: orgData_organisation_publications_form;
  creator: orgData_organisation_publications_creator | null;
  created: any;
  sendDate: any;
  validFrom: any;
  validTo: any;
  lastDryrun: any | null;
  lastNotified: any | null;
  lastReminderSent: any | null;
  sent: boolean;
  stats: orgData_organisation_publications_stats;
  recipientUpdateChanges: orgData_organisation_publications_recipientUpdateChanges;
  recipients: orgData_organisation_publications_recipients;
}

export interface orgData_organisation {
  __typename: "Organisation";
  id: string;
  publications: orgData_organisation_publications[];
}

export interface orgData {
  organisation: orgData_organisation;
}

export interface orgDataVariables {
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePublication
// ====================================================

export interface DeletePublication_deletePublication {
  __typename: "Publication";
  id: string;
}

export interface DeletePublication {
  deletePublication: DeletePublication_deletePublication;
}

export interface DeletePublicationVariables {
  id: string;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BuildInfoFooter
// ====================================================

export interface BuildInfoFooter_buildInfo {
  __typename: "BuildInfo";
  version: string;
}

export interface BuildInfoFooter {
  buildInfo: BuildInfoFooter_buildInfo;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailableOrgs
// ====================================================

export interface AvailableOrgs_me_skolidOrganization {
  __typename: "SkolidOrganization";
  id: string;
  name: string;
  country: ISO3166Country;
}

export interface AvailableOrgs_me_availableOrganisations {
  __typename: "Organisation";
  id: string;
  displayName: string;
  defaultLanguage: ISO6391Language;
}

export interface AvailableOrgs_me_connectedUsers_skolidOrganization {
  __typename: "SkolidOrganization";
  id: string;
  name: string;
  country: ISO3166Country;
}

export interface AvailableOrgs_me_connectedUsers_availableOrganisations_features {
  __typename: "OrganizationFeatures";
  relocation: boolean;
}

export interface AvailableOrgs_me_connectedUsers_availableOrganisations {
  __typename: "Organisation";
  id: string;
  displayName: string;
  defaultLanguage: ISO6391Language;
  features: AvailableOrgs_me_connectedUsers_availableOrganisations_features;
}

export interface AvailableOrgs_me_connectedUsers {
  __typename: "User";
  id: string;
  name: string | null;
  skolidOrganization: AvailableOrgs_me_connectedUsers_skolidOrganization | null;
  availableOrganisations: AvailableOrgs_me_connectedUsers_availableOrganisations[];
}

export interface AvailableOrgs_me {
  __typename: "User";
  id: string;
  source: UserSource;
  idpSub: string | null;
  name: string | null;
  skolidOrganization: AvailableOrgs_me_skolidOrganization | null;
  availableOrganisations: AvailableOrgs_me_availableOrganisations[];
  connectedUsers: AvailableOrgs_me_connectedUsers[] | null;
}

export interface AvailableOrgs {
  me: AvailableOrgs_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Me
// ====================================================

export interface Me_me_permissions_organisation_roles {
  __typename: "PermissionRole";
  roleId: PermissionType;
  sources: PermissionSource[];
}

export interface Me_me_permissions_organisation {
  __typename: "OrganisationPermissionElement";
  resourceId: string;
  displayName: string | null;
  roles: Me_me_permissions_organisation_roles[];
}

export interface Me_me_permissions_schoolUnits_roles {
  __typename: "PermissionRole";
  roleId: PermissionType;
  sources: PermissionSource[];
}

export interface Me_me_permissions_schoolUnits {
  __typename: "SchoolUnitPermissionElement";
  resourceId: string;
  displayName: string | null;
  employmentRoles: EmploymentRole[];
  roles: Me_me_permissions_schoolUnits_roles[];
}

export interface Me_me_permissions {
  __typename: "SubjectPermissions";
  id: string | null;
  organisation: Me_me_permissions_organisation;
  schoolUnits: Me_me_permissions_schoolUnits[];
}

export interface Me_me {
  __typename: "User";
  id: string;
  name: string | null;
  permissions: Me_me_permissions;
}

export interface Me {
  me: Me_me;
}

export interface MeVariables {
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MyEducloudUserIds
// ====================================================

export interface MyEducloudUserIds_me_educloudUserIds {
  __typename: "IdCustomerId";
  id: string;
  customerId: string;
}

export interface MyEducloudUserIds_me {
  __typename: "User";
  id: string;
  educloudUserIds: MyEducloudUserIds_me_educloudUserIds[];
  language: string | null;
}

export interface MyEducloudUserIds {
  me: MyEducloudUserIds_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicationCustomComponentRelations
// ====================================================

export interface ApplicationCustomComponentRelations_me_emails {
  __typename: "UserEmail";
  value: string;
  preferredHome: boolean;
}

export interface ApplicationCustomComponentRelations_me_relations_edges_node_enrolments_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface ApplicationCustomComponentRelations_me_relations_edges_node_enrolments {
  __typename: "Enrolment";
  schoolType: Code_SchoolType;
  startDate: any;
  endDate: any | null;
  schoolUnit: ApplicationCustomComponentRelations_me_relations_edges_node_enrolments_schoolUnit | null;
}

export interface ApplicationCustomComponentRelations_me_relations_edges_node_placements_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface ApplicationCustomComponentRelations_me_relations_edges_node_placements {
  __typename: "Placement";
  schoolType: Code_SchoolType | null;
  startDate: any | null;
  endDate: any | null;
  schoolUnit: ApplicationCustomComponentRelations_me_relations_edges_node_placements_schoolUnit | null;
}

export interface ApplicationCustomComponentRelations_me_relations_edges_node {
  __typename: "User";
  id: string;
  customerId: string | null;
  name: string | null;
  source: UserSource;
  enrolments: ApplicationCustomComponentRelations_me_relations_edges_node_enrolments[] | null;
  placements: ApplicationCustomComponentRelations_me_relations_edges_node_placements[] | null;
}

export interface ApplicationCustomComponentRelations_me_relations_edges {
  __typename: "Relation";
  node: ApplicationCustomComponentRelations_me_relations_edges_node | null;
}

export interface ApplicationCustomComponentRelations_me_relations {
  __typename: "RelationConnection";
  edges: ApplicationCustomComponentRelations_me_relations_edges[];
}

export interface ApplicationCustomComponentRelations_me {
  __typename: "User";
  id: string;
  name: string | null;
  emails: ApplicationCustomComponentRelations_me_emails[] | null;
  relations: ApplicationCustomComponentRelations_me_relations | null;
}

export interface ApplicationCustomComponentRelations {
  me: ApplicationCustomComponentRelations_me;
}

export interface ApplicationCustomComponentRelationsVariables {
  org: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EmailTemplate
// ====================================================

export interface EmailTemplate_emailTemplate {
  __typename: "EmailTemplate";
  subject: string;
  htmlContent: string;
}

export interface EmailTemplate {
  emailTemplate: EmailTemplate_emailTemplate;
}

export interface EmailTemplateVariables {
  language: string;
  type: EmailTemplateType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EmailTemplateSubstituions
// ====================================================

export interface EmailTemplateSubstituions_emailSubstitutions {
  __typename: "EmailTemplateSubstitutions";
  org: string | null;
  sender: string | null;
  serviceName: string | null;
  serviceUrl: string | null;
  actionUrl: string | null;
}

export interface EmailTemplateSubstituions {
  emailSubstitutions: EmailTemplateSubstituions_emailSubstitutions | null;
}

export interface EmailTemplateSubstituionsVariables {
  org: string;
  role?: EmailTemplateSubstitutionsRole | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FormAccessSchoolUnits
// ====================================================

export interface FormAccessSchoolUnits_organisation_schoolUnits {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface FormAccessSchoolUnits_organisation {
  __typename: "Organisation";
  id: string;
  displayName: string;
  schoolUnits: FormAccessSchoolUnits_organisation_schoolUnits[];
}

export interface FormAccessSchoolUnits {
  organisation: FormAccessSchoolUnits_organisation;
}

export interface FormAccessSchoolUnitsVariables {
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignFormFormUpload
// ====================================================

export interface SignFormFormUpload_signFormUpload_post_headers {
  __typename: "AwsHeader";
  key: string;
  value: string;
}

export interface SignFormFormUpload_signFormUpload {
  __typename: "SignFormUpload";
  key: string;
  signedUrl: string;
  post_headers: SignFormFormUpload_signFormUpload_post_headers[];
}

export interface SignFormFormUpload {
  signFormUpload: SignFormFormUpload_signFormUpload;
}

export interface SignFormFormUploadVariables {
  input: SignFormUploadInput;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: myAdminSettings
// ====================================================

export interface myAdminSettings_me_settings {
  __typename: "UserSettings";
  id: string;
  workEmail: string | null;
  personalEmail: string | null;
  updatedConsentNotification: boolean;
}

export interface myAdminSettings_me_emails {
  __typename: "UserEmail";
  value: string;
  type: string;
  preferredHome: boolean;
  preferredWork: boolean;
}

export interface myAdminSettings_me {
  __typename: "User";
  id: string;
  settings: myAdminSettings_me_settings;
  emails: myAdminSettings_me_emails[] | null;
}

export interface myAdminSettings {
  me: myAdminSettings_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateUserSettings
// ====================================================

export interface updateUserSettings_updateUserSettings {
  __typename: "UserSettings";
  id: string;
  workEmail: string | null;
  personalEmail: string | null;
  updatedConsentNotification: boolean;
}

export interface updateUserSettings {
  updateUserSettings: updateUserSettings_updateUserSettings;
}

export interface updateUserSettingsVariables {
  input: UserSettingsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: recordFormHistory
// ====================================================

export interface recordFormHistory_recordFormHistory_ClassResponsesHistoryRecord_data {
  __typename: "ClassResponsesHistoryData";
  customerId: string;
  schoolUnitId: string;
  studentGroupId: string;
}

export interface recordFormHistory_recordFormHistory_ClassResponsesHistoryRecord {
  __typename: "ClassResponsesHistoryRecord";
  id: string;
  label: string;
  type: RecordHistoryType;
  data: recordFormHistory_recordFormHistory_ClassResponsesHistoryRecord_data;
}

export interface recordFormHistory_recordFormHistory_FormHistoryRecord_data {
  __typename: "FormHistoryData";
  formId: string;
}

export interface recordFormHistory_recordFormHistory_FormHistoryRecord {
  __typename: "FormHistoryRecord";
  id: string;
  label: string;
  type: RecordHistoryType;
  data: recordFormHistory_recordFormHistory_FormHistoryRecord_data;
}

export type recordFormHistory_recordFormHistory = recordFormHistory_recordFormHistory_ClassResponsesHistoryRecord | recordFormHistory_recordFormHistory_FormHistoryRecord;

export interface recordFormHistory {
  recordFormHistory: recordFormHistory_recordFormHistory;
}

export interface recordFormHistoryVariables {
  input: FormHistoryRecordInput;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: recordClassResponsesHistory
// ====================================================

export interface recordClassResponsesHistory_recordClassResponsesHistory_ClassResponsesHistoryRecord_data {
  __typename: "ClassResponsesHistoryData";
  customerId: string;
  schoolUnitId: string;
  studentGroupId: string;
}

export interface recordClassResponsesHistory_recordClassResponsesHistory_ClassResponsesHistoryRecord {
  __typename: "ClassResponsesHistoryRecord";
  id: string;
  label: string;
  type: RecordHistoryType;
  data: recordClassResponsesHistory_recordClassResponsesHistory_ClassResponsesHistoryRecord_data;
}

export interface recordClassResponsesHistory_recordClassResponsesHistory_FormHistoryRecord_data {
  __typename: "FormHistoryData";
  formId: string;
}

export interface recordClassResponsesHistory_recordClassResponsesHistory_FormHistoryRecord {
  __typename: "FormHistoryRecord";
  id: string;
  label: string;
  type: RecordHistoryType;
  data: recordClassResponsesHistory_recordClassResponsesHistory_FormHistoryRecord_data;
}

export type recordClassResponsesHistory_recordClassResponsesHistory = recordClassResponsesHistory_recordClassResponsesHistory_ClassResponsesHistoryRecord | recordClassResponsesHistory_recordClassResponsesHistory_FormHistoryRecord;

export interface recordClassResponsesHistory {
  recordClassResponsesHistory: recordClassResponsesHistory_recordClassResponsesHistory;
}

export interface recordClassResponsesHistoryVariables {
  input: ClassResponsesHistoryRecordInput;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteFavorite
// ====================================================

export interface deleteFavorite_deleteFavorite {
  __typename: "FormHistoryRecord" | "ClassResponsesHistoryRecord";
  id: string;
}

export interface deleteFavorite {
  deleteFavorite: deleteFavorite_deleteFavorite | null;
}

export interface deleteFavoriteVariables {
  id: string;
  context?: Context | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveResponseDraft
// ====================================================

export interface RemoveResponseDraft_removeResponseDraft {
  __typename: "PublicationResponse";
  id: string;
}

export interface RemoveResponseDraft {
  removeResponseDraft: RemoveResponseDraft_removeResponseDraft;
}

export interface RemoveResponseDraftVariables {
  responseId: string;
  context?: Context | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemovePublicationResponseSignatures
// ====================================================

export interface RemovePublicationResponseSignatures_removePublicationResponseSignatures_signatures_signer {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  idp: string | null;
  idpSub: string | null;
  name: string | null;
}

export interface RemovePublicationResponseSignatures_removePublicationResponseSignatures_signatures_educloudUser {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
}

export interface RemovePublicationResponseSignatures_removePublicationResponseSignatures_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  customerId: string | null;
  signer: RemovePublicationResponseSignatures_removePublicationResponseSignatures_signatures_signer | null;
  educloudUser: RemovePublicationResponseSignatures_removePublicationResponseSignatures_signatures_educloudUser | null;
  educloudUserId: string | null;
}

export interface RemovePublicationResponseSignatures_removePublicationResponseSignatures_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface RemovePublicationResponseSignatures_removePublicationResponseSignatures_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface RemovePublicationResponseSignatures_removePublicationResponseSignatures_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface RemovePublicationResponseSignatures_removePublicationResponseSignatures_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: RemovePublicationResponseSignatures_removePublicationResponseSignatures_response_components_questions_AnswerRanking_rankings[];
}

export interface RemovePublicationResponseSignatures_removePublicationResponseSignatures_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface RemovePublicationResponseSignatures_removePublicationResponseSignatures_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface RemovePublicationResponseSignatures_removePublicationResponseSignatures_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface RemovePublicationResponseSignatures_removePublicationResponseSignatures_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type RemovePublicationResponseSignatures_removePublicationResponseSignatures_response_components_questions = RemovePublicationResponseSignatures_removePublicationResponseSignatures_response_components_questions_AnswerMultiChoice | RemovePublicationResponseSignatures_removePublicationResponseSignatures_response_components_questions_AnswerCheckboxes | RemovePublicationResponseSignatures_removePublicationResponseSignatures_response_components_questions_AnswerRanking | RemovePublicationResponseSignatures_removePublicationResponseSignatures_response_components_questions_AnswerShortText | RemovePublicationResponseSignatures_removePublicationResponseSignatures_response_components_questions_AnswerLongText | RemovePublicationResponseSignatures_removePublicationResponseSignatures_response_components_questions_AnswerYesOrNo | RemovePublicationResponseSignatures_removePublicationResponseSignatures_response_components_questions_AnswerDate;

export interface RemovePublicationResponseSignatures_removePublicationResponseSignatures_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: RemovePublicationResponseSignatures_removePublicationResponseSignatures_response_components_questions[];
}

export interface RemovePublicationResponseSignatures_removePublicationResponseSignatures_response {
  __typename: "FormAnswer";
  components: RemovePublicationResponseSignatures_removePublicationResponseSignatures_response_components[];
}

export interface RemovePublicationResponseSignatures_removePublicationResponseSignatures_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface RemovePublicationResponseSignatures_removePublicationResponseSignatures {
  __typename: "PublicationResponse";
  id: string;
  status: PublicationResponseStatus;
  signRole: PublicationResponseSignRole | null;
  signatures: RemovePublicationResponseSignatures_removePublicationResponseSignatures_signatures[] | null;
  modified: any;
  language: ISO6391Language;
  response: RemovePublicationResponseSignatures_removePublicationResponseSignatures_response | null;
  lockHolder: RemovePublicationResponseSignatures_removePublicationResponseSignatures_lockHolder | null;
  lockExpire: any | null;
  validIfSigned: boolean;
}

export interface RemovePublicationResponseSignatures {
  removePublicationResponseSignatures: RemovePublicationResponseSignatures_removePublicationResponseSignatures;
}

export interface RemovePublicationResponseSignaturesVariables {
  id: string;
  context?: Context | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateResponse
// ====================================================

export interface CreateResponse_createPublicationResponse_signatures_signer {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  idp: string | null;
  idpSub: string | null;
  name: string | null;
}

export interface CreateResponse_createPublicationResponse_signatures_educloudUser {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
}

export interface CreateResponse_createPublicationResponse_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  customerId: string | null;
  signer: CreateResponse_createPublicationResponse_signatures_signer | null;
  educloudUser: CreateResponse_createPublicationResponse_signatures_educloudUser | null;
  educloudUserId: string | null;
}

export interface CreateResponse_createPublicationResponse_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface CreateResponse_createPublicationResponse_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface CreateResponse_createPublicationResponse_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface CreateResponse_createPublicationResponse_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: CreateResponse_createPublicationResponse_response_components_questions_AnswerRanking_rankings[];
}

export interface CreateResponse_createPublicationResponse_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface CreateResponse_createPublicationResponse_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface CreateResponse_createPublicationResponse_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface CreateResponse_createPublicationResponse_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type CreateResponse_createPublicationResponse_response_components_questions = CreateResponse_createPublicationResponse_response_components_questions_AnswerMultiChoice | CreateResponse_createPublicationResponse_response_components_questions_AnswerCheckboxes | CreateResponse_createPublicationResponse_response_components_questions_AnswerRanking | CreateResponse_createPublicationResponse_response_components_questions_AnswerShortText | CreateResponse_createPublicationResponse_response_components_questions_AnswerLongText | CreateResponse_createPublicationResponse_response_components_questions_AnswerYesOrNo | CreateResponse_createPublicationResponse_response_components_questions_AnswerDate;

export interface CreateResponse_createPublicationResponse_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: CreateResponse_createPublicationResponse_response_components_questions[];
}

export interface CreateResponse_createPublicationResponse_response {
  __typename: "FormAnswer";
  components: CreateResponse_createPublicationResponse_response_components[];
}

export interface CreateResponse_createPublicationResponse_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface CreateResponse_createPublicationResponse {
  __typename: "PublicationResponse";
  id: string;
  status: PublicationResponseStatus;
  signRole: PublicationResponseSignRole | null;
  signatures: CreateResponse_createPublicationResponse_signatures[] | null;
  modified: any;
  language: ISO6391Language;
  response: CreateResponse_createPublicationResponse_response | null;
  lockHolder: CreateResponse_createPublicationResponse_lockHolder | null;
  lockExpire: any | null;
  validIfSigned: boolean;
}

export interface CreateResponse {
  createPublicationResponse: CreateResponse_createPublicationResponse;
}

export interface CreateResponseVariables {
  recipientId: string;
  response: FormAnswerInput;
  language?: ISO6391Language | null;
  context?: Context | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveResponse
// ====================================================

export interface SaveResponse_savePublicationResponse_signatures_signer {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  idp: string | null;
  idpSub: string | null;
  name: string | null;
}

export interface SaveResponse_savePublicationResponse_signatures_educloudUser {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
}

export interface SaveResponse_savePublicationResponse_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  customerId: string | null;
  signer: SaveResponse_savePublicationResponse_signatures_signer | null;
  educloudUser: SaveResponse_savePublicationResponse_signatures_educloudUser | null;
  educloudUserId: string | null;
}

export interface SaveResponse_savePublicationResponse_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface SaveResponse_savePublicationResponse_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface SaveResponse_savePublicationResponse_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface SaveResponse_savePublicationResponse_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: SaveResponse_savePublicationResponse_response_components_questions_AnswerRanking_rankings[];
}

export interface SaveResponse_savePublicationResponse_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface SaveResponse_savePublicationResponse_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface SaveResponse_savePublicationResponse_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface SaveResponse_savePublicationResponse_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type SaveResponse_savePublicationResponse_response_components_questions = SaveResponse_savePublicationResponse_response_components_questions_AnswerMultiChoice | SaveResponse_savePublicationResponse_response_components_questions_AnswerCheckboxes | SaveResponse_savePublicationResponse_response_components_questions_AnswerRanking | SaveResponse_savePublicationResponse_response_components_questions_AnswerShortText | SaveResponse_savePublicationResponse_response_components_questions_AnswerLongText | SaveResponse_savePublicationResponse_response_components_questions_AnswerYesOrNo | SaveResponse_savePublicationResponse_response_components_questions_AnswerDate;

export interface SaveResponse_savePublicationResponse_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: SaveResponse_savePublicationResponse_response_components_questions[];
}

export interface SaveResponse_savePublicationResponse_response {
  __typename: "FormAnswer";
  components: SaveResponse_savePublicationResponse_response_components[];
}

export interface SaveResponse_savePublicationResponse_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface SaveResponse_savePublicationResponse {
  __typename: "PublicationResponse";
  id: string;
  status: PublicationResponseStatus;
  signRole: PublicationResponseSignRole | null;
  signatures: SaveResponse_savePublicationResponse_signatures[] | null;
  modified: any;
  language: ISO6391Language;
  response: SaveResponse_savePublicationResponse_response | null;
  lockHolder: SaveResponse_savePublicationResponse_lockHolder | null;
  lockExpire: any | null;
  validIfSigned: boolean;
}

export interface SaveResponse {
  savePublicationResponse: SaveResponse_savePublicationResponse;
}

export interface SaveResponseVariables {
  responseId: string;
  response: FormAnswerInput;
  language: ISO6391Language;
  context?: Context | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignPublicationResponse
// ====================================================

export interface SignPublicationResponse_signPublicationResponse_signatures_signer {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  idp: string | null;
  idpSub: string | null;
  name: string | null;
}

export interface SignPublicationResponse_signPublicationResponse_signatures_educloudUser {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
}

export interface SignPublicationResponse_signPublicationResponse_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  customerId: string | null;
  signer: SignPublicationResponse_signPublicationResponse_signatures_signer | null;
  educloudUser: SignPublicationResponse_signPublicationResponse_signatures_educloudUser | null;
  educloudUserId: string | null;
}

export interface SignPublicationResponse_signPublicationResponse_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface SignPublicationResponse_signPublicationResponse_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface SignPublicationResponse_signPublicationResponse_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface SignPublicationResponse_signPublicationResponse_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: SignPublicationResponse_signPublicationResponse_response_components_questions_AnswerRanking_rankings[];
}

export interface SignPublicationResponse_signPublicationResponse_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface SignPublicationResponse_signPublicationResponse_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface SignPublicationResponse_signPublicationResponse_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface SignPublicationResponse_signPublicationResponse_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type SignPublicationResponse_signPublicationResponse_response_components_questions = SignPublicationResponse_signPublicationResponse_response_components_questions_AnswerMultiChoice | SignPublicationResponse_signPublicationResponse_response_components_questions_AnswerCheckboxes | SignPublicationResponse_signPublicationResponse_response_components_questions_AnswerRanking | SignPublicationResponse_signPublicationResponse_response_components_questions_AnswerShortText | SignPublicationResponse_signPublicationResponse_response_components_questions_AnswerLongText | SignPublicationResponse_signPublicationResponse_response_components_questions_AnswerYesOrNo | SignPublicationResponse_signPublicationResponse_response_components_questions_AnswerDate;

export interface SignPublicationResponse_signPublicationResponse_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: SignPublicationResponse_signPublicationResponse_response_components_questions[];
}

export interface SignPublicationResponse_signPublicationResponse_response {
  __typename: "FormAnswer";
  components: SignPublicationResponse_signPublicationResponse_response_components[];
}

export interface SignPublicationResponse_signPublicationResponse_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface SignPublicationResponse_signPublicationResponse {
  __typename: "PublicationResponse";
  id: string;
  status: PublicationResponseStatus;
  signRole: PublicationResponseSignRole | null;
  signatures: SignPublicationResponse_signPublicationResponse_signatures[] | null;
  modified: any;
  language: ISO6391Language;
  response: SignPublicationResponse_signPublicationResponse_response | null;
  lockHolder: SignPublicationResponse_signPublicationResponse_lockHolder | null;
  lockExpire: any | null;
  validIfSigned: boolean;
}

export interface SignPublicationResponse {
  signPublicationResponse: SignPublicationResponse_signPublicationResponse;
}

export interface SignPublicationResponseVariables {
  id: string;
  response: string;
  context?: Context | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TryTakePublicationResponseLock
// ====================================================

export interface TryTakePublicationResponseLock_tryTakePublicationResponseLock_signatures_signer {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  idp: string | null;
  idpSub: string | null;
  name: string | null;
}

export interface TryTakePublicationResponseLock_tryTakePublicationResponseLock_signatures_educloudUser {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
}

export interface TryTakePublicationResponseLock_tryTakePublicationResponseLock_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  customerId: string | null;
  signer: TryTakePublicationResponseLock_tryTakePublicationResponseLock_signatures_signer | null;
  educloudUser: TryTakePublicationResponseLock_tryTakePublicationResponseLock_signatures_educloudUser | null;
  educloudUserId: string | null;
}

export interface TryTakePublicationResponseLock_tryTakePublicationResponseLock_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface TryTakePublicationResponseLock_tryTakePublicationResponseLock_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface TryTakePublicationResponseLock_tryTakePublicationResponseLock_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface TryTakePublicationResponseLock_tryTakePublicationResponseLock_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: TryTakePublicationResponseLock_tryTakePublicationResponseLock_response_components_questions_AnswerRanking_rankings[];
}

export interface TryTakePublicationResponseLock_tryTakePublicationResponseLock_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface TryTakePublicationResponseLock_tryTakePublicationResponseLock_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface TryTakePublicationResponseLock_tryTakePublicationResponseLock_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface TryTakePublicationResponseLock_tryTakePublicationResponseLock_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type TryTakePublicationResponseLock_tryTakePublicationResponseLock_response_components_questions = TryTakePublicationResponseLock_tryTakePublicationResponseLock_response_components_questions_AnswerMultiChoice | TryTakePublicationResponseLock_tryTakePublicationResponseLock_response_components_questions_AnswerCheckboxes | TryTakePublicationResponseLock_tryTakePublicationResponseLock_response_components_questions_AnswerRanking | TryTakePublicationResponseLock_tryTakePublicationResponseLock_response_components_questions_AnswerShortText | TryTakePublicationResponseLock_tryTakePublicationResponseLock_response_components_questions_AnswerLongText | TryTakePublicationResponseLock_tryTakePublicationResponseLock_response_components_questions_AnswerYesOrNo | TryTakePublicationResponseLock_tryTakePublicationResponseLock_response_components_questions_AnswerDate;

export interface TryTakePublicationResponseLock_tryTakePublicationResponseLock_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: TryTakePublicationResponseLock_tryTakePublicationResponseLock_response_components_questions[];
}

export interface TryTakePublicationResponseLock_tryTakePublicationResponseLock_response {
  __typename: "FormAnswer";
  components: TryTakePublicationResponseLock_tryTakePublicationResponseLock_response_components[];
}

export interface TryTakePublicationResponseLock_tryTakePublicationResponseLock_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface TryTakePublicationResponseLock_tryTakePublicationResponseLock {
  __typename: "PublicationResponse";
  id: string;
  status: PublicationResponseStatus;
  signRole: PublicationResponseSignRole | null;
  signatures: TryTakePublicationResponseLock_tryTakePublicationResponseLock_signatures[] | null;
  modified: any;
  language: ISO6391Language;
  response: TryTakePublicationResponseLock_tryTakePublicationResponseLock_response | null;
  lockHolder: TryTakePublicationResponseLock_tryTakePublicationResponseLock_lockHolder | null;
  lockExpire: any | null;
  validIfSigned: boolean;
}

export interface TryTakePublicationResponseLock {
  tryTakePublicationResponseLock: TryTakePublicationResponseLock_tryTakePublicationResponseLock | null;
}

export interface TryTakePublicationResponseLockVariables {
  id: string;
  context?: Context | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReleasePublicationResponseLock
// ====================================================

export interface ReleasePublicationResponseLock_releasePublicationResponseLock_signatures_signer {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  idp: string | null;
  idpSub: string | null;
  name: string | null;
}

export interface ReleasePublicationResponseLock_releasePublicationResponseLock_signatures_educloudUser {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
}

export interface ReleasePublicationResponseLock_releasePublicationResponseLock_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  customerId: string | null;
  signer: ReleasePublicationResponseLock_releasePublicationResponseLock_signatures_signer | null;
  educloudUser: ReleasePublicationResponseLock_releasePublicationResponseLock_signatures_educloudUser | null;
  educloudUserId: string | null;
}

export interface ReleasePublicationResponseLock_releasePublicationResponseLock_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface ReleasePublicationResponseLock_releasePublicationResponseLock_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface ReleasePublicationResponseLock_releasePublicationResponseLock_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface ReleasePublicationResponseLock_releasePublicationResponseLock_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: ReleasePublicationResponseLock_releasePublicationResponseLock_response_components_questions_AnswerRanking_rankings[];
}

export interface ReleasePublicationResponseLock_releasePublicationResponseLock_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface ReleasePublicationResponseLock_releasePublicationResponseLock_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface ReleasePublicationResponseLock_releasePublicationResponseLock_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface ReleasePublicationResponseLock_releasePublicationResponseLock_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type ReleasePublicationResponseLock_releasePublicationResponseLock_response_components_questions = ReleasePublicationResponseLock_releasePublicationResponseLock_response_components_questions_AnswerMultiChoice | ReleasePublicationResponseLock_releasePublicationResponseLock_response_components_questions_AnswerCheckboxes | ReleasePublicationResponseLock_releasePublicationResponseLock_response_components_questions_AnswerRanking | ReleasePublicationResponseLock_releasePublicationResponseLock_response_components_questions_AnswerShortText | ReleasePublicationResponseLock_releasePublicationResponseLock_response_components_questions_AnswerLongText | ReleasePublicationResponseLock_releasePublicationResponseLock_response_components_questions_AnswerYesOrNo | ReleasePublicationResponseLock_releasePublicationResponseLock_response_components_questions_AnswerDate;

export interface ReleasePublicationResponseLock_releasePublicationResponseLock_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: ReleasePublicationResponseLock_releasePublicationResponseLock_response_components_questions[];
}

export interface ReleasePublicationResponseLock_releasePublicationResponseLock_response {
  __typename: "FormAnswer";
  components: ReleasePublicationResponseLock_releasePublicationResponseLock_response_components[];
}

export interface ReleasePublicationResponseLock_releasePublicationResponseLock_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface ReleasePublicationResponseLock_releasePublicationResponseLock {
  __typename: "PublicationResponse";
  id: string;
  status: PublicationResponseStatus;
  signRole: PublicationResponseSignRole | null;
  signatures: ReleasePublicationResponseLock_releasePublicationResponseLock_signatures[] | null;
  modified: any;
  language: ISO6391Language;
  response: ReleasePublicationResponseLock_releasePublicationResponseLock_response | null;
  lockHolder: ReleasePublicationResponseLock_releasePublicationResponseLock_lockHolder | null;
  lockExpire: any | null;
  validIfSigned: boolean;
}

export interface ReleasePublicationResponseLock {
  releasePublicationResponseLock: ReleasePublicationResponseLock_releasePublicationResponseLock | null;
}

export interface ReleasePublicationResponseLockVariables {
  id: string;
  context?: Context | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: publicationRecipient
// ====================================================

export interface publicationRecipient_publicationRecipient_copyableResponses_form {
  __typename: "Form";
  id: string;
  name: string;
  language: ISO6391Language;
}

export interface publicationRecipient_publicationRecipient_copyableResponses_user {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface publicationRecipient_publicationRecipient_copyableResponses_signatures_signer {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  idp: string | null;
  idpSub: string | null;
  name: string | null;
}

export interface publicationRecipient_publicationRecipient_copyableResponses_signatures_educloudUser {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
}

export interface publicationRecipient_publicationRecipient_copyableResponses_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  customerId: string | null;
  signer: publicationRecipient_publicationRecipient_copyableResponses_signatures_signer | null;
  educloudUser: publicationRecipient_publicationRecipient_copyableResponses_signatures_educloudUser | null;
  educloudUserId: string | null;
}

export interface publicationRecipient_publicationRecipient_copyableResponses_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface publicationRecipient_publicationRecipient_copyableResponses_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface publicationRecipient_publicationRecipient_copyableResponses_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface publicationRecipient_publicationRecipient_copyableResponses_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: publicationRecipient_publicationRecipient_copyableResponses_response_components_questions_AnswerRanking_rankings[];
}

export interface publicationRecipient_publicationRecipient_copyableResponses_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface publicationRecipient_publicationRecipient_copyableResponses_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface publicationRecipient_publicationRecipient_copyableResponses_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface publicationRecipient_publicationRecipient_copyableResponses_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type publicationRecipient_publicationRecipient_copyableResponses_response_components_questions = publicationRecipient_publicationRecipient_copyableResponses_response_components_questions_AnswerMultiChoice | publicationRecipient_publicationRecipient_copyableResponses_response_components_questions_AnswerCheckboxes | publicationRecipient_publicationRecipient_copyableResponses_response_components_questions_AnswerRanking | publicationRecipient_publicationRecipient_copyableResponses_response_components_questions_AnswerShortText | publicationRecipient_publicationRecipient_copyableResponses_response_components_questions_AnswerLongText | publicationRecipient_publicationRecipient_copyableResponses_response_components_questions_AnswerYesOrNo | publicationRecipient_publicationRecipient_copyableResponses_response_components_questions_AnswerDate;

export interface publicationRecipient_publicationRecipient_copyableResponses_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: publicationRecipient_publicationRecipient_copyableResponses_response_components_questions[];
}

export interface publicationRecipient_publicationRecipient_copyableResponses_response {
  __typename: "FormAnswer";
  components: publicationRecipient_publicationRecipient_copyableResponses_response_components[];
}

export interface publicationRecipient_publicationRecipient_copyableResponses_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface publicationRecipient_publicationRecipient_copyableResponses {
  __typename: "PublicationResponse";
  form: publicationRecipient_publicationRecipient_copyableResponses_form;
  user: publicationRecipient_publicationRecipient_copyableResponses_user | null;
  id: string;
  status: PublicationResponseStatus;
  signRole: PublicationResponseSignRole | null;
  signatures: publicationRecipient_publicationRecipient_copyableResponses_signatures[] | null;
  modified: any;
  language: ISO6391Language;
  response: publicationRecipient_publicationRecipient_copyableResponses_response | null;
  lockHolder: publicationRecipient_publicationRecipient_copyableResponses_lockHolder | null;
  lockExpire: any | null;
  validIfSigned: boolean;
}

export interface publicationRecipient_publicationRecipient_responses_signatures_signer {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  idp: string | null;
  idpSub: string | null;
  name: string | null;
}

export interface publicationRecipient_publicationRecipient_responses_signatures_educloudUser {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
}

export interface publicationRecipient_publicationRecipient_responses_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  customerId: string | null;
  signer: publicationRecipient_publicationRecipient_responses_signatures_signer | null;
  educloudUser: publicationRecipient_publicationRecipient_responses_signatures_educloudUser | null;
  educloudUserId: string | null;
}

export interface publicationRecipient_publicationRecipient_responses_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface publicationRecipient_publicationRecipient_responses_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface publicationRecipient_publicationRecipient_responses_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface publicationRecipient_publicationRecipient_responses_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: publicationRecipient_publicationRecipient_responses_response_components_questions_AnswerRanking_rankings[];
}

export interface publicationRecipient_publicationRecipient_responses_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface publicationRecipient_publicationRecipient_responses_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface publicationRecipient_publicationRecipient_responses_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface publicationRecipient_publicationRecipient_responses_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type publicationRecipient_publicationRecipient_responses_response_components_questions = publicationRecipient_publicationRecipient_responses_response_components_questions_AnswerMultiChoice | publicationRecipient_publicationRecipient_responses_response_components_questions_AnswerCheckboxes | publicationRecipient_publicationRecipient_responses_response_components_questions_AnswerRanking | publicationRecipient_publicationRecipient_responses_response_components_questions_AnswerShortText | publicationRecipient_publicationRecipient_responses_response_components_questions_AnswerLongText | publicationRecipient_publicationRecipient_responses_response_components_questions_AnswerYesOrNo | publicationRecipient_publicationRecipient_responses_response_components_questions_AnswerDate;

export interface publicationRecipient_publicationRecipient_responses_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: publicationRecipient_publicationRecipient_responses_response_components_questions[];
}

export interface publicationRecipient_publicationRecipient_responses_response {
  __typename: "FormAnswer";
  components: publicationRecipient_publicationRecipient_responses_response_components[];
}

export interface publicationRecipient_publicationRecipient_responses_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface publicationRecipient_publicationRecipient_responses {
  __typename: "PublicationResponse";
  id: string;
  status: PublicationResponseStatus;
  signRole: PublicationResponseSignRole | null;
  signatures: publicationRecipient_publicationRecipient_responses_signatures[] | null;
  modified: any;
  language: ISO6391Language;
  response: publicationRecipient_publicationRecipient_responses_response | null;
  lockHolder: publicationRecipient_publicationRecipient_responses_lockHolder | null;
  lockExpire: any | null;
  validIfSigned: boolean;
}

export interface publicationRecipient_publicationRecipient_user {
  __typename: "User";
  id: string;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface publicationRecipient_publicationRecipient_publication_form_translations_componentData_predicateComponents_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface publicationRecipient_publicationRecipient_publication_form_translations_componentData_predicateComponents_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: publicationRecipient_publicationRecipient_publication_form_translations_componentData_predicateComponents_questions_options[] | null;
}

export interface publicationRecipient_publicationRecipient_publication_form_translations_componentData_predicateComponents {
  __typename: "TranslatePredicateComponent";
  id: string;
  language: ISO6391Language | null;
  questions: publicationRecipient_publicationRecipient_publication_form_translations_componentData_predicateComponents_questions[];
}

export interface publicationRecipient_publicationRecipient_publication_form_translations_componentData_components_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface publicationRecipient_publicationRecipient_publication_form_translations_componentData_components_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: publicationRecipient_publicationRecipient_publication_form_translations_componentData_components_questions_options[] | null;
}

export interface publicationRecipient_publicationRecipient_publication_form_translations_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface publicationRecipient_publicationRecipient_publication_form_translations_componentData_components {
  __typename: "TranslateComponent";
  id: string;
  language: ISO6391Language | null;
  title: string;
  description: string;
  questions: publicationRecipient_publicationRecipient_publication_form_translations_componentData_components_questions[];
  attachments: publicationRecipient_publicationRecipient_publication_form_translations_componentData_components_attachments[];
}

export interface publicationRecipient_publicationRecipient_publication_form_translations_componentData {
  __typename: "TranslationComponentData";
  predicateComponents: publicationRecipient_publicationRecipient_publication_form_translations_componentData_predicateComponents[] | null;
  components: publicationRecipient_publicationRecipient_publication_form_translations_componentData_components[];
}

export interface publicationRecipient_publicationRecipient_publication_form_translations {
  __typename: "TranslationForm";
  formId: string;
  name: string;
  description: string;
  language: ISO6391Language;
  componentData: publicationRecipient_publicationRecipient_publication_form_translations_componentData;
}

export interface publicationRecipient_publicationRecipient_publication_form_owner {
  __typename: "Organisation";
  id: string;
  displayName: string;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type publicationRecipient_publicationRecipient_publication_form_componentData_components_questions = publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionYesNo | publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionRanking | publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionShortText | publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionCheckboxes | publicationRecipient_publicationRecipient_publication_form_componentData_components_questions_FormQuestionMultiChoice;

export interface publicationRecipient_publicationRecipient_publication_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: publicationRecipient_publicationRecipient_publication_form_componentData_components_questions[];
  templateId: string | null;
  attachments: publicationRecipient_publicationRecipient_publication_form_componentData_components_attachments[] | null;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions = publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionYesNo | publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionRanking | publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionShortText | publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents_questions[];
}

export interface publicationRecipient_publicationRecipient_publication_form_componentData {
  __typename: "ComponentData";
  components: publicationRecipient_publicationRecipient_publication_form_componentData_components[];
  predicateComponents: publicationRecipient_publicationRecipient_publication_form_componentData_predicateComponents[] | null;
}

export interface publicationRecipient_publicationRecipient_publication_form {
  __typename: "Form";
  id: string;
  name: string;
  description: string | null;
  language: ISO6391Language;
  translationLanguages: ISO6391Language[];
  translations: publicationRecipient_publicationRecipient_publication_form_translations[];
  owner: publicationRecipient_publicationRecipient_publication_form_owner;
  componentData: publicationRecipient_publicationRecipient_publication_form_componentData;
}

export interface publicationRecipient_publicationRecipient_publication {
  __typename: "Publication";
  id: string;
  form: publicationRecipient_publicationRecipient_publication_form;
}

export interface publicationRecipient_publicationRecipient {
  __typename: "PublicationRecipient";
  id: string;
  copyableResponses: publicationRecipient_publicationRecipient_copyableResponses[];
  responses: publicationRecipient_publicationRecipient_responses[];
  user: publicationRecipient_publicationRecipient_user | null;
  publication: publicationRecipient_publicationRecipient_publication;
}

export interface publicationRecipient {
  publicationRecipient: publicationRecipient_publicationRecipient;
}

export interface publicationRecipientVariables {
  id: string;
  context?: Context | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminPublicationRecipientDraft
// ====================================================

export interface adminPublicationRecipientDraft_publicationRecipient_responses_signatures_signer {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  idp: string | null;
  idpSub: string | null;
  name: string | null;
}

export interface adminPublicationRecipientDraft_publicationRecipient_responses_signatures_educloudUser {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
}

export interface adminPublicationRecipientDraft_publicationRecipient_responses_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  customerId: string | null;
  signer: adminPublicationRecipientDraft_publicationRecipient_responses_signatures_signer | null;
  educloudUser: adminPublicationRecipientDraft_publicationRecipient_responses_signatures_educloudUser | null;
  educloudUserId: string | null;
}

export interface adminPublicationRecipientDraft_publicationRecipient_responses_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface adminPublicationRecipientDraft_publicationRecipient_responses_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface adminPublicationRecipientDraft_publicationRecipient_responses_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface adminPublicationRecipientDraft_publicationRecipient_responses_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: adminPublicationRecipientDraft_publicationRecipient_responses_response_components_questions_AnswerRanking_rankings[];
}

export interface adminPublicationRecipientDraft_publicationRecipient_responses_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface adminPublicationRecipientDraft_publicationRecipient_responses_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface adminPublicationRecipientDraft_publicationRecipient_responses_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface adminPublicationRecipientDraft_publicationRecipient_responses_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type adminPublicationRecipientDraft_publicationRecipient_responses_response_components_questions = adminPublicationRecipientDraft_publicationRecipient_responses_response_components_questions_AnswerMultiChoice | adminPublicationRecipientDraft_publicationRecipient_responses_response_components_questions_AnswerCheckboxes | adminPublicationRecipientDraft_publicationRecipient_responses_response_components_questions_AnswerRanking | adminPublicationRecipientDraft_publicationRecipient_responses_response_components_questions_AnswerShortText | adminPublicationRecipientDraft_publicationRecipient_responses_response_components_questions_AnswerLongText | adminPublicationRecipientDraft_publicationRecipient_responses_response_components_questions_AnswerYesOrNo | adminPublicationRecipientDraft_publicationRecipient_responses_response_components_questions_AnswerDate;

export interface adminPublicationRecipientDraft_publicationRecipient_responses_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: adminPublicationRecipientDraft_publicationRecipient_responses_response_components_questions[];
}

export interface adminPublicationRecipientDraft_publicationRecipient_responses_response {
  __typename: "FormAnswer";
  components: adminPublicationRecipientDraft_publicationRecipient_responses_response_components[];
}

export interface adminPublicationRecipientDraft_publicationRecipient_responses_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface adminPublicationRecipientDraft_publicationRecipient_responses {
  __typename: "PublicationResponse";
  id: string;
  status: PublicationResponseStatus;
  signRole: PublicationResponseSignRole | null;
  signatures: adminPublicationRecipientDraft_publicationRecipient_responses_signatures[] | null;
  modified: any;
  language: ISO6391Language;
  response: adminPublicationRecipientDraft_publicationRecipient_responses_response | null;
  lockHolder: adminPublicationRecipientDraft_publicationRecipient_responses_lockHolder | null;
  lockExpire: any | null;
  validIfSigned: boolean;
}

export interface adminPublicationRecipientDraft_publicationRecipient_user {
  __typename: "User";
  id: string;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_translations_componentData_predicateComponents_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_translations_componentData_predicateComponents_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: adminPublicationRecipientDraft_publicationRecipient_publication_form_translations_componentData_predicateComponents_questions_options[] | null;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_translations_componentData_predicateComponents {
  __typename: "TranslatePredicateComponent";
  id: string;
  language: ISO6391Language | null;
  questions: adminPublicationRecipientDraft_publicationRecipient_publication_form_translations_componentData_predicateComponents_questions[];
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_translations_componentData_components_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_translations_componentData_components_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: adminPublicationRecipientDraft_publicationRecipient_publication_form_translations_componentData_components_questions_options[] | null;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_translations_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_translations_componentData_components {
  __typename: "TranslateComponent";
  id: string;
  language: ISO6391Language | null;
  title: string;
  description: string;
  questions: adminPublicationRecipientDraft_publicationRecipient_publication_form_translations_componentData_components_questions[];
  attachments: adminPublicationRecipientDraft_publicationRecipient_publication_form_translations_componentData_components_attachments[];
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_translations_componentData {
  __typename: "TranslationComponentData";
  predicateComponents: adminPublicationRecipientDraft_publicationRecipient_publication_form_translations_componentData_predicateComponents[] | null;
  components: adminPublicationRecipientDraft_publicationRecipient_publication_form_translations_componentData_components[];
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_translations {
  __typename: "TranslationForm";
  formId: string;
  name: string;
  description: string;
  language: ISO6391Language;
  componentData: adminPublicationRecipientDraft_publicationRecipient_publication_form_translations_componentData;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_owner {
  __typename: "Organisation";
  id: string;
  displayName: string;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions = adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionYesNo | adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionRanking | adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionShortText | adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionCheckboxes | adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions_FormQuestionMultiChoice;

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_questions[];
  templateId: string | null;
  attachments: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components_attachments[] | null;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions = adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionYesNo | adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionRanking | adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionShortText | adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents_questions[];
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData {
  __typename: "ComponentData";
  components: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_components[];
  predicateComponents: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData_predicateComponents[] | null;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication_form {
  __typename: "Form";
  id: string;
  name: string;
  description: string | null;
  language: ISO6391Language;
  translationLanguages: ISO6391Language[];
  translations: adminPublicationRecipientDraft_publicationRecipient_publication_form_translations[];
  owner: adminPublicationRecipientDraft_publicationRecipient_publication_form_owner;
  componentData: adminPublicationRecipientDraft_publicationRecipient_publication_form_componentData;
}

export interface adminPublicationRecipientDraft_publicationRecipient_publication {
  __typename: "Publication";
  id: string;
  form: adminPublicationRecipientDraft_publicationRecipient_publication_form;
}

export interface adminPublicationRecipientDraft_publicationRecipient {
  __typename: "PublicationRecipient";
  id: string;
  responses: adminPublicationRecipientDraft_publicationRecipient_responses[];
  user: adminPublicationRecipientDraft_publicationRecipient_user | null;
  publication: adminPublicationRecipientDraft_publicationRecipient_publication;
}

export interface adminPublicationRecipientDraft {
  publicationRecipient: adminPublicationRecipientDraft_publicationRecipient;
}

export interface adminPublicationRecipientDraftVariables {
  id: string;
  context?: Context | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminPublicationRecipient
// ====================================================

export interface adminPublicationRecipient_publicationRecipient_responses_signatures_signer {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  idp: string | null;
  idpSub: string | null;
  name: string | null;
}

export interface adminPublicationRecipient_publicationRecipient_responses_signatures_educloudUser {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
}

export interface adminPublicationRecipient_publicationRecipient_responses_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  customerId: string | null;
  signer: adminPublicationRecipient_publicationRecipient_responses_signatures_signer | null;
  educloudUser: adminPublicationRecipient_publicationRecipient_responses_signatures_educloudUser | null;
  educloudUserId: string | null;
}

export interface adminPublicationRecipient_publicationRecipient_responses_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface adminPublicationRecipient_publicationRecipient_responses_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface adminPublicationRecipient_publicationRecipient_responses_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface adminPublicationRecipient_publicationRecipient_responses_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: adminPublicationRecipient_publicationRecipient_responses_response_components_questions_AnswerRanking_rankings[];
}

export interface adminPublicationRecipient_publicationRecipient_responses_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface adminPublicationRecipient_publicationRecipient_responses_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface adminPublicationRecipient_publicationRecipient_responses_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface adminPublicationRecipient_publicationRecipient_responses_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type adminPublicationRecipient_publicationRecipient_responses_response_components_questions = adminPublicationRecipient_publicationRecipient_responses_response_components_questions_AnswerMultiChoice | adminPublicationRecipient_publicationRecipient_responses_response_components_questions_AnswerCheckboxes | adminPublicationRecipient_publicationRecipient_responses_response_components_questions_AnswerRanking | adminPublicationRecipient_publicationRecipient_responses_response_components_questions_AnswerShortText | adminPublicationRecipient_publicationRecipient_responses_response_components_questions_AnswerLongText | adminPublicationRecipient_publicationRecipient_responses_response_components_questions_AnswerYesOrNo | adminPublicationRecipient_publicationRecipient_responses_response_components_questions_AnswerDate;

export interface adminPublicationRecipient_publicationRecipient_responses_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: adminPublicationRecipient_publicationRecipient_responses_response_components_questions[];
}

export interface adminPublicationRecipient_publicationRecipient_responses_response {
  __typename: "FormAnswer";
  components: adminPublicationRecipient_publicationRecipient_responses_response_components[];
}

export interface adminPublicationRecipient_publicationRecipient_responses_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface adminPublicationRecipient_publicationRecipient_responses {
  __typename: "PublicationResponse";
  id: string;
  status: PublicationResponseStatus;
  signRole: PublicationResponseSignRole | null;
  signatures: adminPublicationRecipient_publicationRecipient_responses_signatures[] | null;
  modified: any;
  language: ISO6391Language;
  response: adminPublicationRecipient_publicationRecipient_responses_response | null;
  lockHolder: adminPublicationRecipient_publicationRecipient_responses_lockHolder | null;
  lockExpire: any | null;
  validIfSigned: boolean;
}

export interface adminPublicationRecipient_publicationRecipient {
  __typename: "PublicationRecipient";
  id: string;
  modifyPermission: boolean;
  responses: adminPublicationRecipient_publicationRecipient_responses[];
}

export interface adminPublicationRecipient {
  publicationRecipient: adminPublicationRecipient_publicationRecipient;
}

export interface adminPublicationRecipientVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: publicationResponse
// ====================================================

export interface publicationResponse_publicationResponse_signatures_signer {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  idp: string | null;
  idpSub: string | null;
  name: string | null;
}

export interface publicationResponse_publicationResponse_signatures_educloudUser {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
}

export interface publicationResponse_publicationResponse_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  customerId: string | null;
  signer: publicationResponse_publicationResponse_signatures_signer | null;
  educloudUser: publicationResponse_publicationResponse_signatures_educloudUser | null;
  educloudUserId: string | null;
}

export interface publicationResponse_publicationResponse_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface publicationResponse_publicationResponse_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface publicationResponse_publicationResponse_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface publicationResponse_publicationResponse_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: publicationResponse_publicationResponse_response_components_questions_AnswerRanking_rankings[];
}

export interface publicationResponse_publicationResponse_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface publicationResponse_publicationResponse_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface publicationResponse_publicationResponse_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface publicationResponse_publicationResponse_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type publicationResponse_publicationResponse_response_components_questions = publicationResponse_publicationResponse_response_components_questions_AnswerMultiChoice | publicationResponse_publicationResponse_response_components_questions_AnswerCheckboxes | publicationResponse_publicationResponse_response_components_questions_AnswerRanking | publicationResponse_publicationResponse_response_components_questions_AnswerShortText | publicationResponse_publicationResponse_response_components_questions_AnswerLongText | publicationResponse_publicationResponse_response_components_questions_AnswerYesOrNo | publicationResponse_publicationResponse_response_components_questions_AnswerDate;

export interface publicationResponse_publicationResponse_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: publicationResponse_publicationResponse_response_components_questions[];
}

export interface publicationResponse_publicationResponse_response {
  __typename: "FormAnswer";
  components: publicationResponse_publicationResponse_response_components[];
}

export interface publicationResponse_publicationResponse_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface publicationResponse_publicationResponse_publicationRecipient_responses {
  __typename: "PublicationResponse";
  id: string;
  modified: any;
}

export interface publicationResponse_publicationResponse_publicationRecipient {
  __typename: "PublicationRecipient";
  id: string;
  responses: publicationResponse_publicationResponse_publicationRecipient_responses[];
}

export interface publicationResponse_publicationResponse {
  __typename: "PublicationResponse";
  modifyPermission: boolean;
  id: string;
  status: PublicationResponseStatus;
  signRole: PublicationResponseSignRole | null;
  signatures: publicationResponse_publicationResponse_signatures[] | null;
  modified: any;
  language: ISO6391Language;
  response: publicationResponse_publicationResponse_response | null;
  lockHolder: publicationResponse_publicationResponse_lockHolder | null;
  lockExpire: any | null;
  validIfSigned: boolean;
  publicationRecipient: publicationResponse_publicationResponse_publicationRecipient;
}

export interface publicationResponse {
  publicationResponse: publicationResponse_publicationResponse | null;
}

export interface publicationResponseVariables {
  responseId: string;
  context?: Context | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: organisationSchoolunits
// ====================================================

export interface organisationSchoolunits_organisation_schoolUnits_classes {
  __typename: "StudentGroup";
  id: string;
}

export interface organisationSchoolunits_organisation_schoolUnits {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
  organisationType: SchoolUnitOrganisationType | null;
  classes: organisationSchoolunits_organisation_schoolUnits_classes[];
}

export interface organisationSchoolunits_organisation {
  __typename: "Organisation";
  id: string;
  displayName: string;
  schoolUnits: organisationSchoolunits_organisation_schoolUnits[];
}

export interface organisationSchoolunits {
  organisation: organisationSchoolunits_organisation;
}

export interface organisationSchoolunitsVariables {
  suFilter?: SchoolUnitsFilter | null;
  context: Context;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuardianApplication
// ====================================================

export interface GuardianApplication_application_form_translations_componentData_predicateComponents_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface GuardianApplication_application_form_translations_componentData_predicateComponents_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: GuardianApplication_application_form_translations_componentData_predicateComponents_questions_options[] | null;
}

export interface GuardianApplication_application_form_translations_componentData_predicateComponents {
  __typename: "TranslatePredicateComponent";
  id: string;
  language: ISO6391Language | null;
  questions: GuardianApplication_application_form_translations_componentData_predicateComponents_questions[];
}

export interface GuardianApplication_application_form_translations_componentData_components_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface GuardianApplication_application_form_translations_componentData_components_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: GuardianApplication_application_form_translations_componentData_components_questions_options[] | null;
}

export interface GuardianApplication_application_form_translations_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface GuardianApplication_application_form_translations_componentData_components {
  __typename: "TranslateComponent";
  id: string;
  language: ISO6391Language | null;
  title: string;
  description: string;
  questions: GuardianApplication_application_form_translations_componentData_components_questions[];
  attachments: GuardianApplication_application_form_translations_componentData_components_attachments[];
}

export interface GuardianApplication_application_form_translations_componentData {
  __typename: "TranslationComponentData";
  predicateComponents: GuardianApplication_application_form_translations_componentData_predicateComponents[] | null;
  components: GuardianApplication_application_form_translations_componentData_components[];
}

export interface GuardianApplication_application_form_translations {
  __typename: "TranslationForm";
  formId: string;
  name: string;
  description: string;
  language: ISO6391Language;
  componentData: GuardianApplication_application_form_translations_componentData;
}

export interface GuardianApplication_application_form_owner {
  __typename: "Organisation";
  id: string;
  displayName: string;
}

export interface GuardianApplication_application_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface GuardianApplication_application_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: GuardianApplication_application_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface GuardianApplication_application_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface GuardianApplication_application_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface GuardianApplication_application_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: GuardianApplication_application_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: GuardianApplication_application_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface GuardianApplication_application_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface GuardianApplication_application_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: GuardianApplication_application_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface GuardianApplication_application_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface GuardianApplication_application_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface GuardianApplication_application_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: GuardianApplication_application_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: GuardianApplication_application_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface GuardianApplication_application_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface GuardianApplication_application_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface GuardianApplication_application_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: GuardianApplication_application_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: GuardianApplication_application_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type GuardianApplication_application_form_componentData_components_questions = GuardianApplication_application_form_componentData_components_questions_FormQuestionYesNo | GuardianApplication_application_form_componentData_components_questions_FormQuestionRanking | GuardianApplication_application_form_componentData_components_questions_FormQuestionShortText | GuardianApplication_application_form_componentData_components_questions_FormQuestionCheckboxes | GuardianApplication_application_form_componentData_components_questions_FormQuestionMultiChoice;

export interface GuardianApplication_application_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface GuardianApplication_application_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: GuardianApplication_application_form_componentData_components_questions[];
  templateId: string | null;
  attachments: GuardianApplication_application_form_componentData_components_attachments[] | null;
}

export interface GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type GuardianApplication_application_form_componentData_predicateComponents_questions = GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionYesNo | GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionRanking | GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionShortText | GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | GuardianApplication_application_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface GuardianApplication_application_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: GuardianApplication_application_form_componentData_predicateComponents_questions[];
}

export interface GuardianApplication_application_form_componentData {
  __typename: "ComponentData";
  components: GuardianApplication_application_form_componentData_components[];
  predicateComponents: GuardianApplication_application_form_componentData_predicateComponents[] | null;
}

export interface GuardianApplication_application_form {
  __typename: "Form";
  id: string;
  name: string;
  description: string | null;
  language: ISO6391Language;
  translationLanguages: ISO6391Language[];
  translations: GuardianApplication_application_form_translations[];
  owner: GuardianApplication_application_form_owner;
  componentData: GuardianApplication_application_form_componentData;
}

export interface GuardianApplication_application {
  __typename: "Application";
  id: string;
  singleGuardianSend: boolean;
  singleGuardianAcceptOffer: boolean;
  schoolunitSpecific: boolean;
  closed: boolean;
  form: GuardianApplication_application_form;
}

export interface GuardianApplication {
  application: GuardianApplication_application;
}

export interface GuardianApplicationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuardianApplicationResponse
// ====================================================

export interface GuardianApplicationResponse_applicationResponse_lastAction {
  __typename: "ApplicationResponseEvent";
  id: string;
  action: ApplicationResponseAction;
  newStatus: ApplicationResponseStatus;
  comment: string | null;
  errorCode: string | null;
  created: any;
}

export interface GuardianApplicationResponse_applicationResponse_actions {
  __typename: "ApplicationResponseEvent";
  id: string;
  action: ApplicationResponseAction;
  newStatus: ApplicationResponseStatus;
  comment: string | null;
  errorCode: string | null;
  created: any;
}

export interface GuardianApplicationResponse_applicationResponse_signatures {
  __typename: "ApplicationResponseSignature";
  signerId: string;
  signedAs: string;
  customerId: string | null;
}

export interface GuardianApplicationResponse_applicationResponse_feedbackSignatures {
  __typename: "ApplicationResponseFeedbackSignature";
  signerId: string;
  signedAs: string;
  customerId: string | null;
}

export interface GuardianApplicationResponse_applicationResponse_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface GuardianApplicationResponse_applicationResponse_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface GuardianApplicationResponse_applicationResponse_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface GuardianApplicationResponse_applicationResponse_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: GuardianApplicationResponse_applicationResponse_response_components_questions_AnswerRanking_rankings[];
}

export interface GuardianApplicationResponse_applicationResponse_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface GuardianApplicationResponse_applicationResponse_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface GuardianApplicationResponse_applicationResponse_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface GuardianApplicationResponse_applicationResponse_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type GuardianApplicationResponse_applicationResponse_response_components_questions = GuardianApplicationResponse_applicationResponse_response_components_questions_AnswerMultiChoice | GuardianApplicationResponse_applicationResponse_response_components_questions_AnswerCheckboxes | GuardianApplicationResponse_applicationResponse_response_components_questions_AnswerRanking | GuardianApplicationResponse_applicationResponse_response_components_questions_AnswerShortText | GuardianApplicationResponse_applicationResponse_response_components_questions_AnswerLongText | GuardianApplicationResponse_applicationResponse_response_components_questions_AnswerYesOrNo | GuardianApplicationResponse_applicationResponse_response_components_questions_AnswerDate;

export interface GuardianApplicationResponse_applicationResponse_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: GuardianApplicationResponse_applicationResponse_response_components_questions[];
}

export interface GuardianApplicationResponse_applicationResponse_response {
  __typename: "FormAnswer";
  components: GuardianApplicationResponse_applicationResponse_response_components[];
}

export interface GuardianApplicationResponse_applicationResponse_creator_verifiedRelation {
  __typename: "VerifiedRelation";
  isVerifiedRelation: boolean;
  customerId: string | null;
}

export interface GuardianApplicationResponse_applicationResponse_creator {
  __typename: "ApplicationCreator";
  id: string;
  name: string;
  source: UserSource;
  verifiedRelation: GuardianApplicationResponse_applicationResponse_creator_verifiedRelation;
  orgId: string;
}

export interface GuardianApplicationResponse_applicationResponse_subject {
  __typename: "SubjectCoSigner";
  userId: string | null;
  customerId: string | null;
  name: string;
}

export interface GuardianApplicationResponse_applicationResponse_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface GuardianApplicationResponse_applicationResponse_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface GuardianApplicationResponse_applicationResponse {
  __typename: "ApplicationResponse";
  id: string;
  status: ApplicationResponseStatus;
  modified: any;
  seenByOrg: any | null;
  language: ISO6391Language;
  lastAction: GuardianApplicationResponse_applicationResponse_lastAction;
  actions: GuardianApplicationResponse_applicationResponse_actions[];
  signatures: GuardianApplicationResponse_applicationResponse_signatures[];
  feedbackSignatures: GuardianApplicationResponse_applicationResponse_feedbackSignatures[];
  response: GuardianApplicationResponse_applicationResponse_response | null;
  creator: GuardianApplicationResponse_applicationResponse_creator;
  subject: GuardianApplicationResponse_applicationResponse_subject;
  lockHolder: GuardianApplicationResponse_applicationResponse_lockHolder | null;
  feedbackId: string | null;
  feedbackDescription: string | null;
  lockExpire: any | null;
  schoolUnit: GuardianApplicationResponse_applicationResponse_schoolUnit | null;
}

export interface GuardianApplicationResponse {
  applicationResponse: GuardianApplicationResponse_applicationResponse;
}

export interface GuardianApplicationResponseVariables {
  responseId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GuardianCreateApplicationResponse
// ====================================================

export interface GuardianCreateApplicationResponse_createApplicationResponse_lastAction {
  __typename: "ApplicationResponseEvent";
  id: string;
  action: ApplicationResponseAction;
  newStatus: ApplicationResponseStatus;
  comment: string | null;
  errorCode: string | null;
  created: any;
}

export interface GuardianCreateApplicationResponse_createApplicationResponse_actions {
  __typename: "ApplicationResponseEvent";
  id: string;
  action: ApplicationResponseAction;
  newStatus: ApplicationResponseStatus;
  comment: string | null;
  errorCode: string | null;
  created: any;
}

export interface GuardianCreateApplicationResponse_createApplicationResponse_signatures {
  __typename: "ApplicationResponseSignature";
  signerId: string;
  signedAs: string;
  customerId: string | null;
}

export interface GuardianCreateApplicationResponse_createApplicationResponse_feedbackSignatures {
  __typename: "ApplicationResponseFeedbackSignature";
  signerId: string;
  signedAs: string;
  customerId: string | null;
}

export interface GuardianCreateApplicationResponse_createApplicationResponse_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface GuardianCreateApplicationResponse_createApplicationResponse_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface GuardianCreateApplicationResponse_createApplicationResponse_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface GuardianCreateApplicationResponse_createApplicationResponse_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: GuardianCreateApplicationResponse_createApplicationResponse_response_components_questions_AnswerRanking_rankings[];
}

export interface GuardianCreateApplicationResponse_createApplicationResponse_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface GuardianCreateApplicationResponse_createApplicationResponse_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface GuardianCreateApplicationResponse_createApplicationResponse_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface GuardianCreateApplicationResponse_createApplicationResponse_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type GuardianCreateApplicationResponse_createApplicationResponse_response_components_questions = GuardianCreateApplicationResponse_createApplicationResponse_response_components_questions_AnswerMultiChoice | GuardianCreateApplicationResponse_createApplicationResponse_response_components_questions_AnswerCheckboxes | GuardianCreateApplicationResponse_createApplicationResponse_response_components_questions_AnswerRanking | GuardianCreateApplicationResponse_createApplicationResponse_response_components_questions_AnswerShortText | GuardianCreateApplicationResponse_createApplicationResponse_response_components_questions_AnswerLongText | GuardianCreateApplicationResponse_createApplicationResponse_response_components_questions_AnswerYesOrNo | GuardianCreateApplicationResponse_createApplicationResponse_response_components_questions_AnswerDate;

export interface GuardianCreateApplicationResponse_createApplicationResponse_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: GuardianCreateApplicationResponse_createApplicationResponse_response_components_questions[];
}

export interface GuardianCreateApplicationResponse_createApplicationResponse_response {
  __typename: "FormAnswer";
  components: GuardianCreateApplicationResponse_createApplicationResponse_response_components[];
}

export interface GuardianCreateApplicationResponse_createApplicationResponse_creator_verifiedRelation {
  __typename: "VerifiedRelation";
  isVerifiedRelation: boolean;
  customerId: string | null;
}

export interface GuardianCreateApplicationResponse_createApplicationResponse_creator {
  __typename: "ApplicationCreator";
  id: string;
  name: string;
  source: UserSource;
  verifiedRelation: GuardianCreateApplicationResponse_createApplicationResponse_creator_verifiedRelation;
  orgId: string;
}

export interface GuardianCreateApplicationResponse_createApplicationResponse_subject {
  __typename: "SubjectCoSigner";
  userId: string | null;
  customerId: string | null;
  name: string;
}

export interface GuardianCreateApplicationResponse_createApplicationResponse_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface GuardianCreateApplicationResponse_createApplicationResponse_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface GuardianCreateApplicationResponse_createApplicationResponse {
  __typename: "ApplicationResponse";
  id: string;
  status: ApplicationResponseStatus;
  modified: any;
  seenByOrg: any | null;
  language: ISO6391Language;
  lastAction: GuardianCreateApplicationResponse_createApplicationResponse_lastAction;
  actions: GuardianCreateApplicationResponse_createApplicationResponse_actions[];
  signatures: GuardianCreateApplicationResponse_createApplicationResponse_signatures[];
  feedbackSignatures: GuardianCreateApplicationResponse_createApplicationResponse_feedbackSignatures[];
  response: GuardianCreateApplicationResponse_createApplicationResponse_response | null;
  creator: GuardianCreateApplicationResponse_createApplicationResponse_creator;
  subject: GuardianCreateApplicationResponse_createApplicationResponse_subject;
  lockHolder: GuardianCreateApplicationResponse_createApplicationResponse_lockHolder | null;
  feedbackId: string | null;
  feedbackDescription: string | null;
  lockExpire: any | null;
  schoolUnit: GuardianCreateApplicationResponse_createApplicationResponse_schoolUnit | null;
}

export interface GuardianCreateApplicationResponse {
  createApplicationResponse: GuardianCreateApplicationResponse_createApplicationResponse;
}

export interface GuardianCreateApplicationResponseVariables {
  applicationId: string;
  response: FormAnswerInput;
  language: ISO6391Language;
  schoolUnit?: IdCustomerIdInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GuardianSaveApplicationResponse
// ====================================================

export interface GuardianSaveApplicationResponse_saveApplicationResponse_lastAction {
  __typename: "ApplicationResponseEvent";
  id: string;
  action: ApplicationResponseAction;
  newStatus: ApplicationResponseStatus;
  comment: string | null;
  errorCode: string | null;
  created: any;
}

export interface GuardianSaveApplicationResponse_saveApplicationResponse_actions {
  __typename: "ApplicationResponseEvent";
  id: string;
  action: ApplicationResponseAction;
  newStatus: ApplicationResponseStatus;
  comment: string | null;
  errorCode: string | null;
  created: any;
}

export interface GuardianSaveApplicationResponse_saveApplicationResponse_signatures {
  __typename: "ApplicationResponseSignature";
  signerId: string;
  signedAs: string;
  customerId: string | null;
}

export interface GuardianSaveApplicationResponse_saveApplicationResponse_feedbackSignatures {
  __typename: "ApplicationResponseFeedbackSignature";
  signerId: string;
  signedAs: string;
  customerId: string | null;
}

export interface GuardianSaveApplicationResponse_saveApplicationResponse_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface GuardianSaveApplicationResponse_saveApplicationResponse_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface GuardianSaveApplicationResponse_saveApplicationResponse_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface GuardianSaveApplicationResponse_saveApplicationResponse_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: GuardianSaveApplicationResponse_saveApplicationResponse_response_components_questions_AnswerRanking_rankings[];
}

export interface GuardianSaveApplicationResponse_saveApplicationResponse_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface GuardianSaveApplicationResponse_saveApplicationResponse_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface GuardianSaveApplicationResponse_saveApplicationResponse_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface GuardianSaveApplicationResponse_saveApplicationResponse_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type GuardianSaveApplicationResponse_saveApplicationResponse_response_components_questions = GuardianSaveApplicationResponse_saveApplicationResponse_response_components_questions_AnswerMultiChoice | GuardianSaveApplicationResponse_saveApplicationResponse_response_components_questions_AnswerCheckboxes | GuardianSaveApplicationResponse_saveApplicationResponse_response_components_questions_AnswerRanking | GuardianSaveApplicationResponse_saveApplicationResponse_response_components_questions_AnswerShortText | GuardianSaveApplicationResponse_saveApplicationResponse_response_components_questions_AnswerLongText | GuardianSaveApplicationResponse_saveApplicationResponse_response_components_questions_AnswerYesOrNo | GuardianSaveApplicationResponse_saveApplicationResponse_response_components_questions_AnswerDate;

export interface GuardianSaveApplicationResponse_saveApplicationResponse_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: GuardianSaveApplicationResponse_saveApplicationResponse_response_components_questions[];
}

export interface GuardianSaveApplicationResponse_saveApplicationResponse_response {
  __typename: "FormAnswer";
  components: GuardianSaveApplicationResponse_saveApplicationResponse_response_components[];
}

export interface GuardianSaveApplicationResponse_saveApplicationResponse_creator_verifiedRelation {
  __typename: "VerifiedRelation";
  isVerifiedRelation: boolean;
  customerId: string | null;
}

export interface GuardianSaveApplicationResponse_saveApplicationResponse_creator {
  __typename: "ApplicationCreator";
  id: string;
  name: string;
  source: UserSource;
  verifiedRelation: GuardianSaveApplicationResponse_saveApplicationResponse_creator_verifiedRelation;
  orgId: string;
}

export interface GuardianSaveApplicationResponse_saveApplicationResponse_subject {
  __typename: "SubjectCoSigner";
  userId: string | null;
  customerId: string | null;
  name: string;
}

export interface GuardianSaveApplicationResponse_saveApplicationResponse_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface GuardianSaveApplicationResponse_saveApplicationResponse_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface GuardianSaveApplicationResponse_saveApplicationResponse {
  __typename: "ApplicationResponse";
  id: string;
  status: ApplicationResponseStatus;
  modified: any;
  seenByOrg: any | null;
  language: ISO6391Language;
  lastAction: GuardianSaveApplicationResponse_saveApplicationResponse_lastAction;
  actions: GuardianSaveApplicationResponse_saveApplicationResponse_actions[];
  signatures: GuardianSaveApplicationResponse_saveApplicationResponse_signatures[];
  feedbackSignatures: GuardianSaveApplicationResponse_saveApplicationResponse_feedbackSignatures[];
  response: GuardianSaveApplicationResponse_saveApplicationResponse_response | null;
  creator: GuardianSaveApplicationResponse_saveApplicationResponse_creator;
  subject: GuardianSaveApplicationResponse_saveApplicationResponse_subject;
  lockHolder: GuardianSaveApplicationResponse_saveApplicationResponse_lockHolder | null;
  feedbackId: string | null;
  feedbackDescription: string | null;
  lockExpire: any | null;
  schoolUnit: GuardianSaveApplicationResponse_saveApplicationResponse_schoolUnit | null;
}

export interface GuardianSaveApplicationResponse {
  saveApplicationResponse: GuardianSaveApplicationResponse_saveApplicationResponse;
}

export interface GuardianSaveApplicationResponseVariables {
  responseId: string;
  response: FormAnswerInput;
  language: ISO6391Language;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GuardianRemoveApplicationResponse
// ====================================================

export interface GuardianRemoveApplicationResponse_removeApplicationResponse {
  __typename: "ApplicationResponse";
  id: string;
}

export interface GuardianRemoveApplicationResponse {
  removeApplicationResponse: GuardianRemoveApplicationResponse_removeApplicationResponse;
}

export interface GuardianRemoveApplicationResponseVariables {
  responseId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GuardianSignApplicationResponse
// ====================================================

export interface GuardianSignApplicationResponse_signApplicationResponse_lastAction {
  __typename: "ApplicationResponseEvent";
  id: string;
  action: ApplicationResponseAction;
  newStatus: ApplicationResponseStatus;
  comment: string | null;
  errorCode: string | null;
  created: any;
}

export interface GuardianSignApplicationResponse_signApplicationResponse_actions {
  __typename: "ApplicationResponseEvent";
  id: string;
  action: ApplicationResponseAction;
  newStatus: ApplicationResponseStatus;
  comment: string | null;
  errorCode: string | null;
  created: any;
}

export interface GuardianSignApplicationResponse_signApplicationResponse_signatures {
  __typename: "ApplicationResponseSignature";
  signerId: string;
  signedAs: string;
  customerId: string | null;
}

export interface GuardianSignApplicationResponse_signApplicationResponse_feedbackSignatures {
  __typename: "ApplicationResponseFeedbackSignature";
  signerId: string;
  signedAs: string;
  customerId: string | null;
}

export interface GuardianSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface GuardianSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface GuardianSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface GuardianSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: GuardianSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerRanking_rankings[];
}

export interface GuardianSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface GuardianSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface GuardianSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface GuardianSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type GuardianSignApplicationResponse_signApplicationResponse_response_components_questions = GuardianSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerMultiChoice | GuardianSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerCheckboxes | GuardianSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerRanking | GuardianSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerShortText | GuardianSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerLongText | GuardianSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerYesOrNo | GuardianSignApplicationResponse_signApplicationResponse_response_components_questions_AnswerDate;

export interface GuardianSignApplicationResponse_signApplicationResponse_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: GuardianSignApplicationResponse_signApplicationResponse_response_components_questions[];
}

export interface GuardianSignApplicationResponse_signApplicationResponse_response {
  __typename: "FormAnswer";
  components: GuardianSignApplicationResponse_signApplicationResponse_response_components[];
}

export interface GuardianSignApplicationResponse_signApplicationResponse_creator_verifiedRelation {
  __typename: "VerifiedRelation";
  isVerifiedRelation: boolean;
  customerId: string | null;
}

export interface GuardianSignApplicationResponse_signApplicationResponse_creator {
  __typename: "ApplicationCreator";
  id: string;
  name: string;
  source: UserSource;
  verifiedRelation: GuardianSignApplicationResponse_signApplicationResponse_creator_verifiedRelation;
  orgId: string;
}

export interface GuardianSignApplicationResponse_signApplicationResponse_subject {
  __typename: "SubjectCoSigner";
  userId: string | null;
  customerId: string | null;
  name: string;
}

export interface GuardianSignApplicationResponse_signApplicationResponse_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface GuardianSignApplicationResponse_signApplicationResponse_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface GuardianSignApplicationResponse_signApplicationResponse {
  __typename: "ApplicationResponse";
  id: string;
  status: ApplicationResponseStatus;
  modified: any;
  seenByOrg: any | null;
  language: ISO6391Language;
  lastAction: GuardianSignApplicationResponse_signApplicationResponse_lastAction;
  actions: GuardianSignApplicationResponse_signApplicationResponse_actions[];
  signatures: GuardianSignApplicationResponse_signApplicationResponse_signatures[];
  feedbackSignatures: GuardianSignApplicationResponse_signApplicationResponse_feedbackSignatures[];
  response: GuardianSignApplicationResponse_signApplicationResponse_response | null;
  creator: GuardianSignApplicationResponse_signApplicationResponse_creator;
  subject: GuardianSignApplicationResponse_signApplicationResponse_subject;
  lockHolder: GuardianSignApplicationResponse_signApplicationResponse_lockHolder | null;
  feedbackId: string | null;
  feedbackDescription: string | null;
  lockExpire: any | null;
  schoolUnit: GuardianSignApplicationResponse_signApplicationResponse_schoolUnit | null;
}

export interface GuardianSignApplicationResponse {
  signApplicationResponse: GuardianSignApplicationResponse_signApplicationResponse;
}

export interface GuardianSignApplicationResponseVariables {
  responseId: string;
  response: FormAnswerInput;
  language: ISO6391Language;
  context?: Context | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GuardianApplicationResponseAction
// ====================================================

export interface GuardianApplicationResponseAction_performApplicationResponseAction_lastAction {
  __typename: "ApplicationResponseEvent";
  id: string;
  action: ApplicationResponseAction;
  newStatus: ApplicationResponseStatus;
  comment: string | null;
  errorCode: string | null;
  created: any;
}

export interface GuardianApplicationResponseAction_performApplicationResponseAction_actions {
  __typename: "ApplicationResponseEvent";
  id: string;
  action: ApplicationResponseAction;
  newStatus: ApplicationResponseStatus;
  comment: string | null;
  errorCode: string | null;
  created: any;
}

export interface GuardianApplicationResponseAction_performApplicationResponseAction_signatures {
  __typename: "ApplicationResponseSignature";
  signerId: string;
  signedAs: string;
  customerId: string | null;
}

export interface GuardianApplicationResponseAction_performApplicationResponseAction_feedbackSignatures {
  __typename: "ApplicationResponseFeedbackSignature";
  signerId: string;
  signedAs: string;
  customerId: string | null;
}

export interface GuardianApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface GuardianApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface GuardianApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface GuardianApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: GuardianApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerRanking_rankings[];
}

export interface GuardianApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface GuardianApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface GuardianApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface GuardianApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type GuardianApplicationResponseAction_performApplicationResponseAction_response_components_questions = GuardianApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerMultiChoice | GuardianApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerCheckboxes | GuardianApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerRanking | GuardianApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerShortText | GuardianApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerLongText | GuardianApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerYesOrNo | GuardianApplicationResponseAction_performApplicationResponseAction_response_components_questions_AnswerDate;

export interface GuardianApplicationResponseAction_performApplicationResponseAction_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: GuardianApplicationResponseAction_performApplicationResponseAction_response_components_questions[];
}

export interface GuardianApplicationResponseAction_performApplicationResponseAction_response {
  __typename: "FormAnswer";
  components: GuardianApplicationResponseAction_performApplicationResponseAction_response_components[];
}

export interface GuardianApplicationResponseAction_performApplicationResponseAction_creator_verifiedRelation {
  __typename: "VerifiedRelation";
  isVerifiedRelation: boolean;
  customerId: string | null;
}

export interface GuardianApplicationResponseAction_performApplicationResponseAction_creator {
  __typename: "ApplicationCreator";
  id: string;
  name: string;
  source: UserSource;
  verifiedRelation: GuardianApplicationResponseAction_performApplicationResponseAction_creator_verifiedRelation;
  orgId: string;
}

export interface GuardianApplicationResponseAction_performApplicationResponseAction_subject {
  __typename: "SubjectCoSigner";
  userId: string | null;
  customerId: string | null;
  name: string;
}

export interface GuardianApplicationResponseAction_performApplicationResponseAction_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface GuardianApplicationResponseAction_performApplicationResponseAction_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface GuardianApplicationResponseAction_performApplicationResponseAction {
  __typename: "ApplicationResponse";
  id: string;
  status: ApplicationResponseStatus;
  modified: any;
  seenByOrg: any | null;
  language: ISO6391Language;
  lastAction: GuardianApplicationResponseAction_performApplicationResponseAction_lastAction;
  actions: GuardianApplicationResponseAction_performApplicationResponseAction_actions[];
  signatures: GuardianApplicationResponseAction_performApplicationResponseAction_signatures[];
  feedbackSignatures: GuardianApplicationResponseAction_performApplicationResponseAction_feedbackSignatures[];
  response: GuardianApplicationResponseAction_performApplicationResponseAction_response | null;
  creator: GuardianApplicationResponseAction_performApplicationResponseAction_creator;
  subject: GuardianApplicationResponseAction_performApplicationResponseAction_subject;
  lockHolder: GuardianApplicationResponseAction_performApplicationResponseAction_lockHolder | null;
  feedbackId: string | null;
  feedbackDescription: string | null;
  lockExpire: any | null;
  schoolUnit: GuardianApplicationResponseAction_performApplicationResponseAction_schoolUnit | null;
}

export interface GuardianApplicationResponseAction {
  performApplicationResponseAction: GuardianApplicationResponseAction_performApplicationResponseAction;
}

export interface GuardianApplicationResponseActionVariables {
  input: ActionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuardianFormList
// ====================================================

export interface GuardianFormList_me_emails {
  __typename: "UserEmail";
  source: EmailSource;
}

export interface GuardianFormList_me_publicationRecipients_form {
  __typename: "Form";
  id: string;
  name: string;
  language: ISO6391Language;
}

export interface GuardianFormList_me_publicationRecipients_responses_signatures_educloudUser {
  __typename: "User";
  id: string;
  customerId: string | null;
  name: string | null;
}

export interface GuardianFormList_me_publicationRecipients_responses_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  educloudUser: GuardianFormList_me_publicationRecipients_responses_signatures_educloudUser | null;
  customerId: string | null;
  educloudUserId: string | null;
}

export interface GuardianFormList_me_publicationRecipients_responses {
  __typename: "PublicationResponse";
  id: string;
  created: any;
  status: PublicationResponseStatus;
  signatures: GuardianFormList_me_publicationRecipients_responses_signatures[] | null;
}

export interface GuardianFormList_me_publicationRecipients {
  __typename: "PublicationRecipient";
  id: string;
  created: any;
  form: GuardianFormList_me_publicationRecipients_form;
  responses: GuardianFormList_me_publicationRecipients_responses[];
}

export interface GuardianFormList_me_applicationResponses_application_form {
  __typename: "Form";
  id: string;
  name: string;
}

export interface GuardianFormList_me_applicationResponses_application {
  __typename: "Application";
  id: string;
  name: string;
  form: GuardianFormList_me_applicationResponses_application_form;
}

export interface GuardianFormList_me_applicationResponses_creator {
  __typename: "ApplicationCreator";
  id: string;
  name: string;
}

export interface GuardianFormList_me_applicationResponses_subject {
  __typename: "SubjectCoSigner";
  userId: string | null;
  name: string;
}

export interface GuardianFormList_me_applicationResponses {
  __typename: "ApplicationResponse";
  id: string;
  status: ApplicationResponseStatus;
  created: any;
  application: GuardianFormList_me_applicationResponses_application;
  creator: GuardianFormList_me_applicationResponses_creator;
  subject: GuardianFormList_me_applicationResponses_subject;
}

export interface GuardianFormList_me_connectedUsers_publicationRecipients_form {
  __typename: "Form";
  id: string;
  name: string;
  language: ISO6391Language;
}

export interface GuardianFormList_me_connectedUsers_publicationRecipients_responses_signatures_educloudUser {
  __typename: "User";
  id: string;
  customerId: string | null;
  name: string | null;
}

export interface GuardianFormList_me_connectedUsers_publicationRecipients_responses_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  educloudUser: GuardianFormList_me_connectedUsers_publicationRecipients_responses_signatures_educloudUser | null;
  customerId: string | null;
  educloudUserId: string | null;
}

export interface GuardianFormList_me_connectedUsers_publicationRecipients_responses {
  __typename: "PublicationResponse";
  id: string;
  created: any;
  status: PublicationResponseStatus;
  signatures: GuardianFormList_me_connectedUsers_publicationRecipients_responses_signatures[] | null;
}

export interface GuardianFormList_me_connectedUsers_publicationRecipients {
  __typename: "PublicationRecipient";
  id: string;
  created: any;
  form: GuardianFormList_me_connectedUsers_publicationRecipients_form;
  responses: GuardianFormList_me_connectedUsers_publicationRecipients_responses[];
}

export interface GuardianFormList_me_connectedUsers_applicationResponses_application_form {
  __typename: "Form";
  id: string;
  name: string;
}

export interface GuardianFormList_me_connectedUsers_applicationResponses_application {
  __typename: "Application";
  id: string;
  name: string;
  form: GuardianFormList_me_connectedUsers_applicationResponses_application_form;
}

export interface GuardianFormList_me_connectedUsers_applicationResponses_creator {
  __typename: "ApplicationCreator";
  id: string;
  name: string;
}

export interface GuardianFormList_me_connectedUsers_applicationResponses_subject {
  __typename: "SubjectCoSigner";
  userId: string | null;
  name: string;
}

export interface GuardianFormList_me_connectedUsers_applicationResponses {
  __typename: "ApplicationResponse";
  id: string;
  status: ApplicationResponseStatus;
  created: any;
  application: GuardianFormList_me_connectedUsers_applicationResponses_application;
  creator: GuardianFormList_me_connectedUsers_applicationResponses_creator;
  subject: GuardianFormList_me_connectedUsers_applicationResponses_subject;
}

export interface GuardianFormList_me_connectedUsers {
  __typename: "User";
  id: string;
  name: string | null;
  publicationRecipients: GuardianFormList_me_connectedUsers_publicationRecipients[] | null;
  applicationResponses: GuardianFormList_me_connectedUsers_applicationResponses[];
}

export interface GuardianFormList_me_relations_edges_node_publicationRecipients_form {
  __typename: "Form";
  id: string;
  name: string;
  language: ISO6391Language;
}

export interface GuardianFormList_me_relations_edges_node_publicationRecipients_responses_signatures_educloudUser {
  __typename: "User";
  id: string;
  customerId: string | null;
  name: string | null;
}

export interface GuardianFormList_me_relations_edges_node_publicationRecipients_responses_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  educloudUser: GuardianFormList_me_relations_edges_node_publicationRecipients_responses_signatures_educloudUser | null;
  customerId: string | null;
  educloudUserId: string | null;
}

export interface GuardianFormList_me_relations_edges_node_publicationRecipients_responses {
  __typename: "PublicationResponse";
  id: string;
  created: any;
  status: PublicationResponseStatus;
  signatures: GuardianFormList_me_relations_edges_node_publicationRecipients_responses_signatures[] | null;
}

export interface GuardianFormList_me_relations_edges_node_publicationRecipients {
  __typename: "PublicationRecipient";
  id: string;
  created: any;
  form: GuardianFormList_me_relations_edges_node_publicationRecipients_form;
  responses: GuardianFormList_me_relations_edges_node_publicationRecipients_responses[];
}

export interface GuardianFormList_me_relations_edges_node_applicationResponses_application_form {
  __typename: "Form";
  id: string;
  name: string;
}

export interface GuardianFormList_me_relations_edges_node_applicationResponses_application {
  __typename: "Application";
  id: string;
  name: string;
  form: GuardianFormList_me_relations_edges_node_applicationResponses_application_form;
}

export interface GuardianFormList_me_relations_edges_node_applicationResponses_creator {
  __typename: "ApplicationCreator";
  id: string;
  name: string;
}

export interface GuardianFormList_me_relations_edges_node_applicationResponses_subject {
  __typename: "SubjectCoSigner";
  userId: string | null;
  name: string;
}

export interface GuardianFormList_me_relations_edges_node_applicationResponses {
  __typename: "ApplicationResponse";
  id: string;
  status: ApplicationResponseStatus;
  created: any;
  application: GuardianFormList_me_relations_edges_node_applicationResponses_application;
  creator: GuardianFormList_me_relations_edges_node_applicationResponses_creator;
  subject: GuardianFormList_me_relations_edges_node_applicationResponses_subject;
}

export interface GuardianFormList_me_relations_edges_node {
  __typename: "User";
  id: string;
  name: string | null;
  publicationRecipients: GuardianFormList_me_relations_edges_node_publicationRecipients[] | null;
  applicationResponses: GuardianFormList_me_relations_edges_node_applicationResponses[];
}

export interface GuardianFormList_me_relations_edges {
  __typename: "Relation";
  type: RelationType;
  node: GuardianFormList_me_relations_edges_node | null;
}

export interface GuardianFormList_me_relations {
  __typename: "RelationConnection";
  edges: GuardianFormList_me_relations_edges[];
}

export interface GuardianFormList_me {
  __typename: "User";
  id: string;
  emails: GuardianFormList_me_emails[] | null;
  name: string | null;
  publicationRecipients: GuardianFormList_me_publicationRecipients[] | null;
  applicationResponses: GuardianFormList_me_applicationResponses[];
  connectedUsers: GuardianFormList_me_connectedUsers[] | null;
  relations: GuardianFormList_me_relations | null;
}

export interface GuardianFormList {
  me: GuardianFormList_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminForceUpdatePublicationGroupNames
// ====================================================

export interface adminForceUpdatePublicationGroupNames_adminForceUpdatePublicationGroupNames {
  __typename: "Response";
  code: ReturnCode | null;
}

export interface adminForceUpdatePublicationGroupNames {
  adminForceUpdatePublicationGroupNames: adminForceUpdatePublicationGroupNames_adminForceUpdatePublicationGroupNames;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminPing
// ====================================================

export interface adminPing_adminPing {
  __typename: "Response";
  code: ReturnCode | null;
}

export interface adminPing {
  adminPing: adminPing_adminPing;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminClearEducloudCache
// ====================================================

export interface adminClearEducloudCache_adminClearEducloudCache {
  __typename: "Response";
  code: ReturnCode | null;
}

export interface adminClearEducloudCache {
  adminClearEducloudCache: adminClearEducloudCache_adminClearEducloudCache;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateEmptyActorNames
// ====================================================

export interface updateEmptyActorNames {
  updateEmptyActorNames: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SuperadminOrganisations
// ====================================================

export interface SuperadminOrganisations_organisations_dataSources {
  __typename: "DataSource";
  customerId: string;
  name: string;
  description: string;
}

export interface SuperadminOrganisations_organisations_settings {
  __typename: "OrganisationSettings";
  emailSuffixWork: string;
  emailSuffixHome: string;
}

export interface SuperadminOrganisations_organisations {
  __typename: "Organisation";
  id: string;
  displayName: string;
  skolidOrgId: string | null;
  country: ISO3166Country;
  defaultLanguage: ISO6391Language;
  dataSources: SuperadminOrganisations_organisations_dataSources[];
  settings: SuperadminOrganisations_organisations_settings;
}

export interface SuperadminOrganisations {
  organisations: SuperadminOrganisations_organisations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SuperadminOrganisationsPermissions
// ====================================================

export interface SuperadminOrganisationsPermissions_me_availableOrganisations_myPermissions_organisation_roles {
  __typename: "PermissionRole";
  roleId: PermissionType;
}

export interface SuperadminOrganisationsPermissions_me_availableOrganisations_myPermissions_organisation {
  __typename: "OrganisationPermissionElement";
  roles: SuperadminOrganisationsPermissions_me_availableOrganisations_myPermissions_organisation_roles[];
}

export interface SuperadminOrganisationsPermissions_me_availableOrganisations_myPermissions {
  __typename: "SubjectPermissions";
  organisation: SuperadminOrganisationsPermissions_me_availableOrganisations_myPermissions_organisation;
}

export interface SuperadminOrganisationsPermissions_me_availableOrganisations {
  __typename: "Organisation";
  id: string;
  displayName: string;
  defaultLanguage: ISO6391Language;
  myPermissions: SuperadminOrganisationsPermissions_me_availableOrganisations_myPermissions;
}

export interface SuperadminOrganisationsPermissions_me_connectedUsers_skolidOrganization {
  __typename: "SkolidOrganization";
  id: string;
}

export interface SuperadminOrganisationsPermissions_me_connectedUsers_availableOrganisations_myPermissions_organisation_roles {
  __typename: "PermissionRole";
  roleId: PermissionType;
}

export interface SuperadminOrganisationsPermissions_me_connectedUsers_availableOrganisations_myPermissions_organisation {
  __typename: "OrganisationPermissionElement";
  roles: SuperadminOrganisationsPermissions_me_connectedUsers_availableOrganisations_myPermissions_organisation_roles[];
}

export interface SuperadminOrganisationsPermissions_me_connectedUsers_availableOrganisations_myPermissions {
  __typename: "SubjectPermissions";
  organisation: SuperadminOrganisationsPermissions_me_connectedUsers_availableOrganisations_myPermissions_organisation;
}

export interface SuperadminOrganisationsPermissions_me_connectedUsers_availableOrganisations {
  __typename: "Organisation";
  id: string;
  displayName: string;
  defaultLanguage: ISO6391Language;
  myPermissions: SuperadminOrganisationsPermissions_me_connectedUsers_availableOrganisations_myPermissions;
}

export interface SuperadminOrganisationsPermissions_me_connectedUsers {
  __typename: "User";
  id: string;
  name: string | null;
  skolidOrganization: SuperadminOrganisationsPermissions_me_connectedUsers_skolidOrganization | null;
  availableOrganisations: SuperadminOrganisationsPermissions_me_connectedUsers_availableOrganisations[];
}

export interface SuperadminOrganisationsPermissions_me {
  __typename: "User";
  id: string;
  name: string | null;
  availableOrganisations: SuperadminOrganisationsPermissions_me_availableOrganisations[];
  connectedUsers: SuperadminOrganisationsPermissions_me_connectedUsers[] | null;
}

export interface SuperadminOrganisationsPermissions {
  me: SuperadminOrganisationsPermissions_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SuperadminUpdateOrganisationSettings
// ====================================================

export interface SuperadminUpdateOrganisationSettings_updateOrganisationSettings_settings {
  __typename: "OrganisationSettings";
  emailSuffixWork: string;
  emailSuffixHome: string;
}

export interface SuperadminUpdateOrganisationSettings_updateOrganisationSettings {
  __typename: "Organisation";
  id: string;
  defaultLanguage: ISO6391Language;
  settings: SuperadminUpdateOrganisationSettings_updateOrganisationSettings_settings;
}

export interface SuperadminUpdateOrganisationSettings {
  updateOrganisationSettings: SuperadminUpdateOrganisationSettings_updateOrganisationSettings;
}

export interface SuperadminUpdateOrganisationSettingsVariables {
  orgId: string;
  input: UpdateOrganisationSettingsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminSignLogoUpload
// ====================================================

export interface AdminSignLogoUpload_adminSignLogoUpload_post_headers {
  __typename: "AwsHeader";
  key: string;
  value: string;
}

export interface AdminSignLogoUpload_adminSignLogoUpload {
  __typename: "AdminSignLogoUpload";
  key: string;
  signedUrl: string;
  post_headers: AdminSignLogoUpload_adminSignLogoUpload_post_headers[];
}

export interface AdminSignLogoUpload {
  adminSignLogoUpload: AdminSignLogoUpload_adminSignLogoUpload;
}

export interface AdminSignLogoUploadVariables {
  input: AdminSignLogoUploadInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminForceCheckOrgPublicationRecipientChanges
// ====================================================

export interface adminForceCheckOrgPublicationRecipientChanges_adminForceCheckOrgPublicationRecipientChanges {
  __typename: "Response";
  code: ReturnCode | null;
}

export interface adminForceCheckOrgPublicationRecipientChanges {
  adminForceCheckOrgPublicationRecipientChanges: adminForceCheckOrgPublicationRecipientChanges_adminForceCheckOrgPublicationRecipientChanges;
}

export interface adminForceCheckOrgPublicationRecipientChangesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminAssignAdminPermissionToMe
// ====================================================

export interface adminAssignAdminPermissionToMe_adminAssignAdminPermissionToMe {
  __typename: "Response";
  code: ReturnCode | null;
}

export interface adminAssignAdminPermissionToMe {
  adminAssignAdminPermissionToMe: adminAssignAdminPermissionToMe_adminAssignAdminPermissionToMe;
}

export interface adminAssignAdminPermissionToMeVariables {
  org: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminCreateTemplate
// ====================================================

export interface AdminCreateTemplate_adminCreateTemplate {
  __typename: "Template";
  id: string;
}

export interface AdminCreateTemplate {
  adminCreateTemplate: AdminCreateTemplate_adminCreateTemplate;
}

export interface AdminCreateTemplateVariables {
  title: string;
  category: string;
  language: ISO6391Language;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminUpdateTemplate
// ====================================================

export interface AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionRanking_options[];
}

export interface AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionCheckboxes_options[];
}

export interface AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionMultiChoice_options[];
}

export type AdminUpdateTemplate_adminUpdateTemplate_componentData_questions = AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionYesNo | AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionRanking | AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionShortText | AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionCheckboxes | AdminUpdateTemplate_adminUpdateTemplate_componentData_questions_FormQuestionMultiChoice;

export interface AdminUpdateTemplate_adminUpdateTemplate_componentData_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface AdminUpdateTemplate_adminUpdateTemplate_componentData {
  __typename: "ComponentTemplate";
  id: string;
  title: string;
  description: string;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: AdminUpdateTemplate_adminUpdateTemplate_componentData_questions[];
  attachments: AdminUpdateTemplate_adminUpdateTemplate_componentData_attachments[];
}

export interface AdminUpdateTemplate_adminUpdateTemplate {
  __typename: "Template";
  id: string;
  componentData: AdminUpdateTemplate_adminUpdateTemplate_componentData;
}

export interface AdminUpdateTemplate {
  adminUpdateTemplate: AdminUpdateTemplate_adminUpdateTemplate;
}

export interface AdminUpdateTemplateVariables {
  id: string;
  componentData: ComponentTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminPublishTemplate
// ====================================================

export interface AdminPublishTemplate_adminPublishTemplate {
  __typename: "Template";
  id: string;
}

export interface AdminPublishTemplate {
  adminPublishTemplate: AdminPublishTemplate_adminPublishTemplate;
}

export interface AdminPublishTemplateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminCreateTemplateTranslation
// ====================================================

export interface AdminCreateTemplateTranslation_adminCreateTemplateTranslation {
  __typename: "TemplateTranslation";
  id: string;
}

export interface AdminCreateTemplateTranslation {
  adminCreateTemplateTranslation: AdminCreateTemplateTranslation_adminCreateTemplateTranslation;
}

export interface AdminCreateTemplateTranslationVariables {
  id: string;
  language: ISO6391Language;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminUpdateTemplateTranslation
// ====================================================

export interface AdminUpdateTemplateTranslation_adminUpdateTemplateTranslation_componentData_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface AdminUpdateTemplateTranslation_adminUpdateTemplateTranslation_componentData_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: AdminUpdateTemplateTranslation_adminUpdateTemplateTranslation_componentData_questions_options[] | null;
}

export interface AdminUpdateTemplateTranslation_adminUpdateTemplateTranslation_componentData_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface AdminUpdateTemplateTranslation_adminUpdateTemplateTranslation_componentData {
  __typename: "TranslationComponentTemplate";
  id: string;
  title: string;
  description: string;
  questions: AdminUpdateTemplateTranslation_adminUpdateTemplateTranslation_componentData_questions[];
  attachments: AdminUpdateTemplateTranslation_adminUpdateTemplateTranslation_componentData_attachments[];
}

export interface AdminUpdateTemplateTranslation_adminUpdateTemplateTranslation {
  __typename: "TemplateTranslation";
  id: string;
  language: ISO6391Language;
  componentData: AdminUpdateTemplateTranslation_adminUpdateTemplateTranslation_componentData;
}

export interface AdminUpdateTemplateTranslation {
  adminUpdateTemplateTranslation: AdminUpdateTemplateTranslation_adminUpdateTemplateTranslation;
}

export interface AdminUpdateTemplateTranslationVariables {
  id: string;
  language: ISO6391Language;
  componentData: ComponentTemplateTranslationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminPublishTemplateTranslation
// ====================================================

export interface AdminPublishTemplateTranslation_adminPublishTemplateTranslation {
  __typename: "TemplateTranslation";
  id: string;
}

export interface AdminPublishTemplateTranslation {
  adminPublishTemplateTranslation: AdminPublishTemplateTranslation_adminPublishTemplateTranslation;
}

export interface AdminPublishTemplateTranslationVariables {
  id: string;
  language: ISO6391Language;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: superadminTemplates
// ====================================================

export interface superadminTemplates_templates_componentData_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface superadminTemplates_templates_componentData_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: superadminTemplates_templates_componentData_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface superadminTemplates_templates_componentData_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface superadminTemplates_templates_componentData_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface superadminTemplates_templates_componentData_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: superadminTemplates_templates_componentData_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: superadminTemplates_templates_componentData_questions_FormQuestionRanking_options[];
}

export interface superadminTemplates_templates_componentData_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface superadminTemplates_templates_componentData_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: superadminTemplates_templates_componentData_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface superadminTemplates_templates_componentData_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface superadminTemplates_templates_componentData_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface superadminTemplates_templates_componentData_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: superadminTemplates_templates_componentData_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: superadminTemplates_templates_componentData_questions_FormQuestionCheckboxes_options[];
}

export interface superadminTemplates_templates_componentData_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface superadminTemplates_templates_componentData_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface superadminTemplates_templates_componentData_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: superadminTemplates_templates_componentData_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: superadminTemplates_templates_componentData_questions_FormQuestionMultiChoice_options[];
}

export type superadminTemplates_templates_componentData_questions = superadminTemplates_templates_componentData_questions_FormQuestionYesNo | superadminTemplates_templates_componentData_questions_FormQuestionRanking | superadminTemplates_templates_componentData_questions_FormQuestionShortText | superadminTemplates_templates_componentData_questions_FormQuestionCheckboxes | superadminTemplates_templates_componentData_questions_FormQuestionMultiChoice;

export interface superadminTemplates_templates_componentData_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface superadminTemplates_templates_componentData {
  __typename: "ComponentTemplate";
  id: string;
  title: string;
  description: string;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: superadminTemplates_templates_componentData_questions[];
  attachments: superadminTemplates_templates_componentData_attachments[];
}

export interface superadminTemplates_templates_translations_componentData_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface superadminTemplates_templates_translations_componentData_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: superadminTemplates_templates_translations_componentData_questions_options[] | null;
}

export interface superadminTemplates_templates_translations_componentData_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface superadminTemplates_templates_translations_componentData {
  __typename: "TranslationComponentTemplate";
  id: string;
  title: string;
  description: string;
  questions: superadminTemplates_templates_translations_componentData_questions[];
  attachments: superadminTemplates_templates_translations_componentData_attachments[];
}

export interface superadminTemplates_templates_translations {
  __typename: "TemplateTranslation";
  id: string;
  published: boolean;
  language: ISO6391Language;
  componentData: superadminTemplates_templates_translations_componentData;
}

export interface superadminTemplates_templates {
  __typename: "Template";
  id: string;
  language: ISO6391Language;
  published: boolean;
  componentData: superadminTemplates_templates_componentData;
  translations: superadminTemplates_templates_translations[];
}

export interface superadminTemplates {
  templates: superadminTemplates_templates[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: amISuperAdmin
// ====================================================

export interface amISuperAdmin {
  amISuperAdmin: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FullApplication
// ====================================================

export interface FullApplication_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullApplication_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullApplication_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface FullApplication_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullApplication_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullApplication_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullApplication_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: FullApplication_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface FullApplication_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullApplication_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullApplication_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface FullApplication_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullApplication_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullApplication_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullApplication_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: FullApplication_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface FullApplication_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullApplication_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullApplication_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullApplication_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: FullApplication_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type FullApplication_form_componentData_components_questions = FullApplication_form_componentData_components_questions_FormQuestionYesNo | FullApplication_form_componentData_components_questions_FormQuestionRanking | FullApplication_form_componentData_components_questions_FormQuestionShortText | FullApplication_form_componentData_components_questions_FormQuestionCheckboxes | FullApplication_form_componentData_components_questions_FormQuestionMultiChoice;

export interface FullApplication_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface FullApplication_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: FullApplication_form_componentData_components_questions[];
  templateId: string | null;
  attachments: FullApplication_form_componentData_components_attachments[] | null;
}

export interface FullApplication_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullApplication_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullApplication_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface FullApplication_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullApplication_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullApplication_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullApplication_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: FullApplication_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface FullApplication_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullApplication_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullApplication_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface FullApplication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullApplication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullApplication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullApplication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: FullApplication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface FullApplication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullApplication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullApplication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullApplication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: FullApplication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type FullApplication_form_componentData_predicateComponents_questions = FullApplication_form_componentData_predicateComponents_questions_FormQuestionYesNo | FullApplication_form_componentData_predicateComponents_questions_FormQuestionRanking | FullApplication_form_componentData_predicateComponents_questions_FormQuestionShortText | FullApplication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | FullApplication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface FullApplication_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: FullApplication_form_componentData_predicateComponents_questions[];
}

export interface FullApplication_form_componentData {
  __typename: "ComponentData";
  components: FullApplication_form_componentData_components[];
  predicateComponents: FullApplication_form_componentData_predicateComponents[] | null;
}

export interface FullApplication_form_owner {
  __typename: "Organisation";
  id: string;
  displayName: string;
}

export interface FullApplication_form {
  __typename: "Form";
  id: string;
  type: FormType;
  subType: FormSubType;
  name: string;
  description: string | null;
  language: ISO6391Language;
  componentData: FullApplication_form_componentData;
  owner: FullApplication_form_owner;
}

export interface FullApplication {
  __typename: "Application";
  id: string;
  name: string;
  sent: boolean;
  sendDate: any;
  singleGuardianSend: boolean;
  singleGuardianAcceptOffer: boolean;
  schoolunitSpecific: boolean;
  closed: boolean;
  subscribable: boolean;
  form: FullApplication_form;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrganisationWithSettings
// ====================================================

export interface OrganisationWithSettings_dataSources {
  __typename: "DataSource";
  customerId: string;
  name: string;
  description: string;
}

export interface OrganisationWithSettings_settings_employmentPermissions {
  __typename: "OrganisationEmploymentPermission";
  employmentType: EmploymentRole;
  roles: PermissionType[];
}

export interface OrganisationWithSettings_settings {
  __typename: "OrganisationSettings";
  autoUpdateRecipients: boolean;
  employmentPermissions: OrganisationWithSettings_settings_employmentPermissions[];
}

export interface OrganisationWithSettings {
  __typename: "Organisation";
  id: string;
  displayName: string;
  dataSources: OrganisationWithSettings_dataSources[];
  settings: OrganisationWithSettings_settings;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FullSubjectPermissions
// ====================================================

export interface FullSubjectPermissions_organisation_roles {
  __typename: "PermissionRole";
  roleId: PermissionType;
  sources: PermissionSource[];
}

export interface FullSubjectPermissions_organisation {
  __typename: "OrganisationPermissionElement";
  resourceId: string;
  displayName: string | null;
  roles: FullSubjectPermissions_organisation_roles[];
}

export interface FullSubjectPermissions_schoolUnits_roles {
  __typename: "PermissionRole";
  roleId: PermissionType;
  sources: PermissionSource[];
}

export interface FullSubjectPermissions_schoolUnits {
  __typename: "SchoolUnitPermissionElement";
  resourceId: string;
  displayName: string | null;
  employmentRoles: EmploymentRole[];
  roles: FullSubjectPermissions_schoolUnits_roles[];
}

export interface FullSubjectPermissions {
  __typename: "SubjectPermissions";
  id: string | null;
  organisation: FullSubjectPermissions_organisation;
  schoolUnits: FullSubjectPermissions_schoolUnits[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RecipientUpdatePublicationUser
// ====================================================

export interface RecipientUpdatePublicationUser_user {
  __typename: "User";
  id: string;
  customerId: string | null;
  name: string | null;
}

export interface RecipientUpdatePublicationUser {
  __typename: "PublicationUser";
  groupIds: string[];
  manuallyAdded: boolean;
  excludedFromPublication: boolean;
  user: RecipientUpdatePublicationUser_user | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FullHistoryRecord
// ====================================================

export interface FullHistoryRecord_ClassResponsesHistoryRecord_data {
  __typename: "ClassResponsesHistoryData";
  customerId: string;
  schoolUnitId: string;
  studentGroupId: string;
}

export interface FullHistoryRecord_ClassResponsesHistoryRecord {
  __typename: "ClassResponsesHistoryRecord";
  id: string;
  label: string;
  type: RecordHistoryType;
  data: FullHistoryRecord_ClassResponsesHistoryRecord_data;
}

export interface FullHistoryRecord_FormHistoryRecord_data {
  __typename: "FormHistoryData";
  formId: string;
}

export interface FullHistoryRecord_FormHistoryRecord {
  __typename: "FormHistoryRecord";
  id: string;
  label: string;
  type: RecordHistoryType;
  data: FullHistoryRecord_FormHistoryRecord_data;
}

export type FullHistoryRecord = FullHistoryRecord_ClassResponsesHistoryRecord | FullHistoryRecord_FormHistoryRecord;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormMetadata
// ====================================================

export interface FormMetadata_creator {
  __typename: "User" | "Client";
  id: string;
  name: string | null;
}

export interface FormMetadata {
  __typename: "Form";
  id: string;
  type: FormType;
  name: string;
  creator: FormMetadata_creator | null;
  created: any;
  locked: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FullComponentQuestion
// ====================================================

export interface FullComponentQuestion_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullComponentQuestion_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullComponentQuestion_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface FullComponentQuestion_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullComponentQuestion_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullComponentQuestion_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullComponentQuestion_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: FullComponentQuestion_FormQuestionRanking_options[];
}

export interface FullComponentQuestion_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullComponentQuestion_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullComponentQuestion_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface FullComponentQuestion_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullComponentQuestion_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullComponentQuestion_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullComponentQuestion_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: FullComponentQuestion_FormQuestionCheckboxes_options[];
}

export interface FullComponentQuestion_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullComponentQuestion_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullComponentQuestion_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullComponentQuestion_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: FullComponentQuestion_FormQuestionMultiChoice_options[];
}

export type FullComponentQuestion = FullComponentQuestion_FormQuestionYesNo | FullComponentQuestion_FormQuestionRanking | FullComponentQuestion_FormQuestionShortText | FullComponentQuestion_FormQuestionCheckboxes | FullComponentQuestion_FormQuestionMultiChoice;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FullFormComponent
// ====================================================

export interface FullFormComponent_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullFormComponent_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullFormComponent_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface FullFormComponent_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullFormComponent_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullFormComponent_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullFormComponent_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: FullFormComponent_questions_FormQuestionRanking_options[];
}

export interface FullFormComponent_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullFormComponent_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullFormComponent_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface FullFormComponent_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullFormComponent_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullFormComponent_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullFormComponent_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: FullFormComponent_questions_FormQuestionCheckboxes_options[];
}

export interface FullFormComponent_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullFormComponent_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullFormComponent_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullFormComponent_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: FullFormComponent_questions_FormQuestionMultiChoice_options[];
}

export type FullFormComponent_questions = FullFormComponent_questions_FormQuestionYesNo | FullFormComponent_questions_FormQuestionRanking | FullFormComponent_questions_FormQuestionShortText | FullFormComponent_questions_FormQuestionCheckboxes | FullFormComponent_questions_FormQuestionMultiChoice;

export interface FullFormComponent_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface FullFormComponent {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: FullFormComponent_questions[];
  templateId: string | null;
  attachments: FullFormComponent_attachments[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FullFormPredicateComponent
// ====================================================

export interface FullFormPredicateComponent_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullFormPredicateComponent_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullFormPredicateComponent_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface FullFormPredicateComponent_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullFormPredicateComponent_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullFormPredicateComponent_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullFormPredicateComponent_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: FullFormPredicateComponent_questions_FormQuestionRanking_options[];
}

export interface FullFormPredicateComponent_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullFormPredicateComponent_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullFormPredicateComponent_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface FullFormPredicateComponent_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullFormPredicateComponent_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullFormPredicateComponent_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullFormPredicateComponent_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: FullFormPredicateComponent_questions_FormQuestionCheckboxes_options[];
}

export interface FullFormPredicateComponent_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullFormPredicateComponent_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullFormPredicateComponent_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullFormPredicateComponent_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: FullFormPredicateComponent_questions_FormQuestionMultiChoice_options[];
}

export type FullFormPredicateComponent_questions = FullFormPredicateComponent_questions_FormQuestionYesNo | FullFormPredicateComponent_questions_FormQuestionRanking | FullFormPredicateComponent_questions_FormQuestionShortText | FullFormPredicateComponent_questions_FormQuestionCheckboxes | FullFormPredicateComponent_questions_FormQuestionMultiChoice;

export interface FullFormPredicateComponent {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: FullFormPredicateComponent_questions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormComponentData
// ====================================================

export interface FormComponentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FormComponentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FormComponentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface FormComponentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FormComponentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FormComponentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FormComponentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: FormComponentData_components_questions_FormQuestionRanking_options[];
}

export interface FormComponentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FormComponentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FormComponentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface FormComponentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FormComponentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FormComponentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FormComponentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: FormComponentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface FormComponentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FormComponentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FormComponentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FormComponentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: FormComponentData_components_questions_FormQuestionMultiChoice_options[];
}

export type FormComponentData_components_questions = FormComponentData_components_questions_FormQuestionYesNo | FormComponentData_components_questions_FormQuestionRanking | FormComponentData_components_questions_FormQuestionShortText | FormComponentData_components_questions_FormQuestionCheckboxes | FormComponentData_components_questions_FormQuestionMultiChoice;

export interface FormComponentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface FormComponentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: FormComponentData_components_questions[];
  templateId: string | null;
  attachments: FormComponentData_components_attachments[] | null;
}

export interface FormComponentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FormComponentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FormComponentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface FormComponentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FormComponentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FormComponentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FormComponentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: FormComponentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface FormComponentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FormComponentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FormComponentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface FormComponentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FormComponentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FormComponentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FormComponentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: FormComponentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface FormComponentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FormComponentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FormComponentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FormComponentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: FormComponentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type FormComponentData_predicateComponents_questions = FormComponentData_predicateComponents_questions_FormQuestionYesNo | FormComponentData_predicateComponents_questions_FormQuestionRanking | FormComponentData_predicateComponents_questions_FormQuestionShortText | FormComponentData_predicateComponents_questions_FormQuestionCheckboxes | FormComponentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface FormComponentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: FormComponentData_predicateComponents_questions[];
}

export interface FormComponentData {
  __typename: "ComponentData";
  components: FormComponentData_components[];
  predicateComponents: FormComponentData_predicateComponents[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FullFormAccess
// ====================================================

export interface FullFormAccess_schoolUnits {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface FullFormAccess {
  __typename: "FormAccess";
  public: boolean;
  organisation: boolean;
  schoolUnits: (FullFormAccess_schoolUnits | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FullFormAnswer
// ====================================================

export interface FullFormAnswer_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface FullFormAnswer_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface FullFormAnswer_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface FullFormAnswer_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: FullFormAnswer_components_questions_AnswerRanking_rankings[];
}

export interface FullFormAnswer_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface FullFormAnswer_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface FullFormAnswer_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface FullFormAnswer_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type FullFormAnswer_components_questions = FullFormAnswer_components_questions_AnswerMultiChoice | FullFormAnswer_components_questions_AnswerCheckboxes | FullFormAnswer_components_questions_AnswerRanking | FullFormAnswer_components_questions_AnswerShortText | FullFormAnswer_components_questions_AnswerLongText | FullFormAnswer_components_questions_AnswerYesOrNo | FullFormAnswer_components_questions_AnswerDate;

export interface FullFormAnswer_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: FullFormAnswer_components_questions[];
}

export interface FullFormAnswer {
  __typename: "FormAnswer";
  components: FullFormAnswer_components[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FullResponse
// ====================================================

export interface FullResponse_signatures_signer {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  idp: string | null;
  idpSub: string | null;
  name: string | null;
}

export interface FullResponse_signatures_educloudUser {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
}

export interface FullResponse_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  customerId: string | null;
  signer: FullResponse_signatures_signer | null;
  educloudUser: FullResponse_signatures_educloudUser | null;
  educloudUserId: string | null;
}

export interface FullResponse_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface FullResponse_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface FullResponse_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface FullResponse_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: FullResponse_response_components_questions_AnswerRanking_rankings[];
}

export interface FullResponse_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface FullResponse_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface FullResponse_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface FullResponse_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type FullResponse_response_components_questions = FullResponse_response_components_questions_AnswerMultiChoice | FullResponse_response_components_questions_AnswerCheckboxes | FullResponse_response_components_questions_AnswerRanking | FullResponse_response_components_questions_AnswerShortText | FullResponse_response_components_questions_AnswerLongText | FullResponse_response_components_questions_AnswerYesOrNo | FullResponse_response_components_questions_AnswerDate;

export interface FullResponse_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: FullResponse_response_components_questions[];
}

export interface FullResponse_response {
  __typename: "FormAnswer";
  components: FullResponse_response_components[];
}

export interface FullResponse_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface FullResponse {
  __typename: "PublicationResponse";
  id: string;
  status: PublicationResponseStatus;
  signRole: PublicationResponseSignRole | null;
  signatures: FullResponse_signatures[] | null;
  modified: any;
  language: ISO6391Language;
  response: FullResponse_response | null;
  lockHolder: FullResponse_lockHolder | null;
  lockExpire: any | null;
  validIfSigned: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FullResponseWithEmail
// ====================================================

export interface FullResponseWithEmail_signatures_signer_emails {
  __typename: "UserEmail";
  value: string;
  preferredHome: boolean;
}

export interface FullResponseWithEmail_signatures_signer {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  idp: string | null;
  idpSub: string | null;
  name: string | null;
  emails: FullResponseWithEmail_signatures_signer_emails[] | null;
}

export interface FullResponseWithEmail_signatures_educloudUser {
  __typename: "User";
  id: string;
  source: UserSource;
  customerId: string | null;
  name: string | null;
}

export interface FullResponseWithEmail_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  signer: FullResponseWithEmail_signatures_signer | null;
  educloudUser: FullResponseWithEmail_signatures_educloudUser | null;
  educloudUserId: string | null;
}

export interface FullResponseWithEmail_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface FullResponseWithEmail_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface FullResponseWithEmail_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface FullResponseWithEmail_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: FullResponseWithEmail_response_components_questions_AnswerRanking_rankings[];
}

export interface FullResponseWithEmail_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface FullResponseWithEmail_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface FullResponseWithEmail_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface FullResponseWithEmail_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type FullResponseWithEmail_response_components_questions = FullResponseWithEmail_response_components_questions_AnswerMultiChoice | FullResponseWithEmail_response_components_questions_AnswerCheckboxes | FullResponseWithEmail_response_components_questions_AnswerRanking | FullResponseWithEmail_response_components_questions_AnswerShortText | FullResponseWithEmail_response_components_questions_AnswerLongText | FullResponseWithEmail_response_components_questions_AnswerYesOrNo | FullResponseWithEmail_response_components_questions_AnswerDate;

export interface FullResponseWithEmail_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: FullResponseWithEmail_response_components_questions[];
}

export interface FullResponseWithEmail_response {
  __typename: "FormAnswer";
  components: FullResponseWithEmail_response_components[];
}

export interface FullResponseWithEmail_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface FullResponseWithEmail {
  __typename: "PublicationResponse";
  id: string;
  status: PublicationResponseStatus;
  signatures: FullResponseWithEmail_signatures[] | null;
  modified: any;
  language: ISO6391Language;
  response: FullResponseWithEmail_response | null;
  lockHolder: FullResponseWithEmail_lockHolder | null;
  lockExpire: any | null;
  validIfSigned: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FullApplicationResponse
// ====================================================

export interface FullApplicationResponse_lastAction {
  __typename: "ApplicationResponseEvent";
  id: string;
  action: ApplicationResponseAction;
  newStatus: ApplicationResponseStatus;
  comment: string | null;
  errorCode: string | null;
  created: any;
}

export interface FullApplicationResponse_actions {
  __typename: "ApplicationResponseEvent";
  id: string;
  action: ApplicationResponseAction;
  newStatus: ApplicationResponseStatus;
  comment: string | null;
  errorCode: string | null;
  created: any;
}

export interface FullApplicationResponse_signatures {
  __typename: "ApplicationResponseSignature";
  signerId: string;
  signedAs: string;
  customerId: string | null;
}

export interface FullApplicationResponse_feedbackSignatures {
  __typename: "ApplicationResponseFeedbackSignature";
  signerId: string;
  signedAs: string;
  customerId: string | null;
}

export interface FullApplicationResponse_response_components_questions_AnswerMultiChoice {
  __typename: "AnswerMultiChoice";
  questionId: string;
  multiChoice: string;
}

export interface FullApplicationResponse_response_components_questions_AnswerCheckboxes {
  __typename: "AnswerCheckboxes";
  questionId: string;
  checkboxes: string[];
}

export interface FullApplicationResponse_response_components_questions_AnswerRanking_rankings {
  __typename: "RankItem";
  order: number;
  value: string;
}

export interface FullApplicationResponse_response_components_questions_AnswerRanking {
  __typename: "AnswerRanking";
  questionId: string;
  rankings: FullApplicationResponse_response_components_questions_AnswerRanking_rankings[];
}

export interface FullApplicationResponse_response_components_questions_AnswerShortText {
  __typename: "AnswerShortText";
  questionId: string;
  shortText: string;
}

export interface FullApplicationResponse_response_components_questions_AnswerLongText {
  __typename: "AnswerLongText";
  questionId: string;
  longText: string;
}

export interface FullApplicationResponse_response_components_questions_AnswerYesOrNo {
  __typename: "AnswerYesOrNo";
  questionId: string;
  yesOrNo: boolean;
}

export interface FullApplicationResponse_response_components_questions_AnswerDate {
  __typename: "AnswerDate";
  questionId: string;
  date: any | null;
}

export type FullApplicationResponse_response_components_questions = FullApplicationResponse_response_components_questions_AnswerMultiChoice | FullApplicationResponse_response_components_questions_AnswerCheckboxes | FullApplicationResponse_response_components_questions_AnswerRanking | FullApplicationResponse_response_components_questions_AnswerShortText | FullApplicationResponse_response_components_questions_AnswerLongText | FullApplicationResponse_response_components_questions_AnswerYesOrNo | FullApplicationResponse_response_components_questions_AnswerDate;

export interface FullApplicationResponse_response_components {
  __typename: "FormComponentAnswer";
  componentId: string;
  redacted: boolean | null;
  sensitiveInformationConsent: boolean | null;
  questions: FullApplicationResponse_response_components_questions[];
}

export interface FullApplicationResponse_response {
  __typename: "FormAnswer";
  components: FullApplicationResponse_response_components[];
}

export interface FullApplicationResponse_creator_verifiedRelation {
  __typename: "VerifiedRelation";
  isVerifiedRelation: boolean;
  customerId: string | null;
}

export interface FullApplicationResponse_creator {
  __typename: "ApplicationCreator";
  id: string;
  name: string;
  source: UserSource;
  verifiedRelation: FullApplicationResponse_creator_verifiedRelation;
  orgId: string;
}

export interface FullApplicationResponse_subject {
  __typename: "SubjectCoSigner";
  userId: string | null;
  customerId: string | null;
  name: string;
}

export interface FullApplicationResponse_lockHolder {
  __typename: "User";
  id: string;
  firstName: string | null;
}

export interface FullApplicationResponse_schoolUnit {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
}

export interface FullApplicationResponse {
  __typename: "ApplicationResponse";
  id: string;
  status: ApplicationResponseStatus;
  modified: any;
  seenByOrg: any | null;
  language: ISO6391Language;
  lastAction: FullApplicationResponse_lastAction;
  actions: FullApplicationResponse_actions[];
  signatures: FullApplicationResponse_signatures[];
  feedbackSignatures: FullApplicationResponse_feedbackSignatures[];
  response: FullApplicationResponse_response | null;
  creator: FullApplicationResponse_creator;
  subject: FullApplicationResponse_subject;
  lockHolder: FullApplicationResponse_lockHolder | null;
  feedbackId: string | null;
  feedbackDescription: string | null;
  lockExpire: any | null;
  schoolUnit: FullApplicationResponse_schoolUnit | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StandardName
// ====================================================

export interface StandardName {
  __typename: "User";
  name: string | null;
  firstName: string | null;
  lastName: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FullPublication
// ====================================================

export interface FullPublication_form_componentData_components_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullPublication_form_componentData_components_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullPublication_form_componentData_components_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface FullPublication_form_componentData_components_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullPublication_form_componentData_components_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullPublication_form_componentData_components_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullPublication_form_componentData_components_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: FullPublication_form_componentData_components_questions_FormQuestionRanking_options[];
}

export interface FullPublication_form_componentData_components_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullPublication_form_componentData_components_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullPublication_form_componentData_components_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface FullPublication_form_componentData_components_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullPublication_form_componentData_components_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullPublication_form_componentData_components_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullPublication_form_componentData_components_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: FullPublication_form_componentData_components_questions_FormQuestionCheckboxes_options[];
}

export interface FullPublication_form_componentData_components_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullPublication_form_componentData_components_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullPublication_form_componentData_components_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullPublication_form_componentData_components_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: FullPublication_form_componentData_components_questions_FormQuestionMultiChoice_options[];
}

export type FullPublication_form_componentData_components_questions = FullPublication_form_componentData_components_questions_FormQuestionYesNo | FullPublication_form_componentData_components_questions_FormQuestionRanking | FullPublication_form_componentData_components_questions_FormQuestionShortText | FullPublication_form_componentData_components_questions_FormQuestionCheckboxes | FullPublication_form_componentData_components_questions_FormQuestionMultiChoice;

export interface FullPublication_form_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface FullPublication_form_componentData_components {
  __typename: "FormComponent";
  id: string;
  applicationFeedbackId: string | null;
  language: ISO6391Language | null;
  title: string;
  description: string | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: FullPublication_form_componentData_components_questions[];
  templateId: string | null;
  attachments: FullPublication_form_componentData_components_attachments[] | null;
}

export interface FullPublication_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullPublication_form_componentData_predicateComponents_questions_FormQuestionYesNo {
  __typename: "FormQuestionYesNo" | "FormQuestionDate" | "FormQuestionLongText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullPublication_form_componentData_predicateComponents_questions_FormQuestionYesNo_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
}

export interface FullPublication_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullPublication_form_componentData_predicateComponents_questions_FormQuestionRanking_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullPublication_form_componentData_predicateComponents_questions_FormQuestionRanking {
  __typename: "FormQuestionRanking";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullPublication_form_componentData_predicateComponents_questions_FormQuestionRanking_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  minSelectedOptions: number | null;
  maxSelectedOptions: number | null;
  options: FullPublication_form_componentData_predicateComponents_questions_FormQuestionRanking_options[];
}

export interface FullPublication_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullPublication_form_componentData_predicateComponents_questions_FormQuestionShortText {
  __typename: "FormQuestionShortText";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullPublication_form_componentData_predicateComponents_questions_FormQuestionShortText_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  validationType: ShortTextValidationType | null;
}

export interface FullPublication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullPublication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullPublication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes {
  __typename: "FormQuestionCheckboxes";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullPublication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  options: FullPublication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes_options[];
}

export interface FullPublication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates {
  __typename: "FormQuestionPredicate";
  predicate: string;
  componentId: string;
}

export interface FullPublication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options {
  __typename: "FormQuestionOptions";
  id: string;
  label: string;
  externalId: string | null;
}

export interface FullPublication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice {
  __typename: "FormQuestionMultiChoice";
  id: string;
  templateQuestionId: string | null;
  question: string;
  shortName: string | null;
  predicates: FullPublication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_predicates[] | null;
  type: FormQuestionType;
  compulsory: boolean;
  dropdownView: boolean | null;
  options: FullPublication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice_options[];
}

export type FullPublication_form_componentData_predicateComponents_questions = FullPublication_form_componentData_predicateComponents_questions_FormQuestionYesNo | FullPublication_form_componentData_predicateComponents_questions_FormQuestionRanking | FullPublication_form_componentData_predicateComponents_questions_FormQuestionShortText | FullPublication_form_componentData_predicateComponents_questions_FormQuestionCheckboxes | FullPublication_form_componentData_predicateComponents_questions_FormQuestionMultiChoice;

export interface FullPublication_form_componentData_predicateComponents {
  __typename: "FormPredicateComponent";
  id: string;
  language: ISO6391Language | null;
  sensitive: boolean;
  permission: FormComponentPermission;
  questions: FullPublication_form_componentData_predicateComponents_questions[];
}

export interface FullPublication_form_componentData {
  __typename: "ComponentData";
  components: FullPublication_form_componentData_components[];
  predicateComponents: FullPublication_form_componentData_predicateComponents[] | null;
}

export interface FullPublication_form_owner {
  __typename: "Organisation";
  id: string;
  displayName: string;
}

export interface FullPublication_form {
  __typename: "Form";
  id: string;
  type: FormType;
  name: string;
  description: string | null;
  language: ISO6391Language;
  componentData: FullPublication_form_componentData;
  owner: FullPublication_form_owner;
}

export interface FullPublication_recipients_groups_edges {
  __typename: "PublicationGroupStudentGroup";
  id: string;
  groupId: string;
  customerId: string;
  schoolYearFilter: number[] | null;
  type: PublicationGroupType;
  displayName: string;
  removed: boolean;
}

export interface FullPublication_recipients_groups {
  __typename: "PublicationGroupConnection";
  edges: FullPublication_recipients_groups_edges[];
}

export interface FullPublication_recipients {
  __typename: "PublicationRecipientList";
  groups: FullPublication_recipients_groups;
}

export interface FullPublication {
  __typename: "Publication";
  id: string;
  form: FullPublication_form;
  recipients: FullPublication_recipients;
  name: string;
  validFrom: any;
  validTo: any;
  sendDate: any;
  sent: boolean;
  singleGuardian: boolean;
  childResponse: boolean;
  skipEmails: boolean;
  tags: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SchoolUnits
// ====================================================

export interface SchoolUnits_classes {
  __typename: "StudentGroup";
  id: string;
  customerId: string;
  schoolType: Code_SchoolType | null;
  displayName: string;
  memberCount: number;
  groupType: Code_GroupType | null;
}

export interface SchoolUnits {
  __typename: "SchoolUnit";
  id: string;
  customerId: string;
  displayName: string;
  schoolTypes: Code_SchoolType[] | null;
  classes: SchoolUnits_classes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FullTranslation
// ====================================================

export interface FullTranslation_componentData_predicateComponents_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface FullTranslation_componentData_predicateComponents_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: FullTranslation_componentData_predicateComponents_questions_options[] | null;
}

export interface FullTranslation_componentData_predicateComponents {
  __typename: "TranslatePredicateComponent";
  id: string;
  language: ISO6391Language | null;
  questions: FullTranslation_componentData_predicateComponents_questions[];
}

export interface FullTranslation_componentData_components_questions_options {
  __typename: "TranslateFormQuestionOptions";
  id: string;
  label: string;
}

export interface FullTranslation_componentData_components_questions {
  __typename: "FormTranslateQuestion";
  id: string;
  question: string;
  shortName: string | null;
  options: FullTranslation_componentData_components_questions_options[] | null;
}

export interface FullTranslation_componentData_components_attachments {
  __typename: "ComponentAttachment";
  name: string;
  key: string;
  url: string;
}

export interface FullTranslation_componentData_components {
  __typename: "TranslateComponent";
  id: string;
  language: ISO6391Language | null;
  title: string;
  description: string;
  questions: FullTranslation_componentData_components_questions[];
  attachments: FullTranslation_componentData_components_attachments[];
}

export interface FullTranslation_componentData {
  __typename: "TranslationComponentData";
  predicateComponents: FullTranslation_componentData_predicateComponents[] | null;
  components: FullTranslation_componentData_components[];
}

export interface FullTranslation {
  __typename: "TranslationForm";
  formId: string;
  name: string;
  description: string;
  language: ISO6391Language;
  componentData: FullTranslation_componentData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ChildItem
// ====================================================

export interface ChildItem_publicationRecipients_form {
  __typename: "Form";
  id: string;
  name: string;
  language: ISO6391Language;
}

export interface ChildItem_publicationRecipients_responses_signatures_educloudUser {
  __typename: "User";
  id: string;
  customerId: string | null;
  name: string | null;
}

export interface ChildItem_publicationRecipients_responses_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  educloudUser: ChildItem_publicationRecipients_responses_signatures_educloudUser | null;
  customerId: string | null;
  educloudUserId: string | null;
}

export interface ChildItem_publicationRecipients_responses {
  __typename: "PublicationResponse";
  id: string;
  created: any;
  status: PublicationResponseStatus;
  signatures: ChildItem_publicationRecipients_responses_signatures[] | null;
}

export interface ChildItem_publicationRecipients {
  __typename: "PublicationRecipient";
  id: string;
  created: any;
  form: ChildItem_publicationRecipients_form;
  responses: ChildItem_publicationRecipients_responses[];
}

export interface ChildItem_applicationResponses_application_form {
  __typename: "Form";
  id: string;
  name: string;
}

export interface ChildItem_applicationResponses_application {
  __typename: "Application";
  id: string;
  name: string;
  form: ChildItem_applicationResponses_application_form;
}

export interface ChildItem_applicationResponses_creator {
  __typename: "ApplicationCreator";
  id: string;
  name: string;
}

export interface ChildItem_applicationResponses_subject {
  __typename: "SubjectCoSigner";
  userId: string | null;
  name: string;
}

export interface ChildItem_applicationResponses {
  __typename: "ApplicationResponse";
  id: string;
  status: ApplicationResponseStatus;
  created: any;
  application: ChildItem_applicationResponses_application;
  creator: ChildItem_applicationResponses_creator;
  subject: ChildItem_applicationResponses_subject;
}

export interface ChildItem {
  __typename: "User";
  id: string;
  name: string | null;
  publicationRecipients: ChildItem_publicationRecipients[] | null;
  applicationResponses: ChildItem_applicationResponses[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ChildItemRecipient
// ====================================================

export interface ChildItemRecipient_form {
  __typename: "Form";
  id: string;
  name: string;
  language: ISO6391Language;
}

export interface ChildItemRecipient_responses_signatures_educloudUser {
  __typename: "User";
  id: string;
  customerId: string | null;
  name: string | null;
}

export interface ChildItemRecipient_responses_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  educloudUser: ChildItemRecipient_responses_signatures_educloudUser | null;
  customerId: string | null;
  educloudUserId: string | null;
}

export interface ChildItemRecipient_responses {
  __typename: "PublicationResponse";
  id: string;
  created: any;
  status: PublicationResponseStatus;
  signatures: ChildItemRecipient_responses_signatures[] | null;
}

export interface ChildItemRecipient {
  __typename: "PublicationRecipient";
  id: string;
  created: any;
  form: ChildItemRecipient_form;
  responses: ChildItemRecipient_responses[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ChildItemPublicationRecipientResponse
// ====================================================

export interface ChildItemPublicationRecipientResponse_signatures_educloudUser {
  __typename: "User";
  id: string;
  customerId: string | null;
  name: string | null;
}

export interface ChildItemPublicationRecipientResponse_signatures {
  __typename: "PublicationResponseSignature";
  signerId: string;
  educloudUser: ChildItemPublicationRecipientResponse_signatures_educloudUser | null;
  customerId: string | null;
  educloudUserId: string | null;
}

export interface ChildItemPublicationRecipientResponse {
  __typename: "PublicationResponse";
  id: string;
  created: any;
  status: PublicationResponseStatus;
  signatures: ChildItemPublicationRecipientResponse_signatures[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AddressSource {
  educloud = "educloud",
  skolid = "skolid",
}

export enum ApplicationResponseAction {
  ack = "ack",
  assign = "assign",
  complement = "complement",
  create = "create",
  no_feedback = "no_feedback",
  reject_input = "reject_input",
  report_error = "report_error",
  retry_automatic_action = "retry_automatic_action",
  send_feedback = "send_feedback",
  set_schoolunit = "set_schoolunit",
  sign = "sign",
  sign_feedback = "sign_feedback",
  unlock = "unlock",
  withdraw = "withdraw",
}

export enum ApplicationResponseStatus {
  awaiting_feedback_signature = "awaiting_feedback_signature",
  awaiting_signature = "awaiting_signature",
  closed = "closed",
  editable = "editable",
  feedback_respondable = "feedback_respondable",
  resolved = "resolved",
  sent_to_org = "sent_to_org",
  under_process = "under_process",
}

export enum Code_GroupType {
  Avdelning = "Avdelning",
  Klass = "Klass",
  Mentor = "Mentor",
  Ovrigt = "Ovrigt",
  Personalgrupp = "Personalgrupp",
  Provgrupp = "Provgrupp",
  Schema = "Schema",
  Undervisning = "Undervisning",
}

export enum Code_SchoolType {
  ABU = "ABU",
  AU = "AU",
  FHS = "FHS",
  FKLASS = "FKLASS",
  FS = "FS",
  FTH = "FTH",
  GR = "GR",
  GRS = "GRS",
  GY = "GY",
  GYS = "GYS",
  HS = "HS",
  KKU = "KKU",
  KU = "KU",
  OPPFTH = "OPPFTH",
  SAM = "SAM",
  SARVUX = "SARVUX",
  SARVUXGR = "SARVUXGR",
  SARVUXGY = "SARVUXGY",
  SFI = "SFI",
  SP = "SP",
  STF = "STF",
  TR = "TR",
  VUX = "VUX",
  VUXGR = "VUXGR",
  VUXGY = "VUXGY",
  VUXSARGR = "VUXSARGR",
  VUXSARGY = "VUXSARGY",
  VUXSARTR = "VUXSARTR",
  VUXSFI = "VUXSFI",
  YH = "YH",
}

export enum EmailSource {
  db_setting = "db_setting",
  educloud = "educloud",
  idp = "idp",
  skolid = "skolid",
}

export enum EmailTemplateSubstitutionsRole {
  home = "home",
  work = "work",
}

export enum EmailTemplateType {
  applicationFeedbackAvailable = "applicationFeedbackAvailable",
  applicationStatusChanged = "applicationStatusChanged",
  childRevokedPublication = "childRevokedPublication",
  newApplicationAnswerNotificationTemplate = "newApplicationAnswerNotificationTemplate",
  newChildPublication = "newChildPublication",
  newChildPublicationWithMessage = "newChildPublicationWithMessage",
  newPublication = "newPublication",
  newPublicationResponse = "newPublicationResponse",
  newPublicationWithMessage = "newPublicationWithMessage",
  publicationChildReminder = "publicationChildReminder",
  publicationReminder = "publicationReminder",
  revokedPublication = "revokedPublication",
  updatedConsentNotification = "updatedConsentNotification",
}

export enum EmploymentRole {
  other_educational_staff = "other_educational_staff",
  other_staff = "other_staff",
  preschool_principal = "preschool_principal",
  preschool_teacher = "preschool_teacher",
  principal = "principal",
  teacher = "teacher",
}

export enum FormComponentPermission {
  read_base = "read_base",
  read_food = "read_food",
  read_it = "read_it",
  read_language = "read_language",
  read_medical = "read_medical",
  read_photo = "read_photo",
}

export enum FormQuestionType {
  checkboxes = "checkboxes",
  date = "date",
  longText = "longText",
  multiChoice = "multiChoice",
  ranking = "ranking",
  shortText = "shortText",
  yesOrNo = "yesOrNo",
}

export enum FormSubType {
  general = "general",
  outMigration = "outMigration",
}

export enum FormType {
  application = "application",
  publication = "publication",
}

export enum ISO3166Country {
  DE = "DE",
  DK = "DK",
  NO = "NO",
  SE = "SE",
}

export enum ISO6391Language {
  da = "da",
  de = "de",
  en = "en",
  nb = "nb",
  nn = "nn",
  sv = "sv",
}

export enum PermissionSource {
  ABSTRACTION = "ABSTRACTION",
  DIRECT = "DIRECT",
  EMPLOYMENT = "EMPLOYMENT",
  HIERARCHY = "HIERARCHY",
  INHERIT = "INHERIT",
}

export enum PermissionType {
  admin = "admin",
  application_answers = "application_answers",
  create = "create",
  manage_application = "manage_application",
  read = "read",
  read_all = "read_all",
  read_application_answers = "read_application_answers",
  read_base = "read_base",
  read_food = "read_food",
  read_it = "read_it",
  read_language = "read_language",
  read_medical = "read_medical",
  read_photo = "read_photo",
  send = "send",
  write_publication_response = "write_publication_response",
}

export enum PublicationGroupType {
  StudentGroup = "StudentGroup",
}

export enum PublicationResponseSignRole {
  admin = "admin",
  guardian = "guardian",
  self = "self",
}

export enum PublicationResponseStatus {
  not_signed = "not_signed",
  partially_signed = "partially_signed",
  valid = "valid",
}

export enum RecordHistoryType {
  classResponses = "classResponses",
  form = "form",
}

export enum RelationType {
  foster_child = "foster_child",
  foster_parent = "foster_parent",
  guardian = "guardian",
  other_adult = "other_adult",
  other_adult_of = "other_adult_of",
  ward = "ward",
}

export enum ResourceType {
  organisation = "organisation",
  schoolunit = "schoolunit",
}

export enum ReturnCode {
  error = "error",
  ok = "ok",
}

export enum SchoolUnitOrganisationType {
  Skola = "Skola",
  Skolenhet = "Skolenhet",
}

export enum ShortTextValidationType {
  email = "email",
  nationalId = "nationalId",
  phone = "phone",
}

export enum UserSource {
  db = "db",
  educloud = "educloud",
  skolid = "skolid",
}

export interface ActionInput {
  responseId: string;
  previousEventId: string;
  action: ApplicationResponseAction;
  comment?: string | null;
  errorCode?: string | null;
  feedbackId?: string | null;
  feedbackDescription?: string | null;
  schoolunitId?: string | null;
  customerId?: string | null;
}

export interface AdminSignLogoUploadInput {
  orgId: string;
  filename: string;
  contentLength: number;
  contentType: string;
}

export interface ClassResponsesHistoryRecordDataInput {
  customerId: string;
  schoolUnitId: string;
  studentGroupId: string;
}

export interface ClassResponsesHistoryRecordInput {
  isFavorite?: boolean | null;
  label: string;
  type: RecordHistoryType;
  data: ClassResponsesHistoryRecordDataInput;
}

export interface ComponentAnswerInput {
  componentId: string;
  questions: QuestionAnswerInput[];
  sensitiveInformationConsent?: boolean | null;
  redacted?: boolean | null;
}

export interface ComponentAttachmentInput {
  name: string;
  key: string;
  url?: string | null;
}

export interface ComponentDataInput {
  components: ComponentInput[];
  predicateComponents?: FormPredicateComponentInput[] | null;
}

export interface ComponentInput {
  id: string;
  language?: ISO6391Language | null;
  title: string;
  description?: string | null;
  templateId?: string | null;
  questions: FormQuestionInput[];
  sensitive: boolean;
  permission: FormComponentPermission;
  attachments?: ComponentAttachmentInput[] | null;
  applicationFeedbackId?: string | null;
}

export interface ComponentTemplateInput {
  id: string;
  templateId?: string | null;
  language?: ISO6391Language | null;
  title: string;
  description?: string | null;
  questions: FormQuestionInput[];
  sensitive: boolean;
  permission: FormComponentPermission;
  attachments?: ComponentAttachmentInput[] | null;
}

export interface ComponentTemplateTranslationInput {
  id: string;
  title: string;
  description: string;
  questions: FormTranslationQuestionInput[];
  attachments: ComponentAttachmentInput[];
}

export interface Context {
  language: string;
  org?: string | null;
}

export interface CreateApplicationInput {
  formId: string;
  name: string;
  singleGuardianSend: boolean;
  singleGuardianAcceptOffer: boolean;
  schoolunitSpecific: boolean;
  subscribable: boolean;
  sendDate: any;
  timezoneName: string;
}

export interface CreatePublicationInput {
  formId: string;
  name: string;
  recipients: PublicationRecipientListInput;
  validFrom: any;
  validTo: any;
  sendDate: any;
  timeZoneName: string;
  singleGuardian: boolean;
  childResponse: boolean;
  skipEmails: boolean;
  tags?: string[] | null;
}

export interface EmploymentPermissionInput {
  employmentType: EmploymentRole;
  roles: PermissionType[];
}

export interface FormAccessInput {
  public: boolean;
  organisation: boolean;
  schoolUnits: IdCustomerIdInput[];
}

export interface FormAnswerInput {
  components: ComponentAnswerInput[];
}

export interface FormHistoryRecordDataInput {
  formId: string;
}

export interface FormHistoryRecordInput {
  isFavorite?: boolean | null;
  label: string;
  type: RecordHistoryType;
  data: FormHistoryRecordDataInput;
}

export interface FormPredicateComponentInput {
  id: string;
  language?: ISO6391Language | null;
  questions: FormQuestionInput[];
  permission: FormComponentPermission;
  sensitive: boolean;
}

export interface FormQuestionInput {
  id: string;
  templateQuestionId?: string | null;
  type: FormQuestionType;
  validationType?: ShortTextValidationType | null;
  question: string;
  shortName?: string | null;
  compulsory: boolean;
  options?: FormQuestionOptionsInput[] | null;
  predicates?: FormQuestionPredicateInput[] | null;
  minSelectedOptions?: number | null;
  maxSelectedOptions?: number | null;
  dropdownView?: boolean | null;
}

export interface FormQuestionOptionsInput {
  id: string;
  label: string;
  externalId?: string | null;
}

export interface FormQuestionPredicateInput {
  predicate: string;
  componentId: string;
}

export interface FormTranslationQuestionInput {
  id: string;
  templateQuestionId?: string | null;
  question: string;
  shortName?: string | null;
  options?: FormTranslationQuestionOptionsInput[] | null;
}

export interface FormTranslationQuestionOptionsInput {
  id: string;
  label: string;
}

export interface IdCustomerIdInput {
  id: string;
  customerId: string;
}

export interface MultiActionInput {
  responses: ResIdPrevEvent[];
  action: ApplicationResponseAction;
  comment?: string | null;
  feedbackId?: string | null;
  feedbackDescription?: string | null;
  schoolunitId?: string | null;
  customerId?: string | null;
}

export interface PublicationRecipientListInput {
  users?: PublicationRecipientUserListInput[] | null;
  studentGroups?: PublicationRecipientStudentGroupListInput[] | null;
}

export interface PublicationRecipientStudentGroupListInput {
  id: string;
  customerId: string;
  active: boolean;
  schoolYearFilter?: number[] | null;
}

export interface PublicationRecipientUserListInput {
  userId: string;
  customerId: string;
  manuallyAdded: boolean;
  excludedFromPublication: boolean;
}

export interface QuestionAnswerInput {
  questionId: string;
  yesOrNo?: boolean | null;
  multiChoice?: string | null;
  checkboxes?: string[] | null;
  rankings?: RankingInput[] | null;
  shortText?: string | null;
  longText?: string | null;
  date?: any | null;
}

export interface RankingInput {
  value: string;
  order: number;
}

export interface ResIdPrevEvent {
  responseId: string;
  previousEventId: string;
}

export interface ResourcePermission {
  resourceId: string;
  resourceTypeId: ResourceType;
  customerId?: string | null;
  roleIds: PermissionType[];
}

export interface SaveApplicationInput {
  id: string;
  sendDate?: any | null;
  singleGuardianSend: boolean;
  singleGuardianAcceptOffer: boolean;
  schoolunitSpecific: boolean;
  subscribable: boolean;
  timezoneName: string;
  closed: boolean;
}

export interface SaveFormInput {
  id?: string | null;
  type: FormType;
  subType: FormSubType;
  title: string;
  description: string;
  componentData: ComponentDataInput;
  language: ISO6391Language;
  tags?: string[] | null;
  access: FormAccessInput;
}

export interface SavePublicationInput {
  id: string;
  recipients: PublicationRecipientListInput;
  sendDate?: any | null;
  validFrom?: any | null;
  validTo?: any | null;
  timeZoneName?: string | null;
  singleGuardian?: boolean | null;
  childResponse?: boolean | null;
  skipEmails?: boolean | null;
  tags?: string[] | null;
}

export interface SchoolUnitsFilter {
  permissionType?: PermissionType | null;
  organisationType?: SchoolUnitOrganisationType | null;
  ignorePermission?: boolean | null;
}

export interface SetClientPermissionInput {
  clientId: string;
  permissions: ResourcePermission[];
}

export interface SetUserPermissionInput {
  userid: string;
  source: UserSource;
  customerId?: string | null;
  permissions: ResourcePermission[];
}

export interface SignFormUploadInput {
  filename: string;
  contentLength: number;
  contentType: string;
}

export interface TranslationComponentDataInput {
  components: TranslationComponentInput[];
  predicateComponents?: TranslationPredicateComponentInput[] | null;
}

export interface TranslationComponentInput {
  id: string;
  language?: ISO6391Language | null;
  title: string;
  description: string;
  questions: FormTranslationQuestionInput[];
  templateId?: string | null;
  attachments: ComponentAttachmentInput[];
}

export interface TranslationPredicateComponentInput {
  id: string;
  language?: ISO6391Language | null;
  questions: FormTranslationQuestionInput[];
}

export interface UpdateFormTranslationInput {
  formId: string;
  language: ISO6391Language;
  name: string;
  description: string;
  componentData: TranslationComponentDataInput;
}

export interface UpdateOrganisationSettingsInput {
  defaultLanguage?: ISO6391Language | null;
  autoUpdateRecipients?: boolean | null;
  employmentPermissions?: EmploymentPermissionInput[] | null;
  emailSuffixWork?: string | null;
  emailSuffixHome?: string | null;
}

export interface UserIdInput {
  id: string;
  source: UserSource;
  customerId?: string | null;
}

export interface UserSettingsInput {
  workEmail?: string | null;
  personalEmail?: string | null;
  updatedConsentNotification: boolean;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

export type DateTimeScalar<T = any> = T;

export type DateStringScalar<T = any> = T;

export enum ErrorCodes {
  GENERIC = "GENERIC",
  ACCESS = "ACCESS",
  NO_DATA = "NO_DATA",
  NOT_FOUND = "NOT_FOUND",
  INPUT_ERROR = "INPUT_ERROR",
}

export interface Response {
  code?: ReturnCode| null;
  message?: string| null;
}

export interface Name {
  formatted?: string| null;
  familyName?: string| null;
  givenName?: string| null;
  middleName?: string| null;
  honorificPrefix?: string| null;
  honorificSuffix?: string| null;
}

export interface Email {
  value?: string| null;
  type?: string| null;
  primary?: boolean| null;
}

export interface PhoneNumber {
  value?: string| null;
  type?: string| null;
}

export interface Address {
  type?: string| null;
  streetAddress?: string| null;
  locality?: string| null;
  region?: string| null;
  postalCode?: string| null;
  country?: string| null;
}

export interface Relation {
  type: RelationType;
  node?: User| null;
}

export interface RelationConnection {
  edges: ((Relation)[]) ;
  nodes: ((User| null)[]) ;
}

export interface UserSettings {
  id: string;
  workEmail?: string| null;
  personalEmail?: string| null;
  updatedConsentNotification: boolean;
}

export interface Enrolment {
  schoolUnit?: SchoolUnit| null;
  schoolYear?: number| null;
  schoolType: Code_SchoolType;
  startDate: DateStringScalar;
  endDate?: DateStringScalar| null;
  educationCode?: string| null;
  specification?: string| null;
}

export interface Placement {
  schoolUnit?: SchoolUnit| null;
  schoolType?: Code_SchoolType| null;
  startDate?: DateStringScalar| null;
  endDate?: DateStringScalar| null;
  child?: User| null;
}

export interface StudentGroupMembership {
  startDate?: DateStringScalar| null;
  endDate?: DateStringScalar| null;
  studentGroup?: StudentGroup| null;
  user?: User| null;
}

export interface Employment {
  id: string;
  signature?: string| null;
  employmentPercent?: number| null;
  hoursPerYear?: number| null;
  startDate?: DateStringScalar| null;
  endDate?: DateStringScalar| null;
  employmentRole: EmploymentRole;
  user?: User| null;
  employedAt?: SchoolUnit| null;
}

export interface UserEmail {
  value: string;
  type: string;
  weight: number;
  source: EmailSource;
  preferredHome: boolean;
  preferredWork: boolean;
}

export interface UserAddress {
  source: AddressSource;
  type?: string| null;
  streetAddress?: string| null;
  locality?: string| null;
  region?: string| null;
  postalCode?: string| null;
  country?: string| null;
}

export interface Actor {
  id: string;
  name?: string| null;
  permissions: SubjectPermissions;
  availableOrganisations: ((Organisation)[]) ;
}
export interface ActorPermissionsArgs {
  context: Context;
}

export interface IdCustomerId {
  id: string;
  customerId: string;
}

export interface SkolidOrganization {
  id: string;
  name: string;
  country: ISO3166Country;
}

export interface User {
  id: string;
  source: UserSource;
  customerId?: string| null;
  idp?: string| null;
  idpSub?: string| null;
  name?: string| null;
  firstName?: string| null;
  lastName?: string| null;
  emails?: ((UserEmail)[]) | null;
  addresses?: ((UserAddress)[]) | null;
  birthDate?: DateStringScalar| null;
  nationalId?: string| null;
  country?: ISO3166Country| null;
  language?: string| null;
  connectedUsers?: ((User)[]) | null;
  permissions: SubjectPermissions;
  availableOrganisations: ((Organisation)[]) ;
  publications?: ((Publication)[]) | null;
  publicationRecipients?: ((PublicationRecipient)[]) | null;
  responses: ((PublicationResponse)[]) ;
  applicationResponses: ((ApplicationResponse)[]) ;
  history: ((HistoryRecord)[]) ;
  favorites: ((HistoryRecord)[]) ;
  relations?: RelationConnection| null;
  educloudUserIds: ((IdCustomerId)[]) ;
  settings: UserSettings;
  enrolments?: ((Enrolment)[]) | null;
  placements?: ((Placement)[]) | null;
  studentGroupMemberships?: ((StudentGroupMembership)[]) | null;
  employments?: ((Employment)[]) | null;
  skolidOrganization?: SkolidOrganization| null;
}
export interface UserConnectedUsersArgs {
  filter: UserSource| null;
}
export interface UserPermissionsArgs {
  context: Context;
}
export interface UserPublicationRecipientsArgs {
  publicationId: string| null;
  publicationTag: string| null;
}
export interface UserHistoryArgs {
  context: Context;
}
export interface UserFavoritesArgs {
  context: Context;
}
export interface UserRelationsArgs {
  relationTypes: ((RelationType| null)[]) | null;
  org: string| null;
  uniquePeople: boolean| null;
}
export interface UserStudentGroupMembershipsArgs {
  type: Code_GroupType| null;
  types: ((Code_GroupType)[]) | null;
  onlyDateValid: boolean| null;
}

export interface UserIdInput {
  id: string;
  source: UserSource;
  customerId?: string| null;
}

export interface UsersIdInput {
  ids: ((string)[]) ;
  source: UserSource;
  customerId: string;
}

export interface Client {
  id: string;
  name?: string| null;
  clientId: string;
  permissions: SubjectPermissions;
  availableOrganisations: ((Organisation)[]) ;
}
export interface ClientPermissionsArgs {
  context: Context;
}

export interface Translations {
  sv?: string| null;
  en?: string| null;
}

export interface FormQuestion {
  id: string;
  templateQuestionId?: string| null;
  type: FormQuestionType;
  question: string;
  shortName?: string| null;
  compulsory: boolean;
  predicates?: ((FormQuestionPredicate)[]) | null;
}

export interface FormQuestionCheckboxes {
  id: string;
  templateQuestionId?: string| null;
  type: FormQuestionType;
  question: string;
  shortName?: string| null;
  options: ((FormQuestionOptions)[]) ;
  compulsory: boolean;
  predicates?: ((FormQuestionPredicate)[]) | null;
}

export interface FormQuestionMultiChoice {
  id: string;
  templateQuestionId?: string| null;
  type: FormQuestionType;
  question: string;
  shortName?: string| null;
  options: ((FormQuestionOptions)[]) ;
  compulsory: boolean;
  dropdownView?: boolean| null;
  predicates?: ((FormQuestionPredicate)[]) | null;
}

export interface FormQuestionRanking {
  id: string;
  templateQuestionId?: string| null;
  type: FormQuestionType;
  shortName?: string| null;
  question: string;
  options: ((FormQuestionOptions)[]) ;
  compulsory: boolean;
  minSelectedOptions?: number| null;
  maxSelectedOptions?: number| null;
  predicates?: ((FormQuestionPredicate)[]) | null;
}

export type FormQuestionWithOptionsUnion = FormQuestionCheckboxes | FormQuestionMultiChoice | FormQuestionRanking;

export interface FormQuestionYesNo {
  id: string;
  templateQuestionId?: string| null;
  type: FormQuestionType;
  shortName?: string| null;
  question: string;
  compulsory: boolean;
  predicates?: ((FormQuestionPredicate)[]) | null;
}

export interface FormQuestionDate {
  id: string;
  templateQuestionId?: string| null;
  type: FormQuestionType;
  shortName?: string| null;
  question: string;
  compulsory: boolean;
  predicates?: ((FormQuestionPredicate)[]) | null;
}

export interface FormQuestionShortText {
  id: string;
  templateQuestionId?: string| null;
  type: FormQuestionType;
  shortName?: string| null;
  question: string;
  compulsory: boolean;
  validationType?: ShortTextValidationType| null;
  predicates?: ((FormQuestionPredicate)[]) | null;
}

export interface FormQuestionLongText {
  id: string;
  templateQuestionId?: string| null;
  type: FormQuestionType;
  shortName?: string| null;
  question: string;
  compulsory: boolean;
  predicates?: ((FormQuestionPredicate)[]) | null;
}

export type FormQuestionWithoutOptionsUnion = FormQuestionYesNo | FormQuestionDate | FormQuestionShortText | FormQuestionLongText;

export interface Answer {
  questionId: string;
}

export interface AnswerYesOrNo {
  questionId: string;
  yesOrNo: boolean;
}

export interface AnswerMultiChoice {
  questionId: string;
  multiChoice: string;
}

export interface AnswerCheckboxes {
  questionId: string;
  checkboxes: ((string)[]) ;
}

export interface RankItem {
  value: string;
  order: number;
}

export interface AnswerRanking {
  questionId: string;
  rankings: ((RankItem)[]) ;
}

export interface AnswerShortText {
  questionId: string;
  shortText: string;
}

export interface AnswerLongText {
  questionId: string;
  longText: string;
}

export interface AnswerDate {
  questionId: string;
  date?: DateStringScalar| null;
}

export type QuestionAnswerUnion = AnswerYesOrNo | AnswerCheckboxes | AnswerMultiChoice | AnswerRanking | AnswerShortText | AnswerLongText | AnswerDate;

export interface RankingInput {
  value: string;
  order: number;
}

export interface QuestionAnswerInput {
  questionId: string;
  yesOrNo?: boolean| null;
  multiChoice?: string| null;
  checkboxes?: ((string)[]) | null;
  rankings?: ((RankingInput)[]) | null;
  shortText?: string| null;
  longText?: string| null;
  date?: DateStringScalar| null;
}

export interface FormComponentAnswer {
  componentId: string;
  redacted?: boolean| null;
  sensitiveInformationConsent?: boolean| null;
  questions: ((QuestionAnswerUnion)[]) ;
}

export interface ComponentAnswerInput {
  componentId: string;
  questions: ((QuestionAnswerInput)[]) ;
  sensitiveInformationConsent?: boolean| null;
  redacted?: boolean| null;
}

export interface FormAnswer {
  components: ((FormComponentAnswer)[]) ;
}

export interface FormAnswerInput {
  components: ((ComponentAnswerInput)[]) ;
}

export interface YesNoResponse {
  questionId: string;
  answer: boolean;
}

export interface FormQuestionOptions {
  id: string;
  label: string;
  externalId?: string| null;
}

export interface FormQuestionOptionsInput {
  id: string;
  label: string;
  externalId?: string| null;
}

export interface FormQuestionPredicate {
  predicate: string;
  componentId: string;
}

export interface FormQuestionPredicateInput {
  predicate: string;
  componentId: string;
}

export interface FormQuestionInput {
  id: string;
  templateQuestionId?: string| null;
  type: FormQuestionType;
  validationType?: ShortTextValidationType| null;
  question: string;
  shortName?: string| null;
  compulsory: boolean;
  options?: ((FormQuestionOptionsInput)[]) | null;
  predicates?: ((FormQuestionPredicateInput)[]) | null;
  minSelectedOptions?: number| null;
  maxSelectedOptions?: number| null;
  dropdownView?: boolean| null;
}

export enum TemplateCategory {
  allergy = "allergy",
  dietaryRestriction = "dietaryRestriction",
  media = "media",
  other = "other",
}

export interface Template {
  id: string;
  category?: TemplateCategory| null;
  language: ISO6391Language;
  componentData: ComponentTemplate;
  translations: ((TemplateTranslation)[]) ;
  published: boolean;
  archived: boolean;
}
export interface TemplateTranslationsArgs {
  filter: TemplateFilter| null;
}

export enum TemplateFilter {
  usable = "usable",
  all = "all",
}

export interface ComponentTemplate {
  id: string;
  title: string;
  description: string;
  questions: ((FormQuestion)[]) ;
  sensitive: boolean;
  permission: FormComponentPermission;
  attachments: ((ComponentAttachment)[]) ;
}

export interface TemplateTranslation {
  id: string;
  language: ISO6391Language;
  published: boolean;
  componentData: TranslationComponentTemplate;
}

export interface TranslationComponentTemplate {
  id: string;
  title: string;
  description: string;
  questions: ((FormTranslateQuestion)[]) ;
  attachments: ((ComponentAttachment)[]) ;
}

export interface ComponentTemplateReference {
  templateId: string;
}

export interface ComponentAttachment {
  name: string;
  key: string;
  url: string;
}

export interface FormComponent {
  id: string;
  language?: ISO6391Language| null;
  title: string;
  description?: string| null;
  questions: ((FormQuestion)[]) ;
  templateId?: string| null;
  sensitive: boolean;
  permission: FormComponentPermission;
  attachments?: ((ComponentAttachment)[]) | null;
  applicationFeedbackId?: string| null;
}

export interface FormPredicateComponent {
  id: string;
  language?: ISO6391Language| null;
  questions: ((FormQuestion)[]) ;
  permission: FormComponentPermission;
  sensitive: boolean;
}

export interface FormPredicateComponentInput {
  id: string;
  language?: ISO6391Language| null;
  questions: ((FormQuestionInput)[]) ;
  permission: FormComponentPermission;
  sensitive: boolean;
}

export interface ComponentAttachmentInput {
  name: string;
  key: string;
  url?: string| null;
}

export interface ComponentInput {
  id: string;
  language?: ISO6391Language| null;
  title: string;
  description?: string| null;
  templateId?: string| null;
  questions: ((FormQuestionInput)[]) ;
  sensitive: boolean;
  permission: FormComponentPermission;
  attachments?: ((ComponentAttachmentInput)[]) | null;
  applicationFeedbackId?: string| null;
}

export interface ComponentDataInput {
  components: ((ComponentInput)[]) ;
  predicateComponents?: ((FormPredicateComponentInput)[]) | null;
}

export interface ComponentData {
  components: ((FormComponent)[]) ;
  predicateComponents?: ((FormPredicateComponent)[]) | null;
}

export interface FormStatistics {
  publications: number;
  total: number;
  sent: number;
  answered: number;
  unanswered: number;
}

export interface Form {
  id: string;
  name: string;
  description?: string| null;
  type: FormType;
  subType: FormSubType;
  archived: boolean;
  locked: boolean;
  componentData: ComponentData;
  creator?: Actor| null;
  owner: Organisation;
  created: DateTimeScalar;
  modified: DateTimeScalar;
  publications: ((Publication)[]) ;
  access: FormAccess;
  language: ISO6391Language;
  translationLanguages: ((ISO6391Language)[]) ;
  translation?: TranslationForm| null;
  translations: ((TranslationForm)[]) ;
  tags: ((string)[]) ;
}
export interface FormTranslationArgs {
  language: ISO6391Language;
}

export interface FormAccess {
  public: boolean;
  organisation: boolean;
  schoolUnits: ((SchoolUnit| null)[]) ;
}

export interface FormTranslateQuestion {
  id: string;
  question: string;
  shortName?: string| null;
  options?: ((TranslateFormQuestionOptions)[]) | null;
}

export interface TranslateFormQuestionOptions {
  id: string;
  label: string;
}

export interface TranslateComponent {
  id: string;
  language?: ISO6391Language| null;
  title: string;
  description: string;
  questions: ((FormTranslateQuestion)[]) ;
  attachments: ((ComponentAttachment)[]) ;
}

export interface TranslatePredicateComponent {
  id: string;
  language?: ISO6391Language| null;
  questions: ((FormTranslateQuestion)[]) ;
}

export interface TranslationComponentData {
  components: ((TranslateComponent)[]) ;
  predicateComponents?: ((TranslatePredicateComponent)[]) | null;
}

export interface TranslationForm {
  formId: string;
  name: string;
  description: string;
  componentData: TranslationComponentData;
  created: DateTimeScalar;
  modified: DateTimeScalar;
  language: ISO6391Language;
}

export interface DataSource {
  customerId: string;
  name: string;
  description: string;
}

export interface OrganisationEmploymentPermission {
  employmentType: EmploymentRole;
  roles: ((PermissionType)[]) ;
}

export interface OrganisationSettings {
  autoUpdateRecipients: boolean;
  employmentPermissions: ((OrganisationEmploymentPermission)[]) ;
  emailSuffixWork: string;
  emailSuffixHome: string;
}

export enum PublicationAccessRoleType {
  send = "send",
  read = "read",
}

export interface SchoolUnitsFilter {
  permissionType?: PermissionType| null;
  organisationType?: SchoolUnitOrganisationType| null;
  ignorePermission?: boolean| null;
}

export interface OrganizationFeatures {
  relocation: boolean;
  pdfArchive: boolean;
}

export interface Organisation {
  id: string;
  displayName: string;
  schoolUnits: ((SchoolUnit)[]) ;
  publications: ((Publication)[]) ;
  applications: ((Application)[]) ;
  forms: ((Form)[]) ;
  sendableForms: ((Form)[]) ;
  sentForms: ((Form)[]) ;
  permissions: OrganisationPermssions;
  myPermissions: SubjectPermissions;
  dataSources: ((DataSource)[]) ;
  skolidOrgId?: string| null;
  settings: OrganisationSettings;
  country: ISO3166Country;
  defaultLanguage: ISO6391Language;
  features: OrganizationFeatures;
}
export interface OrganisationSchoolUnitsArgs {
  filter: SchoolUnitsFilter| null;
}
export interface OrganisationPublicationsArgs {
  accessRole: PublicationAccessRoleType| null;
}
export interface OrganisationApplicationsArgs {
  schoolunitSpecificIfNotOrgPermission: boolean| null;
}
export interface OrganisationFormsArgs {
  filter: FormFilter| null;
}
export interface OrganisationSendableFormsArgs {
  type: FormType;
}

export enum FormFilter {
  available = "available",
  publishable = "publishable",
  archived = "archived",
  all = "all",
}

export interface SchoolUnit {
  id: string;
  customerId: string;
  displayName: string;
  schoolTypes?: ((Code_SchoolType)[]) | null;
  schoolUnitCode?: string| null;
  organisationCode?: string| null;
  municipalityCode?: string| null;
  startDate?: DateTimeScalar| null;
  endDate?: DateTimeScalar| null;
  classes: ((StudentGroup)[]) ;
  organisation: SchoolUnit;
  publications: ((Publication)[]) ;
  organisationType?: SchoolUnitOrganisationType| null;
}

export interface FormsSentToGroupMetadata {
  publications: number;
  numFormsSent: number;
  numCompleteAnswers: number;
}

export interface IdValueDisplayName {
  id: string;
  displayName: string;
}

export enum PublicationFilter {
  sent = "sent",
  valid = "valid",
}

export interface StudentGroup {
  id: string;
  customerId: string;
  sentFormsMetadata: FormsSentToGroupMetadata;
  publications: ((Publication)[]) ;
  publicationRecipients: ((PublicationRecipient)[]) ;
  displayName: string;
  groupType?: Code_GroupType| null;
  schoolType?: Code_SchoolType| null;
  startDate: DateTimeScalar;
  endDate?: DateTimeScalar| null;
  schoolUnit: SchoolUnit;
  members: ((User| null)[]) ;
  memberCount: number;
}
export interface StudentGroupPublicationsArgs {
  filter: PublicationFilter| null;
}
export interface StudentGroupPublicationRecipientsArgs {
  publicationId: string| null;
  publicationTag: string| null;
}

export interface PublicationStatistics {
  total: number;
  groups: number;
  sent: number;
  answered: number;
  unanswered: number;
  notifications: number;
  deliverableNotifications: number;
  undeliverableNotifications: number;
}

export enum NotificationStatus {
  undeliverable = "undeliverable",
  deliverable = "deliverable",
  all = "all",
}

export interface PublicationNotificationUserConnection {
  edges: ((PublicationNotificationUser)[]) ;
  filteredByPermissions: boolean;
}

export interface PublicationNonRespondentUserConnection {
  edges: ((NonRespondent)[]) ;
  filteredByPermissions: boolean;
}

export interface PublicationNotificationUser {
  child?: User| null;
  childGroupIds: ((string)[]) ;
  recipients: ((PublicationNotificationRecipientInfo)[]) ;
}

export interface NonRespondent {
  student?: User| null;
  groupIds: ((string)[]) ;
}

export interface PublicationNotificationRecipientInfo {
  user?: User| null;
  available: ((string)[]) ;
}

export interface Publication {
  id: string;
  name: string;
  form: Form;
  creator?: Actor| null;
  created: DateTimeScalar;
  modified: DateTimeScalar;
  recipients: PublicationRecipientList;
  nonRespondents: PublicationNonRespondentUserConnection;
  notifications: PublicationNotificationUserConnection;
  sendDate: DateTimeScalar;
  lastDryrun?: DateTimeScalar| null;
  lastNotified?: DateTimeScalar| null;
  lastReminderSent?: DateTimeScalar| null;
  sent: boolean;
  stats: PublicationStatistics;
  recipientUpdateChanges: PublicationRecipientListChanges;
  validTo: DateTimeScalar;
  validFrom: DateTimeScalar;
  singleGuardian: boolean;
  childResponse: boolean;
  skipEmails: boolean;
  tags: ((string)[]) ;
  owner: Organisation;
}
export interface PublicationNotificationsArgs {
  filter: NotificationStatus;
}

export interface PublicationRecipientListChanges {
  addedCount: number;
  removedCount: number;
  changedCount: number;
  updated?: DateTimeScalar| null;
  added: ((PublicationUser)[]) ;
  removed: ((PublicationUser)[]) ;
  changed: ((PublicationUser)[]) ;
}

export enum ApplicationResponseSignRole {
  creator = "creator",
  admin = "admin",
  guardian = "guardian",
  self = "self",
  cosigner = "cosigner",
}

export interface PDF {
  content: string;
  contentType: string;
}

export interface PublicationResponse {
  id: string;
  user?: User| null;
  form: Form;
  created: DateTimeScalar;
  modified: DateTimeScalar;
  language: ISO6391Language;
  response?: FormAnswer| null;
  responsePdf?: PDF| null;
  signatures?: ((PublicationResponseSignature)[]) | null;
  publicationRecipient: PublicationRecipient;
  publicationRecipientId: string;
  status: PublicationResponseStatus;
  signRole?: PublicationResponseSignRole| null;
  publication: Publication;
  lockHolder?: User| null;
  lockExpire?: DateTimeScalar| null;
  validIfSigned: boolean;
  modifyPermission: boolean;
}
export interface PublicationResponseResponsePdfArgs {
  lang: ISO6391Language;
}
export interface PublicationResponseValidIfSignedArgs {
  signRole: PublicationResponseSignRole| null;
}

export interface PublicationResponseSignature {
  checksum: string;
  signerId: string;
  signer?: User| null;
  timestamp: DateTimeScalar;
  educloudUserId?: string| null;
  educloudUser?: User| null;
  customerId?: string| null;
}

export interface PublicationUserConnection {
  edges: ((PublicationRecipient)[]) ;
}

export interface PublicationUser {
  userId: string;
  manuallyAdded: boolean;
  excludedFromPublication: boolean;
  groups: ((PublicationGroupUnion)[]) ;
  groupIds: ((string)[]) ;
  user?: User| null;
}

export interface PublicationGroupConnection {
  edges: ((PublicationGroupStudentGroup)[]) ;
}

export type PublicationGroupUnion = PublicationGroupStudentGroup;

export interface PublicationGroupStatistics {
  total: number;
  sent: number;
  unsent: number;
  answered: number;
  unanswered: number;
}

export interface PublicationGroupStudentGroup {
  id: string;
  groupId: string;
  customerId: string;
  type: PublicationGroupType;
  displayName: string;
  schoolYearFilter?: ((number)[]) | null;
  removed: boolean;
  memberCount: number;
  members: ((User| null)[]) ;
  node: StudentGroup;
}

export interface PublicationRecipientList {
  users: PublicationUserConnection;
  groups: PublicationGroupConnection;
}

export interface PublicationRecipientUserListInput {
  userId: string;
  customerId: string;
  manuallyAdded: boolean;
  excludedFromPublication: boolean;
}

export interface PublicationRecipientStudentGroupListInput {
  id: string;
  customerId: string;
  active: boolean;
  schoolYearFilter?: ((number)[]) | null;
}

export interface PublicationRecipientListInput {
  users?: ((PublicationRecipientUserListInput)[]) | null;
  studentGroups?: ((PublicationRecipientStudentGroupListInput)[]) | null;
}

export interface CreatePublicationInput {
  formId: string;
  name: string;
  recipients: PublicationRecipientListInput;
  validFrom: DateStringScalar;
  validTo: DateStringScalar;
  sendDate: DateStringScalar;
  timeZoneName: string;
  singleGuardian: boolean;
  childResponse: boolean;
  skipEmails: boolean;
  tags?: ((string)[]) | null;
}

export interface SavePublicationInput {
  id: string;
  recipients: PublicationRecipientListInput;
  sendDate?: DateStringScalar| null;
  validFrom?: DateStringScalar| null;
  validTo?: DateStringScalar| null;
  timeZoneName?: string| null;
  singleGuardian?: boolean| null;
  childResponse?: boolean| null;
  skipEmails?: boolean| null;
  tags?: ((string)[]) | null;
}

export enum PublicationResponseFilter {
  LastDraftLastValid = "LastDraftLastValid",
  LastDraft = "LastDraft",
  LastValid = "LastValid",
  LastDraftLastPartiallySigned = "LastDraftLastPartiallySigned",
  LastValidLastPartiallySigned = "LastValidLastPartiallySigned",
  LastValidOrElseLastPartiallySigned = "LastValidOrElseLastPartiallySigned",
  LastPartiallySigned = "LastPartiallySigned",
  AllValid = "AllValid",
}

export interface PublicationRecipient {
  id: string;
  user?: User| null;
  form: Form;
  publication: Publication;
  created: DateTimeScalar;
  modified: DateTimeScalar;
  deleted: boolean;
  sent: boolean;
  manuallyAdded: boolean;
  excludedFromPublication: boolean;
  groups: ((PublicationGroupUnion)[]) ;
  groupIds: ((string)[]) ;
  validResponse?: PublicationResponse| null;
  responses: ((PublicationResponse)[]) ;
  copyableResponses: ((PublicationResponse)[]) ;
  modifyPermission: boolean;
}
export interface PublicationRecipientResponsesArgs {
  filter: PublicationResponseFilter;
}

export interface Application {
  id: string;
  name: string;
  form: Form;
  creator?: Actor| null;
  created: DateTimeScalar;
  modified: DateTimeScalar;
  sendDate: DateTimeScalar;
  subscribable: boolean;
  sent: boolean;
  closed: boolean;
  singleGuardianSend: boolean;
  singleGuardianAcceptOffer: boolean;
  schoolunitSpecific: boolean;
  stats: ApplicationStatistics;
  responses: ((ApplicationResponse)[]) ;
  owner: Organisation;
}
export interface ApplicationResponsesArgs {
  statusFilters: ((ApplicationResponseStatus)[]) | null;
}

export interface ApplicationStatistics {
  answered: number;
  open: number;
  closed: number;
}

export interface CreateApplicationInput {
  formId: string;
  name: string;
  singleGuardianSend: boolean;
  singleGuardianAcceptOffer: boolean;
  schoolunitSpecific: boolean;
  subscribable: boolean;
  sendDate: DateStringScalar;
  timezoneName: string;
}

export interface SaveApplicationInput {
  id: string;
  sendDate?: DateStringScalar| null;
  singleGuardianSend: boolean;
  singleGuardianAcceptOffer: boolean;
  schoolunitSpecific: boolean;
  subscribable: boolean;
  timezoneName: string;
  closed: boolean;
}

export interface SubjectCoSigner {
  userId?: string| null;
  customerId?: string| null;
  name: string;
  nationalId: string;
  user?: User| null;
}

export interface ApplicationResponseEvent {
  id: string;
  applicationResponse: ApplicationResponse;
  created: DateTimeScalar;
  actor?: Actor| null;
  action: ApplicationResponseAction;
  comment?: string| null;
  errorCode?: string| null;
  newStatus: ApplicationResponseStatus;
  following?: ApplicationResponseEvent| null;
  followed_by?: ApplicationResponseEvent| null;
}

export interface ApplicationResponseSignature {
  signerId: string;
  signer?: User| null;
  signedAs: string;
  signedAsUser?: User| null;
  customerId?: string| null;
  verifiedRelation?: VerifiedRelation| null;
}

export interface ApplicationResponseFeedbackSignature {
  signerId: string;
  signer?: User| null;
  signedAs: string;
  signedAsUser?: User| null;
  customerId?: string| null;
}

export interface VerifiedRelation {
  isVerifiedRelation: boolean;
  customerId?: string| null;
}

export interface ApplicationCreator {
  id: string;
  name: string;
  source: UserSource;
  nationalId?: string| null;
  verifiedRelation: VerifiedRelation;
  orgId: string;
  tempNationalIds?: ((TempNationalId)[]) | null;
}

export interface TempNationalId {
  org: string;
  value: string;
}

export interface ApplicationResponse {
  id: string;
  application: Application;
  creator: ApplicationCreator;
  subject: SubjectCoSigner;
  coSigner?: SubjectCoSigner| null;
  schoolUnit?: SchoolUnit| null;
  created: DateTimeScalar;
  modified: DateTimeScalar;
  language: ISO6391Language;
  response?: FormAnswer| null;
  signatures: ((ApplicationResponseSignature)[]) ;
  feedbackSignatures: ((ApplicationResponseFeedbackSignature)[]) ;
  status: ApplicationResponseStatus;
  lastAction: ApplicationResponseEvent;
  actions: ((ApplicationResponseEvent)[]) ;
  seenByOrg?: DateTimeScalar| null;
  feedbackId?: string| null;
  feedbackDescription?: string| null;
  lockHolder?: User| null;
  lockExpire?: DateTimeScalar| null;
  validIfSigned: boolean;
}

export interface PermissionRole {
  roleId: PermissionType;
  sources: ((PermissionSource)[]) ;
}

export interface SubjectPermissionElement {
  user?: User| null;
  userId?: string| null;
  source?: string| null;
  displayName?: string| null;
  client?: Client| null;
  roles: ((PermissionRole)[]) ;
  employmentRoles: ((EmploymentRole)[]) ;
}

export interface SchoolUnitPermissions {
  resourceId: string;
  schoolUnit: SchoolUnit;
  customerId: string;
  displayName: string;
  permissions: ((SubjectPermissionElement)[]) ;
}

export interface OrganisationPermssions {
  resourceId: string;
  displayName?: string| null;
  organisation: ((SubjectPermissionElement)[]) ;
  schoolUnits: ((SchoolUnitPermissions)[]) ;
}

export interface OrganisationPermissionElement {
  resourceId: string;
  displayName?: string| null;
  roles: ((PermissionRole)[]) ;
  employmentRoles: ((EmploymentRole)[]) ;
}

export interface SchoolUnitPermissionElement {
  resourceId: string;
  schoolUnit: SchoolUnit;
  customerId: string;
  displayName?: string| null;
  roles: ((PermissionRole)[]) ;
  employmentRoles: ((EmploymentRole)[]) ;
}

export interface SubjectPermissions {
  id?: string| null;
  userSource?: UserSource| null;
  customerId?: string| null;
  clientId?: string| null;
  organisation: OrganisationPermissionElement;
  schoolUnits: ((SchoolUnitPermissionElement)[]) ;
}

export interface ResourcePermission {
  resourceId: string;
  resourceTypeId: ResourceType;
  customerId?: string| null;
  roleIds: ((PermissionType)[]) ;
}

export interface SetUserPermissionInput {
  userid: string;
  source: UserSource;
  customerId?: string| null;
  permissions: ((ResourcePermission)[]) ;
}

export interface SetClientPermissionInput {
  clientId: string;
  permissions: ((ResourcePermission)[]) ;
}

export interface EmploymentPermissionInput {
  employmentType: EmploymentRole;
  roles: ((PermissionType)[]) ;
}

export interface UpdateOrganisationSettingsInput {
  defaultLanguage?: ISO6391Language| null;
  autoUpdateRecipients?: boolean| null;
  employmentPermissions?: ((EmploymentPermissionInput)[]) | null;
  emailSuffixWork?: string| null;
  emailSuffixHome?: string| null;
}

export interface UpdateFormTranslationInput {
  formId: string;
  language: ISO6391Language;
  name: string;
  description: string;
  componentData: TranslationComponentDataInput;
}

export interface TranslationComponentInput {
  id: string;
  language?: ISO6391Language| null;
  title: string;
  description: string;
  questions: ((FormTranslationQuestionInput)[]) ;
  templateId?: string| null;
  attachments: ((ComponentAttachmentInput)[]) ;
}

export interface TranslationPredicateComponentInput {
  id: string;
  language?: ISO6391Language| null;
  questions: ((FormTranslationQuestionInput)[]) ;
}

export interface TranslationComponentDataInput {
  components: ((TranslationComponentInput)[]) ;
  predicateComponents?: ((TranslationPredicateComponentInput)[]) | null;
}

export interface FormTranslationQuestionInput {
  id: string;
  templateQuestionId?: string| null;
  question: string;
  shortName?: string| null;
  options?: ((FormTranslationQuestionOptionsInput)[]) | null;
}

export interface FormTranslationQuestionOptionsInput {
  id: string;
  label: string;
}

export interface SaveFormInput {
  id?: string| null;
  type: FormType;
  subType: FormSubType;
  title: string;
  description: string;
  componentData: ComponentDataInput;
  language: ISO6391Language;
  tags?: ((string)[]) | null;
  access: FormAccessInput;
}

export interface IdCustomerIdInput {
  id: string;
  customerId: string;
}

export interface FormAccessInput {
  public: boolean;
  organisation: boolean;
  schoolUnits: ((IdCustomerIdInput)[]) ;
}

export interface Context {
  language: string;
  org?: string| null;
}

export interface FormHistoryRecordDataInput {
  formId: string;
}

export interface FormHistoryRecordInput {
  isFavorite?: boolean| null;
  label: string;
  type: RecordHistoryType;
  data: FormHistoryRecordDataInput;
}

export interface ClassResponsesHistoryRecordDataInput {
  customerId: string;
  schoolUnitId: string;
  studentGroupId: string;
}

export interface ClassResponsesHistoryRecordInput {
  isFavorite?: boolean| null;
  label: string;
  type: RecordHistoryType;
  data: ClassResponsesHistoryRecordDataInput;
}

export type HistoryDataUnion = FormHistoryData | ClassResponsesHistoryData;

export interface HistoryRecord {
  id: string;
  isFavorite: boolean;
  label: string;
  type: RecordHistoryType;
  time: DateTimeScalar;
  data: HistoryDataUnion;
}

export interface FormHistoryData {
  formId: string;
}

export interface FormHistoryRecord {
  id: string;
  isFavorite: boolean;
  label: string;
  type: RecordHistoryType;
  time: DateTimeScalar;
  data: FormHistoryData;
}

export interface ClassResponsesHistoryData {
  customerId: string;
  schoolUnitId: string;
  studentGroupId: string;
}

export interface ClassResponsesHistoryRecord {
  id: string;
  isFavorite: boolean;
  label: string;
  type: RecordHistoryType;
  time: DateTimeScalar;
  data: ClassResponsesHistoryData;
}

export interface SignFormUploadInput {
  filename: string;
  contentLength: number;
  contentType: string;
}

export interface AwsHeader {
  key: string;
  value: string;
}

export interface SignFormUpload {
  key: string;
  signedUrl: string;
  post_headers: ((AwsHeader)[]) ;
}

export interface DemoCreateResponsesInput {
  publicationId: string;
  count: number;
  answer?: number| null;
  answerYes?: number| null;
  checkboxOptionSelected?: number| null;
  statusValid?: number| null;
  rankingOptionSelected?: number| null;
}

export enum MigrationMode {
  lowToHigh = "lowToHigh",
  highToLow = "highToLow",
  all = "all",
}

export interface MigrateToSourceInput {
  orgId: string;
  oldSourceId: string;
  newSourceId: string;
  schoolUnitIds?: ((string)[]) | null;
  migrationMode: MigrationMode;
  dryrun?: boolean| null;
}

export interface MigrateToSourceStats {
  recipientsToMerge: number;
  migratedRecipients: number;
  droppedGroups: number;
  signatureUpdates: number;
  notificationHistoryUpdates: number;
  dryrun: boolean;
}

export interface MigratePermissionsInput {
  dryrun?: boolean| null;
  deleteOld?: boolean| null;
}

export interface AdminCreateTemplateInput {
  title: string;
  category: string;
  language: ISO6391Language;
}

export interface AdminUpdateTemplateInput {
  id: string;
  componentData: ComponentTemplateInput;
}

export interface AdminCreateTemplateTranslationInput {
  id: string;
  language: ISO6391Language;
}

export interface AdminUpdateTemplateTranslationInput {
  id: string;
  language: ISO6391Language;
  componentData: ComponentTemplateTranslationInput;
}

export interface ComponentTemplateInput {
  id: string;
  templateId?: string| null;
  language?: ISO6391Language| null;
  title: string;
  description?: string| null;
  questions: ((FormQuestionInput)[]) ;
  sensitive: boolean;
  permission: FormComponentPermission;
  attachments?: ((ComponentAttachmentInput)[]) | null;
}

export interface ComponentTemplateTranslationInput {
  id: string;
  title: string;
  description: string;
  questions: ((FormTranslationQuestionInput)[]) ;
  attachments: ((ComponentAttachmentInput)[]) ;
}

export interface MigratePublicationResponseEducloudUserStatus {
  received: number;
  success: number;
  failed: number;
  total: number;
}

export interface NewResponseStats {
  publication: Publication;
  group: PublicationGroupUnion;
  count: number;
  lastActivity: DateTimeScalar;
}

export interface UserSettingsInput {
  workEmail?: string| null;
  personalEmail?: string| null;
  updatedConsentNotification: boolean;
}

export interface AddOrgInput {
  id: string;
  name: string;
  country: ISO3166Country;
  defaultLanguage: ISO6391Language;
}

export interface RemoveOrgInput {
  id: string;
}

export interface AddIdpInput {
  id: string;
  name: string;
}

export interface RemoveIdpInput {
  id: string;
}

export interface AddIdpOrgConnectionInput {
  orgId: string;
  idpId: string;
}

export interface RemoveIdpOrgConnectionInput {
  orgId: string;
  idpId: string;
}

export interface Idp {
  id: string;
  name: string;
  connections: ((Org)[]) ;
}

export interface Org {
  id: string;
  name: string;
  connections: ((Idp)[]) ;
}

export interface IdpOrgConnection {
  org: Org;
  idp: Idp;
}

export interface BuildInfo {
  version: string;
  hostname: string;
}

export interface ActionInput {
  responseId: string;
  previousEventId: string;
  action: ApplicationResponseAction;
  comment?: string| null;
  errorCode?: string| null;
  feedbackId?: string| null;
  feedbackDescription?: string| null;
  schoolunitId?: string| null;
  customerId?: string| null;
}

export interface ResIdPrevEvent {
  responseId: string;
  previousEventId: string;
}

export interface MultiActionInput {
  responses: ((ResIdPrevEvent)[]) ;
  action: ApplicationResponseAction;
  comment?: string| null;
  feedbackId?: string| null;
  feedbackDescription?: string| null;
  schoolunitId?: string| null;
  customerId?: string| null;
}

export interface EmailTemplate {
  subject: string;
  htmlContent: string;
}

export interface EmailTemplateSubstitutions {
  org?: string| null;
  sender?: string| null;
  actionUrl?: string| null;
  serviceUrl?: string| null;
  serviceName?: string| null;
}

export interface SearchUsersResult {
  hasMoreResults: boolean;
  users: ((User)[]) ;
}

export interface AdminSignLogoUploadInput {
  orgId: string;
  filename: string;
  contentLength: number;
  contentType: string;
}

export interface AdminSignLogoUpload {
  key: string;
  signedUrl: string;
  post_headers: ((AwsHeader)[]) ;
}

export interface PermissionUsers {
  userId: string;
  source: string;
  user: User;
}

export interface PermissionUsersSources {
  educloud: ((PermissionUsers)[]) ;
  skolid: ((PermissionUsers)[]) ;
}

export interface QueryFormArgs {
  id: string;
  context: Context;
}
export interface QueryPublicationArgs {
  id: string;
  context: Context| null;
}
export interface QueryApplicationArgs {
  id: string;
  context: Context| null;
}
export interface QueryPublicationResponseArgs {
  id: string;
  context: Context| null;
}
export interface QueryPublicationRecipientArgs {
  id: string;
  context: Context| null;
}
export interface QueryReplaceablePublicationsArgs {
  formId: string;
  minimumValidTo: DateStringScalar;
  timeZoneName: string;
}
export interface QueryApplicationResponseArgs {
  id: string;
  context: Context| null;
}
export interface QueryOrganisationArgs {
  id: string| null;
  context: Context| null;
}
export interface QuerySchoolUnitArgs {
  id: string;
  customerId: string;
  context: Context;
}
export interface QuerySchoolUnitsArgs {
  ids: ((string)[]) ;
  customerId: string;
  context: Context;
}
export interface QueryStudentGroupArgs {
  id: string;
  customerId: string;
  context: Context;
}
export interface QueryStudentGroupsArgs {
  ids: ((string)[]) ;
  customerId: string;
  context: Context;
}
export interface QueryNewResponseStatsArgs {
  since: DateTimeScalar| null;
  onlyFavorites: boolean| null;
  context: Context;
}
export interface QueryUpdatedResponsesArgs {
  since: DateTimeScalar| null;
  onlyFavorites: boolean| null;
  context: Context;
}
export interface QueryTemplatesArgs {
  filter: TemplateFilter| null;
}
export interface QueryUserArgs {
  input: UserIdInput;
  context: Context| null;
}
export interface QueryUsersArgs {
  input: UsersIdInput;
  context: Context;
}
export interface QueryFindUsersArgs {
  key: string;
}
export interface QuerySearchUsersArgs {
  key: string;
  page: number| null;
  context: Context;
}
export interface QueryPermissionUsersArgs {
  educloudUserIds: ((string)[]) | null;
  skolidUserIds: ((string)[]) | null;
  customerId: string;
  context: Context;
}
export interface QueryEmailTemplateArgs {
  language: string;
  type: EmailTemplateType;
}
export interface QueryEmailSubstitutionsArgs {
  org: string;
  role: EmailTemplateSubstitutionsRole| null;
}
export interface QueryClientArgs {
  clientId: string| null;
}
export interface QueryActorArgs {
  id: string| null;
  context: Context| null;
}

export interface MutationSaveFormArgs {
  input: SaveFormInput;
  context: Context;
}
export interface MutationUpdateFormAccessArgs {
  id: string;
  access: FormAccessInput;
  context: Context;
}
export interface MutationUpdateFormTagsArgs {
  id: string;
  tags: ((string)[]) ;
  context: Context;
}
export interface MutationLockFormArgs {
  id: string;
}
export interface MutationDeleteFormArgs {
  id: string;
}
export interface MutationCopyFormArgs {
  id: string;
}
export interface MutationCreateFormTranslationArgs {
  id: string;
  language: ISO6391Language;
  context: Context;
}
export interface MutationDeleteFormTranslationArgs {
  id: string;
  language: ISO6391Language;
  context: Context;
}
export interface MutationUpdateFormTranslationArgs {
  input: UpdateFormTranslationInput;
  context: Context;
}
export interface MutationUpdateFormTranslationsArgs {
  input: ((UpdateFormTranslationInput)[]) ;
  context: Context;
}
export interface MutationCreatePublicationArgs {
  input: CreatePublicationInput;
  context: Context;
}
export interface MutationSavePublicationArgs {
  input: SavePublicationInput;
  context: Context;
}
export interface MutationUpdatePublicationTagsArgs {
  id: string;
  tags: ((string)[]) ;
  context: Context;
}
export interface MutationDeletePublicationArgs {
  id: string;
  context: Context;
}
export interface MutationRevokePublicationArgs {
  id: string;
  reason: string;
  context: Context;
}
export interface MutationSendPublicationReminderArgs {
  id: string;
  context: Context;
}
export interface MutationUpdatePublicationRecipientsArgs {
  id: string;
  context: Context;
}
export interface MutationPromptUpdateUncontactableArgs {
  id: string;
  context: Context;
}
export interface MutationCreateApplicationArgs {
  input: CreateApplicationInput;
  context: Context;
}
export interface MutationSaveApplicationArgs {
  input: SaveApplicationInput;
  context: Context;
}
export interface MutationDeleteApplicationArgs {
  id: string;
  context: Context;
}
export interface MutationSendArgs {
  formId: string;
  studentGroups: ((string)[]) ;
  date: DateTimeScalar| null;
  context: Context;
}
export interface MutationTryTakePublicationResponseLockArgs {
  responseId: string;
  context: Context| null;
}
export interface MutationReleasePublicationResponseLockArgs {
  responseId: string;
  context: Context| null;
}
export interface MutationCreatePublicationResponseArgs {
  recipientId: string;
  response: FormAnswerInput;
  language: ISO6391Language| null;
  context: Context| null;
}
export interface MutationRemoveResponseDraftArgs {
  responseId: string;
  context: Context| null;
}
export interface MutationSavePublicationResponseArgs {
  responseId: string;
  response: FormAnswerInput;
  language: ISO6391Language;
  context: Context| null;
}
export interface MutationSignPublicationResponseArgs {
  id: string;
  response: string;
  context: Context| null;
}
export interface MutationRemovePublicationResponseSignaturesArgs {
  id: string;
  context: Context| null;
}
export interface MutationCreateApplicationResponseArgs {
  applicationId: string;
  response: FormAnswerInput;
  language: ISO6391Language;
  schoolUnit: IdCustomerIdInput| null;
}
export interface MutationSaveApplicationResponseArgs {
  responseId: string;
  response: FormAnswerInput;
  language: ISO6391Language;
}
export interface MutationRemoveApplicationResponseArgs {
  responseId: string;
}
export interface MutationSignApplicationResponseArgs {
  responseId: string;
  response: FormAnswerInput;
  language: ISO6391Language;
  context: Context| null;
}
export interface MutationRemoveApplicationResponseSignaturesArgs {
  responseId: string;
}
export interface MutationPerformApplicationResponseActionArgs {
  input: ActionInput;
  context: Context| null;
}
export interface MutationPerformMultiApplicationResponseActionsArgs {
  input: MultiActionInput;
  context: Context| null;
}
export interface MutationSetUserPermissionArgs {
  input: SetUserPermissionInput;
  context: Context;
}
export interface MutationSetClientPermissionArgs {
  input: SetClientPermissionInput;
  context: Context;
}
export interface MutationUpdateOrganisationSettingsArgs {
  orgId: string| null;
  input: UpdateOrganisationSettingsInput;
  context: Context| null;
}
export interface MutationUpdateUserSettingsArgs {
  input: UserSettingsInput;
}
export interface MutationRecordFormHistoryArgs {
  input: FormHistoryRecordInput;
  context: Context;
}
export interface MutationRecordClassResponsesHistoryArgs {
  input: ClassResponsesHistoryRecordInput;
  context: Context;
}
export interface MutationDeleteFavoriteArgs {
  id: string;
  context: Context| null;
}
export interface MutationSignFormUploadArgs {
  input: SignFormUploadInput;
  context: Context;
}
export interface MutationAdminForceCheckPublicationRecipientChangesArgs {
  id: string;
}
export interface MutationAdminForceCheckOrgPublicationRecipientChangesArgs {
  id: string;
}
export interface MutationAdminTriggerOrganisationResponseArchiveArgs {
  id: string;
}
export interface MutationAdminMigrateToSourceArgs {
  input: MigrateToSourceInput;
}
export interface MutationAdminMigrateOrganisationDataToOtherOrganisationArgs {
  from: string;
  to: string;
}
export interface MutationAdminCreateTemplateArgs {
  input: AdminCreateTemplateInput;
}
export interface MutationAdminUpdateTemplateArgs {
  input: AdminUpdateTemplateInput;
}
export interface MutationAdminPublishTemplateArgs {
  id: string;
}
export interface MutationAdminCreateTemplateTranslationArgs {
  input: AdminCreateTemplateTranslationInput;
}
export interface MutationAdminUpdateTemplateTranslationArgs {
  input: AdminUpdateTemplateTranslationInput;
}
export interface MutationAdminPublishTemplateTranslationArgs {
  id: string;
  language: ISO6391Language;
}
export interface MutationAdminAddIdpArgs {
  input: AddIdpInput;
}
export interface MutationAdminRemoveIdpArgs {
  input: RemoveIdpInput;
}
export interface MutationAdminAddIdpOrgConnectionArgs {
  input: AddIdpOrgConnectionInput;
}
export interface MutationAdminRemoveIdpOrgConnectionArgs {
  input: RemoveIdpOrgConnectionInput;
}
export interface MutationAdminAssignAdminPermissionToMeArgs {
  orgId: string;
}
export interface MutationAdminSignLogoUploadArgs {
  input: AdminSignLogoUploadInput;
}
export interface MutationDemoCreateResponsesArgs {
  input: DemoCreateResponsesInput;
}
export interface MutationDemoMarkPublicationSentArgs {
  id: string;
}

export const strippers = {

  UserIdInput: (inp: UserIdInput): UserIdInput => {
    const obj: Partial<UserIdInput> = {};

    obj.id = inp.id;
    obj.source = inp.source;
    obj.customerId = inp.customerId;

    return obj as UserIdInput;
  },

  UsersIdInput: (inp: UsersIdInput): UsersIdInput => {
    const obj: Partial<UsersIdInput> = {};

    obj.ids = inp.ids;
    obj.source = inp.source;
    obj.customerId = inp.customerId;

    return obj as UsersIdInput;
  },

  RankingInput: (inp: RankingInput): RankingInput => {
    const obj: Partial<RankingInput> = {};

    obj.value = inp.value;
    obj.order = inp.order;

    return obj as RankingInput;
  },

  QuestionAnswerInput: (inp: QuestionAnswerInput): QuestionAnswerInput => {
    const obj: Partial<QuestionAnswerInput> = {};

    obj.questionId = inp.questionId;
    obj.yesOrNo = inp.yesOrNo;
    obj.multiChoice = inp.multiChoice;
    obj.checkboxes = inp.checkboxes;
    obj.rankings = inp.rankings ? inp.rankings.map(v =>  strippers.RankingInput(v)) : undefined;
    obj.shortText = inp.shortText;
    obj.longText = inp.longText;
    obj.date = inp.date;

    return obj as QuestionAnswerInput;
  },

  ComponentAnswerInput: (inp: ComponentAnswerInput): ComponentAnswerInput => {
    const obj: Partial<ComponentAnswerInput> = {};

    obj.componentId = inp.componentId;
    obj.questions =  inp.questions.map(v =>  strippers.QuestionAnswerInput(v));
    obj.sensitiveInformationConsent = inp.sensitiveInformationConsent;
    obj.redacted = inp.redacted;

    return obj as ComponentAnswerInput;
  },

  FormAnswerInput: (inp: FormAnswerInput): FormAnswerInput => {
    const obj: Partial<FormAnswerInput> = {};

    obj.components =  inp.components.map(v =>  strippers.ComponentAnswerInput(v));

    return obj as FormAnswerInput;
  },

  FormQuestionOptionsInput: (inp: FormQuestionOptionsInput): FormQuestionOptionsInput => {
    const obj: Partial<FormQuestionOptionsInput> = {};

    obj.id = inp.id;
    obj.label = inp.label;
    obj.externalId = inp.externalId;

    return obj as FormQuestionOptionsInput;
  },

  FormQuestionPredicateInput: (inp: FormQuestionPredicateInput): FormQuestionPredicateInput => {
    const obj: Partial<FormQuestionPredicateInput> = {};

    obj.predicate = inp.predicate;
    obj.componentId = inp.componentId;

    return obj as FormQuestionPredicateInput;
  },

  FormQuestionInput: (inp: FormQuestionInput): FormQuestionInput => {
    const obj: Partial<FormQuestionInput> = {};

    obj.id = inp.id;
    obj.templateQuestionId = inp.templateQuestionId;
    obj.type = inp.type;
    obj.validationType = inp.validationType;
    obj.question = inp.question;
    obj.shortName = inp.shortName;
    obj.compulsory = inp.compulsory;
    obj.options = inp.options ? inp.options.map(v =>  strippers.FormQuestionOptionsInput(v)) : undefined;
    obj.predicates = inp.predicates ? inp.predicates.map(v =>  strippers.FormQuestionPredicateInput(v)) : undefined;
    obj.minSelectedOptions = inp.minSelectedOptions;
    obj.maxSelectedOptions = inp.maxSelectedOptions;
    obj.dropdownView = inp.dropdownView;

    return obj as FormQuestionInput;
  },

  FormPredicateComponentInput: (inp: FormPredicateComponentInput): FormPredicateComponentInput => {
    const obj: Partial<FormPredicateComponentInput> = {};

    obj.id = inp.id;
    obj.language = inp.language;
    obj.questions =  inp.questions.map(v =>  strippers.FormQuestionInput(v));
    obj.permission = inp.permission;
    obj.sensitive = inp.sensitive;

    return obj as FormPredicateComponentInput;
  },

  ComponentAttachmentInput: (inp: ComponentAttachmentInput): ComponentAttachmentInput => {
    const obj: Partial<ComponentAttachmentInput> = {};

    obj.name = inp.name;
    obj.key = inp.key;
    obj.url = inp.url;

    return obj as ComponentAttachmentInput;
  },

  ComponentInput: (inp: ComponentInput): ComponentInput => {
    const obj: Partial<ComponentInput> = {};

    obj.id = inp.id;
    obj.language = inp.language;
    obj.title = inp.title;
    obj.description = inp.description;
    obj.templateId = inp.templateId;
    obj.questions =  inp.questions.map(v =>  strippers.FormQuestionInput(v));
    obj.sensitive = inp.sensitive;
    obj.permission = inp.permission;
    obj.attachments = inp.attachments ? inp.attachments.map(v =>  strippers.ComponentAttachmentInput(v)) : undefined;
    obj.applicationFeedbackId = inp.applicationFeedbackId;

    return obj as ComponentInput;
  },

  ComponentDataInput: (inp: ComponentDataInput): ComponentDataInput => {
    const obj: Partial<ComponentDataInput> = {};

    obj.components =  inp.components.map(v =>  strippers.ComponentInput(v));
    obj.predicateComponents = inp.predicateComponents ? inp.predicateComponents.map(v =>  strippers.FormPredicateComponentInput(v)) : undefined;

    return obj as ComponentDataInput;
  },

  SchoolUnitsFilter: (inp: SchoolUnitsFilter): SchoolUnitsFilter => {
    const obj: Partial<SchoolUnitsFilter> = {};

    obj.permissionType = inp.permissionType;
    obj.organisationType = inp.organisationType;
    obj.ignorePermission = inp.ignorePermission;

    return obj as SchoolUnitsFilter;
  },

  PublicationRecipientUserListInput: (inp: PublicationRecipientUserListInput): PublicationRecipientUserListInput => {
    const obj: Partial<PublicationRecipientUserListInput> = {};

    obj.userId = inp.userId;
    obj.customerId = inp.customerId;
    obj.manuallyAdded = inp.manuallyAdded;
    obj.excludedFromPublication = inp.excludedFromPublication;

    return obj as PublicationRecipientUserListInput;
  },

  PublicationRecipientStudentGroupListInput: (inp: PublicationRecipientStudentGroupListInput): PublicationRecipientStudentGroupListInput => {
    const obj: Partial<PublicationRecipientStudentGroupListInput> = {};

    obj.id = inp.id;
    obj.customerId = inp.customerId;
    obj.active = inp.active;
    obj.schoolYearFilter = inp.schoolYearFilter;

    return obj as PublicationRecipientStudentGroupListInput;
  },

  PublicationRecipientListInput: (inp: PublicationRecipientListInput): PublicationRecipientListInput => {
    const obj: Partial<PublicationRecipientListInput> = {};

    obj.users = inp.users ? inp.users.map(v =>  strippers.PublicationRecipientUserListInput(v)) : undefined;
    obj.studentGroups = inp.studentGroups ? inp.studentGroups.map(v =>  strippers.PublicationRecipientStudentGroupListInput(v)) : undefined;

    return obj as PublicationRecipientListInput;
  },

  CreatePublicationInput: (inp: CreatePublicationInput): CreatePublicationInput => {
    const obj: Partial<CreatePublicationInput> = {};

    obj.formId = inp.formId;
    obj.name = inp.name;
    obj.recipients =  strippers.PublicationRecipientListInput(inp.recipients);
    obj.validFrom = inp.validFrom;
    obj.validTo = inp.validTo;
    obj.sendDate = inp.sendDate;
    obj.timeZoneName = inp.timeZoneName;
    obj.singleGuardian = inp.singleGuardian;
    obj.childResponse = inp.childResponse;
    obj.skipEmails = inp.skipEmails;
    obj.tags = inp.tags;

    return obj as CreatePublicationInput;
  },

  SavePublicationInput: (inp: SavePublicationInput): SavePublicationInput => {
    const obj: Partial<SavePublicationInput> = {};

    obj.id = inp.id;
    obj.recipients =  strippers.PublicationRecipientListInput(inp.recipients);
    obj.sendDate = inp.sendDate;
    obj.validFrom = inp.validFrom;
    obj.validTo = inp.validTo;
    obj.timeZoneName = inp.timeZoneName;
    obj.singleGuardian = inp.singleGuardian;
    obj.childResponse = inp.childResponse;
    obj.skipEmails = inp.skipEmails;
    obj.tags = inp.tags;

    return obj as SavePublicationInput;
  },

  CreateApplicationInput: (inp: CreateApplicationInput): CreateApplicationInput => {
    const obj: Partial<CreateApplicationInput> = {};

    obj.formId = inp.formId;
    obj.name = inp.name;
    obj.singleGuardianSend = inp.singleGuardianSend;
    obj.singleGuardianAcceptOffer = inp.singleGuardianAcceptOffer;
    obj.schoolunitSpecific = inp.schoolunitSpecific;
    obj.subscribable = inp.subscribable;
    obj.sendDate = inp.sendDate;
    obj.timezoneName = inp.timezoneName;

    return obj as CreateApplicationInput;
  },

  SaveApplicationInput: (inp: SaveApplicationInput): SaveApplicationInput => {
    const obj: Partial<SaveApplicationInput> = {};

    obj.id = inp.id;
    obj.sendDate = inp.sendDate;
    obj.singleGuardianSend = inp.singleGuardianSend;
    obj.singleGuardianAcceptOffer = inp.singleGuardianAcceptOffer;
    obj.schoolunitSpecific = inp.schoolunitSpecific;
    obj.subscribable = inp.subscribable;
    obj.timezoneName = inp.timezoneName;
    obj.closed = inp.closed;

    return obj as SaveApplicationInput;
  },

  ResourcePermission: (inp: ResourcePermission): ResourcePermission => {
    const obj: Partial<ResourcePermission> = {};

    obj.resourceId = inp.resourceId;
    obj.resourceTypeId = inp.resourceTypeId;
    obj.customerId = inp.customerId;
    obj.roleIds = inp.roleIds;

    return obj as ResourcePermission;
  },

  SetUserPermissionInput: (inp: SetUserPermissionInput): SetUserPermissionInput => {
    const obj: Partial<SetUserPermissionInput> = {};

    obj.userid = inp.userid;
    obj.source = inp.source;
    obj.customerId = inp.customerId;
    obj.permissions =  inp.permissions.map(v =>  strippers.ResourcePermission(v));

    return obj as SetUserPermissionInput;
  },

  SetClientPermissionInput: (inp: SetClientPermissionInput): SetClientPermissionInput => {
    const obj: Partial<SetClientPermissionInput> = {};

    obj.clientId = inp.clientId;
    obj.permissions =  inp.permissions.map(v =>  strippers.ResourcePermission(v));

    return obj as SetClientPermissionInput;
  },

  EmploymentPermissionInput: (inp: EmploymentPermissionInput): EmploymentPermissionInput => {
    const obj: Partial<EmploymentPermissionInput> = {};

    obj.employmentType = inp.employmentType;
    obj.roles = inp.roles;

    return obj as EmploymentPermissionInput;
  },

  UpdateOrganisationSettingsInput: (inp: UpdateOrganisationSettingsInput): UpdateOrganisationSettingsInput => {
    const obj: Partial<UpdateOrganisationSettingsInput> = {};

    obj.defaultLanguage = inp.defaultLanguage;
    obj.autoUpdateRecipients = inp.autoUpdateRecipients;
    obj.employmentPermissions = inp.employmentPermissions ? inp.employmentPermissions.map(v =>  strippers.EmploymentPermissionInput(v)) : undefined;
    obj.emailSuffixWork = inp.emailSuffixWork;
    obj.emailSuffixHome = inp.emailSuffixHome;

    return obj as UpdateOrganisationSettingsInput;
  },

  UpdateFormTranslationInput: (inp: UpdateFormTranslationInput): UpdateFormTranslationInput => {
    const obj: Partial<UpdateFormTranslationInput> = {};

    obj.formId = inp.formId;
    obj.language = inp.language;
    obj.name = inp.name;
    obj.description = inp.description;
    obj.componentData =  strippers.TranslationComponentDataInput(inp.componentData);

    return obj as UpdateFormTranslationInput;
  },

  TranslationComponentInput: (inp: TranslationComponentInput): TranslationComponentInput => {
    const obj: Partial<TranslationComponentInput> = {};

    obj.id = inp.id;
    obj.language = inp.language;
    obj.title = inp.title;
    obj.description = inp.description;
    obj.questions =  inp.questions.map(v =>  strippers.FormTranslationQuestionInput(v));
    obj.templateId = inp.templateId;
    obj.attachments =  inp.attachments.map(v =>  strippers.ComponentAttachmentInput(v));

    return obj as TranslationComponentInput;
  },

  TranslationPredicateComponentInput: (inp: TranslationPredicateComponentInput): TranslationPredicateComponentInput => {
    const obj: Partial<TranslationPredicateComponentInput> = {};

    obj.id = inp.id;
    obj.language = inp.language;
    obj.questions =  inp.questions.map(v =>  strippers.FormTranslationQuestionInput(v));

    return obj as TranslationPredicateComponentInput;
  },

  TranslationComponentDataInput: (inp: TranslationComponentDataInput): TranslationComponentDataInput => {
    const obj: Partial<TranslationComponentDataInput> = {};

    obj.components =  inp.components.map(v =>  strippers.TranslationComponentInput(v));
    obj.predicateComponents = inp.predicateComponents ? inp.predicateComponents.map(v =>  strippers.TranslationPredicateComponentInput(v)) : undefined;

    return obj as TranslationComponentDataInput;
  },

  FormTranslationQuestionInput: (inp: FormTranslationQuestionInput): FormTranslationQuestionInput => {
    const obj: Partial<FormTranslationQuestionInput> = {};

    obj.id = inp.id;
    obj.templateQuestionId = inp.templateQuestionId;
    obj.question = inp.question;
    obj.shortName = inp.shortName;
    obj.options = inp.options ? inp.options.map(v =>  strippers.FormTranslationQuestionOptionsInput(v)) : undefined;

    return obj as FormTranslationQuestionInput;
  },

  FormTranslationQuestionOptionsInput: (inp: FormTranslationQuestionOptionsInput): FormTranslationQuestionOptionsInput => {
    const obj: Partial<FormTranslationQuestionOptionsInput> = {};

    obj.id = inp.id;
    obj.label = inp.label;

    return obj as FormTranslationQuestionOptionsInput;
  },

  SaveFormInput: (inp: SaveFormInput): SaveFormInput => {
    const obj: Partial<SaveFormInput> = {};

    obj.id = inp.id;
    obj.type = inp.type;
    obj.subType = inp.subType;
    obj.title = inp.title;
    obj.description = inp.description;
    obj.componentData =  strippers.ComponentDataInput(inp.componentData);
    obj.language = inp.language;
    obj.tags = inp.tags;
    obj.access =  strippers.FormAccessInput(inp.access);

    return obj as SaveFormInput;
  },

  IdCustomerIdInput: (inp: IdCustomerIdInput): IdCustomerIdInput => {
    const obj: Partial<IdCustomerIdInput> = {};

    obj.id = inp.id;
    obj.customerId = inp.customerId;

    return obj as IdCustomerIdInput;
  },

  FormAccessInput: (inp: FormAccessInput): FormAccessInput => {
    const obj: Partial<FormAccessInput> = {};

    obj.public = inp.public;
    obj.organisation = inp.organisation;
    obj.schoolUnits =  inp.schoolUnits.map(v =>  strippers.IdCustomerIdInput(v));

    return obj as FormAccessInput;
  },

  Context: (inp: Context): Context => {
    const obj: Partial<Context> = {};

    obj.language = inp.language;
    obj.org = inp.org;

    return obj as Context;
  },

  FormHistoryRecordDataInput: (inp: FormHistoryRecordDataInput): FormHistoryRecordDataInput => {
    const obj: Partial<FormHistoryRecordDataInput> = {};

    obj.formId = inp.formId;

    return obj as FormHistoryRecordDataInput;
  },

  FormHistoryRecordInput: (inp: FormHistoryRecordInput): FormHistoryRecordInput => {
    const obj: Partial<FormHistoryRecordInput> = {};

    obj.isFavorite = inp.isFavorite;
    obj.label = inp.label;
    obj.type = inp.type;
    obj.data =  strippers.FormHistoryRecordDataInput(inp.data);

    return obj as FormHistoryRecordInput;
  },

  ClassResponsesHistoryRecordDataInput: (inp: ClassResponsesHistoryRecordDataInput): ClassResponsesHistoryRecordDataInput => {
    const obj: Partial<ClassResponsesHistoryRecordDataInput> = {};

    obj.customerId = inp.customerId;
    obj.schoolUnitId = inp.schoolUnitId;
    obj.studentGroupId = inp.studentGroupId;

    return obj as ClassResponsesHistoryRecordDataInput;
  },

  ClassResponsesHistoryRecordInput: (inp: ClassResponsesHistoryRecordInput): ClassResponsesHistoryRecordInput => {
    const obj: Partial<ClassResponsesHistoryRecordInput> = {};

    obj.isFavorite = inp.isFavorite;
    obj.label = inp.label;
    obj.type = inp.type;
    obj.data =  strippers.ClassResponsesHistoryRecordDataInput(inp.data);

    return obj as ClassResponsesHistoryRecordInput;
  },

  SignFormUploadInput: (inp: SignFormUploadInput): SignFormUploadInput => {
    const obj: Partial<SignFormUploadInput> = {};

    obj.filename = inp.filename;
    obj.contentLength = inp.contentLength;
    obj.contentType = inp.contentType;

    return obj as SignFormUploadInput;
  },

  DemoCreateResponsesInput: (inp: DemoCreateResponsesInput): DemoCreateResponsesInput => {
    const obj: Partial<DemoCreateResponsesInput> = {};

    obj.publicationId = inp.publicationId;
    obj.count = inp.count;
    obj.answer = inp.answer;
    obj.answerYes = inp.answerYes;
    obj.checkboxOptionSelected = inp.checkboxOptionSelected;
    obj.statusValid = inp.statusValid;
    obj.rankingOptionSelected = inp.rankingOptionSelected;

    return obj as DemoCreateResponsesInput;
  },

  MigrateToSourceInput: (inp: MigrateToSourceInput): MigrateToSourceInput => {
    const obj: Partial<MigrateToSourceInput> = {};

    obj.orgId = inp.orgId;
    obj.oldSourceId = inp.oldSourceId;
    obj.newSourceId = inp.newSourceId;
    obj.schoolUnitIds = inp.schoolUnitIds;
    obj.migrationMode = inp.migrationMode;
    obj.dryrun = inp.dryrun;

    return obj as MigrateToSourceInput;
  },

  MigratePermissionsInput: (inp: MigratePermissionsInput): MigratePermissionsInput => {
    const obj: Partial<MigratePermissionsInput> = {};

    obj.dryrun = inp.dryrun;
    obj.deleteOld = inp.deleteOld;

    return obj as MigratePermissionsInput;
  },

  AdminCreateTemplateInput: (inp: AdminCreateTemplateInput): AdminCreateTemplateInput => {
    const obj: Partial<AdminCreateTemplateInput> = {};

    obj.title = inp.title;
    obj.category = inp.category;
    obj.language = inp.language;

    return obj as AdminCreateTemplateInput;
  },

  AdminUpdateTemplateInput: (inp: AdminUpdateTemplateInput): AdminUpdateTemplateInput => {
    const obj: Partial<AdminUpdateTemplateInput> = {};

    obj.id = inp.id;
    obj.componentData =  strippers.ComponentTemplateInput(inp.componentData);

    return obj as AdminUpdateTemplateInput;
  },

  AdminCreateTemplateTranslationInput: (inp: AdminCreateTemplateTranslationInput): AdminCreateTemplateTranslationInput => {
    const obj: Partial<AdminCreateTemplateTranslationInput> = {};

    obj.id = inp.id;
    obj.language = inp.language;

    return obj as AdminCreateTemplateTranslationInput;
  },

  AdminUpdateTemplateTranslationInput: (inp: AdminUpdateTemplateTranslationInput): AdminUpdateTemplateTranslationInput => {
    const obj: Partial<AdminUpdateTemplateTranslationInput> = {};

    obj.id = inp.id;
    obj.language = inp.language;
    obj.componentData =  strippers.ComponentTemplateTranslationInput(inp.componentData);

    return obj as AdminUpdateTemplateTranslationInput;
  },

  ComponentTemplateInput: (inp: ComponentTemplateInput): ComponentTemplateInput => {
    const obj: Partial<ComponentTemplateInput> = {};

    obj.id = inp.id;
    obj.templateId = inp.templateId;
    obj.language = inp.language;
    obj.title = inp.title;
    obj.description = inp.description;
    obj.questions =  inp.questions.map(v =>  strippers.FormQuestionInput(v));
    obj.sensitive = inp.sensitive;
    obj.permission = inp.permission;
    obj.attachments = inp.attachments ? inp.attachments.map(v =>  strippers.ComponentAttachmentInput(v)) : undefined;

    return obj as ComponentTemplateInput;
  },

  ComponentTemplateTranslationInput: (inp: ComponentTemplateTranslationInput): ComponentTemplateTranslationInput => {
    const obj: Partial<ComponentTemplateTranslationInput> = {};

    obj.id = inp.id;
    obj.title = inp.title;
    obj.description = inp.description;
    obj.questions =  inp.questions.map(v =>  strippers.FormTranslationQuestionInput(v));
    obj.attachments =  inp.attachments.map(v =>  strippers.ComponentAttachmentInput(v));

    return obj as ComponentTemplateTranslationInput;
  },

  UserSettingsInput: (inp: UserSettingsInput): UserSettingsInput => {
    const obj: Partial<UserSettingsInput> = {};

    obj.workEmail = inp.workEmail;
    obj.personalEmail = inp.personalEmail;
    obj.updatedConsentNotification = inp.updatedConsentNotification;

    return obj as UserSettingsInput;
  },

  AddOrgInput: (inp: AddOrgInput): AddOrgInput => {
    const obj: Partial<AddOrgInput> = {};

    obj.id = inp.id;
    obj.name = inp.name;
    obj.country = inp.country;
    obj.defaultLanguage = inp.defaultLanguage;

    return obj as AddOrgInput;
  },

  RemoveOrgInput: (inp: RemoveOrgInput): RemoveOrgInput => {
    const obj: Partial<RemoveOrgInput> = {};

    obj.id = inp.id;

    return obj as RemoveOrgInput;
  },

  AddIdpInput: (inp: AddIdpInput): AddIdpInput => {
    const obj: Partial<AddIdpInput> = {};

    obj.id = inp.id;
    obj.name = inp.name;

    return obj as AddIdpInput;
  },

  RemoveIdpInput: (inp: RemoveIdpInput): RemoveIdpInput => {
    const obj: Partial<RemoveIdpInput> = {};

    obj.id = inp.id;

    return obj as RemoveIdpInput;
  },

  AddIdpOrgConnectionInput: (inp: AddIdpOrgConnectionInput): AddIdpOrgConnectionInput => {
    const obj: Partial<AddIdpOrgConnectionInput> = {};

    obj.orgId = inp.orgId;
    obj.idpId = inp.idpId;

    return obj as AddIdpOrgConnectionInput;
  },

  RemoveIdpOrgConnectionInput: (inp: RemoveIdpOrgConnectionInput): RemoveIdpOrgConnectionInput => {
    const obj: Partial<RemoveIdpOrgConnectionInput> = {};

    obj.orgId = inp.orgId;
    obj.idpId = inp.idpId;

    return obj as RemoveIdpOrgConnectionInput;
  },

  ActionInput: (inp: ActionInput): ActionInput => {
    const obj: Partial<ActionInput> = {};

    obj.responseId = inp.responseId;
    obj.previousEventId = inp.previousEventId;
    obj.action = inp.action;
    obj.comment = inp.comment;
    obj.errorCode = inp.errorCode;
    obj.feedbackId = inp.feedbackId;
    obj.feedbackDescription = inp.feedbackDescription;
    obj.schoolunitId = inp.schoolunitId;
    obj.customerId = inp.customerId;

    return obj as ActionInput;
  },

  ResIdPrevEvent: (inp: ResIdPrevEvent): ResIdPrevEvent => {
    const obj: Partial<ResIdPrevEvent> = {};

    obj.responseId = inp.responseId;
    obj.previousEventId = inp.previousEventId;

    return obj as ResIdPrevEvent;
  },

  MultiActionInput: (inp: MultiActionInput): MultiActionInput => {
    const obj: Partial<MultiActionInput> = {};

    obj.responses =  inp.responses.map(v =>  strippers.ResIdPrevEvent(v));
    obj.action = inp.action;
    obj.comment = inp.comment;
    obj.feedbackId = inp.feedbackId;
    obj.feedbackDescription = inp.feedbackDescription;
    obj.schoolunitId = inp.schoolunitId;
    obj.customerId = inp.customerId;

    return obj as MultiActionInput;
  },

  AdminSignLogoUploadInput: (inp: AdminSignLogoUploadInput): AdminSignLogoUploadInput => {
    const obj: Partial<AdminSignLogoUploadInput> = {};

    obj.orgId = inp.orgId;
    obj.filename = inp.filename;
    obj.contentLength = inp.contentLength;
    obj.contentType = inp.contentType;

    return obj as AdminSignLogoUploadInput;
  },

}

