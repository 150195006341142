import gql from "graphql-tag";
import _ from "lodash";
import * as React from "react";
import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
import { useAdminContext, useTranslation } from "../../App/reducer";
import { Button, Checkbox, ConsentForm, Loading } from "../../Common";
import { BottomMenu } from "../../Common/components/BottomMenu";
import { FormAccess } from "../../Common/components/FormAccess";
import { GenericError } from "../../Common/components/GenericError";
import {
  formComponentDataFragment,
  fullFormAccessFragment,
  fullFormTranslationFragment,
} from "../../Common/fragments";
import { useLocationState, useRandomId } from "../../Common/hooks";
import * as gqltypes from "../../gqltypes";
import { links } from "../links";
import { FormDoesNotExist } from "./FormDoesNotExist";

interface InnerFormViewProps {
  form: gqltypes.AdminFormView["form"];
  handleGoBack: () => void;
  warning?: string;
  setLanguage: (language: gqltypes.ISO6391Language) => void;
  language?: gqltypes.ISO6391Language;
  translation?: gqltypes.FullTranslation | null;
}

export function InnerFormView(props: InnerFormViewProps) {
  const { tr } = useTranslation();

  const mainRef: any = React.useRef();

  const [response, setResponse] = React.useState<gqltypes.FormAnswer>();
  const [validateAnswers, setValidateAnswers] = useLocationState(
    "validateAnswers",
    false
  );
  const [showAllApplicationFeedbacks, setShowAllApplicationFeedbacks] =
    React.useState(true);

  const { form } = props;
  const formName = (props.translation && props.translation.name) || form.name;
  const formDescription =
    (props.translation && props.translation.description) ||
    form.description ||
    "";

  const isApplication = props.form.type === gqltypes.FormType.application;

  const ValidateAnswerCheckbox = () => (
    <Checkbox
      id={useRandomId()}
      label={tr("formViewSetValidateAnswersLabel")}
      checked={validateAnswers}
      onChange={(id, checked) => {
        setValidateAnswers(checked);
      }}
    />
  );

  return (
    <div id="form-viewer-container" ref={mainRef}>
      <ValidateAnswerCheckbox />
      {props.warning && (
        <div className="alert alert-warning">{props.warning}</div>
      )}
      <FormAccess edit={false} access={form.access} formType={form.type} />

      {isApplication ? (
        <Checkbox
          label={tr("formViewSetShowAllApplicationFeedbacksLabel")}
          id="setShowAllApplicationFeedbacks"
          checked={showAllApplicationFeedbacks}
          onChange={(id, checked) => {
            setShowAllApplicationFeedbacks(checked);
          }}
        />
      ) : null}

      <ConsentForm
        formType={form.type}
        formOwner={form.owner}
        formTitle={formName}
        formDescription={formDescription}
        components={
          form.componentData.components as gqltypes.FullFormComponent[]
        }
        predicateComponents={form.componentData.predicateComponents || []}
        response={response}
        updateResponse={(res) => setResponse(res)}
        validateAnswers={validateAnswers}
        formLanguage={form.language}
        translation={props.translation}
        translating={Boolean(props.translation)}
        availableLanguages={[props.form.language].concat(
          props.form.translationLanguages
        )}
        handleLanguageChange={props.setLanguage}
        displayAllApplicationFeedbackOptions={
          isApplication && showAllApplicationFeedbacks
        }
      />
      <div className="actions">
        <Button
          onClick={props.handleGoBack}
          level="secondary"
          label={tr("goBack")}
        />
      </div>
      <BottomMenu show alignmentRef={mainRef}>
        <div className="p-content">
          <ValidateAnswerCheckbox />
        </div>
      </BottomMenu>
    </div>
  );
}

const FormViewer = () => {
  const { tr } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const adminContext = useAdminContext();
  const [language, setLanguage] = React.useState<gqltypes.ISO6391Language>();
  const {
    data: formData,
    loading,
    error,
  } = useQuery<gqltypes.AdminFormView, gqltypes.AdminFormViewVariables>(
    formQuery,
    {
      variables: { id: params.id!, context: adminContext },
    }
  );

  if (loading) {
    return <Loading />;
  }
  const form = formData?.form;

  if (error || !form) {
    if (
      error?.message ===
      "GraphQL error: Requestor is not allowed read or modify form"
    ) {
      return <GenericError title={tr("editFormNoAccess")} />;
    }
    if (!form) {
      return <FormDoesNotExist />;
    }
    return <GenericError title={tr("genericLoadError")} />;
  }

  const warningText =
    (form.archived ? tr("formArchivedWarning") : undefined) ||
    (form.locked ? tr("formLockedWarning") : undefined);

  return (
    <InnerFormView
      form={form}
      handleGoBack={() => {
        navigate(links.admin.form.list());
      }}
      warning={warningText}
      setLanguage={(lang) => {
        setLanguage(language === lang ? undefined : lang);
      }}
      language={language}
      translation={form.translations.find(
        (translation) => translation.language === language
      )}
    />
  );
};

const formQuery = gql`
  query AdminFormView($id: ID!, $context: Context!) {
    form(id: $id, context: $context) {
      id
      type
      archived
      locked
      name
      description
      language
      translationLanguages
      translations {
        ...FullTranslation
      }
      componentData {
        ...FormComponentData
      }
      access {
        ...FullFormAccess
      }
      owner {
        id
        displayName
      }
    }
  }
  ${formComponentDataFragment}
  ${fullFormAccessFragment}
  ${fullFormTranslationFragment}
`;

export const FormViewerContainer = FormViewer;
