import * as Redux from "redux";
import createSagaMiddleware from "redux-saga";
import * as Sentry from "@sentry/react";
import { fatalError } from "./App/actions";
import { AppState, appReducer } from "./App/reducer";

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const sagaMiddleware = createSagaMiddleware({
  onError: (e) => {
    // console.error("Saga error", e);
    store.dispatch(fatalError());
    throw e;
  },
});

const reduxCrashReporter = (s: any) => (next: any) => (action: any) => {
  try {
    return next(action);
  } catch (err) {
    console.error("Reducer error");
    throw err;
  }
};

export interface StoreState {
  app: AppState;
}

export const store = Redux.createStore<StoreState, any, {}, {}>(
  Redux.combineReducers({
    app: appReducer,
  }),
  Redux.compose(
    Redux.applyMiddleware(reduxCrashReporter, sagaMiddleware),
    sentryReduxEnhancer
  )
);
export function runSaga(saga: any) {
  sagaMiddleware.run(saga);
}
