import * as React from "react";
import { useTranslation } from "../../App/reducer";

interface Props {
  title?: string;
  explanation?: string;
}

export function GenericError(props: Props) {
  const { tr } = useTranslation();
  return (
    <div>
      <h2>{props.title || tr("somethingWentWrong")}</h2>
      {props.explanation && <p>{props.explanation}</p>}
    </div>
  );
}
