import moment from "moment";
import React from "react";
import { useTranslation } from "../../App/reducer";
import { Time } from "../../Common/components/Time";

const LabelTableRow = (props: { name: string; children: React.ReactNode }) => (
  <tr>
    <td>
      <strong>{props.name}</strong>
    </td>
    <td>{props.children}</td>
  </tr>
);

interface PublicationApplicationMetadata {
  creator: { name: string | null } | null;
  created: Date | string;
  modified?: Date | string;
  sendDate: Date | string;
  validFrom?: Date | string;
  validTo?: Date | string;
  lastNotified?: string | null;
  lastReminderSent?: string | null;
  sent: boolean;
}

interface Props {
  publication: PublicationApplicationMetadata;
  fields: Array<keyof PublicationApplicationMetadata>;
  style?: any;
}

export function PublicationApplicationMetaInfo(props: Props) {
  const { publication, style } = props;
  const { tr } = useTranslation();

  const fields = props.fields.reduce((out, item) => {
    out[item] = true;
    return out;
  }, {} as { [field in keyof PublicationApplicationMetadata]: true | undefined });

  return (
    <table className="table" style={style}>
      <tbody>
        {fields.creator && (
          <LabelTableRow name={tr("creator")}>
            {publication.creator ? (
              publication.creator.name
            ) : (
              <i>{tr("failedToFetchCreator")}</i>
            )}
          </LabelTableRow>
        )}

        {fields.created && (
          <LabelTableRow name={tr("creationDate")}>
            <Time date={publication.created} />
          </LabelTableRow>
        )}

        {fields.modified && publication.modified && (
          <LabelTableRow name={tr("modified")}>
            <Time date={publication.modified} />
          </LabelTableRow>
        )}

        {fields.sendDate && (
          <LabelTableRow name={tr("sendDate")}>
            <Time date={publication.sendDate} />
          </LabelTableRow>
        )}

        {fields.validFrom &&
        fields.validTo &&
        props.publication.validFrom !== undefined &&
        props.publication.validTo !== undefined ? (
          <LabelTableRow name={tr("validityPeriod")}>
            <Time date={publication.validFrom!} /> -{" "}
            <Time date={publication.validTo!} /> (
            {moment(publication.validTo).diff(
              moment(publication.validFrom),
              "days"
            ) + 1}{" "}
            {tr("days")})
          </LabelTableRow>
        ) : null}

        {fields.lastNotified && (
          <LabelTableRow name={tr("PmiLastMessaged")}>
            {publication.lastNotified ? (
              <Time date={publication.lastNotified} type="relative" />
            ) : publication.sent ? (
              tr("PmiNotMessaged")
            ) : (
              tr("PmiNotYetSent")
            )}
          </LabelTableRow>
        )}
      </tbody>
    </table>
  );
}
