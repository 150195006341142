import React from "react";
import { ConfirmOptions } from "../../Utils/dialogs/confirmTypes";
import { Button } from "./Button";
import { Dialog } from "./Dialog";

interface Props {
  show: boolean;
  proceed: () => void;
  options: ConfirmOptions;
}

export function AlertDialog(props: Props) {
  const { show, proceed, options } = props;
  return (
    <Dialog
      show={show}
      cancel={proceed}
      title={options.title}
      content={options.content ? options.content : options.message}
      size={options.size}
      footer={
        <React.Fragment>
          <Button
            level={options.proceedLevel || "primary"}
            onClick={proceed}
            autoFocus
            label={options.proceedText}
          />
        </React.Fragment>
      }
    />
  );
}
