import * as gqltypes from "./gqltypes";
import type { normalizeSwedishNationalId } from "./regional";

export { User as OIDCUser } from "oidc-client";

export type Optionalize<T> = { [P in keyof T]?: T[P] };

export enum Region {
  sweden = "se",
  norway = "no",
}

export enum Language {
  swedish = "sv",
  norwegian = "nb",
}

export interface RegionSettings {
  // skolidIdp: string;
  FSLetter: string;
  defaultLanguage: gqltypes.ISO6391Language;
  nationalIdRegex: RegExp;
  normalizeNationalId: typeof normalizeSwedishNationalId;
  readableNationalId: (nationalId: string) => string;
}

export interface Settings {
  skolidIdp: string;
  bugSnagApiKey: string;
  releaseStage: "production" | "stage" | "accept" | "demo" | "dev" | "local";
  redirectUri: string;
  signoutRedirectUri: string;
  silentRedirectUri?: string;
  authority: string;
  skolidApiUrl: string;
  apiUrl: string;
  openIdConnect: {
    clientId: string;
  };
  defaultWindowTitle: string;
  useHashRouter: boolean;
  region: Region;
  regionSettings: RegionSettings;
  s3ApiUrl: string;
}

export enum Progress {
  NotStarted,
  InProgress,
  Error,
  Success,
}

export enum SavingState {
  NotSaved,
  Saving,
  Failed,
  Saved,
}

export type ID = string;

export type WithRouteProps<T> = T;

export type ShortTextValidationTypes = null | gqltypes.ShortTextValidationType;

interface FormQuestionShapes {
  multiChoice: {
    type: gqltypes.FormQuestionType.multiChoice;
    options: gqltypes.FormQuestionOptions[];
    predicates?: gqltypes.FormQuestionPredicate[];

    dropdownView?: boolean;
  } & Omit<gqltypes.FormQuestionMultiChoice, "type">;
  checkboxes: {
    type: gqltypes.FormQuestionType.checkboxes;
    options: gqltypes.FormQuestionOptions[];
    predicates?: gqltypes.FormQuestionPredicate[];
  } & Omit<gqltypes.FormQuestionCheckboxes, "type">;
  ranking: {
    type: gqltypes.FormQuestionType.ranking;
    options: gqltypes.FormQuestionOptions[];
    minSelectedOptions?: number;
    maxSelectedOptions?: number;
    predicates?: gqltypes.FormQuestionPredicate[];
  } & Omit<gqltypes.FormQuestionRanking, "type">;
  shortText: {
    type: gqltypes.FormQuestionType.shortText;
    validationType?: ShortTextValidationTypes;
    predicates?: gqltypes.FormQuestionPredicate[];
  } & Omit<gqltypes.FormQuestionShortText, "type">;
  longText: {
    type: gqltypes.FormQuestionType.longText;
    predicates?: gqltypes.FormQuestionPredicate[];
  } & Omit<gqltypes.FormQuestionLongText, "type">;
  yesOrNo: {
    type: gqltypes.FormQuestionType.yesOrNo;
    predicates?: gqltypes.FormQuestionPredicate[];
  } & Omit<gqltypes.FormQuestionYesNo, "type">;
  date: {
    type: gqltypes.FormQuestionType.date;
    predicates?: gqltypes.FormQuestionPredicate[];
  } & Omit<gqltypes.FormQuestionDate, "type">;
}

type FormQuestionWithOptionsUnion =
  | FormQuestionShapes["multiChoice"]
  | FormQuestionShapes["checkboxes"]
  | FormQuestionShapes["ranking"];
type FormQuestion =
  | FormQuestionWithOptionsUnion
  | FormQuestionShapes["shortText"]
  | FormQuestionShapes["longText"]
  | FormQuestionShapes["yesOrNo"]
  | FormQuestionShapes["date"];

type FormQuestionType = keyof FormQuestionShapes;
export type FormQuestionDataTyped<K extends FormQuestionType> =
  FormQuestionShapes[K];
