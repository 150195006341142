import gql from "graphql-tag";
import _ from "lodash";
import * as React from "react";
import { QueryResult, useQuery } from "@apollo/client";
import { graphql, DataValue } from "@apollo/client/react/hoc";
import { Collapse, UncontrolledTooltip } from "reactstrap";
import {
  Translation,
  UserPermission,
  useAdminContext,
  withAdminContext,
  withTranslation,
  withUserPermissions,
} from "../../App/reducer";
import {
  Button,
  ConsentForm,
  FormComponentView,
  FormInput,
  FormSelect,
  ISTContainer,
  Loading,
  RTEditor,
  Table,
} from "../../Common";
import { ConfirmDialog } from "../../Common/components/ConfirmDialog";
import FormSearchSelect from "../../Common/components/FormSearchSelect";
import { Time } from "../../Common/components/Time";
import {
  formComponentDataFragment,
  fullApplicationResponseFragment,
  fullFormTranslationFragment,
} from "../../Common/fragments";
import {
  ORGANISTION_SCHOOLUNITS_QEURY,
  ORGANISTION_SCHOOLUNITS_QEURY_TYPES,
} from "../../Common/queries";
import {
  canPerformAction,
  getApplicationActionErrorCodeLabel,
  getApplicationActionLabel,
  getApplicationStatusLabel,
  getCombinedIdCustomerId,
  getPartsFromCombinedIdCustomerId,
  getPerformableActions,
  notDateEndedFilter,
  permissionResourceIdFromSchoolUnit,
} from "../../Common/utils";
import { richTextIsValid } from "../../Common/validation";
import {
  showAlertDialog,
  showConfirmDialog,
} from "../../Utils/dialogs/showDialog";
import * as f from "../../Utils/functional";
import { removeTypenameRedacted } from "../../Utils/graphql-helpers";
import { client, getAdminContext } from "../../api";
import * as gqltypes from "../../gqltypes";
import {
  defaultDialogCancel,
  defaultDialogProceed,
} from "../../translation/strings";
import { SavingState } from "../../types";
import { getPredicateComponent } from "./CreateForm";
import { useNavigate } from "react-router";
import withRouter from "../../Utils/withRouter";

interface Props extends Translation, UserPermission {
  params: { id: string };
  formData: DataValue<gqltypes.AdminViewApplicationResponse> & QueryResult;
}
interface State {
  saveState: SavingState;
  response?: gqltypes.FormAnswer;
}

const getSuggestedSus = (
  tr: Translation["tr"],
  appRes: gqltypes.AdminViewApplicationResponse["applicationResponse"]
) => {
  const user = appRes.completeSubject.user;

  const enrolments = user?.enrolments?.filter(notDateEndedFilter) || [];
  const placements = user?.placements?.filter(notDateEndedFilter) || [];

  const result: NonNullable<{ value: string; label: string }>[] = [];
  const foundSet = new Set<string>();

  const enrolmentTranslated = tr("enrolment");
  enrolments.forEach((enr) => {
    const { schoolUnit: su } = enr;
    const completeId = su && getCombinedIdCustomerId(su);
    const dupId = `enr_${completeId}`;
    if (su && completeId && !foundSet.has(dupId)) {
      foundSet.add(dupId);
      result.push({
        value: completeId,
        label: `${su.displayName} (${enrolmentTranslated})`,
      });
    }
  });

  const placementTraslated = tr("placement");
  placements.forEach((pl) => {
    const { schoolUnit: su, schoolType } = pl;
    const completeId = su && getCombinedIdCustomerId(su);
    const dupId = `pl_${completeId}_${schoolType}`;
    if (su && completeId && !foundSet.has(dupId)) {
      foundSet.add(dupId);
      result.push({
        value: completeId,
        label: `${su.displayName} (${placementTraslated} ${schoolType})`,
      });
    }
  });

  return result;
};

const ApplicationInfoBox = (props: {
  tr: Translation["tr"];
  appRes: gqltypes.AdminViewApplicationResponse["applicationResponse"];
  editPermission: boolean;
}) => {
  const { tr } = props;
  const { id, lastAction, status, schoolUnit, subject, creator } = props.appRes;

  const currentSu = schoolUnit
    ? {
        value: getCombinedIdCustomerId(schoolUnit),
        label: schoolUnit.displayName,
      }
    : null;

  const [selectedSu, setSelectedSu] = React.useState(currentSu);
  const [showChangeSu, setShowChangeSu] = React.useState(false);
  const suggestedSus = React.useMemo(() => {
    return getSuggestedSus(tr, props.appRes);
  }, [props.appRes, tr]);

  const context = useAdminContext();

  const otherSus = useQuery<
    ORGANISTION_SCHOOLUNITS_QEURY_TYPES["data"],
    ORGANISTION_SCHOOLUNITS_QEURY_TYPES["variables"]
  >(ORGANISTION_SCHOOLUNITS_QEURY, {
    fetchPolicy: "cache-first",
    variables: {
      context,
      suFilter: {
        ignorePermission: true,
      },
    },
  });

  const schoolUnitOptions = React.useMemo(() => {
    const mappedOtherSus =
      otherSus.data?.organisation.schoolUnits
        .filter(function (su) {
          const isSkolaWithoutClasses =
            su.organisationType === gqltypes.SchoolUnitOrganisationType.Skola &&
            !su.classes.length;
          return !isSkolaWithoutClasses;
        })
        .map((su) => ({
          value: getCombinedIdCustomerId(su),
          label: su.displayName,
        })) ?? [];

    return [
      {
        label: tr("viewApplicationAnswersChooseSchoolunitSuggestions"),
        options: suggestedSus,
      },
      {
        label: tr("viewApplicationAnswersChooseSchoolunitAll"),
        options: _.orderBy(mappedOtherSus, "label"),
      },
    ];
  }, [suggestedSus, otherSus.data?.organisation.schoolUnits, tr]);

  const canSetSchoolUnit =
    props.editPermission &&
    canPerformAction(gqltypes.ApplicationResponseAction.set_schoolunit, status);

  const notAssignedLabel = tr("viewApplicationAnswersSchoolunitNotAssigned");

  return (
    <ISTContainer header="Info">
      <div className="p-content">
        {props.appRes.application.schoolunitSpecific ? (
          <div>
            {tr("schoolunit")}:{" "}
            {schoolUnit ? (
              <strong>{schoolUnit.displayName}</strong>
            ) : (
              <span className="text-muted">{notAssignedLabel}</span>
            )}
            {canSetSchoolUnit ? (
              <Button
                label={showChangeSu ? tr("hide") : tr("change")}
                level="link"
                size="btn-sm"
                onClick={() => {
                  setShowChangeSu(!showChangeSu);
                }}
              />
            ) : null}
          </div>
        ) : null}

        {showChangeSu && canSetSchoolUnit ? (
          <>
            <label htmlFor="schoolUnitSelect">{tr("schoolunit")}</label>
            <FormSearchSelect
              id="schoolUnitSelect"
              defaultValue={{ label: notAssignedLabel, value: "" }}
              defaultInputValue=""
              placeholder={notAssignedLabel}
              isClearable
              options={schoolUnitOptions}
              value={selectedSu}
              onChange={(item) => {
                setSelectedSu(item || (null as any));
              }}
            />
            <Button
              className="my-2"
              label={
                currentSu && !selectedSu
                  ? tr("applicationResponseSaveWithoutSchoolunitAssignment")
                  : tr("applicationResponseSaveSchoolunitAssignment")
              }
              level={currentSu && !selectedSu ? "warning" : "primary"}
              disabled={currentSu?.value === selectedSu?.value}
              onClick={() => {
                if (!selectedSu) {
                  // Remove set su
                  performAction(
                    id,
                    lastAction.id,
                    gqltypes.ApplicationResponseAction.set_schoolunit,
                    ""
                  );
                  return;
                }

                const { id: schoolunitId, customerId } =
                  getPartsFromCombinedIdCustomerId(selectedSu.value);
                performAction(
                  id,
                  lastAction.id,
                  gqltypes.ApplicationResponseAction.set_schoolunit,
                  "",
                  { schoolunitId, customerId }
                );
              }}
            />
          </>
        ) : null}

        <div>
          {tr("applicationResponseApplicationCreatedBy")}:{" "}
          {creator ? (
            <span style={{ fontWeight: "bold", display: "inline-block" }}>
              <span>{creator.name}</span>
              {creator.verifiedRelation.isVerifiedRelation === true ? (
                ""
              ) : creator.verifiedRelation.isVerifiedRelation === false &&
                creator.verifiedRelation.customerId === null ? (
                <>
                  <span
                    id={"tooltipApplicationResponse"}
                    data-toggle="tooltip"
                    style={{
                      fontSize: "12px",
                      maxWidth: "90px",
                      minWidth: "90px",
                    }}
                    className="text-truncate"
                  >
                    <i
                      className="fas fa-exclamation-triangle text-warning m-1"
                      style={{
                        color: "var(--yellow)",
                      }}
                    />
                  </span>
                  <UncontrolledTooltip target={"tooltipApplicationResponse"}>
                    {tr("toolTipCreatorHasNoRelationToSubject")}
                  </UncontrolledTooltip>
                </>
              ) : (
                <>
                  <span
                    id={"tooltipApplicationResponse"}
                    data-toggle="tooltip"
                    style={{
                      fontSize: "12px",
                      maxWidth: "90px",
                      minWidth: "90px",
                    }}
                    className="text-truncate"
                  >
                    <i
                      className="fas fa-exclamation-triangle text-warning m-1"
                      style={{
                        color: "var(--yellow)",
                      }}
                    />
                  </span>
                  <UncontrolledTooltip target={"tooltipApplicationResponse"}>
                    {tr(
                      "toolTipCreatorHasNoRelationToSubjectWithCustomerId",
                      creator.verifiedRelation.customerId
                    )}
                  </UncontrolledTooltip>
                </>
              )}
            </span>
          ) : (
            "???"
          )}
        </div>

        <div>
          {tr("applicationResponseApplicationSubject")}:{" "}
          <span style={{ fontWeight: "bold" }}>{subject.name}</span>
        </div>
      </div>
    </ISTContainer>
  );
};

const hasPerformActionPermissionRights = (
  permissions: Props["permissions"],
  appRes: Props["formData"]["applicationResponse"],
  org: string
) => {
  if (!permissions) return false;
  if (!appRes) return false;

  if (appRes.schoolUnit) {
    const suId = permissionResourceIdFromSchoolUnit(org, appRes.schoolUnit);
    return Boolean(permissions.su[suId]?.application_answers);
  }

  return Boolean(permissions.org.application_answers);
};

const ApplicationResponseView = (props: Props) => {
  const navigate = useNavigate();
  const { tr, formData } = props;
  const [componentAnswer, setComponentAnswer] =
    React.useState<gqltypes.FormAnswer>({
      components: [],
    });

  const response:
    | gqltypes.AdminViewApplicationResponse_applicationResponse_response
    | null
    | undefined = props.formData?.applicationResponse?.response;

  React.useEffect(() => {
    if (response) {
      setComponentAnswer(response);
    }
  }, [response]);

  if (formData.loading || !formData.applicationResponse) {
    return <Loading />;
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  const appRes = formData.applicationResponse;
  const form = appRes.application.form;
  const submittedTime = appRes.seenByOrg!;
  const creator: gqltypes.AdminViewApplicationResponse_applicationResponse_creator =
    appRes.creator;

  let translating = false;
  let translation: (typeof form)["translations"][0] | undefined;
  if (form.language !== appRes.language) {
    translating = true;
    translation = form.translations.find((t) => t.language === appRes.language);
  }

  const hasPerformActionPermission = hasPerformActionPermissionRights(
    props.permissions,
    appRes,
    form.owner.id
  );

  const status = appRes.status;

  const actions = hasPerformActionPermission
    ? getPerformableActions("admin", status)
    : [];

  const selectedAFC = form.componentData.components.find(
    (c) => c.applicationFeedbackId === appRes.feedbackId
  );

  const canSeeFeedbackResponse =
    appRes.status === gqltypes.ApplicationResponseStatus.resolved ||
    appRes.status === gqltypes.ApplicationResponseStatus.closed;

  return (
    <div id="form-view">
      <ApplicationInfoBox
        tr={tr}
        appRes={appRes}
        editPermission={hasPerformActionPermission}
      />
      <ConsentForm
        formType={form.type}
        formOwner={form.owner}
        child={appRes.creator}
        formTitle={form.name}
        formDescription={form.description}
        formLanguage={form.language}
        isAdmin
        components={form.componentData.components}
        predicateComponents={form.componentData.predicateComponents || []}
        response={appRes.response || undefined}
        submittedTime={submittedTime}
        availableLanguages={[appRes.language]}
        translating={translating}
        translation={translation}
        readOnly={true}
        creator={creator}
        singleGuardian={appRes.application.singleGuardianSend}
        schoolunitSpecific={appRes.application.schoolunitSpecific}
        selectedApplicationSchoolUnit={appRes.schoolUnit}
      />

      {appRes.feedbackId && selectedAFC && (
        <form id="form-viewer">
          <div className="p-3 bg-warning rounded mb-content">
            <h3>
              {canSeeFeedbackResponse
                ? tr("AdminApplicationResponseFeedbackAnswered")
                : tr("AdminApplicationResponseFeedbackUnanswered")}
            </h3>
            <FormComponentView
              getPredicateComponent={(id) =>
                f.throwWhenError(
                  getPredicateComponent(
                    form.componentData.predicateComponents || undefined,
                    id
                  )
                )
              }
              component={{
                ...selectedAFC,
                description: appRes.feedbackDescription!,
              }}
              answer={
                appRes.response && canSeeFeedbackResponse
                  ? appRes.response.components.find(
                      (c) => c.componentId === selectedAFC.id
                    )
                  : undefined
              }
              response={componentAnswer}
              formLanguage={form.language}
              readOnly
            />
          </div>
        </form>
      )}

      <div className="alert alert-info">
        {getApplicationStatusLabel(tr, status)}
      </div>

      {actions.length ? (
        <div className="p-content mb-content">
          <ActionContainer
            tr={tr}
            form={form}
            appRes={appRes}
            status={appRes.status}
            refetchFormData={props.formData.refetch}
            perform={(action, comment, extraData) =>
              performAction(
                appRes.id,
                appRes.lastAction.id,
                action,
                comment,
                extraData
              )
            }
          />
        </div>
      ) : null}

      <HistoryTable tr={tr} actions={appRes.actions} />
      <div className="actions mb-3">
        <Button onClick={handleGoBack} level="secondary" label={tr("goBack")} />
      </div>
    </div>
  );
};

interface HistoryTableProps {
  tr: Translation["tr"];
  actions: gqltypes.AdminViewApplicationResponse["applicationResponse"]["actions"];
}

const HistoryTable = (props: HistoryTableProps) => {
  const { tr } = props;
  return (
    <ISTContainer header={tr("applicationResponseHistoryHeader")}>
      <Table
        initialOrder="date"
        initialOrderDir="asc"
        unsortable
        headers={[
          {
            key: "action",
            element: tr("applicationResponseHistoryColumnAction"),
          },
          {
            key: "newStatus",
            element: tr("applicationResponseHistoryColumnNewStatus"),
          },
          {
            key: "comment",
            element: tr("applicationResponseHistoryColumnComment"),
          },
          {
            key: "user",
            element: tr("applicationResponseHistoryColumnUser"),
          },
          {
            key: "date",
            element: tr("applicationResponseHistoryColumnDate"),
            usingSortValue: true,
          },
        ]}
        rows={props.actions.map((a) => ({
          key: a.id,
          columns: {
            action: { content: getApplicationActionLabel(tr, a.action) },
            newStatus: { content: getApplicationStatusLabel(tr, a.newStatus) },
            comment: {
              content:
                a.comment ||
                getApplicationActionErrorCodeLabel(tr, a.errorCode) ||
                "",
            },
            user: { content: a.actor ? a.actor.name : "?" || "" },
            date: {
              content: <Time type="calendar" date={a.created} />,
              sortValue: a.created,
            },
          },
        }))}
      />
    </ISTContainer>
  );
};

interface ActionContainerProps {
  tr: Translation["tr"];
  form: gqltypes.AdminViewApplicationResponse["applicationResponse"]["application"]["form"];
  status: gqltypes.AdminViewApplicationResponse["applicationResponse"]["status"];
  appRes?: gqltypes.AdminViewApplicationResponse_applicationResponse;
  refetchFormData?: () => void;
  perform: (
    action: gqltypes.ApplicationResponseAction,
    comment: string,
    extraData?: PerformActionExtras
  ) => void;
  multiEditMode?: boolean;
  numberOfEdits?: number;
}

export const ActionContainer = (props: ActionContainerProps) => {
  const { appRes, tr } = props;
  const [dialogType, setDialogType] = React.useState<
    | "close"
    | "complement"
    | "assign"
    | "ack"
    | "feedback"
    | "manualClose"
    | null
  >(null);
  const [comment, setComment] = React.useState("");
  const [applicationAction, setApplicationAction] =
    React.useState<gqltypes.ApplicationResponseAction>();
  const [feedbackId, setFeedbackId] = React.useState<string>();
  const [feedbackDesc, setFeedbackDesc] = React.useState<string>();

  React.useEffect(() => closeConfirmDialog(), [props.status]);

  const closeConfirmDialog = () => {
    setComment("");
    setApplicationAction(undefined);
    setDialogType(null);
  };

  const editManyText = props.numberOfEdits
    ? tr("AdminApplicationResponseEditManyText", props.numberOfEdits)
    : null;

  let contents: React.ReactElement | null = null;

  const perform = (action: gqltypes.ApplicationResponseAction) => () => {
    props.perform(action, comment, {
      feedbackId,
      feedbackDescription: feedbackDesc,
    });
  };

  const closeButton = (
    <Button
      className="col-auto mr-content"
      label={tr("AdminApplicationResponseCloseButtonLabel")}
      level="danger"
      onClick={() => setDialogType("close")}
    />
  );

  const closeDialog = (
    <ConfirmDialog
      show={dialogType === "close"}
      proceed={perform(gqltypes.ApplicationResponseAction.reject_input)}
      dismiss={closeConfirmDialog}
      cancel={closeConfirmDialog}
      awaitingProceedFunc={false}
      disabledProceed={!Boolean(comment)}
      options={{
        title: tr("AdminApplicationResponseCloseTitle"),
        proceedText: tr("AdminApplicationResponseProceedText"),
        proceedLevel: "danger",
        cancelText: defaultDialogCancel(tr),
        content: (
          <div>
            <p>{tr("AdminApplicationResponseCloseDescription")}</p>
            <p>{editManyText}</p>
            <FormInput
              id="comment"
              label={tr("AdminApplicationResponseCommentLabel")}
              value={comment}
              onChange={(e) => setComment(e.currentTarget.value)}
            />
          </div>
        ),
      }}
    />
  );

  const selectApplicationAction = (action: string) => {
    const actionType: gqltypes.ApplicationResponseAction =
      action as gqltypes.ApplicationResponseAction;
    if (actionType) {
      setApplicationAction(actionType);
      setComment("");
    }
  };

  const manualCloseDialog = (
    <ConfirmDialog
      show={dialogType === "manualClose"}
      proceed={
        applicationAction
          ? perform(applicationAction)
          : () => {
              return;
            }
      }
      dismiss={closeConfirmDialog}
      cancel={closeConfirmDialog}
      awaitingProceedFunc={false}
      disabledProceed={
        applicationAction === gqltypes.ApplicationResponseAction.reject_input
          ? !Boolean(comment)
          : !Boolean(applicationAction)
      }
      options={{
        title: tr("AdminApplicationResponseManualCloseTitle"),
        proceedText: tr("AdminApplicationResponseManualCloseProceedText"),
        cancelText: defaultDialogCancel(tr),
        content: (
          <div>
            <FormSelect
              id="action"
              label={tr("AdminApplicationResponseManualCloseSelectActionLabel")}
              value={applicationAction}
              defaultOption={tr(
                "AdminApplicationResponseManualCloseSelectAction"
              )}
              onChange={(e) => selectApplicationAction(e.currentTarget.value)}
              options={[
                {
                  value: gqltypes.ApplicationResponseAction.ack,
                  label: tr(
                    "AdminApplicationResponseManualCloseSelectActionAck"
                  ),
                },
                {
                  value: gqltypes.ApplicationResponseAction.reject_input,
                  label: tr(
                    "AdminApplicationResponseManualCloseSelectActionRejectInput"
                  ),
                },
              ]}
            />
            {applicationAction ===
            gqltypes.ApplicationResponseAction.reject_input ? (
              <div>
                <p>
                  {tr(
                    "AdminApplicationResponseCloseDescription",
                    "Meddela ansökare varför du stänger ansökan."
                  )}
                </p>
                <FormInput
                  id="comment"
                  label={tr(
                    "AdminApplicationResponseCommentLabel",
                    "Kommentar"
                  )}
                  value={comment}
                  onChange={(e) => setComment(e.currentTarget.value)}
                />
              </div>
            ) : null}
          </div>
        ),
      }}
    />
  );

  const complementButton = (
    <Button
      className="col-auto"
      label={tr("AdminApplicationResponseComplementButtonLabel")}
      level="warning"
      onClick={() => setDialogType("complement")}
    />
  );

  const assignDialog = (
    <ConfirmDialog
      show={dialogType === "assign"}
      proceed={perform(gqltypes.ApplicationResponseAction.assign)}
      dismiss={closeConfirmDialog}
      cancel={closeConfirmDialog}
      awaitingProceedFunc={false}
      options={{
        title: tr("AdminApplicationResponseAssignTitle"),
        message: editManyText || undefined,
        proceedText: tr("AdminApplicationResponseAssignActionLabel"),
        cancelText: defaultDialogCancel(tr),
      }}
    />
  );

  const ackDialog = (
    <ConfirmDialog
      show={dialogType === "ack"}
      proceed={perform(gqltypes.ApplicationResponseAction.ack)}
      dismiss={closeConfirmDialog}
      cancel={closeConfirmDialog}
      awaitingProceedFunc={false}
      options={{
        title: tr("AdminApplicationResponseAckTitle"),
        message: editManyText || undefined,
        proceedText: tr("AdminApplicationResponseAckActionLabel"),
        cancelText: defaultDialogCancel(tr),
      }}
    />
  );

  const complementDialog = (
    <ConfirmDialog
      show={dialogType === "complement"}
      proceed={perform(gqltypes.ApplicationResponseAction.complement)}
      dismiss={closeConfirmDialog}
      cancel={closeConfirmDialog}
      awaitingProceedFunc={false}
      disabledProceed={!Boolean(comment)}
      options={{
        title: tr("AdminApplicationResponseComplementTitle"),
        proceedText: tr("AdminApplicationResponseComplementProceedText"),
        proceedLevel: "warning",
        cancelText: defaultDialogCancel(tr),
        content: (
          <div>
            <p>{tr("AdminApplicationResponseComplementDescription")}</p>
            <FormInput
              id="comment"
              label={tr("AdminApplicationResponseCommentLabel")}
              value={comment}
              onChange={(e) => setComment(e.currentTarget.value)}
            />
          </div>
        ),
      }}
    />
  );

  const feedbackOptions = props.form.componentData.components.reduce(
    (count, item) => (count += item.applicationFeedbackId ? 1 : 0),
    0
  );

  const shouldShowFeedbackOptions =
    feedbackOptions > 0 &&
    (props.status === gqltypes.ApplicationResponseStatus.under_process ||
      (props.form.subType === gqltypes.FormSubType.outMigration &&
        props.status === gqltypes.ApplicationResponseStatus.sent_to_org));

  let SelectFeedbackComponent: null | React.ReactNode = null;
  if (shouldShowFeedbackOptions) {
    // eslint-disable-next-line no-inner-declarations
    function onSelectFeedback(
      afc: gqltypes.AdminViewApplicationResponse_applicationResponse_application_form_componentData_components
    ) {
      console.log("selected feedback: %s", afc.applicationFeedbackId);
      if (!afc.applicationFeedbackId) return;
      if (afc.applicationFeedbackId === feedbackId) {
        setFeedbackId(undefined);
        setFeedbackDesc(undefined);
        return;
      }
      const desciprtion =
        props.form.componentData.components.find(
          (c) => c.applicationFeedbackId === afc.applicationFeedbackId
        )?.description || "";
      setFeedbackId(afc.applicationFeedbackId);
      setFeedbackDesc(desciprtion);
    }

    // eslint-disable-next-line no-inner-declarations
    async function onSendFeedback() {
      if (!feedbackId) {
        throw new Error("Tried to send feedback without a feedbackId");
      }
      if (!richTextIsValid(feedbackDesc)) {
        showAlertDialog({
          title: tr("AdminApplicationResponseSendFeedbackMissingMessageTitle"),
          message: tr(
            "AdminApplicationResponseSendFeedbackMissingMessageMessage"
          ),
          proceedText: defaultDialogProceed(tr),
          cancelText: defaultDialogCancel(tr),
        });
        return;
      }
      const confirmed = await showConfirmDialog({
        title: tr("AdminApplicationResponseFeedbackTitle"),
        message: editManyText || undefined,
        proceedText: tr("AdminApplicationResponseSendFeedback"),
        cancelText: defaultDialogCancel(tr),
      });
      if (confirmed) {
        props.perform(gqltypes.ApplicationResponseAction.send_feedback, "", {
          feedbackId,
          feedbackDescription: feedbackDesc,
        });
      }
    }

    SelectFeedbackComponent = (
      <React.Fragment>
        <form id="form-viewer">
          <div className="row">
            {props.form.componentData.components
              .filter((c) => Boolean(c.applicationFeedbackId))
              .map((afc) => {
                const isSelected = feedbackId === afc.applicationFeedbackId;
                const classNames = isSelected
                  ? " bg-warning rounded rounded-lg"
                  : "";
                return (
                  <div
                    key={afc.id}
                    className={`col-6 pt-content mb-2 ${classNames} clickable`}
                    onClick={() => onSelectFeedback(afc)}
                  >
                    <FormComponentView
                      getPredicateComponent={(id) =>
                        f.throwWhenError(
                          getPredicateComponent(
                            props.form.componentData.predicateComponents ||
                              undefined,
                            id
                          )
                        )
                      }
                      component={
                        isSelected && feedbackDesc !== null
                          ? { ...afc, description: feedbackDesc }
                          : afc
                      }
                      formLanguage={props.form.language}
                      readOnly={!isSelected}
                    />
                  </div>
                );
              })}
          </div>
        </form>
        {feedbackId && (
          <div className="p-content rounded bg-white">
            <h4>{tr("AdminApplicationResponseFeedbackMessage")}</h4>
            <RTEditor
              key={feedbackId}
              toolbarLayout="mini"
              initialContentState={feedbackDesc}
              onChange={setFeedbackDesc}
            />
            {props.form.subType !== gqltypes.FormSubType.outMigration && (
              <div className="row mt-content">
                <div className="col-auto ml-auto">
                  <Button
                    label={tr("AdminApplicationResponseSendFeedback")}
                    level="success"
                    onClick={onSendFeedback}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }

  switch (props.status) {
    case gqltypes.ApplicationResponseStatus.sent_to_org: {
      const label =
        props.form.subType !== gqltypes.FormSubType.general
          ? tr("AdminApplicationResponseApproveLabel")
          : tr("AdminApplicationResponseAssignActionLabel");
      contents = (
        <React.Fragment>
          {props.form.subType === gqltypes.FormSubType.outMigration &&
            feedbackOptions > 0 && (
              <div className="col-12 p-content">
                <h1>{tr("AdminApplicationResponseChooseFeedback")}</h1>
                <p>{tr("AdminApplicationResponseChooseFeedbackDescription")}</p>
                {SelectFeedbackComponent}
              </div>
            )}
          <div className="col-auto">
            {closeButton}
            {props.form.subType === gqltypes.FormSubType.outMigration
              ? complementButton
              : null}
          </div>
          <div className="col-auto ml-auto">
            <Button
              label={label}
              level="success"
              onClick={
                props.multiEditMode
                  ? () => setDialogType("assign")
                  : perform(gqltypes.ApplicationResponseAction.assign)
              }
            />
          </div>
        </React.Fragment>
      );
      break;
    }
    case gqltypes.ApplicationResponseStatus.under_process: {
      contents = (
        <React.Fragment>
          {feedbackOptions > 0 ? (
            <div className="col-12 p-content">
              <h1>{tr("AdminApplicationResponseChooseFeedback")}</h1>
              {SelectFeedbackComponent}
              {props.form.subType !== gqltypes.FormSubType.outMigration && (
                <h1 className="px-content">
                  {tr("AdminApplicationResponseFeedbackOr")}
                </h1>
              )}
            </div>
          ) : null}
          <div className="col-auto">
            {closeButton}
            {complementButton}
          </div>
          <div className="col-auto ml-auto">
            <Button
              label={tr("AdminApplicationResponseSendNoFeedback")}
              level="warning"
              onClick={async () => {
                const confirmed = await showConfirmDialog({
                  title: tr("AdminApplicationResponseSendNoFeedback"),
                  message: editManyText || undefined,
                  proceedText: tr("AdminApplicationResponseSendNoFeedback"),
                  cancelText: defaultDialogCancel(tr),
                });

                if (confirmed) {
                  props.perform(
                    gqltypes.ApplicationResponseAction.no_feedback,
                    ""
                  );
                }
              }}
            />
          </div>
        </React.Fragment>
      );
      if (props.form.subType !== gqltypes.FormSubType.general) {
        contents = (
          <ActionContainerUnderProcessNonGeneral
            tr={tr}
            lastAction={props.appRes?.lastAction}
            refetchFormData={props.refetchFormData}
            onClickRetry={() => {
              props.perform(
                gqltypes.ApplicationResponseAction.retry_automatic_action,
                ""
              );
            }}
          >
            {contents}
          </ActionContainerUnderProcessNonGeneral>
        );
        break;
      }
      break;
    }
    case gqltypes.ApplicationResponseStatus.resolved:
      contents = (
        <div className="col-auto ml-auto">
          <Button
            label={tr("AdminApplicationResponseAckActionLabel")}
            level="success"
            onClick={
              props.multiEditMode
                ? () => setDialogType("ack")
                : perform(gqltypes.ApplicationResponseAction.ack)
            }
          />
        </div>
      );
      break;
    case gqltypes.ApplicationResponseStatus.awaiting_signature: {
      const appResResponse = appRes?.response;
      if (!appRes || !appResResponse) break;
      contents = (
        <div className="col-auto ml-auto">
          <Button
            label={tr("AdminApplicationResponseAwaitingSignature")}
            onClick={() => {
              performActionSign(appRes.id, appResResponse, appRes.language);
            }}
          />
        </div>
      );
      break;
    }
    case gqltypes.ApplicationResponseStatus.editable: {
      contents = (
        <div className="col-auto ml-auto">
          <Button
            onClick={() => setDialogType("manualClose")}
            level="danger"
            label={tr("AdminApplicationResponseManualCloseButtonLabel")}
          />
        </div>
      );
      break;
    }
  }
  if (contents) {
    return (
      <div className="row">
        {contents}
        {closeDialog}
        {complementDialog}
        {assignDialog}
        {ackDialog}
        {manualCloseDialog}
      </div>
    );
  }
  return null;
};

const ActionContainerUnderProcessNonGeneral = (props: {
  children: React.ReactNode;
  tr: Translation["tr"];
  onClickRetry: () => void;
  refetchFormData?: () => void;
  lastAction?: gqltypes.AdminViewApplicationResponse_applicationResponse["lastAction"];
}) => {
  const { lastAction, refetchFormData, tr } = props;
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const retryTimeout = React.useRef<null | NodeJS.Timeout>(null);
  const timeRetryTotal = React.useRef(0);
  const timeNow = Date.now();
  const timeLastAction = lastAction
    ? new Date(lastAction.created).getTime()
    : timeNow;
  const timeUntilRetryBtn = 120000;
  const lastActionWasError =
    lastAction?.newStatus ===
      gqltypes.ApplicationResponseStatus.under_process &&
    lastAction?.action === gqltypes.ApplicationResponseAction.report_error;
  const showRetryImmediately =
    lastActionWasError || timeNow - timeLastAction > timeUntilRetryBtn;
  const [showRetry, setShowRetry] = React.useState(showRetryImmediately);
  const [showControls, setShowControls] = React.useState(showRetry);

  // Show manual controls button in five seconds if not yet visible
  React.useEffect(() => {
    if (showControls) return;
    const t = setTimeout(() => setShowControls(true), 5000);
    return () => clearTimeout(t);
  }, [showControls]);

  /*
   * Unless the retry button is already visible (because it's been over two
   * minutes since last action) this effect will refetch form data every at
   * X seconds interval until a new status has been found. X is different
   * depending on how long it has been since last action. If total time since
   * starting fetching is greater that 120 seconds it will stop attempting to
   * refetch and instead show a retry button. In total it will attempt to
   * fetch form data 10 times.
   * This shows at which seconds after mount a new form data fetch would occur:
   * FIRST FETCH:       2
   * AFTER FIRST FETCH: 9  -> 16 -> 23 -> 30          (7 seconds interval)
   * AFTER 30 SEONDS:   46 -> 62 -> 78 -> 94 -> 110  (16 seconds interval)
   * AFTER 120 SECONDS: 126     (toggles on retry button, ends fetch loop)
   */
  React.useEffect(() => {
    if (showRetryImmediately) {
      setShowRetry(true);
      setShowControls(true);
      return;
    }
    function setRetryTimeout(fn: () => void, delay: number) {
      timeRetryTotal.current += delay;
      retryTimeout.current = setTimeout(fn, delay);
    }
    function onTimeout() {
      if (!refetchFormData) return;
      if (timeRetryTotal.current < timeUntilRetryBtn) {
        refetchFormData();
        const delay = timeRetryTotal.current < 30000 ? 7000 : 16000;
        setRetryTimeout(onTimeout, delay);
      } else {
        timeRetryTotal.current = 0;
        setShowRetry(true);
      }
    }
    setRetryTimeout(onTimeout, 2000);
    return () => {
      if (retryTimeout.current !== null) clearTimeout(retryTimeout.current);
    };
  }, [refetchFormData, showRetryImmediately]);

  return (
    <React.Fragment>
      <div className="p-content md-content w-100">
        <div className="row">
          <div className="col-auto">
            {showControls && (
              <Button
                label={tr("AdminApplicationResponseUnderProcessManualControls")}
                level="secondary"
                onClick={() => setIsCollapsed(!isCollapsed)}
              />
            )}
          </div>
          <div className="col-auto ml-auto">
            {showRetry ? (
              <Button
                label={tr("AdminApplicationResponseUnderProcessRetry")}
                level="primary"
                onClick={() => {
                  setShowRetry(false);
                  props.onClickRetry();
                }}
              />
            ) : (
              <i className="fa fa-spinner fa-spin mr-2" />
            )}
          </div>
        </div>
      </div>
      <div className="p-content md-content w-100">
        <Collapse isOpen={!isCollapsed}>
          <div className="alert alert-info">
            {tr("AdminApplicationResponseUnderProcessWarning")}
          </div>
          <div className="row">{props.children}</div>
        </Collapse>
      </div>
    </React.Fragment>
  );
};

type PerformActionExtras = {
  feedbackId?: string;
  feedbackDescription?: string;
  schoolunitId?: string;
  customerId?: string;
};

const performAction = (
  responseId: string,
  lastEvent: string,
  action: gqltypes.ApplicationResponseAction,
  comment: string,
  extras?: PerformActionExtras
) => {
  const context = getAdminContext();

  client.mutate<
    gqltypes.AdminApplicationResponseAction,
    gqltypes.AdminApplicationResponseActionVariables
  >({
    mutation: gql`
      mutation AdminApplicationResponseAction(
        $input: ActionInput!
        $context: Context!
      ) {
        performApplicationResponseAction(input: $input, context: $context) {
          ...FullApplicationResponse
          actions {
            id
            actor {
              id
              name
            }
            created
            action
            comment
            errorCode
            newStatus
          }
          application {
            id
            name
            singleGuardianSend
            singleGuardianAcceptOffer
            form {
              id
              type
              subType
              name
              description
              language
              componentData {
                ...FormComponentData
              }
              translations {
                ...FullTranslation
              }
              owner {
                id
                displayName
              }
            }
          }
        }
      }
      ${fullApplicationResponseFragment}
      ${formComponentDataFragment}
      ${fullFormTranslationFragment}
    `,
    variables: {
      context,
      input: {
        responseId,
        previousEventId: lastEvent,
        action,
        comment,
        ...extras,
      },
    },
  });
};

const performActionSign = (
  responseId: string,
  response: State["response"],
  language: gqltypes.ISO6391Language
) => {
  const context = getAdminContext();
  if (!response) {
    console.error("signApplicationResponse: missing response", response);
    throw new Error("missing response");
  }
  client.mutate<
    gqltypes.AdminSignApplicationResponse,
    gqltypes.AdminSignApplicationResponseVariables
  >({
    mutation: gql`
      mutation AdminSignApplicationResponse(
        $responseId: ID!
        $response: FormAnswerInput!
        $language: ISO6391Language!
        $context: Context
      ) {
        signApplicationResponse(
          responseId: $responseId
          response: $response
          language: $language
          context: $context
        ) {
          ...FullApplicationResponse
          actions {
            id
            actor {
              id
              name
            }
            created
            action
            comment
            errorCode
            newStatus
          }
        }
      }
      ${fullApplicationResponseFragment}
    `,
    variables: {
      responseId,
      response: removeTypenameRedacted(response),
      language,
      context,
    },
  });
};

const withFormData = graphql<
  Props,
  gqltypes.AdminViewApplicationResponse,
  gqltypes.AdminViewApplicationResponseVariables,
  any
>(
  gql`
    query AdminViewApplicationResponse($id: ID!, $context: Context!) {
      applicationResponse(id: $id, context: $context) {
        ...FullApplicationResponse
        completeSubject: subject {
          user {
            id
            source
            customerId
            enrolments {
              schoolUnit {
                id
                customerId
                displayName
              }
              schoolType
              startDate
              endDate
            }
            placements {
              schoolUnit {
                id
                customerId
                displayName
              }
              schoolType
              startDate
              endDate
            }
          }
        }
        actions {
          id
          actor {
            id
            name
          }
          created
          action
          comment
          newStatus
        }
        application {
          id
          name
          singleGuardianSend
          singleGuardianAcceptOffer
          schoolunitSpecific
          form {
            id
            type
            subType
            name
            description
            language
            componentData {
              ...FormComponentData
            }
            translations {
              ...FullTranslation
            }
            owner {
              id
              displayName
            }
          }
        }
      }
    }
    ${fullApplicationResponseFragment}
    ${formComponentDataFragment}
    ${fullFormTranslationFragment}
  `,
  {
    name: "formData",
    options: (props) => ({
      variables: { id: props.params.id, context: getAdminContext() },
    }),
  }
);

export const ApplicationResponseContainer = _.flowRight(
  withRouter,
  withTranslation,
  withAdminContext,
  withFormData,
  withUserPermissions
)(ApplicationResponseView);
