import * as React from "react";
import { Translation, withTranslation } from "../../App/reducer";
import { GenericError } from "../../Common/components/GenericError";

function FormDoesNotExistInner(props: Translation) {
  const { tr } = props;
  return (
    <GenericError
      title={tr("formDoesNotExistTitle")}
      explanation={tr("formDoesNotExistDescription")}
    />
  );
}

export const FormDoesNotExist = withTranslation(FormDoesNotExistInner);
