import React from "react";
import { ConfirmOptions } from "./confirmTypes";
import confirmable, { ConfirmableComponentProps } from "./confirmable";
import { createRoot } from "react-dom/client";

function createConfirmation<ComponentProps extends ConfirmableComponentProps>(
  Component: React.ComponentType<ComponentProps>,
  unmountDelay = 1000
) {
  return (options: ConfirmOptions) => {
    const ConfirmableComponent: React.ComponentClass<any> = confirmable(
      // TODO: Oscar wat
      Component
    );
    const wrapper = document.body.appendChild(document.createElement("div"));

    let component: any | null;
    const root = createRoot(wrapper!);

    const promise = new Promise((resolve, reject) => {
      try {
        root.render(
          React.createElement(() => (
            <ConfirmableComponent
              reject={reject}
              resolve={resolve}
              dispose={dispose}
              ref={(ref) => (component = ref)}
              options={options}
            />
          ))
        );
      } catch (e) {
        console.error(e);
        throw e;
      }
    });

    function dispose() {
      setTimeout(() => {
        root.unmount();
        setTimeout(() => {
          if (wrapper && wrapper.parentElement) {
            wrapper.parentElement.removeChild(wrapper);
            if (options.onClose) {
              options.onClose();
            }
          }
        });
      }, unmountDelay);
    }

    return promise
      .then(
        () => {
          if (options.proceedFunc) {
            component.signalWaiting(true);
            return options.proceedFunc(component).then(() => {
              dispose();
              return true;
            });
          } else {
            dispose();
            return true;
          }
        },
        () => {
          if (options.onCancel) {
            options.onCancel();
          }
          dispose();
          return false;
        }
      )
      .catch((e) => {
        dispose();
        return false;
      });
  };
}

export default createConfirmation;
