import gql from "graphql-tag";
import * as React from "react";
import { Query } from "@apollo/client/react/components";
import { info as buildInfo } from "../../buildinfo";
import * as gqltypes from "../../gqltypes";
import { getSettings } from "../../settings";
import { Translation, withTranslation } from "../reducer";
import istLogoUrl from "./ist-logo.png";

const isNotProd = getSettings().releaseStage !== "production";

const istUrl = "https://ist.com/";

const FooterInner = (props: Translation) => (
  <footer>
    <div className="footercontent">
      <img src={istLogoUrl} alt="Logo" />
      <div>
        <span>
          {props.tr("serviceBy")}{" "}
          <strong>
            <a href={istUrl} target="_blank" rel="noreferrer">
              IST
            </a>
          </strong>
          .
        </span>
        <span>
          © IST AB 2018. {props.tr("responsibleForService")}{" "}
          <strong>
            <a href={istUrl} target="_blank" rel="noreferrer">
              IST
            </a>
          </strong>
        </span>
        {isNotProd ? (
          <React.Fragment>
            <Query<gqltypes.BuildInfoFooter>
              skip={!isNotProd}
              fetchPolicy="cache-first"
              query={gql`
                query BuildInfoFooter {
                  buildInfo {
                    version
                  }
                }
              `}
            >
              {({ loading, error, data }) => {
                if (loading || !data || error) {
                  return null;
                }
                return <span>API: {data.buildInfo.version}</span>;
              }}
            </Query>
            <span>Portal: {buildInfo.version}</span>
          </React.Fragment>
        ) : (
          <span>Version: {buildInfo.version}</span>
        )}
      </div>
    </div>
  </footer>
);

export const Footer = withTranslation(FooterInner);
