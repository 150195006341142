export function copyToClipboard(el: HTMLElement | null) {
  if (!el) {
    console.warn("Attempting to copy, but element not defined");
    return;
  }
  const body = document.body;
  if (document.createRange && window.getSelection) {
    const docRange = document.createRange();
    const selection = window.getSelection();
    if (selection !== null) {
      selection.removeAllRanges();
      try {
        docRange.selectNodeContents(el);
        selection.addRange(docRange);
        document.execCommand("Copy");
        selection.removeAllRanges();
      } catch (e) {
        docRange.selectNode(el);
        selection.addRange(docRange);
      }
    }
  } else if ((body as any).createTextRange) {
    // IE
    const docRange = (body as any).createTextRange();
    docRange.moveToElementText(el);
    docRange.select();
    docRange.execCommand("Copy");
  }
}
