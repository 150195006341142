import * as React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Translation, withTranslation } from "../../App/reducer";
import { nonEmptyString } from "../../Utils/functional";
import * as gqltypes from "../../gqltypes";
import { getTr, languageNames } from "../../translation";
import { SavingState } from "../../types";
import { FormInput, RTEditor, RTViewer } from "../index";
import { richTextIsValid } from "../validation";
import { OrgLogo } from "./OrgLogo";
import { Time } from "./Time";

interface LanguageDropdownProps {
  tr: Translation["tr"];
  currentLanguage: gqltypes.ISO6391Language;
  availableLanguages: gqltypes.ISO6391Language[];
  handleLanguageChange?: (language: gqltypes.ISO6391Language) => void;
}

const LanguageDropdown = (props: LanguageDropdownProps) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        className="bg-primary text-white"
        caret={
          props.availableLanguages.length > 1 &&
          Boolean(props.handleLanguageChange)
        }
        aria-haspopup
        disabled={!props.handleLanguageChange}
        aria-label={languageNames[props.currentLanguage]}
      >
        {props.currentLanguage.toUpperCase()}
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem header>{props.tr("language")}</DropdownItem>
        {props.availableLanguages.map((language) => (
          <DropdownItem
            key={language}
            id={language}
            active={props.currentLanguage === language}
            onClick={(e: any) => {
              if (props.handleLanguageChange) {
                props.handleLanguageChange(e.currentTarget.id);
              }
            }}
          >
            {languageNames[language]}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

function isValid(formTitle?: string, formDescription?: string | null) {
  return nonEmptyString(formTitle) && richTextIsValid(formDescription);
}

interface Props extends Translation {
  canEdit: boolean;
  editingHeaderComponent: boolean;
  editHeaderComponent: (event: React.MouseEvent<HTMLDivElement>) => void;
  handleTitleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleTranslationTitleChange?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleDescriptionChange?: (value: string) => void;
  handleTranslationDescriptionChange?: (value: string) => void;
  formTitle?: string;
  formDescription?: string | null;
  formLanguage: gqltypes.ISO6391Language;
  child?: Pick<gqltypes.User, "name" | "firstName" | "lastName"> | null;
  classAndSchool?: string;
  savingState: SavingState;
  validate?: boolean;
  submittedTime?: string;
  formOwner: Pick<gqltypes.Form["owner"], "id" | "displayName">;
  translation?: gqltypes.FullTranslation | null;
  translating?: boolean;
  availableLanguages?: gqltypes.ISO6391Language[];
  displayAvailableLanguages?: boolean;
  handleLanguageChange?: (language: gqltypes.ISO6391Language) => void;
  formType: gqltypes.FormType;
  children?: React.ReactNode;
  ignoreBodyEditClick?: boolean;
}

function FormHeaderInner(props: Props) {
  const { tr, formDescription, translating, translation } = props;

  const displayValues = translating
    ? {
        title: translation ? translation.name : "",
        description: translation ? translation.description : "",
      }
    : {
        title: props.formTitle,
        description: formDescription,
      };

  const indicateInvalid =
    props.validate && !isValid(displayValues.title, displayValues.description);
  const formHeaderClasses =
    "form-section" +
    (props.editingHeaderComponent ? " is-editing" : "") +
    (indicateInvalid ? " invalid" : "");

  const currentLanguage =
    translating && translation ? translation.language : props.formLanguage;

  return props.editingHeaderComponent ? (
    <div className={formHeaderClasses} onClick={props.editHeaderComponent}>
      <div>
        <h3>{tr("formNameAndDescription")}</h3>
        <FormInput
          className="horizontal"
          value={displayValues.title || ""}
          onChange={
            translating
              ? props.handleTranslationTitleChange
              : props.handleTitleChange
          }
          id="formTitle"
          label={tr("formHeaderTitleLabel").toUpperCase()}
          placeholder={tr("formHeaderTitleLabel")}
          required={true}
          feedback={
            indicateInvalid && !nonEmptyString(displayValues.title)
              ? tr("validationAnswerCompulsoryButMissing")
              : ""
          }
          subText={
            translating
              ? `${props.formLanguage}: ${props.formTitle}`
              : undefined
          }
        />
        <label id="editor-description">
          {tr("formHeaderDescriptionLabel")}
        </label>
        <RTEditor
          initialContentState={displayValues.description}
          ariaDescribedBy="editor-description"
          toolbarLayout="mini"
          feedback={
            indicateInvalid && !richTextIsValid(displayValues.description)
              ? tr("validationAnswerCompulsoryButMissing")
              : ""
          }
          onChange={
            translating
              ? props.handleTranslationDescriptionChange
              : props.handleDescriptionChange
          }
        />
        {translating && (
          <div style={{ opacity: 0.5 }}>
            {props.formLanguage}: <RTViewer value={formDescription} />
          </div>
        )}
      </div>
    </div>
  ) : (
    <FormHeaderView
      {...props}
      uiTr={tr}
      tr={getTr(currentLanguage)}
      currentLanguage={currentLanguage}
      title={displayValues.title}
      description={displayValues.description}
      indicateInvalid={indicateInvalid}
    />
  );
}

interface FormHeaderViewProps
  extends Pick<
    Props,
    | "tr"
    | "child"
    | "formOwner"
    | "editHeaderComponent"
    | "canEdit"
    | "availableLanguages"
    | "handleLanguageChange"
    | "submittedTime"
    | "classAndSchool"
  > {
  uiTr: Translation["tr"];
  currentLanguage: gqltypes.ISO6391Language;
  title: string | undefined;
  description: string | null | undefined;
  indicateInvalid: boolean | undefined;
  formType: gqltypes.FormType;
  children?: React.ReactNode;
  ignoreBodyEditClick?: boolean;
}

const FormHeaderView = (props: FormHeaderViewProps) => {
  const { tr, indicateInvalid, child, classAndSchool } = props;
  return (
    <React.Fragment>
      <OrgLogo
        id={props.formOwner.id}
        height={45}
        className="m-content"
        fallbackText={props.formOwner.displayName}
      />
      <div
        className={
          "box form-head-section" + (indicateInvalid ? " invalid" : "")
        }
        onClick={
          props.canEdit && !props.ignoreBodyEditClick
            ? props.editHeaderComponent
            : undefined
        }
      >
        <div className="box-header header">
          <span>{props.title || props.tr("formHeaderTitleLabel")}</span>
          {props.canEdit && (
            <div className="form-component-actions">
              <i
                onClick={props.editHeaderComponent}
                className="fas fa-pencil-alt icon-button edit-button"
              />
            </div>
          )}
          {props.availableLanguages && (
            <LanguageDropdown
              tr={props.uiTr}
              currentLanguage={props.currentLanguage}
              availableLanguages={props.availableLanguages}
              handleLanguageChange={props.handleLanguageChange}
            />
          )}
        </div>
        <div className="section-body">
          <RTViewer value={props.description} />
          {props.formType === gqltypes.FormType.publication && (
            <div>
              <span className="d-block">
                <strong>{tr("formStudent")}</strong>
              </span>
              <span className="d-block">
                {child
                  ? `${child.name} ${
                      classAndSchool ? ` - ${classAndSchool}` : ""
                    }`
                  : "[" + tr("formPersonNamePlaceholder") + "]"}
              </span>
            </div>
          )}
          {props.submittedTime && (
            <div className="mt-2">
              <span className="d-block">
                <strong>{tr("formAnswerDate")}</strong>
              </span>
              <span className="d-block">
                <Time date={props.submittedTime} />
              </span>
            </div>
          )}
          {props.children}
        </div>
      </div>
    </React.Fragment>
  );
};

export const FormHeader = withTranslation(FormHeaderInner);
