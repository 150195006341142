import gql from "graphql-tag";
import _ from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { QueryResult } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import { graphql, DataValue } from "@apollo/client/react/hoc";
import { Link } from "react-router-dom";
import {
  allFulfilledPredicates,
  getPredicateComponent,
} from "../../Admin/components/CreateForm";
import {
  AdminContext,
  Translation,
  UserProfile,
  withAdminContext,
  withTranslation,
  withUserProfile,
} from "../../App/reducer";
import {
  Button,
  ConsentForm,
  FormComponentView,
  ISTContainer,
  Loading,
} from "../../Common";
import { isValidCustomComponent } from "../../Common/components/ApplicationCustomComponent";
import {
  formComponentDataFragment,
  fullApplicationResponseFragment,
  fullFormTranslationFragment,
} from "../../Common/fragments";
import {
  canPerformAction,
  getApplicationStatusLabel,
} from "../../Common/utils";
import {
  showAlertDialog,
  showConfirmDialog,
} from "../../Utils/dialogs/showDialog";
import { definedNotNull, replaceInArray } from "../../Utils/functional";
import * as f from "../../Utils/functional";
import { removeTypenameRedacted } from "../../Utils/graphql-helpers";
import { client } from "../../api";
import curry from "../../curry";
import * as gqltypes from "../../gqltypes";
import {
  defaultDialogCancel,
  defaultDialogProceed,
} from "../../translation/strings";
import { Progress, SavingState } from "../../types";
import { FORM_DATA_QUERY } from "../queries";
import { allQuestionsMustBeValid } from "./PublicationResponseView";
import { Params, useNavigate, useParams } from "react-router";
import withRouter from "../../Utils/withRouter";

const promiseDebouncer =
  (
    isLocked: boolean,
    setLock: (value: boolean) => void,
    fn: () => Promise<any> | undefined
  ) =>
  () => {
    if (isLocked) {
      return;
    }

    setLock(true);
    (fn() || Promise.resolve()).then(() => {
      setLock(false);
    });
    return;
  };

const validateAnswers = (
  response: gqltypes.FormAnswer,
  form: gqltypes.GuardianApplication["application"]["form"],
  ignoreApplicationCustomComponent: boolean,
  includeFeedbackId?: string | null
) => {
  const components = form && form.componentData.components;
  const componentData = form && form.componentData;
  if (!components || !componentData) {
    throw new Error("components and componentData must be defined");
  }

  const validCustomComponent =
    ignoreApplicationCustomComponent || isValidCustomComponent(response);
  if (!validCustomComponent) return false;

  const predicateComponents = allFulfilledPredicates(components, response)
    .map(
      (p) =>
        componentData.predicateComponents &&
        componentData.predicateComponents.find((pc) => pc.id === p.componentId)
    )
    .filter(definedNotNull);

  const allValid = curry(allQuestionsMustBeValid, response);

  return (
    components
      .filter((c) => {
        // Include component(s) with selected feedbackId
        if (includeFeedbackId)
          return includeFeedbackId === c.applicationFeedbackId;

        // Include all non-feedback components
        return !Boolean(c.applicationFeedbackId);
      })
      .every(allValid) && predicateComponents.every(allValid)
  );
};

function ExistingApplications(props: {
  tr: Translation["tr"];
  applicationId: string;
  currentAppResId: string | undefined;
  closed: boolean;
}) {
  const { tr, applicationId, currentAppResId } = props;
  return (
    <Query<gqltypes.GuardianFormList>
      query={FORM_DATA_QUERY}
      fetchPolicy="cache-first"
    >
      {({ loading, error, data }) => {
        if (!data || loading) return null;

        const me = data.me;

        if (!me || !me.relations) {
          throw new Error("");
        }
        const connectedUsers = me.connectedUsers;
        const relations = me.relations.edges
          .map((e) => e.node)
          .filter(definedNotNull);
        const items = _.flatten(relations.map((r) => r.applicationResponses))
          .concat(
            _.flatten(
              connectedUsers
                ? connectedUsers.map((u) => u.applicationResponses)
                : []
            )
          )
          .concat(me.applicationResponses);

        const distinctItems = new Map<string, (typeof items)[0]>();
        items.forEach((item) => {
          if (!distinctItems.has(item.id)) {
            distinctItems.set(item.id, item);
          }
        });

        const applications = [...distinctItems.values()].filter(
          (app) =>
            app.application.id === applicationId && app.id !== currentAppResId
        );

        // No applications and cannot make new one
        if (!applications.length && props.closed) return null;

        // No applications and already creating one
        if (!applications.length && !currentAppResId) return null;

        return (
          <ISTContainer
            header={tr("guardianApplicationOtherApplications")}
            className="mb-5"
          >
            <div className="p-content">
              <div className="row">
                {applications.map((app) => (
                  <div className="col-md-6 p-1" key={app.id}>
                    <Link to={`/application/${applicationId}/${app.id}`}>
                      <div className="p-2 bg-light rounded">
                        <span className="">
                          <strong>{app.subject.name}</strong> (
                          {getApplicationStatusLabel(tr, app.status)})
                        </span>
                      </div>
                    </Link>
                  </div>
                ))}
                {currentAppResId && !props.closed ? (
                  <div className="col-md-6 p-1">
                    <Link to={`/application/${applicationId}`}>
                      <div className="p-2 bg-light rounded">
                        <span className="">
                          <strong>
                            <i className="fas fa-plus" />{" "}
                            {tr("guardianApplicationNewApplication")}
                          </strong>
                        </span>
                      </div>
                    </Link>
                  </div>
                ) : null}
              </div>
            </div>
          </ISTContainer>
        );
      }}
    </Query>
  );
}

function ApplicationClosedNotSentIn(props: { tr: Translation["tr"] }) {
  return (
    <div className="alert alert-danger">
      {props.tr("guardianApplicationClosedNotSentIn")}
    </div>
  );
}

function ApplicationNotSentIn(props: { tr: Translation["tr"] }) {
  return (
    <div className="alert alert-warning">
      {props.tr("guardianApplicationNotSentIn")}
    </div>
  );
}

function ApplicationAwaitingSignature(props: {
  tr: Translation["tr"];
  signers: gqltypes.FullApplicationResponse["signatures"];
  hasSigned: boolean;
}) {
  if (!props.hasSigned) {
    return (
      <div className="alert alert-warning">
        {props.tr("guardianApplicationAwaitingYourSignature")}
      </div>
    );
  }

  return (
    <div className="alert alert-warning">
      {props.tr("guardianApplicationAwaitingOtherSignature")}
    </div>
  );
}

function ApplicationSentIn(props: { tr: Translation["tr"] }) {
  return (
    <div className="alert alert-success">
      {props.tr("guardianApplicationSentIn")}
    </div>
  );
}

function ApplicationUnderProcess(props: { tr: Translation["tr"] }) {
  return (
    <div className="alert alert-success">
      {props.tr("guardianApplicationUnderProcess")}
    </div>
  );
}

function ApplicationComplement(props: {
  tr: Translation["tr"];
  comment: string;
}) {
  return (
    <div className="alert alert-warning">
      {props.tr("guardianApplicationComplement", props.comment)}
    </div>
  );
}

function ApplicationAwaitingFeedbackSignature(props: {
  tr: Translation["tr"];
  signers: gqltypes.FullApplicationResponse["feedbackSignatures"];
  hasSignedFeedback: boolean;
}) {
  if (!props.hasSignedFeedback) {
    return (
      <div className="alert alert-warning">
        {props.tr("guardianApplicationAwaitingFeedbackYourSignature")}
      </div>
    );
  }

  return (
    <div className="alert alert-warning">
      {props.tr("guardianApplicationAwaitingFeedbackOtherSignature")}
    </div>
  );
}

function ApplicationResolved(props: { tr: Translation["tr"] }) {
  return (
    <div className="alert alert-success">
      {props.tr("guardianApplicationResolved")}
    </div>
  );
}

function ApplicationClosed(props: { tr: Translation["tr"] }) {
  return (
    <div className="alert alert-success">
      {props.tr("guardianApplicationClosed")}
    </div>
  );
}

function ApplicationRejectInput(props: {
  tr: Translation["tr"];
  comment: string;
}) {
  return (
    <div className="alert alert-danger">
      {props.tr("guardianApplicationRejectInput", props.comment)}
    </div>
  );
}

interface Props extends UserProfile, Translation {
  responseData?: DataValue<gqltypes.GuardianApplicationResponse> & QueryResult;
  application: DataValue<gqltypes.GuardianApplication> & QueryResult;
  hasResponse?: boolean;
  params: Params;
}

interface State {
  signState: Progress;
  saveState: SavingState;
  showValidation: boolean;
  response?: gqltypes.FormAnswer;
  responseLanguage?: gqltypes.ISO6391Language;
  selectedSchoolUnit: { id: string; customerId: string } | null;
}

const useApplicationResponseState = () => {
  const [response, setResponse] = useState<State["response"]>();
  const [responseLanguage, setResponseLanguage] =
    useState<State["responseLanguage"]>();
  const [showValidation, setShowValidation] =
    useState<State["showValidation"]>(false);
  const [selectedSchoolUnit, setSelectedSchoolUnit] =
    useState<State["selectedSchoolUnit"]>(null);

  return {
    response,
    setResponse,
    responseLanguage,
    setResponseLanguage,
    showValidation,
    setShowValidation,
    selectedSchoolUnit,
    setSelectedSchoolUnit,
  };
};

const updateApplicationResponseState = (
  response: gqltypes.FullApplicationResponse | undefined,
  state: ReturnType<typeof useApplicationResponseState>
) => {
  if (!response) return;

  if (response.response) {
    state.setResponse(response.response);
  }
};

const responseStatesThatShouldShowFeedback = [
  gqltypes.ApplicationResponseStatus.feedback_respondable,
  gqltypes.ApplicationResponseStatus.awaiting_feedback_signature,
  gqltypes.ApplicationResponseStatus.resolved,
  gqltypes.ApplicationResponseStatus.closed,
];

// eslint-disable-next-line react/display-name
const NewApplicationView = React.memo((props: Props) => {
  const { tr, application, responseData } = props;
  const state = useApplicationResponseState();
  const params = useParams();
  const navigate = useNavigate();

  const [performingAction, setPerformingAction] = useState(false);

  const applicationId = params.applicationId;
  const responseId = params.responseId;

  const response = props.responseData && props.responseData.applicationResponse;

  useEffect(() => {
    updateApplicationResponseState(response, state);
    // TODO: Seems to work fine but might want to fix this lint issue some day
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (
    props.hasResponse &&
    (!responseData ||
      (responseData.error &&
        responseData.error.graphQLErrors.some((e) =>
          e.extensions
            ? e.extensions.code === gqltypes.ErrorCodes.ACCESS
            : false
        )))
  ) {
    return (
      <div className="alert alert-danger">
        {tr("guardianApplicationViewNoAccess")}
      </div>
    );
  }

  const app = application.application;
  if (
    !application ||
    application.loading ||
    (responseData && responseData.loading) ||
    !app
  ) {
    return <Loading />;
  }

  if (app && app.closed && !props.hasResponse) {
    return (
      <div>
        <ExistingApplications
          tr={tr}
          applicationId={applicationId!}
          currentAppResId={responseId}
          closed={app.closed}
        />
        <ISTContainer header={tr("guardianApplicationClosedTitle")}>
          <div className="p-content">
            {tr("guardianApplicationClosedDescription")}
          </div>
        </ISTContainer>
      </div>
    );
  }

  const form = props.application.application!.form;

  const closedButNotSentIn = response
    ? app.closed && !Boolean(response.seenByOrg)
    : false;

  const preventsEdits = closedButNotSentIn
    ? true
    : response
    ? response.status !== gqltypes.ApplicationResponseStatus.editable
    : false;

  const preventsFeedbackEdits = response
    ? response.status !==
      gqltypes.ApplicationResponseStatus.feedback_respondable
    : false;

  const dirty =
    response && response.response && state.response
      ? !_.isEqual(
          removeTypenameRedacted(response.response),
          removeTypenameRedacted(state.response)
        )
      : true;

  const isValidAnswer = state.response
    ? validateAnswers(
        state.response,
        form,
        Boolean(props.hasResponse),
        response ? response.feedbackId : null
      )
    : false;

  const saveButtonDisabled = !dirty;

  const hasSigned =
    response && props.educloudUserIds
      ? response.signatures.some(
          (sig) =>
            props.educloudUserIds!.find(
              (edu) =>
                edu.id === sig.signedAs && edu.customerId === sig.signedAs
            ) || sig.signerId === props.actorId
        )
      : false;

  const canSign =
    !closedButNotSentIn && !hasSigned && response
      ? canPerformAction(
          gqltypes.ApplicationResponseAction.sign,
          response.status
        )
      : false;

  const hasSignedFeedback =
    response && props.educloudUserIds
      ? response.feedbackSignatures.some(
          (sig) =>
            props.educloudUserIds!.find(
              (edu) =>
                edu.id === sig.signedAs && edu.customerId === sig.signedAs
            ) || sig.signerId === props.actorId
        )
      : false;

  const feedbackComponent =
    response &&
    response.feedbackId &&
    responseStatesThatShouldShowFeedback.includes(response.status) &&
    form.componentData.components.find(
      (c) => c.applicationFeedbackId === response.feedbackId
    );
  const feedbackDescription = response && response.feedbackDescription;

  const feedbackHasQuestions = feedbackComponent
    ? feedbackComponent.questions.length > 0
    : false;

  const status = response ? response.status : undefined;

  const lastComplementOrRejectInputAction = response
    ? _.findLast(response.actions, (a: (typeof response.actions)[0]) => {
        return (
          a.action === gqltypes.ApplicationResponseAction.complement ||
          a.action === gqltypes.ApplicationResponseAction.reject_input
        );
      })
    : undefined;

  const statusComponent = closedButNotSentIn ? (
    <ApplicationClosedNotSentIn tr={tr} />
  ) : lastComplementOrRejectInputAction &&
    lastComplementOrRejectInputAction.action ===
      gqltypes.ApplicationResponseAction.complement &&
    (status === gqltypes.ApplicationResponseStatus.editable ||
      (status === gqltypes.ApplicationResponseStatus.awaiting_signature &&
        !hasSigned)) ? (
    <ApplicationComplement
      tr={tr}
      comment={lastComplementOrRejectInputAction.comment || ""}
    />
  ) : lastComplementOrRejectInputAction &&
    lastComplementOrRejectInputAction.action ===
      gqltypes.ApplicationResponseAction.reject_input &&
    status === gqltypes.ApplicationResponseStatus.closed ? (
    <ApplicationRejectInput
      tr={tr}
      comment={lastComplementOrRejectInputAction.comment || ""}
    />
  ) : status === gqltypes.ApplicationResponseStatus.awaiting_signature ? (
    <ApplicationAwaitingSignature
      tr={tr}
      signers={response ? response.signatures : []}
      hasSigned={hasSigned}
    />
  ) : status === gqltypes.ApplicationResponseStatus.sent_to_org ? (
    <ApplicationSentIn tr={tr} />
  ) : status === gqltypes.ApplicationResponseStatus.under_process ? (
    <ApplicationUnderProcess tr={tr} />
  ) : status ===
    gqltypes.ApplicationResponseStatus.awaiting_feedback_signature ? (
    <ApplicationAwaitingFeedbackSignature
      tr={tr}
      signers={response ? response.feedbackSignatures : []}
      hasSignedFeedback={hasSignedFeedback}
    />
  ) : status === gqltypes.ApplicationResponseStatus.resolved ? (
    <ApplicationResolved tr={tr} />
  ) : status === gqltypes.ApplicationResponseStatus.closed ? (
    <ApplicationClosed tr={tr} />
  ) : response && !response.seenByOrg ? (
    <ApplicationNotSentIn tr={tr} />
  ) : null;

  return (
    <div id="form-view">
      <ExistingApplications
        tr={tr}
        applicationId={app.id}
        currentAppResId={responseId}
        closed={app.closed}
      />
      {statusComponent}
      {response && feedbackComponent && (
        <React.Fragment>
          <div className="bg-warning rounded rounded-lg p-3">
            <h4>{tr("guardianApplicationAnswerFromCustomer")}</h4>
            <form id="form-viewer">
              <FormComponentView
                getPredicateComponent={(id) =>
                  f.throwWhenError(
                    getPredicateComponent(
                      form.componentData.predicateComponents || undefined,
                      id
                    )
                  )
                }
                component={
                  feedbackDescription
                    ? { ...feedbackComponent, description: feedbackDescription }
                    : feedbackComponent
                }
                formLanguage={form.language}
                updateComponentAnswer={
                  preventsFeedbackEdits
                    ? undefined
                    : (component) => {
                        state.setResponse(
                          getNewResponse(component, state.response)
                        );
                      }
                }
                response={state.response}
                answer={
                  state.response &&
                  state.response.components.find(
                    (c) => c.componentId === feedbackComponent.id
                  )
                }
                validateAnswers={state.showValidation}
                readOnly={preventsFeedbackEdits}
              />
            </form>
          </div>
          {feedbackHasQuestions ? (
            <div className="actions mt-3">
              <div />
              <div>
                {response &&
                response.status ===
                  gqltypes.ApplicationResponseStatus
                    .awaiting_feedback_signature ? (
                  <Button
                    label={tr("guardianApplicationUnsign")}
                    level="secondary"
                    onClick={promiseDebouncer(
                      performingAction,
                      setPerformingAction,
                      () =>
                        performAction(
                          tr,
                          response.id,
                          response.lastAction.id,
                          gqltypes.ApplicationResponseAction.unlock,
                          null
                        )
                    )}
                  />
                ) : null}
                {!hasSignedFeedback ? (
                  <React.Fragment>
                    {response.status ===
                    gqltypes.ApplicationResponseStatus.feedback_respondable ? (
                      <Button
                        label={dirty ? tr("save") : tr("allChangesSaved")}
                        disabled={!dirty}
                        onClick={() => {
                          saveApplicationResponse(
                            responseId!,
                            state.response,
                            state.responseLanguage || form.language
                          );
                        }}
                      />
                    ) : null}

                    <Button
                      label={tr("guardianApplicationSignFeedback")}
                      disabled={dirty}
                      onClick={promiseDebouncer(
                        performingAction,
                        setPerformingAction,
                        async () => {
                          state.setShowValidation(true);
                          if (!isValidAnswer) return;

                          return performAction(
                            tr,
                            responseId!,
                            response.lastAction.id,
                            gqltypes.ApplicationResponseAction.sign_feedback,
                            null
                          );
                        }
                      )}
                    />
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          ) : null}
        </React.Fragment>
      )}

      <div style={{ opacity: preventsEdits ? 0.7 : 1 }}>
        <ConsentForm
          formType={gqltypes.FormType.application}
          formOwner={form.owner}
          initialForm={!props.hasResponse}
          // child={recipient.user}
          // classAndSchool={classAndSchool}
          // submittedTime={submittedTime}
          formTitle={form.name}
          formDescription={form.description}
          components={form.componentData.components}
          predicateComponents={form.componentData.predicateComponents || []}
          formLanguage={form.language}
          response={state.response}
          validateAnswers={state.showValidation}
          updateResponse={
            preventsEdits
              ? undefined
              : (updatedResponse) => state.setResponse(updatedResponse)
          }
          translating={
            state.responseLanguage && state.responseLanguage !== form.language
          }
          translation={form.translations.find(
            (translation) => translation.language === state.responseLanguage
          )}
          availableLanguages={[form.language].concat(form.translationLanguages)}
          displayAvailableLanguages
          handleLanguageChange={(language) =>
            state.setResponseLanguage(
              state.responseLanguage === language ? form.language : language
            )
          }
          readOnly={preventsEdits}
          singleGuardian={
            application.application
              ? application.application.singleGuardianSend
              : undefined
          }
          schoolunitSpecific={application.application?.schoolunitSpecific}
          selectedApplicationSchoolUnit={state.selectedSchoolUnit}
          updateSelectedApplicationSchoolUnit={state.setSelectedSchoolUnit}
        />
      </div>

      {statusComponent}

      <div className="actions">
        <div>
          <div>
            <Button
              onClick={async () => {
                const confirmed = dirty
                  ? await showConfirmDialog({
                      title: tr("unsavedChangesTitle"),
                      message: tr("unsavedChangesMessage"),
                      proceedText: tr("unsavedChangesProceed"),
                      cancelText: defaultDialogCancel(tr),
                    })
                  : true;
                if (!confirmed) {
                  return;
                }
                navigate("/");
              }}
              level="secondary"
              label={tr("goBack")}
            />
          </div>
          {response &&
          canPerformAction(
            gqltypes.ApplicationResponseAction.withdraw,
            response.status
          ) ? (
            <div>
              <Button
                label={
                  response.seenByOrg
                    ? tr("guardianApplicationWithdraw")
                    : tr("guardianApplicationRemove")
                }
                level="danger"
                disabled={performingAction}
                onClick={promiseDebouncer(
                  performingAction,
                  setPerformingAction,
                  async () => {
                    if (response.seenByOrg) {
                      const confirmed = await showConfirmDialog({
                        title: tr("guardianApplicationWithdrawTitle"),
                        message: tr("guardianApplicationWithdrawMessage"),
                        proceedText: tr("unsavedChangesProceed"),
                        cancelText: defaultDialogCancel(tr),
                      });
                      if (!confirmed) return;

                      await performAction(
                        tr,
                        response.id,
                        response.lastAction.id,
                        gqltypes.ApplicationResponseAction.withdraw,
                        null
                      );
                    } else {
                      const confirmed = await showConfirmDialog({
                        title: tr("guardianApplicationRemoveTitle"),
                        message: tr("guardianApplicationRemoveMessage"),
                        proceedText: tr("unsavedChangesProceed"),
                        cancelText: defaultDialogCancel(tr),
                      });
                      if (!confirmed) return;

                      await removeApplicationResponse(response.id);
                      navigate("/", { replace: true });
                    }
                  }
                )}
              />
            </div>
          ) : null}
        </div>

        {response &&
        response.status ===
          gqltypes.ApplicationResponseStatus.awaiting_signature ? (
          <div>
            <Button
              label={tr("guardianApplicationUnsign")}
              level="secondary"
              disabled={performingAction}
              onClick={promiseDebouncer(
                performingAction,
                setPerformingAction,
                () =>
                  performAction(
                    tr,
                    response.id,
                    response.lastAction.id,
                    gqltypes.ApplicationResponseAction.unlock,
                    null
                  )
              )}
            />
          </div>
        ) : null}

        {!hasSigned ? (
          <div>
            {!response ||
            response.status === gqltypes.ApplicationResponseStatus.editable ? (
              <div>
                <Button
                  label={
                    props.hasResponse
                      ? !dirty
                        ? tr("allChangesSaved")
                        : tr("save")
                      : tr("create")
                  }
                  disabled={saveButtonDisabled}
                  onClick={() => {
                    state.setShowValidation(true);
                    if (!isValidAnswer) {
                      return;
                    }
                    if (props.hasResponse) {
                      saveApplicationResponse(
                        responseId!,
                        state.response,
                        state.responseLanguage || form.language
                      );
                    } else {
                      createApplicationResponse(
                        applicationId!,
                        state.response,
                        state.responseLanguage || form.language,
                        state.selectedSchoolUnit
                      ).then((res) => {
                        const resId = res.data!.createApplicationResponse.id;
                        navigate(`/application/${applicationId}/${resId}`, {
                          replace: true,
                        });
                      });
                    }
                  }}
                />
              </div>
            ) : null}

            {props.hasResponse && !dirty ? (
              <div>
                <Button
                  label={tr("guardianApplicationSignResponse")}
                  disabled={!canSign || performingAction}
                  onClick={promiseDebouncer(
                    performingAction,
                    setPerformingAction,
                    () => {
                      state.setShowValidation(true);
                      if (!isValidAnswer) {
                        return;
                      }
                      return signApplicationResponse(
                        responseId!,
                        state.response,
                        state.responseLanguage || form.language
                      );
                    }
                  )}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
});

const getNewResponse = (
  component: gqltypes.FormComponentAnswer,
  response: State["response"]
) => {
  const currentResponse = response;
  const components = currentResponse
    ? currentResponse.components.map((c) => _.omit(c, "redacted"))
    : [];

  const indexToUpdate = _.findIndex(
    components,
    (c) => c.componentId === component.componentId
  );
  const updatedComponents =
    indexToUpdate !== -1
      ? replaceInArray(components, indexToUpdate, component)
      : [...components, component];

  return { components: updatedComponents };
};

interface OfferProps {
  tr: Translation["tr"];
  responseId: string;
  hasSigned: boolean;
  lastEvent: string;
  comment: gqltypes.FullApplicationResponse["lastAction"]["comment"];
}

/*

  API

*/

const withApplication = graphql<
  Props,
  gqltypes.GuardianApplication,
  gqltypes.GuardianApplicationVariables
>(
  gql`
    query GuardianApplication($id: ID!) {
      application(id: $id) {
        id
        singleGuardianSend
        singleGuardianAcceptOffer
        schoolunitSpecific
        closed
        form {
          id
          name
          description
          language
          translationLanguages
          translations {
            ...FullTranslation
          }
          owner {
            id
            displayName
          }
          componentData {
            ...FormComponentData
          }
        }
      }
    }
    ${formComponentDataFragment}
    ${fullFormTranslationFragment}
  `,
  {
    name: "application",
    options: (props) => ({
      variables: {
        id: props.params.applicationId!,
      },
    }),
  }
);

const withResponse = graphql<
  Props & AdminContext,
  gqltypes.GuardianApplicationResponse,
  gqltypes.GuardianApplicationResponseVariables
>(
  gql`
    query GuardianApplicationResponse($responseId: ID!) {
      applicationResponse(id: $responseId) {
        ...FullApplicationResponse
      }
    }
    ${fullApplicationResponseFragment}
  `,
  {
    name: "responseData",
    options: (props) => ({
      variables: {
        responseId: props.params.responseId!,
      },
    }),
  }
);

async function createApplicationResponse(
  applicationId: string,
  response: State["response"],
  language: State["responseLanguage"],
  schoolUnit: State["selectedSchoolUnit"]
) {
  if (!language || !response) {
    console.error(
      "createApplicationResponse: missing language or response",
      response,
      language
    );
    throw new Error("missing language or response");
  }
  const res = await client.mutate<
    gqltypes.GuardianCreateApplicationResponse,
    gqltypes.GuardianCreateApplicationResponseVariables
  >({
    mutation: gql`
      mutation GuardianCreateApplicationResponse(
        $applicationId: ID!
        $response: FormAnswerInput!
        $language: ISO6391Language!
        $schoolUnit: IdCustomerIdInput
      ) {
        createApplicationResponse(
          applicationId: $applicationId
          response: $response
          language: $language
          schoolUnit: $schoolUnit
        ) {
          ...FullApplicationResponse
        }
      }
      ${fullApplicationResponseFragment}
    `,
    variables: {
      applicationId,
      response: removeTypenameRedacted(response),
      language,
      schoolUnit,
    },
  });
  return res;
}

async function saveApplicationResponse(
  responseId: string,
  response: State["response"],
  language: State["responseLanguage"]
) {
  if (!language || !response) {
    console.error(
      "saveApplicationResponse: missing language or response",
      response,
      language
    );
    throw new Error("missing language or response");
  }
  const res = await client.mutate<
    gqltypes.GuardianSaveApplicationResponse,
    gqltypes.GuardianSaveApplicationResponseVariables
  >({
    mutation: gql`
      mutation GuardianSaveApplicationResponse(
        $responseId: ID!
        $response: FormAnswerInput!
        $language: ISO6391Language!
      ) {
        saveApplicationResponse(
          responseId: $responseId
          response: $response
          language: $language
        ) {
          ...FullApplicationResponse
        }
      }
      ${fullApplicationResponseFragment}
    `,
    variables: {
      responseId,
      response: removeTypenameRedacted(response),
      language,
    },
  });
  return res;
}

async function removeApplicationResponse(responseId: string) {
  const res = await client.mutate<
    gqltypes.GuardianRemoveApplicationResponse,
    gqltypes.GuardianRemoveApplicationResponseVariables
  >({
    mutation: gql`
      mutation GuardianRemoveApplicationResponse($responseId: ID!) {
        removeApplicationResponse(responseId: $responseId) {
          id
        }
      }
    `,
    variables: {
      responseId,
    },
  });
  return res;
}

async function signApplicationResponse(
  responseId: string,
  response: State["response"],
  language: State["responseLanguage"],
  context?: gqltypes.Context
) {
  if (!language || !response) {
    console.error(
      "signApplicationResponse: missing language or response",
      response,
      language
    );
    throw new Error("missing language or response");
  }
  const res = await client.mutate<
    gqltypes.GuardianSignApplicationResponse,
    gqltypes.GuardianSignApplicationResponseVariables
  >({
    mutation: gql`
      mutation GuardianSignApplicationResponse(
        $responseId: ID!
        $response: FormAnswerInput!
        $language: ISO6391Language!
        $context: Context
      ) {
        signApplicationResponse(
          responseId: $responseId
          response: $response
          language: $language
          context: $context
        ) {
          ...FullApplicationResponse
        }
      }
      ${fullApplicationResponseFragment}
    `,
    variables: {
      responseId,
      response: removeTypenameRedacted(response),
      language,
      context,
    },
  });
  return res;
}

async function performAction(
  tr: Translation["tr"],
  responseId: string,
  lastEvent: string,
  action: gqltypes.ApplicationResponseAction,
  comment: string | undefined | null
) {
  return client
    .mutate<
      gqltypes.GuardianApplicationResponseAction,
      gqltypes.GuardianApplicationResponseActionVariables
    >({
      mutation: gql`
        mutation GuardianApplicationResponseAction($input: ActionInput!) {
          performApplicationResponseAction(input: $input) {
            ...FullApplicationResponse
          }
        }
        ${fullApplicationResponseFragment}
      `,
      variables: {
        input: {
          responseId,
          previousEventId: lastEvent,
          action,
          comment,
        },
      },
    })
    .catch((e) => {
      showAlertDialog({
        title: tr("guardianApplicationFailedPerformActionTitle"),
        message: tr("guardianApplicationFailedPerformActionMessage"),
        proceedText: defaultDialogProceed(tr),
        cancelText: defaultDialogCancel(tr),
      });
    });
}

export const ApplicationViewContainer = _.flowRight(
  withTranslation,
  withAdminContext,
  withRouter,
  withApplication
)(NewApplicationView);

const FormViewWithResponse = (props: any) => (
  <NewApplicationView {...props} hasResponse={true} />
);

export const ApplicationViewWithResponseContainer = _.flowRight(
  withTranslation,
  withAdminContext,
  withRouter,
  withApplication,
  withResponse,
  withUserProfile
)(FormViewWithResponse);
