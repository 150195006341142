import gql from "graphql-tag";
import * as React from "react";
import * as ReactRedux from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Dispatch } from "redux";
import * as appActions from "../App/actions";
import { Header } from "../App/components/Header";
import { MenuItem, SideMenu } from "../App/components/SideMenu";
import { AvailableOrg } from "../App/reducer";
import { PageNotFound } from "../Common/components/PageNotFound";
import { StartViewContainer } from "../Start/StartViewContainer";
import { client } from "../api";
import { UserPermissions } from "../permissions";
import * as persistance from "../persistance";
import { StoreState } from "../store";
import { OIDCUser } from "../types";
import { Advanced } from "./components/Advanced";
import { EmailTemplateContainer } from "./components/EmailTemplate";
import { Organisations } from "./components/Organisations";
import { TemplatesContainer } from "./components/Templates";

const items: MenuItem[] = [
  { label: "Superadmin", type: "header" },
  { label: "Organisationer", url: "/superadmin", exactMatch: true },
  { label: "Email", url: "/superadmin/email" },
  { label: "Mallar", url: "/superadmin/templates" },
  { label: "Avancerat", url: "/superadmin/advanced" },
  { label: "" },
  { label: "Tillbaka till admin", url: "/admin" },
];

interface StateProps {
  isInitialized: boolean;
  user: OIDCUser | null;
  fatalError: string | null;
  errorSplash: { title: string; message?: string } | null;
  userPermissions: UserPermissions | null;
  currentOrg: string | null;
  availableOrgs: AvailableOrg[] | null;
}

interface OwnProps {}

interface Props extends StateProps, OwnProps {
  dispatch: Dispatch<any>;
  children?: React.ReactNode;
}

interface State {
  loadingIsAdmin: boolean;
  isAdmin: boolean | null;
}

class SuperadminApp extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { loadingIsAdmin: false, isAdmin: null };
  }

  public UNSAFE_componentWillMount() {
    this.tryFetchIsAdmin();
  }

  public componentDidUpdate() {
    this.tryFetchIsAdmin();
  }

  private tryFetchIsAdmin() {
    if (
      this.props.user &&
      !this.state.loadingIsAdmin &&
      this.state.isAdmin === null
    ) {
      this.setState({ loadingIsAdmin: true });
      client
        .query<{ amISuperAdmin: boolean }>({
          query: gql`
            query amISuperAdmin {
              amISuperAdmin
            }
          `,
        })
        .then((result) => {
          const isAdmin = result.data.amISuperAdmin;
          this.setState({ isAdmin });

          const sub = this.props.user?.profile.sub;
          if (sub) {
            const key = `is_superadmin_${sub}`;
            if (isAdmin) {
              persistance.set(key, "true");
            } else {
              persistance.remove(key);
            }
          }
        });
    }
  }

  public render() {
    return (
      <div className="app">
        <Header
          {...(this.props as any)}
          menuItems={items}
          userPermissions={{ org: {}, some: {}, su: {} }}
          currentOrg={this.props.currentOrg}
          availableOrgs={this.props.availableOrgs}
          dispatch={this.props.dispatch}
          onLogin={() => this.props.dispatch(appActions.loginRequested())}
          onLogout={() => this.props.dispatch(appActions.logoutRequested())}
        />

        <div className="mainLayout">
          {this.state.isAdmin ? (
            <SideMenu
              menuItems={items}
              userPermissions={{ org: {}, some: {}, su: {} }}
            />
          ) : null}
          <main className="mainContent">
            <ProtectedPaths {...this.props} isAdmin={this.state.isAdmin}>
              <Routes>
                <Route path="/email" element={<EmailTemplateContainer />} />
                <Route path="/templates" element={<TemplatesContainer />} />
                <Route path="/advanced" element={<Advanced />} />
                <Route path="/organisations/:id" element={<Organisations />} />
                <Route path="/*" element={<Organisations />} />
                <Route element={<PageNotFound startPageUrl="/superadmin" />} />
              </Routes>
            </ProtectedPaths>
          </main>
        </div>
      </div>
    );
  }
}

export const ProtectedPaths: React.FC<
  Props & {
    isAdmin: boolean | null;
  }
> = (props) => {
  // Protected paths require the application to be fully initialized (for instance the loading of an eventual user must have finished)
  if (!props.isInitialized) {
    return null;
  }

  if (!props.user) {
    return <StartViewContainer mode="user" />;
  }

  if (props.isAdmin === false) {
    return <span>Du verkar inte vara superadmin</span>;
  }
  if (!props.isAdmin) {
    return null;
  }

  return <div>{props.children}</div>;
};

export default ReactRedux.connect<StateProps, {}, OwnProps, StoreState>(
  (state) => ({
    isInitialized: state.app.isInitialized,
    user: state.app.user,
    fatalError: state.app.fatalError,
    errorSplash: state.app.error,
    userPermissions: state.app.permissions,
    currentOrg: state.app.context.org,
    availableOrgs: state.app.availableOrgs,
  })
)(SuperadminApp);
