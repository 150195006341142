import React from "react";
import { Link } from "react-router-dom";
import * as gqltypes from "../../gqltypes";
import { getUserInfoUrl } from "../utils";

export const WrapLink = (props: {
  children: any;
  url: string;
  use: boolean;
  title?: string;
  style?: any;
}) =>
  props.use ? (
    <Link to={props.url} style={props.style} title={props.title}>
      {props.children}
    </Link>
  ) : (
    props.children
  );

export const NameWithPotentialLink = (props: {
  name: string | React.ReactNode;
  user:
    | (Pick<gqltypes.User, "id" | "source"> &
        Partial<Pick<gqltypes.User, "idp" | "idpSub">> & {
          customerId: string | null;
        })
    | null;
  bold?: boolean;
}) => (
  <WrapLink
    use={Boolean(props.user)}
    url={props.user ? getUserInfoUrl(props.user) : ""}
  >
    {props.bold ? <strong>{props.name}</strong> : props.name}
  </WrapLink>
);
