import gql from "graphql-tag";
import * as _ from "lodash";
import * as React from "react";
import { MutationFunction, QueryResult } from "@apollo/client";
import { graphql, DataValue } from "@apollo/client/react/hoc";
import {
  AdminContext,
  Translation,
  withAdminContext,
  withTranslation,
} from "../../App/reducer";
import {
  Checkbox,
  ISTContainer,
  Loading,
  Table,
  ISTContainerInnerButton,
  ISTContainerInnerButtonProcessing,
} from "../../Common";
import { Tooltip } from "../../Common/components/Tooltip";
import * as gqltypes from "../../gqltypes";
import {
  Permission,
  PermissionSource,
  PermissionType,
  SchoolUnitValues,
  getPermissionDescription,
  getPermissionDisplayName,
  permissionInheritance,
  permissionOnOrgOnly,
  permissionOrder,
} from "../../permissions";
import withRouter from "../../Utils/withRouter";
import { ORG_PERMISSION_QUERY } from "./Permissions";
import {
  AggregatedReadEditView,
  fullSubjectPermissionsFragment,
} from "./UserPermissions";
import { Prompt } from "../../Common/components/RouterPrompt";
import { UserNameAndRoles } from "../../Common/components/UsernameAndRoles";

const DELIMITER = "__";

interface StateProps extends AdminContext {}

interface Props extends Response, Translation, StateProps {
  params: { id: string };
}

interface State {
  orgValues: Permission;
  initialOrgValues: Permission;
  suValues: SchoolUnitValues;
  initialSuValues: SchoolUnitValues;
  saving: boolean;
}

class ClientPermissions extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      orgValues: {},
      initialOrgValues: {},
      suValues: {},
      initialSuValues: {},
      saving: false,
    };
  }
  private mainRef: any = React.createRef();
  public componentDidMount() {
    if (this.props.client && this.props.client.client) {
      this.updateStateFromServer(this.props);
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      (this.props.client.client && !prevProps.client.client) ||
      (this.props.client.client &&
        prevProps.client.client &&
        this.props.client.client.permissions !==
          prevProps.client.client.permissions)
    ) {
      this.updateStateFromServer(this.props);
    }
  }

  public render() {
    const { tr } = this.props;
    if (this.props.client.loading) {
      return (
        <main className="top content">
          <Loading />
        </main>
      );
    }

    if (!this.props.client.me || !this.props.client.client) {
      return;
    }

    const pristine = !this.isStateDirty();
    const requestorIsOrgAdmin =
      this.props.client.me.permissions.organisation.roles.some(
        (role) => role.roleId === gqltypes.PermissionType.admin
      );

    const permissionTypes = permissionOrder;

    const schoolUnits = this.props.client.client.permissions.schoolUnits;
    const ISTContainerInnerButtonProcessing = {
      processingState: this.state.saving,
      processingLabel: tr("saving"),
    } as ISTContainerInnerButtonProcessing;

    const ISTContainerInnerButton = {
      buttonLabel: tr("save"),
      active: pristine,
      processing: ISTContainerInnerButtonProcessing,
      onClick: this.handleSave,
    } as ISTContainerInnerButton;

    return (
      <main ref={this.mainRef}>
        <Prompt
          when={!pristine}
          message={tr("createFormUnsavedChangesWarning")}
        />
        <h1>
          {tr(
            "userPermissionsTitle",
            this.props.client.client.name,
            this.props.client.client.permissions.organisation.displayName
          )}
        </h1>
        <p className="col-12 col-md-9 p-0 pb-3">
          {tr("userPermissionsDescription")}
        </p>

        <ISTContainer
          header={
            this.props.client.client.name +
            ", " +
            this.props.client.client.permissions.organisation.displayName
          }
          button={ISTContainerInnerButton}
        >
          <div style={{ overflowX: "auto", maxHeight: "80vh" }}>
            <h3 className="pl-4 mt-4">
              {tr("userPermissionsTitleOnOrganisation")}
            </h3>
            <table
              className="table mb-content table-no-end-line table-responsive-sm"
              style={{ minWidth: "1080px" }}
            >
              <thead>
                <tr>
                  {permissionTypes.map((type) => (
                    <th
                      key={`th${DELIMITER}${type}`}
                      scope="col"
                      className={`${
                        type === gqltypes.PermissionType.manage_application
                          ? "text-right"
                          : "text-center"
                      } no-upper ${
                        type === gqltypes.PermissionType.admin
                          ? "th-border-bottom"
                          : "th-border"
                      }`}
                      style={
                        type === gqltypes.PermissionType.read ||
                        type === gqltypes.PermissionType.manage_application
                          ? type === gqltypes.PermissionType.read
                            ? { width: "360px" }
                            : { width: "260px" }
                          : { width: "58px" }
                      }
                    >
                      <Tooltip
                        target={`tooltip${DELIMITER}org${DELIMITER}${type}`}
                        content={getPermissionDisplayName(type, tr)}
                        message={getPermissionDescription(type, tr)}
                      />
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                <tr>
                  {permissionTypes.map((type) =>
                    type === gqltypes.PermissionType.read ? (
                      <AggregatedReadEditView
                        key={`org${DELIMITER}${type}`}
                        tr={tr}
                        mode="org"
                        prefixId={`org${DELIMITER}`}
                        permission={this.state.orgValues}
                        handlePermissionChange={this.handleOrgPermissionCheck}
                        disabled={!requestorIsOrgAdmin}
                      />
                    ) : (
                      <td
                        key={`org${DELIMITER}${type}`}
                        className={`${
                          type === gqltypes.PermissionType.manage_application
                            ? "text-right"
                            : "text-center"
                        } ${
                          type === gqltypes.PermissionType.admin
                            ? "td-border-bottom"
                            : "td-border"
                        }`}
                      >
                        <Checkbox
                          id={`org${DELIMITER}${type}`}
                          containerClassName="d-inline-block"
                          checked={this.state.orgValues[type] !== undefined}
                          disabled={
                            !requestorIsOrgAdmin ||
                            (this.state.orgValues[type] &&
                              this.state.orgValues[type]!.some(
                                (source) => source !== PermissionSource.DIRECT
                              ))
                          }
                          onChange={this.handleOrgPermissionCheck}
                        />
                      </td>
                    )
                  )}
                </tr>
              </tbody>
            </table>
            <h3 className="pl-4 mt-4">{tr("userPermissionsTitleOnUnit")}</h3>
            <Table
              initialOrder="schoolUnitName"
              initialOrderDir="asc"
              thClassName="no-upper"
              headers={[
                {
                  key: "schoolUnitName",
                  element: tr("schoolUnit"),
                  usingSortValue: true,
                  style: { width: "260px" },
                },
              ].concat(
                permissionTypes
                  .filter((type) => !permissionOnOrgOnly[type])
                  .map((type) => {
                    return {
                      key: type,
                      element: (
                        <Tooltip
                          target={`tooltip${DELIMITER}su${DELIMITER}${type}`}
                          content={getPermissionDisplayName(type, tr) || "?"}
                          message={getPermissionDescription(type, tr)}
                        />
                      ) as any,
                      usingSortValue: true,
                      style:
                        type === gqltypes.PermissionType.read ||
                        type === gqltypes.PermissionType.manage_application
                          ? type === gqltypes.PermissionType.manage_application
                            ? { width: "260px" }
                            : { width: "360px" }
                          : { width: "58px" },
                      sortArrowOwnLine: true,
                      className: `text-center
                    ${
                      type === gqltypes.PermissionType.admin
                        ? "th-border-bottom"
                        : "th-border"
                    }`,
                    };
                  })
              )}
              rows={schoolUnits.map((su) => ({
                key: su.resourceId,
                columns: {
                  schoolUnitName: {
                    content: (
                      <UserNameAndRoles tr={tr} id={su.resourceId} name={su.displayName} roles={su.employmentRoles} />
                      ),
                    sortValue: su.displayName,
                    className: "text-truncate",
                    style: { maxWidth: "260px" },
                  },
                  ...permissionTypes
                    .filter((type) => !permissionOnOrgOnly[type])
                    .reduce((acc, type) => {
                      const checked = this.state.suValues[su.resourceId]
                        ? this.state.suValues[su.resourceId][type] !== undefined
                        : false;
                      acc[type] = {
                        sortValue: checked,
                        className: "text-center",
                        content:
                          type === gqltypes.PermissionType.read ? (
                            <AggregatedReadEditView
                              tr={tr}
                              prefixId={`${su.resourceId}${DELIMITER}`}
                              mode="su"
                              permission={
                                this.state.suValues[su.resourceId] || {}
                              }
                              handlePermissionChange={
                                this.handleSUPermissionCheck
                              }
                            />
                          ) : (
                            <Checkbox
                              id={`${su.resourceId}${DELIMITER}${type}`}
                              containerClassName="d-inline-block"
                              checked={checked}
                              disabled={
                                this.state.suValues[su.resourceId] &&
                                this.state.suValues[su.resourceId][type] &&
                                this.state.suValues[su.resourceId][type]!.some(
                                  (source) => source !== PermissionSource.DIRECT
                                )
                              }
                              onChange={this.handleSUPermissionCheck}
                            />
                          ),
                      };
                      return acc;
                    }, {} as any),
                },
              }))}
            />
          </div>
        </ISTContainer>
      </main>
    );
  }

  private updateStateFromServer = (props: Props) => {
    const ns: Partial<State> = {};

    if (!props.client.client) {
      throw new Error("updateStateFromServer with empty client");
    }

    ns.orgValues = {} as Permission;

    props.client.client.permissions.organisation.roles.forEach((role) => {
      ns.orgValues![role.roleId] = role.sources;
    });
    ns.initialOrgValues = _.cloneDeep(ns.orgValues);

    ns.suValues = {} as SchoolUnitValues;

    props.client.client.permissions.schoolUnits.forEach((su) => {
      ns.suValues![su.resourceId] = {};
      su.roles.forEach((role) => {
        ns.suValues![su.resourceId][role.roleId] = role.sources;
      });
    });

    ns.initialSuValues = _.cloneDeep(ns.suValues);

    this.setState(ns as any);
  };

  private permissionOrgHelper = (
    mode: "add" | "remove",
    ns: State,
    roleId: gqltypes.PermissionType,
    permissionSource: gqltypes.PermissionSource
  ) => {
    const inheritance = permissionInheritance[roleId] || [];
    if (mode === "add") {
      if (!ns.orgValues[roleId]) {
        ns.orgValues[roleId] = [];
      }
      ns.orgValues[roleId]!.push(permissionSource);
      this.permissionAllSUHelper(
        mode,
        ns.suValues,
        roleId,
        PermissionSource.HIERARCHY
      );
    } else if (mode === "remove") {
      if (
        ns.orgValues[roleId] &&
        ns.orgValues[roleId]!.some((source) => source === permissionSource)
      ) {
        if (ns.orgValues[roleId]!.length === 1) {
          delete ns.orgValues[roleId];
        } else {
          ns.orgValues[roleId] = ns.orgValues[roleId]!.filter(
            (source) => source !== permissionSource
          );
        }
        this.permissionAllSUHelper(
          mode,
          ns.suValues,
          roleId,
          PermissionSource.HIERARCHY
        );
      }
    }
    inheritance.forEach((inheritRole) => {
      this.permissionOrgHelper(
        mode,
        ns as any,
        inheritRole,
        gqltypes.PermissionSource.INHERIT
      );
    });
  };

  private permissionSUHelper = (
    mode: "add" | "remove",
    suValues: SchoolUnitValues,
    suid: string,
    roleId: gqltypes.PermissionType,
    permissionSource: PermissionSource
  ) => {
    const inheritance = permissionInheritance[roleId] || [];
    if (mode === "add") {
      if (!suValues[suid]) {
        suValues[suid] = {};
      }
      if (!suValues[suid][roleId]) {
        suValues[suid][roleId] = [permissionSource];
      } else {
        if (suValues[suid][roleId]!.indexOf(permissionSource) === -1) {
          suValues[suid][roleId]!.push(permissionSource);
        }
      }
      inheritance.forEach((inheritRole) => {
        this.permissionSUHelper(
          mode,
          suValues,
          suid,
          inheritRole,
          PermissionSource.INHERIT
        );
      });
    } else if (mode === "remove") {
      if (!suValues[suid][roleId]) {
        return;
      }
      if (
        suValues[suid][roleId]!.some((source) => source === permissionSource)
      ) {
        if (suValues[suid][roleId]!.length === 1) {
          delete suValues[suid][roleId];
          inheritance.forEach((inheritRole) => {
            this.permissionSUHelper(
              mode,
              suValues,
              suid,
              inheritRole,
              PermissionSource.INHERIT
            );
          });
        } else {
          suValues[suid][roleId] = suValues[suid][roleId]!.filter(
            (source) => source !== permissionSource
          );
        }
      }
    }
  };

  private permissionAllSUHelper = (
    mode: "add" | "remove",
    suValues: SchoolUnitValues,
    roleId: gqltypes.PermissionType,
    permissionSource: PermissionSource
  ) => {
    if (!this.props.client.client) {
      throw new Error("permissionAllSUHelper with empty client");
    }
    const suids = this.props.client.client.permissions.schoolUnits.map(
      (su) => su.resourceId
    );

    suids.forEach((suid) => {
      this.permissionSUHelper(mode, suValues, suid, roleId, permissionSource);
    });
  };

  private handleOrgPermissionCheck = (id: string, checked: boolean) => {
    const roleId = id.slice(
      id.indexOf(DELIMITER) + DELIMITER.length
    ) as gqltypes.PermissionType;
    const ns: Partial<State> = {};
    ns.orgValues = _.cloneDeep(this.state.orgValues);
    ns.suValues = _.cloneDeep(this.state.suValues);

    const inheritance = permissionInheritance[roleId] || [];
    const mode = checked ? "add" : "remove";

    this.permissionOrgHelper(
      mode,
      ns as any,
      roleId,
      gqltypes.PermissionSource.DIRECT
    );

    this.setState(ns as any);
  };

  private handleSUPermissionCheck = (id: string, checked: boolean) => {
    const index = id.indexOf(DELIMITER);
    const suid = id.slice(0, index);
    const roleId = id.slice(
      index + DELIMITER.length
    ) as gqltypes.PermissionType;
    const ns: Partial<State> = {};
    ns.suValues = _.cloneDeep(this.state.suValues);

    const inheritance = permissionInheritance[roleId] || [];
    const mode = checked ? "add" : "remove";

    this.permissionSUHelper(
      mode,
      ns.suValues,
      suid,
      roleId,
      PermissionSource.DIRECT
    );

    this.setState(ns as any);
  };

  private isPermissionDirty = (perm: Permission, initialPerm: Permission) => {
    const permKeys = Object.keys(perm) as gqltypes.PermissionType[];
    const setPermissions = permKeys.filter((permKey) =>
      perm[permKey]!.some((source) => source === PermissionSource.DIRECT)
    );

    const initialpermKeys = Object.keys(
      initialPerm
    ) as gqltypes.PermissionType[];
    const initialSetPermissions = new Set<string>(
      initialpermKeys.filter((permKey) =>
        initialPerm[permKey]!.some(
          (source) => source === PermissionSource.DIRECT
        )
      )
    );

    const sizeDiffers = setPermissions.length !== initialSetPermissions.size;
    const samePermissions = !setPermissions.every((setPerm) =>
      initialSetPermissions.has(setPerm)
    );

    return sizeDiffers || samePermissions;
  };

  private isStateDirty = () => {
    if (
      this.isPermissionDirty(this.state.orgValues, this.state.initialOrgValues)
    ) {
      return true;
    }

    const suids = [
      ...new Set(
        Object.keys(this.state.suValues).concat(
          Object.keys(this.state.initialSuValues)
        )
      ),
    ];
    return suids.some((suid) =>
      this.isPermissionDirty(
        this.state.suValues[suid],
        this.state.initialSuValues[suid]
      )
    );
  };

  private handleSave = async () => {
    const params = this.props.params;
    const clientId = decodeURIComponent(this.props.params.id!);

    const orgRoles = (
      Object.keys(this.state.orgValues) as gqltypes.PermissionType[]
    ).filter((roleId) =>
      this.state.orgValues[roleId]!.some(
        (source) => source === PermissionSource.DIRECT
      )
    ) as PermissionType[];
    const permissions: gqltypes.SetClientPermissionInput = {
      clientId,
      permissions: [],
    };
    const suids = [
      ...new Set(
        Object.keys(this.state.suValues).concat(
          Object.keys(this.state.initialSuValues)
        )
      ),
    ];
    suids.forEach((suid) => {
      if (
        this.isPermissionDirty(
          this.state.suValues[suid],
          this.state.initialSuValues[suid]
        )
      ) {
        permissions.permissions.push({
          resourceId: suid,
          resourceTypeId: gqltypes.ResourceType.schoolunit,
          roleIds: (
            Object.keys(this.state.suValues[suid]) as gqltypes.PermissionType[]
          ).filter((roleId) =>
            this.state.suValues[suid][roleId]!.some(
              (source) => source === PermissionSource.DIRECT
            )
          ) as PermissionType[],
        });
      }
    });
    if (
      this.isPermissionDirty(this.state.orgValues, this.state.initialOrgValues)
    ) {
      if (!this.props.adminContext.org) {
        throw new Error("adminContext missing org");
      }
      permissions.permissions.push({
        resourceId: this.props.adminContext.org,
        resourceTypeId: gqltypes.ResourceType.organisation,
        roleIds: orgRoles,
      });
    }
    if (permissions.permissions.length > 0) {
      this.setState({ saving: true });
      await this.props.setClientPermission({
        variables: {
          input: permissions,
          context: this.props.adminContext,
        },
      });
      this.setState({ saving: false });
    }
  };
}

interface Response {
  client: DataValue<gqltypes.ClientWithPermissions> &
    QueryResult<gqltypes.ClientWithPermissionsVariables>;
  setClientPermission: MutationFunction<
    gqltypes.SetClientPermission,
    gqltypes.SetClientPermissionVariables
  >;
}

const withOrgPermissions = graphql<
  Props,
  gqltypes.ClientWithPermissions,
  gqltypes.ClientWithPermissionsVariables
>(
  gql`
    query ClientWithPermissions($id: String!, $context: Context!) {
      client(clientId: $id) {
        id
        name
        permissions(context: $context) {
          ...FullSubjectPermissions
        }
      }
      me {
        id
        name
        permissions(context: $context) {
          ...FullSubjectPermissions
        }
      }
    }
    ${fullSubjectPermissionsFragment}
  `,
  {
    name: "client",
    options: (props) => {
      const clientId = decodeURIComponent(props.params.id!);
      return {
        variables: {
          id: clientId,
          context: props.adminContext,
        },
      };
    },
  }
);

const withSetClientPermission = graphql<
  Props,
  gqltypes.SetClientPermission,
  gqltypes.SetClientPermissionVariables
>(
  gql`
    mutation SetClientPermission(
      $input: SetClientPermissionInput!
      $context: Context!
    ) {
      setClientPermission(input: $input, context: $context) {
        ...FullSubjectPermissions
      }
    }
    ${fullSubjectPermissionsFragment}
  `,
  {
    name: "setClientPermission",
    options: (props) => ({
      refetchQueries: [
        {
          query: ORG_PERMISSION_QUERY,
          variables: { context: props.adminContext },
        },
      ],
    }),
  }
);

export const ClientPermissionsContainer = withAdminContext(
  _.flowRight(
    withRouter,
    withTranslation,
    withOrgPermissions,
    withSetClientPermission
  )(ClientPermissions)
);
