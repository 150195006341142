import _ from "lodash";
import { links } from "../Admin/links";
import { Translation } from "../App/reducer";
import { assertUnreachable } from "../Utils/typeHelpers";
import * as gqltypes from "../gqltypes";
import { getSettings } from "../settings";

const skolidIdp = getSettings().skolidIdp;

export const getUserInternalUserId = (
  user: Pick<gqltypes.User, "id" | "source" | "customerId" | "idp" | "idpSub">
) => {
  if (
    user.source === gqltypes.UserSource.db &&
    user.idp === skolidIdp &&
    user.idpSub
  ) {
    return gqlToInternalMap.skolid + "/" + user.idpSub;
  }
  if (user.source === gqltypes.UserSource.educloud) {
    if (!user.customerId) {
      console.log("missing customerId for link", user);
    }

    return (
      gqlToInternalMap[user.source] + "/" + user.customerId! + "/" + user.id
    );
  }
  return `${gqlToInternalMap[user.source]}/${user.id}`;
};

export const getSourceFromShortSource = (source: string) => {
  if (!source) {
    throw new Error("no source");
  }
  return internalToGqlMap[source[0]];
};

const internalToGqlMap: { [key: string]: gqltypes.UserSource } = {
  s: gqltypes.UserSource.skolid,
  e: gqltypes.UserSource.educloud,
  d: gqltypes.UserSource.db,
};

const gqlToInternalMap: { [key in gqltypes.UserSource]: string } = {
  skolid: "s",
  educloud: "e",
  db: "d",
};

export const getUserInfoUrl = (
  user:
    | (Pick<gqltypes.User, "id" | "source" | "idp" | "idpSub"> & {
        customerId: string | null;
      })
    | null
) => {
  const internalId = user
    ? typeof user === "string"
      ? user
      : getUserInternalUserId(user)
    : undefined;
  return internalId ? links.admin.user.get(internalId) : "#";
};

type UsedByType = "guardian" | "admin" | "both";

const stateActionTree: {
  [state in gqltypes.ApplicationResponseStatus]: {
    [action in keyof typeof gqltypes.ApplicationResponseAction]?: {
      newStatus: gqltypes.ApplicationResponseStatus | "vary";
      usedBy: UsedByType;
    };
  };
} = {
  editable: {
    set_schoolunit: {
      newStatus: gqltypes.ApplicationResponseStatus.editable,
      usedBy: "guardian",
    },
    sign: {
      newStatus: "vary",
      usedBy: "guardian",
    },
    withdraw: {
      newStatus: gqltypes.ApplicationResponseStatus.closed,
      usedBy: "guardian",
    },
    ack: {
      newStatus: gqltypes.ApplicationResponseStatus.closed,
      usedBy: "admin",
    },
    reject_input: {
      newStatus: gqltypes.ApplicationResponseStatus.closed,
      usedBy: "admin",
    },
  },
  awaiting_signature: {
    sign: {
      newStatus: "vary",
      usedBy: "both",
    },
    unlock: {
      newStatus: gqltypes.ApplicationResponseStatus.editable,
      usedBy: "guardian",
    },
    withdraw: {
      newStatus: gqltypes.ApplicationResponseStatus.closed,
      usedBy: "guardian",
    },
  },
  sent_to_org: {
    set_schoolunit: {
      newStatus: gqltypes.ApplicationResponseStatus.sent_to_org,
      usedBy: "admin",
    },
    assign: {
      newStatus: gqltypes.ApplicationResponseStatus.under_process,
      usedBy: "admin",
    },
    complement: {
      newStatus: gqltypes.ApplicationResponseStatus.editable,
      usedBy: "admin",
    },
    reject_input: {
      newStatus: gqltypes.ApplicationResponseStatus.closed,
      usedBy: "admin",
    },
    withdraw: {
      newStatus: gqltypes.ApplicationResponseStatus.closed,
      usedBy: "guardian",
    },
    send_feedback: {
      newStatus: "vary",
      usedBy: "admin",
    },
  },
  under_process: {
    set_schoolunit: {
      newStatus: gqltypes.ApplicationResponseStatus.under_process,
      usedBy: "admin",
    },
    send_feedback: {
      newStatus: "vary",
      usedBy: "admin",
    },
    no_feedback: {
      newStatus: gqltypes.ApplicationResponseStatus.closed,
      usedBy: "admin",
    },
    complement: {
      newStatus: gqltypes.ApplicationResponseStatus.editable,
      usedBy: "admin",
    },
    reject_input: {
      newStatus: gqltypes.ApplicationResponseStatus.closed,
      usedBy: "admin",
    },
    retry_automatic_action: {
      newStatus: gqltypes.ApplicationResponseStatus.under_process,
      usedBy: "admin",
    },
    report_error: {
      newStatus: gqltypes.ApplicationResponseStatus.under_process,
      usedBy: "admin",
    },
  },
  feedback_respondable: {
    sign_feedback: {
      newStatus: "vary",
      usedBy: "guardian",
    },
  },
  awaiting_feedback_signature: {
    sign_feedback: {
      newStatus: "vary",
      usedBy: "guardian",
    },
    unlock: {
      newStatus: gqltypes.ApplicationResponseStatus.feedback_respondable,
      usedBy: "guardian",
    },
  },
  resolved: {
    ack: {
      newStatus: gqltypes.ApplicationResponseStatus.closed,
      usedBy: "admin",
    },
  },
  closed: {},
};

export const canPerformAction = (
  action: gqltypes.ApplicationResponseAction,
  status: gqltypes.ApplicationResponseStatus
) => {
  return stateActionTree[status][action] !== undefined;
};

export const getPerformableActions = (
  role: UsedByType,
  status: gqltypes.ApplicationResponseStatus
) => {
  return Object.entries(stateActionTree[status])
    .filter((entry) => {
      if (!entry[1]) return false;
      const usedBy = entry[1].usedBy;
      return usedBy === role || usedBy === "both";
    })
    .map((entry) => entry[0]) as gqltypes.ApplicationResponseAction[];
};

export const nextState = (
  action: gqltypes.ApplicationResponseAction,
  status: gqltypes.ApplicationResponseStatus
) => {
  const event = stateActionTree[status][action];
  return event && event.newStatus;
};

export function getApplicationStatusLabel(
  tr: Translation["tr"],
  status: gqltypes.ApplicationResponseStatus
): string {
  switch (status) {
    case gqltypes.ApplicationResponseStatus.editable:
      return tr("applicationStatusEditableLabel");
    case gqltypes.ApplicationResponseStatus.awaiting_signature:
      return tr("applicationStatusAwaitingSignatureLabel");
    case gqltypes.ApplicationResponseStatus.sent_to_org:
      return tr("applicationStatusSentToOrgLabel");
    case gqltypes.ApplicationResponseStatus.under_process:
      return tr("applicationStatusUnderProcessLabel");
    case gqltypes.ApplicationResponseStatus.feedback_respondable:
      return tr("applicationStatusFeedbackRespondableLabel");
    case gqltypes.ApplicationResponseStatus.awaiting_feedback_signature:
      return tr("applicationStatusAwaitingFeedbackSignatureLabel");
    case gqltypes.ApplicationResponseStatus.resolved:
      return tr("applicationStatusResolvedLabel");
    case gqltypes.ApplicationResponseStatus.closed:
      return tr("applicationStatusClosedLabel");
    default:
      assertUnreachable(status);
      throw new Error("");
  }
}

export function getApplicationActionLabel(
  tr: Translation["tr"],
  status: gqltypes.ApplicationResponseAction
): string {
  switch (status) {
    case gqltypes.ApplicationResponseAction.create:
      return tr("applicationActionCreateLabel");
    case gqltypes.ApplicationResponseAction.sign:
      return tr("applicationActionSignLabel");
    case gqltypes.ApplicationResponseAction.unlock:
      return tr("applicationActionUnlockLabel");
    case gqltypes.ApplicationResponseAction.assign:
      return tr("applicationActionAssignLabel");
    case gqltypes.ApplicationResponseAction.send_feedback:
      return tr("applicationActionSendFeedbackLabel");
    case gqltypes.ApplicationResponseAction.sign_feedback:
      return tr("applicationActionSignFeedbackLabel");
    case gqltypes.ApplicationResponseAction.complement:
      return tr("applicationActionComplementLabel");
    case gqltypes.ApplicationResponseAction.no_feedback:
      return tr("applicationActionNoFeedbackLabel");
    case gqltypes.ApplicationResponseAction.reject_input:
      return tr("applicationActionRejectInputLabel");
    case gqltypes.ApplicationResponseAction.withdraw:
      return tr("applicationActionWithdrawLabel");
    case gqltypes.ApplicationResponseAction.ack:
      return tr("applicationActionAckLabel");
    case gqltypes.ApplicationResponseAction.set_schoolunit:
      return tr("applicationActionSetSchoolunitLabel");
    case gqltypes.ApplicationResponseAction.retry_automatic_action:
      return tr("applicationActionRetryAutomaticAction");
    case gqltypes.ApplicationResponseAction.report_error:
      return tr("applicationActionReportError");
    default:
      assertUnreachable(status);
      throw new Error("");
  }
}

export function getApplicationActionErrorCodeLabel(
  tr: Translation["tr"],
  e: gqltypes.AdminViewApplicationResponse_applicationResponse_actions["errorCode"]
) {
  if (!e) return null;
  switch (e) {
    case "admin_worker_no_admin_configured":
      return tr("applicationActionErrorCodeNoAdminConfigured");
    case "admin_worker_person_not_found":
      return tr("applicationActionErrorCodePersonNotFound");
    case "admin_worker_failed_to_find_withdrawal_date":
      return tr("applicationActionErrorCodeFailedToFindWithdrawalDate");
    default:
      return e;
  }
}

function getTableAsCsv(tableId: string) {
  // Select rows from table_id
  const rows = document.querySelectorAll("table#" + tableId + " tr");
  // Construct csv
  const csv = [];
  for (let i = 0; i < rows.length; i++) {
    const row = [];
    const cols = rows[i].querySelectorAll("td, th");
    for (let j = 0; j < cols.length; j++) {
      // Clean innertext to remove multiple spaces and jumpline (break csv)
      let data = (cols[j] as any).innerText
        .replace(/(\r\n|\n|\r)/gm, "")
        .replace(/(\s\s)/gm, " ");
      // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
      data = data.replace(/"/g, '""');
      // Push escaped string
      row.push('"' + data + '"');
    }
    csv.push(row.join(";"));
  }
  return csv.join("\n");
}
declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean;
  }
}
// Quick and simple export target #table_id into a csv
export function downloadTableAsCsv(tableId: string, filestart: string) {
  const csvString = getTableAsCsv(tableId);
  // Download it
  const filename = filestart + "_" + new Date().toLocaleDateString() + ".csv";

  const encodedCsvString = encodeURIComponent(csvString);

  if (navigator.msSaveBlob) {
    // IE 10+
    const BOM = "\uFEFF";
    const blob = new Blob([BOM + csvString], {
      type: "text/csv; charset=utf-8",
    });
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,\ufeff" + encodedCsvString
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function permissionResourceIdFromSchoolUnit(
  orgid: string,
  su: {
    id: string;
    customerId: string;
  }
) {
  return `${orgid}::${su.customerId}::${su.id}`;
}

export function getCombinedIdCustomerId(su: {
  id: string;
  customerId: string;
}) {
  return su.id + "::" + su.customerId;
}

export function getPartsFromCombinedIdCustomerId(combined: string) {
  const [id, customerId] = combined.split("::");
  return { id, customerId };
}

interface IdCustomerId {
  id: string;
  customerId: string;
}

export const isIdCustomerIdSame = (a: IdCustomerId, b: IdCustomerId) => {
  return a.id === b.id && a.customerId === b.customerId;
};

export const findIdCustomerIdOverlap = (
  arr1: IdCustomerId[],
  arr2: IdCustomerId[]
) => {
  return _.intersectionWith(arr1, arr2, isIdCustomerIdSame).map((r) => ({
    id: r.id,
    customerId: r.customerId,
  }));
};

export class ComplexSet<T = any> {
  private set: Set<string>;
  private values: T[] = [];
  private transform: (obj: T) => string;

  constructor(transform: (obj: T) => string, values: T[] = []) {
    this.transform = transform;
    this.set = new Set();

    values.forEach((v) => {
      this.add(v);
    });
  }

  public add(obj: T) {
    const key = this.transform(obj);
    if (!this.set.has(key)) {
      this.values.push(obj);
      this.set.add(key);
    }
  }

  public has(obj: T) {
    return this.set.has(this.transform(obj));
  }

  public allValues() {
    return this.values;
  }
}

export function validDateFilter(item: {
  startDate?: string | null;
  endDate?: string | null;
}) {
  const now = Date.now();
  const validStartDate = item.startDate
    ? now > new Date(item.startDate).getTime()
    : true;
  const validEndDate = item.endDate
    ? now < new Date(item.endDate).getTime()
    : true;
  return validStartDate && validEndDate;
}

export function notDateEndedFilter(item: { endDate?: string | null }) {
  const now = Date.now();
  const validEndDate = item.endDate
    ? now < new Date(item.endDate).getTime()
    : true;
  return validEndDate;
}

export const skolidIstOrgRegex = /^ist\.\w{2}$/;
export const isSkolidIstOrg = (org?: string) =>
  org ? skolidIstOrgRegex.test(org) : false;

export const cleanSchoolUnitNumberFromName = (
  displayName: string,
  type?: gqltypes.Code_SchoolType | null
) => {
  if (type === gqltypes.Code_SchoolType.FS) return displayName;

  return displayName.replace(/f-\d|\d-\d|\d|grs/i, "").replace("  ", " ");
};

// const input = [
//   ["Katedralskolan 2", gqltypes.Code_SchoolType.GY],
//   ["Katedralskolan F-6", gqltypes.Code_SchoolType.GY],
//   ["Getingeskolan 4-6", gqltypes.Code_SchoolType.GR],
//   ["Getingeskolan 7-9", gqltypes.Code_SchoolType.GR],
//   ["Getingeskolan F-3", gqltypes.Code_SchoolType.GR],
//   ["Getingeskolan grs", gqltypes.Code_SchoolType.GR],
//   ["Nyhemsskolan F-3 A", gqltypes.Code_SchoolType.GR],
//   ["Nyhemsskolan F-3 B", gqltypes.Code_SchoolType.GR],
//   ["Katedralskolan F-6", gqltypes.Code_SchoolType.GY],
//   ["Katedralskolan F-6", gqltypes.Code_SchoolType.GY],
//   ["Katedralskolan F-6", gqltypes.Code_SchoolType.GY],
//   ["Förskolan gurkan", gqltypes.Code_SchoolType.FS],
//   ["Förskola gurkvägen 5", gqltypes.Code_SchoolType.FS],
//   ["Förskola utsädesgatan 54", gqltypes.Code_SchoolType.FS]
// ] as any;

// console.log(
//   input
//     .map((inp: any) => cleanSchoolUnitNumberFromName(inp[0], inp[1]))
//     .join("\n")
// );
