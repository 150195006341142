const memCache = new Map<string, string>();

export function get(key: string) {
  try {
    return window.localStorage.getItem(key);
  } catch (e) {
    return memCache.get(key) || null;
  }
}

export function set(key: string, value: string) {
  try {
    window.localStorage.setItem(key, value);
    return true;
  } catch (e) {
    memCache.set(key, value);
    return false;
  }
}

export function remove(key: string) {
  try {
    window.localStorage.removeItem(key);
  } catch (e) {
    memCache.delete(key);
    // Do nothing
  }
}
