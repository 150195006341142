import gql from "graphql-tag";
import _ from "lodash";
import React, { useState } from "react";
import { Query } from "@apollo/client/react/components";
import { Collapse } from "reactstrap";
import {
  Translation,
  useAdminContext,
  useTranslation,
} from "../../App/reducer";
import { definedNotNull } from "../../Utils/functional";
import * as gqltypes from "../../gqltypes";
import { getPermissionDisplayName } from "../../permissions";
import { ComplexSet, getCombinedIdCustomerId } from "../utils";
import { GenericError } from "./GenericError";
import { Checkbox, Loading } from "..";
import { HelpTooltip } from "./HelpTooltip";

const FORM_ACCESS_SCHOOLUNITS_QUERY = gql`
  query FormAccessSchoolUnits($context: Context!) {
    organisation(context: $context) {
      id
      displayName
      schoolUnits(filter: { permissionType: create }) {
        id
        customerId
        displayName
      }
    }
  }
`;

interface SuEditorProps {
  tr: Translation["tr"];
  access: gqltypes.FormAccessInput;
  disabled?: boolean;
  collapsed: boolean;
  adminContext: gqltypes.Context;
  handleChangeSchoolunitAccess: Props["handleChangeSchoolunitAccess"];
  permType: gqltypes.PermissionType;
  schoolUnits: gqltypes.FormAccessSchoolUnits_organisation_schoolUnits[];
}

export const SuEditor = (props: SuEditorProps) => {
  const { tr, access, disabled, schoolUnits } = props;
  const selectedSet = new ComplexSet(
    getCombinedIdCustomerId,
    props.access.schoolUnits
  );
  const schoolUnitsSorted = _.orderBy(schoolUnits, "displayName");
  return (
    <React.Fragment>
      <p>
        {tr(
          "formAccessSuDescription",
          getPermissionDisplayName(props.permType, tr)
        )}{" "}
        <HelpTooltip
          tooltipText={tr("createFormWhoHasAccessToSingleSchoolUnitsTooltip")}
        />
      </p>
      <div className="row form-school-unit-permission">
        {schoolUnitsSorted.map((su) => (
          <div key={su.id} className="col-12 col-md-6 col-lg-4">
            <Checkbox
              id={su.id}
              label={su.displayName}
              checked={access.public || selectedSet.has(su)}
              disabled={access.public || disabled}
              onChange={(id, checked) =>
                props.handleChangeSchoolunitAccess &&
                props.handleChangeSchoolunitAccess(
                  { id: su.id, customerId: su.customerId },
                  checked
                )
              }
            />
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

interface SuViewerProps {
  tr: Translation["tr"];
  access: gqltypes.FullFormAccess;
  disabled?: boolean;
  permType: gqltypes.PermissionType;
  schoolUnits: gqltypes.FormAccessSchoolUnits_organisation_schoolUnits[];
}

export const SuViewer = (props: SuViewerProps) => {
  const { tr, access, schoolUnits } = props;
  const schoolUnitsSorted = _.orderBy(schoolUnits, "displayName");

  const getSchoolUnitsId = schoolUnits.map((id) => id.id);

  const accessSchoolUnitsFiltered =
    access.schoolUnits.filter((su) => getSchoolUnitsId?.includes(su!.id)) || [];

  return (
    <React.Fragment>
      <p>
        {tr(
          "formAccessSuDescription",
          getPermissionDisplayName(props.permType, tr)
        )}
      </p>
      <div className="row">
        {_.orderBy(
          (access.public
            ? schoolUnitsSorted
            : accessSchoolUnitsFiltered
          ).filter(definedNotNull),
          "displayName"
        ).map((su) => (
          <div key={su.id} className="col-12 col-md-6 col-lg-4">
            <Checkbox
              id={su.id}
              label={su.displayName}
              checked={true}
              disabled={access.public || true}
            />
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

interface Props {
  edit: boolean;
  access: gqltypes.FormAccessInput | gqltypes.FullFormAccess | null;
  formType: gqltypes.FormType;
  disabled?: boolean;
  handleChangeBooleanAccess?: (id: string, checked: boolean) => void;
  handleChangeSchoolunitAccess?: (
    suid: { id: string; customerId: string },
    checked: boolean
  ) => void;
}

export const FormAccess = (props: Props) => {
  const { access, handleChangeBooleanAccess, handleChangeSchoolunitAccess } =
    props;

  const { tr } = useTranslation();
  const [accessVisible, setAccessVisible] = useState(true);
  const adminContext = useAdminContext();

  const permType =
    props.formType === gqltypes.FormType.publication
      ? gqltypes.PermissionType.create
      : gqltypes.PermissionType.manage_application;

  const disabled =
    !handleChangeBooleanAccess ||
    !handleChangeSchoolunitAccess ||
    props.disabled;

  if (!access) {
    return null;
  }
  return (
    <Query<gqltypes.FormAccessSchoolUnits>
      query={FORM_ACCESS_SCHOOLUNITS_QUERY}
      variables={{ context: adminContext }}
      skip={!accessVisible}
      fetchPolicy="cache-first"
    >
      {({ loading, error, data }) => {
        const formAccesSchoolUnitsId = access.schoolUnits.map((asu) => asu?.id);

        const accessSchoolUnitsFiltred: gqltypes.FormAccessSchoolUnits_organisation_schoolUnits[] =
          data?.organisation.schoolUnits.filter((osu) =>
            formAccesSchoolUnitsId?.includes(osu.id)
          ) || [];

        if (data && !data.organisation && loading) {
          return <Loading fadeIn={0} />;
        }
        if (error) {
          return <GenericError />;
        }
        return (
          <div className="box p-content">
            <h3
              className="clickable"
              onClick={() => {
                setAccessVisible(!accessVisible);
              }}
            >
              {tr("formAccessTitle")}{" "}
              <i
                className={`fas fa-chevron-${accessVisible ? "up" : "down"}`}
              />
            </h3>
            <p>
              {tr("formAccessDescription")}
              {props.formType === gqltypes.FormType.publication ? (
                <>
                  {" "}
                  <HelpTooltip
                    tooltipText={tr(
                      "createFormWhoHasAccessToSeeTooltipPublication"
                    )}
                  />
                </>
              ) : props.formType === gqltypes.FormType.application ? (
                <>
                  {" "}
                  <HelpTooltip
                    tooltipText={tr(
                      "createFormWhoHasAccessToSeeTooltipApplication"
                    )}
                  />
                </>
              ) : null}
            </p>
            <Collapse isOpen={accessVisible}>
              <Checkbox
                id="public"
                label={tr(
                  "formAccessPublicPermission",
                  getPermissionDisplayName(permType, tr)
                )}
                checked={access.public}
                disabled={disabled}
                onChange={props.handleChangeBooleanAccess}
              />
              <Checkbox
                id="organisation"
                label={tr(
                  "formAccessOrganisationPermission",
                  getPermissionDisplayName(permType, tr)
                )}
                disabled={access.public || disabled}
                checked={access.public || access.organisation}
                onChange={props.handleChangeBooleanAccess}
              />
              {data && data.organisation ? (
                <>
                  {props.formType === gqltypes.FormType.application ? null : (
                    <h4 className="mt-3">
                      {tr(
                        "formAccessSingleSchoolUnits",
                        access.public
                          ? data.organisation.schoolUnits
                            ? data.organisation.schoolUnits.length
                            : 0
                          : accessSchoolUnitsFiltred?.length
                      )}
                    </h4>
                  )}
                  {/* No schoolUnit list on applications, only org level */}
                  {props.formType ===
                  gqltypes.FormType.application ? null : props.edit ? (
                    <SuEditor
                      tr={tr}
                      access={access as gqltypes.FormAccessInput}
                      disabled={disabled}
                      adminContext={adminContext}
                      collapsed={accessVisible}
                      handleChangeSchoolunitAccess={
                        handleChangeSchoolunitAccess
                      }
                      permType={permType}
                      schoolUnits={data.organisation.schoolUnits}
                    />
                  ) : (
                    <SuViewer
                      tr={tr}
                      access={access as gqltypes.FullFormAccess}
                      disabled={disabled}
                      permType={permType}
                      schoolUnits={accessSchoolUnitsFiltred}
                    />
                  )}
                </>
              ) : null}
            </Collapse>
          </div>
        );
      }}
    </Query>
  );
};
