import gql from "graphql-tag";
import _ from "lodash";
import * as React from "react";
import { useMutation, useQuery } from "@apollo/client";
import Dropzone, { Accept, FileRejection } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import * as actions from "../../App/actions";
import { useTranslation } from "../../App/reducer";
import {
  Button,
  FormInput,
  FormSelect,
  ISTContainer,
  Loading,
  Table,
} from "../../Common";
import { MutationButton } from "../../Common/components/MutationButton";
import { OrgLogo } from "../../Common/components/OrgLogo";
import { useRandomId } from "../../Common/hooks";
import {
  showAlertDialog,
  showConfirmDialog,
} from "../../Utils/dialogs/showDialog";
import * as gqltypes from "../../gqltypes";
import * as persistance from "../../persistance";
import { StoreState } from "../../store";
import { getAvailableUiLanguages } from "../../translation";
import {
  defaultDialogCancel,
  defaultDialogProceed,
} from "../../translation/strings";
import { getSettings } from "../../settings";

const settings = getSettings();

export const ORGANISATIONS_QUERY = gql`
  query SuperadminOrganisations {
    organisations {
      id
      displayName
      skolidOrgId
      country
      defaultLanguage
      dataSources {
        customerId
        name
        description
      }
      settings {
        emailSuffixWork
        emailSuffixHome
      }
    }
  }
`;

export const PERMISSIONS_QUERY = gql`
  query SuperadminOrganisationsPermissions {
    me {
      id
      name
      availableOrganisations {
        id
        displayName
        defaultLanguage
        myPermissions {
          organisation {
            roles {
              roleId
            }
          }
        }
      }
      connectedUsers(filter: skolid) {
        id
        name
        skolidOrganization {
          id
        }
        availableOrganisations {
          id
          displayName
          defaultLanguage
          myPermissions {
            organisation {
              roles {
                roleId
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_ORGANISATION_SETTINGS_MUTATION = gql`
  mutation SuperadminUpdateOrganisationSettings(
    $orgId: ID!
    $input: UpdateOrganisationSettingsInput!
  ) {
    updateOrganisationSettings(orgId: $orgId, input: $input) {
      id
      defaultLanguage
      settings {
        emailSuffixWork
        emailSuffixHome
      }
    }
  }
`;

const VisitOrgButton = (props: { label: string; orgId: string }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const availableOrgs = useSelector(
    (store: StoreState) => store.app.availableOrgs
  );
  return (
    <Button
      label={props.label}
      onClick={() => {
        if (!availableOrgs) {
          persistance.set("lastOrg", props.orgId);
        } else {
          dispatch(actions.orgWillChange({ id: props.orgId }));
        }
        navigate("/admin");
      }}
    />
  );
};

const EditEmailSuffix = (props: {
  label: string;
  path: string;
  oldValue: string;
  setValue: (value: string) => void;
}) => {
  const [suffix, setSuffix] = React.useState(props.oldValue);
  const id = useRandomId();

  const dirty = suffix !== props.oldValue;

  const adminLink = `${window.origin}${props.path}${suffix}`;

  return (
    <div className="row">
      <FormInput
        className="col-6 mb-0"
        id={id}
        label={props.label}
        value={suffix}
        onChange={(e) => {
          setSuffix(e.target.value);
        }}
      />
      <div className="col-6 d-flex align-items-end">
        <Button
          disabled={!dirty}
          label="Spara"
          onClick={() => {
            props.setValue(suffix);
          }}
        />
      </div>
      <div className="col">
        <a href={adminLink} target="_blank" rel="noreferrer">
          {adminLink}
        </a>
      </div>
    </div>
  );
};

const OrganisationSettings = (props: {
  orgId: string;
  defaultLanguage: gqltypes.ISO6391Language;
  settings: gqltypes.SuperadminOrganisations["organisations"][0]["settings"];
}) => {
  const { emailSuffixWork, emailSuffixHome } = props.settings;

  const [updateOrgSetting] = useMutation<
    gqltypes.SuperadminUpdateOrganisationSettings,
    gqltypes.SuperadminUpdateOrganisationSettingsVariables
  >(UPDATE_ORGANISATION_SETTINGS_MUTATION);

  const [language, setLanguage] = React.useState(props.defaultLanguage);

  return (
    <div className="mb-content content">
      <h3>Inställningar</h3>
      <div className="row" />
      <div className="row">
        <FormSelect
          className="mb-content col-3"
          id={useRandomId()}
          label="Default språk"
          options={getAvailableUiLanguages().map((lang) => ({
            label: lang,
            value: lang,
          }))}
          value={language}
          onChange={(e) => {
            setLanguage(e.currentTarget.value as any);
          }}
        />
        <div className="col-6 d-flex align-items-end mb-content">
          <Button
            disabled={language === props.defaultLanguage}
            label="Spara"
            onClick={() => {
              updateOrgSetting({
                variables: {
                  orgId: props.orgId,
                  input: { defaultLanguage: language },
                },
              });
            }}
          />
        </div>
      </div>
      <EditEmailSuffix
        label="Email länk suffix (Privat)"
        path=""
        oldValue={emailSuffixHome}
        setValue={(value) => {
          updateOrgSetting({
            variables: {
              orgId: props.orgId,
              input: { emailSuffixHome: value },
            },
          });
        }}
      />
      <br />
      <EditEmailSuffix
        label="Email länk suffix (Admin)"
        path="/admin"
        oldValue={emailSuffixWork}
        setValue={(value) => {
          updateOrgSetting({
            variables: {
              orgId: props.orgId,
              input: { emailSuffixWork: value },
            },
          });
        }}
      />
    </div>
  );
};

const ShowUploadOrgLogo = (props: { id: string }) => {
  const { tr } = useTranslation();
  const [dropzoneDisabled, setDropzoneDisabled] = React.useState(false);
  const [dropzoneActive, setDropzoneActive] = React.useState(false);
  const [logoQueryParams, setlogoQueryParams] = React.useState("");

  const [mutate] = useMutation<
    gqltypes.AdminSignLogoUpload,
    gqltypes.AdminSignLogoUploadVariables
  >(gql`
    mutation AdminSignLogoUpload($input: AdminSignLogoUploadInput!) {
      adminSignLogoUpload(input: $input) {
        key
        signedUrl
        post_headers {
          key
          value
        }
      }
    }
  `);

  const allowedFileUploadTypesAccept: Accept = {
    "image/png": [".png"],
  };
  const allowedFileUploadTypesString = ".png";
  const allowedMaxSize = 300000;
  const allowedMaxSizeString = "300KB";

  const fileOverlayStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 0,
    background: "rgba(0,0,0,0.5)",
    textAlign: "center",
    color: "#fff",
    zIndex: 20000,
  } as any;

  const handleFileUpload = async (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[]
  ) => {
    const confirmed = await showConfirmDialog({
      title: tr("superadminOrganisationConfirmLogoUploadTitle"),
      content: "Du håller på ladda upp en logga till organisationen",
      proceedText: defaultDialogProceed(tr),
      cancelText: defaultDialogCancel(tr),
    });

    if (!confirmed) {
      setDropzoneActive(false);
      return;
    }

    if (rejectedFiles.length) {
      await showAlertDialog({
        title: tr("formFailedAttachmentUploadTitle"),
        message: tr(
          "formFailedAttachmentUploadDescription",
          allowedFileUploadTypesString,
          allowedMaxSizeString
        ),
        proceedText: defaultDialogProceed(tr),
        cancelText: defaultDialogCancel(tr),
      });
      setDropzoneActive(false);
      return;
    }
    acceptedFiles.forEach(async (file) => {
      const res = await mutate({
        variables: {
          input: {
            orgId: props.id,
            filename: file.name,
            contentLength: file.size,
            contentType: file.type,
          },
        },
      });

      if (!res) {
        await showAlertDialog({
          title: tr("formFailedAttachmentUploadTitle"),
          message: tr(
            "formFailedAttachmentUploadDescription",
            allowedFileUploadTypesString,
            allowedMaxSizeString
          ),
          proceedText: defaultDialogProceed(tr),
          cancelText: defaultDialogCancel(tr),
        });
        console.error("Failed to get signed upload url from api");
        setDropzoneActive(false);
        return;
      }

      const formData = new FormData();

      res.data?.adminSignLogoUpload.post_headers.forEach((header) => {
        formData.append(header.key, header.value);
      });
      formData.append("file", file);

      const req = new Promise<any>((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.addEventListener("error", reject);
        request.addEventListener("readystatechange", () => {
          const validStatus = request.status >= 200 && request.status < 300;

          if (request.readyState !== 4) {
            return;
          }
          if (validStatus) {
            resolve(request.response);
          } else {
            reject();
          }
        });
        request.open("POST", settings.s3ApiUrl);
        request.send(formData);
      });
      req
        .then(() => {
          const options = {
            name: file.name,
            key: res.data?.adminSignLogoUpload.key,
            url: res.data?.adminSignLogoUpload.signedUrl,
          };
          console.log("done");
          setTimeout(() => {
            setlogoQueryParams("?time=" + Date.now());
          }, 1000);
        })
        .catch(() => {
          console.log("upload failed");
        })
        .finally(() => {
          setDropzoneActive(false);
        });
    });
  };

  return (
    <Dropzone
      disabled={dropzoneDisabled}
      multiple={false}
      maxSize={allowedMaxSize}
      accept={allowedFileUploadTypesAccept}
      onDrop={handleFileUpload}
      onDragEnter={() => setDropzoneActive(true)}
      onDragLeave={() => setDropzoneActive(false)}
    >
      {({ open: openFileDialog, getRootProps, getInputProps }) => (
        <div
          {...getRootProps({
            className: "position-relative",
          })}
        >
          {dropzoneActive && (
            <div style={fileOverlayStyle}>
              {tr("formComponentEditorDropToUpload")}
            </div>
          )}
          <OrgLogo
            id={props.id}
            height={60}
            className="mb-content"
            queryParams={logoQueryParams}
          />
          <input {...getInputProps()} disabled={false} />

          <div className="text-muted mb-3">
            Ladda upp en logga genom att dra den hit. 120px hög och transparent
            bakgrund föredras. Måste vara .png fil.
            <br />
            Klicka för att öppna filväljare.
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export const Organisations = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const orgs = useQuery<gqltypes.SuperadminOrganisations>(ORGANISATIONS_QUERY);
  const perms =
    useQuery<gqltypes.SuperadminOrganisationsPermissions>(PERMISSIONS_QUERY);

  if (orgs.loading) return <Loading />;

  const orgList = _.orderBy(orgs.data?.organisations ?? [], "displayName");

  if (params.id) {
    const org = orgList.find((o) => o.id === params.id);

    if (!org) return <div>Hittade inte organisation</div>;

    const myPermissions = perms.data?.me.availableOrganisations.find(
      (o) => o.id === org.id
    )?.myPermissions;
    return (
      <Organisation
        org={org}
        myPermissions={myPermissions}
        reloadPerms={perms.refetch}
      />
    );
  }

  const permissionsWithAccess = new Set(
    perms.data?.me.availableOrganisations
      .map((o) => o.id)
      .concat(
        _.flatten(
          perms.data?.me.connectedUsers?.map((cu) =>
            cu.availableOrganisations.map((ao) => ao.id)
          )
        )
      )
  );

  return (
    <ISTContainer header="Organisationer">
      <div className="p-content">
        <Table
          clickableRows
          headers={[
            { key: "name", element: "Namn" },
            { key: "country", element: "Land" },
            { key: "language", element: "Språk" },
            { key: "logo", element: "" },
            { key: "skolidOrgId", element: "SkolID org ID" },
            { key: "sources", element: "Datakällor" },
            { key: "link", element: "" },
          ]}
          rows={orgList.map((org) => ({
            key: org.id,
            onClick: () =>
              navigate(location.pathname + "/organisations/" + org.id),
            columns: {
              logo: {
                content: <OrgLogo id={org.id} height={30} fallbackText="-" />,
              },
              name: { content: org.displayName },
              country: { content: org.country },
              language: { content: org.defaultLanguage },
              skolidOrgId: {
                content: org.skolidOrgId,
              },
              sources: { content: org.dataSources.length },
              link: {
                content: permissionsWithAccess.has(org.id) ? (
                  <VisitOrgButton label="Gå till" orgId={org.id} />
                ) : null,
                preventsClick: true,
              },
            },
          }))}
        />
      </div>
    </ISTContainer>
  );
};

export const Organisation = (props: {
  org: gqltypes.SuperadminOrganisations["organisations"][0];
  myPermissions?:
    | gqltypes.SuperadminOrganisationsPermissions["me"]["availableOrganisations"][0]["myPermissions"]
    | null;
  reloadPerms: () => Promise<unknown>;
}) => {
  const { org } = props;

  return (
    <>
      <ShowUploadOrgLogo id={org.id} />
      <ISTContainer header={org.displayName}>
        <div className="p-content">
          <div className="mb-content">
            {/* <h2>{org.displayName}</h2> */}
            <div>
              ID: <strong>{org.id}</strong>
            </div>
            <div>
              Land: <strong>{org.country}</strong>
            </div>
            <div className="mb-content">
              Default språk: <strong>{org.defaultLanguage}</strong>
            </div>
            <div>
              SkolID Organisations ID:{" "}
              <strong>
                {org.skolidOrgId ? (
                  <a
                    href={`https://superadmin.skolid.se/organization/${org.skolidOrgId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {org.skolidOrgId}
                  </a>
                ) : (
                  "-"
                )}
              </strong>
            </div>
            <div>
              Educloud info:{" "}
              <a
                href={`https://educloud.ist.com/subscriptions/${org.id}`}
                target="_blank"
                rel="noreferrer"
              >
                Här
              </a>
            </div>
            <h3 className="mt-content">Källor</h3>
            <Table
              headers={[
                { key: "sourceId", element: "sourceId" },
                { key: "name", element: "Namn" },
                { key: "description", element: "Beskrivning" },
              ]}
              rows={org.dataSources.map((source) => ({
                key: source.customerId,
                columns: {
                  sourceId: { content: source.customerId },
                  name: { content: source.name },
                  description: { content: source.description },
                },
              }))}
            />
          </div>

          <OrganisationSettings
            orgId={org.id}
            defaultLanguage={org.defaultLanguage}
            settings={org.settings}
          />

          <h3>Avancerat</h3>

          <MutationButton
            mutation={gql`
              mutation adminForceCheckOrgPublicationRecipientChanges($id: ID!) {
                adminForceCheckOrgPublicationRecipientChanges(id: $id) {
                  code
                }
              }
            `}
            label="Tvinga koll av mottagare för alla publikationer"
            successLabel="Uppdaterat"
            variables={{
              id: org.id,
            }}
            onlyOnce
          />

          <div className="mt-5">
            <h3>Adminbehörighet</h3>
            {props.myPermissions?.organisation.roles.some(
              (r) => r.roleId === "admin"
            ) ? (
              <strong>Du är admin för organisationen</strong>
            ) : (
              <MutationButton
                mutation={gql`
                  mutation adminAssignAdminPermissionToMe($org: ID!) {
                    adminAssignAdminPermissionToMe(orgId: $org) {
                      code
                    }
                  }
                `}
                variables={{
                  org: org.id,
                }}
                label="Tilldela adminrättighet till mig"
                successLabel="Du är nu admin"
                onlyOnce
                onSuccess={props.reloadPerms}
                onClick={() => {
                  return showConfirmDialog({
                    title: `Bli admin på ${org.displayName}?`,
                    message: `Du håller på att bli admin för ${org.displayName}. Är du säker?`,
                    cancelText: "Avbryt",
                    proceedText: "OK",
                  });
                }}
              />
            )}
          </div>
          {props.myPermissions?.organisation.roles.length ? (
            <div className="mt-5">
              <VisitOrgButton
                label={`Gå till adminsida för ${org.displayName}`}
                orgId={org.id}
              />
            </div>
          ) : null}
        </div>
      </ISTContainer>
    </>
  );
};
