/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/display-name */
import { useParams, useLocation, useNavigate } from "react-router";

const withRouter = (WrappedComponent: any) => (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  return <WrappedComponent {...props} {...{ navigate, location, params }} />;
};

export default withRouter;
