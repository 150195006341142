import * as gqltypes from "./gqltypes";
import {
  normalizeNorwayNationalId,
  normalizeSwedishNationalId,
} from "./regional";
import { Region, RegionSettings, Settings } from "./types";

// Default settings
const origin = window.location.origin;

const prodSkolidSE = {
  authority: "https://skolid.se",
  skolidApiUrl: "https://api.skolid.se",
};
const prodSkolidNO = {
  openIdConnect: {
    clientId: "com.ist.home.consent-portal-no",
  },
  authority: "https://skoleid.no",
  skolidApiUrl: "https://api.skolid.se",
};

const demoNorway = {
  authority: "https://demo.skoleid.no",
};

const norwaySettings = {
  defaultWindowTitle: "IST Saksflyt",
  region: Region.norway as const,
};

const regionSettings: { [region in Region]: RegionSettings } = {
  se: {
    FSLetter: "F",
    defaultLanguage: gqltypes.ISO6391Language.sv,
    nationalIdRegex: /^(19|20)?\d{6}-?\w{4}$/,
    normalizeNationalId: normalizeSwedishNationalId,
    readableNationalId: (nationalId: string) => {
      if (nationalId.length !== 12) return nationalId;

      const first6 = nationalId.substr(2, 6);
      const last4 = nationalId.substr(8, 4);

      if (first6.length !== 6 || last4.length !== 4) {
        return nationalId;
      }

      return `${first6}-${last4}`;
    },
  },
  no: {
    FSLetter: "F",
    defaultLanguage: gqltypes.ISO6391Language.nb,
    nationalIdRegex: /^\d{6,8}-?\w{5}$/,
    normalizeNationalId: normalizeNorwayNationalId,
    readableNationalId: (nationalId: string) => nationalId,
  },
};

const defaultSettings: Settings = {
  skolidIdp: "local",
  releaseStage: "local",
  authority: "https://demo.skolid.se",
  skolidApiUrl: "https://api-demo.skolid.se",
  apiUrl: "https://consent-api-dev.home.ist.com/graphql",
  redirectUri: `${origin}/signin-callback`,
  signoutRedirectUri: `${origin}/signout-callback`,
  silentRedirectUri: `${origin}/silent-renew.html`,
  bugSnagApiKey: "10e8fdc38e4a436f63c822fffd31e016",
  openIdConnect: {
    clientId: "com.ist.home.consent-portal",
  },
  defaultWindowTitle: "IST Formulär",
  useHashRouter: false,
  region: Region.sweden,
  regionSettings: regionSettings.se, // default does not matter, is overriden
  s3ApiUrl: "https://minio-staging-api.ist.com/istforms-dox",
};

const localIp = "oscar.local";

// Override settings per host
const hostSettings: { [key: string]: Partial<Settings> } = {
  "localhost:3000": {
    apiUrl: "http://localhost:4005/graphql",
  },

  [`${localIp}:3000`]: {
    apiUrl: `http://${localIp}:4005/graphql`,
  },

  "consent-dev.ist.com": {
    apiUrl: "https://consent-api-dev.ist.com/graphql",
    releaseStage: "dev",
  },
  "consent-dev-no.ist.com": {
    ...demoNorway,
    ...norwaySettings,
    apiUrl: "https://consent-api-dev.ist.com/graphql",
    releaseStage: "dev",
  },

  "forms-9a-branch-se.ist.com": {
    apiUrl: "https://forms-service-9a-branch.ist.com/graphql",
    releaseStage: "dev",
    openIdConnect: {
      clientId: "ist.forms-ui-branch",
    },
  },
  "forms-9a-branch-no.ist.com": {
    ...demoNorway,
    ...norwaySettings,
    apiUrl: "https://forms-service-9a-branch.ist.com/graphql",
    releaseStage: "dev",
    openIdConnect: {
      clientId: "ist.forms-ui-branch",
    },
  },

  "forms-9a-dev-se.ist.com": {
    apiUrl: "https://forms-service-9a-dev.ist.com/graphql",
    releaseStage: "dev",
    openIdConnect: {
      clientId: "ist.forms-ui-dev",
    },
  },
  "forms-9a-dev-no.ist.com": {
    ...demoNorway,
    ...norwaySettings,
    apiUrl: "https://forms-service-9a-dev.ist.com/graphql",
    releaseStage: "dev",
    openIdConnect: {
      clientId: "ist.forms-ui-dev",
    },
  },

  "consent-demo.ist.com": {
    apiUrl: "https://consent-api-demo.ist.com/graphql",
    releaseStage: "demo",
    openIdConnect: {
      clientId: "com.ist.home.consent-demo-portal",
    },
    s3ApiUrl: "https://s3.ist.com/td-forms",
  },
  "consent-demo-no.ist.com": {
    ...demoNorway,
    ...norwaySettings,
    apiUrl: "https://consent-api-demo.ist.com/graphql",
    releaseStage: "demo",
    openIdConnect: {
      clientId: "com.ist.home.consent-demo-portal",
    },
    s3ApiUrl: "https://s3.ist.com/td-forms",
  },

  "consent-stage.ist.com": {
    apiUrl: "https://consent-api-stage.ist.com/graphql",
    releaseStage: "stage",
    openIdConnect: {
      clientId: "com.ist.home.consent-stage-portal",
    },
  },
  "consent-stage-no.ist.com": {
    ...demoNorway,
    ...norwaySettings,
    apiUrl: "https://consent-api-stage.ist.com/graphql",
    releaseStage: "stage",
    openIdConnect: {
      clientId: "com.ist.home.consent-stage-portal",
    },
  },

  "consent-accept-se.ist.com": {
    apiUrl: "https://consent-api-accept.ist.com/graphql",
    releaseStage: "accept",
    openIdConnect: {
      clientId: "com.ist.home.consent-accept-portal",
    },
  },
  "consent-accept-no.ist.com": {
    ...demoNorway,
    ...norwaySettings,
    apiUrl: "https://consent-api-accept.ist.com/graphql",
    releaseStage: "accept",
    openIdConnect: {
      clientId: "com.ist.home.consent-accept-portal",
    },
  },

  "svara.ist.se": {
    ...prodSkolidSE,
    apiUrl: "https://consent-api.ist.se/graphql",
    releaseStage: "production",
    s3ApiUrl: "https://s3.ist.com/td-forms",
  },
  "svar.no.ist.com": {
    ...prodSkolidNO,
    ...norwaySettings,
    apiUrl: "https://consent-api.ist.se/graphql",
    releaseStage: "production",
    s3ApiUrl: "https://s3.ist.com/td-forms",
  },
};

// Exported settings
const hsettings = hostSettings[window.location && window.location.host] || {};
const withoutRegionSettings = {
  ...defaultSettings,
  ...hsettings,
};
export function getSettings(): Settings {
  return {
    ...withoutRegionSettings,
    regionSettings: regionSettings[withoutRegionSettings.region],
  };
}

window.document.title = withoutRegionSettings.defaultWindowTitle;
