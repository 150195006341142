import gql from "graphql-tag";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useAdminContext, useTranslation } from "../../App/reducer";
import { Checkbox, ISTContainer, Loading, Table } from "../../Common";
import { GenericError } from "../../Common/components/GenericError";
import { Tooltip } from "../../Common/components/Tooltip";
import * as gqltypes from "../../gqltypes";
import {
  SchoolUnitValues,
  employmentRoleOrder,
  formComponentPermissions,
  getEmploymentRoleName,
  getPermissionDescription,
  getPermissionDisplayName,
  isPermissionDirty,
  permissionOnOrgOnly,
  permissionOrder,
  permissionSUHelper,
} from "../../permissions";
import { getSettings } from "../../settings";
import { AggregatedReadEditView } from "./UserPermissions";

const isDemoEnvironment = getSettings().releaseStage === "demo";

const DELIMITER = "__";

const usePermissionSettingsState = (data?: gqltypes.OrganisationSettings) => {
  const [suStylePermissions, setSuStylePermissions] =
    useState<SchoolUnitValues>({});
  const [initialSuStylePermissions, setInitialSuStylePermissions] =
    useState<SchoolUnitValues>({});

  useEffect(() => {
    const empPerms = data?.organisation.settings?.employmentPermissions;
    const newSuStylePermissions = {};

    empPerms?.forEach((ep) => {
      ep.roles.forEach((role) => {
        permissionSUHelper(
          "add",
          newSuStylePermissions,
          ep.employmentType,
          role,
          gqltypes.PermissionSource.DIRECT
        );
      });
    });
    setInitialSuStylePermissions(newSuStylePermissions);
    setSuStylePermissions(newSuStylePermissions);
  }, [data]);

  const employmentRoles = [
    ...new Set(
      Object.keys(suStylePermissions).concat(
        Object.keys(initialSuStylePermissions)
      )
    ),
  ];
  const dirty = employmentRoles.some((resourceId) =>
    isPermissionDirty(
      suStylePermissions[resourceId],
      initialSuStylePermissions[resourceId]
    )
  );

  return {
    suStyle: suStylePermissions,
    setSuStylePermission: setSuStylePermissions,
    initialSuStylePermissions,
    dirty,
  };
};

const useOrganisationSettingsState = (data?: gqltypes.OrganisationSettings) => {
  const [autoUpdateRecipients, setAutoUpdateRecipients] = useState(false);
  const [initialUpdateRecipinets, setInitialUpdateRecipients] =
    useState<boolean>();

  useEffect(() => {
    const autoUpdate = data?.organisation.settings?.autoUpdateRecipients;

    if (autoUpdate !== undefined) {
      setInitialUpdateRecipients(autoUpdate);
      setAutoUpdateRecipients(autoUpdate);
    }
  }, [data]);

  const dirty = autoUpdateRecipients !== initialUpdateRecipinets;

  return { autoUpdateRecipients, setAutoUpdateRecipients, dirty };
};

export const OrganisationSettings = () => {
  const adminContext = useAdminContext();
  const { tr } = useTranslation();
  const { data, loading, error, refetch } = useQuery<
    gqltypes.OrganisationSettings,
    gqltypes.OrganisationSettingsVariables
  >(ORG_SETTINGS_QUERY, {
    variables: { context: adminContext },
  });

  const [saveOrganisationSettingsMutation, { loading: saving }] = useMutation<
    gqltypes.SaveOrganisationSettings,
    gqltypes.SaveOrganisationSettingsVariables
  >(SAVE_ORG_SETTINGS);

  const permissionState = usePermissionSettingsState(data);
  const orgSettings = useOrganisationSettingsState(data);

  if (error) {
    return <GenericError title={tr("genericLoadError")} />;
  }

  if (!data || loading) {
    return <Loading />;
  }

  const handleSUPermissionCheck = (id: string, checked: boolean) => {
    const index = id.indexOf(DELIMITER);
    const suid = id.slice(0, index);
    const roleId = id.slice(
      index + DELIMITER.length
    ) as gqltypes.PermissionType;
    const newSuPermissions = _.cloneDeep(permissionState.suStyle);

    const mode = checked ? "add" : "remove";

    permissionSUHelper(
      mode,
      newSuPermissions,
      suid,
      roleId,
      gqltypes.PermissionSource.DIRECT
    );

    // Logic to remove write_publication_response when all reads are removed
    const hasRead = formComponentPermissions.some(
      (readPerm) => newSuPermissions?.[suid]?.[readPerm] !== undefined
    );

    if (mode === "remove" && !hasRead) {
      permissionSUHelper(
        "remove",
        newSuPermissions,
        suid,
        gqltypes.PermissionType.write_publication_response,
        gqltypes.PermissionSource.DIRECT
      );
    }

    permissionState.setSuStylePermission(newSuPermissions);
  };

  const saveEmploymentPermissions = async () => {
    const enrolmentTypes = [
      ...new Set(
        Object.keys(permissionState.suStyle).concat(
          Object.keys(permissionState.initialSuStylePermissions)
        )
      ),
    ] as gqltypes.EmploymentRole[];

    const employmentPermissions: gqltypes.SaveOrganisationSettingsVariables["input"]["employmentPermissions"] =
      [];

    enrolmentTypes.forEach((employmentType) => {
      const current = permissionState.suStyle[employmentType];
      const initial = permissionState.initialSuStylePermissions[employmentType];
      if (isPermissionDirty(current, initial)) {
        employmentPermissions.push({
          employmentType,
          roles: (Object.keys(current) as gqltypes.PermissionType[]).filter(
            (roleId) =>
              current[roleId]!.some(
                (src) => src === gqltypes.PermissionSource.DIRECT
              )
          ) as gqltypes.PermissionType[],
        });
      }
    });

    await saveOrganisationSettingsMutation({
      variables: { input: { employmentPermissions }, context: adminContext },
    });
  };

  return (
    <main>
      <h1>{tr("orgSettingsTitle")}</h1>
      <p className="col-12 col-md-9 p-0 pb-3">{tr("orgSettingsDescription")}</p>
      <ISTContainer header={tr("orgSettingsGeneralSettings")}>
        <div className="p-content">
          <Checkbox
            id="autoUpdateRecipients"
            label={tr("autoUpdateRecipientsLabel")}
            checked={orgSettings.autoUpdateRecipients}
            onChange={(id, checked) => {
              orgSettings.setAutoUpdateRecipients(checked);
            }}
          />
          <div className="p-content clearfix">
            <button
              className="btn btn-primary float-right"
              disabled={!orgSettings.dirty || saving}
              onClick={() => {
                saveOrganisationSettingsMutation({
                  variables: {
                    input: {
                      autoUpdateRecipients: orgSettings.autoUpdateRecipients,
                    },
                    context: adminContext,
                  },
                });
              }}
            >
              {tr("save")}
            </button>
          </div>
        </div>
      </ISTContainer>

      <ISTContainer header={tr("orgSettingsRolePermissions")}>
        <div className="row p-content">
          <p className="col-12 col-md-9 mb-0">
            {tr("orgSettingsRolePermissionsDescription")}
          </p>
        </div>
        <Table
          thClassName="no-upper"
          unsortable
          headers={[
            {
              key: "employmentRoleName",
              element: tr("employmentRole"),
              usingSortValue: true,
            },
          ].concat(
            permissionOrder
              .filter((type) => !permissionOnOrgOnly[type])
              .map((type) => {
                return {
                  key: type,
                  element: (
                    <Tooltip
                      target={`tooltip${DELIMITER}su${DELIMITER}${type}`}
                      content={getPermissionDisplayName(type, tr)}
                      message={getPermissionDescription(type, tr)}
                    />
                  ) as any,
                  usingSortValue: true,
                  style:
                    type === gqltypes.PermissionType.read
                      ? undefined
                      : { width: "10%" },
                  sortArrowOwnLine: true,
                  className: "text-center",
                };
              })
          )}
          rows={employmentRoleOrder.map((employmentRole) => {
            const hasSuReadPermission = formComponentPermissions.some(
              (readPerm) =>
                permissionState.suStyle[employmentRole]?.[readPerm] !==
                undefined
            );
            return {
              key: employmentRole,
              columns: {
                employmentRoleName: {
                  content: getEmploymentRoleName(tr, employmentRole),
                },
                ...permissionOrder
                  .filter((type) => !permissionOnOrgOnly[type])
                  .reduce((acc, type) => {
                    const checked =
                      permissionState.suStyle[employmentRole]?.[type] !==
                      undefined;

                    acc[type] = {
                      sortValue: checked,
                      className: "text-center",
                      content:
                        type === gqltypes.PermissionType.read ? (
                          <AggregatedReadEditView
                            tr={tr}
                            prefixId={`${employmentRole}${DELIMITER}`}
                            mode="su"
                            permission={
                              permissionState.suStyle[employmentRole] || {}
                            }
                            handlePermissionChange={handleSUPermissionCheck}
                            disabled={isDemoEnvironment}
                          />
                        ) : (
                          <Checkbox
                            id={`${employmentRole}${DELIMITER}${type}`}
                            containerClassName="d-inline-block"
                            checked={checked}
                            onChange={handleSUPermissionCheck}
                            disabled={
                              isDemoEnvironment ||
                              (type ===
                                gqltypes.PermissionType
                                  .write_publication_response &&
                                !hasSuReadPermission)
                            }
                          />
                        ),
                    };
                    return acc;
                  }, {} as any),
              },
            };
          })}
        />
        {isDemoEnvironment ? (
          <div className="alert alert-warning">
            {tr("orgSettingsEmploymentRolesDisabledDemoEnv")}
          </div>
        ) : null}
        <div className="p-content clearfix">
          <button
            className="btn btn-primary float-right"
            disabled={!permissionState.dirty || saving || isDemoEnvironment}
            onClick={isDemoEnvironment ? undefined : saveEmploymentPermissions}
          >
            {tr("save")}
          </button>
        </div>
      </ISTContainer>
      <ISTContainer header={tr("orgSettingsDataSourcesHeader")}>
        <div className="row p-content">
          <p className="col-12 col-md-9 mb-0">
            {tr("orgSettingsDataSourcesDescription")}
          </p>
        </div>
        <Table
          headers={[
            { key: "id", element: tr("id") },
            { key: "name", element: tr("name") },
            { key: "description", element: tr("description") },
          ]}
          rows={data.organisation.dataSources.map((ds) => ({
            key: ds.customerId,
            columns: {
              id: { content: ds.customerId },
              name: { content: ds.name },
              description: { content: ds.description },
            },
          }))}
        />
      </ISTContainer>
    </main>
  );
};

const ORG_SETTINGS_FRAGMENT = gql`
  fragment OrganisationWithSettings on Organisation {
    id
    displayName
    dataSources {
      customerId
      name
      description
    }
    settings {
      autoUpdateRecipients
      employmentPermissions {
        employmentType
        roles
      }
    }
  }
`;

const ORG_SETTINGS_QUERY = gql`
  query OrganisationSettings($context: Context!) {
    organisation(context: $context) {
      ...OrganisationWithSettings
    }
  }
  ${ORG_SETTINGS_FRAGMENT}
`;

const SAVE_ORG_SETTINGS = gql`
  mutation SaveOrganisationSettings(
    $input: UpdateOrganisationSettingsInput!
    $context: Context!
  ) {
    updateOrganisationSettings(input: $input, context: $context) {
      ...OrganisationWithSettings
    }
  }
  ${ORG_SETTINGS_FRAGMENT}
`;
