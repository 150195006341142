/***
    Type safe currying.
    If you need to you can add more arities.
***/

type Curry1<A, R> = (a: A) => R;

type Curry2<A, B, R> = {
  (a: A): Curry1<B, R>;
  (a: A, b: B): R;
};

type Curry3<A, B, C, R> = {
  (a: A): Curry2<B, C, R>;
  (a: A, b: B): Curry1<C, R>;
  (a: A, b: B, c: C): R;
};

type Curry4<A, B, C, D, R> = {
  (a: A): Curry3<B, C, D, R>;
  (a: A, b: B): Curry2<C, D, R>;
  (a: A, b: B, c: C): Curry1<D, R>;
  (a: A, b: B, c: C, d: D): R;
};

type VariadicCurry<T1, T2, R> = T1 extends [any, any, any, any]
  ? T2 extends [T1[0], T1[1], T1[2]]
    ? Curry1<T1[3], R>
    : T2 extends [T1[0], T1[1]]
    ? Curry2<T1[2], T1[3], R>
    : T2 extends [T1[0]]
    ? Curry3<T1[1], T1[2], T1[3], R>
    : T2 extends []
    ? Curry4<T1[0], T1[1], T1[2], T1[3], R>
    : unknown
  : T1 extends [any, any, any]
  ? T2 extends [T1[0], T1[1]]
    ? Curry1<T1[2], R>
    : T2 extends [T1[0]]
    ? Curry2<T1[1], T1[2], R>
    : T2 extends []
    ? Curry3<T1[0], T1[1], T1[2], R>
    : unknown
  : T1 extends [any, any]
  ? T2 extends [T1[0]]
    ? Curry1<T1[1], R>
    : T2 extends []
    ? Curry2<T1[0], T1[1], R>
    : unknown
  : T1 extends [any]
  ? T2 extends []
    ? Curry1<T1[0], R>
    : unknown
  : unknown;

export default function curry<T extends any[], R>(
  fn: (...args: T) => R
): VariadicCurry<T, [], R>;

export default function curry<T1 extends any[], T2 extends any[], R>(
  fn: (...args: T1) => R,
  ...args: T2
): VariadicCurry<T1, T2, R>;

export default function curry<T extends any[], R>(
  fn: (...args: T) => R,
  ...suppliedArgs: T
) {
  return (...innerArgs: any[]) => {
    const newArgs = [...suppliedArgs, ...innerArgs];
    if (fn.length > newArgs.length) {
      return curry.apply(null, [fn, ...newArgs]);
    } else {
      // eslint-disable-next-line prefer-spread
      return fn.apply(null, newArgs);
    }
  };
}
