import * as React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { Translation } from "../../App/reducer";
import * as gqltypes from "../../gqltypes";
import {
  getComponentPermissionDisplayName,
  getPermissionDisplayName,
} from "../../permissions";

export function PermissionsTooltip(props: {
  tr: Translation["tr"];
  target: string;
}) {
  const { tr } = props;
  return (
    <UncontrolledTooltip target={props.target}>
      {tr("permissionToolTip1")}
      <i>{getPermissionDisplayName(gqltypes.PermissionType.read_base, tr)}</i>{" "}
      {tr(
        "permissionToolTip2",
        getComponentPermissionDisplayName(
          gqltypes.FormComponentPermission.read_base,
          tr
        )
      )}
    </UncontrolledTooltip>
  );
}
