import "./sentry";
import "./Styles/index.scss";

import * as React from "react";
import { ApolloProvider } from "@apollo/client";
import * as ReactRedux from "react-redux";
import * as Sentry from "@sentry/react";
import { AppContainer } from "./App";
import { applicationLaunched } from "./App/actions";
import { client } from "./api";
import { store } from "./store";
import { getTr } from "./translation";

store.dispatch(applicationLaunched());

const FallbackComponent = (): React.ReactElement => {
  const state = store.getState();
  const tr = getTr(state.app.uiLanguage);
  return (
    <main>
      <div className="start-box">
        <h1>{tr("rootFallbackTitle")}</h1>
        <p>{tr("rootFallbackMessage")}</p>
      </div>
    </main>
  );
};

export const Root = () => (
  <Sentry.ErrorBoundary fallback={FallbackComponent}>
    <ApolloProvider client={client}>
      <ReactRedux.Provider store={store}>
        <AppContainer />
      </ReactRedux.Provider>
    </ApolloProvider>
  </Sentry.ErrorBoundary>
);
