import React from "react";
import * as gqltypes from "../../gqltypes";
import { Translation } from "../../App/reducer";

const replyColumn = (
  response: gqltypes.applicationReportDetails_application_responses,
  components: gqltypes.applicationReportDetails_application_form_componentData_components[],
  tr: Translation["tr"]
): React.ReactNode[] => {
  const matchingComponent = components.find(
    (
      component: gqltypes.applicationReportDetails_application_form_componentData_components
    ) => component.applicationFeedbackId === response?.feedbackId
  );

  const feedbackTitle: string = matchingComponent?.title || "";
  const feedbackDescription = response?.feedbackDescription
    ? JSON.parse(response.feedbackDescription)?.blocks?.[0]?.text || ""
    : "";

  const filteredOutActions: gqltypes.applicationReportDetails_application_responses_actions[] =
    response?.actions?.filter(
      (action) =>
        action.action &&
        ![
          gqltypes.ApplicationResponseAction.create,
          gqltypes.ApplicationResponseAction.sign,
          gqltypes.ApplicationResponseAction.assign,
          gqltypes.ApplicationResponseAction.complement,
        ].includes(action.action)
    ) || [];

  const feedback: React.ReactNode[] = [];
  const uniqueFeedback: Set<string> = new Set<string>(); // To keep track of unique feedback messages

  filteredOutActions.forEach((action, index) => {
    switch (action.action) {
      case gqltypes.ApplicationResponseAction.reject_input:
        if (action.comment !== null) {
          appendFeedbackItem(
            feedback,
            uniqueFeedback,
            index,
            tr("applicationReportRejected"),
            action.comment ?? ""
          );
        }
        break;
      case gqltypes.ApplicationResponseAction.withdraw:
        appendFeedbackItem(
          feedback,
          uniqueFeedback,
          index,
          tr("applicationReportWithdrawnByGuardian")
        );
        break;
      case gqltypes.ApplicationResponseAction.no_feedback:
        appendFeedbackItem(
          feedback,
          uniqueFeedback,
          index,
          tr("applicationReportApprovedWithoutFeedback")
        );
        break;
      case gqltypes.ApplicationResponseAction.send_feedback:
      case gqltypes.ApplicationResponseAction.ack:
        if (response.status === gqltypes.ApplicationResponseStatus.closed) {
          appendFeedbackItem(
            feedback,
            uniqueFeedback,
            index,
            tr("applicationReportFeedback"),
            feedbackTitle || "",
            feedbackDescription || ""
          );
        }
        break;
      case gqltypes.ApplicationResponseAction.sign_feedback:
        if (response.status === gqltypes.ApplicationResponseStatus.resolved) {
          appendFeedbackItem(
            feedback,
            uniqueFeedback,
            index,
            tr("applicationReportFeedback"),
            feedbackTitle || "",
            feedbackDescription || ""
          );
        }
        break;
      default:
        if (action.comment !== null) {
          appendFeedbackItem(
            feedback,
            uniqueFeedback,
            index,
            feedbackTitle,
            action.comment ?? ""
          );
        }
        break;
    }
  });

  return feedback;
};

const appendFeedbackItem = (
  feedback: React.ReactNode[],
  uniqueFeedback: Set<string>,
  index: number,
  feedbackComponentTitle: string,
  feedbackComponentHeadingTitle?: string,
  feedbackDescriptionOrComment?: string
): void => {
  // Normalize the key by trimming whitespace and converting to lowercase
  const key = `${feedbackComponentTitle.trim().toLowerCase()}${(
    feedbackComponentHeadingTitle ?? ""
  )
    .trim()
    .toLowerCase()}${(feedbackDescriptionOrComment ?? "")
    .trim()
    .toLowerCase()}`;

  if (!uniqueFeedback.has(key)) {
    uniqueFeedback.add(key);

    // Add a period at the end of formattedFeedbackComponentHeadingTitle if it doesn't already end with one
    const formattedFeedbackComponentHeadingTitle: string | undefined =
      feedbackComponentHeadingTitle &&
      !feedbackComponentHeadingTitle.trim().endsWith(".")
        ? `${feedbackComponentHeadingTitle.trim()}.`
        : feedbackComponentHeadingTitle?.trim();

    feedback.push(
      <div key={index} className="mb-2">
        <p className="font-weight-bold">{feedbackComponentTitle}</p>
        <div className="d-flex">
          {formattedFeedbackComponentHeadingTitle && (
            <span className="text-nowrap">
              {formattedFeedbackComponentHeadingTitle}
            </span>
          )}
          {feedbackDescriptionOrComment && (
            <span
              className="text-truncate"
              style={{ marginLeft: "1px", maxWidth: "200px" }}
            >
              {feedbackDescriptionOrComment}
            </span>
          )}
        </div>
      </div>
    );
  }
};

export default replyColumn;
