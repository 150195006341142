import gql from "graphql-tag";
import { findIndex } from "lodash";
import * as React from "react";
import { useQuery } from "@apollo/client";
import { Collapse, UncontrolledTooltip } from "reactstrap";
import { Translation } from "../../App/reducer";
import { definedNotNull, replaceInArray } from "../../Utils/functional";
import * as gqltypes from "../../gqltypes";
import { getSettings } from "../../settings";
import {
  cleanSchoolUnitNumberFromName,
  getCombinedIdCustomerId,
  getPartsFromCombinedIdCustomerId,
  notDateEndedFilter,
} from "../utils";
import { isValidQuestionAnswer } from "../validation";
import { Button } from "./Button";
import { GenericError } from "./GenericError";
import { FormComponentQuestionView, FormSelect, Loading } from "..";

const readableNationalId = getSettings().regionSettings.readableNationalId;

export const applicationCustomComponentId = "application_custom_component";
export type CustomComponentQuestionIds =
  | "application_creator_name"
  | "application_creator_nationalId"
  | "application_creator_email"
  | "application_sub_id"
  | "application_sub_customerId"
  | "application_sub_name"
  | "application_sub_nationalId"
  | "application_co_name"
  | "application_co_nationalId"
  | "application_co_email";

const getComponentQuestions = (
  tr: Translation["tr"] | null,
  requiredCo: boolean
): { [key in CustomComponentQuestionIds]: any } => {
  return {
    application_creator_name: getQuestion(
      "application_creator_name",
      "name",
      true
    ),
    application_creator_nationalId: getQuestion(
      "application_creator_nationalId",
      "nationalId",
      true
    ),
    application_creator_email: getQuestion(
      "application_creator_email",
      tr ? tr("application_creator_email") : "Epost",
      true,
      gqltypes.ShortTextValidationType.email
    ),
    application_sub_id: getQuestion("application_sub_id", "id", true),
    application_sub_customerId: getQuestion(
      "application_sub_customerId",
      "customerId",
      true
    ),
    application_sub_name: getQuestion(
      "application_sub_name",
      tr ? tr("application_sub_name") : "För- och efternamn",
      true
    ),
    application_sub_nationalId: getQuestion(
      "application_sub_nationalId",
      tr ? tr("application_sub_nationalId") : "Personnummer",
      true,
      gqltypes.ShortTextValidationType.nationalId
    ),
    application_co_name: getQuestion(
      "application_co_name",
      tr ? tr("application_co_name") : "För- och efternamn",
      requiredCo
    ),
    application_co_nationalId: getQuestion(
      "application_co_nationalId",
      tr ? tr("application_co_nationalId") : "Personnummer",
      requiredCo,
      gqltypes.ShortTextValidationType.nationalId
    ),
    application_co_email: getQuestion(
      "application_co_email",
      tr ? tr("application_co_email") : "Epost",
      requiredCo,
      gqltypes.ShortTextValidationType.email
    ),
  };
};

export const isValidCustomComponent = (response: gqltypes.FormAnswer) => {
  const componentAnswer =
    response &&
    response.components &&
    response.components.find(
      (r) => r.componentId === applicationCustomComponentId
    );

  if (!componentAnswer) return false;

  const coName = getShortTextAnswer(componentAnswer, "application_co_name");
  const coNationalId = getShortTextAnswer(
    componentAnswer,
    "application_co_nationalId"
  );
  const coEmail = getShortTextAnswer(componentAnswer, "application_co_email");

  const questions = getComponentQuestions(
    null,
    Boolean(coName || coNationalId || coEmail)
  );

  if (
    !isValidQuestionAnswer(
      questions.application_creator_email,
      getAnswer(componentAnswer, "application_creator_email")
    )
  ) {
    return false;
  }

  const subId = getShortTextAnswer(componentAnswer, "application_sub_id");

  if (subId && subId !== "other") {
    return true;
  }

  // picked manual entry
  // Subject parts
  const validSubName = isValidQuestionAnswer(
    questions.application_sub_name,
    getAnswer(componentAnswer, "application_sub_name")
  );
  const validSubNationalId = isValidQuestionAnswer(
    questions.application_sub_nationalId,
    getAnswer(componentAnswer, "application_sub_nationalId")
  );

  if (!(validSubName && validSubNationalId)) return false;

  // CoSigner parts

  const validCoName = isValidQuestionAnswer(
    questions.application_co_name,
    getAnswer(componentAnswer, "application_co_name")
  );
  const validCoNationalId = isValidQuestionAnswer(
    questions.application_co_nationalId,
    getAnswer(componentAnswer, "application_co_nationalId")
  );
  const validCoEmail = isValidQuestionAnswer(
    questions.application_co_email,
    getAnswer(componentAnswer, "application_co_email")
  );

  if (!(validCoName && validCoNationalId && validCoEmail)) {
    return false;
  }

  return true;
};

const getSuggestedSus = (
  tr: Translation["tr"],
  selectedUser: NonNullable<
    gqltypes.ApplicationCustomComponentRelations["me"]["relations"]
  >["edges"][0]["node"]
) => {
  const user = selectedUser;

  const enrolments = user?.enrolments?.filter(notDateEndedFilter) || [];
  const placements = user?.placements?.filter(notDateEndedFilter) || [];

  const result: NonNullable<{ value: string; label: string }>[] = [];
  const foundSet = new Set<string>();

  enrolments.forEach((enr) => {
    const { schoolUnit: su, schoolType } = enr;
    const completeId = su && getCombinedIdCustomerId(su);
    const dupId = `enr_${completeId}`;
    if (su && completeId && !foundSet.has(dupId)) {
      foundSet.add(dupId);
      result.push({
        value: completeId,
        label: cleanSchoolUnitNumberFromName(su.displayName, schoolType),
      });
    }
  });

  placements.forEach((pl) => {
    const { schoolUnit: su, schoolType } = pl;
    const completeId = su && getCombinedIdCustomerId(su);
    const dupId = `pl_${completeId}_${schoolType}`;
    if (su && completeId && !foundSet.has(dupId)) {
      foundSet.add(dupId);
      result.push({
        value: completeId,
        label: cleanSchoolUnitNumberFromName(su.displayName, schoolType),
      });
    }
  });

  result.push({
    value: "",
    label: tr("applicationCustomerComponentSelectSuOther"),
  });

  return result;
};

interface Props {
  tr: Translation["tr"];
  uiLanguage: Translation["uiLanguage"];
  validateAnswers?: boolean;
  subjectCoSignerLocked: boolean;
  singleGuardian?: boolean;
  schoolunitSpecific?: boolean;
  displayingForAdmin?: boolean;
  creator?: gqltypes.AdminViewApplicationResponse_applicationResponse_creator;
  formOwner: { id: string; displayName: string };

  selectedSchoolUnit?: { id: string; customerId: string } | null;
  updateSelectedSchoolUnit?: (
    input: { id: string; customerId: string } | null
  ) => void;
  // language: keyof availableTranslationLanguages;

  updateComponentAnswer?: (component: gqltypes.ComponentAnswerInput) => void;
  answer?: gqltypes.FormComponentAnswer | null;
}

const getQuestion = (
  id: CustomComponentQuestionIds,
  label: string,
  compulsory: boolean,
  validationType?: gqltypes.ShortTextValidationType
) => {
  return {
    id,
    compulsory,
    type: gqltypes.FormQuestionType.shortText,
    question: label,
    validationType,
  };
};

const getAnswer = (
  answer: gqltypes.FormComponentAnswer | undefined | null,
  questionId: CustomComponentQuestionIds
) => {
  return answer
    ? answer.questions.find((q) => q.questionId === questionId)
    : undefined;
};

export const getShortTextAnswer = (
  answer: gqltypes.FormComponentAnswer | undefined | null,
  questionId: CustomComponentQuestionIds
) => {
  const ans = getAnswer(answer, questionId);

  return ans ? (ans as gqltypes.AnswerShortText).shortText : undefined;
};

export const ApplicationCustomComponent = (props: Props) => {
  const update = (answers: gqltypes.QuestionAnswerUnion[]) => {
    if (props.subjectCoSignerLocked) return;

    if (!props.updateComponentAnswer) return;
    const componentId = applicationCustomComponentId;

    if (!definedNotNull(props.answer)) {
      return props.updateComponentAnswer({
        componentId,
        questions: answers,
      });
    }

    let newQuestions = props.answer.questions || [];

    answers.forEach((answer) => {
      const indexToUpdate = findIndex(
        newQuestions,
        (q) => q.questionId === answer.questionId
      );

      newQuestions =
        indexToUpdate === -1
          ? [...newQuestions, answer]
          : replaceInArray(newQuestions, indexToUpdate, answer);
    });

    props.updateComponentAnswer({
      componentId,
      questions: newQuestions,
      sensitiveInformationConsent: true,
    });
  };

  const updateSingle = (answer: gqltypes.QuestionAnswerUnion) => {
    update([answer]);
  };

  const selectSubjectWithId = (sub: { id: string; customerId: string }) => {
    update([
      { questionId: "application_sub_id", shortText: sub.id },
      {
        questionId: "application_sub_customerId",
        shortText: sub.customerId || "",
      },
      { questionId: "application_sub_name", shortText: "" },
      { questionId: "application_sub_nationalId", shortText: "" },
      { questionId: "application_co_name", shortText: "" },
      { questionId: "application_co_nationalId", shortText: "" },
      { questionId: "application_co_email", shortText: "" },
    ]);
  };

  const { tr, uiLanguage } = props;

  if (props.subjectCoSignerLocked) {
    const subId = getShortTextAnswer(props.answer, "application_sub_id");
    const subCustomerId = getShortTextAnswer(
      props.answer,
      "application_sub_customerId"
    );
    const subName = getShortTextAnswer(props.answer, "application_sub_name");
    const subNationalId = getShortTextAnswer(
      props.answer,
      "application_sub_nationalId"
    );

    const creatorName = getShortTextAnswer(
      props.answer,
      "application_creator_name"
    );
    const creatorNationalId = getShortTextAnswer(
      props.answer,
      "application_creator_nationalId"
    );

    const coSignerName = getShortTextAnswer(
      props.answer,
      "application_co_name"
    );
    const coSignerNationalId = getShortTextAnswer(
      props.answer,
      "application_co_nationalId"
    );

    return (
      <div className="mt-content">
        <i>{tr("applicationCustomComponentCreator")}</i>
        <p>
          <strong>
            {creatorName}
            {creatorNationalId ? (
              <>{` (${readableNationalId(creatorNationalId)})`}</>
            ) : null}
            {props.creator &&
            props.creator.verifiedRelation.isVerifiedRelation === true ? (
              ""
            ) : props.creator &&
              props.creator.verifiedRelation.isVerifiedRelation === false &&
              props.creator &&
              props.creator.verifiedRelation.customerId === null ? (
              <>
                <span
                  id={"tooltipApplicationCustomComponent"}
                  data-toggle="tooltip"
                  style={{
                    fontSize: "12px",
                    maxWidth: "90px",
                    minWidth: "90px",
                  }}
                  className="text-truncate align-baseline"
                >
                  {props.displayingForAdmin === true ? (
                    <>
                      <i
                        className="fas fa-exclamation-triangle text-warning m-1"
                        style={{
                          color: "var(--yellow)",
                        }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </span>
                <UncontrolledTooltip
                  target={"tooltipApplicationCustomComponent"}
                >
                  {tr("toolTipCreatorHasNoRelationToSubject")}
                </UncontrolledTooltip>
              </>
            ) : (
              <>
                <span
                  id={"tooltipApplicationCustomComponent"}
                  data-toggle="tooltip"
                  style={{
                    fontSize: "12px",
                    maxWidth: "90px",
                    minWidth: "90px",
                  }}
                  className="text-truncate align-baseline"
                >
                  {props.displayingForAdmin === true ? (
                    <>
                      <i
                        className="fas fa-exclamation-triangle text-warning m-1"
                        style={{
                          color: "var(--yellow)",
                        }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </span>
                <UncontrolledTooltip
                  target={"tooltipApplicationCustomComponent"}
                >
                  {tr(
                    "toolTipCreatorHasNoRelationToSubjectWithCustomerId",
                    props.creator?.verifiedRelation.customerId
                  )}
                </UncontrolledTooltip>
              </>
            )}
          </strong>
        </p>
        <i>{tr("applicationCustomComponentSubject")}</i>
        <p className="m-0">
          <strong>
            {subName ? subName : "?"}
            {subNationalId ? ` (${readableNationalId(subNationalId)})` : null}
          </strong>
        </p>
        {coSignerName ? (
          <span>
            {tr("applicationCustomComponentOtherGuardian")}:{" "}
            <strong>
              {coSignerName}{" "}
              {coSignerNationalId ? `(${coSignerNationalId})` : null}
            </strong>
          </span>
        ) : null}

        {props.displayingForAdmin ? (
          <p className="mt-3">
            {tr("applicationCustomComponentGuardianSource")}:{" "}
            {subId && subCustomerId
              ? tr("applicationCustomComponentGuardianSourceEducloud")
              : tr("applicationCustomComponentGuardianSourceManually")}
          </p>
        ) : null}
      </div>
    );
  }

  return (
    <ApplicationCustomComponentEditMode
      {...props}
      updateSingle={updateSingle}
      selectSubjectWithId={selectSubjectWithId}
    />
  );
};

interface EditModeProps extends Props {
  updateSingle: (answer: gqltypes.QuestionAnswerUnion) => void;
  selectSubjectWithId: (sub: { id: string; customerId: string }) => void;
}

const ApplicationCustomComponentEditMode = (props: EditModeProps) => {
  const { tr, uiLanguage, updateSingle, selectSubjectWithId } = props;
  const [hasSetEmailField, setHasSetEmailField] = React.useState(false);

  const subId = getShortTextAnswer(props.answer, "application_sub_id");

  const { data, loading, error } = useQuery<
    gqltypes.ApplicationCustomComponentRelations,
    gqltypes.ApplicationCustomComponentRelationsVariables
  >(
    gql`
      query ApplicationCustomComponentRelations($org: String!) {
        me {
          id
          name
          emails {
            value
            preferredHome
          }
          relations(
            relationTypes: [ward, foster_child]
            org: $org
            uniquePeople: true
          ) {
            edges {
              node {
                id
                customerId
                name
                source
                enrolments {
                  schoolType
                  startDate
                  endDate
                  schoolUnit {
                    id
                    customerId
                    displayName
                  }
                }
                placements {
                  schoolType
                  startDate
                  endDate
                  schoolUnit {
                    id
                    customerId
                    displayName
                  }
                }
              }
            }
          }
        }
      }
    `,
    { variables: { org: props.formOwner.id }, fetchPolicy: "cache-first" }
  );

  const subCustomerId = getShortTextAnswer(
    props.answer,
    "application_sub_customerId"
  );

  const selectedPerson = subCustomerId
    ? getCombinedIdCustomerId({ id: subId!, customerId: subCustomerId })
    : subId;

  const selectedPersonObj = data?.me.relations?.edges.find((r) =>
    r.node ? getCombinedIdCustomerId(r.node as any) === selectedPerson : false
  )?.node;

  const sus =
    props.schoolunitSpecific && selectedPersonObj
      ? getSuggestedSus(tr, selectedPersonObj)
      : [];

  React.useEffect(() => {
    if (!props.schoolunitSpecific) {
      props.updateSelectedSchoolUnit?.(null);
      return;
    }

    let newSu: { id: string; customerId: string } | null =
      (sus && sus[0] && getPartsFromCombinedIdCustomerId(sus[0].value)) ?? null;

    newSu = sus.length === 1 ? null : newSu;

    props.updateSelectedSchoolUnit?.(newSu);
    // TODO: Seems to work fine but might want to fix this lint issue some day
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subId]);

  if (loading) return <Loading />;

  const creatorEmail =
    data && data.me ? data.me.emails!.find((e) => e.preferredHome) : undefined;

  if (!hasSetEmailField && creatorEmail && creatorEmail.value) {
    setTimeout(() => {
      setHasSetEmailField(true);
      updateSingle({
        questionId: "application_creator_email",
        shortText: creatorEmail.value,
      });
    }, 1);
  }

  if (error || !data) {
    return <GenericError title={tr("genericLoadError")} />;
  }

  const options: { value: string; label: string }[] = [];
  options.push({
    value: "self",
    label: tr("applicationCustomComponent", data.me.name),
  });
  data.me.relations!.edges.forEach((e) => {
    const n = e.node;
    if (!n || !n.customerId) return;

    options.push({
      value: getCombinedIdCustomerId({
        id: n.id,
        customerId: n.customerId,
      }),
      label: e.node!.name || "??",
    });
  });
  options.push({
    value: "other",
    label: tr("applicationCustomComponentSubjectOther"),
  });

  const coName = getShortTextAnswer(props.answer, "application_co_name");
  const coNationalId = getShortTextAnswer(
    props.answer,
    "application_co_nationalId"
  );
  const coEmail = getShortTextAnswer(props.answer, "application_co_email");

  const questions = getComponentQuestions(
    tr,
    Boolean(coName || coNationalId || coEmail)
  );

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 col-md-6">
          <FormSelect
            label={tr("applicationCustomComponentWhoIsSubject")}
            options={options}
            defaultOption={
              selectedPerson
                ? undefined
                : tr("applicationCustomComponentChooseSubject")
            }
            value={selectedPerson || undefined}
            onChange={(e) => {
              selectSubjectWithId(
                getPartsFromCombinedIdCustomerId(e.currentTarget.value)
              );
            }}
            feedback={
              props.validateAnswers && !selectedPerson
                ? tr("applicationCustomComponentMustChooseSubject")
                : undefined
            }
          />
          <Collapse isOpen={props.schoolunitSpecific && sus.length > 1}>
            <div>
              <label className="question-title font-weight-bold">
                {tr("applicationCustomComponentSelectSchoolLabel")}
              </label>
              <br />
              {sus.map((su) => {
                const isUnselected = props.selectedSchoolUnit === null;
                const isSame = props.selectedSchoolUnit
                  ? su.value ===
                    getCombinedIdCustomerId(props.selectedSchoolUnit)
                  : false;
                const selected = su.value === "" ? isUnselected : isSame;
                return (
                  <Button
                    key={su.value}
                    className="mr-2"
                    label={su.label}
                    level={selected ? "primary" : "secondary"}
                    onClick={() =>
                      props.updateSelectedSchoolUnit?.(
                        su.value
                          ? getPartsFromCombinedIdCustomerId(su.value)
                          : null
                      )
                    }
                  />
                );
              })}
            </div>
          </Collapse>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <FormComponentQuestionView
            data={questions.application_creator_email}
            answer={getAnswer(props.answer, "application_creator_email")}
            updateAnswer={updateSingle}
            validateAnswers={props.validateAnswers}
            tr={tr}
            uiLanguage={uiLanguage}
          />
        </div>
        <p className="col-md-9">
          {tr("applicationCustomComponentCreatorEmailDescription")}
        </p>
      </div>
      <Collapse isOpen={selectedPerson === "other"}>
        <div>
          <hr />
          <div>{tr("application_sub_title")}</div>
          <div className="row">
            <div className="col-12 col-md-6">
              <FormComponentQuestionView
                data={questions.application_sub_name}
                answer={getAnswer(props.answer, "application_sub_name")}
                updateAnswer={updateSingle}
                validateAnswers={props.validateAnswers}
                tr={tr}
                uiLanguage={uiLanguage}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormComponentQuestionView
                data={questions.application_sub_nationalId}
                answer={getAnswer(props.answer, "application_sub_nationalId")}
                updateAnswer={updateSingle}
                validateAnswers={props.validateAnswers}
                tr={tr}
                uiLanguage={uiLanguage}
              />
            </div>
          </div>

          <hr />

          <div>{tr("application_co_title")}</div>
          <div className="row">
            <div className="col-12 col-md-4">
              <FormComponentQuestionView
                data={questions.application_co_name}
                answer={getAnswer(props.answer, "application_co_name")}
                updateAnswer={updateSingle}
                validateAnswers={props.validateAnswers}
                tr={tr}
                uiLanguage={uiLanguage}
              />
            </div>
            <div className="col-12 col-md-4">
              <FormComponentQuestionView
                data={questions.application_co_nationalId}
                answer={getAnswer(props.answer, "application_co_nationalId")}
                updateAnswer={updateSingle}
                validateAnswers={props.validateAnswers}
                tr={tr}
                uiLanguage={uiLanguage}
              />
            </div>
            <div className="col-12 col-md-4">
              <FormComponentQuestionView
                data={questions.application_co_email}
                answer={getAnswer(props.answer, "application_co_email")}
                updateAnswer={updateSingle}
                validateAnswers={props.validateAnswers}
                tr={tr}
                uiLanguage={uiLanguage}
              />
            </div>
          </div>
        </div>
      </Collapse>
    </React.Fragment>
  );
};
