import gql from "graphql-tag";

const childItemFragment = gql`
  fragment ChildItem on User {
    id
    name
    publicationRecipients {
      ...ChildItemRecipient
    }
    applicationResponses {
      id
      status
      created
      application {
        id
        name
        form {
          id
          name
        }
      }
      creator {
        id
        name
      }
      subject {
        userId
        name
      }
    }
  }

  fragment ChildItemRecipient on PublicationRecipient {
    id
    created
    form {
      id
      name
      language
    }
    responses(filter: LastDraftLastValid) {
      ...ChildItemPublicationRecipientResponse
    }
  }

  fragment ChildItemPublicationRecipientResponse on PublicationResponse {
    id
    created
    status
    signatures {
      signerId
      educloudUser {
        id
        customerId
        name
      }
      customerId
      educloudUserId
    }
  }
`;

export const FORM_DATA_QUERY = gql`
  query GuardianFormList {
    me {
      id
      emails {
        source
      }
      ...ChildItem
      connectedUsers(filter: educloud) {
        ...ChildItem
      }
      relations(relationTypes: [ward, foster_child]) {
        edges {
          type
          node {
            ...ChildItem
          }
        }
      }
    }
  }
  ${childItemFragment}
`;
