import gql from "graphql-tag";
import * as _ from "lodash";
import * as React from "react";
import { QueryResult, useMutation } from "@apollo/client";
import { graphql, DataValue } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { Location, NavigateFunction, Params, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {
  AdminContext,
  Translation,
  withAdminContext,
  withTranslation,
} from "../../App/reducer";
import {
  Button,
  Checkbox,
  FormSelect,
  ISTContainer,
  Loading,
  Table,
  TableColumnData,
  TableHeader,
  TableRow,
  TableSortOrder,
} from "../../Common";
import { GenericError } from "../../Common/components/GenericError";
import { Time } from "../../Common/components/Time";
import { Tooltip } from "../../Common/components/Tooltip";
import { NameWithPotentialLink } from "../../Common/components/Utils";
import {
  formComponentDataFragment,
  fullResponseFragment,
} from "../../Common/fragments";
import {
  CREATE_RESPONSE_MUTATION,
  CREATE_RESPONSE_MUTATION_TYPES,
} from "../../Common/mutations";
import { emptyPublicationResponse } from "../../Common/queries";
import * as tooltips from "../../Common/tooltips";
import { permissionResourceIdFromSchoolUnit } from "../../Common/utils";
import * as f from "../../Utils/functional";
import { assertUnreachable } from "../../Utils/typeHelpers";
import * as gqltypes from "../../gqltypes";
import {
  SimplePermission,
  UserPermissions,
  getComponentPermissionDisplayName,
} from "../../permissions";
import * as persistance from "../../persistance";
import { StoreState } from "../../store";
import { SYMBOLS, displayAnswer } from "../form";
import { links } from "../links";
import {
  allPredicateQuestions,
  allPredicateQuestionsAndComponents,
  predicateComponentVisible,
} from "./CreateForm";
import withRouter from "../../Utils/withRouter";

const shortenQuestion = (question: string, maxLen = 30) => {
  return question.length > maxLen
    ? question.substr(0, maxLen) + "..."
    : question;
};

const getExtraColumnLabel = (tr: Translation["tr"], id: Extras) => {
  switch (id) {
    case "date":
      return tr("responsesExtraDateLabel");
    case "language":
      return tr("responsesExtraLanguageLabel");
    case "signers":
      return tr("responsesExtraSignersLabel");
    case "status":
      return tr("responsesExtraStatusLabel");
    case "email":
      return tr("responsesSignersEmailLabel");
    default:
      assertUnreachable(id);
  }
};

type Extras = "signers" | "date" | "status" | "language" | "email";
interface ExtraColumnData {
  id: Extras;
  default?: boolean;
}

const extraColumns: ExtraColumnData[] = [
  { id: "signers" },
  { id: "date" },
  { id: "status" },
  { id: "language" },
  //  { id: "email" }
];

type Filter = "hideUnanswered";

interface FilterColumnData {
  id: Filter;
  default?: boolean;
}
const dataFilters: FilterColumnData[] = [{ id: "hideUnanswered" }];

interface State {
  selectedPublication?: string;
  columns: { [key: string]: boolean };
  fields: { [key: string]: boolean };
  isAllQuestionsChecked: boolean;
  filters: { [key in Filter]: boolean };
}

interface OutProps {
  customerId: string;
  studentGroupId: string;
  schoolUnitId: string;
  pathWithoutPublication: string;
}

interface StateProps {
  userPermissions: UserPermissions | null;
}

interface Props extends Translation, AdminContext, StateProps, OutProps {
  groupData: DataValue<gqltypes.studentGroupForms> &
    QueryResult<gqltypes.studentGroupFormsVariables>;
  location: Location;
  params: Params;
  navigate: NavigateFunction;
}

interface LocationState {
  columns?: State["columns"];
  fields?: State["fields"];
  filters?: State["filters"];
  isAllQuestionsChecked?: State["isAllQuestionsChecked"];
  sortOrder?: TableSortOrder[];
}

class StudentGroupOverviewInner extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const path = props.location.pathname;
    let publicationId =
      this.props.params.publicationId ||
      persistance.get(`${path}_selectedPublication`);
    if (props.groupData.studentGroup) {
      if (
        !props.groupData.studentGroup.publications.some(
          (pub) => pub.id === publicationId
        )
      ) {
        persistance.remove(`${path}_selectedPublication`);
        publicationId = props.groupData.studentGroup.publications.length
          ? props.groupData.studentGroup.publications[0].id
          : null;
      }
    }

    const columns = {} as State["columns"];
    const fields = extraColumns
      .filter((e) => e.default)
      .reduce((r, item) => {
        r[item.id] = true;
        return r;
      }, {} as State["fields"]);

    const locState: LocationState | null = this.props.location.state as any;
    const selectedColumnsState = locState ? locState.columns || {} : {};
    Object.entries(selectedColumnsState).forEach((entry) => {
      columns[entry[0]] = entry[1];
    });

    const filters = dataFilters
      .filter((e) => e.default)
      .reduce((r, item) => {
        r[item.id] = true;
        return r;
      }, {} as State["filters"]);

    const selectedFilterState = locState ? locState.filters || {} : {};
    Object.entries(selectedFilterState).forEach((entry) => {
      (filters as any)[entry[0]] = entry[1];
    });

    const isAllQuestionsChecked =
      locState && locState.isAllQuestionsChecked
        ? locState.isAllQuestionsChecked
        : Object.keys(columns).length > 0 &&
          Object.keys(columns).every((key) => {
            return columns[key];
          });

    this.state = {
      columns,
      fields,
      isAllQuestionsChecked,
      filters,
      selectedPublication: publicationId ? publicationId : undefined,
    };
  }

  private filterOutQuestions(components: gqltypes.studentGroupForms_studentGroup_publications_form_componentData_components[], predicateComponents: gqltypes.studentGroupForms_studentGroup_publications_form_componentData_predicateComponents[] | null): any[] {
    if (components) {
      const questions = f.flattenAll(
        components.map((c) =>
          c.questions.map((q) => {
            const predQuestions = allPredicateQuestionsAndComponents(
              predicateComponents || [],
              q
            ).map(({ question: q2, predicateComponent: pc }) => ({
              component: c,
              question: q2,
              pc,
            }));
            return [{ component: c, question: q }, predQuestions];
          })
        )
      );

      return questions;
    }

    return [];
  }

  private manuallyAddSelectedQuestions(form: gqltypes.studentGroupForms_studentGroup_publications_form) {
    if (form && this.state.isAllQuestionsChecked) {
      const components: gqltypes.studentGroupForms_studentGroup_publications_form_componentData_components[] = form.componentData.components;
      const predicateComponents: gqltypes.studentGroupForms_studentGroup_publications_form_componentData_predicateComponents[] | null = form.componentData.predicateComponents;
      const questions = this.filterOutQuestions(components, predicateComponents);

      const map : { [key: string]: boolean} = {};
      questions.forEach((q) => {
        map[q.question.id.toString()] = true;
      });
  
      this.setState({
        columns: map
      });
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const groupChanged = this.props.studentGroupId !== nextProps.studentGroupId;

    if (groupChanged) {
      this.mergeLocationState(this.state);
    }

    if (!groupChanged && nextProps.groupData.studentGroup) {
      if (
        !nextProps.groupData.studentGroup.publications.some(
          (pub) => pub.id === this.state.selectedPublication
        )
      ) {
        const form = nextProps.groupData.studentGroup.publications[0];
        this.manuallyAddSelectedQuestions(form?.form);
        this.setState({
          selectedPublication: form ? form.id : undefined,
        });

        const path = nextProps.location.pathname;
        persistance.remove(`${path}_selectedPublication`);
      }
    } else if (groupChanged || this.state.selectedPublication === undefined) {
      if (!nextProps.groupData.loading && nextProps.groupData.studentGroup) {
        const form = nextProps.groupData.studentGroup.publications[0];
        this.manuallyAddSelectedQuestions(form?.form);
        this.setState({
          selectedPublication: form ? form.id : undefined,
        });
      } else {
        this.setState({
          selectedPublication: undefined,
        });
      }
    }
  }

  public render() {
    const { tr, groupData } = this.props;
    const locState: LocationState | null = this.props.location.state as any;

    if (groupData.error) {
      return (
        <main className="top content">
          <h1>{tr("failedToFetchData")}</h1>
        </main>
      );
    }
    if (!groupData.studentGroup || !this.props.userPermissions) {
      return (
        <ISTContainer header={"Laddar..."}>
          <Loading className="p-content" fadeIn={500} />
        </ISTContainer>
      );
    }

    if (groupData.studentGroup.publications.length === 0) {
      return (
        <div className="alert alert-info">{tr("responsesNoPublications")}</div>
      );
    }

    const suResouceId = permissionResourceIdFromSchoolUnit(
      this.props.adminContext.org!,
      {
        id: this.props.schoolUnitId,
        customerId: this.props.customerId,
      }
    );

    const schoolUnitPermissions =
      this.props.userPermissions.su[suResouceId] || {};

    const publications = groupData.studentGroup.publications;
    const studentGroup = groupData.studentGroup;
    const forms = groupData.studentGroup.publications.map((p) => p.form);
    const selectedPublication = publications.find(
      (p) => p.id === this.state.selectedPublication
    );

    if (!this.state.selectedPublication || !selectedPublication) {
      return (
        <Loading
          className="p-content"
          message={tr("responsesLoadingSelectedPublication")}
        />
      );
    }

    const components = selectedPublication
      ? selectedPublication.form.componentData.components
      : [];

    const predicateComponents = selectedPublication
      ? selectedPublication.form.componentData.predicateComponents || []
      : [];

    const selectedQuestions = [] as gqltypes.FormQuestion[];
    const questions = this.filterOutQuestions(components, predicateComponents);

    const selectedComponentsMap: any = {};
    questions.forEach(({ component, question }) => {
      let c: any = selectedComponentsMap[component.id];
      if (!c) {
        selectedComponentsMap[component.id] = { ...component, questions: [] };
        c = selectedComponentsMap[component.id];
      }
      c.questions.push(question);
    });
    const selectedComponents: gqltypes.FormComponent[] = Object.values(
      selectedComponentsMap
    );

    const selectedPredicateComponents = questions
      .filter(({ pc }) => pc)
      .map(({ pc }) => pc);

    questions.forEach(({ question }) => f.push(selectedQuestions, question));

    if (
      questions.length !== 0 &&
      Object.keys(this.state.columns).length === 0
    ) {
      const selectedQuestions: { [key: string]: boolean } = questions.reduce(
        (selectedQuestions, item) => {
          selectedQuestions[item.question.id] = false;
          return selectedQuestions;
        },
        {} as { [key: string]: boolean }
      );

      this.setState({
        columns: selectedQuestions,
        isAllQuestionsChecked: false,
      });
    }

    const selectedExtras = extraColumns.filter(
      (col) => this.state.fields[col.id] === true
    );

    const options = publications.map((p) => ({
      value: p.id,
      label: `${p.name} - ${p.form.name}`,
    }));

    const filterTranslation: { [key in Filter]: string } = {
      hideUnanswered: tr("responsesFilterHideUnansweredLabel"),
    };


    return (
      <ISTContainer
        id="student-group-responses"
        header={studentGroup ? studentGroup.displayName : ""}
      >
        {forms.length > 0 ? (
          <form className="p-content">
            <FormSelect
              label={tr("publication")}
              value={this.state.selectedPublication}
              onChange={this.handleChangePublication}
              options={options}
            />
          </form>
        ) : (
          <p className="p-content">{tr("responsesNoPublications")}</p>
        )}

        {selectedPublication && (
          <div>
            <div className="p-content">
              <h4>{tr("responsesFilterHeader")}</h4>
              <div className="row mb-content">
                {dataFilters.map((e) => (
                  <div key={e.id} className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <Checkbox
                      id={e.id}
                      onChange={this.handleChangeFilters}
                      label={filterTranslation[e.id]}
                      checked={this.state.filters[e.id] === true}
                    />
                  </div>
                ))}
              </div>

              <hr />
              <h4>{tr("responsesShowFieldsHeader")}</h4>
              <div className="row mb-content">
                {extraColumns.map((e) => (
                  <div key={e.id} className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <Checkbox
                      id={e.id}
                      onChange={this.handleChangeFields}
                      label={getExtraColumnLabel(tr, e.id)}
                      checked={this.state.fields[e.id] === true}
                    />
                  </div>
                ))}
              </div>

              <hr />
              <h4>{tr("responsesQuestionsHeader")}</h4>
              {components.some((c) => c.questions.length > 0) ? (
                <div className="mb-content">
                  <Checkbox
                    id="isAllQuestionsChecked"
                    onChange={(id, checked) => this.setCheckAll(checked)}
                    checked={this.state.isAllQuestionsChecked}
                    label={this.props.tr("reportMarkAllAnswers")}
                  />
                </div>
              ) : null}
              {components.map((c) => {
                return (
                  <div key={c.id} className="mb-content">
                    <span>
                      <strong>{c.title} </strong>
                    </span>
                    <div className="row">
                      {c.questions.map((q) => {
                        const questionLabel = (
                          question: gqltypes.FormQuestion
                        ) =>
                          (question.shortName
                            ? `${question.shortName} - `
                            : "") + question.question;
                        return (
                          <React.Fragment key={q.id}>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                              <Checkbox
                                onChange={this.handleChangeColumns}
                                label={questionLabel(q)}
                                checked={this.state.isAllQuestionsChecked ? true : this.state.columns[q.id] === true}
                                id={q.id}
                              />
                            </div>
                            {allPredicateQuestions(predicateComponents, q).map(
                              (q2) => (
                                <div
                                  key={q2.id}
                                  className="col-12 col-sm-6 col-md-4 col-lg-3"
                                >
                                  <Checkbox
                                    onChange={this.handleChangeColumns}
                                    label={questionLabel(q2)}
                                    checked={this.state.isAllQuestionsChecked ? true : this.state.columns[q2.id] === true}
                                    id={q2.id}
                                  />
                                </div>
                              )
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <NewTable
              tr={tr}
              customerId={studentGroup.customerId}
              groupId={studentGroup.id}
              publicationId={selectedPublication.id}
              adminContext={this.props.adminContext}
              components={components}
              selectedComponents={selectedComponents}
              selectedPredicateComponents={selectedPredicateComponents}
              selectedQuestions={selectedQuestions}
              selectedQuestionsState={this.state.columns}
              selectedExtraColumns={selectedExtras}
              filters={this.state.filters}
              viewForm={this.viewForm}
              permissions={schoolUnitPermissions}
              sortOrderChanged={(order) => {
                this.mergeLocationState({ sortOrder: order });
              }}
              initialSortOrder={locState ? locState.sortOrder || [] : []}
            />
          </div>
        )}
      </ISTContainer>
    );
  }

  private mergeLocationState(state: Partial<LocationState>) {
    this.props.navigate(location.pathname, {
      state: {
        ...((this.props.location.state as any) || {}),
        ...state,
      } as LocationState,
      replace: true,
    });
  }

  private checkIfAllColumnsChecked = (): void => {
    if (
      Object.keys(this.state.columns).every((key) => {
        return this.state.columns[key];
      })
    ) {
      this.setState(
        {
          isAllQuestionsChecked: true,
        },
        () => {
          this.mergeLocationState({
            isAllQuestionsChecked: true,
          });
        }
      );
    } else {
      this.setState(
        {
          isAllQuestionsChecked: false,
        },
        () => {
          this.mergeLocationState({
            isAllQuestionsChecked: false,
          });
        }
      );
    }
  };

  private handleChangeColumns = (id: string, checked: boolean): void => {
    this.setState(
      (state) => {
        return { columns: { ...state.columns, [id]: checked } };
      },
      () => {
        this.mergeLocationState({
          columns: this.state.columns,
        });
        this.checkIfAllColumnsChecked();
      }
    );
  };

  private handleChangeFields = (id: string, checked: boolean): void => {
    this.setState(
      (state) => {
        return { fields: { ...state.fields, [id]: checked } };
      },
      () => {
        this.mergeLocationState({
          fields: this.state.fields,
        });
      }
    );
  };

  setCheckAll = (checked: boolean): void => {
    this.setState({ isAllQuestionsChecked: checked }, () => {
      this.mergeLocationState({
        isAllQuestionsChecked: checked,
      });
    });

    if (this.props.groupData.studentGroup) {
      Object.keys(this.state.columns).map((key) => {
        this.setState(
          (state) => {
            return { columns: { ...state.columns, [key]: checked } };
          },
          () => {
            this.mergeLocationState({
              columns: this.state.columns,
            });
          }
        );
      });
    }
  };

  private handleChangeFilters = (id: string, checked: boolean): void => {
    this.setState(
      (state) => {
        return { filters: { ...state.filters, [id]: checked } };
      },
      () => {
        this.mergeLocationState({
          filters: this.state.filters,
        });
      }
    );
  };

  private handleChangePublication = (
    event: React.FormEvent<HTMLSelectElement>
  ) => {
    const value = event.currentTarget.value;
    this.setState({
      selectedPublication: value,
      columns: {},
    });
    if (this.props.params.publicationId) {
      this.props.navigate(this.props.pathWithoutPublication + "/" + value, {
        replace: true,
      });
    } else {
      this.props.navigate(this.props.pathWithoutPublication + "/" + value);
    }
    const path = this.props.location.pathname;
    persistance.set(`${path}_selectedPublication`, value);
  };

  private viewForm = (id: string) => {
    this.props.navigate(links.admin.publication.response(id));
  };
}

const studentResponsesQuery = gql`
  query groupPublicationRecipients(
    $groupId: ID!
    $customerId: ID!
    $publicationId: ID!
    $context: Context!
  ) {
    studentGroup(id: $groupId, customerId: $customerId, context: $context) {
      publicationRecipients(publicationId: $publicationId) {
        user {
          id
          source
          customerId
          name
        }
        id
        modifyPermission
        responses(filter: LastValidOrElseLastPartiallySigned) {
          ...FullResponse
        }
      }
      id
    }
  }
  ${fullResponseFragment}
`;

interface NewTableProps {
  tr: Translation["tr"];
  publicationId: string;
  groupId: string;
  customerId: string;
  components: gqltypes.FormComponent[];
  selectedComponents: gqltypes.FormComponent[];
  selectedPredicateComponents: gqltypes.FormPredicateComponent[];
  selectedQuestions: gqltypes.FormQuestion[];
  selectedQuestionsState: { [key: string]: boolean };
  selectedExtraColumns: ExtraColumnData[];
  filters: { [key in Filter]: boolean };
  viewForm: (id: string) => void;
  initialSortOrder: TableSortOrder[];
  sortOrderChanged: (order: TableSortOrder[]) => void;
  permissions: SimplePermission;
}
const withStudentResponse = graphql<
  NewTableProps & AdminContext,
  gqltypes.groupPublicationRecipients,
  gqltypes.groupPublicationRecipientsVariables
>(studentResponsesQuery, {
  options: (props) => ({
    variables: {
      groupId: props.groupId,
      customerId: props.customerId,
      publicationId: props.publicationId,
      context: props.adminContext,
    },
    fetchPolicy: "cache-first",
  }),
});

const NewTable = withStudentResponse((props) => {
  const [createPublication, { loading: creatingResponse }] = useMutation<
    CREATE_RESPONSE_MUTATION_TYPES["data"],
    CREATE_RESPONSE_MUTATION_TYPES["variables"]
  >(CREATE_RESPONSE_MUTATION);
  const navigate = useNavigate();

  const { tr } = props;
  if (!props.data) {
    return <Loading />;
  }
  if (props.data.loading && !props.data.studentGroup) {
    return <Loading />;
  }
  if (props.data.error || !props.data.studentGroup) {
    return <GenericError title={tr("genericLoadError")} />;
  }

  const pushQuestion = (cols: TableHeader[], question: gqltypes.FormQuestion) =>
    f.push(cols, {
      key: question.id,
      element: (
        <span title={question.question}>
          {question.shortName
            ? question.shortName
            : shortenQuestion(question.question, 20)}
        </span>
      ),
    });

  const questionsThatIsChecked: gqltypes.FormQuestion[] = [];

  props.selectedQuestions.map((q: gqltypes.FormQuestion) => {
    if (props.selectedQuestionsState[q.id]) {
      questionsThatIsChecked.push(q);
    }
  });

  const questionCols: TableHeader[] = questionsThatIsChecked.reduce(
    pushQuestion,
    [] as TableHeader[]
  );

  const centerClassName = "text-center";

  const formPermissions = new Set(props.components.map((c) => c.permission));

  const missingPerms = [...formPermissions].filter(
    (p) => !props.permissions[p]
  );
  const missingPermsText = missingPerms
    .map((missing) => getComponentPermissionDisplayName(missing, tr))
    .join(", ");

  const rows = props.data.studentGroup.publicationRecipients.map<TableRow>(
    (recipient) => {
      const responseForm =
        recipient.responses.find((res) => res.status === "valid") ||
        recipient.responses.find((res) => res.status === "partially_signed");

      const response =
        responseForm &&
        responseForm.response &&
        responseForm.response.components;

      const questionColAnswers = questionCols.reduce((keys, col) => {
        const predicateComponent = props.selectedPredicateComponents.find(
          (com) => com.questions.some((q) => q.id === col.key)
        );

        const component =
          predicateComponent ||
          props.selectedComponents.find((com) =>
            com.questions.some((q) => q.id === col.key)
          );

        if (!component) {
          throw new Error(
            "Failed to find component with question that has id " + col.key
          );
        }

        const question =
          component && component.questions.find((q) => q.id === col.key);

        const componentResponse =
          response && response.find((com) => com.componentId === component!.id);

        const answer =
          componentResponse &&
          componentResponse.questions &&
          componentResponse.questions.find((q) => q.questionId === col.key);

        const redacted =
          (componentResponse && componentResponse.redacted) || false;

        const wasSeen = predicateComponent
          ? responseForm && responseForm.response
            ? predicateComponentVisible(
                props.components,
                responseForm.response,
                predicateComponent
              )
            : true
          : true;

        const content = redacted ? (
          <Tooltip
            target={"tooltip_" + responseForm!.id + col.key}
            content={`${SYMBOLS.REDACTED} ${tr("viewAnswerRedactedWarning")}`}
            message={tr(
              "viewAnswerRedactedWarningDescription",
              getComponentPermissionDisplayName(component.permission, tr)
            )}
          />
        ) : !wasSeen ? (
          tooltips.predicateComponentUnseenTooltip(tr, col.key)
        ) : answer ? (
          displayAnswer(
            tr,
            question as gqltypes.FormQuestionWithOptionsUnion,
            answer
          )
        ) : null;

        keys[col.key] = {
          content,
        };
        return keys;
      }, {} as { [id: string]: TableColumnData });

      const userName = recipient.user ? (
        recipient.user.name
      ) : (
        <i>{tr("failedToFetchUser")}</i>
      );

      const validAnswer = responseForm
        ? responseForm.status === "valid"
        : false;
      const partiallySigned = responseForm
        ? responseForm.status === "partially_signed"
        : false;
      const signRole = responseForm?.signRole;
      const rowClassName = responseForm
        ? validAnswer
          ? "table-success"
          : "table-info"
        : "";

      const hasAnswer = Boolean(responseForm);

      return {
        key: recipient.id,
        className: rowClassName,
        hidden: props.filters.hideUnanswered && !hasAnswer,
        columns: {
          name: {
            content: (
              <>
                {partiallySigned ? (
                  <Tooltip
                    target={
                      "tooltip_" + responseForm!.id + "partially_signed_warning"
                    }
                    content={SYMBOLS.PARTIALLY_SIGNED + " "}
                    message={tr("viewAnswerPartiallySignedDescription")}
                  />
                ) : null}
                {signRole === "admin" ? (
                  <Tooltip
                    target={"tooltip_" + responseForm!.id + "admin_signed"}
                    content={SYMBOLS.ADMIN_ANSWER + " "}
                    message={tr("viewAnswerAdminSignedDescription")}
                  />
                ) : null}
                <NameWithPotentialLink name={userName} user={recipient.user} />
              </>
            ),
            sortValue: userName,
            preventsClick: true,
          },

          date: {
            content: responseForm ? (
              <Time date={responseForm.modified} />
            ) : null,
            sortValue: responseForm ? responseForm.modified : "",
          },

          status: {
            content: responseForm ? (
              responseForm.status === "valid" ? (
                <i className="fa fa-check text-success" />
              ) : (
                <i className="fas fa-signature" />
              )
            ) : null,
            className: centerClassName,
            sortValue: responseForm ? responseForm.status : "",
          },

          signers: {
            sortValue:
              responseForm && responseForm.signatures
                ? responseForm.signatures.length
                : 0,
            content: responseForm
              ? responseForm.signatures
                ? responseForm.signatures.map((signature, i) => {
                    const user = signature.educloudUser
                      ? signature.educloudUser
                      : signature.signer;

                    return (
                      <div
                        className="mr-2"
                        key={user ? user.id : signature.educloudUserId || i}
                      >
                        <NameWithPotentialLink
                          name={
                            user ? user.name : <i>{tr("failedToFetchUser")}</i>
                          }
                          user={user}
                        />
                      </div>
                    );
                  })
                : ""
              : null,
          },

          language: {
            content: responseForm ? responseForm.language : null,
            className: centerClassName,
          },

          ...questionColAnswers,

          show_answer: {
            content: responseForm ? (
              <Link
                title={tr("viewAnswerShowForm")}
                aria-label={tr("viewAnswerAriaShowForm", userName)}
                to={links.admin.publication.response(responseForm.id)}
              >
                <i aria-hidden="true" className="fas fa-chevron-circle-right" />
              </Link>
            ) : recipient.modifyPermission ? (
              <Button
                label={tr("viewAnswerFillForm")}
                title={tr("viewAnswerAriaFillForm", userName)}
                level="link"
                className="p-0"
                size="btn-sm"
                disabled={creatingResponse}
                onClick={async () => {
                  const result = await createPublication({
                    variables: {
                      recipientId: recipient.id,
                      response: emptyPublicationResponse,
                    },
                  });
                  const responseId = result.data?.createPublicationResponse.id;

                  if (!responseId)
                    throw new Error("Failed to create publication response");

                  navigate(links.admin.publication.response(responseId));
                }}
              />
            ) : null,
          },
        },
      };
    }
  );

  const extraCols: TableHeader[] = [
    {
      key: "name",
      element: tr("viewAnswerColumnName"),
      usingSortValue: true,
    },
    {
      key: "date",
      element: getExtraColumnLabel(tr, "date"),
      hidden: !props.selectedExtraColumns.some((i) => i.id === "date"),
      usingSortValue: true,
    },
    {
      key: "signers",
      element: getExtraColumnLabel(tr, "signers"),
      hidden: !props.selectedExtraColumns.some((i) => i.id === "signers"),
      usingSortValue: true,
    },
    {
      key: "status",
      element: getExtraColumnLabel(tr, "status"),
      hidden: !props.selectedExtraColumns.some((i) => i.id === "status"),
      className: centerClassName,
      usingSortValue: true,
    },
    {
      key: "language",
      element: getExtraColumnLabel(tr, "language"),
      hidden: !props.selectedExtraColumns.some((i) => i.id === "language"),
      className: centerClassName,
    },
    {
      key: "email",
      element: getExtraColumnLabel(tr, "email"),
      hidden: !props.selectedExtraColumns.some((i) => i.id === "email"),
      usingSortValue: true,
    },
  ];

  const count =
    props.selectedComponents.reduce(
      (acc, com) => acc + com.questions.length,
      0
    ) + props.selectedExtraColumns.length;

  const headers = extraCols
    .concat(questionCols)
    .concat({ element: null, key: "show_answer", unsortable: true });

  return (
    <>
      {missingPerms.length && props.permissions.write_publication_response ? (
        <div className="alert alert-warning">
          {tr("createAnswerMissingReadPermissions", missingPermsText)}
        </div>
      ) : null}
      <Table
        initialOrder={
          props.initialSortOrder.length
            ? props.initialSortOrder.map((so) => so.col)
            : "name"
        }
        initialOrderDir={
          props.initialSortOrder.length
            ? props.initialSortOrder.map((so) => so.dir)
            : "asc"
        }
        topHeaders={props.selectedComponents.map((com) => {
          return {
            key: com.id,
            ref: com.questions.map((q) => q.id),
            element: (
              <div className="text-center mr-2 border-bottom" title={com.title}>
                {shortenQuestion(com.title, Math.max(35 - 3 * count, 20))}
              </div>
            ),
          };
        })}
        headers={headers}
        rows={rows}
        emptyListComponent={
          <div className="px-content alert alert-info">
            {tr("responsesNoData")}
          </div>
        }
        sortChanged={props.sortOrderChanged}
      />
    </>
  );
});

const withStudentGroupData = graphql<
  Props & AdminContext,
  gqltypes.studentGroupForms,
  gqltypes.studentGroupFormsVariables,
  { id: string }
>(
  gql`
    query studentGroupForms(
      $groupId: ID!
      $customerId: ID!
      $context: Context!
    ) {
      studentGroup(id: $groupId, customerId: $customerId, context: $context) {
        id
        customerId
        displayName
        publications(filter: valid) {
          id
          name
          sendDate
          form {
            id
            name
            componentData {
              ...FormComponentData
            }
          }
        }
      }
    }
    ${formComponentDataFragment}
  `,
  {
    name: "groupData",
    options: (props) => ({
      variables: {
        groupId: props.studentGroupId,
        customerId: props.customerId,
        context: props.adminContext,
      },
      fetchPolicy: "cache-first",
    }),
  }
);

export const StudentGroupResponses: React.ComponentClass<OutProps> =
  _.flowRight(
    withTranslation,
    withAdminContext,
    withRouter,
    withStudentGroupData
  )(
    connect<StateProps, any, any, StoreState>((state) => ({
      userPermissions: state.app.permissions,
    }))(StudentGroupOverviewInner)
  );
