import * as React from "react";

interface Props {
  id?: string;
  header: string;
  children?: React.ReactNode;
  className?: string;
  button?: ISTContainerInnerButton;
}

export interface ISTContainerInnerButton {
  buttonLabel: string;
  onClick: Function;
  active: boolean;
  processing?: ISTContainerInnerButtonProcessing;
}

export interface ISTContainerInnerButtonProcessing {
  processingState: boolean;
  processingLabel: string;
}

export const ISTContainerInner = (props: Props, ref: any) => {
  return (
    <div className={"box " + props.className || ""} id={props.id} ref={ref}>
      <div className="box-header justify-content-between">
        {props.header}{" "}
        {props.button?.buttonLabel && !props.button.active ? (
          <button
            onClick={
              props.button.onClick
                ? () => {
                    if (props.button?.onClick !== undefined) {
                      props.button.onClick();
                    }
                  }
                : undefined
            }
            className={`saveBtn`}
          >
            {props.button.processing?.processingState ? props.button.processing.processingLabel : props.button.buttonLabel}
          </button>
        ) : (
          ""
        )}
      </div>
      {props.children}
    </div>
  );
};

export const ISTContainer = React.forwardRef(ISTContainerInner);
