import React, { useState, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import _ from "lodash";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import {
  Translation,
  useAdminContext,
  useTranslation,
} from "../../App/reducer";
import { Button, ISTContainer, Loading } from "../../Common";
import { ISTContainerExpandable } from "../../Common/components/ISTContainerExpandable";
import { Tooltip } from "../../Common/components/Tooltip";
import { skolidIstOrgRegex } from "../../Common/utils";
import * as gqltypes from "../../gqltypes";
import {
  formComponentPermissions,
  getComponentPermissionDisplayName,
  getEmploymentRoleName,
  getPermissionDescription,
  getPermissionDisplayName,
  permissionOrder,
} from "../../permissions";
import { links } from "../links";
import FormSearchSelectRounded from "../../Common/components/FormSearchSelectRounded";
import SchoolUnitsPermissions from "./SchoolUnitsPermissions";
import { FormInputRounded } from "../../Common/components/FormInputRounded";

// GraphQL query to fetch organization permissions
export const ORG_PERMISSION_QUERY = gql`
  query OrganisationPermissions($context: Context!) {
    organisation(context: $context) {
      id
      displayName
      skolidOrgId
      permissions {
        resourceId
        displayName
        organisation {
          user {
            id
            source
            customerId
            name
            idp
            connectedUsers {
              id
              source
            }
            skolidOrganization {
              id
              name
              country
            }
          }
          client {
            id
            clientId
            name
          }
          roles {
            roleId
            sources
          }
        }
      }
    }
  }
`;

// GraphQL query to search for users
const SEARCH_QUERY = gql`
  query FindUserQuery($key: String!) {
    findUsers(key: $key) {
      id
      source
      customerId
      name
      idp
      skolidOrganization {
        id
        name
        country
      }
    }
  }
`;

export interface SchoolUnitPermissionConditional {
  employmentRoles?: gqltypes.EmploymentRole[];
  displayName?: string | null;
  userId?: string | null;
  user?: gqltypes.OrganisationPermissions_organisation_permissions_organisation_user | null;
  client: gqltypes.OrganisationPermissions_organisation_permissions_organisation_client | null;
  roles: gqltypes.OrganisationPermissions_organisation_permissions_organisation_roles[];
  __typename?: string;
}

// Styles for permission columns
export const permissionColStyle = {
  td: {
    borderLeft: "1px dashed var(--secondary)",
    borderBottom: "1px solid var(--secondary)",
    background: "var(--white)",
    verticalAlign: "middle",
  },
  tdh: {
    borderLeft: "1px dashed var(--secondary)",
    borderBottom: "1px solid var(--secondary)",
    background: "var(--white)",
    width: "350px",
    verticalAlign: "bottom",
  },
};

let lastTr: any = null;
let lastRes: { value: string; label: string }[] | null = null;

// Function to get all employment role options
const getAllEmploymentRoleOptions = (tr: Translation["tr"]) => {
  if (lastRes && lastTr === tr) return lastRes;

  lastTr = tr;
  lastRes = _.orderBy(
    Object.keys(gqltypes.EmploymentRole).map((role) => ({
      value: role,
      label: getEmploymentRoleName(tr, role as gqltypes.EmploymentRole),
    })),
    "label"
  );
  lastRes.push({
    value: "without_employment",
    label: tr("permissionsEmploymentTypeWithoutEmployment"),
  });

  return lastRes;
};

// Debounce function to delay search input
const debouncedSearch = _.debounce((func) => func(), 500);

// Component to render a link to user permission
export const LinkToUserPermission = (props: {
  tr: Translation["tr"];
  user: Pick<gqltypes.User, "id" | "name" | "source" | "idp"> & {
    customerId: string | null;
    skolidOrganization: { id: string; name: string } | null;
  };
  organisationSkolidOrgId: string | null;
  path: string;
  maxLength?: number;
}) => {
  const elId =
    "path_" + props.path.replace(/[\.:]/g, "") + "___" + props.user.id;
  const name =
    props.user.name || props.tr("permissionSearchSkolidUserMissingName");
  return (
    <Link to={links.admin.permissions.user(props.user)}>
      <span id={elId}>{name}</span>
      <UncontrolledTooltip target={elId}>{name}</UncontrolledTooltip>
    </Link>
  );
};

// Component to render a link to user permission with roles
export const LinkToUserPermissionWithRoles = (props: {
  tr: Translation["tr"];
  user: Pick<gqltypes.User, "id" | "name" | "source" | "idp"> & {
    customerId: string | null;
    skolidOrganization: { id: string; name: string } | null;
  };
  organisationSkolidOrgId: string | null;
  path: string;
  maxLength?: number;
  roles?: string;
}) => {
  const elId =
    "path_" + props.path.replace(/[\.:]/g, "") + "___" + props.user.id;
  return (
    <div
      className="d-flex justify-content-start align-items-center"
      style={{
        minWidth: "250px",
      }}
      id={elId}
    >
      <span
        className="text-truncate"
        style={{
          flex: "0 2 auto",
        }}
      >
        <Link to={links.admin.permissions.user(props.user)}>
          <span>{props.user.name}</span>
        </Link>
      </span>
      {props.roles ? (
        <span
          className="text-truncate ml-1"
          style={{
            color: "var(--gray-dark)",
            fontSize: "12px",
            flex: "1 0 20%",
          }}
        >
          {props.roles}
        </span>
      ) : null}
      <UncontrolledTooltip target={elId}>
        {props.user.name + " " + (props.roles ? props.roles : "")}
      </UncontrolledTooltip>
    </div>
  );
};

// Component to render user's Skolid organization
export const UserSkolidOrganisation = (props: {
  user: Pick<gqltypes.User, "id" | "name" | "source" | "idp"> & {
    customerId: string | null;
    skolidOrganization: { id: string; name: string } | null;
  };
  tr: Translation["tr"];
  skolidOrgId: string | null | undefined;
  warningIconSuffix?: boolean;
}) => {
  let isSkolidOrgAccount;
  let isSkolidIstAccount;
  if (props.user) {
    isSkolidOrgAccount = props.skolidOrgId
      ? (props.skolidOrgId || null) === props.user.skolidOrganization?.id
      : false;
    isSkolidIstAccount = props.user.skolidOrganization?.id
      ? skolidIstOrgRegex.test(props.user.skolidOrganization?.id)
      : false;
  }
  return (
    <>
      <span
        id={"tooltip_org_skolId" + props.user.id}
        data-toggle="tooltip"
        style={{ fontSize: "12px", maxWidth: "90px", minWidth: "90px" }}
        className="text-truncate"
      >
        {(isSkolidOrgAccount || isSkolidIstAccount) &&
        props.user?.skolidOrganization?.id ? (
          <>{props.user?.skolidOrganization?.id.toUpperCase()}</>
        ) : null}

        {!isSkolidOrgAccount &&
        !isSkolidIstAccount &&
        props.user?.skolidOrganization?.id &&
        !props.warningIconSuffix ? (
          <>
            <i
              className="fas fa-exclamation-triangle"
              style={{
                color: "var(--yellow)",
              }}
            />{" "}
            {props.user?.skolidOrganization?.id.toUpperCase()}
          </>
        ) : null}

        {!isSkolidOrgAccount &&
        !isSkolidIstAccount &&
        props.user?.skolidOrganization?.id &&
        props.warningIconSuffix ? (
          <span className="d-flex align-items-center">
            <span className="text-truncate" style={{ maxWidth: "200px" }}>
              {props.user?.skolidOrganization?.id.toUpperCase()}
            </span>
            <span className="d-flex">
              <i
                className="fas fa-exclamation-triangle ml-1"
                style={{
                  color: "var(--yellow)",
                }}
              />
            </span>
          </span>
        ) : null}
      </span>
      <UncontrolledTooltip target={"tooltip_org_skolId" + props.user.id}>
        {props.user?.skolidOrganization?.name
          ? props.user?.skolidOrganization?.name
          : ""}
        {!isSkolidOrgAccount &&
        !isSkolidIstAccount &&
        props.user?.skolidOrganization?.id
          ? " - " + props.tr("permissionToolTipOtherSkolidOrganisation")
          : null}
      </UncontrolledTooltip>
    </>
  );
};

// Component to render aggregated read view
export const AggregatedReadView = (props: {
  tr: Translation["tr"];
  roles: { roleId: string }[];
  borderStyle: string;
}) => {
  return (
    <td
      className="text-center align-middle"
      style={{
        borderBottom: `1px ${props.borderStyle} var(--secondary)`,
        borderLeft: "1px dashed var(--secondary)",
        background: "var(--white)",
        minWidth: "230px",
      }}
    >
      <div
        className="d-flex justify-content-center flex-wrap"
        style={{ gap: "6px" }}
      >
        {formComponentPermissions.map(
          (role: gqltypes.FormComponentPermission) => (
            <span
              key={role}
              className={`badge badge-${
                props.roles.some((r) => r.roleId === role)
                  ? "success"
                  : "light text-muted"
              }`}
              style={{ fontSize: "10px" }}
            >
              {getComponentPermissionDisplayName(role, props.tr)}
            </span>
          )
        )}
      </div>
    </td>
  );
};

// Component to render a link to client permission
export const LinkToClientPermission = (props: {
  client: Pick<gqltypes.Client, "clientId" | "name">;
  path: string;
}) => {
  const elId =
    "path_" +
    props.path.replace(/[\.:]/g, "") +
    "___" +
    props.client.clientId.replace(/[\.:]/g, "");
  return (
    <Link to={links.admin.permissions.client(props.client.clientId)}>
      <span id={elId}>{props.client.name}</span>
      <UncontrolledTooltip target={elId}>
        {props.client.name}
      </UncontrolledTooltip>
    </Link>
  );
};

// Function to sort list by connected users
export const sortListByConnectedUsers = (
  props: SchoolUnitPermissionConditional[]
) => {
  const sorted: SchoolUnitPermissionConditional[] = [];

  for (let i = 0; i < props.length; i++) {
    let user: gqltypes.OrganisationPermissions_organisation_permissions_organisation_user;

    if (props[i] && !sorted.includes(props[i])) {
      if (props[i].user) {
        user = props[i].user!;
        let potentionalUserMatch: gqltypes.OrganisationPermissions_organisation_permissions_organisation_user;
        sorted.push(props[i]);

        for (let j = 0; j < props.length; j++) {
          if (props[j].user?.connectedUsers?.length) {
            potentionalUserMatch = props[j].user!;
            if (
              potentionalUserMatch.connectedUsers &&
              potentionalUserMatch.connectedUsers?.length &&
              potentionalUserMatch.connectedUsers?.length > 0
            ) {
              for (
                let k = 0;
                k < potentionalUserMatch.connectedUsers?.length;
                k++
              ) {
                if (
                  potentionalUserMatch.connectedUsers[k].id === user.id &&
                  potentionalUserMatch.connectedUsers[k].source !== "educloud"
                ) {
                  //push the connected user into the array
                  sorted.push(props[j]);
                }
              }
            }
          }
        }
      } else {
        //push clients into list
        sorted.push(props[i]);
      }
    }
  }

  return { sorted };
};

const Permissions = () => {
  const [userSearchText, setUserSearchText] = useState("");
  const [debouncedUserSearchText, setDebouncedUserSearchText] = useState("");
  const [schoolUnitNameFilter, setSchoolUnitNameFilter] = useState("");
  const [userNameFilter, setUserNameFilter] = useState("");
  const [employmentRoleFilter, setEmploymentRoleFilter] = useState([]);

  const { tr } = useTranslation();
  const adminContext: gqltypes.Context = useAdminContext();

  // Query to fetch organization permissions
  const {
    loading: orgLoading,
    error: orgError,
    data: orgData,
  } = useQuery<
    gqltypes.OrganisationPermissions,
    gqltypes.OrganisationPermissionsVariables
  >(ORG_PERMISSION_QUERY, {
    variables: { context: adminContext },
    fetchPolicy: "cache-and-network",
  });
  // Query to search for users
  const {
    loading: searchLoading,
    error: searchError,
    data: searchData,
  } = useQuery<gqltypes.FindUserQuery, gqltypes.FindUserQueryVariables>(
    SEARCH_QUERY,
    {
      variables: { key: debouncedUserSearchText },
      skip: !debouncedUserSearchText,
    }
  );

  //to debounce user search input
  useEffect(() => {
    debouncedSearch(() => setDebouncedUserSearchText(userSearchText));
  }, [userSearchText]);

  // Memoized employment role options
  const employmentRoleOptions = useMemo(
    () => getAllEmploymentRoleOptions(tr),
    [tr]
  );

  // Memoized sorted organization by name
  const sortedOrganisationByName: gqltypes.OrganisationPermissions_organisation_permissions_organisation[] =
    useMemo(() => {
      if (!orgData?.organisation?.permissions?.organisation) {
        return [];
      }
      return _.orderBy(
        orgData.organisation.permissions.organisation,
        "user.name",
        "asc"
      );
    }, [orgData]);

  // Memoized sorted array by connected users
  const sortedArray: SchoolUnitPermissionConditional[] = useMemo(() => {
    if (!sortedOrganisationByName.length) {
      return [];
    }
    return sortListByConnectedUsers(sortedOrganisationByName).sorted;
  }, [sortedOrganisationByName]);

  const suNameFilter = new RegExp(_.escapeRegExp(schoolUnitNameFilter), "i");
  const personNameFilter = new RegExp(_.escapeRegExp(userNameFilter), "i");

  if (orgLoading) return <Loading />;

  if (orgError || !orgData?.organisation)
    return <h1>{tr("failedToFetchData")}</h1>;

  const variableOrgId = adminContext.org;

  if (variableOrgId !== orgData.organisation.id) return <Loading />;

  return (
    <main>
      <h1>{tr("permissionTitle")}</h1>
      <p className="col-12 col-md-9 p-0 pb-3">{tr("permissionDescription")}</p>
      <div className="box">
        <div className="box-header">{tr("permissionFindUserTitle")}</div>
        <div className="row p-content">
          <p className="col-12 col-md-9 pb-3">
            {tr("permissionFindUserDescription")}
          </p>
          <div className="col-md-6">
            <FormInputRounded
              id="searchField"
              label={tr("personSearchLookupLabel")}
              placeholder={tr("personSearchLookupPlaceholder")}
              onChange={(event) => setUserSearchText(event.currentTarget.value)}
              value={userSearchText}
            />
          </div>
          <div className="col-12">
            {debouncedUserSearchText && (
              <>
                {searchLoading && <Loading />}
                {searchError && (
                  <div className="alert alert-warning m-content">
                    {tr("failedToFetchData")}
                  </div>
                )}
                {searchData && (
                  <div className="search-results">
                    {searchData.findUsers.length === 0 ? (
                      <div className="alert alert-info">
                        {tr("noSearchHits")}
                        {/\s/.test(debouncedUserSearchText) && (
                          <div>{tr("noSearchHitsNameNotAllowed")}</div>
                        )}
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex d-sm-inline-flex flex-column">
                            {searchData.findUsers.map((user) => (
                              <div
                                key={user.id}
                                className="d-block align-items-center flex-wrap d-sm-flex flex-sm-row flex-column"
                              >
                                <div
                                  className="text-truncate"
                                  style={{ flex: "1.5 1 0" }}
                                >
                                  <span>
                                    <LinkToUserPermission
                                      tr={tr}
                                      user={user}
                                      path="search"
                                      organisationSkolidOrgId={
                                        orgData.organisation?.skolidOrgId ||
                                        null
                                      }
                                    />
                                  </span>
                                </div>
                                <div
                                  className="ml-1 d-flex align-items-center text-truncate"
                                  style={{
                                    flex: "1.4 1 0",
                                    minWidth: "200px",
                                    fontSize: "12px",
                                    color: "var(--gray)",
                                  }}
                                >
                                  <span className="mr-1">{`- ${tr(
                                    "permissionSearchSkolidAccountPrefix"
                                  )}: `}</span>
                                  <UserSkolidOrganisation
                                    skolidOrgId={
                                      orgData.organisation?.skolidOrgId
                                    }
                                    user={user}
                                    tr={tr}
                                    warningIconSuffix={true}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <ISTContainerExpandable
        header={orgData.organisation.displayName}
        description={tr("permissonPeopleWithSystemLevelRights")}
      >
        <div className="table-container">
          <table
            className={`table ${
              orgData.organisation.permissions.organisation.length >= 10
                ? "table-sticky-header"
                : ""
            } mb-1 mt-0 table-last-row`}
            style={{ minWidth: "1080px" }}
          >
            <thead>
              <tr>
                <th
                  className="no-upper"
                  scope="col"
                  style={{
                    borderBottom: "1px solid var(--secondary)",
                    width: "240px",
                    verticalAlign: "bottom",
                  }}
                >
                  {tr("permissionColumnName")}
                </th>
                <th
                  className="text-right no-upper"
                  scope="col"
                  style={{
                    borderBottom: "1px solid var(--secondary)",
                    verticalAlign: "bottom",
                  }}
                >
                  <span>{tr("permissionColumnSkolID")}</span>
                </th>
                {permissionOrder.map((perm) => (
                  <th
                    scope="col"
                    key={`org_${perm}`}
                    style={permissionColStyle.tdh}
                    className="text-center no-upper"
                  >
                    <Tooltip
                      target={`tooltip_org_${perm}`}
                      content={getPermissionDisplayName(perm, tr)}
                      message={getPermissionDescription(perm, tr)}
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {!orgData.organisation.permissions.organisation.length ? (
                <div className="px-content">{tr("tableNoRows")}</div>
              ) : (
                sortedArray.map((subjectPerm, i) => {
                  let checkIfSame = "solid";
                  subjectPerm.user?.connectedUsers?.forEach((e) => {
                    if (
                      sortedArray[i + 1] &&
                      sortedArray[i + 1].user?.id === e.id
                    )
                      checkIfSame = "dashed";
                  });

                  return subjectPerm.user ? (
                    <tr key={`org_${subjectPerm.user.id}`}>
                      <td
                        style={{
                          borderBottom: `1px ${checkIfSame} var(--secondary)`,
                          maxWidth: "270px",
                          fontSize: "14px",
                          verticalAlign: "middle",
                        }}
                      >
                        <span
                          className="d-block text-truncate"
                          style={{ minWidth: "250px" }}
                        >
                          <LinkToUserPermission
                            tr={tr}
                            user={subjectPerm.user}
                            path="org"
                            organisationSkolidOrgId={
                              orgData.organisation?.skolidOrgId || null
                            }
                          />
                        </span>
                      </td>
                      <td
                        style={{
                          borderBottom: `1px ${checkIfSame} var(--secondary)`,
                          color: "var(--gray)",
                          whiteSpace: "nowrap",
                          maxWidth: "130px",
                          verticalAlign: "middle",
                        }}
                        className="text-right"
                        key={`org_skolId${subjectPerm.user.id}`}
                      >
                        <div className="d-inline-flex justify-content-end">
                          <UserSkolidOrganisation
                            skolidOrgId={orgData.organisation?.skolidOrgId}
                            user={subjectPerm.user}
                            tr={tr}
                          />
                        </div>
                      </td>
                      {permissionOrder.map((perm) =>
                        perm === gqltypes.PermissionType.read ? (
                          <AggregatedReadView
                            key={`org_${subjectPerm.user!.id}_${perm}`}
                            tr={tr}
                            roles={subjectPerm.roles}
                            borderStyle={checkIfSame}
                          />
                        ) : (
                          <td
                            key={`org_${subjectPerm.user!.id}_${perm}`}
                            className="text-center"
                            style={{
                              borderBottom: `1px ${checkIfSame} var(--secondary)`,
                              borderLeft: "1px dashed var(--secondary)",
                              background: "var(--white)",
                              verticalAlign: "middle",
                            }}
                          >
                            {subjectPerm.roles.some(
                              (role) => role.roleId === perm
                            ) && <i className="fa fa-check text-dark" />}
                          </td>
                        )
                      )}
                    </tr>
                  ) : subjectPerm.client ? (
                    <tr key={subjectPerm.client.id}>
                      <td
                        style={{
                          borderBottom: `1px ${checkIfSame} var(--secondary)`,
                          fontSize: "14px",
                          verticalAlign: "middle",
                        }}
                      >
                        <LinkToClientPermission
                          client={subjectPerm.client}
                          path="org"
                        />
                      </td>
                      <td
                        style={{
                          borderBottom: `1px ${checkIfSame} var(--secondary)`,
                          color: "var(--gray)",
                          verticalAlign: "middle",
                        }}
                        className="text-right td-color"
                      >
                        <span
                          id={`tooltip_org_skolId${subjectPerm.client.id.replace(
                            /\./g,
                            "_"
                          )}`}
                          style={{ fontSize: "12px" }}
                        >
                          API
                          <UncontrolledTooltip
                            target={`tooltip_org_skolId${subjectPerm.client.id.replace(
                              /\./g,
                              "_"
                            )}`}
                          >
                            API
                          </UncontrolledTooltip>
                        </span>
                      </td>
                      {permissionOrder.map((perm) =>
                        perm === gqltypes.PermissionType.read ? (
                          <AggregatedReadView
                            key={`org_${subjectPerm.client!.id}_${perm}`}
                            tr={tr}
                            roles={subjectPerm.roles}
                            borderStyle={checkIfSame}
                          />
                        ) : (
                          <td
                            key={`org_${subjectPerm.client!.id}_${perm}`}
                            className="text-center"
                            style={permissionColStyle.td}
                          >
                            {subjectPerm.roles.some(
                              (role) => role.roleId === perm
                            ) && <i className="fa fa-check text-dark" />}
                          </td>
                        )
                      )}
                    </tr>
                  ) : null;
                })
              )}
            </tbody>
          </table>
        </div>
      </ISTContainerExpandable>

      <ISTContainer header={tr("schoolUnits")}>
        <div className="p-content row d-flex align-items-end">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h3>{tr("permissionFilter")}</h3>
              </div>
              <div className="col-6">
                <div className="form-group d-flex justify-content-end">
                  <Button
                    className="button-filter rounded-pill"
                    label={tr("clearFilter")}
                    level="secondary"
                    onClick={() => {
                      setSchoolUnitNameFilter("");
                      setUserNameFilter("");
                      setEmploymentRoleFilter([]);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <FormInputRounded
                  label={tr("permissionFilterOnSchoolUnitName")}
                  id="schoolUnitNameFilter"
                  type="search"
                  value={schoolUnitNameFilter}
                  placeholder={tr(
                    "permissionFilterOnSchoolUnitNamePlaceholder"
                  )}
                  onChange={(event) =>
                    setSchoolUnitNameFilter(event.currentTarget.value)
                  }
                />
              </div>
              <div className="col-md-4">
                <FormInputRounded
                  label={tr("permissionFilterOnPersonName")}
                  id="userNameFilter"
                  type="search"
                  value={userNameFilter}
                  placeholder={tr("permissionFilterOnPersonNamePlaceholder")}
                  onChange={(event) =>
                    setUserNameFilter(event.currentTarget.value)
                  }
                />
              </div>
              <div className="col-md-4">
                <FormSearchSelectRounded
                  className="form-group"
                  label={tr("permissionEmploymentRoleFilterPlaceholder")}
                  placeholder={tr("permissionEmploymentRoleFilterPlaceholder")}
                  isMulti
                  isSearchable={false}
                  closeMenuOnSelect={false}
                  value={employmentRoleFilter}
                  onChange={(selected: never[]) =>
                    setEmploymentRoleFilter(selected)
                  }
                  options={employmentRoleOptions}
                />
              </div>
            </div>
          </div>
        </div>
        <SchoolUnitsPermissions
          suNameFilter={suNameFilter}
          personNameFilter={personNameFilter}
          employmentRoleFilter={employmentRoleFilter}
        />
      </ISTContainer>
    </main>
  );
};

export const PermissionsContainer = Permissions;
