import gql from "graphql-tag";
import _ from "lodash";
import moment from "moment";
import { apply, call, put, select, takeEvery } from "redux-saga/effects";
import { Action } from "redux-ts-simple";
import { fullSubjectPermissionsFragment } from "../Admin/components/UserPermissions";
import { skolidIstOrgRegex } from "../Common/utils";
import { client as apolloClient } from "../api";
import * as gqltypes from "../gqltypes";
import { SimplePermission, UserPermissions } from "../permissions";
import * as persistance from "../persistance";
import * as sentry from "../sentry";
import { StoreState, runSaga } from "../store";
import { getFirstAvailableUiLanguage, getTr } from "../translation";
import { OIDCUser } from "../types";
import * as actions from "./actions";
import { AvailableOrg, Context } from "./reducer";
import {
  getSingoutRedirectUrl,
  impersonate,
  signinRedirect,
  signoutRedirect,
  stopImpersonation,
  userManager,
} from "./userManager";

const getLanguageToUse = (preferred?: string | null) => {
  const preferredLanguages = navigator.languages
    ? [
        ...new Set(
          navigator.languages.map((l) => l.replace("_", "-").split("-")[0])
        ),
      ]
    : [];
  const lastLanguage = persistance.get(`lastLanguage`);
  return getFirstAvailableUiLanguage(
    [preferred || "", lastLanguage || ""].concat(preferredLanguages)
  );
};

function* initialize() {
  yield put(actions.changeUiLanguage(getLanguageToUse()));

  const user: OIDCUser | null = yield apply(
    userManager,
    userManager.getUser,
    []
  );
  if (user && !user.expired) {
    yield put(actions.userChanged(user));
  } else if (user && user.expired) {
    yield call(() => userManager.removeUser());
  } else if (!user) {
    const paramIdp = new URLSearchParams(window.location.search).get("idp");

    // Some users might still have "lastIdp" set, take this in concideration if lastIdp is needed again
    // const lastIdp = persistance.get("lastIdp");
    if (paramIdp) {
      yield put(actions.changeIdp(paramIdp));
    }
    // else if (lastIdp) {
    //   yield put(actions.changeIdp(lastIdp));
    // }

    const autoLogin = [...new URLSearchParams(window.location.search).keys()]
      .map((key) => key.toLowerCase())
      .includes("autologin");

    if (autoLogin) {
      yield login();
      return;
    }
  }

  yield put(actions.initialized());
}

function* login() {
  yield call(signinRedirect);
}

function* logout(action: Action<{ reason: "session_timeout" } | undefined>) {
  const reason = action?.payload?.reason;

  // In the case the signoutRedirect fails, seems to happen sometimes?
  setTimeout(() => {
    sentry.captureMessage("Using fallback logout redirect.", {
      extra: { reason },
      level: sentry.SeverityLevel.warning,
    });

    window.location.href = getSingoutRedirectUrl(reason);
  }, 10 * 1000);

  apolloClient.stop();
  yield apolloClient.cache.reset();

  // NOTE may want to add support for global logout
  yield call(signoutRedirect, reason);

  // We now redirect, no need to handle store as it makes site flicker
  // yield put(actions.userChanged(null));
}

function* userChanged() {
  const store: {
    requestedAdminView: boolean;
    user: OIDCUser | null;
  } = yield select((state: StoreState) => ({
    user: state.app.user,
    requestedAdminView: state.app.requestedAdminView,
  }));
  if (store.requestedAdminView) {
    yield loadAvailableOrganisations();
  }

  yield loadUserInfo();

  if (store.user) {
    const context: Context = yield select((state: StoreState) => ({
      org: state.app.context.org,
      language: state.app.context.language,
    }));
    sentry.setUser({
      id: store.user.profile.sub,
      email: store.user.profile.email,
    });
  }
}

function* loadAvailableOrganisations() {
  const store: {
    currentOrg: string | null;
    uiLanguage: string;
  } = yield select((state: StoreState) => ({
    currentOrg: state.app.context.org,
    uiLanguage: state.app.uiLanguage,
  }));
  try {
    const availableOrganisations =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      yield apolloClient.query<gqltypes.AvailableOrgs>({
        fetchPolicy: "network-only",
        query: gql`
          query AvailableOrgs {
            me {
              id
              source
              idpSub
              name
              skolidOrganization {
                id
                name
                country
              }
              availableOrganisations {
                id
                displayName
                defaultLanguage
              }
              connectedUsers(filter: skolid) {
                id
                name
                skolidOrganization {
                  id
                  name
                  country
                }
                availableOrganisations {
                  id
                  displayName
                  defaultLanguage
                  features {
                    relocation
                  }
                }
              }
            }
          }
        `,
      });
    const res: gqltypes.AvailableOrgs = availableOrganisations.data;
    const availableOrgs: AvailableOrg[] = [];

    res.me.connectedUsers?.forEach((cu) => {
      // if (res.me.source === "db" && cu.id === res.me.idpSub) {
      cu.availableOrganisations.forEach((ao) => {
        availableOrgs.push({
          ...ao,
          account: {
            sub: cu.id,
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            org: cu.skolidOrganization?.id!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            orgName: cu.skolidOrganization?.name!,
          },
        });
      });
    });

    yield put(
      actions.availableOrgsChanged(
        _.orderBy(availableOrgs, "displayName", "asc")
      )
    );
    if (availableOrgs.length && !store.currentOrg) {
      const lastOrg = persistance.get("lastOrg");
      const u: OIDCUser | null = yield userManager.getUser();
      const subId = u?.profile.sub;

      const lastOrgAccountJson = subId
        ? persistance.get(`lastOrgAccount_${subId}`)
        : null;
      const lastOrgAccount: {
        org: string;
        sub: string;
      } | null = lastOrgAccountJson ? JSON.parse(lastOrgAccountJson) : null;

      if (
        lastOrgAccount &&
        availableOrgs.find(
          (org) => lastOrg === org.id && lastOrgAccount.sub === org.account?.sub
        )
      ) {
        yield put(
          actions.orgWillChange({ id: lastOrg!, account: lastOrgAccount })
        );
      } else if (
        u?.profile.sub &&
        availableOrgs.find(
          (org) => lastOrg === org.id && u?.profile.sub === org.account?.sub
        )
      ) {
        yield put(
          actions.orgWillChange({
            id: lastOrg!,
            account: { org: lastOrg!, sub: u?.profile.sub },
          })
        );
      } else if (availableOrgs.length) {
        const sortedAvailableOrgs = availableOrgs.sort((a, b) =>
          a.id > b.id ? 1 : -1
        );
        // If user login with IST skolid and customer has their own organisation in skolid,
        // then we let them use their own skolid org account
        if (u?.profile.org && skolidIstOrgRegex.test(u?.profile.org)) {
          const firstNonIstSkolidOrgAccess = sortedAvailableOrgs.find(
            (organisation) =>
              organisation.account?.org &&
              !skolidIstOrgRegex.test(organisation.account?.org)
          );
          if (firstNonIstSkolidOrgAccess) {
            yield put(
              actions.orgWillChange({
                id: firstNonIstSkolidOrgAccess.id,
                account: firstNonIstSkolidOrgAccess.account,
              })
            );
          } else {
            yield put(
              actions.orgWillChange({
                id: sortedAvailableOrgs[0].id,
                account: sortedAvailableOrgs[0].account,
              })
            );
          }
        } else {
          // If you login with customers own skolid organisation,
          // then they are not allowed to act as a IST skolid organisation user.
          const firstNonIstOrgAccess = sortedAvailableOrgs.find(
            (organisation) =>
              organisation.account?.org &&
              !skolidIstOrgRegex.test(organisation.account?.org) &&
              organisation.account?.org === u?.profile.org
          );
          if (firstNonIstOrgAccess) {
            yield put(
              actions.orgWillChange({
                id: firstNonIstOrgAccess.id,
                account: firstNonIstOrgAccess.account,
              })
            );
          } else {
            yield put(actions.orgSelected(false));
          }
        }
      }
    }
  } catch (e) {
    console.error(e);
    const tr = getTr(store.uiLanguage);
    yield put(
      actions.errorSplash({
        title: tr("sagasFailedToFetchAvailableOrgsTitle"),
        message: tr("sagasFailedToFetchAvailableOrgsMessage"),
      })
    );
  }
}

const addPermissionHelper = (
  local: SimplePermission,
  permissions: UserPermissions,
  roleId: gqltypes.PermissionType,
  suid?: string
) => {
  if (!local) {
    if (suid) {
      permissions.su[suid] = {};
      local = permissions.su[suid];
    } else {
      local = {};
    }
  }
  local[roleId] = true;
  permissions.some[roleId] = true;
};

function* loadPermissions() {
  const adminContext: Context = yield select((state: StoreState) => ({
    org: state.app.context.org,
    language: state.app.context.language,
  }));
  // console.log("loadPermissions adminContext", adminContext);
  if (!adminContext.org) {
    return;
  }
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const me = yield apolloClient.query<gqltypes.Me>({
      query: gql`
        query Me($context: Context!) {
          me {
            id
            name
            permissions(context: $context) {
              ...FullSubjectPermissions
            }
          }
        }
        ${fullSubjectPermissionsFragment}
      `,
      variables: { context: adminContext },
    });

    const res: gqltypes.Me = me.data;
    const permissions: UserPermissions = {
      org: {},
      su: {},
      some: {},
    };

    res.me.permissions.organisation.roles.forEach((role) => {
      addPermissionHelper(permissions.org, permissions, role.roleId);
    });
    res.me.permissions.schoolUnits.forEach((su) => {
      su.roles.forEach((role) => {
        addPermissionHelper(
          permissions.su[su.resourceId],
          permissions,
          role.roleId,
          su.resourceId
        );
      });
    });

    // console.log("calculated permissions", permissions);
    yield put(actions.permissionsChanged(permissions));
  } catch (e) {
    const tr = getTr(adminContext.language);
    yield put(
      actions.errorSplash({
        title: tr("sagasFailedToFetchPermissionsForOrgTitle"),
        message: tr("sagasFailedToFetchPermissionsForOrgMessage"),
      })
    );
  }
}

function* loadUserInfo() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const me = yield apolloClient.query<gqltypes.MyEducloudUserIds>({
    query: gql`
      query MyEducloudUserIds {
        me {
          id
          educloudUserIds {
            id
            customerId
          }
          language
        }
      }
    `,
  });
  const res: gqltypes.MyEducloudUserIds = me.data;

  yield put(actions.userInfoChanged(res.me));
  if (res.me.language) {
    yield put(actions.changeUiLanguage(getLanguageToUse(res.me.language)));
  }
}

// function* idpChanged() {
//   const store: {
//     idp: string;
//   } = yield select<StoreState>(state => ({
//     idp: state.app.idp
//   }));
//   persistance.set("lastIdp", store.idp);
// }

function* orgChanged() {
  const store: {
    context: Context;
    availableOrgs: AvailableOrg[];
  } = yield select((state: StoreState) => ({
    context: state.app.context,
    availableOrgs: state.app.availableOrgs,
  }));
  const org = store.availableOrgs.find((aorg) => aorg.id === store.context.org);
  sentry.setOrganisation({
    id: org ? org.id : null,
    name: org ? org.displayName : null,
  });
  if (org) {
    persistance.set("lastOrg", org.id);
  }
}

function* orgWillChange(
  action: Action<{ id: string; account?: { org: string; sub: string } }>
) {
  const newOrg = action.payload;

  const store: {
    availableOrgs: AvailableOrg[];
    uiLanguage: string;
    org: string | null;
  } = yield select((state: StoreState) => ({
    availableOrgs: state.app.availableOrgs,
    uiLanguage: state.app.uiLanguage,
    org: state.app.context.org,
  }));
  const org =
    store.availableOrgs.find(
      (aorg) =>
        aorg.id === newOrg.id && aorg.account?.sub === newOrg.account?.sub
    ) || store.availableOrgs.find((aorg) => aorg.id === newOrg.id);

  const u: OIDCUser | null = yield userManager.getUser();
  if (org?.account) {
    const skolidLoginOrg: string | undefined = u?.profile?.org;

    if (skolidLoginOrg === org.account.org) {
      stopImpersonation();
      yield apolloClient.cache.reset();
      yield apolloClient.reFetchObservableQueries();
      yield userChanged();
    } else if (skolidLoginOrg && skolidIstOrgRegex.test(skolidLoginOrg)) {
      yield impersonate({ subjectId: org.account.sub });
      yield apolloClient.cache.reset();
      yield apolloClient.reFetchObservableQueries();
      yield userChanged();
    } else {
      const tr = getTr(store.uiLanguage);
      const result = window.confirm(
        tr("sagasLoginWithIstAccount", window.location.host)
      );
      if (result) {
        yield call(signinRedirect, "login");
      }
      return;
    }
  }

  const subId = u?.profile.sub;

  if (newOrg.account) {
    persistance.set(`lastOrgAccount_${subId}`, JSON.stringify(newOrg.account));
  } else {
    persistance.remove(`lastOrgAccount_${subId}`);
  }
  yield put(actions.orgChanged(newOrg));
}

function* languageChanged() {
  const store: {
    uiLanguage: string;
  } = yield select((state: StoreState) => ({
    uiLanguage: state.app.uiLanguage,
  }));

  document.documentElement.setAttribute("lang", store.uiLanguage);

  persistance.set(`lastLanguage`, store.uiLanguage);
  moment.locale(store.uiLanguage);
  sentry.setLanguage(store.uiLanguage);
}

function* appSaga() {
  yield takeEvery(actions.applicationLaunched.type, initialize);
  yield takeEvery(actions.loginRequested.type, login);
  yield takeEvery(actions.logoutRequested.type, logout);
  yield takeEvery(actions.userChanged.type, userChanged);
  yield takeEvery(actions.requestedAdminView.type, loadAvailableOrganisations);
  yield takeEvery(actions.orgChanged.type, loadPermissions);
  yield takeEvery(actions.orgChanged.type, orgChanged);
  yield takeEvery(actions.orgWillChange.type, orgWillChange);
  // yield takeEvery(actions.changeIdp.type, idpChanged);
  yield takeEvery(actions.changeUiLanguage.type, languageChanged);
}

runSaga(appSaga);
