import gql from "graphql-tag";
import React from "react";
import { Query } from "@apollo/client/react/components";
import {
  Translation,
  UserProfile,
  withTranslation,
  withUserProfile,
} from "../../App/reducer";
import { client } from "../../api";
import * as gqltypes from "../../gqltypes";
import { Button, Checkbox, FormInput, ISTContainer, Loading } from "../index";
import { GenericError } from "./GenericError";
import { Location } from "react-router";
import withRouter from "../../Utils/withRouter";

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9])+(\.[a-zA-Z]{2,})*))$/;

const settingsQuery = gql`
  query myAdminSettings {
    me {
      id
      settings {
        id
        workEmail
        personalEmail
        updatedConsentNotification
      }
      emails {
        value
        type
        preferredHome
        preferredWork
      }
    }
  }
`;

const updateSettingsQuery = gql`
  mutation updateUserSettings($input: UserSettingsInput!) {
    updateUserSettings(input: $input) {
      id
      workEmail
      personalEmail
      updatedConsentNotification
    }
  }
`;

interface SettingsProps extends Translation, UserProfile {
  currentWorkEmail: string | null;
  currentHomeEmail: string | null;
  settings: gqltypes.myAdminSettings["me"]["settings"];
  location: Location;
}

interface SettingsState {
  workEmail: string | null;
  personalEmail: string | null;
  updatedConsentNotification: boolean;
}

class Settings extends React.PureComponent<SettingsProps, SettingsState> {
  constructor(props: SettingsProps) {
    super(props);
    this.state = {
      workEmail: props.settings.workEmail,
      personalEmail: props.settings.personalEmail,
      updatedConsentNotification: props.settings.updatedConsentNotification,
    };
  }

  public componentDidUpdate(oldProps: SettingsProps) {
    if (oldProps !== this.props) {
      this.updateState(this.props);
    }
  }

  public render() {
    const { tr, user, settings, currentHomeEmail, currentWorkEmail } =
      this.props;
    const { updatedConsentNotification, workEmail, personalEmail } = this.state;

    const dirty =
      updatedConsentNotification !== settings.updatedConsentNotification ||
      settings.workEmail !== workEmail ||
      settings.personalEmail !== personalEmail;

    const validPersonalMail = personalEmail
      ? emailRegex.test(personalEmail)
      : true;
    const validWorkMail = workEmail ? emailRegex.test(workEmail) : true;

    const asAdmin = this.props.location.pathname.startsWith("/admin");

    return (
      <div className="p-content">
        <div className="col-md-9">
          <FormInput
            id="personalEmail"
            value={personalEmail || ""}
            onChange={(a) => {
              this.setState({ personalEmail: a.target.value });
            }}
            label={tr("myAdminProfilePersonalEmailLabel")}
            placeholder={currentHomeEmail || ""}
            feedback={
              !validPersonalMail ? tr("myAdminProfileInvalidEmail") : undefined
            }
            subText={tr("myAdminProfilePersonalEmailDescription")}
          />
        </div>
        {asAdmin && (
          <React.Fragment>
            <div className="col-md-9">
              <FormInput
                id="emailWork"
                value={workEmail || ""}
                onChange={(a) => {
                  this.setState({ workEmail: a.target.value });
                }}
                label={tr("myAdminProfileWorkEmailLabel")}
                placeholder={currentWorkEmail || ""}
                feedback={
                  !validWorkMail ? tr("myAdminProfileInvalidEmail") : undefined
                }
                subText={tr("myAdminProfileWorkEmailDescription")}
              />
            </div>
            <div className="col-md-9">
              <Checkbox
                id="updatedConsentNotification"
                checked={updatedConsentNotification}
                onChange={(id, checked) => {
                  this.setState({ updatedConsentNotification: checked });
                }}
                label={tr("myAdminProfileUpdatedConsentNotificationLabel")}
              />
            </div>
          </React.Fragment>
        )}
        <div className="col-12 mt-3">
          <Button
            label={tr("save")}
            onClick={this.saveSettings}
            disabled={!dirty || !validWorkMail || !validPersonalMail}
          />
        </div>
      </div>
    );
  }

  private updateState = (props: SettingsProps) => {
    this.setState({
      personalEmail: props.settings.personalEmail,
      workEmail: props.settings.workEmail,
      updatedConsentNotification: props.settings.updatedConsentNotification,
    });
  };

  private saveSettings = () => {
    client.mutate<
      gqltypes.updateUserSettings,
      gqltypes.updateUserSettingsVariables
    >({
      mutation: updateSettingsQuery,
      variables: {
        input: {
          updatedConsentNotification: this.state.updatedConsentNotification,
          workEmail: this.state.workEmail,
          personalEmail: this.state.personalEmail,
        },
      },
    });
  };
}

interface Props extends Translation, UserProfile {
  location: Location;
}

class UserProfileInner extends React.PureComponent<Props> {
  public render() {
    const { tr, user } = this.props;
    return (
      <div>
        <h1>{tr("myAdminProfileTitle")}</h1>
        <p className="col-12 col-md-9 p-0 pb-3">
          {tr("myAdminProfileDescription")}
        </p>
        <ISTContainer header={tr("myAdminProfileHeader")}>
          <Query<gqltypes.myAdminSettings> query={settingsQuery}>
            {({ loading, error, data }) => {
              if (loading || !data) {
                return <Loading />;
              }

              const homeEmail =
                data.me.emails &&
                data.me.emails.find((email) => email.preferredHome);
              const workEmail =
                data.me.emails &&
                data.me.emails.find((email) => email.preferredWork);

              if (error) {
                return <GenericError title={tr("genericLoadError")} />;
              }

              return (
                <Settings
                  {...this.props}
                  settings={data.me.settings}
                  currentHomeEmail={homeEmail ? homeEmail.value : null}
                  currentWorkEmail={workEmail ? workEmail.value : null}
                />
              );
            }}
          </Query>
        </ISTContainer>
      </div>
    );
  }
}

export const UserProfileContainer = withTranslation(
  withUserProfile(withRouter(UserProfileInner))
);
