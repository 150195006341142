import React, { useCallback } from "react";
import { HelpTooltip } from "../../Common/components/HelpTooltip";

interface Props {
  checked: boolean;
  containerClassName?: string;
  disabled?: boolean;
  id: string;
  indeterminate?: boolean;
  label?: React.ReactNode;
  onChange?: (id: string, checked: boolean) => void;
  readOnly?: boolean;
  required?: boolean;
  tabIndex?: number;
  style?: any;
}

const CheckboxInner = (props: Props) => {
  const {
    label,
    indeterminate = false,
    containerClassName,
    style,
    ...attributes
  } = props;
  const { onChange } = props;

  const handleChange = useCallback(() => {
    if (onChange) { 
      onChange(props.id, !props.checked);
    }
  }, [onChange, props.id, props.checked]);
  return (
    <div className={`custom-control custom-checkbox ${containerClassName || ""}`}
         style={style}
    >
      <input
        {...attributes}
        type="checkbox"
        className="custom-control-input"
        ref={(ref) => {
          if (ref) {
            ref.indeterminate = indeterminate;
          }
        }}
        onChange={!attributes.readOnly ? handleChange : undefined}
      />
      <label
        className={`custom-control-label ${label ? "" : "no-label"}`}
        htmlFor={attributes.id}
      >
        {label}
      </label>
    </div>
  );
};

export const Checkbox = React.memo(CheckboxInner);
