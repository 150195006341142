import {
  ApolloError,
  ApolloQueryResult,
  FetchResult,
  MutationFunction,
  QueryResult,
} from "@apollo/client";
import { graphql, DataValue } from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as _ from "lodash";
import * as React from "react";
import FlipMove from "react-flip-move";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {
  AdminContext,
  CurrentOrg,
  Translation,
  UserPermission,
  withAdminContext,
  withCurrentOrg,
  withTranslation,
  withUserPermissions,
} from "../../App/reducer";
import {
  Button,
  Checkbox,
  FormComponentEditor,
  FormComponentView,
  FormHeader,
  FormSelect,
  Loading,
} from "../../Common";
import { SaveManager } from "../../Common/SaveManager";
import { ApplicationCustomComponent } from "../../Common/components/ApplicationCustomComponent";
import { FormAccess } from "../../Common/components/FormAccess";

import { PermissionsTooltip } from "../../Common/components/PermissionsTooltip";
import {
  formComponentDataFragment,
  formMetadataFragment,
  fullFormAccessFragment,
  fullFormQuestionFragment,
  fullFormTranslationFragment,
} from "../../Common/fragments";
import { withRecordFormHistory } from "../../Common/mutations";
import {
  componentIsValid,
  predicateComponentIsValid,
  richTextIsValid,
  translationIsValid,
} from "../../Common/validation";
import { OUT_MIGRATION_TEMPLATE_ID } from "../../Utils/constants";
import {
  showAlertDialog,
  showConfirmDialog,
  showErrorDialog,
} from "../../Utils/dialogs/showDialog";
import {
  deepOmitArray,
  definedNotNull,
  move,
  nonEmptyString,
  omit,
  replaceInArray,
} from "../../Utils/functional";
import * as f from "../../Utils/functional";
import { resolvePred } from "../../Utils/predicates";
import { assertUnreachable } from "../../Utils/typeHelpers";
import { client } from "../../api";
import * as gqltypes from "../../gqltypes";
import { getComponentPermissionDisplayName } from "../../permissions";
import { getSettings } from "../../settings";
import { languageNames } from "../../translation";
import {
  defaultDialogCancel,
  defaultDialogProceed,
} from "../../translation/strings";
import {
  FormQuestionDataTyped,
  SavingState,
  WithRouteProps,
} from "../../types";
import {
  applyTranslationOnComponent,
  availableTranslationLanguageEnum,
  getComponentQuestions,
  getTemplateComponentInLanguage,
  isTemplateComponent,
  makeComponentFromTemplate,
  makeComponentFromTemplateTranslation,
  makeEmptyComponent,
  makeEmptyFeebackComponent,
  makeEmptyPredicateComponent,
} from "../form";
import { links } from "../links";
import { sendableFormsQuery } from "./CreatePublication";
import { FormDoesNotExist } from "./FormDoesNotExist";
import { InnerFormView } from "./FormViewer";
import { FormType, IdCustomerIdInput } from "../../gqltypes";
import { GenericError } from "../../Common/components/GenericError";
import { NavigateFunction } from "react-router";
import withRouter from "../../Utils/withRouter";
import { Prompt } from "../../Common/components/RouterPrompt";

const settings = getSettings();

function isReadOnly(componentData: gqltypes.FullFormComponent) {
  return isTemplateComponent(componentData);
}

export const getFormTypeName = (
  tr: Translation["tr"],
  formType: gqltypes.FormType | gqltypes.FormSubType
) => {
  switch (formType) {
    case gqltypes.FormType.publication:
      return tr("formTypePublication");
    case gqltypes.FormType.application:
      return tr("formTypeApplication");
    case gqltypes.FormSubType.general:
      return tr("formSubTypeGeneral");
    case gqltypes.FormSubType.outMigration:
      return tr("formSubTypeOutMigration");

    default:
      assertUnreachable(formType);
      throw new Error("");
  }
};

const cleanPCs = (
  components: ComponentsState,
  predicateComponents: NonNullable<PredicateComponentsState>
): NonNullable<PredicateComponentsState> =>
  predicateComponents
    .map((pc) => {
      const cs = parentComponents(components, pc.id);
      const c = cs[0];
      if (cs.length === 1) {
        // All good!
      } else if (cs.length === 0) {
        console.error(
          "No parent component found, removing predicate component."
        );
        return null;
      } else {
        console.error(
          "More than one parent component found, using first found."
        );
      }

      // Create a copy of `pc` and modify the copy
      const updatedPC = {
        ...pc,
        permission: c.permission,
        sensitive: c.sensitive,
      };

      return updatedPC;
    })
    .filter(definedNotNull);

export interface QuestionAndAnswer {
  question: gqltypes.FormQuestion;
  answer: gqltypes.QuestionAnswerUnion | undefined;
}

export function matchingPred(
  { answer, question }: QuestionAndAnswer,
  pred: gqltypes.FormQuestionPredicate
): boolean {
  let a;

  switch (question.type) {
    case gqltypes.FormQuestionType.multiChoice:
      a = answer as gqltypes.AnswerMultiChoice;
      return (
        a !== undefined &&
        resolvePred(pred.predicate, { option: { id: a.multiChoice } })
      );
    case gqltypes.FormQuestionType.checkboxes:
      a = answer as gqltypes.AnswerCheckboxes;
      return (
        a !== undefined &&
        a.checkboxes.some((id) =>
          resolvePred(pred.predicate, { option: { id } })
        )
      );
    case gqltypes.FormQuestionType.ranking:
      a = answer as gqltypes.AnswerRanking;
      return false;
    case gqltypes.FormQuestionType.shortText:
      a = answer as gqltypes.AnswerShortText;
      return false;
    case gqltypes.FormQuestionType.longText:
      a = answer as gqltypes.AnswerLongText;
      return false;
    case gqltypes.FormQuestionType.yesOrNo:
      a = answer as gqltypes.AnswerYesOrNo;
      return (
        a !== undefined &&
        resolvePred(pred.predicate, {
          question: { id: a.questionId },
          value: a.yesOrNo,
        })
      );
    case gqltypes.FormQuestionType.date:
      a = answer as gqltypes.AnswerDate;
      return false;
    default:
      assertUnreachable(question.type);
  }

  return false;
}

export const getPredicateComponent = (
  predicateComponents: gqltypes.FormPredicateComponent[] | undefined,
  id: string
) => {
  return f.undefinedToError(
    f.safePipe(predicateComponents, (pcs) => pcs.find((pc) => pc.id === id)),
    Error("Did not find predicate component to get")
  );
};

export const allPredicates = (component: {
  questions: {
    predicates?: gqltypes.FormQuestionPredicate[] | null;
  }[];
}): gqltypes.FormQuestionPredicate[] =>
  f.flattenAll(component.questions.map((q) => q.predicates || []));

export const allPredicateQuestionsAndComponents = (
  predicateComponents: gqltypes.FormPredicateComponent[],
  question: gqltypes.FormQuestion
): {
  question: gqltypes.FormQuestion;
  predicateComponent: gqltypes.FormPredicateComponent;
}[] => {
  return (
    (question.predicates &&
      f.flattenAll(
        question.predicates
          .map((p) => predicateComponents.find((pc) => pc.id === p.componentId))
          .filter(definedNotNull)
          .map((pc) =>
            pc.questions.map((q) => ({ predicateComponent: pc, question: q }))
          )
      )) ||
    []
  );
};

export const allPredicateQuestions = (
  predicateComponents: gqltypes.FormPredicateComponent[],
  question: gqltypes.FormQuestion
): gqltypes.FormQuestion[] => {
  return (
    (question.predicates &&
      f.flatten(
        question.predicates
          .map((p) => predicateComponents.find((pc) => pc.id === p.componentId))
          .filter(definedNotNull)
          .map((pc) => pc.questions)
      )) ||
    []
  );
};

export const parentComponents = (
  components: gqltypes.FormComponent[],
  predicateComponentId: string
): gqltypes.FormComponent[] =>
  f.flattenAll(
    components.filter((c) =>
      c.questions.some((q) =>
        (q.predicates || []).some((p) => p.componentId === predicateComponentId)
      )
    )
  );

export const allPredicatesFromData = (data: {
  components: {
    questions: { predicates: gqltypes.FormQuestionPredicate[] | null }[];
  }[];
}): gqltypes.FormQuestionPredicate[] =>
  f.flattenAll(data.components.map(allPredicates));

export const allFulfilledPredicates = (
  components: gqltypes.FormComponent[],
  response: gqltypes.FormAnswer
): gqltypes.FormQuestionPredicate[] => {
  const questions = f.flattenAll(components.map((c) => c.questions));
  const allPreds = f.flattenAll(questions.map((q) => q.predicates || []));
  const allQAs = f.flattenAll(
    response.components.map(
      (c) =>
        c.questions
          .map((a) => ({
            question: questions.find((q) => q.id === a.questionId),
            answer: a,
          }))
          .filter((qa) => qa.question) // qa.question will be empty if the answer was for a question in a predicate component,
      //                                but we can safely ignore them atm
    )
  );

  return allPreds.filter((p) =>
    f.notEmpty(allQAs.filter((qa) => matchingPred(qa, p)))
  );
};

export const predicateComponentVisible = (
  components: gqltypes.FormComponent[],
  response: gqltypes.FormAnswer,
  predicateComponent: gqltypes.FormPredicateComponent
): boolean => {
  const questions = f.flattenAll(components.map((c) => c.questions));
  const allPreds = f.flattenAll(questions.map((q) => q.predicates || []));
  const allQAs = f.flattenAll(
    response.components.map(
      (c) =>
        c.questions
          .map((a) => ({
            question: questions.find((q) => q.id === a.questionId),
            answer: a,
          }))
          .filter((qa) => qa.question) // qa.question will be empty if the answer was for a question in a predicate component,
      //                                but we can safely ignore them atm
    )
  );

  return allPreds.some(
    (p) =>
      p.componentId === predicateComponent.id &&
      f.notEmpty(allQAs.filter((qa) => matchingPred(qa, p)))
  );
};

export const allOptions = (data: {
  components: {
    questions: any[];
  }[];
}): gqltypes.FormQuestionOptions[] =>
  f.flattenAll(
    data.components.map((c) => c.questions.map((q) => q.options || []))
  );

enum ComponentKind {
  standard,
  predicate,
}

export const updateTranslation = <IT extends { id: string }>(
  state: { translationForms: TranslationFormState },
  kind: ComponentKind,
  t: IT | null,
  language: string,
  doWhenDone: (v: { translationForms: TranslationFormState }) => void
) => {
  if (!t) {
    return;
  }

  const tfs = state.translationForms[language];

  if (!tfs) {
    throw new Error("language not found");
  }

  const componentData = tfs.componentData;

  switch (kind) {
    case ComponentKind.standard:
      tfs.componentData.components = f.replaceOrAdd(
        tfs.componentData.components,
        (c) => c.id === t.id,
        t as unknown as gqltypes.TranslateComponent
      );
      break;
    case ComponentKind.predicate:
      tfs.componentData.predicateComponents = f.replaceOrAdd(
        tfs.componentData.predicateComponents || [],
        (c) => c.id === t.id,
        t as unknown as gqltypes.TranslatePredicateComponent
      );
      break;
    default:
      assertUnreachable(kind);
  }

  const result = {
    translationForms: {
      ...state.translationForms,
      [language]: {
        ...state.translationForms[language]!,
        componentData,
      },
    } as TranslationFormState,
  };

  doWhenDone(result);
};

interface Response {
  lockForm: (options: {
    variables: gqltypes.LockFormVariables;
  }) => Promise<gqltypes.LockForm>;
}

// TI is input type, eg gqltypes.RecordHistoryInput
export interface Rest<T, TI, TransT> {
  get: (id: string) => T;
  create: () => T;
  update: (id: string, updatedComponent: TI) => void;
  getTranslation: (id: string) => TransT;
  updateTranslation: (updatedComponent: TransT, language: string) => void;
}

interface Props
  extends Response,
    AdminContext,
    Translation,
    CurrentOrg,
    UserPermission {
  saveForm: MutationFunction<gqltypes.SaveForm, gqltypes.SaveFormVariables>;
  updateFormTranslation: MutationFunction<
    gqltypes.UpdateFormTranslation,
    gqltypes.UpdateFormTranslationVariables
  >;
  updateFormTranslations: MutationFunction<
    gqltypes.UpdateFormTranslations,
    gqltypes.UpdateFormTranslationsVariables
  >;
  recordFormHistory: MutationFunction<
    gqltypes.recordFormHistory,
    gqltypes.recordFormHistoryVariables
  >;
  form: DataValue<gqltypes.form> & QueryResult;
  templateData: DataValue<gqltypes.templates> & QueryResult;
  navigate: NavigateFunction;
}

type ComponentsState = gqltypes.form["form"]["componentData"]["components"];
type PredicateComponentsState =
  gqltypes.form["form"]["componentData"]["predicateComponents"];
type TranslationFormState = {
  [language: string]:
    | Omit<gqltypes.UpdateFormTranslationInput, "formId">
    | undefined;
};
interface State {
  savingState: SavingState;
  components: ComponentsState;
  predicateComponents: PredicateComponentsState;
  translationForms: TranslationFormState;
  componentBeingEdited?: string;
  editingHeaderComponent: boolean;
  formTitle: string;
  formDescription: string;
  language: gqltypes.ISO6391Language;
  validateForm: boolean;
  access: gqltypes.FormAccessInput;
  translationLanguage?: gqltypes.ISO6391Language | null;
  createTranslationLanguage?: gqltypes.ISO6391Language;
  responsePreview: boolean;
  advancedMode: boolean;
  hasAdvancedOptions?: boolean;
  response?: gqltypes.FormAnswer;
  formType: gqltypes.FormType;
  tags: string[];
  translationErrors: gqltypes.ISO6391Language[];
  selectedApplicationSchoolUnit: { id: string; customerId: string } | null;
  formSubType: gqltypes.FormSubType;
}

class CreateEditForm extends React.PureComponent<WithRouteProps<Props>, State> {
  private autoSaveOn: boolean;
  private editExistingForm: boolean;
  private initialStatePopulated: boolean;
  private saveManager: SaveManager<FetchResult<gqltypes.SaveForm>>;
  private updateTranslationManager: SaveManager<
    FetchResult<gqltypes.UpdateFormTranslation>
  >;

  constructor(props: Props) {
    super(props);
    this.editExistingForm = props.form !== undefined;
    this.autoSaveOn = this.editExistingForm;

    this.initialStatePopulated = false;

    this.saveManager = new SaveManager(
      async () => {
        return this.save();
      },
      this.onSaveDone as any,
      this.onSaveError
    );

    this.updateTranslationManager = new SaveManager(
      this.updateTranslation,
      () => {
        // update success
        // console.log("updateTranslationManager success");
      },
      (e) => {
        // update failed
        console.error("updateTranslationManager failed", e);
        showAlertDialog({
          title: this.props.tr("createFormUpdateTranslationFailedTitle"),
          message: this.props.tr("createFormUpdateTranslationFailedMessage"),
          proceedText: defaultDialogProceed(this.props.tr),
          cancelText: defaultDialogCancel(this.props.tr),
        });
      }
    );

    this.state = {
      savingState: SavingState.Saved,
      formTitle: "",
      formDescription: "",
      language:
        this.props.org?.defaultLanguage ||
        settings.regionSettings.defaultLanguage,
      components: [],
      predicateComponents: [],
      translationForms: {},
      componentBeingEdited: undefined,
      editingHeaderComponent: !this.editExistingForm,
      validateForm: false,
      access: { public: false, organisation: true, schoolUnits: [] },
      responsePreview: false,
      advancedMode: false,
      hasAdvancedOptions: false,
      formType: gqltypes.FormType.publication,
      translationErrors: [],
      tags: [],
      selectedApplicationSchoolUnit: null,
      formSubType: gqltypes.FormSubType.general,
    };
  }

  private hasAdvancedOptions = (
    components: NonNullable<
      Props["form"]["form"]
    >["componentData"]["components"]
  ) => {
    const hasExternalId = components.some((component) =>
      component.questions.some((q) => {
        switch (q.type) {
          case gqltypes.FormQuestionType.multiChoice:
            return (q as FormQuestionDataTyped<"multiChoice">).options.some(
              (o) => Boolean(o.externalId)
            );

          case gqltypes.FormQuestionType.checkboxes:
            return (q as FormQuestionDataTyped<"checkboxes">).options.some(
              (o) => Boolean(o.externalId)
            );

          case gqltypes.FormQuestionType.ranking:
            return (q as FormQuestionDataTyped<"ranking">).options.some((o) =>
              Boolean(o.externalId)
            );
        }
        return false;
      })
    );
    return hasExternalId;
  };

  private attemptSetInitialState = (
    prevProps: Props | undefined,
    props: Props
  ) => {
    if (this.initialStatePopulated) {
      return;
    }
    const formData = props.form;
    if (formData && formData.form && !formData.loading) {
      this.props.recordFormHistory({
        variables: {
          context: props.adminContext,
          input: {
            isFavorite: false,
            type: gqltypes.RecordHistoryType.form,
            label: formData.form.name,
            data: {
              formId: formData.form.id,
            },
          },
        },
      });

      const form = formData.form;
      if (form !== undefined) {
        this.initialStatePopulated = true;
        const hasAdvancedOptions = this.hasAdvancedOptions(
          form.componentData.components
        );
        const { __typename, ...formAccess } = form.access;
        this.setState({
          components: form.componentData.components,
          predicateComponents: form.componentData.predicateComponents,
          formTitle: form.name,
          language: form.language,
          formDescription: form.description || "",
          access: {
            ...formAccess,
            schoolUnits: formAccess.schoolUnits
              .filter(definedNotNull)
              .map((schoolUnit: IdCustomerIdInput) => ({
                id: schoolUnit.id,
                customerId: schoolUnit.customerId,
              })),
          },
          formType: form.type,
          translationForms: form.translations.reduce((out, item) => {
            out[item.language] = _.cloneDeep(item);
            return out;
          }, {} as TranslationFormState),
          advancedMode: hasAdvancedOptions,
          hasAdvancedOptions,
          tags: form.tags,
        });
      }
    }
  };

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.components !== prevState.components) {
      const hasAdvancedOptions = this.hasAdvancedOptions(this.state.components);
      this.setState((state) => ({
        advancedMode: hasAdvancedOptions ? true : state.advancedMode,
        hasAdvancedOptions,
      }));
    }
    if (this.state.language !== prevState.language) {
      this.handleBaseLanguageChanged(this.state.language);
    }
    if (this.props === prevProps) {
      return;
    }
    this.attemptSetInitialState(prevProps, this.props as Props);
  }

  public componentDidMount() {
    this.attemptSetInitialState(undefined, this.props as Props);
    // Workaround to avoid the event listener responding to the menu click that renders
    // this view
    setTimeout(() => {
      window.addEventListener("click", this.bodyClickListener);
    }, 500);
  }

  public componentWillUnmount() {
    window.removeEventListener("click", this.bodyClickListener);
    this.saveManager.destroy();
  }

  public render() {
    const { tr } = this.props;
    const header =
      (this.editExistingForm ? tr("edit") : tr("create")) + " " + tr("form");
    const unusedTemplates = this.props.templateData.templates
      ? this.props.templateData.templates.filter((template) => {
          if (
            this.state.components.find((c) => c.templateId === template.id) !==
            undefined
          ) {
            return false;
          }
          if (
            this.state.formType === "publication" &&
            template.id === "e0eecb61-f3a9-46ff-9d9d-c96b26333b20"
          ) {
            // FS-509: We use id here, because we don't want specificly "Flyttedato" to be available for Publications.
            return false;
          }

          return (
            settings.regionSettings.defaultLanguage === template.language ||
            template.translations.find((translation) => {
              return (
                settings.regionSettings.defaultLanguage === translation.language
              );
            })
          );
        })
      : [];

    const form = this.props.form && this.props.form.form;
    if (this.editExistingForm) {
      if (
        this.props.form.error?.message ===
        "GraphQL error: Requestor is not allowed read or modify form"
      ) {
        return <GenericError title={tr("editFormNoAccess")} />;
      }
      if (this.props.form.error) {
        return <FormDoesNotExist />;
      }
      if (!form) {
        return <Loading />;
      }
    }

    if (form && (form.archived || form.locked)) {
      const warningText = form.archived
        ? tr("formArchivedWarning")
        : tr("formLockedWarning");
      return (
        <InnerFormView
          form={form}
          handleGoBack={this.handleGoBack}
          warning={warningText}
          setLanguage={() => {
            //
          }}
        />
      );
    }

    const translating = Boolean(this.state.translationLanguage);

    const translation: gqltypes.FullTranslation | undefined | null =
      this.state.translationLanguage &&
      (this.state.translationForms[this.state.translationLanguage] as any);

    const formLanguage = form ? form.language : this.state.language;

    const translationLanguages = form
      ? _.orderBy(form.translationLanguages)
      : [];
    const exisitingLanguages = form
      ? [form.language].concat(translationLanguages)
      : [];

    const newTranslationLanguageList = Object.values(
      availableTranslationLanguageEnum as unknown as gqltypes.ISO6391Language
    ).filter((newLanguage) => {
      if (form) {
        if (
          exisitingLanguages.some(
            (tl: gqltypes.ISO6391Language) => newLanguage === tl
          )
        ) {
          return false;
        }
      }
      return true;
    }) as gqltypes.ISO6391Language[];

    const mapComponents = (data: State["components"][0], index: number) => {
      const editingComponent = this.state.componentBeingEdited === data.id;
      const startEditing = (event: any) =>
        !isReadOnly(data) && this.editFormComponentEventHandler(event, data.id);

      const componentTranslation:
        | gqltypes.FullTranslation["componentData"]["components"][0]
        | null
        | undefined = (translation &&
        translation.componentData.components.find((c) => c.id === data.id)) || {
        id: data.id,
        language: null,
        title: "",
        description: "",
        questions: [],
        attachments: data.attachments || [],
        __typename: "TranslateComponent",
      };

      const componentResponse =
        this.state.response &&
        this.state.response.components.find((c) => c.componentId === data.id);

      const getPredicateTranslation = (id: string) => {
        return (
          f.throwWhenError(
            f.safePipe(
              f.get(translation, "componentData", "predicateComponents"),
              (pcs) => pcs.find((c) => c.id === id)
            )
          ) || {
            id,
            language: null,
            title: "",
            description: "",
            questions: [],
            attachments: data.attachments || [],
            __typename: "TranslatePredicateComponent",
          }
        );
      };

      return editingComponent ? (
        <FormComponentEditor
          key={data.id + "-editor"}
          predicateComponentCreator={{
            get: this.getPredicateComponent,
            create: this.handleAddPredicateComponent,
            update: this.updatePredicateComponent,
            getTranslation: getPredicateTranslation,
            updateTranslation: (
              t: gqltypes.TranslatePredicateComponent,
              language: string
            ) =>
              updateTranslation(
                this.state,
                ComponentKind.predicate,
                t,
                language,
                (newState) => this.setState(newState, this.autoSaveTranslation)
              ),
          }}
          removeComponent={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
            this.removeComponent(e, data)
          }
          updateComponent={(updatedComponent: gqltypes.ComponentInput) =>
            this.updateComponent(updatedComponent.id, updatedComponent)
          }
          updateTranslation={(
            t: gqltypes.TranslatePredicateComponent,
            language: string
          ) =>
            updateTranslation(
              this.state,
              ComponentKind.standard,
              t,
              language,
              (newState) => this.setState(newState, this.autoSaveTranslation)
            )
          }
          startEditingComponent={startEditing}
          component={data}
          formLanguage={formLanguage}
          validateComponent={this.state.validateForm}
          actionBlock={this.renderEditorActionBlock(
            data,
            index === 0,
            index === this.state.components.length - 1
          )}
          translating={translating}
          translation={componentTranslation}
          translationLanguage={this.state.translationLanguage}
          formType={this.state.formType}
          advancedMode={this.state.advancedMode}
        />
      ) : (
        <FormComponentView
          getPredicateComponent={this.getPredicateComponent}
          getPredicateComponentTranslation={getPredicateTranslation}
          key={data.id + "-view"}
          isFormCreator={true}
          removeComponent={(e) => this.removeComponent(e, data)}
          startEditingComponent={
            this.state.responsePreview ? undefined : startEditing
          }
          response={this.state.response}
          component={data}
          validateAnswers={this.state.responsePreview}
          answer={componentResponse}
          updateComponentAnswer={this.updateComponentResponse}
          formLanguage={formLanguage}
          validateComponent={this.state.validateForm}
          actionBlock={this.renderViewActionBlock(
            data,
            index === 0,
            index === this.state.components.length - 1
          )}
          translationLanguage={
            this.state.translationLanguage as gqltypes.ISO6391Language.sv
          }
          translation={componentTranslation}
          translating={translating}
        />
      );
    };

    return (
      <div id="form-creator">
        <Prompt
          when={this.state.savingState === SavingState.NotSaved}
          message={tr("createFormUnsavedChangesWarning")}
        />
        <h1>{header}</h1>
        <p className="col-12 col-md-9 p-0">{tr("createFormDescription")}</p>
        <p className="col-12 col-md-9 p-0 pb-3">
          {tr("createFormUsageDescription")}
        </p>
        <div className="content">
          {this.editExistingForm && !form ? (
            <Loading />
          ) : (
            <form>
              {this.editExistingForm && (
                <FormAccess
                  edit={true}
                  formType={this.state.formType}
                  access={this.state.access}
                  disabled={
                    this.state.formType === FormType.application ||
                    !this.props?.permissions?.org?.create
                  }
                  handleChangeBooleanAccess={this.handleChangeBooleanAccess}
                  handleChangeSchoolunitAccess={
                    this.handleChangeSchoolunitAccess
                  }
                />
              )}
              {form ? (
                <div>
                  <div className="mb-2">
                    {tr("formType")}:{" "}
                    <span className="badge badge-info">
                      {getFormTypeName(tr, form.type)} (
                      {this.props.form.form &&
                        getFormTypeName(tr, this.props.form.form.subType)}
                      )
                    </span>
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    {tr("language")}:{" "}
                    {exisitingLanguages.map((language) => (
                      <Button
                        key={language}
                        label={languageNames[language]}
                        className={`mr-2 ${
                          this.state.translationErrors.includes(language)
                            ? "border-danger"
                            : ""
                        }`}
                        level={
                          language === this.state.translationLanguage ||
                          (!this.state.translationLanguage &&
                            language === formLanguage)
                            ? "primary"
                            : "secondary"
                        }
                        onClick={() => {
                          this.setState({
                            translationLanguage:
                              formLanguage === language ? undefined : language,
                          });
                        }}
                      />
                    ))}
                    <UncontrolledDropdown className="ml-auto">
                      <DropdownToggle caret>
                        {tr("createFormAddRemoveTranslation")}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem header>{tr("add")}</DropdownItem>
                        {Object.values(newTranslationLanguageList).map((l) => (
                          <DropdownItem
                            key={l}
                            onClick={() => this.handleAddTranslation(l)}
                          >
                            {languageNames[l]}
                          </DropdownItem>
                        ))}
                        <DropdownItem divider />
                        <DropdownItem header>{tr("remove")}</DropdownItem>
                        {translationLanguages.map((l) => (
                          <DropdownItem
                            key={l}
                            onClick={() => this.handleRemoveTranslation(l)}
                          >
                            {languageNames[l]}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              ) : (
                <div>
                  <FormSelect
                    label={tr("mainLanguage")}
                    options={Object.values(
                      availableTranslationLanguageEnum as unknown as gqltypes.ISO6391Language
                    ).map((l: gqltypes.ISO6391Language) => ({
                      value: l,
                      label: languageNames[l],
                    }))}
                    value={this.state.language}
                    onChange={(e) => {
                      const language = e.currentTarget
                        .value as keyof typeof availableTranslationLanguageEnum;
                      this.setState({
                        language: availableTranslationLanguageEnum[
                          language
                        ] as any,
                      });
                    }}
                  />
                  <FormSelect
                    label={tr("formType")}
                    options={[
                      {
                        value: gqltypes.FormType.publication,
                        label: getFormTypeName(
                          tr,
                          gqltypes.FormType.publication
                        ),
                      },
                      {
                        value: gqltypes.FormType.application,
                        label: getFormTypeName(
                          tr,
                          gqltypes.FormType.application
                        ),
                      },
                    ]}
                    value={this.state.formType}
                    onChange={(e) => {
                      const formType = e.currentTarget
                        .value as State["formType"];
                      this.setState({
                        formType,
                      });
                      if (this.editExistingForm) {
                        this.saveManager.save();
                      }
                    }}
                  />
                  {this.props.org?.features?.relocation &&
                    this.state.formType === gqltypes.FormType.application && (
                      <FormSelect
                        label={tr("formSubType")}
                        options={[
                          {
                            value: gqltypes.FormSubType.general,
                            label: getFormTypeName(
                              tr,
                              gqltypes.FormSubType.general
                            ),
                          },
                          {
                            value: gqltypes.FormSubType.outMigration,
                            label: getFormTypeName(
                              tr,
                              gqltypes.FormSubType.outMigration
                            ),
                          },
                        ]}
                        value={this.state.formSubType}
                        onChange={(e) => {
                          const formSubType = e.currentTarget
                            .value as State["formSubType"];
                          this.setState({ formSubType });
                          const outMigrationTemplate =
                            this.props.templateData.templates?.find(
                              (template) =>
                                template.id === OUT_MIGRATION_TEMPLATE_ID
                            );
                          if (
                            formSubType === gqltypes.FormSubType.outMigration
                          ) {
                            if (outMigrationTemplate) {
                              this.handleAddTemplate(outMigrationTemplate);
                            }
                          } else {
                            this.setState(
                              (state) => ({
                                savingState: SavingState.NotSaved,
                                components: state.components.filter(
                                  (c) => c.id !== OUT_MIGRATION_TEMPLATE_ID
                                ),
                              }),
                              this.autoSave
                            );
                          }
                          if (this.editExistingForm) {
                            this.saveManager.save();
                          }
                        }}
                      />
                    )}
                </div>
              )}
              {/* Commenting the labels dropdown for hiding unused functionality and keeping the possibility for future development */}
              {/* <FormSelectTags
                tr={tr}
                className="mt-3"
                value={this.state.tags}
                onChange={(tags) => {
                  this.setState({ tags: tags.map((tag) => tag.value) });
                  if (this.editExistingForm) {
                    this.saveManager.save();
                  }
                }}
              /> */}
              <div className="row d-flex align-items-end py-3">
                <div className="col-12">
                  <Checkbox
                    id="responsePreviewCheckbox"
                    label={tr("createFormResponsePreviewLabel")}
                    checked={this.state.responsePreview}
                    onChange={(id, checked) => {
                      this.setState({ responsePreview: checked });
                    }}
                  />
                </div>
                {/* Commenting the advanced mode checkbox for hiding unused functionality and keeping the possibility for future development */}
                {/* <div className="col-12">
                  <Checkbox
                    id="advancedModeCheckbox"
                    label={
                      <div>
                        {tr("createFormAdvancedModeLabel")}{" "}
                        <HelpTooltip
                          tooltipText={
                            this.state.hasAdvancedOptions
                              ? tr("createFormAdvancedModeLockedDescription")
                              : tr("createFormAdvancedModeDescription")
                          }
                        />
                      </div>
                    }
                    disabled={this.state.hasAdvancedOptions}
                    checked={this.state.advancedMode}
                    onChange={(id, checked) => {
                      this.setState({
                        advancedMode: checked,
                      });
                    }}
                  />
                </div> */}
              </div>
              <FormHeader
                formType={this.state.formType}
                canEdit={true}
                formOwner={
                  form
                    ? form.owner
                    : {
                        id: this.props.adminContext.org!,
                        displayName: "Kommunnamn",
                      }
                }
                editingHeaderComponent={this.state.editingHeaderComponent}
                editHeaderComponent={this.editHeaderComponent}
                handleTitleChange={this.handleChangeFormTitle}
                handleTranslationTitleChange={this.handleTranslationChangeFormTitle.bind(
                  this,
                  this.state.translationLanguage
                )}
                handleDescriptionChange={this.handleChangeFormDescription}
                handleTranslationDescriptionChange={this.handleTranslationChangeFormDescription.bind(
                  this,
                  this.state.translationLanguage
                )}
                formTitle={this.state.formTitle}
                formDescription={this.state.formDescription}
                formLanguage={this.state.language}
                ignoreBodyEditClick={this.state.responsePreview}
                translating={translating}
                translation={translation}
                savingState={this.state.savingState}
                validate={this.state.validateForm}
              >
                {this.state.formType === gqltypes.FormType.application ? (
                  <ApplicationCustomComponent
                    subjectCoSignerLocked={false}
                    tr={tr}
                    uiLanguage={this.state.language}
                    formOwner={
                      form
                        ? form.owner
                        : {
                            id: this.props.adminContext.org!,
                            displayName: "Kommunnamn",
                          }
                    }
                    answer={
                      this.state.response &&
                      this.state.response.components.find(
                        (c) => c.componentId === "application_custom_component"
                      )
                    }
                    updateComponentAnswer={this.updateComponentResponse}
                    selectedSchoolUnit={
                      this.state.selectedApplicationSchoolUnit
                    }
                    updateSelectedSchoolUnit={(selectedApplicationSchoolUnit) =>
                      this.setState({ selectedApplicationSchoolUnit })
                    }
                  />
                ) : null}
              </FormHeader>

              <FlipMove
                duration={350}
                easing="ease-out"
                appearAnimation={"none"}
                enterAnimation={"fade"}
                leaveAnimation={
                  null as any /* workaround for animations at bottom of page */
                }
              >
                <>
                  {this.state.components
                    .filter((c) => !c.applicationFeedbackId)
                    .map(mapComponents)}
                  {this.state.formType === gqltypes.FormType.application ? (
                    <div className="bg-warning p-3 rounded">
                      <h3>{tr("applicationFeedbackLabel")}</h3>
                      <p className="col-md-9">
                        {tr("createFormApplicationFeedbackDescription")}
                      </p>
                      {this.state.components
                        .filter((c) => Boolean(c.applicationFeedbackId))
                        .map(mapComponents)}
                    </div>
                  ) : null}
                </>
              </FlipMove>

              {!translating && (
                <div
                  className="new-component-placeholder"
                  onClick={this.stopPropagation}
                >
                  <span>
                    {tr("createFormAddComponent")}
                    <Button
                      size="btn-sm"
                      level="secondary"
                      onClick={this.handleAddComponent}
                      label={tr("newFormComponentButtonLabel")}
                    />
                    {this.state.formType === gqltypes.FormType.application ? (
                      <Button
                        size="btn-sm"
                        level="secondary"
                        onClick={this.handleAddFeedbackComponent}
                        label={tr("newFormFeedbackComponentButtonLabel")}
                      />
                    ) : null}
                    {unusedTemplates.length > 0 && (
                      <span>
                        {tr("createFormTemplateFor")}
                        {unusedTemplates.map((template) => {
                          const tt = template.translations.find(
                            (t) => t.language === this.state.language
                          );
                          return (
                            <Button
                              size="btn-sm"
                              key={template.id}
                              onClick={() => this.handleAddTemplate(template)}
                              label={
                                tt
                                  ? tt.componentData.title
                                  : (template.language !== this.state.language
                                      ? template.language + ": "
                                      : "") + template.componentData.title
                              }
                              level="secondary"
                            />
                          );
                        })}
                      </span>
                    )}
                  </span>
                </div>
              )}

              <div className="d-flex" onClick={this.stopPropagation}>
                <Button
                  className="mr-auto"
                  onClick={this.handleCancel}
                  level="secondary"
                  label={
                    this.state.savingState === SavingState.Saved
                      ? tr("goBack")
                      : tr("cancel")
                  }
                  type="button"
                />
                <div>
                  <Button
                    icon={
                      this.state.savingState === SavingState.Saving ? (
                        <i className="fa fa-spinner fa-spin" />
                      ) : (
                        <i className="fa fa-save" />
                      )
                    }
                    disabled={
                      this.state.savingState === SavingState.Saving ||
                      this.state.savingState === SavingState.Saved
                    }
                    label={
                      this.editExistingForm
                        ? this.state.savingState === SavingState.Saved
                          ? tr("allChangesSaved")
                          : tr("save")
                        : tr("create")
                    }
                    level="secondary"
                    onClick={this.handleSaveNow}
                    type="submit"
                  />{" "}
                  <Button
                    icon={<i className="fa fa-lock" />}
                    level="primary"
                    label={
                      this.state.formType === gqltypes.FormType.publication
                        ? tr("createFormAcceptForPublication")
                        : tr("createFormAcceptForApplication")
                    }
                    disabled={
                      !this.editExistingForm ||
                      this.state.savingState !== SavingState.Saved
                    }
                    onClick={this.handlePublishForm}
                    type="button"
                  />
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }

  private updateComponentResponse = (
    component: gqltypes.FormComponentAnswer
  ) => {
    const currentResponse = this.state.response;
    const components = currentResponse
      ? currentResponse.components.map((c) => omit(c, "redacted"))
      : [];

    const indexToUpdate = _.findIndex(
      components,
      (c) => c.componentId === component.componentId
    );
    const updatedComponents =
      indexToUpdate !== -1
        ? replaceInArray(components, indexToUpdate, component)
        : [...components, component];
    this.setState({ response: { components: updatedComponents } });
  };

  private renderRemoveAction = (component: State["components"][0]) => {
    if (
      component.id === OUT_MIGRATION_TEMPLATE_ID &&
      this.state.formSubType === gqltypes.FormSubType.outMigration
    )
      return null;
    return (
      <i
        onClick={(e: React.MouseEvent<HTMLDivElement>) =>
          this.removeComponent(e, component)
        }
        className="fas fa-trash icon-button"
      />
    );
  };

  private renderDownAction = (componentId: string, disabled: boolean) => (
    <i
      onClick={(e: React.MouseEvent<HTMLDivElement>) =>
        !disabled && this.moveComponent(e, componentId, 1)
      }
      className={
        "fas fa-arrow-down icon-button" + (disabled ? " disabled" : "")
      }
    />
  );

  private renderUpAction = (componentId: string, disabled: boolean) => (
    <i
      onClick={(e: React.MouseEvent<HTMLDivElement>) =>
        !disabled && this.moveComponent(e, componentId, -1)
      }
      className={"fas fa-arrow-up icon-button" + (disabled ? " disabled" : "")}
    />
  );

  private renderPreviewAction = () => {
    const { tr } = this.props;
    const language = this.state.translationLanguage || this.state.language;
    return (
      <React.Fragment>
        {<p className="mb-0">{tr("editingLanguage", language)}</p>}
        <i
          onClick={() => {
            // Quickfix for the problem where form div changes editing component back after set to empty
            // setTimeout is run after this
            setTimeout(() => {
              this.editFormComponent("");
            });
          }}
          className={"fas fa-eye icon-button"}
        />
      </React.Fragment>
    );
  };

  private renderEditorActionBlock = (
    component: State["components"][0],
    isFirst: boolean,
    isLast: boolean
  ) => {
    const translating = Boolean(this.state.translationLanguage);
    return (
      <div className="form-component-actions d-flex d-row align-items-baseline">
        {this.renderPreviewAction()}
        {!translating && this.renderUpAction(component.id, isFirst)}
        {!translating && this.renderDownAction(component.id, isLast)}
        {!translating && this.renderRemoveAction(component)}
      </div>
    );
  };

  private renderViewActionBlock = (
    component: State["components"][0],
    isFirst: boolean,
    isLast: boolean
  ) => {
    const tooltipTarget = "taggingTooltip-" + component.id;
    const translating = Boolean(this.state.translationLanguage);
    return (
      <div className="form-component-actions d-flex d-row align-items-baseline">
        {this.state.formType === gqltypes.FormType.publication ? (
          <i id={tooltipTarget} className="mr-3" data-toggle="tooltip">
            {this.props.tr("createFormTagging")}:{" "}
            {getComponentPermissionDisplayName(
              component.permission,
              this.props.tr
            )}{" "}
            <i className="fa fa-question-circle" />
            <PermissionsTooltip tr={this.props.tr} target={tooltipTarget} />
          </i>
        ) : null}
        {!isReadOnly(component) && (
          <i
            onClick={(event) =>
              !isReadOnly(component) &&
              this.editFormComponentEventHandler(event, component.id)
            }
            className="fas fa-pencil-alt icon-button edit-button"
          />
        )}
        {!translating && this.renderUpAction(component.id, isFirst)}
        {!translating && this.renderDownAction(component.id, isLast)}
        {!translating && this.renderRemoveAction(component)}
      </div>
    );
  };

  private moveComponent = (
    e: React.MouseEvent<HTMLDivElement>,
    componentId: string,
    indexIncrement: number
  ) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState(
      (state: State) => {
        const index = _.findIndex(
          state.components,
          (c) => c.id === componentId
        );
        if (index === -1) {
          throw new Error("componentIndex not found");
        }
        return {
          savingState: SavingState.NotSaved,
          components: move(state.components, index, index + indexIncrement),
        };
      },
      () => this.autoSave()
    );
  };

  private bodyClickListener = (event: any) => {
    this.setState({
      componentBeingEdited: "",
      editingHeaderComponent: false,
    });
  };

  private editHeaderComponent = (event: React.MouseEvent<HTMLDivElement>) => {
    event.nativeEvent.stopImmediatePropagation();
    this.setState({ componentBeingEdited: "", editingHeaderComponent: true });
  };

  private editFormComponentEventHandler = (
    event: React.MouseEvent<HTMLElement>,
    id: string
  ) => {
    event.nativeEvent.stopImmediatePropagation();
    this.editFormComponent(id);
  };

  private editFormComponent = (id: string) => {
    this.setState({ componentBeingEdited: id, editingHeaderComponent: false });
  };

  private stopPropagation = (event: React.MouseEvent<HTMLElement>) => {
    event.nativeEvent.stopImmediatePropagation();
  };

  private handleAddTranslation = async (language: gqltypes.ISO6391Language) => {
    const form = this.props.form.form!;

    const createdComponentLanguages: Set<gqltypes.ISO6391Language> = new Set();

    const res = await client.mutate<
      gqltypes.CreateFormTranslation,
      gqltypes.CreateFormTranslationVariables
    >({
      mutation: gql`
        mutation CreateFormTranslation(
          $formId: ID!
          $language: ISO6391Language!
          $context: Context!
        ) {
          createFormTranslation(
            id: $formId
            language: $language
            context: $context
          ) {
            ...FullTranslation
          }
        }
        ${fullFormTranslationFragment}
      `,
      variables: {
        formId: form.id,
        language,
        context: this.props.adminContext,
      },
    });

    if (
      (
        res.data as gqltypes.CreateFormTranslation
      ).createFormTranslation.componentData.components.some((c) => {
        if (c.language) {
          createdComponentLanguages.add(c.language);
          return true;
        }
        return false;
      })
    ) {
      showAlertDialog({
        title: this.props.tr(
          "createFormCreatedTranslationMissingTemplateLanguageTitle"
        ),
        message: this.props.tr(
          "createFormCreatedTranslationMissingTemplateLanguage",
          [...createdComponentLanguages].join(", ")
        ),
        proceedText: defaultDialogProceed(this.props.tr),
        cancelText: defaultDialogCancel(this.props.tr),
      });
    }

    // Fix to get the new translation
    this.initialStatePopulated = false;
    this.props.form.refetch();
  };

  private handleRemoveTranslation = async (
    language: gqltypes.ISO6391Language
  ) => {
    const form = this.props.form.form!;

    const confirmed = await showConfirmDialog({
      title: this.props.tr("createFormRemoveTranslation"),
      content: (
        <React.Fragment>
          <p>
            {this.props.tr(
              "createFormRemoveTranslationMessage",
              languageNames[language]
            )}
          </p>
          <p>{this.props.tr("createFormRemoveTranslationMessageWarning")}</p>
        </React.Fragment>
      ),
      proceedText: this.props.tr("createFormRemoveComponentConfirm"),
      cancelText: defaultDialogCancel(this.props.tr),
    });
    if (!confirmed) {
      return;
    }

    this.setState(
      {
        translationLanguage:
          this.state.translationLanguage === language
            ? null
            : this.state.translationLanguage,
      },
      async () => {
        const res = await client.mutate({
          mutation: gql`
            mutation DeleteFormTranslation(
              $formId: ID!
              $language: ISO6391Language!
              $context: Context!
            ) {
              deleteFormTranslation(
                id: $formId
                language: $language
                context: $context
              ) {
                ...FullTranslation
              }
            }
            ${fullFormTranslationFragment}
          `,
          variables: {
            formId: form.id,
            language,
            context: this.props.adminContext,
          },
        });

        // Fix to get the new translation
        this.initialStatePopulated = false;
        this.props.form.refetch();
      }
    );
  };

  private removeComponent = async (
    e: React.MouseEvent<HTMLDivElement>,
    componentData: gqltypes.FormComponent
  ) => {
    e.stopPropagation();
    const templateId = this.attemptGetTemplateId(componentData);

    if (!templateId) {
      const confirmed = await showConfirmDialog({
        title: this.props.tr("createFormRemoveComponent"),
        content: (
          <React.Fragment>
            <p>
              {this.props.tr(
                "createFormRemoveComponentMessage",
                componentData.title
              )}
            </p>
            <p>
              {this.props.tr(
                "createFormRemoveComponentMessageWarning",
                componentData.title
              )}
            </p>
          </React.Fragment>
        ),
        proceedText: this.props.tr("createFormRemoveComponentConfirm"),
        cancelText: defaultDialogCancel(this.props.tr),
      });
      if (!confirmed) {
        return;
      }
    }
    this.setState(
      (state) => ({
        savingState: SavingState.NotSaved,
        components: state.components.filter((c) => c.id !== componentData.id),
      }),
      this.autoSave
    );

    if (templateId) {
      // Remove component from translations
    }
  };

  private updateComponent = (
    id: string,
    updatedComponent: gqltypes.ComponentInput
  ) => {
    this.setState(
      (prevState) => {
        const index = _.findIndex(prevState.components, (c) => c.id === id);
        if (index === -1) {
          throw Error("Did not find component to update");
        }
        return {
          savingState: SavingState.NotSaved,
          components: replaceInArray(
            prevState.components,
            index,
            updatedComponent as any
          ),
        };
      },
      () => this.autoSave()
    );
  };

  private handleAddComponent = () => {
    const newComponent = makeEmptyComponent();
    this.setState(
      (state: any) => {
        return {
          validateForm: false,
          savingState: SavingState.NotSaved,
          components: [...state.components, newComponent],
          componentBeingEdited: newComponent.id,
        };
      },
      () => this.autoSave()
    );
  };

  private getPredicateComponent = (id: string) => {
    return f.throwWhenError(
      getPredicateComponent(this.state.predicateComponents || undefined, id)
    );
  };

  private handleAddPredicateComponent = () => {
    const newComponent = makeEmptyPredicateComponent();

    this.setState(
      (state: any) => {
        return {
          validateForm: false,
          savingState: SavingState.NotSaved,
          predicateComponents: [
            ...(state.predicateComponents || []),
            newComponent,
          ],
          componentBeingEdited: state.componentBeingEdited,
        };
      },
      () => this.autoSave()
    );

    return newComponent;
  };

  private updatePredicateComponent = (
    id: string,
    updatedComponent: gqltypes.FormPredicateComponentInput
  ) => {
    this.setState(
      (prevState) => {
        const index = _.findIndex(
          prevState.predicateComponents,
          (c) => c.id === id
        );
        if (index === -1) {
          throw Error("Did not find predicate component to update");
        }

        const newState = {
          components: [...prevState.components],
          predicateComponents: [...(prevState.predicateComponents || [])],
        };

        // 1. if all questions have been deleted from the predicate component
        if (f.empty(updatedComponent.questions)) {
          f.update(newState, ["predicateComponents"], (pcs) =>
            (pcs || []).filter((pc) => pc.id !== id)
          );

          // 2. then remove all references to that predicate component
          f.update(newState, ["components"], (cs) =>
            cs.map((c) =>
              f.update(c, ["questions"], (qs: gqltypes.FormQuestion[]) =>
                qs.map((q) =>
                  f.update(q, ["predicates"], (ps) =>
                    f.safePipe(ps, (ps2) =>
                      ps2.filter((p) => p.componentId !== id)
                    )
                  )
                )
              )
            )
          );
        } else {
          f.update(newState, ["predicateComponents"], (pcs) =>
            replaceInArray(pcs || [], index, updatedComponent as any)
          );
        }

        return {
          ...newState,
          savingState: SavingState.NotSaved,
        };
      },
      () => this.autoSave()
    );
  };

  private handleAddFeedbackComponent = () => {
    const newComponent = makeEmptyFeebackComponent();
    this.setState(
      (state: any) => {
        return {
          validateForm: false,
          savingState: SavingState.NotSaved,
          components: [...state.components, newComponent],
          componentBeingEdited: newComponent.id,
        };
      },
      () => this.autoSave()
    );
  };

  private handleBaseLanguageChanged = (language: gqltypes.ISO6391Language) => {
    // Update template components to use correct language
    if (!this.props.templateData.templates) {
      return;
    }
    const components = this.state.components.map((component) => {
      if (component.templateId) {
        const template = this.props.templateData.templates!.find(
          (t) => t.id === component.templateId
        );
        if (!template) {
          throw new Error(
            "Failed to find template with id: " + component.templateId
          );
        }

        return getTemplateComponentInLanguage(template, language, [
          gqltypes.ISO6391Language.sv,
        ]);
      }
      return component;
    });

    this.setState({ components: components as any });
  };

  private getTemplateLanguages = (
    template: gqltypes.templates["templates"][0]
  ) => {
    return [template.language].concat(
      template.translations.map((t) => t.language)
    );
  };

  private handleAddTemplate = async (
    template: gqltypes.templates["templates"][0]
  ) => {
    if (
      this.state.components.some((component) => component.id === template.id)
    ) {
      return;
    }

    const availableTemplateLanguages = this.getTemplateLanguages(template);

    const translationFormLanguages = [this.state.language].concat(
      Object.keys(this.state.translationForms) as gqltypes.ISO6391Language[]
    );

    const missing = _.difference(
      translationFormLanguages,
      availableTemplateLanguages
    );

    if (missing.length > 0) {
      const confirmed = await showConfirmDialog({
        title: this.props.tr("createFormAddTemplateMissingLanguagesTitle"),
        message: this.props.tr(
          "createFormAddTemplateMissingLanguagesMessage",
          missing.join(", "),
          template.language
        ),
        proceedText: defaultDialogProceed(this.props.tr),
        cancelText: defaultDialogCancel(this.props.tr),
      });
      if (!confirmed) {
        return;
      }
    }

    let newComponent: gqltypes.ComponentTemplateInput =
      makeComponentFromTemplate(template.componentData);

    // should apply other language
    if (template.language !== this.state.language) {
      const translation = template.translations.find(
        (t) => t.language === this.state.language
      );
      if (translation) {
        newComponent = applyTranslationOnComponent(
          newComponent as any,
          translation
        );
      } else {
        // override language for component
        newComponent.language = template.language;
      }
    }
    this.setState(
      (state: any) => ({
        validateForm: false,
        savingState: SavingState.NotSaved,
        components: [...state.components, newComponent],
      }),
      this.autoSave
    );

    translationFormLanguages.forEach((tfl) => {
      if (tfl !== this.state.language) {
        this.handleAddTemplateLanguage(template, tfl, [template.language]);
      }
    });
  };

  private handleAddTemplateLanguage = async (
    template: gqltypes.templates["templates"][0],
    language: gqltypes.ISO6391Language,
    fallbackLanguages: gqltypes.ISO6391Language[]
  ) => {
    let translation =
      template.language === language
        ? template
        : template.translations.find((t) => t.language === language);

    if (!translation) {
      for (const fallbackLanguage of fallbackLanguages) {
        translation =
          template.language === fallbackLanguage
            ? template
            : template.translations.find(
                (t) => t.language === fallbackLanguage
              );
        if (translation) {
          break;
        }
      }
    }

    const newComponent = makeComponentFromTemplateTranslation(
      translation!.componentData,
      translation!.language !== language ? translation!.language : null
    );

    this.setState((state) => {
      const initialTranslation = state.translationForms[language];
      if (!initialTranslation) {
        throw new Error("Missing initialTranslation");
      }
      const translationForms: State["translationForms"] = {
        ...state.translationForms,
        [language]: {
          ...initialTranslation,
          componentData: {
            ...initialTranslation.componentData,
            components: [
              ...initialTranslation.componentData.components,
              newComponent,
            ],
          },
        },
      };
      return { translationForms };
    }, this.debouncedUpdateAllTranslations);
  };

  private handleGoBack = () => {
    this.props.navigate(links.admin.form.list());
  };

  private handleCancel = async () => {
    if (this.state.savingState === SavingState.Saved) {
      this.handleGoBack();
    } else {
      const confirmed = await showConfirmDialog({
        title: this.props.tr("createFormDiscardChanges"),
        message: this.props.tr("createFormDiscardChangesMessage"),
        proceedText: defaultDialogProceed(this.props.tr),
        cancelText: defaultDialogCancel(this.props.tr),
      });
      if (confirmed) {
        this.setState({ savingState: SavingState.Saved }, () =>
          this.handleGoBack()
        );
      }
    }
  };

  private handlePublishForm = async () => {
    const allPreds = allPredicatesFromData(this.state);
    this.setState({
      predicateComponents: cleanPCs(
        this.state.components,
        (this.state.predicateComponents || []).filter((pc) =>
          allPreds.find((p) => p.componentId === pc.id)
        )
      ),
      savingState: SavingState.NotSaved,
    });

    await this.saveManager.saveNow();

    const componentsValid =
      this.state.components.every(componentIsValid) &&
      (this.state.predicateComponents
        ? this.state.predicateComponents.every(predicateComponentIsValid)
        : true);

    const nonValidComponents = [
      this.state.components.filter((x) => !componentIsValid(x)),
      this.state.predicateComponents
        ? this.state.predicateComponents.filter(
            (x) => !predicateComponentIsValid(x)
          )
        : [],
    ];

    const formMetaValid =
      nonEmptyString(this.state.formTitle) &&
      richTextIsValid(this.state.formDescription);

    const translations = this.props.form.form!.translations;
    const translationValidity = translations.map((t) => ({
      translation: t,
      valid: translationIsValid(
        t,
        this.state.components,
        this.state.predicateComponents || []
      ),
    }));
    const invalidTranslations = translationValidity
      .filter((t) => !t.valid)
      .map((t) => t.translation.language);

    const invalidLanguages = [...invalidTranslations];
    if (!componentsValid) invalidLanguages.push(this.state.language);

    this.setState({
      translationErrors: invalidLanguages,
    });

    if (!(componentsValid && formMetaValid)) {
      const firstInvalidPredicate = this.state.components.find((comp) =>
        comp.questions.some((q) =>
          q.predicates?.some(
            (p) => p.componentId === nonValidComponents[1][0]?.id
          )
        )
      );
      const firstInvalidComponent =
        nonValidComponents[0][0]?.id || firstInvalidPredicate?.id;

      const editingComponent = !formMetaValid
        ? ({ editingHeaderComponent: true } as Pick<
            State,
            "editingHeaderComponent"
          >)
        : firstInvalidComponent
        ? ({ componentBeingEdited: firstInvalidComponent } as Pick<
            State,
            "componentBeingEdited"
          >)
        : ({} as {});
      this.setState({
        validateForm: true,
        translationLanguage: null,
        ...editingComponent,
      });

      return showAlertDialog({
        title: this.props.tr("createFormPublishNotComplete"),
        message: this.props.tr("createFormPublishNotCompleteMessage"),
        proceedText: defaultDialogProceed(this.props.tr),
        cancelText: defaultDialogCancel(this.props.tr),
      });
    }

    if (!translationValidity.every((v) => v.valid)) {
      const firstInvalidLang = invalidTranslations[0]
        ? { translationLanguage: invalidTranslations[0] }
        : {};
      this.setState({
        validateForm: true,
        ...firstInvalidLang,
      });
      return showAlertDialog({
        title: this.props.tr("createFormTranslationsIncompleteTitle"),
        message: this.props.tr(
          "createFormTranslationsIncompleteDescription",
          invalidTranslations.join(", ")
        ),
        proceedText: defaultDialogProceed(this.props.tr),
        cancelText: defaultDialogCancel(this.props.tr),
      });
    }

    const allComponentsDescribed = this.state.components.every((c) =>
      richTextIsValid(c.description)
    );
    const confirmed = await showConfirmDialog({
      title: this.props.tr("createFromAcceptFormTitle"),
      content: (
        <React.Fragment>
          <p>{this.props.tr("createFormAcceptPublicationMessage")}</p>
          {!allComponentsDescribed && (
            <div className="alert alert-warning">
              {this.props.tr("createFormNotAllComponentsDescribed")}
            </div>
          )}
        </React.Fragment>
      ),
      proceedText: this.props.tr("accept"),
      cancelText: defaultDialogCancel(this.props.tr),
    });
    if (confirmed) {
      const form = this.props.form.form!;

      // we remove all dangling predicate components (predicate components without predicates)
      const allPredsFromData = allPredicatesFromData(this.state);
      this.setState({
        predicateComponents: cleanPCs(
          this.state.components,
          (this.state.predicateComponents || []).filter((pc) =>
            allPredsFromData.find((p) => p.componentId === pc.id)
          )
        ),
      });

      await this.props
        .lockForm({
          variables: { id: form.id },
        })
        .catch((e) => {
          showAlertDialog({
            title: this.props.tr("createFormFailedToLockFormTitle"),
            message: this.props.tr("createFormFailedToLockFormDescription"),
            proceedText: defaultDialogProceed(this.props.tr),
            cancelText: defaultDialogCancel(this.props.tr),
          });
          this.setState({ savingState: SavingState.Failed });
          throw e;
        });
      this.props.navigate(links.admin.form.preview(form.id));
    }
  };

  private attemptGetTemplateId(c: unknown): string | null {
    return (c && (c as any).templateId) || null;
  }

  // Build the components explicitly to make sure types are right
  private getComponents = (
    components: ComponentsState,
    predicateComponents: PredicateComponentsState | null
  ): gqltypes.ComponentDataInput => {
    const mappedComponents: gqltypes.ComponentInput[] = [];
    for (const c of components) {
      mappedComponents.push({
        title: c.title,
        applicationFeedbackId: c.applicationFeedbackId,
        description: c.description,
        id: c.id,
        language: c.language,
        templateId: this.attemptGetTemplateId(c),
        questions: getComponentQuestions(c),
        permission: c.permission,
        sensitive: c.sensitive,
        attachments: c.attachments,
      });
    }
    const withoutTypename = deepOmitArray(
      mappedComponents,
      (key) => key === "__typename"
    );

    return predicateComponents
      ? {
          components: withoutTypename,
          predicateComponents: cleanPCs(
            withoutTypename,
            predicateComponents
          ).map(gqltypes.strippers.FormPredicateComponentInput),
        }
      : { components: withoutTypename };
  };

  private updateTranslation = async (
    language: gqltypes.ISO6391Language = this.state.translationLanguage!
  ) => {
    const form = this.props.form && this.props.form.form;
    if (!form) {
      throw new Error(`Could not find form`);
    }
    const translation = this.state.translationForms[language];
    if (!translation) {
      throw new Error(`Could not find translations for language ${language}`);
    }

    const components = deepOmitArray(
      translation.componentData.components,
      (key) => key === "__typename"
    ).map((c) => {
      const description = c.description || "";
      return { ...c, description };
    });

    const predicateComponents = translation.componentData.predicateComponents;

    const input: gqltypes.UpdateFormTranslationInput = {
      formId: form.id,
      language,
      name: translation.name,
      description: translation.description || "",
      componentData: predicateComponents
        ? {
            components,
            predicateComponents: predicateComponents.map(
              gqltypes.strippers.FormPredicateComponentInput
            ),
          }
        : { components },
    };

    return this.props
      .updateFormTranslation({
        variables: { input, context: this.props.adminContext },
      })
      .then((data) => {
        this.props.form.refetch();
        return data;
      });
  };

  private updateAllTranslations = async () => {
    const inputs: gqltypes.UpdateFormTranslationInput[] = [];

    const form = this.props.form && this.props.form.form;
    if (!form) {
      throw new Error(`Could not find form`);
    }

    Object.keys(this.state.translationForms).forEach((language) => {
      const translation = this.state.translationForms[language];
      if (!translation) {
        throw new Error(`Could not find translations for language ${language}`);
      }

      const components = deepOmitArray(
        translation.componentData.components,
        (key) => key === "__typename"
      );

      const input: gqltypes.UpdateFormTranslationInput = {
        formId: form.id,
        language: language as gqltypes.ISO6391Language,
        name: translation.name,
        description: translation.description || "",
        componentData: {
          components: components.map((c) => {
            const description = c.description || "";
            return { ...c, description };
          }),
        },
      };
      inputs.push(input);
    });

    return this.props
      .updateFormTranslations({
        variables: { input: inputs, context: this.props.adminContext },
      })
      .then((data) => {
        this.props.form.refetch();
        return data;
      });
  };

  private debouncedUpdateAllTranslations = _.debounce(
    this.updateAllTranslations,
    1000
  );

  private save = async () => {
    const input: gqltypes.SaveFormInput = {
      id:
        this.props.form && this.props.form.form
          ? this.props.form.form.id
          : undefined,
      type: this.state.formType,
      title: this.state.formTitle,
      description: this.state.formDescription,
      componentData: this.getComponents(
        this.state.components,
        this.state.predicateComponents
      ),
      language: this.state.language,
      tags: this.state.tags,
      subType: this.state.formSubType,
      access: this.state.access,
    };
    const context = this.props.adminContext;

    // TODO: rensa bort oanvända predicates

    if (this.editExistingForm) {
      this.props.recordFormHistory({
        variables: {
          context,
          input: {
            isFavorite: false,
            type: gqltypes.RecordHistoryType.form,
            label: input.title,
            data: {
              formId: input.id!,
            },
          },
        },
      });
    }

    this.setState({ savingState: SavingState.Saving });
    return this.props.saveForm({
      variables: {
        input,
        context,
      },
    });
  };

  private onSaveDone = (result: ApolloQueryResult<gqltypes.SaveForm>) => {
    if (result.errors) {
      this.setState({ savingState: SavingState.Failed });
      return;
    }
    const savedForm = result.data.saveForm;
    const { __typename, ...savedAccess } = savedForm.access;
    this.setState({
      savingState: SavingState.Saved,
      components: savedForm.componentData.components,
      formType: savedForm.type,
      formTitle: savedForm.name,
      formDescription: savedForm.description || "",
      access: {
        ...savedAccess,
        schoolUnits: savedAccess.schoolUnits
          .filter(definedNotNull)
          .map((schoolUnit) => ({
            id: schoolUnit.id,
            customerId: schoolUnit.customerId,
          })),
      },
    });
    if (!this.editExistingForm) {
      this.props.navigate(links.admin.form.edit(result.data.saveForm.id));
    }
  };

  private onSaveError = (err: ApolloError) => {
    this.setState({ savingState: SavingState.Failed });
    let message = this.props.tr("genericSaveError");
    if (err && err.message) {
      switch (err.message) {
        case "This operation would prevent anyone from seeing this form.":
          message = this.props.tr("changeFormPermissionError");
          break;
      }
    }
    showErrorDialog({
      title: this.props.tr("error"),
      message: message,
      proceedText: defaultDialogProceed(this.props.tr),
      cancelText: defaultDialogCancel(this.props.tr),
    });
  };

  private autoSave = () => {
    if (this.autoSaveOn) {
      this.saveManager.save();
    }
  };

  private autoSaveTranslation = () => {
    if (this.autoSaveOn) {
      this.updateTranslationManager.save();
    }
  };

  private handleSaveNow = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    if (
      this.state.access.organisation === false &&
      this.state.access.public === false &&
      this.state.access.schoolUnits.length === 0
    ) {
      this.setState({ savingState: SavingState.Failed });
      showErrorDialog({
        title: this.props.tr("error"),
        message: this.props.tr("saveFormPermissionError"),
        proceedText: defaultDialogProceed(this.props.tr),
        cancelText: defaultDialogCancel(this.props.tr),
      });
    } else {
      return this.saveManager.saveNow();
    }
  };

  private handleChangeBooleanAccess = (type: string, checked: boolean) => {
    this.setState((state) => ({
      access: { ...state.access!, [type]: checked },
    }));
    if (this.editExistingForm) {
      this.saveManager.save();
    }
  };

  private handleChangeSchoolunitAccess = (
    schoolUnit: { id: string; customerId: string },
    checked: boolean
  ) => {
    if (!checked) {
      this.setState((state) => ({
        access: {
          ...state.access,
          schoolUnits: state.access.schoolUnits.filter(
            (schoolUnitAccess: IdCustomerIdInput) =>
              !(
                schoolUnit.id === schoolUnitAccess.id &&
                schoolUnit.customerId === schoolUnitAccess.customerId
              )
          ),
        },
      }));
    } else {
      this.setState((state) => ({
        access: {
          ...state.access,
          schoolUnits: state.access.schoolUnits.concat(schoolUnit),
        },
      }));
    }
    if (this.editExistingForm) {
      this.saveManager.save();
    }
  };

  private handleTranslationChangeFormTitle = (
    language: gqltypes.ISO6391Language,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    this.setState(
      (state) => ({
        translationForms: {
          ...state.translationForms,
          [language]: { ...state.translationForms[language]!, name: value },
        },
        savingState: SavingState.NotSaved,
      }),
      this.autoSaveTranslation
    );
  };

  private handleTranslationChangeFormDescription = (
    language: gqltypes.ISO6391Language,
    value: any
  ) => {
    this.setState(
      (state) => ({
        translationForms: {
          ...state.translationForms,
          [language]: {
            ...state.translationForms[language]!,
            description: value,
          },
        },
        savingState: SavingState.NotSaved,
      }),
      this.autoSaveTranslation
    );
  };

  private handleChangeFormDescription = (value: any) => {
    this.setState({
      formDescription: value,
      savingState: SavingState.NotSaved,
    });

    this.autoSave();
  };

  private handleChangeFormTitle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    this.setState({ formTitle: value, savingState: SavingState.NotSaved });
    this.autoSave();
  };
}

const withLockForm = graphql<
  Props,
  gqltypes.LockForm,
  gqltypes.LockFormVariables
>(
  gql`
    mutation LockForm($id: ID!) {
      lockForm(id: $id) {
        ...FormMetadata
      }
    }
    ${formMetadataFragment}
  `,
  {
    name: "lockForm",
    options: (props) => ({
      refetchQueries: [
        {
          query: sendableFormsQuery,
          variables: {
            context: props.adminContext,
          },
        },
      ],
    }),
  }
);

const withTemplateData = graphql<gqltypes.templates, object>(
  gql`
    query templates {
      templates {
        id
        language
        componentData {
          id
          title
          description
          sensitive
          permission
          questions {
            ...FullComponentQuestion
          }
          attachments {
            name
            key
            url
          }
        }
        translations {
          id
          language
          published
          componentData {
            id
            title
            description
            questions {
              id
              question
              shortName
              options {
                id
                label
              }
            }
            attachments {
              name
              key
              url
            }
          }
        }
      }
    }
    ${fullFormQuestionFragment}
  `,
  { name: "templateData" }
);

const withFormData = graphql<
  WithRouteProps<{ params: { id: string } }> & AdminContext
>(
  gql`
    query form($id: ID!, $context: Context!) {
      form(id: $id, context: $context) {
        id
        type
        subType
        archived
        locked
        name
        description
        language
        translationLanguages
        tags
        componentData {
          ...FormComponentData
        }
        access {
          ...FullFormAccess
        }
        owner {
          id
          displayName
        }
        translations {
          ...FullTranslation
        }
      }
    }
    ${formComponentDataFragment}
    ${fullFormAccessFragment}
    ${fullFormTranslationFragment}
  `,
  {
    name: "form",
    options: (props) => ({
      variables: { id: props.params.id, context: props.adminContext },
    }),
  }
);

const withSaveForm = graphql<
  WithRouteProps<{ id: string }> & AdminContext,
  gqltypes.SaveForm
>(
  gql`
    mutation SaveForm($input: SaveFormInput!, $context: Context!) {
      saveForm(input: $input, context: $context) {
        id
        type
        name
        description
        modified
        language
        tags
        componentData {
          ...FormComponentData
        }
        access {
          ...FullFormAccess
        }
      }
    }
    ${formComponentDataFragment}
    ${fullFormAccessFragment}
  `,
  {
    name: "saveForm",
  }
);

const withUpdateTranslation = graphql<
  WithRouteProps<{ id: string }> & AdminContext,
  gqltypes.UpdateFormTranslation
>(
  gql`
    mutation UpdateFormTranslation(
      $input: UpdateFormTranslationInput!
      $context: Context!
    ) {
      updateFormTranslation(input: $input, context: $context) {
        ...FullTranslation
      }
    }
    ${fullFormTranslationFragment}
  `,
  {
    name: "updateFormTranslation",
  }
);

const withUpdateTranslations = graphql<
  WithRouteProps<{ id: string }> & AdminContext,
  gqltypes.UpdateFormTranslations
>(
  gql`
    mutation UpdateFormTranslations(
      $input: [UpdateFormTranslationInput!]!
      $context: Context!
    ) {
      updateFormTranslations(input: $input, context: $context) {
        ...FullTranslation
      }
    }
    ${fullFormTranslationFragment}
  `,
  {
    name: "updateFormTranslations",
  }
);

export const CreateForm = _.flowRight(
  withTranslation,
  withAdminContext,
  withRouter,
  withSaveForm,
  withLockForm,
  withTemplateData,
  withCurrentOrg
)(CreateEditForm);

export const EditForm = _.flowRight(
  withTranslation,
  withAdminContext,
  withRouter,
  withFormData,
  withLockForm,
  withRecordFormHistory,
  withSaveForm,
  withTemplateData,
  withUpdateTranslation,
  withUpdateTranslations,
  withCurrentOrg,
  withUserPermissions
)(CreateEditForm);
