import gql from "graphql-tag";
import _ from "lodash";
import * as React from "react";
import Dropzone, { Accept, FileRejection } from "react-dropzone";
import FlipMove from "react-flip-move";
import { Rest } from "../../Admin/components/CreateForm";
import {
  defaultQuestionType,
  getComponentQuestions,
  makeQuestion,
} from "../../Admin/form";
import { Translation, withTranslation } from "../../App/reducer";
import {
  showAlertDialog,
  showConfirmDialog,
} from "../../Utils/dialogs/showDialog";
import * as f from "../../Utils/functional";
import { definedNotNull, last, move } from "../../Utils/functional";
import { getAdminContext, client as graphqlClient } from "../../api";
import curry from "../../curry";
import * as gqltypes from "../../gqltypes";
import {
  formComponentPermissions,
  getComponentPermissionDisplayName,
} from "../../permissions";
import { getTr } from "../../translation";
import {
  defaultDialogCancel,
  defaultDialogProceed,
} from "../../translation/strings";
import {
  componentIsValid,
  predicateComponentIsValid,
  translationPredicateComponentIsValid,
} from "../validation";
import { PermissionsTooltip } from "./PermissionsTooltip";
import { RTViewer } from "./RTViewer";
import {
  Checkbox,
  FormComponentQuestionEditor,
  FormInput,
  FormSelect,
  RTEditor,
} from "..";
import { getSettings } from "../../settings";

const settings = getSettings();

// TODO: If T = FormComponent, then IT should be ComponentInput
export interface CommonComponentProps<
  T extends gqltypes.FormComponent | gqltypes.FormPredicateComponent,
  IT extends gqltypes.ComponentInput | gqltypes.FormPredicateComponentInput
> {
  component: T;
  updateComponent: (componentData: IT) => void;
  predicateComponentCreator?: Rest<
    gqltypes.FormPredicateComponent,
    gqltypes.FormPredicateComponentInput,
    gqltypes.TranslatePredicateComponent
  >;
  translationLanguage?: gqltypes.ISO6391Language | null;
  disabled?: boolean;
}

export function getData(
  component: gqltypes.FormComponent
): gqltypes.ComponentInput & {
  attachments: gqltypes.ComponentAttachmentInput[];
} {
  return {
    id: component.id,
    title: component.title,
    applicationFeedbackId: component.applicationFeedbackId,
    sensitive: component.sensitive,
    permission: component.permission,
    description: component.description || "",
    questions: getComponentQuestions(component),
    attachments: component.attachments || [],
  };
}

export const handleRemoveQuestion = async (
  question: gqltypes.FormQuestionInput,
  tr: any,
  data: { questions: gqltypes.FormQuestionInput[] },
  update: any
) => {
  const confirmed = await showConfirmDialog({
    title: tr("removeQuestion"),
    content: (
      <React.Fragment>
        <p>{tr("formComponentEditorRemoveQuestion", question.question)}</p>
        <p>{tr("formComponentEditorRemoveQuestionWarning")}</p>
      </React.Fragment>
    ),
    proceedText: tr("remove"),
    cancelText: defaultDialogCancel(tr),
  });

  if (!confirmed) {
    return;
  }

  f.throwWhenError(
    f.safePipe(
      f.update(data, ["questions"], (qs) =>
        qs.filter((q: gqltypes.FormQuestion) => q.id !== question.id)
      ),
      update
    )
  );
};

export function moveQuestion<T extends { questions: any[] }>(
  data: T,
  update: (data: T) => void,
  questionId: string,
  indexIncrement: number
) {
  const questions = data.questions;
  const index = questions.findIndex((q) => q.id === questionId);
  if (index < 0) {
    throw new Error("Could not find index of question");
  }
  data.questions = move(questions, index, index + indexIncrement);
  update(data);
}

export const renderUpAction = (
  questionId: string,
  disabled: boolean,
  moveQuestionFn: (questionId: string, indexIncrement: number) => void
) => {
  return (
    <i
      onClick={() => !disabled && moveQuestionFn(questionId, -1)}
      className={"fas fa-arrow-up icon-button" + (disabled ? " disabled" : "")}
    />
  );
};

export const renderDownAction = (
  questionId: string,
  disabled: boolean,
  moveQuestionFn: (questionId: string, indexIncrement: number) => void
) => {
  return (
    <i
      onClick={() => !disabled && moveQuestionFn(questionId, 1)}
      className={
        "fas fa-arrow-down icon-button" + (disabled ? " disabled" : "")
      }
    />
  );
};

export const renderActionsContainer = (
  question: gqltypes.FormQuestionInput,
  index: number,
  numQuestions: number,
  disabled: boolean | undefined,
  moveQuestionFn: (questionId: string, indexIncrement: number) => void,
  handleRemoveQuestionFn: (question: gqltypes.FormQuestionInput) => void
) => {
  const isFirst = index === 0;
  const isLast = index === numQuestions - 1;
  return (
    <div className="question-actions col-auto ml-auto">
      <label className="label-placeholder" />
      <div>
        {renderUpAction(question.id, disabled || isFirst, moveQuestionFn)}
        {renderDownAction(question.id, disabled || isLast, moveQuestionFn)}
        <i
          onClick={
            disabled ? undefined : () => handleRemoveQuestionFn(question)
          }
          className={`fas fa-trash icon-button ${disabled ? "disabled" : ""}`}
        />
      </div>
    </div>
  );
};

export const handleUpdateTranslateQuestion = <
  T extends { questions: gqltypes.FormTranslateQuestion[] }
>(
  inData: T,
  id: string,
  updatedQuestion: gqltypes.FormTranslateQuestion,
  doWhenDone: (v: T) => void
) => {
  const updateQuestion = (qs: gqltypes.FormTranslateQuestion[]) => {
    const updatedQs = f.replace(qs, (q) => q.id === id, updatedQuestion);
    if (undefined === updatedQs) {
      qs.push({
        shortName: "",
        options: f.throwWhenError(
          f.safePipe(
            inData.questions,
            (qss) => qss.find((q) => q.id === id),
            (q) => q.options,
            (os) => os.map((o) => ({ id: o.id, label: "" }))
          )
        ),
        ...updatedQuestion,
      });
      return qs;
    } else {
      return updatedQs;
    }
  };

  const res = f.safePipe(
    f.update(inData, ["questions"], updateQuestion),
    (data) => data,
    doWhenDone
  );

  f.throwWhenError(res);
};

export function handleUpdateQuestion<
  T extends { questions: gqltypes.FormQuestion[] }
>(
  inData: T,
  id: string,
  updatedQuestion: gqltypes.FormQuestionInput,
  doWhenDone: (v: T) => void
) {
  const updateQuestion = (qs: gqltypes.FormQuestion[]) =>
    f.undefinedToError(
      f.replace(qs, (q) => q.id === id, updatedQuestion),
      new Error("Misslyckades med att uppdatera fråga.")
    );

  f.throwWhenError(
    f.safePipe(
      inData,
      (data) =>
        f.update(data, ["questions"], (qs) =>
          updateQuestion(qs)
        ) as f.NotUndefinedNullError<T>,
      doWhenDone
    )
  );
}

export interface CommonFormEditorProps {
  translating: boolean;
  advancedMode: boolean;
  formLanguage: gqltypes.ISO6391Language;
}

interface Props
  extends Translation,
    CommonFormEditorProps,
    CommonComponentProps<gqltypes.FormComponent, gqltypes.ComponentInput> {
  actionBlock: JSX.Element;
  removeComponent?: (e: React.MouseEvent<HTMLDivElement>) => void;
  startEditingComponent?: (event: React.MouseEvent<HTMLElement>) => void;
  validateComponent?: boolean;
  translating: boolean;
  translation?:
    | gqltypes.FullTranslation["componentData"]["components"][0]
    | null;
  formType: gqltypes.FormType;
  advancedMode: boolean;
  updateTranslation: (
    translation: gqltypes.TranslateComponent,
    language: gqltypes.ISO6391Language
  ) => void;
}

interface State {
  dropzoneActive: boolean;
}

const allowedFileUploadTypesAccept: Accept = {
  "image/jpeg": [".jpeg"],
  "image/png": [".png"],
  "application/pdf": [".pdf"],
};
const allowedFileUploadTypesString = ".jpeg, .png, .pdf";
const allowedMaxSize = 10000000;
const allowedMaxSizeString = "10MB";

const fileOverlayStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  margin: 0,
  background: "rgba(0,0,0,0.5)",
  textAlign: "center",
  color: "#fff",
  zIndex: 20000,
} as any;

class FormComponentEditorInner extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dropzoneActive: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  public render() {
    const {
      tr,
      translating,
      translationLanguage,
      translation,
      formLanguage,
      component,
    } = this.props;

    const predicateComponents = this.props.predicateComponentCreator
      ? component.questions
          .reduce((predComponentIds, q) => {
            if (q.predicates) {
              predComponentIds.push(...q.predicates.map((p) => p.componentId));
            }
            return predComponentIds;
          }, [] as string[])
          .map((id) => ({
            trans:
              translating &&
              translation &&
              this.props.predicateComponentCreator!.getTranslation(id),
            base: this.props.predicateComponentCreator!.get(id),
          }))
      : [];

    const isValid =
      this.props.validateComponent &&
      componentIsValid(component) &&
      predicateComponents.every((c) =>
        c.trans
          ? translationPredicateComponentIsValid(c.trans, c.base)
          : predicateComponentIsValid(c.base)
      );

    const className =
      "form-section is-editing" +
      (!isValid && this.props.validateComponent ? " invalid" : "");

    const displayValues = translating
      ? {
          title: translation ? translation.title : "",
          description: translation ? translation.description : "",
          attachments: translation ? translation.attachments : [],
        }
      : {
          title: component.title,
          description: component.description,
          attachments: component.attachments || [],
        };

    const newComponentPlaceholder = tr("formComponentNewComponentPlaceholder");

    return (
      <Dropzone
        disabled={this.props.disabled}
        multiple={false}
        maxSize={allowedMaxSize}
        accept={allowedFileUploadTypesAccept}
        onDrop={this.handleFileUpload}
        onDragEnter={this.showDropzoneOverlay}
        onDragLeave={this.hideDropzoneOverlay}
        noClick
      >
        {({ open: openFileDialog, getRootProps, getInputProps }) => (
          <div
            {...getRootProps({
              onClick: (e) => {
                // e.preventDefault();
                if (this.props.startEditingComponent) {
                  this.props.startEditingComponent(e);
                }
              },
              className: className + " position-relative",
            })}
          >
            {this.state.dropzoneActive && (
              <div className={className} style={fileOverlayStyle}>
                {tr("formComponentEditorDropToUpload")}
              </div>
            )}
            <div className="header">
              <h3>{displayValues.title || newComponentPlaceholder}</h3>
              {this.props.actionBlock}
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 d-sm-flex align-items-sm-center">
                <Checkbox
                  id={this.props.component.id + "-sensitive-info"}
                  disabled={translating || this.props.disabled}
                  checked={this.props.component.sensitive}
                  onChange={this.handleChangeSensitive}
                  label={tr("formComponentEditorSensitiveInfoInComponent")}
                />
              </div>
              {this.props.formType === gqltypes.FormType.publication ? (
                <div className="col-12 col-sm-6">
                  <FormSelect
                    disabled={translating || this.props.disabled}
                    label={
                      <span id="taggingTooltip" data-toggle="tooltip">
                        {tr("tagging")}&nbsp;
                        <i className="fa fa-question-circle" />
                      </span>
                    }
                    value={this.props.component.permission || "read_base"}
                    onChange={(event) => {
                      this.handleChange(
                        "permission",
                        event.currentTarget
                          .value as gqltypes.FormComponentPermission
                      );
                    }}
                    options={Object.values(formComponentPermissions).map(
                      (key: gqltypes.FormComponentPermission) => ({
                        value: key,
                        label: getComponentPermissionDisplayName(
                          key,
                          getTr(this.props.uiLanguage)
                        ),
                      })
                    )}
                  />
                  <PermissionsTooltip tr={tr} target={"taggingTooltip"} />
                </div>
              ) : null}
            </div>
            <FormInput
              className="horizontal"
              value={displayValues.title}
              disabled={this.props.disabled}
              placeholder={newComponentPlaceholder}
              onChange={
                translating
                  ? this.handleTranslationChangeEvent
                  : this.handleChangeEvent
              }
              id="title"
              label={tr("formComponentEditorComponentTitleLabel")}
              feedback={this.getRequiredInputFeedback(displayValues.title)}
              subText={translating ? component.title : undefined}
            />
            <label className="form-input-label">
              {tr("formComponentEditorComponentDescriptionLabel")}
            </label>
            <RTEditor
              disabled={this.props.disabled}
              initialContentState={displayValues.description}
              toolbarLayout="mini"
              onChange={
                translating
                  ? this.handleChangeTranslationDescription
                  : this.handleChangeDescription
              }
            />
            {translating && (
              <div style={{ opacity: 0.5 }}>
                {this.props.formLanguage}:{" "}
                <RTViewer value={component.description} />
              </div>
            )}
            <hr />
            <FlipMove
              duration={350}
              easing="ease-out"
              appearAnimation={"none"}
              enterAnimation={"fade"}
              leaveAnimation={
                null as any /* workaround for animations at bottom of page */
              }
            >
              {this.props.component.questions.map((q, index) => {
                const hasOptions =
                  q.type === gqltypes.FormQuestionType.checkboxes ||
                  q.type === gqltypes.FormQuestionType.multiChoice ||
                  q.type === gqltypes.FormQuestionType.ranking;
                const questionTranslation = (translation &&
                  translation.questions.find((tq) => tq.id === q.id)) || {
                  id: q.id,
                  question: "",
                  shortName: "",
                  options: hasOptions ? [] : null,
                  __typename: "FormTranslateQuestion",
                };

                const removeQuestion = (question: gqltypes.FormQuestionInput) =>
                  handleRemoveQuestion(
                    question,
                    tr,
                    getData(this.props.component),
                    this.props.updateComponent
                  );

                return (
                  <FormComponentQuestionEditor
                    predicateComponentCreator={
                      this.props.predicateComponentCreator
                    }
                    tr={tr}
                    key={q.id}
                    uiLanguage={this.props.uiLanguage}
                    updateQuestion={(updatedQuestion) =>
                      handleUpdateQuestion(
                        getData(this.props.component),
                        q.id,
                        updatedQuestion,
                        this.props.updateComponent
                      )
                    }
                    updateTranslation={(updatedTranslation) => {
                      handleUpdateTranslateQuestion(
                        this.getTranslationData(),
                        q.id,
                        updatedTranslation,
                        (data) =>
                          this.props.updateTranslation(
                            data,
                            this.props.translationLanguage!
                          )
                      );
                    }}
                    disabled={this.props.disabled}
                    removeQuestion={removeQuestion}
                    data={q}
                    formLanguage={formLanguage}
                    validate={this.props.validateComponent}
                    actionsContainer={
                      translating
                        ? null
                        : renderActionsContainer(
                            q,
                            index,
                            this.props.component.questions.length,
                            this.props.disabled,
                            curry(
                              moveQuestion,
                              getData(this.props.component),
                              this.props.updateComponent
                            ),
                            removeQuestion
                          )
                    }
                    translation={questionTranslation}
                    translationLanguage={translationLanguage}
                    translating={translating}
                    advancedMode={this.props.advancedMode}
                  />
                );
              })}
            </FlipMove>

            {!translating && (
              <div className="clearfix">
                <span
                  className="float-right d-flex align-items-center clickable text-primary fat"
                  style={{ marginBottom: "20px" }}
                  onClick={this.handleAddQuestion}
                >
                  <span className="pr-3">
                    {tr("formComponentEditorAddQuestion")}
                  </span>
                  <i className="fas fa-plus" style={{ fontSize: "30px" }} />
                </span>
              </div>
            )}

            <h3>
              {tr("formAttachedFiles")}{" "}
              <i
                onClick={openFileDialog}
                className="fas fa-plus clickable"
                style={{ fontSize: "20px" }}
              />
            </h3>

            {displayValues.attachments && displayValues.attachments.length ? (
              <ul>
                {displayValues.attachments.map((attachment) => (
                  <li key={attachment.key}>
                    <a href={attachment.url} target="_blank" rel="noreferrer">
                      {attachment.name}
                    </a>{" "}
                    <i
                      className={`fas fa-trash icon-button ${
                        this.props.disabled ? "disabled" : ""
                      }`}
                      onClick={() =>
                        translating
                          ? this.handleRemoveTranslationAttachment(
                              attachment.key
                            )
                          : this.handleRemoveAttachment(attachment.key)
                      }
                    />
                  </li>
                ))}{" "}
              </ul>
            ) : (
              <span className="text-muted">
                {tr("formComponentEditorComponentAttachmentsUsageDescription")}
              </span>
            )}
            <input {...getInputProps()} disabled={this.props.disabled} />
            {translating &&
            component.attachments &&
            component.attachments.length ? (
              <React.Fragment>
                <label className="mt-2 text-muted">
                  {this.props.formLanguage}: {tr("attachments")}
                </label>
                <div
                  className="p-content"
                  style={{ opacity: 0.5, border: "1px solid black" }}
                >
                  <ul>
                    {component.attachments.map((attachment) => (
                      <li key={attachment.key}>
                        <a
                          href={attachment.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {attachment.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        )}
      </Dropzone>
    );
  }

  private getRequiredInputFeedback = (input?: string | null) => {
    if (!this.props.validateComponent) {
      return "";
    }
    if (!definedNotNull(input) || input === "") {
      return this.props.tr("validationAnswerCompulsoryButMissing");
    }
  };

  public getTranslationData = (): gqltypes.TranslateComponent => {
    if (!this.props.translation) {
      throw new Error("no translation");
    }
    return {
      id: this.props.translation.id,
      title: this.props.translation.title,
      description: this.props.translation.description || "",
      questions: _.cloneDeep(this.props.translation.questions),
      attachments: this.props.translation.attachments || [],
    };
  };

  private handleTranslationChangeEvent = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name as any;
    this.handleTranslationChange(name, value);
  };

  private handleTranslationChange<T extends keyof gqltypes.TranslateComponent>(
    name: T,
    value: gqltypes.TranslateComponent[T]
  ) {
    const data = this.getTranslationData();
    data[name] = value;
    this.props.updateTranslation(data, this.props.translationLanguage!);
  }

  private handleChangeTranslationDescription = (value: string) => {
    const data = this.getTranslationData();
    data.description = value;
    this.props.updateTranslation(data, this.props.translationLanguage!);
  };

  private handleChangeSensitive = (__: string, checked: boolean) => {
    const data = getData(this.props.component);
    data.sensitive = checked;
    this.props.updateComponent(data);
  };

  private handleAddAttachment = (obj: {
    name: string;
    key: string;
    url: string;
  }) => {
    const data = getData(this.props.component);
    const co = [...data.attachments];
    co.push(obj);
    this.props.updateComponent({ ...data, attachments: co });
  };

  private handleRemoveAttachment = (key: string) => {
    const data = getData(this.props.component);
    data.attachments = data.attachments.filter(
      (attachment) => attachment.key !== key
    );
    this.props.updateComponent(data);
  };

  private handleAddTranslationAttachment = (obj: {
    name: string;
    key: string;
    url: string;
  }) => {
    const data = this.getTranslationData();
    data.attachments.push(obj);
    this.props.updateTranslation(data, this.props.translationLanguage!);
  };

  private handleRemoveTranslationAttachment = (key: string) => {
    const data = this.getTranslationData();
    data.attachments = data.attachments.filter(
      (attachment) => attachment.key !== key
    );
    this.props.updateTranslation(data, this.props.translationLanguage!);
  };

  private handleAddQuestion = () => {
    const data = getData(this.props.component);
    const lastQuestion = last(data.questions);
    const questionType = lastQuestion ? lastQuestion.type : defaultQuestionType;
    data.questions.push(
      makeQuestion({
        type: questionType,
        customOrder: data.questions.length + 1,
      })
    );
    this.props.updateComponent(data);
  };

  private handleChangeEvent = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name as any;
    this.handleChange(name, value);
  };

  private handleChange<T extends keyof gqltypes.ComponentInput>(
    name: T,
    value: gqltypes.ComponentInput[T]
  ) {
    const data = getData(this.props.component);
    data[name] = value as any; // TODO: Fixa detta
    this.props.updateComponent(data);
  }

  private handleChangeDescription = (value: string) => {
    const data = getData(this.props.component);
    data.description = value;
    this.props.updateComponent(data);
  };

  private handleFileUpload = async (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[]
  ) => {
    if (rejectedFiles.length) {
      await showAlertDialog({
        title: this.props.tr("formFailedAttachmentUploadTitle"),
        message: this.props.tr(
          "formFailedAttachmentUploadDescription",
          allowedFileUploadTypesString,
          allowedMaxSizeString
        ),
        proceedText: defaultDialogProceed(this.props.tr),
        cancelText: defaultDialogCancel(this.props.tr),
      });
      this.hideDropzoneOverlay();
      return;
    }
    acceptedFiles.forEach(async (file) => {
      const res = (await graphqlClient.mutate({
        mutation: gql`
          mutation SignFormFormUpload(
            $input: SignFormUploadInput!
            $context: Context!
          ) {
            signFormUpload(input: $input, context: $context) {
              key
              signedUrl
              post_headers {
                key
                value
              }
            }
          }
        `,
        variables: {
          input: {
            filename: file.name,
            contentLength: file.size,
            contentType: file.type,
          } as gqltypes.SignFormFormUploadVariables["input"],
          context: getAdminContext(),
        },
        fetchPolicy: "no-cache",
      })) as { data: gqltypes.SignFormFormUpload };

      if (!res) {
        await showAlertDialog({
          title: this.props.tr("formFailedAttachmentUploadTitle"),
          message: this.props.tr(
            "formFailedAttachmentUploadDescription",
            allowedFileUploadTypesString,
            allowedMaxSizeString
          ),
          proceedText: defaultDialogProceed(this.props.tr),
          cancelText: defaultDialogCancel(this.props.tr),
        });
        console.error("Failed to get signed upload url from api");
        this.hideDropzoneOverlay();
        return;
      }

      const formData = new FormData();

      res.data.signFormUpload.post_headers.forEach((header) => {
        formData.append(header.key, header.value);
      });
      formData.append("file", file);

      const req = new Promise<any>((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.addEventListener("error", reject);
        request.addEventListener("readystatechange", () => {
          const validStatus = request.status >= 200 && request.status < 300;

          if (request.readyState !== 4) {
            return;
          }
          if (validStatus) {
            resolve(request.response);
          } else {
            reject();
          }
        });
        request.open("POST", settings.s3ApiUrl);
        request.send(formData);
      });
      req
        .then(() => {
          const options = {
            name: file.name,
            key: res.data.signFormUpload.key,
            url: res.data.signFormUpload.signedUrl,
          };
          this.props.translating
            ? this.handleAddTranslationAttachment(options)
            : this.handleAddAttachment(options);
          // console.log("done upload");
          this.hideDropzoneOverlay();
        })
        .catch(() => {
          console.log("upload failed");
          this.hideDropzoneOverlay();
        });
    });
  };

  private showDropzoneOverlay = () => {
    this.setState({ dropzoneActive: true });
  };

  private hideDropzoneOverlay = () => {
    this.setState({ dropzoneActive: false });
  };
}

export const FormComponentEditor = withTranslation(FormComponentEditorInner);
