import * as React from "react";
import * as ReactRedux from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Spinner from "react-spinkit";
import { Dispatch } from "redux";
import UAParser from "ua-parser-js";
import * as appActions from "../App/actions";
import { Button } from "../Common";
import { StoreState } from "../store";
import { getTr } from "../translation";
import { OIDCUser } from "../types";
import adminImg from "./admin.jpg";
import guardianImg from "./intro.jpg";
import skolidLogo from "./skolid_small_logo.png";

const uaParser = new UAParser();
const browser: { name: string; version: string } = uaParser.getBrowser();
let browserMajorVersion: number | null = null;

const isSiteDown = false;

// All versions and below with name
const unsupported = [{ name: "IE", version: 10 }];
let browserNotSupported = false;

try {
  browserMajorVersion = parseInt(browser.version.split(".")[0], 10);
  browserNotSupported = unsupported.some(
    (unsup) =>
      unsup.name === browser.name &&
      browserMajorVersion !== null &&
      browserMajorVersion <= unsup.version
  );
} catch (e) {
  console.error("failed to parse browser verison", browser);
}

interface StateProps {
  user: OIDCUser | null;
  isAuthenticating: boolean;
  cookiesUnavailable: boolean;
  timeSkewError: boolean;
  uiLanguage: string;
  idp: string;
}

interface OutProps {
  mode: "admin" | "user";
}

export interface Props extends StateProps, OutProps {
  dispatch: Dispatch<any>;
}

class StartView extends React.PureComponent<Props> {
  public render() {
    const {
      isAuthenticating,
      cookiesUnavailable,
      timeSkewError,
      uiLanguage,
      idp,
    } = this.props;

    return (
      <LoginBox
        isAuthenticating={isAuthenticating}
        onAuthenticate={this.onAuthenticate}
        cookiesUnavailable={cookiesUnavailable}
        timeSkewError={timeSkewError}
        uiLanguage={uiLanguage}
        mode={this.props.mode || "user"}
        idp={idp}
        setIdp={(newIdp) => {
          this.props.dispatch(appActions.changeIdp(newIdp));
        }}
      />
    );
  }

  private onAuthenticate = () => {
    this.props.dispatch(appActions.loginRequested());
  };
}

const LoginBox = (props: {
  isAuthenticating: boolean;
  cookiesUnavailable: boolean;
  timeSkewError: boolean;
  uiLanguage: string;
  onAuthenticate: () => void;
  mode: "admin" | "user";
  idp: string;
  setIdp: (idp: string) => void;
}) => {
  const [open, setOpen] = React.useState(false);
  const reason = new URLSearchParams(useLocation().search).get("reason");

  const tr = getTr(props.uiLanguage);
  const content = {
    user: {
      img: guardianImg,
      left: {
        header: tr("startGuardianLeftTitle"),
        bread: [tr("startGuardianLeftBread1"), tr("startGuardianLeftBread2")],
      },
      right: {
        header: tr("startGuardianRightTitle"),
        bread: [tr("startGuardianRightBread1")],
      },
    },
    admin: {
      img: adminImg,
      left: {
        header: tr("startAdminLeftTitle"),
        bread: [tr("startAdminLeftBread1"), tr("startAdminLeftBread2")],
      },
      right: {
        header: tr("startAdminRightTitle"),
        bread: [tr("startAdminRightBread1"), tr("startAdminRightBread2")],
      },
    },
  };

  return (
    <main>
      <div>
        <div className="box" style={{ overflow: "hidden" }}>
          <div
            style={{
              minHeight: "300px",
              overflow: "hidden",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url('${content[props.mode].img}')`,
            }}
          />
          <div className="row p-content">
            <div className="col-12 col-md-6 p-2 p-sm-4">
              <h2>{content[props.mode].left.header}</h2>
              {content[props.mode].left.bread.map((msg, i) => (
                <p key={i}>{msg}</p>
              ))}
            </div>
            <div className="col-12 col-md-6 p-2 p-sm-4">
              <h2>{content[props.mode].right.header}</h2>
              {content[props.mode].right.bread.map((msg, i) => (
                <p key={i}>{msg}</p>
              ))}
              <hr />
              <div
                className={`d-flex justify-content-${
                  props.mode === "admin" ? "between" : "end"
                }`}
              >
                {props.mode === "admin" && (
                  <div>
                    <Link
                      to="/"
                      className="btn btn-secondary d-flex align-items-center"
                    >
                      <div style={{ height: 32, width: 1 }} />
                      {tr("gotoParentLogin")}
                    </Link>
                  </div>
                )}
                {props.isAuthenticating ? (
                  <Spinner name="three-bounce" fadeIn="none" />
                ) : (
                  <div className="">
                    <Button
                      level="primary"
                      label={tr("loginButtonLabel")}
                      icon={
                        !props.idp ? (
                          <img src={skolidLogo} alt="Logo" height={32} />
                        ) : (
                          <img height={32} width={1} alt="Logo" />
                        )
                      }
                      // Workaround for spacing
                      backIcon={
                        props.idp ? (
                          <img
                            height={32}
                            width={1}
                            alt={tr("loginButtonLabel")}
                          />
                        ) : undefined
                      }
                      disabled={
                        props.isAuthenticating ||
                        props.cookiesUnavailable ||
                        isSiteDown
                      }
                      onClick={props.onAuthenticate}
                    />

                    {props.idp ? (
                      <>
                        <br />
                        <Button
                          className="pl-0"
                          label={tr("loginWithSkolID")}
                          onClick={() => {
                            props.setIdp("");
                            props.onAuthenticate();
                          }}
                          level="link"
                          disabled={
                            props.isAuthenticating ||
                            props.cookiesUnavailable ||
                            isSiteDown
                          }
                        />
                      </>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {reason === "session_timeout" && (
          <div className="alert alert-warning">
            {tr("sessionTimedOutWarning")}
          </div>
        )}
        {isSiteDown && (
          <div className="alert alert-warning">{tr("startServiceDown")}</div>
        )}
        {browserNotSupported && (
          <div className="alert alert-danger">
            {tr("startBrowserUnsupported")}
            <br />
            {tr("startBrowserUnsupportedHelp")}
          </div>
        )}
        {props.cookiesUnavailable && (
          <div className="alert alert-danger">
            {tr("startCookiesDisabled")}
            <br />
            {tr("startCookiesDisabledHelp")}
          </div>
        )}
        {props.timeSkewError && (
          <div className="alert alert-danger">
            {tr("startClockIsOff")}
            <p className="mt-content">
              {tr("startClockIsOffHelp1")}
              <br />
              {tr("startClockIsOffHelp2")}
            </p>
          </div>
        )}
      </div>
    </main>
  );
};

export const StartViewContainer = ReactRedux.connect<
  StateProps,
  any,
  OutProps,
  StoreState
>((state) => ({
  user: state.app.user,
  isAuthenticating: state.app.isAuthenticating,
  cookiesUnavailable: state.app.cookiesUnavailable,
  timeSkewError: state.app.timeSkewError,
  uiLanguage: state.app.context.language,
  idp: state.app.idp,
}))(StartView);
