import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { FixedBottom } from "./FixedBottom";

interface Props {
  show: boolean;
  displayOutside?: boolean;
  alignmentRef: any;
  children: React.ReactNode;
  marginX?: number;
}

export const BottomMenu = (props: Props) => {
  const { marginX = 100, displayOutside = false } = props;

  const [isOutside, setIsOutside] = useState(false);
  const internalIsOutside = useRef(isOutside);

  const width = Math.max(
    marginX * 2 + 200,
    (props.alignmentRef?.current?.offsetWidth || 0) - marginX * 2
  );

  const calculateIsOutside = _.throttle(() => {
    const alignmentRefBoundingBox =
      props.alignmentRef?.current?.getBoundingClientRect();

    if (!alignmentRefBoundingBox) return;

    const outside = alignmentRefBoundingBox.bottom < window.innerHeight;

    if (internalIsOutside.current !== outside) {
      internalIsOutside.current = outside;
      setIsOutside(outside);
    }
  }, 200);

  useEffect(() => {
    window.addEventListener("scroll", calculateIsOutside);

    return () => {
      // Throttled calls may be scheduled before the component unmounts
      calculateIsOutside.cancel();
      window.removeEventListener("scroll", calculateIsOutside);
    };
    // TODO: Seems to work fine but might want to fix this lint issue some day
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const show = props.show && (displayOutside ? true : !isOutside);

  return (
    <FixedBottom offset={0}>
      <div
        className="box pb-0"
        style={{
          width,
          marginLeft: marginX + "px",
          marginRight: marginX + "px",
          marginBottom: 15,
          boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.4)",

          // Animated appear/disappear
          transform: show ? "scaleY(1)" : "scaleY(0)",
          transformOrigin: "bottom",
          transition: "transform 0.26s ease",
        }}
      >
        {props.children}
      </div>
    </FixedBottom>
  );
};
