import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Collapse } from "reactstrap";
import { links } from "../../Admin/links";
import { OrgLogo } from "../../Common/components/OrgLogo";
import { Breakpoint, useMediaWidth } from "../../Common/mediaWidth";
import { definedNotNull } from "../../Utils/functional";
import { UserPermissions } from "../../permissions";
import * as persistance from "../../persistance";
import { getSettings } from "../../settings";
import { getAvailableUiLanguages, languageNames } from "../../translation";
import { OIDCUser } from "../../types";
import * as action from "../actions";
import { AvailableOrg, useCurrentOrg, useTranslation } from "../reducer";
import { userManager } from "../userManager";
import { MenuItem } from "./SideMenu";
import consentImgSE from "./formular_logo.png";
import consentImgNO from "./saksflyt-logo.png";
import { InfoCaption } from "./InfoCaption";
import { useEffect } from "react";

const settings = getSettings();
const inDevOrLocal =
  settings.releaseStage === "local" || settings.releaseStage === "dev";
const inDemo = settings.releaseStage !== "production";

export interface HeaderProps {
  user: OIDCUser | null;
  userPermissions: UserPermissions | null;
  currentOrg: string | null;
  availableOrgs: AvailableOrg[] | null;
  dispatch: (...args: any[]) => any;
  onLogin: () => void;
  onLogout: () => void;
  menuItems: MenuItem[];
  skipPermissionCheck?: boolean;
}

const calculateHeadersAndSubItems = (
  menuItems: MenuItem[],
  userPermissions: HeaderProps["userPermissions"]
) => {
  const itemsUnderHeader: { header: MenuItem; items: MenuItem[] }[] = [];

  menuItems.forEach((item, i) => {
    const isHeader = item.type === "header";

    if (isHeader) {
      itemsUnderHeader.push({ header: item, items: [] });
    } else if (item.url) {
      if (
        userPermissions === null ||
        (item.somePermission &&
          !item.somePermission.some((perm) => userPermissions!.some[perm])) ||
        (item.orgPermission && !userPermissions.org[item.orgPermission]) ||
        (item.onlyInDemo && !inDemo)
      ) {
        return null;
      }

      if (itemsUnderHeader.length === 0) {
        itemsUnderHeader.push({ header: menuItems[0], items: [] });

        itemsUnderHeader[itemsUnderHeader.length - 1].items.push(item);
      } else {
        itemsUnderHeader[itemsUnderHeader.length - 1].items.push(item);
      }
    }
  });

  return itemsUnderHeader;
};

const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: React.RefObject<T>,
  handler: (event: MouseEvent) => void
) => {
  React.useEffect(() => {
    const listener = (event: MouseEvent) => {
      const el = ref?.current;
      if (!el || el.contains((event?.target as Node) || null)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("click", listener);
    return () => {
      document.removeEventListener("click", listener);
    };
  }, [ref, handler]);
};
const HeaderInner = (props: HeaderProps) => {
  const { tr, uiLanguage } = useTranslation();
  const location = useLocation();
  const wrapperRef = React.useRef(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [openSubMenus, setOpenSubMenus] = React.useState<string[]>([]);
  const adminPortal = location.pathname.substring(0, 6) === "/admin";
  const superAdminPortal = location.pathname.substring(0, 11) === "/superadmin";
  const { userPermissions, skipPermissionCheck } = props;
  const [breakpoint] = useMediaWidth();
  const mobileMenu = breakpoint < Breakpoint.lg;

  React.useEffect(() => {
    setMenuOpen(false);
    setOpenSubMenus([]);
  }, [location.pathname]);
  React.useEffect(() => {
    setOpenSubMenus([]);
  }, [menuOpen]);

  const clickOutsidehandler = () => {
    if (menuOpen === true) setMenuOpen(false);
  };
  useOnClickOutside(wrapperRef, clickOutsidehandler);
  const headersAndItems = React.useMemo(
    () => calculateHeadersAndSubItems(props.menuItems, props.userPermissions),
    [props.menuItems, props.userPermissions]
  );
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-white"
      style={{
        position: "fixed",
        top: "0",
        width: "100%",
        zIndex: 10000,
        boxShadow: "0px 2px 10px 0px rgb(0 0 0 / 10%)",
      }}
    >
      <div className="container" style={{ maxWidth: "100%" }}>
        <Link
          className="navbar-brand p-0"
          to={adminPortal ? "/admin" : superAdminPortal ? "/superadmin" : "/"}
        >
          <OrgLogo
            id={props.currentOrg || ""}
            skipPrimary={!adminPortal}
            fallbackImg={settings.region === "se" ? consentImgSE : consentImgNO}
            height={45}
          />
        </Link>
        <button
          className="navbar-toggler mb-3 mt-3"
          type="button"
          data-toggle="collapse"
          data-target="#mainMenu"
          aria-controls="mainMenu"
          aria-expanded={menuOpen ? "true" : "false"}
          aria-label={tr("toggleNavigation")}
          onClick={(e) => {
            e.stopPropagation();
            setMenuOpen(!menuOpen);
          }}
        >
          <img
            src="data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"
            alt="Meny"
            className="accessibility navbar-toggler-icon"
          />
        </button>
      </div>
      <Collapse innerRef={wrapperRef} id="mainMenu" isOpen={menuOpen} navbar>
        <ul className="navbar-nav mr-auto">
          {mobileMenu && props.user ? (
            <li className={`nav-item`} key={"start"}>
              <Link
                className="nav-link"
                to={
                  adminPortal
                    ? "/admin"
                    : superAdminPortal
                    ? "/superadmin"
                    : "/"
                }
                onClick={() => {
                  setMenuOpen(false);
                }}
              >
                {tr("adminAppStartItem")}
              </Link>
            </li>
          ) : null}
          {headersAndItems.map((item, index) => {
            if (
              (!skipPermissionCheck && !userPermissions) ||
              (item.header.somePermission &&
                !item.header.somePermission.some(
                  (perm) => userPermissions?.some[perm]
                )) ||
              (item.header.orgPermission &&
                !userPermissions?.org[item.header.orgPermission]) ||
              (item.header.onlyInDemo && !inDemo)
            ) {
              return null;
            }
            return (
              <CustomDropdownMenu
                menuTitle={item.header.label}
                items={item.items.map((subItem, i: number) => {
                  const item: CustomDropdownMenuItem = {};
                  item.href = subItem.url;
                  item.label = subItem.label;
                  item.key = subItem.label + "_" + i;
                  return item;
                })}
                closeMenu={() => setMenuOpen(false)}
                onExpand={() => {
                  setOpenSubMenus([item.header.label]);
                }}
                openSubMenus={openSubMenus}
                key={index}
                topListItemClassName="d-lg-none"
              />
            );
          })}
        </ul>
        <ul className="navbar-nav">
          <AccountArea
            {...props}
            adminPortal={adminPortal}
            closeMenu={() => setMenuOpen(false)}
            setOpenSubMenus={(value: string[]) => {
              setOpenSubMenus(value);
            }}
            openSubMenus={openSubMenus}
          />
          <CustomDropdownMenu
            menuTitle={uiLanguage}
            items={getAvailableUiLanguages().map((language) => {
              const item: CustomDropdownMenuItem = {};
              item.onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                props.dispatch(action.changeUiLanguage(language));
                setMenuOpen(false);
              };
              item.label = languageNames[language as "sv"] || language;
              item.key = item.label;
              return item;
            })}
            closeMenu={() => setMenuOpen(false)}
            onExpand={() => {
              setOpenSubMenus([uiLanguage]);
            }}
            openSubMenus={openSubMenus}
          />
          {props.user ? (
            <InfoCaption region={settings.region.toString()} />
          ) : null}
        </ul>
      </Collapse>
    </nav>
  );
};

export const Header = React.memo(HeaderInner);

interface AccountAreaProps extends HeaderProps {
  adminPortal: boolean;
  closeMenu: () => void;
  setOpenSubMenus?: (value: string[]) => void;
  openSubMenus?: string[];
}

const AccountArea = (props: AccountAreaProps) => {
  const [breakpoint] = useMediaWidth();
  const { tr } = useTranslation();
  const { org: cu } = useCurrentOrg();
  const { availableOrgs } = props;
  const sub = props.user?.profile.sub;

  const currentOrgName = cu ? cu.displayName : "";

  const mobileMenu = breakpoint < Breakpoint.lg;

  const isSuperadmin = sub ? persistance.get(`is_superadmin_${sub}`) : false;

  const onClickWithClose = (fn?: () => void) => () => {
    props.closeMenu();
    if (fn) fn();
  };

  const currentOrgNameOrId =
    cu?.account?.orgName || props?.user?.profile.org || "";

  const loggedInUserItems = props.user
    ? [
        {
          label: props.user.profile.name,
        },
        props.adminPortal
          ? {
              label: tr("headerLoggedInWithAccount", currentOrgNameOrId),
            }
          : null,
        isSuperadmin
          ? {
              label: "Superadmin",
              href: "/superadmin",
            }
          : null,
        isSuperadmin
          ? {
              label: "Admin",
              href: "/admin",
            }
          : null,
        {
          label: tr("myProfileHeaderLabel"),
          href: props.adminPortal ? links.admin.profile() : links.profile(),
        },
        props.user && inDevOrLocal && props.adminPortal
          ? {
              label: props.skipPermissionCheck
                ? tr("resetMenu")
                : tr("showAllMenuRows"),
              onClick: () => {
                props.dispatch(action.toggleSkipPermissionCheck());
              },
            }
          : null,
        {
          label: tr("logOut"),
          onClick: () => {
            props.onLogout();
            setTimeout(() => {
              userManager.signoutRedirect();
            }, 2000);
          },
        },
      ].filter(definedNotNull)
    : [];

  if (props.user) {
    return (
      <>
        {availableOrgs && availableOrgs.length > 0 ? (
          <CustomDropdownMenu
            menuTitle={currentOrgName || tr("chooseOrganisation")}
            items={availableOrgs.map((availableOrg) => {
              const item: CustomDropdownMenuItem = {};
              item.onClick = () => {
                props.dispatch(
                  action.orgWillChange({
                    id: availableOrg.id,
                    account: availableOrg.account,
                  })
                );
                props.closeMenu();
              };
              item.label = props.adminPortal ? (
                <>
                  {availableOrg.displayName}{" "}
                  <span className="font-weight-bold small font-italic">
                    {availableOrg.account?.orgName}
                  </span>
                </>
              ) : (
                availableOrg.displayName
              );
              item.key = availableOrg.id + availableOrg.account?.sub;
              return item;
            })}
            closeMenu={props.closeMenu}
            onExpand={() => {
              props.setOpenSubMenus
                ? props.setOpenSubMenus([
                    currentOrgName || tr("chooseOrganisation"),
                  ])
                : null;
            }}
            openSubMenus={props.openSubMenus}
          />
        ) : null}

        {mobileMenu ? (
          <CustomDropdownMenu
            menuTitle={loggedInUserItems[0].label!}
            items={loggedInUserItems
              .filter(
                (item) => props.user && item.label !== props.user.profile.name
              )
              .map((item) => {
                if (item.onClick) {
                  item.onClick = onClickWithClose(item.onClick);
                }
                return item;
              })}
            closeMenu={props.closeMenu}
            onExpand={() => {
              props.setOpenSubMenus
                ? props.setOpenSubMenus([loggedInUserItems[0].label!])
                : null;
            }}
            openSubMenus={props.openSubMenus}
          />
        ) : (
          <CustomDropdownMenu
            menuTitle={loggedInUserItems[0].label!}
            items={loggedInUserItems
              .filter(
                (item) => props.user && item.label !== props.user.profile.name
              )
              .map((item) => {
                if (item.onClick) {
                  item.onClick = onClickWithClose(item.onClick);
                }
                return item;
              })}
            menuSubHeading={cu?.account?.orgName}
            closeMenu={props.closeMenu}
            onExpand={() => {
              props.setOpenSubMenus
                ? props.setOpenSubMenus([loggedInUserItems[0].label!])
                : null;
            }}
            openSubMenus={props.openSubMenus}
          />
        )}
      </>
    );
  } else {
    return null;
  }
};

interface CustomDropdownMenuProps {
  menuTitle: string;
  items: CustomDropdownMenuItem[];
  onExpand?: (e?: any) => void;
  onClick?: () => void;
  closeMenu?: () => void;
  openSubMenus?: string[];
  topListItemClassName?: string;
  menuSubHeading?: string;
}

interface CustomDropdownMenuItem {
  label?: string | JSX.Element | undefined;
  href?: string | undefined;
  onClick?: (e?: any) => void | undefined;
  key?: string;
}

const CustomDropdownMenu = (props: CustomDropdownMenuProps) => {
  const { openSubMenus = [] } = props;
  const navigate = useNavigate();
  const [menuGroupIsOpen, setMenuGroupIsOpen] = React.useState<{
    [key: string]: boolean[];
  }>({});
  useEffect(() => {
    if (!openSubMenus.includes(props.menuTitle)) {
      setMenuGroupIsOpen({});
    }
  }, [openSubMenus, props.menuTitle]);

  const toggleMenuGroup = (
    groupName: string,
    id: number,
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    const newItemOpenStatus = menuGroupIsOpen[groupName]
      ? !menuGroupIsOpen[groupName][id]
      : true;
    const newState: {
      [key: string]: boolean[];
    } = {};
    newState[groupName] = [];
    newState[groupName][id] = newItemOpenStatus;
    setMenuGroupIsOpen(newState);
  };
  return (
    <li
      className={
        "nav-item dropdown" +
        (props.topListItemClassName ? " " + props.topListItemClassName : "")
      }
      key={props.menuTitle}
    >
      <a
        className={
          props.menuSubHeading
            ? "nav-link dropdown-toggle pb-1"
            : "nav-link dropdown-toggle"
        }
        href="#"
        id="navbardrop"
        data-toggle="dropdown"
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
          props.onExpand ? props.onExpand() : null;
          toggleMenuGroup(props.menuTitle!, 0, e);
        }}
        aria-label={props.menuTitle}
        aria-expanded={
          menuGroupIsOpen[props.menuTitle!]
            ? menuGroupIsOpen[props.menuTitle!][0]
            : false
        }
      >
        {props.menuSubHeading ? (
          <span className="d-inline-block">
            <span className="d-flex flex-column">
              {props.menuTitle}
              <span className="small font-italic">{props.menuSubHeading}</span>
            </span>
          </span>
        ) : (
          props.menuTitle
        )}
      </a>
      <div
        className={
          menuGroupIsOpen[props.menuTitle!] &&
          menuGroupIsOpen[props.menuTitle!][0]
            ? "dropdown-menu dropdown-menu-right show"
            : "dropdown-menu dropdown-menu-right"
        }
      >
        {props.items.map((item: CustomDropdownMenuItem, i: number) => {
          return item.onClick ? (
            <a
              key={item.key ? item.key : i}
              className={
                "dropdown-item" +
                (location.pathname === item.href ? " active" : "")
              }
              href={item.href || "#"}
              onClick={(e) => {
                e.preventDefault();
                setMenuGroupIsOpen({});
                item.onClick ? item.onClick(e) : null;
              }}
            >
              {item.label}
            </a>
          ) : (
            <a
              key={item.key ? item.key : i}
              className={
                "dropdown-item" +
                (location.pathname === item.href ? " active" : "")
              }
              href={item.href || "#"}
              onClick={(e) => {
                e.preventDefault();
                setMenuGroupIsOpen({});
                navigate(item.href || "#");
                props.closeMenu ? props.closeMenu() : null;
              }}
            >
              {item.label}
            </a>
          );
        })}
      </div>
    </li>
  );
};
