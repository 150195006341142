import * as React from "react";

interface Props {
  id?: string;
  header: string;
  children?: React.ReactNode;
  className?: string;
  description: string;
}

export const ISTContainerExpandableInner = (props: Props, ref: any) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div className={`box ${props.className}`} id={props.id} ref={ref}>
      <div
        className="box-header"
        style={isOpen ? {} : { borderRadius: "8px" }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-6" style={{ padding: "0" }}>
              {props.header}
              <i
                className={
                  isOpen ? "fas fa-caret-up ml-2" : "fas fa-caret-down ml-2"
                }
              />
            </div>
            <div
              className="col-md-6 col-sm-6 text-md-right"
              style={{ padding: "0" }}
            >
              <small className="ist-container-expandable-small-text">
                {props.description}{" "}
              </small>
            </div>
          </div>
        </div>
      </div>
      {isOpen ? <>{props.children}</> : <></>}
    </div>
  );
};

export const ISTContainerExpandable = React.forwardRef(
  ISTContainerExpandableInner
);
