import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";

const SAFARI_MOBILE_BOTTOM_MENU_HEIGHT = 44;

interface Props {
  offset?: number;
  children: any;
}

export const FixedBottom = (props: Props) => {
  const anchorRef: any = useRef();
  const [bottomState, setBottomState] = useState(0);

  const computeOffsetBottom = () => {
    if (!anchorRef.current) {
      return;
    }

    const { bottom } = anchorRef.current.getBoundingClientRect();
    const { offset = 0 } = props;

    if (Math.floor(bottom) > window.innerHeight) {
      setBottomState(offset + SAFARI_MOBILE_BOTTOM_MENU_HEIGHT);
    } else {
      setBottomState(offset);
    }
  };

  const handleScroll = _.throttle(computeOffsetBottom, 200);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      // Throttled calls may be scheduled before the component unmounts
      handleScroll.cancel();
      window.removeEventListener("scroll", handleScroll);
    };
    // TODO: Seems to work fine but might want to fix this lint issue some day
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const node = React.cloneElement(React.Children.only(props.children), {
    style: {
      ...props.children.props.style,
      bottom: bottomState,
      position: "fixed",
    },
  });

  return (
    <>
      {node}
      {/* This div is used to run compute the offset without adding a ref */}
      {/* on the rendered children */}
      <div
        ref={anchorRef}
        style={{
          position: "fixed",
          bottom: props.offset,
        }}
      />
    </>
  );
};
