import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export type DialogSize = "sm" | "md" | "lg";

interface Props {
  show: boolean;
  cancel: () => void;
  title: string;
  content: React.ReactNode;
  footer: React.ReactNode;
  size?: DialogSize;
}

export const Dialog = (props: Props) => {
  const { show, content, footer, title, cancel, size } = props;
  return (
    <Modal isOpen={show} toggle={cancel} zIndex={30000} size={size}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{content}</ModalBody>
      <ModalFooter>{footer}</ModalFooter>
    </Modal>
  );
};
