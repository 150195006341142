/* eslint-disable react/display-name */
import { findIndex } from "lodash";
import * as React from "react";
import { getPredicateComponent } from "../../Admin/components/CreateForm";
import { availableTranslationLanguages } from "../../Admin/form";
import * as f from "../../Utils/functional";
import * as gqltypes from "../../gqltypes";
import { getTr } from "../../translation";
import { SavingState } from "../../types";
import { ApplicationCustomComponent } from "./ApplicationCustomComponent";
import { FormComponentView } from "./FormComponentView";
import { FormHeader } from "./FormHeader";

interface Props {
  additionalComponents?: any;
  formType: gqltypes.FormType;
  initialForm?: boolean;
  isAdmin?: boolean;
  child?: Pick<gqltypes.User, "name" | "firstName" | "lastName"> | null;
  classAndSchool?: string;
  formTitle: string;
  formDescription: string | null;
  components: gqltypes.FullFormComponent[];
  predicateComponents: gqltypes.FullFormPredicateComponent[];
  formLanguage: gqltypes.ISO6391Language;
  translation?: gqltypes.FullTranslation | null;
  translating?: boolean;
  availableLanguages?: gqltypes.ISO6391Language[];
  displayAvailableLanguages?: boolean;
  handleLanguageChange?: (language: gqltypes.ISO6391Language) => void;
  validateAnswers?: boolean;
  response?: gqltypes.FormAnswer;
  updateResponse?: (response: gqltypes.FormAnswer) => void;
  submittedTime?: string;
  formOwner: Pick<gqltypes.Form["owner"], "id" | "displayName">;
  readOnly?: boolean;
  creator?: gqltypes.AdminViewApplicationResponse_applicationResponse_creator;
  singleGuardian?: boolean;
  displayAllApplicationFeedbackOptions?: boolean;
  schoolunitSpecific?: boolean;
  isEditable?: boolean | null;
  selectedApplicationSchoolUnit?: { id: string; customerId: string } | null;
  updateSelectedApplicationSchoolUnit?: (
    input: { id: string; customerId: string } | null
  ) => void;
}
interface State {}
export class ConsentForm extends React.PureComponent<Props, State> {
  public render() {
    const {
      translation,
      translating,
      availableLanguages,
      displayAvailableLanguages,
      singleGuardian,
      components,
      creator,
      predicateComponents,
    } = this.props;

    const lang = translation ? translation.language : this.props.formLanguage;
    const tr = getTr(lang);

    const mapComponents =
      (forceReadOnly?: boolean) => (data: (typeof components)[0]) => {
        const componentResponse =
          this.props.response &&
          this.props.response.components.find((c) => c.componentId === data.id);

        const componentTranslation =
          translation &&
          translation.componentData.components.find((c) => c.id === data.id);

        const getPredicateComponentTranslation = (id: string) => {
          return f.throwWhenError(
            f.safePipe(
              f.get(translation, "componentData", "predicateComponents"),
              (pcs) => pcs.find((c) => c.id === id)
            )
          ) as
            | NonNullable<
                gqltypes.FullTranslation["componentData"]["predicateComponents"]
              >[0]
            | null;
        };

        const getPC = (id: string) =>
          f.throwWhenError(getPredicateComponent(predicateComponents, id));

        return (
          <FormComponentView
            getPredicateComponent={getPC}
            getPredicateComponentTranslation={getPredicateComponentTranslation}
            response={this.props.response}
            key={data.id}
            validateAnswers={this.props.validateAnswers}
            updateComponentAnswer={this.updateResponse}
            answer={componentResponse}
            component={data}
            formLanguage={this.props.formLanguage}
            translation={componentTranslation}
            translationLanguage={
              translation &&
              (translation.language as
                | keyof availableTranslationLanguages
                | undefined)
            }
            translating={translating}
            readOnly={this.props.readOnly || forceReadOnly}
            isEditable={this.props.isEditable}
          />
        );
      };

    return (
      <form id="form-viewer">
        <FormHeader
          formType={this.props.formType}
          formOwner={this.props.formOwner}
          child={this.props.child}
          classAndSchool={this.props.classAndSchool}
          canEdit={false}
          editingHeaderComponent={false}
          editHeaderComponent={f.noop}
          formTitle={this.props.formTitle}
          formDescription={this.props.formDescription}
          formLanguage={this.props.formLanguage}
          savingState={SavingState.Saved}
          submittedTime={this.props.submittedTime}
          translating={translating}
          translation={translation}
          availableLanguages={availableLanguages}
          displayAvailableLanguages={displayAvailableLanguages}
          handleLanguageChange={this.props.handleLanguageChange}
        >
          {this.props.formType === gqltypes.FormType.application ? (
            <ApplicationCustomComponent
              tr={tr}
              uiLanguage={lang}
              subjectCoSignerLocked={!this.props.initialForm}
              formOwner={this.props.formOwner}
              answer={
                this.props.response &&
                this.props.response.components.find(
                  (c) => c.componentId === "application_custom_component"
                )
              }
              creator={creator}
              updateComponentAnswer={this.updateResponse}
              validateAnswers={this.props.validateAnswers}
              singleGuardian={singleGuardian}
              displayingForAdmin={this.props.isAdmin}
              schoolunitSpecific={this.props.schoolunitSpecific}
              selectedSchoolUnit={this.props.selectedApplicationSchoolUnit}
              updateSelectedSchoolUnit={
                this.props.updateSelectedApplicationSchoolUnit
              }
            />
          ) : null}
        </FormHeader>
        {this.props.additionalComponents}

        {this.props.components
          .filter((c) => !c.applicationFeedbackId)
          .map(mapComponents())}

        {this.props.displayAllApplicationFeedbackOptions ? (
          <div className="p-3 bg-warning">
            <h3>{tr("applicationFeedbackLabel")}</h3>
            {this.props.components
              .filter((c) => Boolean(c.applicationFeedbackId))
              .map(mapComponents(true))}
          </div>
        ) : null}
      </form>
    );
  }

  private updateResponse = (component: gqltypes.FormComponentAnswer) => {
    if (!this.props.updateResponse) {
      return;
    }
    const currentResponse = this.props.response;
    const components = currentResponse ? currentResponse.components : [];

    const indexToUpdate = findIndex(
      components,
      (c) => c.componentId === component.componentId
    );
    const updatedComponents =
      indexToUpdate !== -1
        ? f.replaceInArray(components, indexToUpdate, component)
        : [...components, component];
    this.props.updateResponse({ components: updatedComponents });
  };
}
