import React from "react";
import { ConfirmOptions } from "./confirmTypes";

export interface ConfirmableProps {
  dispose: () => void;
  resolve: (value?: any) => any;
  reject: (value: any) => void;
  options: ConfirmOptions;
}

export interface ConfirmableComponentProps {
  proceed: () => void;
  cancel?: (value: any) => void;
  dismiss?: (value: any) => void;
  show: boolean;
  awaitingProceedFunc?: boolean;
  options: ConfirmOptions;
}

interface State {
  show: boolean;
  awaitingProceedFunc: boolean;
}

function confirmable<
  T extends ConfirmableComponentProps,
  U extends T & ConfirmableProps
>(Component: React.ComponentType<any>): React.ComponentClass<U> {
  // eslint-disable-next-line react/display-name
  return class extends React.PureComponent<U, State> {
    public constructor(props: U) {
      super(props);

      this.state = {
        show: true,
        awaitingProceedFunc: false,
      };
    }

    public render() {
      return (
        <Component
          proceed={this.proceed}
          cancel={this.cancel}
          dismiss={this.dismiss}
          show={this.state.show}
          awaitingProceedFunc={this.state.awaitingProceedFunc}
          options={this.props.options}
        />
      );
    }

    public signalWaiting = (status: boolean) => {
      this.setState({ awaitingProceedFunc: status });
    };

    public dismiss = () => {
      this.setState(
        {
          show: false,
        },
        () => {
          this.props.dispose();
        }
      );
    };

    private cancel = (value: any) => {
      this.setState(
        {
          show: false,
        },
        () => {
          this.props.reject(value);
        }
      );
    };

    private proceed = (value: any) => {
      this.props.resolve(value);
    };
  };
}

export default confirmable;
