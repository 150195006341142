import gql from "graphql-tag";
import * as gqltypes from "../gqltypes";
import {
  formComponentDataFragment,
  fullFormTranslationFragment,
  fullResponseFragment,
} from "./fragments";
import { DataAndVariables } from "./mutations";

export const emptyPublicationResponse = { components: [] };

export type PUBLICATION_RECIPIENT_QUERY_TYPES = DataAndVariables<
  gqltypes.publicationRecipient,
  gqltypes.publicationRecipientVariables
>;
export const PUBLICATION_RECIPIENT_QUERY = gql`
  query publicationRecipient($id: ID!, $context: Context) {
    publicationRecipient(id: $id, context: $context) {
      id
      copyableResponses {
        form {
          id
          name
          language
        }
        user {
          id
          name
        }
        ...FullResponse
      }
      responses(filter: LastDraft) {
        ...FullResponse
      }
      user {
        id
        name
        firstName
        lastName
      }
      publication {
        id
        form {
          id
          name
          description
          language
          translationLanguages
          translations {
            ...FullTranslation
          }
          owner {
            id
            displayName
          }
          componentData {
            ...FormComponentData
          }
        }
      }
    }
  }
  ${formComponentDataFragment}
  ${fullResponseFragment}
  ${fullFormTranslationFragment}
`;

export type ADMIN_PUBLICATION_RECIPIENT_DRAFT_TYPES = DataAndVariables<
  gqltypes.adminPublicationRecipientDraft,
  gqltypes.adminPublicationRecipientDraftVariables
>;
export const ADMIN_PUBLICATION_RECIPIENT_DRAFT_QUERY = gql`
  query adminPublicationRecipientDraft($id: ID!, $context: Context) {
    publicationRecipient(id: $id, context: $context) {
      id
      responses(filter: LastDraft) {
        ...FullResponse
      }
      user {
        id
        name
        firstName
        lastName
      }
      publication {
        id
        form {
          id
          name
          description
          language
          translationLanguages
          translations {
            ...FullTranslation
          }
          owner {
            id
            displayName
          }
          componentData {
            ...FormComponentData
          }
        }
      }
    }
  }
  ${formComponentDataFragment}
  ${fullResponseFragment}
  ${fullFormTranslationFragment}
`;

export type ADMIN_PUBLICATION_RECIPIENT_TYPES = DataAndVariables<
  gqltypes.adminPublicationRecipient,
  gqltypes.adminPublicationRecipientVariables
>;
export const ADMIN_PUBLICATION_RECIPIENT_QUERY = gql`
  query adminPublicationRecipient($id: ID!) {
    publicationRecipient(id: $id) {
      id
      modifyPermission
      responses(filter: LastDraftLastValid) {
        ...FullResponse
      }
    }
  }
  ${fullResponseFragment}
`;

export type PUBLICATION_RESPONSE_QEURY_TYPES = DataAndVariables<
  gqltypes.publicationResponse,
  gqltypes.publicationResponseVariables
>;
export const PUBLICATION_RESPONSE_QEURY = gql`
  query publicationResponse($responseId: ID!, $context: Context) {
    publicationResponse(id: $responseId, context: $context) {
      modifyPermission
      ...FullResponse
      publicationRecipient {
        id
        responses(filter: AllValid) {
          id
          modified
        }
      }
    }
  }
  ${fullResponseFragment}
`;

export type ORGANISTION_SCHOOLUNITS_QEURY_TYPES = DataAndVariables<
  gqltypes.organisationSchoolunits,
  gqltypes.organisationSchoolunitsVariables
>;
export const ORGANISTION_SCHOOLUNITS_QEURY = gql`
  query organisationSchoolunits(
    $suFilter: SchoolUnitsFilter
    $context: Context!
  ) {
    organisation(context: $context) {
      id
      displayName
      schoolUnits(filter: $suFilter) {
        id
        customerId
        displayName
        organisationType
        classes {
          id
        }
      }
    }
  }
`;
