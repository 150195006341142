import * as React from "react";
import { UncontrolledTooltip } from "reactstrap";

interface Props {
  target: string;
  content: React.ReactNode;
  message: string;
}

export function Tooltip(props: Props) {
  return (
    <span id={props.target} data-toggle="tooltip">
      {props.content}
      <UncontrolledTooltip target={props.target}>
        {props.message}
      </UncontrolledTooltip>
    </span>
  );
}
