import gql from "graphql-tag";
import _ from "lodash";
import * as React from "react";
import { QueryResult } from "@apollo/client";
import { graphql, DataValue } from "@apollo/client/react/hoc";
import { NavigateFunction } from "react-router";
import {
  Translation,
  withAdminContext,
  withTranslation,
} from "../../App/reducer";
import {
  Button,
  ConsentForm,
  ISTContainer,
  Loading,
  Table,
} from "../../Common";
import { GenericError } from "../../Common/components/GenericError";
import { Time } from "../../Common/components/Time";
import {
  formComponentDataFragment,
  fullFormTranslationFragment,
  fullResponseFragment,
} from "../../Common/fragments";
import * as gqltypes from "../../gqltypes";
import { SavingState } from "../../types";
import withRouter from "../../Utils/withRouter";
import { links } from "../links";

interface Props extends Translation {
  params: { id: string };
  formData: DataValue<gqltypes.AdminViewFormResponse> & QueryResult;
  navigate: NavigateFunction;
}
interface State {
  saveState: SavingState;
  response?: gqltypes.FormAnswer;
}

const StatusBars = (props: {
  tr: Translation["tr"];
  status: gqltypes.PublicationResponseStatus;
}) => {
  if (props.status === gqltypes.PublicationResponseStatus.partially_signed) {
    return (
      <div className="alert alert-warning">
        {props.tr("formResponsePartiallySignedAlert")}
      </div>
    );
  }

  return null;
};

class FormResponseView extends React.Component<Props, State> {
  public render() {
    const { tr, formData } = this.props;
    const accessError =
      formData.error &&
      formData.error.graphQLErrors.some(
        (e) => e.extensions && e.extensions.code === "ACCESS"
      );

    if (accessError) {
      return <GenericError title={tr("formResponseNoAccess")} />;
    }

    if (formData.loading || !formData.publicationResponse) {
      return <Loading />;
    }
    const publicationResponse = formData.publicationResponse;
    const form = publicationResponse.form;
    const submittedTime = publicationResponse.modified;

    let translating = false;
    let translation: (typeof form)["translations"][0] | undefined;
    if (form.language !== publicationResponse.language) {
      translating = true;
      translation = form.translations.find(
        (t) => t.language === publicationResponse.language
      );
    }

    const allValidResponses = _.orderBy(
      publicationResponse.publicationRecipient.responses,
      "modified",
      "asc"
    );

    const latestValidResponse = allValidResponses[allValidResponses.length - 1];

    return (
      <>
        <StatusBars tr={tr} status={publicationResponse.status} />
        <div id="form-view">
          <ConsentForm
            formType={form.type}
            formOwner={form.owner}
            child={publicationResponse.user}
            formTitle={form.name}
            formDescription={form.description}
            formLanguage={form.language}
            predicateComponents={form.componentData.predicateComponents || []}
            components={form.componentData.components}
            response={publicationResponse.response || undefined}
            submittedTime={submittedTime}
            availableLanguages={[publicationResponse.language]}
            translating={translating}
            translation={translation}
            readOnly={true}
          />
          {allValidResponses.length ? (
            <ISTContainer header={tr("history")} className="mt-content">
              <Table
                headers={[
                  {
                    key: "version",
                    element: tr("formResponseVersion"),
                  },
                  {
                    key: "sent_in",
                    element: tr("formResponseSentIn"),
                    usingSortValue: true,
                    hidden: true,
                  },
                  {
                    key: "validity",
                    element: tr("formResponseValidity"),
                    unsortable: true,
                  },
                ]}
                rows={allValidResponses.map((res, index) => ({
                  key: res.id,
                  selected: publicationResponse.id === res.id,
                  onClick: () =>
                    this.props.navigate(
                      links.admin.publication.response(res.id),
                      {
                        replace: true,
                      }
                    ),
                  columns: {
                    version: {
                      content: index + 1,
                    },
                    sent_in: {
                      content: <Time date={res.modified} format="dateTime" />,
                      sortValue: res.modified,
                    },
                    validity: {
                      content:
                        latestValidResponse.id === res.id ? (
                          tr("formResponseValidityCurrent")
                        ) : (
                          <div>
                            <Time date={res.modified} /> -{" "}
                            <Time
                              date={allValidResponses[index + 1].modified}
                            />
                          </div>
                        ),
                    },
                  },
                }))}
                initialOrder="sent_in"
              />
            </ISTContainer>
          ) : null}
          <div className="actions mb-content">
            <Button
              onClick={this.handleGoBack}
              level="secondary"
              label={tr("goBack")}
            />
          </div>
        </div>
        <StatusBars tr={tr} status={publicationResponse.status} />
      </>
    );
  }

  private handleGoBack = () => {
    this.props.navigate(-1);
  };
}

const withFormData = graphql<
  Props,
  gqltypes.AdminViewFormResponse,
  gqltypes.AdminViewFormResponseVariables,
  any
>(
  gql`
    query AdminViewFormResponse($id: ID!) {
      publicationResponse(id: $id) {
        id
        user {
          id
          name
          firstName
          lastName
        }
        ...FullResponse
        form {
          id
          type
          name
          description
          language
          componentData {
            ...FormComponentData
          }
          translations {
            ...FullTranslation
          }
          owner {
            id
            displayName
          }
        }
        publicationRecipient {
          id
          responses(filter: AllValid) {
            id
            modified
          }
        }
      }
    }
    ${fullResponseFragment}
    ${formComponentDataFragment}
    ${fullFormTranslationFragment}
  `,
  {
    name: "formData",
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: "cache-first",
    }),
  }
);

export const FormResponseContainer = _.flowRight(
  withTranslation,
  withAdminContext,
  withRouter,
  withFormData
)(FormResponseView);
