import * as React from "react";
import ReactDOM from "react-dom";
import { definedNotNull } from "../../Utils/functional";

const modalRoot = document.getElementById("modal-root")!;
const body = document.getElementsByTagName("body")[0];

interface Props {
  onBlur?: () => void;
  children: React.ReactNode;
}

export class Modal extends React.Component<Props, {}> {
  private el: HTMLDivElement;
  private bodyOverflowStyle?: string | null;

  constructor(props: Props) {
    super(props);
    this.el = document.createElement("div");
    this.el.className = "ist-modal";
  }

  public componentDidMount() {
    this.bodyOverflowStyle = body.style.overflow;
    body.style.overflow = "hidden";
    modalRoot.appendChild(this.el);
    if (this.props.onBlur) {
      this.el.addEventListener("click", this.handleClick);
    }
  }

  public componentWillUnmount() {
    if (definedNotNull(this.bodyOverflowStyle)) {
      body.style.overflow = this.bodyOverflowStyle;
    }
    this.el.removeEventListener("click", this.handleClick);
    modalRoot.removeChild(this.el);
  }

  public render(): React.ReactPortal {
    return ReactDOM.createPortal(
      this.props.children,
      this.el
    ) as React.ReactPortal;
  }

  private handleClick = (event: MouseEvent) => {
    if (this.props.onBlur && event.target === this.el) {
      this.props.onBlur();
    }
  };
}
