import * as React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "../../App/reducer";
import { ISTContainer } from "..";

interface Props {
  startPageUrl: string;
}

export function PageNotFound(props: Props) {
  const { tr } = useTranslation();
  return (
    <main>
      <h1>{tr("pageNotFoundTitle")}</h1>
      <p className="col-12 col-md-9 p-0 pb-3">
        {tr("pageNotFoundDescription")}
      </p>
      <ISTContainer header={tr("pageNotFoundHeader")}>
        <div className="p-content">
          <Link to={props.startPageUrl}>{tr("pageNotFoundClickToReturn")}</Link>
        </div>
      </ISTContainer>
    </main>
  );
}
