import React, { useEffect, useState } from "react";
import { getSettings } from "../../settings";

interface Props {
  id: string;
  className?: string;
  height: number;
  fallbackImg?: string;
  fallbackText?: string;
  skipPrimary?: boolean;
  queryParams?: string;
}

const settings = getSettings();

const failedPrimaries: {
  [orgId: string]: "fallbackText" | "fallbackImage";
} = {};

const getInitialState = (props: Props) => {
  const url =
    (props.skipPrimary || !props.id) && props.fallbackImg
      ? props.fallbackImg
      : `${settings.s3ApiUrl}/logos/${props.id}.png`;

  const old = failedPrimaries[props.id];
  if (old) {
    return {
      img: url || "",
      useFallbackText: old === "fallbackText",
    };
  }
  return {
    img: url,
    useFallbackText: Boolean(props.skipPrimary) && !props.fallbackImg,
  };
};

export function OrgLogo(props: Props) {
  const initialState = getInitialState(props);
  const [useFallbackText, setUseFallbackText] = useState(
    initialState.useFallbackText
  );
  const [img, setImg] = useState(initialState.img);

  useEffect(() => {
    const newState = getInitialState(props);
    setUseFallbackText(newState.useFallbackText);
    setImg(newState.img);
    // TODO: Seems to work fine but might want to fix this lint issue some day
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  if (useFallbackText) {
    return (
      <h2
        style={{ fontSize: props.height, marginBottom: 0 }}
        className={`text-truncate ${props.className}`}
      >
        <span className="text-dark">{props.fallbackText}</span>
      </h2>
    );
  }
  const qp = props.queryParams || "";
  return (
    <img
      height={props.height}
      className={props.className}
      src={img + qp}
      alt="kommunlogga"
      onError={() => {
        if (props.fallbackImg && props.fallbackImg !== img) {
          failedPrimaries[props.id] = "fallbackImage";
          setImg(props.fallbackImg);
        } else if (props.fallbackText) {
          failedPrimaries[props.id] = "fallbackText";
          setUseFallbackText(true);
        }
      }}
    />
  );
}
