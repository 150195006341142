import { Translation } from "./App/reducer";
import { assertUnreachable } from "./Utils/typeHelpers";
import * as gqltypes from "./gqltypes";

export { PermissionType, PermissionSource } from "./gqltypes";

export const getPermissionDisplayName = (
  role: gqltypes.PermissionType,
  tr: Translation["tr"]
) => {
  switch (role) {
    case gqltypes.PermissionType.create:
      return tr("permissionNameCreate");
    case gqltypes.PermissionType.send:
      return tr("permissionNameSend");
    case gqltypes.PermissionType.read:
      return tr("permissionNameRead");
    case gqltypes.PermissionType.read_base:
      return tr("permissionNameReadBase");
    case gqltypes.PermissionType.read_photo:
      return tr("permissionNameReadPhoto");
    case gqltypes.PermissionType.read_food:
      return tr("permissionNameReadFood");
    case gqltypes.PermissionType.read_it:
      return tr("permissionNameReadIT");
    case gqltypes.PermissionType.read_language:
      return tr("permissionNameReadLanguage");
    case gqltypes.PermissionType.read_medical:
      return tr("permissionNameReadMedical");
    case gqltypes.PermissionType.read_all:
      return tr("permissionNameReadEverything");
    case gqltypes.PermissionType.write_publication_response:
      return tr("permissionNameWritePublicationResponse");
    case gqltypes.PermissionType.admin:
      return tr("permissionNameAdmin");
    case gqltypes.PermissionType.manage_application:
      return tr("permissionNameManageApplication");
    case gqltypes.PermissionType.application_answers:
      return tr("permissionNameApplicationAnswers");
    case gqltypes.PermissionType.read_application_answers:
      return tr("permissionNameReadApplicationAnswers");
  }
  assertUnreachable(role);
};

export const getPermissionDescription = (
  role: gqltypes.PermissionType,
  tr: Translation["tr"]
) => {
  switch (role) {
    case gqltypes.PermissionType.create:
      return tr("permissionDescriptionCreate");
    case gqltypes.PermissionType.send:
      return tr("permissionDescriptionSend");
    case gqltypes.PermissionType.read:
      return tr("permissionDescriptionRead");
    case gqltypes.PermissionType.read_base:
      return tr("permissionDescriptionReadBase");
    case gqltypes.PermissionType.read_photo:
      return tr("permissionDescriptionReadPhoto");
    case gqltypes.PermissionType.read_food:
      return tr("permissionDescriptionReadFood");
    case gqltypes.PermissionType.read_it:
      return tr("permissionDescriptionReadIT");
    case gqltypes.PermissionType.read_language:
      return tr("permissionDescriptionReadLanguage");
    case gqltypes.PermissionType.read_medical:
      return tr("permissionDescriptionReadMedical");
    case gqltypes.PermissionType.read_all:
      return tr("permissionDescriptionReadEverything");
    case gqltypes.PermissionType.write_publication_response:
      return tr("permissionDescriptionWritePublicationResponse");
    case gqltypes.PermissionType.admin:
      return tr("permissionDescriptionAdmin");
    case gqltypes.PermissionType.manage_application:
      return tr("permissionDescriptionManageApplication");
    case gqltypes.PermissionType.application_answers:
      return tr("permissionDescriptionApplicationAnswers");
    case gqltypes.PermissionType.read_application_answers:
      return tr("permissionDescriptionReadApplicationAnswers");
  }
  assertUnreachable(role);
};

export const employmentRoleOrder = [
  gqltypes.EmploymentRole.principal,
  gqltypes.EmploymentRole.preschool_principal,
  gqltypes.EmploymentRole.teacher,
  gqltypes.EmploymentRole.preschool_teacher,
  gqltypes.EmploymentRole.other_educational_staff,
  gqltypes.EmploymentRole.other_staff
];

export const permissionOrder = [
  gqltypes.PermissionType.manage_application,
  gqltypes.PermissionType.read_application_answers,
  gqltypes.PermissionType.application_answers,
  gqltypes.PermissionType.create,
  gqltypes.PermissionType.send,
  // gqltypes.PermissionType.read_all,
  gqltypes.PermissionType.read,
  gqltypes.PermissionType.write_publication_response,
  gqltypes.PermissionType.admin
] as gqltypes.PermissionType[];

export const permissionInheritance: {
  [roleId: string]: gqltypes.PermissionType[] | undefined;
} = {
  [gqltypes.PermissionType.read_all]: [
    gqltypes.PermissionType.read_base,
    gqltypes.PermissionType.read_photo,
    gqltypes.PermissionType.read_food,
    gqltypes.PermissionType.read_language,
    gqltypes.PermissionType.read_it
  ],
  [gqltypes.PermissionType.application_answers]: [
    gqltypes.PermissionType.read_application_answers
  ]
};

export const formComponentPermissions = [
  gqltypes.FormComponentPermission.read_base,
  gqltypes.FormComponentPermission.read_it,
  gqltypes.FormComponentPermission.read_food,
  gqltypes.FormComponentPermission.read_photo,
  gqltypes.FormComponentPermission.read_language,
  gqltypes.FormComponentPermission.read_medical
];

export const getComponentPermissionDisplayName = (
  role: gqltypes.FormComponentPermission,
  tr: Translation["tr"]
) => {
  switch (role) {
    case gqltypes.FormComponentPermission.read_base:
      return tr("permissionComponentNameBase");
    case gqltypes.FormComponentPermission.read_it:
      return tr("permissionComponentNameIT");
    case gqltypes.FormComponentPermission.read_food:
      return tr("permissionComponentNameFood");
    case gqltypes.FormComponentPermission.read_photo:
      return tr("permissionComponentNamePhoto");
    case gqltypes.FormComponentPermission.read_language:
      return tr("permissionComponentNameLanguage");
    case gqltypes.FormComponentPermission.read_medical:
      return tr("permissionComponentNameMedical");
  }
  assertUnreachable(role);
};

export const permissionOnOrgOnly: { [role: string]: true | undefined } = {
  [gqltypes.PermissionType.manage_application]: true
  // [gqltypes.PermissionType.read_application_answers]: true,
  // [gqltypes.PermissionType.application_answers]: true
};

export type Permission = { [P in gqltypes.PermissionType]?: string[] };

export type SimplePermission = { [P in gqltypes.PermissionType]?: boolean };

export interface SimpleSchoolUnitValues {
  [suid: string]: SimplePermission;
}

export interface SchoolUnitValues {
  [suid: string]: Permission;
}

export interface UserPermissions {
  org: SimplePermission;
  su: SimpleSchoolUnitValues;
  some: SimplePermission;
}

export function getEmploymentRoleName(
  tr: Translation["tr"],
  role: gqltypes.EmploymentRole
) {
  switch (role) {
    case gqltypes.EmploymentRole.principal:
      return tr("employmentRolePrincipal");

    case gqltypes.EmploymentRole.preschool_principal:
      return tr("employmentRolePreschoolPrincipal");

    case gqltypes.EmploymentRole.teacher:
      return tr("employmentRoleTeacher");

    case gqltypes.EmploymentRole.preschool_teacher:
      return tr("employmentRolePreschoolTeacher");

    case gqltypes.EmploymentRole.other_educational_staff:
      return tr("employmentRoleOtherEducationalStaff");

    case gqltypes.EmploymentRole.other_staff:
      return tr("employmentRoleOtherStaff");
  }

  assertUnreachable(role);
}

export function getEmploymentRoleNames(
  tr: Translation["tr"],
  roles: gqltypes.EmploymentRole[]
) {
  if (!roles.length) return "";
  return ` (${roles
    .map((role) => getEmploymentRoleName(tr, role))
    .join(", ")})`;
}

export const permissionSUHelper = (
  mode: "add" | "remove",
  suValues: SchoolUnitValues,
  suid: string,
  roleId: gqltypes.PermissionType,
  permissionSource: gqltypes.PermissionSource
) => {
  const inheritance = permissionInheritance[roleId] || [];
  if (mode === "add") {
    if (!suValues[suid]) {
      suValues[suid] = {};
    }
    if (!suValues[suid][roleId]) {
      suValues[suid][roleId] = [permissionSource];
    } else {
      if (suValues[suid][roleId]!.indexOf(permissionSource) === -1) {
        suValues[suid][roleId]!.push(permissionSource);
      }
    }
    inheritance.forEach((inheritRole) => {
      permissionSUHelper(
        mode,
        suValues,
        suid,
        inheritRole,
        gqltypes.PermissionSource.INHERIT
      );
    });
  } else if (mode === "remove") {
    if (!suValues[suid][roleId]) {
      return;
    }
    if (suValues[suid][roleId]!.some((source) => source === permissionSource)) {
      if (suValues[suid][roleId]!.length === 1) {
        delete suValues[suid][roleId];
        inheritance.forEach((inheritRole) => {
          permissionSUHelper(
            mode,
            suValues,
            suid,
            inheritRole,
            gqltypes.PermissionSource.INHERIT
          );
        });
      } else {
        suValues[suid][roleId] = suValues[suid][roleId]!.filter(
          (source) => source !== permissionSource
        );
      }
    }
  }
};

export const isPermissionDirty = (
  perm: Permission,
  initialPerm: Permission | undefined
) => {
  if (!initialPerm) return true;

  const permKeys = Object.keys(perm) as gqltypes.PermissionType[];
  const setPermissions = permKeys.filter((permKey) =>
    perm[permKey]!.some((source) => source === gqltypes.PermissionSource.DIRECT)
  );

  const initialpermKeys = Object.keys(initialPerm) as gqltypes.PermissionType[];
  const initialSetPermissions = new Set<string>(
    initialpermKeys.filter((permKey) =>
      initialPerm[permKey]!.some(
        (source) => source === gqltypes.PermissionSource.DIRECT
      )
    )
  );

  const sizeDiffers = setPermissions.length !== initialSetPermissions.size;
  const samePermissions = !setPermissions.every((setPerm) =>
    initialSetPermissions.has(setPerm)
  );

  return sizeDiffers || samePermissions;
};
