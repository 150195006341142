import { AlertDialog } from "../../Common/components/AlertDialog";
import { ConfirmDialog } from "../../Common/components/ConfirmDialog";
import { ErrorDialog } from "../../Common/components/ErrorDialog";
import { ConfirmOptions } from "./confirmTypes";
import createConfirmation from "./createConfirmation";

export const showAlertDialog = (options: ConfirmOptions) => {
  const dialog = createConfirmation(AlertDialog, 100);
  return dialog(options);
};

export const showErrorDialog = (options: ConfirmOptions) => {
  const dialog = createConfirmation(ErrorDialog, 100);
  return dialog(options);
};

export const showConfirmDialog = (options: ConfirmOptions) => {
  const confirmation = createConfirmation(ConfirmDialog, 100);
  return confirmation(options);
};
