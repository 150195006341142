import * as React from "react";

export type BadgeColor =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "light"
  | "dark";

export const BadgeButton = (props: {
  label: string;
  title?: string;
  className?: string;
  onClick: () => void;
  color: BadgeColor;
  disabled?: boolean;
}) => {
  return (
    <button
      className={`btn badge badge-${props.color} ${props.className || ""}`}
      title={props.title}
      onClick={props.onClick}
      disabled={props.disabled}
      style={{fontSize: "10px"}}
    >
      {props.label}
    </button>
  );
};
