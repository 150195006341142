import { convertToHTML as draftToHTML } from "draft-convert";
import { RawDraftContentState, convertFromRaw } from "draft-js";
import * as React from "react";
import renderHTML from "react-render-html";

interface Props {
  value?: string | null;
  className?: string;
}

const convertToHTML = (contentState: any) =>
  draftToHTML({
    entityToHTML: (entity: any, originalText: string) => {
      if (entity.type === "LINK") {
        return (
          <a target="_blank" href={entity.data.url} rel="noreferrer">
            {originalText}
          </a>
        );
      }
      return originalText;
    },
  })(contentState);

export function RTViewer(props: Props) {
  const { value, className = "" } = props;

  if (!value) {
    return null;
  }

  let parsedValue: string | RawDraftContentState = value;
  try {
    parsedValue = JSON.parse(value);
  } catch (e) {
    // not richtext format
  }
  if (typeof parsedValue === "string") {
    return <p className={className}>{parsedValue}</p>;
  }

  const contentState = convertFromRaw(parsedValue);

  return (
    <div className={className}>{renderHTML(convertToHTML(contentState))}</div>
  );
}
