import * as React from "react";
import * as ReactRedux from "react-redux";
import {
  createRoutesFromElements,
  createBrowserRouter,
  Route,
  RouterProvider,
  createHashRouter,
} from "react-router-dom";
import { Dispatch } from "redux";
import { AdminApp } from "../../Admin";
import { GuardianApp } from "../../Guardian";
import SuperadminApp from "../../Superadmin";
import { UserPermissions } from "../../permissions";
import { getSettings } from "../../settings";
import { StoreState } from "../../store";
import { OIDCUser } from "../../types";
import { AvailableOrg, Translation } from "../reducer";
import { HeaderProps } from "./Header";
import { SignInCallbackViewContainer } from "./SignInCallback";
import { SignOutCallbackViewContainer } from "./SignOutCallback";

interface StateProps {
  isInitialized: boolean;
  user: OIDCUser | null;
  fatalError: string | null;
  errorSplash: { title: string; message?: string } | null;
  userPermissions: UserPermissions | null;
  currentOrg: string | null;
  availableOrgs: AvailableOrg[] | null;
  uiLanguage: string;
  skipPermissionCheck?: boolean;
}

interface OutProps {}

interface Props extends StateProps, OutProps, HeaderProps, Translation {
  dispatch: Dispatch<any>;
}

interface AppState {
  signinRedirect?: string;
}

export class App extends React.PureComponent<Props, AppState> {
  private Root = (props: any) => {
    const mergedProps = { ...props, ...this.props };
    return <GuardianApp {...mergedProps} />;
  };

  private Admin = (props: any) => {
    const mergedProps = { ...props, ...this.props };
    return <AdminApp {...mergedProps} />;
  };

  public render() {
    const routes = (
      <Route>
        <Route
          path="/signin-callback"
          element={<SignInCallbackViewContainer />}
        />
        <Route
          path="/signout-callback"
          element={<SignOutCallbackViewContainer />}
        />
        <Route path="/admin/*" element={<this.Admin />} />
        <Route path="/superadmin/*" element={<SuperadminApp />} />
        <Route path="/*" element={<this.Root />} />
      </Route>
    );
    const Router = getSettings().useHashRouter
      ? createHashRouter(createRoutesFromElements(routes))
      : createBrowserRouter(createRoutesFromElements(routes));
    return <RouterProvider router={Router} />;
  }
}

export const AppContainer: any = ReactRedux.connect<
  StateProps,
  {},
  OutProps,
  StoreState
>((state) => ({
  isInitialized: state.app.isInitialized,
  user: state.app.user,
  fatalError: state.app.fatalError,
  errorSplash: state.app.error,
  userPermissions: state.app.permissions,
  currentOrg: state.app.context.org,
  availableOrgs: state.app.availableOrgs,
  uiLanguage: state.app.uiLanguage,
  skipPermissionCheck: state.app.skipPermissionCheck,
}))(App);
