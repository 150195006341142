import React from "react";
import { useTranslation } from "../../App/reducer";
import { copyToClipboard } from "../../Utils/dom";
import { Button } from "./Button";
import { HelpTooltip } from "./HelpTooltip";

interface Props {
  getElementToCopy: () => HTMLElement | null;
  text?: string;
  tooltipText?: string;
}

export const ClipboardCopyButton = (props: Props) => {
  const { tr } = useTranslation();

  return (
    <React.Fragment>
      <Button
        icon={<i className="far fa-clipboard" />}
        label={tr("copyTableLabel")}
        level="secondary"
        onClick={() => copyToClipboard(props.getElementToCopy())}
      />
      {props.tooltipText && (
        <span className="ml-3">
          <HelpTooltip tooltipText={props.tooltipText} />
        </span>
      )}
    </React.Fragment>
  );
};
