import moment from "moment";
import React from "react";
import { assertUnreachable } from "../../Utils/typeHelpers";

const formats = {
  date: "YYYY-MM-DD",
  dateTime: "YYYY-MM-DD HH:mm",
};

interface Props {
  date: Date | string;
  type?: "format" | "relative" | "calendar";
  // relative?: boolean;
  skipRelativeSuffix?: boolean;
  format?: keyof typeof formats;
}

export const Time = (props: Props) => {
  const { type = "format" } = props;

  const fullAbsTime = moment(props.date).format(formats.dateTime);

  let time: string = moment(props.date).format(
    props.format ? formats[props.format] : formats.date
  );

  switch (type) {
    case "format":
      // already defined as default
      break;

    case "relative":
      time = moment(props.date).fromNow(props.skipRelativeSuffix);
      break;

    case "calendar":
      time = moment(props.date).calendar();
      break;

    default:
      assertUnreachable(type);
      break;
  }

  return <time title={fullAbsTime}>{time}</time>;
};
