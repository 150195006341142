import * as gqltypes from "../gqltypes";

function maybeId(id?: string) {
  if (!id) return "";
  return `/${id}`;
}

function getPublicationAnswersLink(obj: {
  customerId: string;
  schoolUnitId: string;
  studentGroupId?: string;
  publicationId?: string;
}) {
  return `/admin/schools/${obj.customerId}/${obj.schoolUnitId}${maybeId(
    obj.studentGroupId
  )}${maybeId(obj.publicationId)}`;
}

function getLinkToFavorite(fav: {
  type: gqltypes.RecordHistoryType;
  data: gqltypes.HistoryRecord["data"];
}) {
  switch (fav.type) {
    case gqltypes.RecordHistoryType.classResponses:
      return getPublicationAnswersLink(
        fav.data as gqltypes.ClassResponsesHistoryData
      );
    case gqltypes.RecordHistoryType.form:
      return `/admin/editForm/${(fav.data as gqltypes.FormHistoryData).formId}`;
  }
  return ``;
}

export const links = {
  profile: () => "/profile",
  toFavorite: getLinkToFavorite,

  admin: {
    start: () => "/admin",
    profile: () => "/admin/profile",

    form: {
      create: () => "/admin/createForm",
      edit: (id: string) => `/admin/editForm/${id}`,
      list: () => "/admin/forms",
      preview: (id: string) => `/admin/formView/${id}`,
    },

    publication: {
      create: () => "/admin/createPublication",
      edit: (id: string) => `/admin/editPublication/${id}`,
      list: () => "/admin/publications",
      listAfterSave:(id:string) => `/admin/publications/${id}`,
      response: (id: string) => `/admin/formResponse/${id}`,
      answers: getPublicationAnswersLink,
      fill: (recipientId: string) =>
        `/admin/fillPublicationResponse/${recipientId}`,
      reports: () => "/admin/reports",
      orgReports: () => "/admin/orgreports",
    },

    application: {
      create: () => "/admin/createApplication",
      edit: (id: string) => `/admin/EditApplication/${id}`,
      list: () => "/admin/applications",
      response: (id: string) => `/admin/applicationResponse/${id}`,
      report: (id?: string) => `/admin/applicationReport${maybeId(id)}`,
      answers: (id?: string) => `/admin/applicationAnswers${maybeId(id)}`,
    },

    user: {
      find: () => "/admin/user",
      get: (id: string) => `/admin/user/${id}`,
    },

    permissions: {
      manage: () => "/admin/permissions",

      user: (user: {
        id: string;
        customerId?: string | null;
        source: gqltypes.UserSource;
      }) =>
        `/admin/permissions/user/${user.source.substr(0, 1)}/${
          user.customerId ? `${encodeURIComponent(user.customerId)}/` : ""
        }${encodeURIComponent(user.id)}`,

      client: (id: string) =>
        `/admin/permissions/client/${encodeURIComponent(id)}`,
    },
  },
};
