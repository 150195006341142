import React from "react";
import { ConfirmOptions } from "../../Utils/dialogs/confirmTypes";
import { Dialog } from "./Dialog";
import { Button } from "..";

interface Props {
  show: boolean;
  proceed: () => void;
  cancel: () => void;
  dismiss: () => void;
  awaitingProceedFunc: boolean;
  options: ConfirmOptions;
  disabledProceed?: boolean;
}

export function ConfirmDialog(props: Props) {
  const { show, proceed, cancel, options, awaitingProceedFunc } = props;
  return (
    <Dialog
      show={show}
      cancel={cancel}
      title={options.title}
      content={
        options.content
          ? typeof options.content === "function"
            ? options.content()
            : options.content
          : options.message
      }
      size={options.size}
      footer={
        awaitingProceedFunc ? (
          options.proceedFuncFooter || "..."
        ) : (
          <React.Fragment>
            <Button
              level="secondary"
              onClick={cancel}
              label={options.cancelText}
            />{" "}
            <Button
              level={options.proceedLevel || "primary"}
              onClick={proceed}
              label={options.proceedText}
              disabled={props.disabledProceed}
            />
          </React.Fragment>
        )
      }
    />
  );
}
