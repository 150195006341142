import * as _ from "lodash";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "../../App/reducer";
import { Button, FormTextArea, ISTContainer } from "../../Common";
import { EmailPreview } from "../../Common/components/EmailPreview";
import { useDidChangeThisRender } from "../../Common/hooks";
import * as gqltypes from "../../gqltypes";
import { ORGANISATIONS_QUERY } from "./Organisations";

export const EmailTemplateContainer = () => {
  const { uiLanguage } = useTranslation();
  const [version, setVersion] = useState(0); // used to be able to reload template
  const [type, setType] = useState(
    Object.keys(gqltypes.EmailTemplateType)[0] as gqltypes.EmailTemplateType
  );
  const [language, setLanguage] = useState(uiLanguage);
  const [userMessage, setUserMessage] = useState("Message");

  const orgs = useQuery<gqltypes.SuperadminOrganisations>(ORGANISATIONS_QUERY);
  const firstOrg = orgs.data?.organisations[0];

  const [org, setOrg] = useState(firstOrg?.id);
  const [sender, setSender] = useState(firstOrg?.displayName);

  const loaded = useDidChangeThisRender(firstOrg);
  if (loaded && firstOrg) {
    setOrg(firstOrg.id);
    setSender(firstOrg.displayName);
  }

  return (
    <main>
      <p className="col-12 col-md-9 p-0 pb-3">Visa email mall</p>
      <ISTContainer header="Email">
        <div className="p-content">
          {orgs.data ? (
            <div>
              <select
                onChange={(value) => {
                  const orgId = value.currentTarget.value;
                  setOrg(orgId);
                  setSender(
                    orgs.data?.organisations.find((o) => o.id === orgId)
                      ?.displayName ?? "Unknown"
                  );
                }}
              >
                {orgs.data.organisations.map((o) => (
                  <option key={o.id} value={o.id}>
                    {o.displayName}
                  </option>
                ))}
              </select>
            </div>
          ) : null}

          <select
            onChange={(val) => setLanguage(val.currentTarget.value)}
            value={language}
          >
            {Object.keys(gqltypes.ISO6391Language).map((availableType) => (
              <option key={availableType}>{availableType}</option>
            ))}
          </select>

          <select
            value={type}
            onChange={(val) =>
              setType(val.currentTarget.value as gqltypes.EmailTemplateType)
            }
          >
            {Object.keys(gqltypes.EmailTemplateType).map((availableType) => (
              <option key={availableType}>{availableType}</option>
            ))}
          </select>

          <Button
            label="Ladda om"
            onClick={() => {
              setVersion(version + 1);
            }}
          />

          {type.includes("WithMessage") ||
          type === "revokedPublication" ||
          type === "childRevokedPublication" ? (
            <FormTextArea
              id="userMessage"
              value={userMessage}
              onChange={(event) => {
                setUserMessage(event.currentTarget.value);
              }}
            />
          ) : null}

          <br />
          <br />
          <h3>Förhandsvisning</h3>
          <EmailPreview
            key={version}
            type={type}
            language={language}
            org={org}
            substitutions={{
              message: userMessage,
            }}
            disableCache
          />
        </div>
      </ISTContainer>
    </main>
  );
};
