import React from "react";
import Select, { MultiValue } from "react-select";
import { Translation } from "../../App/reducer";
import { useRandomId } from "../hooks";
import { HelpTooltip } from "./HelpTooltip";

interface Props {
  tr: Translation["tr"];
  className?: string;
  value: string[];
  onChange: (newState: MultiValue<ValueType>) => void;
}

interface ValueType {
  value: string;
  label: string;
}

const getTagLabel = (tr: Translation["tr"], tag: string) => {
  switch (tag) {
    // Commenting these labels for hiding unused functionality and keeping the possibility for future development
    /*     case "food":
      return tr("defaultTagFood");
    case "it":
      return tr("defaultTagIT");
    case "photo":
      return tr("defaultTagPhoto");
    case "language":
      return tr("defaultTagLanguage");
    case "health":
      return tr("defaultTagHealth"); */
    case "cico_food":
      return tr("defaultTagCicoFood");

    default:
      return tag;
  }
};

const getDefaultTags = (tr: Translation["tr"]): ValueType[] => {
  // Commenting these labels for hiding unused functionality and keeping the possibility for future development
  // const tags = ["food", "it", "photo", "language", "health", "cico_food"];
  const tags = ["cico_food"];

  return tags.map((tag) => ({
    value: tag,
    label: getTagLabel(tr, tag),
  }));
};

export const FormSelectTags = (props: Props) => {
  const id = useRandomId();

  const { tr, value, onChange, className } = props;

  return (
    <div className={`form-group ${className ?? ""}`}>
      <label htmlFor={id}>{tr("tags")}</label>{" "}
      <HelpTooltip tooltipText={tr("formSelectTagsLabelTooltip")} />
      <Select
        id={id}
        isMulti
        classNamePrefix="search-select"
        onChange={(state: MultiValue<ValueType> | null) => {
          onChange(state || []);
        }}
        options={getDefaultTags(tr)}
        noOptionsMessage={() => tr("formSelectTagNoOptionsMessage")}
        value={value.map((tag) => ({
          value: tag,
          label: getTagLabel(tr, tag),
        }))}
        placeholder={tr("formSelectTagsPlaceholder")}
      />
    </div>
  );
};
