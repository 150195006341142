import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
import { allFulfilledPredicates } from "../../Admin/components/CreateForm";
import { FormDoesNotExist } from "../../Admin/components/FormDoesNotExist";
import { Translation, useTranslation, useUserProfile } from "../../App/reducer";
import { Button, ConsentForm, FormSelect, Loading } from "../../Common";
import { useSaveManager } from "../../Common/SaveManager";
import {
  useDidChangeThisRender,
  useMutexLockHandler,
} from "../../Common/hooks";
import {
  CREATE_RESPONSE_MUTATION,
  CREATE_RESPONSE_MUTATION_TYPES,
  RELEASE_PUBLICATION_RESPONSE_LOCK_MUTATION,
  RELEASE_PUBLICATION_RESPONSE_LOCK_MUTATION_TYPES,
  REMOVE_DRAFT_MUTATION,
  REMOVE_DRAFT_MUTATION_TYPES,
  REMOVE_SIGNATURES_FROM_DRAFT_MUTATION,
  REMOVE_SIGNATURES_FROM_DRAFT_MUTATION_TYPES,
  SAVE_RESPONSE_MUTATION,
  SAVE_RESPONSE_MUTATION_TYPES,
  SIGN_PUBLICATION_RESPONSE_MUTATION,
  SIGN_PUBLICATION_RESPONSE_MUTATION_TYPES,
  TRY_TAKE_PUBLICATION_RESPONSE_LOCK_MUTATION,
  TRY_TAKE_PUBLICATION_RESPONSE_LOCK_MUTATION_TYPES,
} from "../../Common/mutations";
import {
  PUBLICATION_RECIPIENT_QUERY,
  PUBLICATION_RECIPIENT_QUERY_TYPES,
  PUBLICATION_RESPONSE_QEURY,
  PUBLICATION_RESPONSE_QEURY_TYPES,
} from "../../Common/queries";
import { ComplexSet, getCombinedIdCustomerId } from "../../Common/utils";
import { isValidQuestionAnswer } from "../../Common/validation";
import {
  showAlertDialog,
  showConfirmDialog,
  showErrorDialog,
} from "../../Utils/dialogs/showDialog";
import { deepOmit, definedNotNull, equalsAny } from "../../Utils/functional";
import curry from "../../curry";
import * as gqltypes from "../../gqltypes";
import {
  defaultDialogCancel,
  defaultDialogProceed,
} from "../../translation/strings";
import { Progress, SavingState } from "../../types";

const isLockValid = (lockExpire: string | null | undefined) => {
  if (!lockExpire) {
    return false;
  }

  return new Date(lockExpire).getTime() > Date.now();
};

const lockTimeRemaining = (lockExpire: string | null | undefined) => {
  if (!lockExpire) {
    return 0;
  }

  const ms = new Date(lockExpire).getTime() - Date.now();

  return Math.max(0, ms);
};

function AllDoneNotification(props: { tr: Translation["tr"] }) {
  return (
    <div className="alert alert-success">
      {props.tr("guardianFormViewFormValid")}
    </div>
  );
}

export const allQuestionsMustBeValid = (
  response: gqltypes.FormAnswer | undefined,
  c: {
    id: string;
    questions: gqltypes.FormQuestion[];
  }
) => {
  const componentResponse =
    response &&
    response.components &&
    response.components.find((r) => r.componentId === c.id);
  return c.questions.every((q) => {
    const questionResponse =
      componentResponse &&
      componentResponse.questions.find((qr) => qr.questionId === q.id);

    if (!questionResponse) {
      return !q.compulsory;
    }

    return isValidQuestionAnswer(q, questionResponse);
  });
};

function PartiallyDoneYouSignedNotification(props: { tr: Translation["tr"] }) {
  return (
    <div className="alert alert-warning">
      {props.tr("guardianFormViewWaitingForOtherGuardian")}
    </div>
  );
}

function PartiallyDoneOtherSignedNotification(props: {
  tr: Translation["tr"];
  signers: string[];
}) {
  return (
    <div className="alert alert-warning">
      {props.tr("guardianFormViewWaitingForUser", props.signers.join(", "))}
    </div>
  );
}

function StatusNotification(props: {
  tr: Translation["tr"];
  youSigned: boolean;
  responseStatus: gqltypes.PublicationResponseStatus;
  signers: string[];
}) {
  switch (props.responseStatus) {
    case gqltypes.PublicationResponseStatus.valid:
      return <AllDoneNotification tr={props.tr} />;
    case gqltypes.PublicationResponseStatus.partially_signed:
      if (props.youSigned) {
        return <PartiallyDoneYouSignedNotification tr={props.tr} />;
      }
      return (
        <PartiallyDoneOtherSignedNotification
          tr={props.tr}
          signers={props.signers}
        />
      );
    default:
      return null;
  }
}

const LockedByOtherNotification = (props: {
  name: string | null;
  tr: Translation["tr"];
}) => {
  const guardianName = props.name || props.tr("theOtherGuardian");
  return (
    <div className="alert alert-warning">
      {props.tr("guardianFormViewLockedByOtherGuardian", guardianName)}
    </div>
  );
};

const CopyComponent = (props: {
  tr: Translation["tr"];
  open: boolean;
  toggle: () => void;
  copyable: gqltypes.publicationRecipient["publicationRecipient"]["copyableResponses"];
  preventsEdits: boolean;
  selectedCopyableResponse: string;
  setSelectedCopyableResponse: (selected: string) => void;
  lastCopiedResponse: string | undefined;
  copyResponse: () => void;
}) => {
  const {
    tr,
    open,
    toggle,
    copyable,
    preventsEdits,
    selectedCopyableResponse,
    lastCopiedResponse,
    copyResponse,
  } = props;

  console.log("LAST", lastCopiedResponse);

  const getName = (user: (typeof copyable)[0]["user"]) => {
    return user ? user.name : "<" + tr("failedToFetchUser") + ">";
  };

  return (
    <div
      className={`box form-section p-content ${open ? "" : "clickable"}`}
      style={{ backgroundColor: "#FFFCC6" }}
      onClick={open ? undefined : toggle}
    >
      <div className="row">
        <div className="col-12">
          <h5>
            <strong>{tr("guardianFormViewCopyAnswerTitle")}</strong>
          </h5>
        </div>
        {open ? (
          <>
            <div className="col-12 mb-3">
              {tr("guardianFormViewCopyAnswerDescription")}
            </div>
            {copyable.length === 1 ? (
              <div className="col-6">
                {tr("guardianFormViewCopyAnswerFrom")}
                <br />
                <strong>{getName(copyable[0].user)}</strong>
              </div>
            ) : (
              <FormSelect
                className="col-12 col-md-6"
                value={selectedCopyableResponse}
                onChange={(e) =>
                  props.setSelectedCopyableResponse(e.currentTarget.value)
                }
                label={tr("guardianFormViewCopyAnswerFrom")}
                options={copyable.map((c) => {
                  const userName = getName(c.user);
                  return {
                    value: c.id,
                    label: tr(
                      "formSentInOptionRow",
                      userName,
                      moment(c.modified).fromNow()
                    ),
                  };
                })}
              />
            )}
            <div className="col-12 ml-auto col-md-auto d-flex justify-content-between">
              <Button
                className="align-self-center mt-2 mr-md-content"
                label={tr("close")}
                onClick={toggle}
                level="secondary"
              />
              <Button
                className="align-self-center mt-2"
                onClick={copyResponse}
                disabled={
                  preventsEdits ||
                  equalsAny(selectedCopyableResponse, ["", lastCopiedResponse])
                }
                label={
                  selectedCopyableResponse === lastCopiedResponse ? (
                    <span>
                      <i className="fa fa-check mr-2" />
                      {tr("guardianFormViewCopyAnswerCopied")}
                    </span>
                  ) : (
                    tr("guardianFormViewCopyAnswerActionLabel")
                  )
                }
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

function getSaveText(tr: Translation["tr"], saveState: SavingState) {
  switch (saveState) {
    case SavingState.Saved:
      return tr("allChangesSaved");
    case SavingState.Saving:
      return tr("saving");
    default:
      return tr("save");
  }
}

async function handleUnlockDraft(
  tr: Translation["tr"],
  state: ReturnType<typeof usePublicationResponseState>,
  mutations: ReturnType<typeof usePublicationResponseMutations>
) {
  await showConfirmDialog({
    title: tr("guardianFormViewUnlockDraftTitle"),
    proceedText: tr("guardianFormViewUnlockDraftProceed"),
    cancelText: defaultDialogCancel(tr),
    proceedFunc: async (hostComponent) => {
      const responseData = state.publicationResponse;
      if (!responseData) {
        throw new Error("handleRemoveDraft encountered undefined values");
      }
      try {
        await mutations.removeSignatures({
          variables: { id: responseData.id },
        });
      } catch (e) {
        alert(e);
        hostComponent.dismiss();
        return;
      }
      hostComponent.dismiss();
    },
    proceedFuncFooter: (
      <span>
        <i className="fa fa-spinner fa-spin mr-2" />
        {tr("draftUnlocking")}
      </span>
    ),
    message: tr("draftUnlockWarning"),
  });
}

async function handleRemoveDraft(
  tr: Translation["tr"],
  state: ReturnType<typeof usePublicationResponseState>,
  mutations: ReturnType<typeof usePublicationResponseMutations>,
  onDone: () => void
) {
  await showConfirmDialog({
    title: tr("guardianFormViewRemoveDraftTitle"),
    proceedText: tr("guardianFormViewRemoveDraftProceed"),
    cancelText: defaultDialogCancel(tr),
    proceedFunc: async (hostComponent) => {
      const responseData = state.publicationResponse;
      if (!responseData) {
        throw new Error("handleRemoveDraft encountered undefined values");
      }
      try {
        await mutations.removeDraft({
          variables: { responseId: responseData.id },
        });
      } catch (e) {
        alert(e);
        hostComponent.dismiss();
        return;
      }
      hostComponent.dismiss();
      onDone();
    },
    proceedFuncFooter: (
      <span>
        <i className="fa fa-spinner fa-spin mr-2" />
        {tr("draftRemoving")}
      </span>
    ),
    message: tr("draftRemoveWarning"),
  });
}

function validate(
  formData: gqltypes.publicationRecipient,
  response: gqltypes.FormAnswer
) {
  const form =
    formData &&
    formData.publicationRecipient &&
    formData.publicationRecipient.publication.form;
  const componentData = form && form.componentData;
  const components = componentData && componentData.components;

  if (!components || !componentData) {
    throw new Error("components and componentData must be defined");
  }

  const visiblePredicates = allFulfilledPredicates(components, response);

  const predicateComponents = visiblePredicates
    .map(
      (p) =>
        componentData.predicateComponents &&
        componentData.predicateComponents.find((pc) => pc.id === p.componentId)
    )
    .filter(definedNotNull);

  const allValid = curry(allQuestionsMustBeValid, response);

  return predicateComponents.every(allValid) && components.every(allValid);
}

function savePublicationResponseHandler(
  id: string | undefined,
  state: ReturnType<typeof usePublicationResponseState>,
  mutations: ReturnType<typeof usePublicationResponseMutations>
) {
  if (!id) {
    throw new Error("savePublicationResponse expected id to be set");
  }
  if (!state.response) {
    throw new Error("savePublicationResponse expected response to be set");
  }

  if (!state.responseLanguage) {
    throw new Error("Language undefined when saving");
  }

  return mutations.savePublicationResponse({
    variables: {
      responseId: id,
      language: state.responseLanguage,
      response: deepOmit(
        state.response,
        (key: string) => key === "__typename" || key === "redacted"
      ),
    },
  });
}

async function handleSign(
  tr: Translation["tr"],
  recipientData: gqltypes.publicationRecipient,
  responseData: gqltypes.publicationResponse,
  state: ReturnType<typeof usePublicationResponseState>,
  mutations: ReturnType<typeof usePublicationResponseMutations>
) {
  const responseRecord =
    state.publicationResponse ||
    (responseData && responseData.publicationResponse);
  if (!responseRecord) {
    throw new Error("responseData must be defined");
  }
  if (!state.response) {
    throw new Error("handleSign expected response to be set");
  }

  const response = deepOmit(
    state.response,
    (key: string) => key === "__typename" || key === "redacted"
  );
  if (!validate(recipientData, response)) {
    state.setShowValidation(true);
    return;
  }
  await showConfirmDialog({
    title: tr("guardianFormViewAcceptAnswerTitle"),
    message: responseRecord.validIfSigned
      ? tr("guardianFormViewAcceptAnswerValidDirectMessage")
      : tr("guardianFormViewAcceptAnswerValidAdditionalGuardianMessage"),
    proceedText: tr("accept"),
    cancelText: defaultDialogCancel(tr),
    proceedFuncFooter: (
      <span>
        <i className="fa fa-spinner fa-spin mr-2" />
        {tr("accepting")}
      </span>
    ),
    proceedFunc: async (hostComponent) => {
      state.setSignState(Progress.InProgress);
      try {
        await mutations.signPublicationResponse({
          variables: {
            id: responseRecord.id,
            response: JSON.stringify(response),
          },
        });
        hostComponent.dismiss();
        state.setSignState(Progress.Success);
      } catch (e) {
        hostComponent.dismiss();
        state.setSignState(Progress.Error);
        showAlertDialog({
          title: tr("error"),
          message: tr("errorApproveResponse"),
          proceedText: defaultDialogProceed(tr),
          cancelText: defaultDialogCancel(tr),
        });
      }
    },
  });
}

async function handleChangeAnswer(
  tr: Translation["tr"],
  recipientData: gqltypes.publicationRecipient,
  state: ReturnType<typeof usePublicationResponseState>,
  mutations: ReturnType<typeof usePublicationResponseMutations>,
  navigate: ReturnType<typeof useNavigate>
) {
  const recipient = recipientData.publicationRecipient;
  if (!recipient) {
    throw new Error("recipient was undefined");
  }
  // If a draft already exists, redirect to it
  // without confirmation prompt since either:
  // a) the user has already previously confirmed creating a new response
  // b) another guardian has confirmed creating a new response
  // ... and to conform with the behavior of "Ändra svar" button visible
  // on the start screen
  const existingDraft = recipient.responses.find(
    (r) => r.status !== gqltypes.PublicationResponseStatus.valid
  );
  if (existingDraft) {
    return navigate(`/form/${recipient.id}/${existingDraft.id}`);
  }

  const exisitingAnswer = recipient.responses.find(
    (r) => r.status === gqltypes.PublicationResponseStatus.valid
  );

  const requiresAllGuardians = exisitingAnswer
    ? exisitingAnswer.validIfSigned
    : false;

  return showConfirmDialog({
    title: tr("guardianFormViewChangeAnswerTitle"),
    content: (
      <div>
        <p>{tr("guardianFormViewChangeAnswerContent1")}</p>
        <p>
          {requiresAllGuardians && tr("guardianFormViewChangeAnswerContent2")}
        </p>
      </div>
    ),
    proceedFuncFooter: (
      <span>
        <i className="fa fa-spinner fa-spin mr-2" />
        {tr("loading")}...
      </span>
    ),
    proceedFunc: async (hostComponent) => {
      try {
        const response = deepOmit(
          state.publicationResponse!.response!,
          (key: string) => key === "__typename" || key === "redacted"
        );
        const result = await mutations.createResponse({
          variables: {
            recipientId: recipient.id!,
            response,
          },
        });
        if (!result || !result.data) {
          throw new Error("Result missing");
        }
        hostComponent.dismiss();
        navigate(
          `/form/${recipient.id}/${result.data.createPublicationResponse.id}`
        );
      } catch (e) {
        hostComponent.dismiss();
        showErrorDialog({
          title: tr("error"),
          message: tr("errorChangeResponse"),
          proceedText: defaultDialogProceed(tr),
          cancelText: defaultDialogCancel(tr),
        });
      }
    },
    proceedText: tr("changeAnswer"),
    cancelText: defaultDialogCancel(tr),
  });
}

const usePublicationResponseMutations = (responseId?: string) => {
  const [savePublicationResponse] = useMutation<
    SAVE_RESPONSE_MUTATION_TYPES["data"],
    SAVE_RESPONSE_MUTATION_TYPES["variables"]
  >(SAVE_RESPONSE_MUTATION, {
    fetchPolicy: "no-cache",
  });

  const [signPublicationResponse] = useMutation<
    SIGN_PUBLICATION_RESPONSE_MUTATION_TYPES["data"],
    SIGN_PUBLICATION_RESPONSE_MUTATION_TYPES["variables"]
  >(SIGN_PUBLICATION_RESPONSE_MUTATION);

  const [createResponse] = useMutation<
    CREATE_RESPONSE_MUTATION_TYPES["data"],
    CREATE_RESPONSE_MUTATION_TYPES["variables"]
  >(CREATE_RESPONSE_MUTATION);

  /**
   * Locks
   */
  const [tryTakePublicationLock] = useMutation<
    TRY_TAKE_PUBLICATION_RESPONSE_LOCK_MUTATION_TYPES["data"],
    TRY_TAKE_PUBLICATION_RESPONSE_LOCK_MUTATION_TYPES["variables"]
  >(TRY_TAKE_PUBLICATION_RESPONSE_LOCK_MUTATION, {
    variables: { id: responseId! },
  });

  const [releasePublicationLock] = useMutation<
    RELEASE_PUBLICATION_RESPONSE_LOCK_MUTATION_TYPES["data"],
    RELEASE_PUBLICATION_RESPONSE_LOCK_MUTATION_TYPES["variables"]
  >(RELEASE_PUBLICATION_RESPONSE_LOCK_MUTATION, {
    variables: { id: responseId! },
  });

  const [removeDraft] = useMutation<
    REMOVE_DRAFT_MUTATION_TYPES["data"],
    REMOVE_DRAFT_MUTATION_TYPES["variables"]
  >(REMOVE_DRAFT_MUTATION, {
    variables: { responseId: responseId! },
  });

  const [removeSignatures] = useMutation<
    REMOVE_SIGNATURES_FROM_DRAFT_MUTATION_TYPES["data"],
    REMOVE_SIGNATURES_FROM_DRAFT_MUTATION_TYPES["variables"]
  >(REMOVE_SIGNATURES_FROM_DRAFT_MUTATION, {
    variables: { id: responseId! },
  });

  return {
    ready: Boolean(responseId),
    savePublicationResponse,
    signPublicationResponse,
    createResponse,
    tryTakePublicationLock,
    releasePublicationLock,
    removeDraft,
    removeSignatures,
  };
};

const usePublicationResponseState = (
  publicationRecipient?: gqltypes.publicationRecipient["publicationRecipient"],
  serverPublicationResponse?: gqltypes.publicationResponse["publicationResponse"]
) => {
  const [showValidation, setShowValidation] =
    useState<State["showValidation"]>(false);
  const [copyPartOpen, setCopyPartOpen] = useState<State["copyPartOpen"]>(true);
  const [selectedCopyableResponse, setSelectedCopyableResponse] =
    useState<State["selectedCopyableResponse"]>("");
  const [lastCopiedResponse, setLastCopiedResponse] =
    useState<State["lastCopiedResponse"]>();
  const [response, setResponse] = useState<State["response"]>();
  const [publicationResponse, setPublicationResponse] =
    useState<State["publicationResponse"]>();
  const [responseLanguage, setResponseLanguage] =
    useState<State["responseLanguage"]>();
  const [signState, setSignState] = useState<State["signState"]>(
    Progress.NotStarted
  );

  /**
   * Part to handle when the new updates are collected from server
   */
  const publicationRecipientChanged =
    useDidChangeThisRender(publicationRecipient);
  const serverPublicationResponseChanged = useDidChangeThisRender(
    serverPublicationResponse
  );

  if (
    serverPublicationResponse &&
    serverPublicationResponse.response &&
    serverPublicationResponseChanged
  ) {
    setPublicationResponse(serverPublicationResponse);
    setResponse(serverPublicationResponse.response);
    setResponseLanguage(serverPublicationResponse.language);
  }

  if (publicationRecipient && publicationRecipientChanged) {
    const copyable = _.orderBy(
      publicationRecipient.copyableResponses,
      "modified",
      "desc"
    );
    if (copyable.length) {
      if (
        lastCopiedResponse &&
        copyable.find((c) => c.id === lastCopiedResponse)
      ) {
        setSelectedCopyableResponse(lastCopiedResponse);
      } else {
        setSelectedCopyableResponse(copyable[0].id);
      }
    }
  }
  /**
   * End update from server
   */

  return {
    showValidation,
    setShowValidation,
    copyPartOpen,
    setCopyPartOpen,
    selectedCopyableResponse,
    setSelectedCopyableResponse,
    lastCopiedResponse,
    setLastCopiedResponse,
    response,
    setResponse,
    publicationResponse,
    setPublicationResponse,
    responseLanguage,
    setResponseLanguage,
    signState,
    setSignState,
  };
};

export const PublicationResponseView = () => {
  const { tr } = useTranslation();
  const user = useUserProfile();
  const navigate = useNavigate();
  const params = useParams();

  const publicationRecipientData = useQuery<
    PUBLICATION_RECIPIENT_QUERY_TYPES["data"],
    PUBLICATION_RECIPIENT_QUERY_TYPES["variables"]
  >(PUBLICATION_RECIPIENT_QUERY, {
    variables: { id: params.recipientId! },
  });

  const responseData = useQuery<
    PUBLICATION_RESPONSE_QEURY_TYPES["data"],
    PUBLICATION_RESPONSE_QEURY_TYPES["variables"]
  >(PUBLICATION_RESPONSE_QEURY, {
    skip: !params.responseId,
    variables: { responseId: params.responseId! },
  });

  const publicationRecipient =
    publicationRecipientData?.data?.publicationRecipient;
  const publicationResponse = responseData?.data?.publicationResponse;
  const state = usePublicationResponseState(
    publicationRecipient,
    publicationResponse
  );

  const mutations = usePublicationResponseMutations(publicationResponse?.id);

  const save = async () => {
    console.log("SAVE???");
    if (!state.response) return;

    const responseId = publicationResponse?.id;
    const response = deepOmit(
      state.response,
      (key: string) => key === "__typename" || key === "redacted"
    );
    const language = state.responseLanguage;

    console.log({ responseId, language, response });
    if (!responseId || !response || !language)
      throw new Error("Missing something");

    console.log("saving");

    return mutations.savePublicationResponse({
      variables: { responseId, language, response },
    });
  };

  const saveManager = useSaveManager(
    () =>
      savePublicationResponseHandler(publicationResponse?.id, state, mutations),
    (result) => {
      if (result) {
        console.log("SUCCCESS", result);
      }
    },
    () => {
      showErrorDialog({
        title: tr("error"),
        message: tr("errorGenericSave"),
        proceedText: defaultDialogProceed(tr),
        cancelText: defaultDialogCancel(tr),
      });
    },
    {}
  ); // TODO: fix

  const responseLocker = publicationResponse?.lockHolder;

  const responseLockExpire = publicationResponse?.lockExpire;

  const lockedByOtherThanMe =
    isLockValid(responseLockExpire) && responseLocker
      ? responseLocker.id !== user.actorId
      : false;

  const lock = useMutexLockHandler({
    tryTakeLock: async () => {
      if (!mutations.ready) return false;

      return mutations.tryTakePublicationLock().then((r) => {
        const pubRes = r.data?.tryTakePublicationResponseLock;

        if (!pubRes) return false;

        return pubRes
          ? isLockValid(pubRes.lockExpire) && pubRes.lockHolder
            ? pubRes.lockHolder.id === user.actorId
            : false
          : false;
      });
    },
    releaseLock: mutations.releasePublicationLock,
    isHoldingLock: () =>
      isLockValid(responseLockExpire) && responseLocker
        ? responseLocker.id === user.actorId
        : false,
    lockTimeRemaining: lockTimeRemaining(responseLockExpire),
    // onAquireLock: () => {},
    onLockTimeout: () => {
      showAlertDialog({
        title: tr("inactive"),
        message: tr("guardianFormViewInactiveMessage"),
        onClose: () => {
          lock.tryTakeLock();
        },
        proceedText: defaultDialogProceed(tr),
        cancelText: defaultDialogCancel(tr),
      });
    },
  });

  if (
    !publicationRecipientData ||
    publicationRecipientData.loading ||
    (responseData && responseData.loading) ||
    state.publicationResponse === undefined
  ) {
    return <Loading />;
  }

  if (!state.publicationResponse) {
    return <FormDoesNotExist />;
  }

  if (!publicationRecipientData.data?.publicationRecipient) {
    throw new Error("publicationRecipient not loaded");
  }

  const recipient = publicationRecipientData.data?.publicationRecipient;
  const publication = recipient.publication;
  const form = publication.form;

  const signers =
    publicationResponse && publicationResponse.signatures
      ? publicationResponse.signatures.map((sig) =>
          sig.educloudUser ? sig.educloudUser.name || "Okänd" : "Okänd"
        )
      : [];

  function youHaveSigned() {
    const signaturesMeta =
      state.publicationResponse && state.publicationResponse.signatures;
    if (!user.educloudUserIds || !signaturesMeta) {
      return false;
    }

    const signersSet = new ComplexSet(
      getCombinedIdCustomerId,
      signaturesMeta.map((s) => ({
        id: s.educloudUserId,
        customerId: s.customerId,
      }))
    );

    return user.educloudUserIds.some((eid) => signersSet.has(eid));
  }

  function isPreventingEdits() {
    if (!state.publicationResponse) {
      return true;
    }

    return (
      !isHoldingLock ||
      youSigned ||
      allDone ||
      state.publicationResponse.status !==
        gqltypes.PublicationResponseStatus.not_signed
    );
  }

  const isHoldingLock = lock.holdsLock;
  const youSigned = youHaveSigned();
  const allDone =
    state.publicationResponse &&
    state.publicationResponse.status ===
      gqltypes.PublicationResponseStatus.valid;
  const preventsEdits = isPreventingEdits();

  const copyable = _.orderBy(recipient.copyableResponses, "modified", "desc");
  const submittedTime = allDone
    ? state.publicationResponse.modified
    : undefined;

  const btnClassName = "col-auto my-3";

  return (
    <div id="form-view">
      <StatusNotification
        tr={tr}
        youSigned={youSigned}
        responseStatus={state.publicationResponse.status}
        signers={signers}
      />
      {lockedByOtherThanMe && !allDone && (
        <LockedByOtherNotification tr={tr} name={responseLocker!.firstName} />
      )}
      <div style={{ opacity: preventsEdits ? 0.7 : 1 }}>
        <ConsentForm
          additionalComponents={
            copyable.length && !preventsEdits ? (
              <CopyComponent
                tr={tr}
                open={state.copyPartOpen}
                toggle={() => state.setCopyPartOpen(!state.copyPartOpen)}
                copyable={copyable}
                selectedCopyableResponse={state.selectedCopyableResponse}
                setSelectedCopyableResponse={(selected) =>
                  state.setSelectedCopyableResponse(selected)
                }
                lastCopiedResponse={state.lastCopiedResponse}
                copyResponse={async () => {
                  const copyPublicationResponse =
                    recipient &&
                    recipient.copyableResponses.find(
                      (r) => r.id === state.selectedCopyableResponse
                    );

                  if (!copyPublicationResponse) {
                    throw new Error("No publicationResponse");
                  }

                  const response = copyPublicationResponse.response;
                  if (!response) {
                    throw new Error("No response available");
                  }
                  if (state.response && state.response.components.length) {
                    const confirmed = await showConfirmDialog({
                      title: tr("copyReplaceWarningTitle"),
                      message: tr("copyReplaceWarningMessage"),
                      proceedText: defaultDialogProceed(tr),
                      cancelText: defaultDialogCancel(tr),
                    });
                    if (!confirmed) return;
                  }
                  state.setLastCopiedResponse(state.selectedCopyableResponse);
                  state.setResponse(
                    deepOmit(response, (key: string) => key === "redacted")
                  );
                  state.setResponseLanguage(copyPublicationResponse.language);
                  saveManager.save();
                }}
                preventsEdits={preventsEdits}
              />
            ) : null
          }
          formType={gqltypes.FormType.publication}
          formOwner={form.owner}
          child={recipient.user}
          submittedTime={submittedTime}
          formTitle={form.name}
          formDescription={form.description}
          predicateComponents={form.componentData.predicateComponents || []}
          components={form.componentData.components}
          formLanguage={form.language}
          response={state.response}
          validateAnswers={state.showValidation}
          readOnly={preventsEdits}
          updateResponse={
            preventsEdits
              ? undefined
              : (res) => {
                  state.setResponse(res);
                  saveManager.save();
                }
          }
          translating={
            state.responseLanguage && state.responseLanguage !== form.language
          }
          translation={form.translations.find(
            (translation) => translation.language === state.responseLanguage
          )}
          availableLanguages={[form.language].concat(form.translationLanguages)}
          displayAvailableLanguages
          handleLanguageChange={
            preventsEdits
              ? undefined
              : (language) => {
                  state.setResponseLanguage(
                    state.responseLanguage === language
                      ? form.language
                      : language
                  );
                  saveManager.save();
                }
          }
        />
      </div>
      <StatusNotification
        tr={tr}
        youSigned={youSigned}
        responseStatus={state.publicationResponse.status}
        signers={signers}
      />
      {lockedByOtherThanMe && !allDone && (
        <LockedByOtherNotification tr={tr} name={responseLocker!.firstName} />
      )}
      <div className="container">
        {preventsEdits ? null : (
          <div className="row mb-content">
            <div className="col-12">
              <Button
                className="w-100"
                size="btn-sm"
                icon={
                  saveManager.state === SavingState.Saving ? (
                    <i className="fa fa-spinner fa-spin" />
                  ) : (
                    <i className="fa fa-save" />
                  )
                }
                disabled={
                  !isHoldingLock ||
                  saveManager.state === SavingState.Saving ||
                  saveManager.state === SavingState.Saved
                }
                label={getSaveText(tr, saveManager.state)}
                level="secondary"
                onClick={saveManager.saveNow}
                type="submit"
              />
            </div>
          </div>
        )}
        <div className="row justify-content-between">
          <div className={btnClassName}>
            <Button
              onClick={() => navigate(-1)}
              level="secondary"
              label={tr("goBack")}
            />
          </div>

          {allDone ? (
            <div className={btnClassName}>
              <Button
                label={tr("newDraft")}
                level="secondary"
                onClick={() =>
                  handleChangeAnswer(
                    tr,
                    publicationRecipientData!.data!,
                    state,
                    mutations,
                    navigate
                  )
                }
              />
            </div>
          ) : (
            <>
              <div className={btnClassName}>
                {state.response &&
                state.publicationResponse.status === "not_signed" ? (
                  <Button
                    onClick={() =>
                      handleRemoveDraft(tr, state, mutations, () =>
                        navigate("/", { replace: true })
                      )
                    }
                    level="secondary"
                    label={tr("removeDraft")}
                    disabled={!isHoldingLock}
                  />
                ) : (
                  <Button
                    onClick={() => handleUnlockDraft(tr, state, mutations)}
                    level="secondary"
                    label={tr("unlockDraft")}
                    disabled={!isHoldingLock}
                  />
                )}
              </div>
              <div className={btnClassName}>
                <Button
                  onClick={() =>
                    handleSign(
                      tr,
                      publicationRecipientData!.data!,
                      responseData!.data!,
                      state,
                      mutations
                    )
                  }
                  label={
                    <span>
                      <i
                        className={`fa fa-spinner fa-spin mr-2 ${
                          state.signState === Progress.InProgress
                            ? ""
                            : "d-none"
                        }`}
                      />
                      {tr("accept")}
                    </span>
                  }
                  disabled={
                    !isHoldingLock ||
                    allDone ||
                    youSigned ||
                    saveManager.state !== SavingState.Saved
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

interface State {
  signState: Progress;
  saveState: SavingState;
  showValidation: boolean;
  copyPartOpen: boolean;
  selectedCopyableResponse: string;
  lastCopiedResponse?: string;
  response?: gqltypes.FormAnswer;
  publicationResponse?:
    | gqltypes.publicationResponse["publicationResponse"]
    | null;
  isHoldingLock: boolean;
  responseLanguage?: gqltypes.ISO6391Language;
}

export const PublicationResponseViewContainer = PublicationResponseView;
