import gql from "graphql-tag";
import * as _ from "lodash";
import * as React from "react";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router";
import { useAdminContext, useTranslation } from "../../App/reducer";
import { Button, FormInput, ISTContainer, Loading, Table } from "../../Common";
import { useLocationState } from "../../Common/hooks";
import { getUserInfoUrl } from "../../Common/utils";
import { definedNotNull } from "../../Utils/functional";
import * as gqltypes from "../../gqltypes";

const MIN_CHAR_COUNT_SEARCH = 3;

const useDebouncedSearchField = (ms: number) => {
  const [fieldValue, setFieldValue] = useLocationState("searchValue", "");
  const [searchValue, setSearchValue] = React.useState(fieldValue);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setSearchValue(fieldValue);
    }, ms);
    return () => {
      clearTimeout(timer);
    };
    // TODO: Seems to work fine but might want to fix this lint issue some day
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue]);

  return { fieldValue, setFieldValue, searchValue };
};

export const FindUserInner = (props: {}) => {
  const navigate = useNavigate();
  const adminContext = useAdminContext();
  const { tr } = useTranslation();
  const state = useDebouncedSearchField(700);

  const [page, setPage] = useLocationState("page", 1);

  const { data, loading } = useQuery<
    gqltypes.AdminSearchUsers,
    gqltypes.AdminSearchUsersVariables
  >(
    gql`
      query AdminSearchUsers($key: String!, $page: Int!, $context: Context!) {
        searchUsers(key: $key, page: $page, context: $context) {
          hasMoreResults
          users {
            id
            name
            source
            customerId
            studentGroupMemberships(
              types: [Klass, Avdelning, Mentor]
              onlyDateValid: true
            ) {
              studentGroup {
                id
                displayName
                schoolUnit {
                  id
                  displayName
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        key: state.searchValue,
        page,
        context: adminContext,
      },
      skip: state.searchValue.length < MIN_CHAR_COUNT_SEARCH,
      fetchPolicy: "cache-first",
    }
  );

  const rowCount = data?.searchUsers.users.length;

  React.useEffect(() => {
    if (state.searchValue !== state.fieldValue) {
      setPage(1);
    }
    // TODO: Seems to work fine but might want to fix this lint issue some day
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchValue, state.fieldValue]);

  return (
    <main>
      <h1>{tr("adminFindUserHeader")}</h1>
      <p className="col-12 col-md-9 p-0 pb-3">
        {tr("adminFindUserDescription")}
      </p>
      <ISTContainer header={tr("findUserTitle")}>
        <div className="p-content">
          <FormInput
            id="searchField"
            label={tr("findUserSearchFieldLabel")}
            value={state.fieldValue}
            onChange={(e) => {
              state.setFieldValue(e.currentTarget.value);
            }}
          />
          {state.searchValue.length < MIN_CHAR_COUNT_SEARCH ? (
            <div>{tr("findUserTooFewCharacters", MIN_CHAR_COUNT_SEARCH)}</div>
          ) : null}
        </div>

        {loading ? (
          <Loading />
        ) : !data ? null : (
          <div>
            <div className="px-content">
              {data.searchUsers.hasMoreResults || page > 1 ? (
                <React.Fragment>
                  <Button
                    label={tr("findUsersPrevPage")}
                    level="secondary"
                    disabled={page === 1}
                    onClick={() => setPage(page - 1)}
                  />{" "}
                  {tr("findUserPage", page)}{" "}
                  <Button
                    label={tr("findUsersNextPage")}
                    disabled={!data.searchUsers.hasMoreResults}
                    onClick={() => setPage(page + 1)}
                  />
                </React.Fragment>
              ) : null}
              <div>{tr("findUsersSearchHits", rowCount)}</div>
            </div>
            <Table
              clickableRows
              noEndLine
              initialOrder="name"
              initialOrderDir="asc"
              unsortable={true}
              noRowsMessage={tr("findUserNoRows")}
              headers={[
                {
                  key: "name",
                  element: tr("adminFindUserNameColumn"),
                },
                {
                  key: "classes",
                  element: tr("adminFindUserClassesColumn"),
                },
              ]}
              rows={data.searchUsers.users.map((u) => ({
                key: u.id,
                onClick: () => {
                  navigate(getUserInfoUrl(u));
                },
                columns: {
                  name: { content: u.name },
                  classes: {
                    content:
                      u.studentGroupMemberships &&
                      u.studentGroupMemberships
                        .map((sg) =>
                          sg.studentGroup
                            ? `${sg.studentGroup.schoolUnit.displayName} - ${sg.studentGroup.displayName}`
                            : null
                        )
                        .filter(definedNotNull)
                        .join(", "),
                  },
                },
              }))}
            />
          </div>
        )}
      </ISTContainer>
    </main>
  );
};

export const FindUserContainer = FindUserInner;
