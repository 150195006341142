import { DebouncedFunc, debounce, defaultTo } from "lodash";
import { useEffect, useRef, useState } from "react";
import { last } from "../Utils/functional";
import { createPseudoID } from "../Utils/pseudoID";
import * as sentry from "../sentry";
import { SavingState } from "../types";

interface SaveManagerOptions {
  debounceTime?: number;
  onStartSave?: () => void;
}

type SaveFunction<SaveResult> = () => Promise<void | SaveResult>;
type StateChangedFunction = (state: SavingState) => void;

export class SaveManager<SaveResult> {
  private destroyed: boolean;
  private saveOperations: string[];
  private handleImmediateSave: SaveFunction<SaveResult>;
  private onDone: (result: SaveResult) => void;
  private onError: (err: Error) => void;
  private onStartSave: () => void;
  private stateListeners: StateChangedFunction[];
  public state: SavingState;

  public defaultDebounceTime = 1000;
  private debouncedSave: DebouncedFunc<(saveID: string) => void>;

  constructor(
    save: SaveFunction<SaveResult>,
    onDone: (result: SaveResult) => void,
    onError: (err: Error) => void,
    options?: SaveManagerOptions
  ) {
    options = defaultTo(options, {});
    this.state = SavingState.Saved;
    this.stateListeners = [];
    this.destroyed = false;
    this.saveOperations = [];
    this.handleImmediateSave = save;
    this.onDone = onDone;
    this.onError = onError;
    this.onStartSave =
      options.onStartSave ||
      (() => {
        //
      });

    this.debouncedSave = debounce(
      this.saveNowInternal,
      defaultTo(options.debounceTime, this.defaultDebounceTime)
    );
  }

  public addStateChangeListener = (fn: StateChangedFunction) => {
    this.stateListeners.push(fn);
  };

  public removeStateChangeListener = (fn: StateChangedFunction) => {
    const index = this.stateListeners.indexOf(fn);
    if (index === -1) return;
    this.stateListeners.splice(index, 1);
  };

  public destroy = () => {
    this.destroyed = true;
  };

  public save = () => {
    this.setState(SavingState.Saving);
    this.onStartSave();
    const saveID = this.startSaveOperation();
    return this.debouncedSave(saveID);
  };

  public saveNow = () => {
    this.onStartSave();
    const saveID = this.startSaveOperation();
    return this.saveNowInternal(saveID);
  };

  public updateSaveFunction = (save: SaveFunction<SaveResult>) => {
    this.handleImmediateSave = save;
  };

  private setState = (state: SavingState) => {
    this.state = state;
    for (const fn of this.stateListeners) {
      fn(state);
    }
  };

  private saveNowInternal = (saveID: string) => {
    this.setState(SavingState.Saving);
    return this.handleImmediateSave()
      .then((saveResult) => {
        if (!saveResult) {
          sentry.captureMessage(
            `SaveManager saveNowInternal encountered falsy saveResult: ${typeof saveResult}`
          );
          this.handleError(
            saveID,
            "SaveManager saveNowInternal encountered falsy saveResult"
          );
        } else {
          this.handleSaveResult(saveID, saveResult);
        }
      })
      .catch((reason) => {
        this.handleError(saveID, reason);
      });
  };

  private handleError = (saveID: string, reason: any) => {
    if (this.destroyed) {
      return;
    }
    if (this.isLastSaveOperation(saveID)) {
      this.endSaveOperation(saveID);
      this.onError(reason);
      this.setState(SavingState.Failed);
    }
  };

  private handleSaveResult = (saveID: string, saveResult: SaveResult) => {
    if (this.destroyed) {
      return;
    }
    if (this.isLastSaveOperation(saveID)) {
      // Remove all previous save operations if the last one has completed
      // If the last one has completed, we're not interested in earlier results
      this.setState(SavingState.Saved);
      this.saveOperations = [];
      return this.onDone(saveResult);
    }
    this.endSaveOperation(saveID);
  };

  private startSaveOperation = () => {
    const saveOperationID = createPseudoID();
    this.saveOperations.push(saveOperationID);
    return saveOperationID;
  };

  private isLastSaveOperation = (operationId: string) => {
    const currentOperation = last(this.saveOperations);
    return currentOperation !== undefined && currentOperation === operationId;
  };

  private endSaveOperation(saveOperationID: string) {
    this.saveOperations = this.saveOperations.filter(
      (operation) => operation !== saveOperationID
    );
  }
}

export const useSaveManager = <SaveResult>(
  handleSave: () => Promise<void | SaveResult>,
  onSaveSuccess: (result: SaveResult) => void,
  onSaveError: (err: Error) => void,
  options: SaveManagerOptions
) => {
  const saveManager = useRef<SaveManager<SaveResult>>();
  const [state, setState] = useState(SavingState.NotSaved);

  useEffect(() => {
    saveManager.current = new SaveManager<SaveResult>(
      handleSave,
      onSaveSuccess,
      onSaveError,
      options
    );
    setState(saveManager.current.state);
    saveManager.current.addStateChangeListener((ns) => {
      setState(ns);
    });
    return () => saveManager.current!.destroy();
    // TODO: Seems to work fine but might want to fix this lint issue some day
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log("saveManager update save function");
    saveManager.current!.updateSaveFunction(handleSave);
  }, [handleSave]);

  return { ...saveManager.current!, state };
};
