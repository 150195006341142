import React from "react";
import { Translation } from "../../App/reducer";
import * as gqltypes from "../../gqltypes";
import { getEmploymentRoleNames } from "../../permissions";
import { UncontrolledTooltip } from "reactstrap";

export function UserNameAndRoles(props: {
  tr: Translation["tr"];
  name: string | null;
  id: string;
  roles?: gqltypes.EmploymentRole[];
}) {
  let roleNames = "";
  const elId = "path_" + props.id.replace(/[\.:]/g, "") + "___";

  if (props.roles?.length) {
    roleNames = getEmploymentRoleNames(props.tr, props.roles);
  }
  return (
    <div className="d-flex justify-content-start align-items-center">
      <span
        id={elId}
        className="text-truncate"
        style={{
          flex: "0 2 auto",
        }}
      >
        {props.name}
      </span>
      {props.roles ? (
        <span
          className="text-truncate ml-1"
          style={{
            color: "var(--gray-dark)",
            fontSize: "12px",
            flex: "1 0.5 20%",
          }}
        >
          {roleNames}
        </span>
      ) : null}
      <UncontrolledTooltip target={elId}>
        {props.name + (props.roles ? " " + roleNames : "")}
      </UncontrolledTooltip>
    </div>
  );
}
