import gql from "graphql-tag";
import * as _ from "lodash";
import moment from "moment-timezone";
import * as React from "react";
import { useEffect, useState } from "react";
import { QueryResult } from "@apollo/client";
import { graphql, DataValue } from "@apollo/client/react/hoc";

import { useNavigate } from "react-router";
import {
  AdminContext,
  CurrentOrg,
  Translation,
  withAdminContext,
  withCurrentOrg,
  withTranslation,
} from "../../App/reducer";
import {
  Button,
  Checkbox,
  ConsentForm,
  FormInput,
  FormSelect,
  ISTContainer,
  Loading,
} from "../../Common";
import { formComponentDataFragment } from "../../Common/fragments";
import {
  showAlertDialog,
  showConfirmDialog,
} from "../../Utils/dialogs/showDialog";
import { extractDateString } from "../../Utils/functional";
import { client, getAdminContext } from "../../api";
import * as gqltypes from "../../gqltypes";
import {
  defaultDialogCancel,
  defaultDialogProceed,
} from "../../translation/strings";
import { Progress, WithRouteProps } from "../../types";
import { links } from "../links";
import withRouter from "../../Utils/withRouter";

const minimumApplicationDelayMinutes = 20;
const currentTimeZoneName = moment.tz.guess();

interface Props extends Response, AdminContext, Translation, CurrentOrg {
  application?: DataValue<gqltypes.application>;
}

interface State {
  applicationName: string;
  isEditing: boolean;
  publicationDate?: Date;
  singleGuardianSend: boolean;
  singleGuardianAcceptOffer: boolean;
  schoolunitSpecific: boolean;
  closed: boolean;
  subscribable: boolean;
  selectedForm?: string;
  selectedFormSubType?: gqltypes.FormSubType;
  showValidation: boolean;
  publishState: Progress;
}

interface ValidationErrors {
  applicationName?: string;
  publicationDate?: string;
  selectedForm?: string;
}

const validateParams = (
  tr: Translation["tr"],
  state: State
): ValidationErrors => {
  const errors: ValidationErrors = {};

  // PublicationDate
  if (state.publicationDate === undefined) {
    errors.publicationDate = tr("validationSelectDate");
  }

  // applicationName
  if (state.applicationName === "") {
    errors.applicationName = tr("validationAnswerCompulsoryButMissing");
  }

  // selectedForm
  if (state.selectedForm === undefined) {
    errors.selectedForm = tr("createApplicationChooseForm");
  }

  return errors;
};

const FormPreviewInner = (props: {
  tr: Translation["tr"];
  selectedForm: State["selectedForm"];
  formData: Props["formData"];
  application: Props["application"];
}) => {
  const { tr, application } = props;
  const [response, setResponse] = React.useState<gqltypes.FormAnswer>();

  if (!props.formData.organisation || !props.selectedForm) {
    return null;
  }

  const lockedForm = application?.application?.form;

  const forms = _.cloneDeep(props.formData.organisation.sendableForms);
  if (lockedForm && !forms.find((f) => f.id === lockedForm.id)) {
    forms.push(lockedForm);
  }

  const form = forms.find((f) => f.id === props.selectedForm);
  if (!form) {
    return null;
  }
  return (
    <div className="mt-content">
      <h2>{tr("createApplicationPreviewTitle")}</h2>
      <p>{tr("createApplicationPreviewDescription")}</p>
      <ConsentForm
        predicateComponents={form.componentData.predicateComponents || []}
        response={response}
        updateResponse={(res) => setResponse(res)}
        formType={form.type}
        formOwner={form.owner}
        formTitle={form.name}
        formDescription={form.description}
        components={form.componentData.components}
        formLanguage={form.language}
        schoolunitSpecific={application?.application?.schoolunitSpecific}
      />
    </div>
  );
};

const FormPreview = React.memo(FormPreviewInner);

const handleChangePublicationDate = (
  date: State["publicationDate"],
  setPublicationDate: (date: State["publicationDate"]) => void
) => {
  if (!date) {
    return setPublicationDate(undefined);
  }

  const currentTime = new Date();
  const isToday = date.toDateString() === currentTime.toDateString();

  const publicationDate = isToday
    ? moment(currentTime)
        .add(minimumApplicationDelayMinutes, "minutes")
        .toDate()
    : date;

  setPublicationDate(publicationDate);
};

const useApplicationState = () => {
  const [applicationName, setApplicationName] =
    useState<State["applicationName"]>("");
  const [selectedForm, setSelectedForm] = useState<State["selectedForm"]>();
  const [selectedFormSubType, setSelectedFormSubType] =
    useState<State["selectedFormSubType"]>();
  const [isEditing, setIsEditing] = useState<State["isEditing"]>(false);
  const [publicationDate, setPublicationDate] = useState<
    State["publicationDate"]
  >(moment().add(minimumApplicationDelayMinutes, "minutes").toDate());
  const [singleGuardianSend, setSingleGuardianSend] =
    useState<State["singleGuardianSend"]>(false);
  const [singleGuardianAcceptOffer, setSingleGuardianAcceptOffer] =
    useState<State["singleGuardianAcceptOffer"]>(false);
  const [schoolunitSpecific, setSchoolunitSpecific] =
    useState<State["schoolunitSpecific"]>(false);
  const [closed, setClosed] = useState<State["closed"]>(false);
  const [subscribable, setSubscribable] = useState<State["subscribable"]>(true);
  const [showValidation, setShowValidation] =
    useState<State["showValidation"]>(false);
  const [publishState, setPublishState] = useState<State["publishState"]>(
    Progress.NotStarted
  );

  return {
    applicationName,
    setApplicationName,
    selectedForm,
    setSelectedForm,
    selectedFormSubType,
    setSelectedFormSubType,
    isEditing,
    setIsEditing,
    publicationDate,
    setPublicationDate,
    singleGuardianSend,
    setSingleGuardianSend,
    singleGuardianAcceptOffer,
    setSingleGuardianAcceptOffer,
    schoolunitSpecific,
    setSchoolunitSpecific,
    closed,
    setClosed,
    showValidation,
    setShowValidation,
    publishState,
    setPublishState,
    subscribable,
    setSubscribable,
  };
};

const updateApplicationState = (
  application: gqltypes.FullApplication | undefined,
  state: ReturnType<typeof useApplicationState>
) => {
  if (!application) return;

  state.setIsEditing(true);
  state.setApplicationName(application.name);
  state.setPublicationDate(new Date(application.sendDate));
  state.setSingleGuardianSend(application.singleGuardianSend);
  state.setSingleGuardianAcceptOffer(application.singleGuardianAcceptOffer);
  state.setSchoolunitSpecific(application.schoolunitSpecific);
  state.setClosed(application.closed);
  state.setSelectedForm(application.form.id);
  state.setSelectedFormSubType(application.form.subType);
  state.setShowValidation(true);
  state.setSubscribable(application.subscribable);
};

const CreateApplication = (props: Props) => {
  const state = useApplicationState();
  const navigate = useNavigate();

  const app = props.application && props.application.application;
  useEffect(() => {
    if (!app) return;
    updateApplicationState(app, state);
    // TODO: Seems to work fine but might want to fix this lint issue some day
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app]);

  const { tr } = props;
  if (props.application && props.application.loading) {
    return <Loading />;
  }
  if (props.formData.loading) {
    return <Loading />;
  }

  const { formData } = props;
  const formsExist =
    formData.organisation && formData.organisation.sendableForms.length > 0;

  const lockedForm = app && app.form;

  let formOptions = formData.organisation
    ? formData.organisation.sendableForms.map((form) => ({
        value: form.id,
        label: form.name,
        subType: form.subType,
      }))
    : [];

  if (!props.org?.features?.relocation) {
    formOptions = formOptions.filter(
      (form) => form.subType === gqltypes.FormSubType.general
    );
  }

  if (lockedForm && !formOptions.find((form) => form.value === lockedForm.id)) {
    formOptions.push({
      value: lockedForm.id,
      label: lockedForm.name,
      subType: lockedForm.subType,
    });
  }

  const validationErrors = validateParams(props.tr, state);

  const isValid = Object.keys(validationErrors).length === 0;

  return (
    <main id="create-application">
      <h1>
        {state.isEditing
          ? tr("editApplicationTitle")
          : tr("createApplicationTitle")}
      </h1>
      <p className="col-12 col-md-9 p-0 pb-3">
        {state.isEditing ? tr("editApplicationDescription") : ""}
      </p>
      <ISTContainer
        header={
          state.isEditing
            ? tr("editApplicationHeader")
            : tr("createApplicationHeader")
        }
      >
        {!formsExist ? (
          <div className="alert alert-info m-content">
            {tr("createApplicationNoFormsToSend")}
          </div>
        ) : (
          <React.Fragment>
            <div className="p-content">
              <div className="row">
                <FormInput
                  className={"col-12 col-md-4"}
                  label={tr("createFormApplicationNameLabel")}
                  disabled={state.isEditing}
                  feedback={
                    state.showValidation ? validationErrors.applicationName : ""
                  }
                  id="application-name-input"
                  onChange={(e) =>
                    state.setApplicationName(e.currentTarget.value)
                  }
                  value={state.applicationName}
                />
                <FormSelect
                  className="col-12 col-md-4"
                  id="form-select"
                  label={tr("createFormFormSelectLabel")}
                  disabled={formData.loading || state.isEditing}
                  onChange={(e) => {
                    const form = formOptions.find(
                      (f) => f.value === e.currentTarget.value
                    );
                    state.setSelectedForm(form?.value);
                    state.setSelectedFormSubType(form?.subType);
                  }}
                  value={state.selectedForm || ""}
                  defaultOption={tr("createApplicationFormNotChosen")}
                  options={formOptions}
                  feedback={
                    state.showValidation
                      ? validationErrors.selectedForm
                      : undefined
                  }
                />
              </div>
              <div className="col-12">
                <Checkbox
                  id="applicationOnlyOneGuardianSend"
                  label={tr("createApplicationSingleGuardianSendLabel")}
                  checked={state.singleGuardianSend}
                  onChange={(id, checked) =>
                    state.setSingleGuardianSend(checked)
                  }
                  disabled={app && app.sent}
                />
              </div>
              <div className="col-12">
                <Checkbox
                  id="applicationOnlyOneGuardianAcceptOffer"
                  label={tr("createApplicationSingleGuardianAcceptOfferLabel")}
                  checked={state.singleGuardianAcceptOffer}
                  onChange={(id, checked) =>
                    state.setSingleGuardianAcceptOffer(checked)
                  }
                  disabled={app && app.sent}
                />
              </div>
              <div className="col-12">
                <Checkbox
                  id="createApplicationSchoolunitSpecific"
                  label={tr("createApplicationSchoolunitSpecificLabel")}
                  checked={state.schoolunitSpecific}
                  onChange={(id, checked) =>
                    state.setSchoolunitSpecific(checked)
                  }
                />
              </div>
              <div className="col-12">
                <Checkbox
                  id="createApplicationSubscribable"
                  label={tr(
                    "createApplicationSubscribableEmailNotificationLabel"
                  )}
                  checked={state.subscribable}
                  onChange={(id, checked) => {
                    state.setSubscribable(checked);
                  }}
                />
              </div>

              {app ? (
                <div className="col-12">
                  <Checkbox
                    id="applicationClosed"
                    label={tr("createApplicationClosedLabel")}
                    checked={state.closed}
                    onChange={(id, checked) => state.setClosed(checked)}
                  />
                </div>
              ) : null}
              {state.selectedFormSubType ===
                gqltypes.FormSubType.outMigration && (
                <div className="pt-3">
                  <div className="alert alert-info">
                    {tr("createApplicationOutMigrationWarning")}
                  </div>
                </div>
              )}
            </div>

            <div className="row flex-md-row-reverse p-content clearfix">
              <div className="col-12 col-md-6">
                <Button
                  className="float-right"
                  disabled={state.publishState === Progress.InProgress}
                  onClick={(e) => {
                    e.preventDefault();
                    state.setShowValidation(true);
                    if (state.isEditing) {
                      handleSaveApplication(tr, app!.id, state, isValid);
                    } else {
                      handleCreateApplication(tr, state, isValid, (id) => {
                        navigate(links.admin.application.list());
                      });
                    }
                  }}
                  label={
                    state.isEditing ? (
                      tr("save")
                    ) : state.publishState === Progress.InProgress ? (
                      <span>
                        <i className="fa fa-spinner fa-spin mr-2" />
                        {tr("creating")}
                      </span>
                    ) : (
                      tr("create")
                    )
                  }
                />
              </div>
              <div className="col-12 col-md-6">
                <span className="">
                  {state.publishState === Progress.Error &&
                    tr("createApplicationFailedToPublish")}
                </span>
              </div>
            </div>
          </React.Fragment>
        )}
      </ISTContainer>

      <FormPreview
        tr={tr}
        application={props.application}
        formData={props.formData}
        selectedForm={state.selectedForm}
      />
    </main>
  );
};

const fullApplicationFragment = gql`
  fragment FullApplication on Application {
    id
    name
    sent
    sendDate
    singleGuardianSend
    singleGuardianAcceptOffer
    schoolunitSpecific
    closed
    subscribable
    form {
      id
      type
      subType
      name
      description
      language
      componentData {
        ...FormComponentData
      }
      owner {
        id
        displayName
      }
    }
  }
  ${formComponentDataFragment}
`;

const CREATE_APPLICATION_MUTATION = gql`
  mutation CreateApplication(
    $input: CreateApplicationInput!
    $context: Context!
  ) {
    createApplication(input: $input, context: $context) {
      ...FullApplication
    }
  }
  ${fullApplicationFragment}
`;

const handleCreateApplication = async (
  tr: Translation["tr"],
  state: State,
  allFieldsValid: boolean,
  onDone: (id: string) => void
) => {
  if (!allFieldsValid) {
    return showAlertDialog({
      title: tr("error"),
      message: tr("createApplicationSomeFieldsIncorrect"),
      proceedText: defaultDialogProceed(tr),
      cancelText: defaultDialogCancel(tr),
    });
  }

  const {
    selectedForm,
    // publicationDate,
    applicationName,
    singleGuardianSend,
    singleGuardianAcceptOffer,
    schoolunitSpecific,
    subscribable,
  } = state;

  const publicationDate = new Date();

  await showConfirmDialog({
    title: tr("createApplicationConfirmTitle"),
    content: tr("createApplicationConfirmMessage"),
    proceedText: tr("create"),
    cancelText: defaultDialogCancel(tr),
    proceedFunc: async (hostingComponent) => {
      if (!selectedForm || !publicationDate) {
        throw new Error("missing stuff");
      }

      const input = {
        name: applicationName,
        formId: selectedForm,
        sendDate: extractDateString(publicationDate),
        singleGuardianSend,
        singleGuardianAcceptOffer,
        schoolunitSpecific,
        timezoneName: currentTimeZoneName,
        subscribable,
      };

      const context = getAdminContext();

      const res = await client.mutate<
        gqltypes.CreateApplication,
        gqltypes.CreateApplicationVariables
      >({
        mutation: CREATE_APPLICATION_MUTATION,
        variables: { input, context },
      });

      onDone(res.data!.createApplication.id);
    },
    proceedFuncFooter: (
      <span>
        <i className="fa fa-spinner fa-spin mr-2" />
        {tr("createApplicationCreatingApplication")}
      </span>
    ),
  });
};

const SAVE_APPLICATION_MUTATION = gql`
  mutation SaveApplication($input: SaveApplicationInput!, $context: Context!) {
    saveApplication(input: $input, context: $context) {
      ...FullApplication
    }
  }
  ${fullApplicationFragment}
`;

const handleSaveApplication = async (
  tr: Translation["tr"],
  id: string,
  state: State,
  allFieldsValid: boolean
) => {
  if (!allFieldsValid) {
    return showAlertDialog({
      title: tr("error"),
      message: tr("createApplicationSomeFieldsIncorrect"),
      proceedText: defaultDialogProceed(tr),
      cancelText: defaultDialogCancel(tr),
    });
  }

  const {
    selectedForm,
    publicationDate,
    singleGuardianSend,
    singleGuardianAcceptOffer,
    schoolunitSpecific,
    closed,
    subscribable,
  } = state;

  if (!selectedForm || !publicationDate) {
    throw new Error("save missing stuff");
  }

  const input = {
    id,
    sendDate: extractDateString(publicationDate),
    singleGuardianSend,
    singleGuardianAcceptOffer,
    schoolunitSpecific,
    timezoneName: currentTimeZoneName,
    closed,
    subscribable,
  };

  const context = getAdminContext();

  const res = await client.mutate<
    gqltypes.SaveApplication,
    gqltypes.SaveApplicationVariables
  >({
    mutation: SAVE_APPLICATION_MUTATION,
    variables: { input, context },
  });

  showAlertDialog({
    title: tr("saved"),
    message: tr("yourChangedSaved"),
    proceedText: defaultDialogProceed(tr),
    cancelText: defaultDialogCancel(tr),
  });
};

interface Response {
  formData: DataValue<gqltypes.publishApplicationData> & QueryResult;
}

export const sendableFormsQuery = gql`
  query publishApplicationData($context: Context!) {
    organisation(context: $context) {
      id
      sendableForms(type: application) {
        id
        type
        subType
        name
        description
        language
        componentData {
          ...FormComponentData
        }
        owner {
          id
          displayName
        }
      }
    }
  }
  ${formComponentDataFragment}
`;

const withFormData = graphql<
  Props,
  gqltypes.publishApplicationData,
  gqltypes.publishApplicationDataVariables
>(sendableFormsQuery, {
  name: "formData",
  options: (props) => ({
    variables: {
      context: props.adminContext,
    },
  }),
});

const withApplication = graphql<
  WithRouteProps<{ params: { id: string } }>,
  gqltypes.publication
>(
  gql`
    query application($id: ID!) {
      application(id: $id) {
        ...FullApplication
      }
    }
    ${fullApplicationFragment}
  `,
  {
    name: "application",
    options: (props) => ({
      variables: {
        id: props.params.id,
      },
    }),
  }
);

export const CreateApplicationContainer = _.flowRight(
  withTranslation,
  withAdminContext,
  withFormData,
  withRouter,
  withCurrentOrg
)(CreateApplication);

export const EditApplicationContainer = _.flowRight(
  withTranslation,
  withRouter,
  withApplication,
  withAdminContext,
  withFormData
)(CreateApplication);
