import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Dispatch } from "redux";
import * as action from "../App/actions";
import { Footer } from "../App/components/Footer";
import { Header, HeaderProps } from "../App/components/Header";
import { MenuItem, SideMenu } from "../App/components/SideMenu";
import { AvailableOrg, Translation, useTranslation } from "../App/reducer";
import { Loading } from "../Common";
import { GenericError } from "../Common/components/GenericError";
import { PageNotFound } from "../Common/components/PageNotFound";
import { UserProfileContainer } from "../Common/components/Profile";
import { StartViewContainer } from "../Start/StartViewContainer";
import { PermissionType, UserPermissions } from "../permissions";
import { getSettings } from "../settings";
import { StoreState } from "../store";
import { OIDCUser } from "../types";
import {
  CreateForm,
  EditForm,
  FormsContainer,
  PermissionsContainer,
  UserPermissionsContainer,
} from "./components";
import { ApplicationReportContainer } from "./components/ApplicationAnswerReport";
import { ApplicationResponseContainer } from "./components/ApplicationResponse";
import { ClientPermissionsContainer } from "./components/ClientPermissions";
import {
  CreateApplicationContainer,
  EditApplicationContainer,
} from "./components/CreateApplication";
import {
  CreatePublicationContainer,
  EditPublicationContainer,
} from "./components/CreatePublication";
import { CreatePublicationResponse } from "./components/CreatePublicationResponse";
import { DemoContainer } from "./components/Demo";
import { FillPublicationResponseViewContainer } from "./components/FillPublicationResponse";
import { FindUserContainer } from "./components/FindUser";
import { FormViewerContainer } from "./components/FormViewer";
import { OrgReportsContainer } from "./components/OrgReports";
import { OrganisationSettings } from "./components/OrganisationSettings";
import { ReportsContainer } from "./components/Reports";
import { StartContainer } from "./components/Start";
import { UserProfile } from "./components/UserInfo";
import { StudentGroupsContainer } from "./components/ViewAnswers";
import { ViewApplicationAnswersContainer } from "./components/ViewApplicationAnswers";
import { ViewApplicationsContainer } from "./components/ViewApplications";
import { SentFormsContainer } from "./components/ViewPublications";

const settings = getSettings();
const isInDemo = settings.releaseStage !== "production";

function getAdminMenuListItems(tr: Translation["tr"]): MenuItem[] {
  return [
    {
      label: tr("adminAppMyPagesItemHeader"),
      url: "header",
      somePermission: [],
    },
    {
      label: tr("adminAppStartItem"),
      url: "/admin",
      exactMatch: true,
    },
    { label: "", type: "spacer" },
    {
      label: tr("adminAppFormItemHeader"),
      type: "header",
      somePermission: [PermissionType.create],
    },
    {
      label: tr("adminAppCreateFormItem"),
      url: "/admin/createForm",
      somePermission: [PermissionType.create],
    },
    {
      label: tr("adminAppListFormsItem"),
      url: "/admin/forms",
      somePermission: [PermissionType.create],
    },
    { label: "", type: "spacer" },
    {
      label: tr("adminAppPublicationItemHeader"),
      type: "header",
      somePermission: [PermissionType.read, PermissionType.send],
    },
    {
      label: tr("adminAppCreatePublicationItem"),
      url: "/admin/createPublication",
      somePermission: [PermissionType.send],
    },
    {
      label: tr("adminAppListPublicationsItem"),
      url: "/admin/publications",
      somePermission: [PermissionType.send],
    },
    {
      label: tr("adminAppViewAnswersItem"),
      url: "/admin/schools",
      somePermission: [PermissionType.read],
    },
    {
      label: tr("adminAppCreateReportItem"),
      url: "/admin/reports",
      somePermission: [PermissionType.read],
    },
    {
      label: tr("adminAppCreateOrgReportItem"),
      url: "/admin/orgreports",
      orgPermission: PermissionType.read,
    },
    { label: "", type: "spacer" },
    {
      label: tr("adminAppApplicationItemHeader"),
      type: "header",
      somePermission: [
        PermissionType.read_application_answers,
        PermissionType.manage_application,
      ],
    },
    {
      label: tr("adminAppCreateApplicationItem"),
      url: "/admin/createApplication",
      orgPermission: PermissionType.manage_application,
    },
    {
      label: tr("adminAppShowApplicationItem"),
      url: "/admin/applications",
      orgPermission: PermissionType.manage_application,
    },
    {
      label: tr("adminAppApplicationAnswersItem"),
      url: "/admin/applicationAnswers",
      somePermission: [PermissionType.read_application_answers],
    },
    {
      label: tr("adminAppApplicationReportItem"),
      url: "/admin/applicationReport",
      somePermission: [PermissionType.read_application_answers],
    },
    { label: "", type: "spacer" },

    {
      label: tr("adminAppAdministrativeItemHeader"),
      type: "header",
    },
    {
      label: tr("adminAppFindUserItem"),
      url: "/admin/user",
      exactMatch: true,
    },
    {
      label: tr("adminAppHandlePermissionsItem"),
      url: "/admin/permissions",
      somePermission: [PermissionType.admin],
    },
    {
      label: tr("adminAppOrgSettingsItem"),
      url: "/admin/settings",
      orgPermission: PermissionType.admin,
    },
    { label: "", type: "spacer", onlyInDemo: true },
    {
      label: tr("adminAppDemoItemHeader"),
      type: "header",
      somePermission: [PermissionType.send],
      onlyInDemo: true,
    },
    {
      label: tr("adminAppDemoItem"),
      url: "/admin/demo",
      somePermission: [PermissionType.send],
      onlyInDemo: true,
    },
  ];
}

interface AdminRouterStateProps {
  orgSelected: boolean | null;
  requestedAdminView: boolean;
  availableOrgs: AvailableOrg[] | null;
  org: string | null;
  uiLanguage: string;
}

export const AdminRouterInner = () => {
  const { tr } = useTranslation();
  const dispatch = useDispatch();

  const state = useSelector<StoreState, AdminRouterStateProps>((store) => ({
    availableOrgs: store.app.availableOrgs,
    org: store.app.context.org,
    requestedAdminView: store.app.requestedAdminView,
    uiLanguage: store.app.uiLanguage,
    orgSelected: store.app.orgSelected,
  }));

  React.useEffect(() => {
    if (!state.requestedAdminView) {
      dispatch(action.requestedAdminView());
    }
    // TODO: Seems to work fine but might want to fix this lint issue some day
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.availableOrgs && state.availableOrgs.length === 0) {
    dispatch(
      action.errorSplash({
        title: tr("adminNoAdminErrorTitle"),
        message: tr("adminNoAdminErrorDescription"),
      })
    );
  }

  if (!state.availableOrgs) {
    return <Loading />;
  } else if (state.availableOrgs.length === 0) {
    return null;
  } else if (!state.org) {
    if (state.orgSelected === false) {
      return <GenericError title={tr("adminNoOrgSelected")} />;
    }
    return null;
  }

  return (
    <Routes>
      <Route path="/" element={<StartContainer />} />
      <Route path="/forms" element={<FormsContainer />} />
      <Route path="/createForm" element={<CreateForm />} />
      <Route
        path="/schools/:customerId?/:unitId?/:groupId?/:publicationId?"
        element={<StudentGroupsContainer />}
      />
      <Route path="/editForm/:id" element={<EditForm />} />
      <Route path="/publications/:id" element={<SentFormsContainer />} />
      <Route path="/publications" element={<SentFormsContainer />} />
      <Route
        path="/editPublication/:id"
        element={<EditPublicationContainer />}
      />
      <Route path="/formView/:id" element={<FormViewerContainer />} />
      <Route
        path="/fillFormView/:recipientId/:responseId"
        element={<FillPublicationResponseViewContainer />}
      />
      <Route
        path="/createPublication"
        element={<CreatePublicationContainer />}
      />
      <Route
        path="/createApplication"
        element={<CreateApplicationContainer />}
      />
      <Route
        path="/editApplication/:id"
        element={<EditApplicationContainer />}
      />
      <Route path="/applications" element={<ViewApplicationsContainer />} />
      <Route
        path="/applicationAnswers/:applicationId?"
        element={<ViewApplicationAnswersContainer />}
      />
      <Route
        path="/applicationReport/:applicationId?"
        element={<ApplicationReportContainer />}
      />
      <Route path="/permissions" element={<PermissionsContainer />} />
      <Route
        path="/permissions/user/:source/:id"
        element={<UserPermissionsContainer />}
      />
      <Route
        path="/permissions/user/:source/:customerId/:id"
        element={<UserPermissionsContainer />}
      />
      <Route
        path="/permissions/client/:id"
        element={<ClientPermissionsContainer />}
      />
      <Route path="/settings" element={<OrganisationSettings />} />
      <Route path="/reports" element={<ReportsContainer />} />
      <Route path="/orgreports" element={<OrgReportsContainer />} />
      <Route
        path="/formResponse/:id/:mode?"
        element={<FillPublicationResponseViewContainer />}
      />
      <Route
        path="/createResponse/:id"
        element={<CreatePublicationResponse />}
      />
      <Route
        path="/applicationResponse/:id"
        element={<ApplicationResponseContainer />}
      />
      <Route path="/profile" element={<UserProfileContainer />} />
      <Route path="/user" element={<FindUserContainer />} />
      <Route path="/user/:source/:id" element={<UserProfile />} />
      <Route path="/user/:source/:customerId/:id" element={<UserProfile />} />

      {isInDemo && <Route path="/demo" element={<DemoContainer />} />}
      <Route element={<PageNotFound startPageUrl="/" />} />
    </Routes>
  );
};

export const AdminRouter = React.memo(AdminRouterInner);

const ErrorSpash = (props: { title: string; message?: string }) => (
  <main className="d-flex flex-grow-1">
    <div className="start-box">
      <h1>{props.title}</h1>
      {props.message ? <p>{props.message}</p> : null}
    </div>
  </main>
);

const ProtectedPaths = (props: React.PropsWithChildren<AdminProps>) => {
  // Protected paths require the application to be fully initialized (for instance the loading of an eventual user must have finished)
  if (!props.isInitialized) {
    return null;
  }

  if (!props.user) {
    return <StartViewContainer mode="admin" />;
  }

  return <div>{props.children}</div>;
};

interface AdminStateProps {
  isInitialized: boolean;
  user: OIDCUser | null;
  fatalError: string | null;
  errorSplash: { title: string; message?: string } | null;
  userPermissions: UserPermissions | null;
  currentOrg: string | null;
  availableOrgs: AvailableOrg[] | null;
  uiLanguage: string;
  skipPermissionCheck?: boolean;
}

interface AdminOutProps {}

interface AdminProps extends AdminStateProps, AdminOutProps, HeaderProps {
  dispatch: Dispatch<any>;
}

const AdminAppInner = (props: AdminProps) => {
  const { errorSplash, fatalError } = props;
  const error = Boolean(fatalError || errorSplash);
  const showMenu = true;

  const { tr } = useTranslation();

  const items = React.useMemo(() => getAdminMenuListItems(tr), [tr]);

  const onLogin = () => {
    props.dispatch(action.loginRequested());
  };

  const onLogout = () => {
    props.dispatch(action.logoutRequested());
  };

  return (
    <div className="app">
      <Header
        {...(props as HeaderProps)}
        menuItems={items}
        userPermissions={props.userPermissions}
        currentOrg={props.currentOrg}
        availableOrgs={props.availableOrgs}
        dispatch={props.dispatch}
        onLogin={onLogin}
        onLogout={onLogout}
      />
      {error ? (
        <ErrorSpash
          title={errorSplash ? errorSplash.title : tr("criticalError")}
          message={
            errorSplash
              ? errorSplash.message
              : fatalError || tr("appDefaultErrorMessage")
          }
        />
      ) : (
        <>
          {showMenu && props.user && (
            <SideMenu
              menuItems={items}
              userPermissions={props.userPermissions}
              skipPermissionCheck={props.skipPermissionCheck}
            />
          )}
          <div className="mainLayout">
            <main
              className={
                showMenu && props.user !== null
                  ? "mainContent"
                  : "mainContentNoSideBar"
              }
            >
              <ProtectedPaths {...props}>
                <AdminRouter />
              </ProtectedPaths>
            </main>
          </div>
        </>
      )}
      <Footer />
    </div>
  );
};

export const AdminApp = React.memo(AdminAppInner);
