const formatYear = (year: string) => {
  if (year.length === 2) {
    const currentYear = new Date().getUTCFullYear();
    if (parseInt("20" + year, 10) > currentYear) {
      return "19" + year;
    }

    return "20" + year;
  }

  return year;
};

/*
 * Transform a swedish nationalId to 12 digit format (191212121212)
 * Also accepts 'temporary' fake nationalIds (19121212-TF01)
 */
export const normalizeSwedishNationalId = (
  nationalId: string,
  options: {
    invalidReturnEmptyString?: boolean;
    invalidReturnInput?: boolean;
  } = {}
): string => {
  // 191212121212 format
  if (/^\d{8}\w{4}$/.test(nationalId)) {
    return nationalId;
  }

  if (!nationalId || nationalId.length < 10) {
    if (options.invalidReturnEmptyString) return "";
    if (options.invalidReturnInput) return nationalId;
    throw new Error("falsy or too short nationalId: " + nationalId);
  }

  const last4 = nationalId.substring(nationalId.length - 4);

  // 19121212-1212 format
  if (/^\d{8}-\w{4}$/.test(nationalId)) {
    const first8 = nationalId.substr(0, 8);
    return first8 + last4;
  }

  // 1212121212 or 121212-1212 format
  if (/^\d{6}-\w{4}$/.test(nationalId) || /^\d{6}\w{4}$/.test(nationalId)) {
    const first2 = nationalId.substr(0, 2);
    const monthDate = nationalId.substr(2, 4);
    const year = formatYear(first2);

    return year + monthDate + last4;
  }

  if (options.invalidReturnEmptyString) return "";
  if (options.invalidReturnInput) return nationalId;

  throw new Error("unknown nationalId format: " + nationalId);
};

export const normalizeNorwayNationalId = (
  nationalId: string,
  options: {
    invalidReturnEmptyString?: boolean;
    invalidReturnInput?: boolean;
  } = {}
): string => {
  // 12121212121 format
  if (/^\d{11}$/.test(nationalId)) {
    return nationalId;
  }

  if (options.invalidReturnEmptyString) return "";
  if (options.invalidReturnInput) return nationalId;

  throw new Error("unknown nationalId format: " + nationalId);
};
