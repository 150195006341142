import "core-js";
import { Root } from "./root";
import * as sentry from "./sentry";
import { createRoot } from "react-dom/client";
import React from "react";

if (typeof Node === "function" && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        sentry.captureException(
          new Error("Cannot remove a child from a different parent"),
          {
            level: sentry.SeverityLevel.warning,
            extra: { child, this: this },
          }
        );
        console.error(
          "Cannot remove a child from a different parent",
          child,
          this
        );
      }
      return child;
    }
    // eslint-disable-next-line prefer-rest-params
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      sentry.captureException(
        new Error(
          "Cannot insert before a reference node from a different parent"
        ),
        {
          level: sentry.SeverityLevel.warning,
          extra: {
            referenceNode,
            this: this,
          },
        }
      );
      if (console) {
        console.error(
          "Cannot insert before a reference node from a different parent",
          referenceNode,
          this
        );
      }
      return newNode;
    }
    // eslint-disable-next-line prefer-rest-params
    return originalInsertBefore.apply(this, arguments);
  };
}
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(React.createElement(Root));

// Enable service worker when they are more stable: https://github.com/facebookincubator/create-react-app/issues/2554
// Currently the browser will render the old app after it has been updated which we can't tolerate.
// More stuff that needs to be considered when enabling: https://github.com/facebookincubator/create-react-app/blob/master/packages/react-scripts/template/README.md#offline-first-considerations
// registerServiceWorker();
