import { createAction } from "redux-ts-simple";
import { ISO6391Language } from "../gqltypes";
import { UserPermissions } from "../permissions";
import { OIDCUser } from "../types";

export const applicationLaunched = createAction("App/Launched");
export const initialized = createAction("App/Initialized");
export const userChanged = createAction<OIDCUser | null>("App/UserChanged");
export const availableOrgsChanged = createAction<
  {
    id: string;
    displayName: string;
    defaultLanguage: ISO6391Language;
    account?: { sub: string; org: string; orgName: string };
  }[]
>("App/AvailableOrgsChanged");
export const orgWillChange = createAction<{
  id: string;
  account?: { org: string; sub: string };
}>("App/OrgWillChange");
export const orgChanged = createAction<{
  id: string;
  account?: { org: string; sub: string };
}>("App/OrgChanged");
export const permissionsChanged = createAction<UserPermissions>(
  "App/PermissionsChanged"
);
export const userInfoChanged = createAction<{
  id: string;
  educloudUserIds: { id: string; customerId: string }[];
  language: string | null;
}>("App/userInfoChanged");
export const changeUiLanguage = createAction<string>("App/UiLanguageChange");
export const changeIdp = createAction<string>("App/IdpChange");

export const requestedAdminView = createAction("App/AdminView");

export const loginRequested = createAction("App/Login");
export const logoutRequested = createAction<
  { reason: "session_timeout" } | undefined
>("App/Logout");
export const fatalError = createAction<string>("App/FatalError");
export const errorSplash =
  createAction<{ title: string; message?: string }>("App/ErrorSplash");

export const flagCookiesUnavailable = createAction("App/CookiesNotWritable");
export const flagTimeSkewError = createAction("App/FlagTimeSkewError");

export const orgSelected = createAction<boolean>("App/SetOrgSelected");

export const toggleMinimizeContactInfoAlert = createAction(
  "App/ToggleMinimizeInfoAlert"
);

export const toggleSkipPermissionCheck = createAction(
  "App/toggleSkipPermissionCheck"
);
