export function classNames(obj: { [key: string]: undefined | boolean }) {
  const result: string[] = [];
  for (const key of Object.keys(obj)) {
    if (obj[key]) {
      result.push(key);
    }
  }

  return result.join(" ");
}

/**
 * Gets a value in a nested object.
 * If the key doesn't exist, returns undefined
 *
 * Example usage:
 * get({kalle: {1: {wat: "hehe"}}}, "kalle", 1, "wat")
 * //= "hehe"
 */

export type NotUndefinedNullError<T> = T extends null | undefined | Error
  ? never
  : T;

type MaybeUndefined<T> = T extends null | undefined ? undefined : never;

export type MaybeUndefinedNullError<T> = T extends Error | null | undefined
  ? T
  : never;

export type MaybeUndefinedNullErrorLUL<T> = T extends Error
  ? Error
  : T extends null
  ? null
  : T extends undefined
  ? undefined
  : never;

export function get<T>(obj: T): T;

export function get<T, K1 extends keyof NonNullable<T>>(
  obj: T,
  k1: K1
): NonNullable<T>[K1] | MaybeUndefined<T>;

export function get<
  T,
  K1 extends keyof NonNullable<T>,
  K2 extends keyof NonNullable<NonNullable<T>[K1]>
>(
  obj: T,
  k1: K1,
  k2: K2
): NonNullable<NonNullable<T>[K1]>[K2] | MaybeUndefined<T>;

export function get<
  T,
  K1 extends keyof NonNullable<T>,
  K2 extends keyof NonNullable<NonNullable<T>[K1]>,
  K3 extends keyof NonNullable<NonNullable<NonNullable<T>[K1]>[K2]>
>(
  obj: T,
  k1: K1,
  k2: K2,
  k3: K3
): NonNullable<NonNullable<NonNullable<T>[K1]>[K2]>[K3] | MaybeUndefined<T>;

export function get<
  T,
  K1 extends keyof NonNullable<T>,
  K2 extends keyof NonNullable<NonNullable<T>[K1]>,
  K3 extends keyof NonNullable<NonNullable<NonNullable<T>[K1]>[K2]>,
  K4 extends keyof NonNullable<
    NonNullable<NonNullable<NonNullable<T>[K1]>[K2]>[K3]
  >
>(
  obj: T,
  k1: K1,
  k2: K2,
  k3: K3,
  k4: K4
):
  | NonNullable<NonNullable<NonNullable<NonNullable<T>[K1]>[K2]>[K3]>[K4]
  | MaybeUndefined<T>;

export function get(coll: any, ...keys: string[]) {
  return keys.reduce((acc, curr) => (acc ? acc[curr] : acc), coll);
}

export function isUndefinedNullError<T>(
  v: NotUndefinedNullError<T> | undefined | null | Error
): boolean {
  return v === undefined || v === null || v instanceof Error;
}

export function update<T, K1 extends keyof NonNullable<T>>(
  obj: T,
  keys: [K1],
  f: (v: NonNullable<T>[K1]) => any
): T;

export function update<
  T,
  K1 extends keyof NonNullable<T>,
  K2 extends keyof NonNullable<NonNullable<T>[K1]>
>(
  obj: T,
  keys: [K1, K2],
  f: (v: NonNullable<NonNullable<T>[K1]>[K2]) => any
): T;

export function update<
  T,
  K1 extends keyof NonNullable<T>,
  K2 extends keyof NonNullable<NonNullable<T>[K1]>,
  K3 extends keyof NonNullable<NonNullable<NonNullable<T>[K1]>[K2]>
>(
  obj: T,
  keys: [K1, K2, K3],
  f: (v: NonNullable<NonNullable<NonNullable<T>[K1]>[K2]>[K3]) => any
): T;

export function update<
  T,
  K1 extends keyof NonNullable<T>,
  K2 extends keyof NonNullable<NonNullable<T>[K1]>,
  K3 extends keyof NonNullable<NonNullable<NonNullable<T>[K1]>[K2]>,
  K4 extends keyof NonNullable<
    NonNullable<NonNullable<NonNullable<T>[K1]>[K2]>[K3]
  >
>(
  obj: T,
  keys: [K1, K2, K3, K4],
  f: (
    v: NonNullable<NonNullable<NonNullable<NonNullable<T>[K1]>[K2]>[K3]>[K4]
  ) => any
): T;

export function update<T extends {}>(
  obj: T,
  keys: any[],
  f: (v: any) => any
): T {
  const init = get.apply(null, [obj, ...keys]);
  const o = get.apply(null, [obj, ...butLast(keys)]);
  o[last(keys) as any] = f(init);
  return obj;
}

export function fmap<T, RT>(vs: undefined, f: (v: T) => RT): undefined;
export function fmap<T, RT>(vs: null, f: (v: T) => RT): null;
export function fmap<T, RT>(vs: Error, f: (v: T) => RT): Error;
export function fmap<T, RT>(
  vs: NotUndefinedNullError<T[]>,
  f: (v: NotUndefinedNullError<T>) => RT
): RT[];
export function fmap<T, RT>(
  vs: NotUndefinedNullError<T>,
  f: (v: NotUndefinedNullError<T>) => RT
): RT;
export function fmap<T, RT>(
  vs: NotUndefinedNullError<T[]> | MaybeUndefinedNullError<T[]>,
  f: (v: NotUndefinedNullError<T>) => RT
): NotUndefinedNullError<RT[]> | MaybeUndefinedNullError<RT[]>;
export function fmap<T, RT>(
  vs: NotUndefinedNullError<T> | MaybeUndefinedNullError<T>,
  f: (v: NotUndefinedNullError<T>) => RT
): NotUndefinedNullError<RT> | MaybeUndefinedNullError<RT>;

// TODO: type checker seems confused when using
// fmap with `get`.
export function fmap<T, RT>(
  vs:
    | NotUndefinedNullError<T[]>
    | NotUndefinedNullError<T>
    | Error
    | undefined
    | null,
  f: (v: T) => RT
): RT[] | RT | undefined | null | Error {
  if (vs === undefined) {
    return undefined;
  } else if (vs === null) {
    return null;
  } else if (vs instanceof Error) {
    return vs;
  } else if (vs instanceof Array) {
    return vs.map(f);
  } else {
    return f(vs);
  }
}

export function replace<T>(
  coll: T[],
  pred: (v: T) => boolean,
  val: T
): T[] | undefined {
  return fmap(coll.findIndex(pred), (i: number) => [
    ...coll.slice(0, i),
    val,
    ...coll.slice(i + 1),
  ]);
}

export const replaceOrAdd = <T>(
  coll: T[],
  pred: (v: T) => boolean,
  v: T
): T[] => coll.filter((x) => !pred(x)).concat(v);

export function replaceIn(coll: any, ...keys: string[]) {
  return keys.reduce((acc, curr) => (acc ? acc[curr] : acc), coll);
}

export function undefinedToError<T, ET>(v: T | undefined, err: ET): T | ET {
  return v === undefined ? err : v;
}

export function throwToError<A, RT>(f: (v: A) => RT, v: A): RT | Error;
export function throwToError<A1, A2, RT>(
  f: (v1: A1, v2: A2) => RT,
  v1: A1,
  v2: A2
): RT | Error;
export function throwToError<A1, A2, A3, RT>(
  f: (v1: A1, v2: A2, v3: A3) => RT,
  v1: A1,
  v2: A2,
  v3: A3
): RT | Error;
export function throwToError<A1, A2, A3, A4, RT>(
  f: (v1: A1, v2: A2, v3: A3, v4: A4) => RT,
  v1: A1,
  v2: A2,
  v3: A3,
  v4: A4
): RT | Error;
export function throwToError<RT>(
  f: (...vs: any[]) => RT,
  ...args: any[]
): RT | Error {
  try {
    // eslint-disable-next-line prefer-spread
    return f.apply(undefined, args);
  } catch (e) {
    return e;
  }
}

export function safeCompose<T, RT1, RT2>(
  f1: (v: T) => NotUndefinedNullError<RT1> | undefined | null | Error,
  f2: (v: RT1) => NotUndefinedNullError<RT2> | undefined | null | Error
): (
  v: NotUndefinedNullError<T> | undefined | null | Error
) => NotUndefinedNullError<RT2> | undefined | null | Error {
  return (init: NotUndefinedNullError<T> | undefined | null | Error) => {
    if (init === undefined || init === null || init instanceof Error) {
      return init;
    }

    let v1;
    try {
      v1 = f1(init);
    } catch (e) {
      v1 = e;
    }

    if (v1 === undefined || v1 === null || v1 instanceof Error) {
      return v1;
    }

    let v2;
    try {
      v2 = f2(v1);
    } catch (e) {
      v2 = e;
    }

    return v2;
  };
}

export function safePipe<T, RT1>(
  init: NotUndefinedNullError<T> | MaybeUndefinedNullError<T>,
  f1: (
    v: NotUndefinedNullError<T>
  ) => NotUndefinedNullError<RT1> | MaybeUndefinedNullError<RT1>
): NotUndefinedNullError<RT1> | MaybeUndefinedNullError<RT1> | Error;

export function safePipe<T, RT1, RT2>(
  init: T | undefined | null | Error,
  f1: (
    v: NotUndefinedNullError<T>
  ) => NotUndefinedNullError<RT1> | MaybeUndefinedNullError<RT1>,
  f2: (
    v: NotUndefinedNullError<RT1>
  ) => NotUndefinedNullError<RT2> | MaybeUndefinedNullError<RT2>
): // f1: (v: T) => RT1 | MaybeUndefinedNullError<RT1>, //undefined | null | Error,
// f2: (v: RT1) => RT2 | undefined | null | Error
NotUndefinedNullError<RT2> | MaybeUndefinedNullError<RT2> | Error;

export function safePipe<T, RT1, RT2, RT3>(
  init: NotUndefinedNullError<T> | MaybeUndefinedNullError<T>,
  f1: (
    v: NotUndefinedNullError<T>
  ) => NotUndefinedNullError<RT1> | MaybeUndefinedNullError<RT1>,
  f2: (
    v: NotUndefinedNullError<RT1>
  ) => NotUndefinedNullError<RT2> | MaybeUndefinedNullError<RT2>,
  f3: (
    v: NotUndefinedNullError<RT2>
  ) => NotUndefinedNullError<RT3> | MaybeUndefinedNullError<RT3>
): NotUndefinedNullError<RT3> | MaybeUndefinedNullError<RT3> | Error;

export function safePipe<T, RT1, RT2, RT3, RT4>(
  init: NotUndefinedNullError<T> | MaybeUndefinedNullError<T>,
  f1: (
    v: NotUndefinedNullError<T>
  ) => NotUndefinedNullError<RT1> | MaybeUndefinedNullError<RT1>,
  f2: (
    v: NotUndefinedNullError<RT1>
  ) => NotUndefinedNullError<RT2> | MaybeUndefinedNullError<RT2>,
  f3: (
    v: NotUndefinedNullError<RT2>
  ) => NotUndefinedNullError<RT3> | MaybeUndefinedNullError<RT3>,
  f4: (
    v: NotUndefinedNullError<RT3>
  ) => NotUndefinedNullError<RT4> | MaybeUndefinedNullError<RT4>
): NotUndefinedNullError<RT4> | MaybeUndefinedNullError<RT4> | Error;

export function safePipe<T, RT1, RT2, RT3, RT4, RT5>(
  init: NotUndefinedNullError<T> | MaybeUndefinedNullError<T>,
  f1: (
    v: NotUndefinedNullError<T>
  ) => NotUndefinedNullError<RT1> | MaybeUndefinedNullError<RT1>,
  f2: (
    v: NotUndefinedNullError<RT1>
  ) => NotUndefinedNullError<RT2> | MaybeUndefinedNullError<RT2>,
  f3: (
    v: NotUndefinedNullError<RT2>
  ) => NotUndefinedNullError<RT3> | MaybeUndefinedNullError<RT3>,
  f4: (
    v: NotUndefinedNullError<RT3>
  ) => NotUndefinedNullError<RT4> | MaybeUndefinedNullError<RT4>,
  f5: (
    v: NotUndefinedNullError<RT4>
  ) => NotUndefinedNullError<RT5> | MaybeUndefinedNullError<RT5>
): NotUndefinedNullError<RT5> | MaybeUndefinedNullError<RT5> | Error;

/**
 * Runs the functions `fs` in sequence, e.g. fs[2](fs[1](fs[0](init))).
 * If any function returns undefined, undefined is returned.
 * If any function returns or throws an Error, that Error is returned.
 * Otherwise, returns the result of the function sequence.
 *
 * @param init
 * @param fs
 */
export function safePipe<T, RT>(
  init: NotUndefinedNullError<T> | MaybeUndefinedNullError<T>,
  ...fs: any[]
): NotUndefinedNullError<RT> | MaybeUndefinedNullError<RT> | Error {
  return fs.reduce(
    (res, f) =>
      res === undefined
        ? undefined
        : res === null
        ? null
        : res instanceof Error
        ? res
        : throwToError(f, res),
    init
  );
}

export function pipe<T, RT1>(init: T, f1: (v: T) => RT1): RT1;
export function pipe<T, RT1, RT2>(
  init: T,
  f1: (v: T) => RT1,
  f2: (v: RT1) => RT2
): RT2;

export function pipe(init: any, ...fs: any[]): any {
  return fs.reduce((res, f) => f(res), init);
}

/**
 * Returns `f1` unless the result is `undefined`.
 * In that case, if f2 is a function `f2()` is returned, otherwise `f2` is returned.
 * @param f1
 * @param f2
 */
export function undefinedToDefault<T1, T2>(
  res: T1,
  f2: (() => T2) | T2
): NonNullable<T1> | T2 {
  if (res !== undefined && res !== null) {
    return res as NonNullable<T1>;
  }

  if (f2 instanceof Function) {
    return f2();
  } else {
    return f2;
  }
}

export const butLast = <T>(arr: T[]): T[] => [...arr.slice(0, arr.length - 1)];

export const empty = <T>(arr: T[] | undefined): boolean =>
  arr === undefined || arr.length === 0;

export const notEmpty = <T>(arr: T[] | undefined): boolean =>
  arr !== undefined && arr.length !== 0;

export function throwWhenError<T>(v: T | Error): T {
  if (v instanceof Error) {
    throw v;
  } else {
    return v;
  }
}

export const push = <T>(vs: T[], v: T): T[] => {
  vs.push(v);
  return vs;
};

export const flattenAll = (vs: any[]): any[] =>
  vs.reduce(
    (res: any[], v: any) =>
      v instanceof Array ? res.concat(flattenAll(v)) : push(res, v),
    []
  );

export const flatten = <T>(vs: T[][]): T[] =>
  vs.reduce((res: T[], arr: T[]) => res.concat(arr), []);

/**
 * map that returns undefined when vs === undefined
 * @param vs
 * @param f
 */
export function map<T, RT>(
  vs: T[] | undefined,
  f: ((v: T) => RT) | ((v: T, i: number) => RT)
): RT[] | undefined {
  return vs === undefined ? undefined : vs.map(f);
}

export function equalsAny(arg: any, tests: any[]) {
  for (const current of tests) {
    if (arg === current) {
      return true;
    }
  }
  return false;
}

export function definedNotNull<T>(arg: T | null | undefined): arg is T {
  return arg !== undefined && arg !== null;
}

export function nonEmptyString(arg: string | null | undefined): arg is string {
  return typeof arg === "string" && arg.length > 0;
}

export function noop() {
  // Do nothing
}

export function contains<T extends string | number>(
  items: T[],
  target: T
): boolean {
  for (const item of items) {
    if (item === target) {
      return true;
    }
  }
  return false;
}

/**
 * Moves an element in arr from "from" index to "to" index.
 *
 * By default, the original array is left unmodified. If inPlace = true,
 * the original is modified.
 */
export function move<T>(arr: T[], from: number, to: number, inPlace = false) {
  const target = inPlace ? arr : arr.slice();
  target.splice(to, 0, target.splice(from, 1)[0]);
  return target;
}

export function withDefault<T>(
  optional: T | undefined | null,
  defaultValue: T
) {
  return definedNotNull(optional) ? optional : defaultValue;
}

export function withDefaultText(
  optional: string | undefined | null,
  defaultValue: string
) {
  return definedNotNull(optional) && optional !== "" ? optional : defaultValue;
}

export function replaceInArray<T>(items: T[], index: number, item: T): T[] {
  if (index < 0 || index >= items.length) {
    throw new Error("[replaceInArray] index out of array bounds");
  }

  return [...items.slice(0, index), item, ...items.slice(index + 1)];
}

export function exclude<T>(items: T[], itemToExclude: T): T[] {
  const copy: T[] = [];
  for (const item of items) {
    if (item !== itemToExclude) {
      copy.push(item);
    }
  }
  return copy;
}

export function last<T>(items: T[]): T | undefined {
  return items[items.length - 1];
}

export function omit<T extends {}>(item: T, property: string): T {
  const output: { [key: string]: any } = {};
  for (const key in item) {
    // eslint-disable-next-line no-prototype-builtins
    if (item.hasOwnProperty(key) && key !== property) {
      output[key] = item[key];
    }
  }
  return output as T;
}

export function deepOmitArray<T extends {}>(
  items: any[],
  predicate: (key: keyof T, value: T[keyof T]) => boolean
): any[] {
  return items.map((item) => {
    if (Array.isArray(item)) {
      return deepOmitArray(item, predicate);
    } else if (typeof item === "object" && item !== null) {
      return deepOmit<T>(item, predicate);
    } else {
      return item;
    }
  });
}

export function deepOmit<T extends {}>(
  item: T,
  predicate: (key: keyof T, value: T[keyof T]) => boolean
): T {
  const output: { [key: string]: any } = {};
  for (const key in item) {
    // eslint-disable-next-line no-prototype-builtins
    if (item.hasOwnProperty(key) && !predicate(key, item[key])) {
      const value = item[key];
      if (Array.isArray(value)) {
        output[key] = deepOmitArray(value, predicate);
      } else if (typeof value === "object" && value !== null) {
        output[key] = deepOmit<T>(value as any, predicate);
      } else {
        output[key] = item[key];
      }
    }
  }
  return output as T;
}

export function truncate(text: string, maxLength: number): string {
  return text.slice(0, maxLength);
}

/**
 * Extracts the full local date in the format YYYY-MM-DD.
 */
export function extractDateString(input: Date): string {
  const year = input.getFullYear();
  const m = input.getMonth() + 1; // getMonth returns 0-based month
  const month = m < 10 ? "0" + m : m;
  const d = input.getDate();
  const date = d < 10 ? "0" + d : d;

  return `${year}-${month}-${date}`;
}

export function isPast(input: Date): boolean {
  return input.getTime() < Date.now();
}
