import { getTr } from "./";
import * as gqltypes from "../gqltypes";

type TrType = ReturnType<typeof getTr>;

export function defaultDialogCancel(tr: TrType) {
  return tr("defaultDialogCancel");
}

export function defaultDialogProceed(tr: TrType) {
  return tr("defaultDialogProceed");
}

export const getRelationTypeLabel = (
  type: gqltypes.RelationType,
  tr: TrType
) => {
  const map: { [typee in gqltypes.RelationType]: string } = {
    guardian: tr("relationTypeGuardianLabel"),
    ward: tr("relationTypeWardLabel"),
    foster_parent: tr("relationTypeFosterParentLabel",),
    foster_child: tr("relationTypeFosterChildLabel"),
    other_adult: tr("relationTypeOtherAdultLabel"),
    other_adult_of: tr("relationTypeOtherAdultOfLabel"),
  };
 
  return map[type];
};

export const getEmailTemplateStrings = (tr: TrType) => {
  return {
    exampleNameGuardian: tr("exampleNameGuardian"),
    exampleNameChild: tr("exampleNameChild"),
  };
};
