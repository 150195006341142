import * as React from "react";
import Select, { Props as ReactSelectProps, createFilter } from "react-select";

interface Props extends ReactSelectProps {
  label?: React.ReactNode;
}

const filter = createFilter({
  matchFrom: "any",
  stringify: (option) => `${option.label}`,
});

export default function FormSearchSelectRounded(props: Props) {
 
  return (
    <>
    <label className="rounded-search-label" >{props.label}</label>
    <Select  {...props}  classNamePrefix={"rounded-search-select"} filterOption={filter}  />
    </>
  );
}
