import * as oidc from "oidc-client";
import * as sentry from "../sentry";
import { getSettings } from "../settings";
import { store } from "../store";
import { flagCookiesUnavailable, logoutRequested } from "./actions";

// Support for server side pre-rendering and testing
const memoryStorage = new oidc.InMemoryWebStorage();

let sessionStore = memoryStorage;
let localStore = memoryStorage;

try {
  sessionStore = window.sessionStorage;
  localStore = window.localStorage;
} catch {
  // cookies blocked
  store.dispatch(flagCookiesUnavailable());
}

const settings = getSettings();

const authSettings: oidc.UserManagerSettings = {
  authority: settings.authority,
  client_id: settings.openIdConnect.clientId,
  redirect_uri: settings.redirectUri,
  post_logout_redirect_uri: settings.signoutRedirectUri,
  response_type: "code",
  scope: "openid profile consent email",
  filterProtocolClaims: true,
  loadUserInfo: true,
  userStore: new oidc.WebStorageStateStore({
    store: sessionStore,
  }),
  stateStore: new oidc.WebStorageStateStore({
    store: localStore,
  }),
  monitorSession: false,
  // silent_redirect_uri: settings.silentRedirectUri
  // automaticSilentRenew: true
};

oidc.Log.logger = console;
oidc.Log.level = oidc.Log.WARN;

function isOnAdminPages() {
  return settings.useHashRouter
    ? window.location.hash.slice(1).startsWith("/admin")
    : window.location.pathname.startsWith("/admin");
}

export function getSingoutRedirectUrl(reason?: "session_timeout") {
  const reasonQueryParam = reason ? "?reason=" + reason : "";
  return (isOnAdminPages() ? "/admin" : "/") + reasonQueryParam;
}

export const userManager = new oidc.UserManager(authSettings);

export const signinRedirect = (prompt?: "login") => {
  const state = store.getState();

  userManager.signinRedirect({
    extraQueryParams: {
      role: isOnAdminPages() ? "teacher admin" : "guardian student",
    },
    acr_values: state.app.idp ? `idp:${state.app.idp}` : undefined,
    ui_locales:
      state.app.uiLanguage + " " + settings.regionSettings.defaultLanguage,
    state: {
      path: settings.useHashRouter
        ? window.location.hash.slice(1)
        : window.location.pathname,
    },
    prompt,
  });
};

export const signoutRedirect = (reason?: "session_timeout") => {
  return userManager.signoutRedirect({
    state: { path: getSingoutRedirectUrl(reason) },
  });
};

userManager.events.addAccessTokenExpiring(() => {
  console.log("Access token expiring");
});

userManager.events.addSilentRenewError((data) => {
  console.log("SilentRenewError", data);
});

userManager.events.addAccessTokenExpired(() => {
  console.log("ACCESS TOKEN EXPIRED");
  store.dispatch(logoutRequested({ reason: "session_timeout" }));
});

let impersonateAuthToken: string | null = null;

export const getAuthToken = async () => {
  // console.log("getAuthToken", impersonateAuthToken);
  if (impersonateAuthToken) return impersonateAuthToken;

  const user = await userManager.getUser();
  return user?.access_token;
};

export const isImpersonating = () => {
  return Boolean(impersonateAuthToken);
};

export const impersonate = async (props: { subjectId: string }) => {
  const user = await userManager.getUser();
  const accessToken = user?.access_token;

  if (!accessToken) {
    throw new Error("missing accessToken to impersonate");
  }

  const data = new FormData();
  data.append("subject_id", props.subjectId!);
  data.append("grant_type", "impersonation");
  data.append("client_id", authSettings.client_id!);
  data.append("actor_token", accessToken);

  try {
    const response = await fetch(authSettings.authority + "/connect/token", {
      method: "POST",
      body: data,
    });

    if (response.status >= 400) {
      console.error("Impersonation failed");
      sentry.captureMessage("Impersonation failed", {
        extra: { subjectId: props.subjectId },
      });
      // setState({ error: "impersonation_failed" });
      return;
    }

    const responseData = await response.json();

    // if (aborted) {
    //   return;
    // }

    // console.log("Impersonate ok");
    impersonateAuthToken = responseData.access_token;
    return responseData.access_token;
  } catch (error) {
    // if (aborted) {
    //   return;
    // }

    console.error("Network error");
    // setState({ error: "network_error" });
  }
};

export const stopImpersonation = () => {
  // console.log("Stop impersonation");
  impersonateAuthToken = null;
};
