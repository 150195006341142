import _ from "lodash";
import * as React from "react";
import { useMemo } from "react";
import FlipMove from "react-flip-move";
import { Transition, animated, config } from "react-spring/renderprops";
import {
  QuestionAndAnswer,
  matchingPred,
} from "../../Admin/components/CreateForm";
import { availableTranslationLanguages } from "../../Admin/form";
import { Translation } from "../../App/reducer";
import {
  contains,
  definedNotNull,
  exclude,
  extractDateString,
  move,
  nonEmptyString,
} from "../../Utils/functional";
import * as f from "../../Utils/functional";
import { assertUnreachable } from "../../Utils/typeHelpers";
import * as gqltypes from "../../gqltypes";
import { getSettings } from "../../settings";
import { FormQuestionDataTyped } from "../../types";
import { isValidEmail, isValidNationalId, isValidPhone } from "../validation";
import { DatePickerWithoutTranslation } from "./DatePicker";
import { FormInput, FormInputType } from "./FormInput";
import FormSearchSelect from "./FormSearchSelect";
import { FormTextArea } from "./FormTextArea";
import { IconButton } from "./IconButton";
import { Checkbox, FormPredicateComponentView, Radiobutton } from "..";

const settings = getSettings();

interface Props {
  tr: Translation["tr"];
  uiLanguage: Translation["uiLanguage"];
  updateAnswer?: (answer: gqltypes.QuestionAnswerUnion) => void;
  updateComponentAnswer?: (component: gqltypes.ComponentAnswerInput) => void;
  answer?: gqltypes.QuestionAnswerUnion;
  disableAnswer?: boolean;
  getPredicateComponent?: (id: string) => gqltypes.FormPredicateComponent;
  getPredicateComponentTranslation?: (
    id: string
  ) =>
    | NonNullable<
        gqltypes.FullTranslation["componentData"]["predicateComponents"]
      >[0]
    | null;
  data:
    | gqltypes.FormQuestionWithOptionsUnion
    | gqltypes.FormQuestionWithoutOptionsUnion;
  validateAnswers?: boolean;
  translationLanguage?: keyof availableTranslationLanguages | null;
  translation?:
    | gqltypes.FullTranslation["componentData"]["components"][0]["questions"][0]
    | null;
  translating?: boolean;
  readOnly?: boolean;
  response?: gqltypes.FormAnswer;
}

const containerClasses = "question-container";

function matchingPreds(
  qna: QuestionAndAnswer
): gqltypes.FormQuestionPredicate[] {
  const preds = (qna.question.predicates || []).filter((p) =>
    matchingPred(qna, p)
  );
  return preds;
}

interface PCProps extends Props {
  getPredicateComponent: (id: string) => gqltypes.FormPredicateComponent;
}

function PredicateComponents(props: PCProps): JSX.Element {
  const preds = useMemo(
    () =>
      matchingPreds({
        question: props.data,
        answer: props.answer,
      }),
    [props.data, props.answer]
  );

  return (
    // eslint-disable-next-line
    // @ts-ignore
    <Transition
      native
      config={config.stiff}
      items={preds}
      immediate={(k) => (k === "display" ? true : false)}
      keys={(p) => (p as any).componentId}
      from={{
        height: 0,
        opacity: 0,
        overflow: "hidden",
        transform: "translate3d(0,-40px,0)",
      }}
      enter={{
        height: "auto",
        opacity: 1,
        overflow: "visible",
        transform: "translate3d(0,0px,0)",
      }}
      leave={{
        height: 0,
        opacity: 0,
        overflow: "hidden",
        transform: "translate3d(0,-40px,0)",
      }}
    >
      {/* eslint-disable-next-line react/display-name */}
      {(p) => (style) => {
        const componentResponse =
          props.response &&
          props.response.components.find(
            (c) => c.componentId === p.componentId
          );

        return (
          <animated.div style={style}>
            <FormPredicateComponentView
              {...f.omit(props, "answer")}
              answer={componentResponse}
              translation={
                props.getPredicateComponentTranslation &&
                props.getPredicateComponentTranslation(p.componentId)
              }
              component={props.getPredicateComponent(p.componentId)}
            />
          </animated.div>
        );
      }}
    </Transition>
  );

  /*
  return (
    <>
      {transitions.map(({ item: p, key, props: style }) => {

        const componentResponse =
          props.response &&
          props.response.components.find(c => c.componentId === p.componentId);

        return (
          <Anim
            key={key}
            style={style}
            {...f.omit(props, "answer")}
            answer={componentResponse}
            translation={
              props.getPredicateComponentTranslation &&
              props.getPredicateComponentTranslation(p.componentId)
            }
            component={props.getPredicateComponent(p.componentId)}
          />
        );
      })}
    </>
  );
  */
}

export class FormComponentQuestionView extends React.PureComponent<Props> {
  public render() {
    const { tr, translation, translating } = this.props;
    const compulsory = this.props.data.compulsory;
    const label =
      (translating
        ? translation
          ? translation.question
          : ""
        : this.props.data.question) || tr("formQuestion");

    return (
      <>
        <div className={containerClasses}>
          <div className="question-header">
            <label
              id={`${this.props.data.id}_label`}
              className="question-title font-weight-bold"
              htmlFor={this.props.data.id}
            >
              {label}
              {this.props.data.type === gqltypes.FormQuestionType.ranking ? (
                <span className="sr-only">
                  {this.getRankingCountText(
                    tr,
                    this.props.data as FormQuestionDataTyped<"ranking">
                  )}
                  {". "}
                  {tr("formComponentQuestionViewRankingDescription")}
                </span>
              ) : null}
            </label>
            {compulsory ? " *" : ""}
          </div>
          <div className={"question-body"}>{this.renderQuestion()}</div>
        </div>
        {this.props.getPredicateComponent !== undefined && (
          <PredicateComponents
            {...this.props}
            getPredicateComponent={this.props.getPredicateComponent}
          />
        )}
      </>
    );
  }

  private handleCheckBoxChange = (
    questionId: string,
    optionId: string,
    on: boolean
  ) => {
    if (!this.props.updateAnswer) {
      return;
    }

    const currentAnswer = this.props.answer
      ? (this.props.answer as gqltypes.AnswerCheckboxes).checkboxes
      : [];
    let newAnswer: string[];
    if (on) {
      newAnswer =
        currentAnswer.indexOf(optionId) === -1
          ? (newAnswer = currentAnswer.concat([optionId]))
          : (newAnswer = currentAnswer.slice());
    } else {
      newAnswer = exclude(currentAnswer, optionId);
    }
    this.props.updateAnswer({ questionId, checkboxes: newAnswer });
  };

  private handleMultiChoiceChange = (questionId: string, optionId: string) => {
    if (!this.props.updateAnswer) {
      return;
    }
    this.props.updateAnswer({ questionId, multiChoice: optionId });
  };

  private handleRankingChange = (
    questionId: string,
    optionId: string,
    order: number
  ) => {
    if (!this.props.updateAnswer) {
      return;
    }
    const currentAnswer = this.props.answer
      ? [...(this.props.answer as gqltypes.AnswerRanking).rankings]
      : [];

    let currentIndex = _.findIndex(
      currentAnswer,
      (item) => item.value === optionId
    );

    if (currentIndex === -1) {
      currentAnswer.push({ value: optionId, order });
      currentIndex = currentAnswer.length - 1;
    }

    const rankings = move(currentAnswer, currentIndex, order);

    rankings.forEach((r, i) => {
      r.order = i;
    });

    this.props.updateAnswer({ questionId, rankings });
  };

  private handleRankingRemove = (questionId: string, optionId: string) => {
    if (!this.props.updateAnswer) {
      return;
    }
    const currentAnswer = this.props.answer
      ? [...(this.props.answer as gqltypes.AnswerRanking).rankings]
      : [];

    const currentIndex = _.findIndex(
      currentAnswer,
      (item) => item.value === optionId
    );

    if (currentIndex === -1) {
      return;
    }

    currentAnswer.splice(currentIndex, 1);

    currentAnswer.forEach((r, i) => {
      r.order = i;
    });

    this.props.updateAnswer({ questionId, rankings: currentAnswer });
  };

  private handleLongTextChange = (questionId: string, answer: string) => {
    if (!this.props.updateAnswer) {
      return;
    }
    this.props.updateAnswer({ questionId, longText: answer });
  };

  private handleShortTextChange = (questionId: string, answer: string) => {
    if (!this.props.updateAnswer) {
      return;
    }
    this.props.updateAnswer({ questionId, shortText: answer });
  };

  private handleYesNoChange = (questionId: string, isYes: boolean) => {
    if (!this.props.updateAnswer) {
      return;
    }
    this.props.updateAnswer({ questionId, yesOrNo: isYes });
  };

  private handleDateChange = (questionId: string, date: Date | undefined) => {
    if (!this.props.updateAnswer) {
      return;
    }
    this.props.updateAnswer({
      questionId,
      date: date ? extractDateString(date) : null,
    });
  };

  private renderCheckboxes(
    data: FormQuestionDataTyped<"checkboxes">,
    answer?: gqltypes.AnswerCheckboxes
  ) {
    const { translation, translating } = this.props;
    const translationOptionMap = translation
      ? translation.options!.reduce((out, item) => {
          out[item.id] = item;
          return out;
        }, {} as { [id: string]: { id: string; label: string } })
      : {};

    return (
      <fieldset
        id={this.props.data.id}
        role="group"
        aria-labelledby={`${this.props.data.id}_label`}
        className="row"
      >
        {data.options.map((option, i) => {
          const optionTranslation = translationOptionMap[option.id];
          const label = translating
            ? optionTranslation
              ? optionTranslation.label
              : ""
            : option.label;

          return (
            <div key={option.id} className="col-12 col-sm-6 col-md-4 col-lg-3">
              <Checkbox
                id={option.id}
                disabled={this.props.disableAnswer}
                label={label || this.props.tr("formOption") + (i + 1)}
                checked={
                  answer ? contains(answer.checkboxes, option.id) : false
                }
                onChange={(id, checked: boolean) =>
                  this.handleCheckBoxChange(data.id, option.id, checked)
                }
                required={data.compulsory}
              />
            </div>
          );
        })}
      </fieldset>
    );
  }

  private renderMultiChoice(
    data: FormQuestionDataTyped<"multiChoice">,
    answer?: gqltypes.AnswerMultiChoice
  ) {
    const requiredAnswerMissing =
      !definedNotNull(answer) && this.props.validateAnswers && data.compulsory;
    const { translation, translating } = this.props;
    const translationOptionMap =
      translation && translation.options
        ? translation.options!.reduce((out, item) => {
            out[item.id] = item;
            return out;
          }, {} as { [id: string]: { id: string; label: string } })
        : {};

    const getOptionLabel = (
      option: (typeof data)["options"][0],
      index = -1
    ) => {
      const optionTranslation = translationOptionMap[option.id];
      const label = translating
        ? optionTranslation
          ? optionTranslation.label
          : ""
        : option.label;
      return label || this.props.tr("formOption") + " " + (index + 1);
    };

    return (
      <React.Fragment>
        {data.dropdownView ? (
          <div className="row">
            <FormSearchSelect
              aria-labelledby={`${this.props.data.id}_label`}
              aria-describedby={`${this.props.data.id}_error`}
              className="col-md-6"
              menuShouldScrollIntoView
              isDisabled={this.props.disableAnswer}
              placeholder={this.props.tr(
                "formComponentQuestionViewMultiChoiceDropdownPlaceholder"
              )}
              noOptionsMessage={() =>
                this.props.tr("formComponentQuestionViewNoOptionsMessage")
              }
              maxMenuHeight={320}
              options={data.options.map((option, i) => {
                return { value: option.id, label: getOptionLabel(option, i) };
              })}
              value={
                answer && answer.multiChoice
                  ? {
                      value: answer.multiChoice,
                      label: getOptionLabel(
                        data.options.find((o) => o.id === answer.multiChoice)!
                      ),
                    }
                  : null
              }
              onChange={(option: any) => {
                this.handleMultiChoiceChange(data.id, option.value);
              }}
            />
          </div>
        ) : (
          <fieldset
            id={this.props.data.id}
            aria-labelledby={`${this.props.data.id}_label`}
            aria-describedby={`${this.props.data.id}_error`}
            role="radiogroup"
            className="row"
          >
            {data.options.map((option, i) => {
              return (
                <div
                  key={option.id}
                  className="col-12 col-sm-6 col-md-4 col-lg-3"
                >
                  <Radiobutton
                    id={option.id}
                    label={getOptionLabel(option, i)}
                    disabled={this.props.disableAnswer}
                    invalid={requiredAnswerMissing}
                    name={data.id}
                    value={option.id}
                    checked={answer ? answer.multiChoice === option.id : false}
                    onChange={() =>
                      this.handleMultiChoiceChange(data.id, option.id)
                    }
                    required={data.compulsory}
                  />
                </div>
              );
            })}
          </fieldset>
        )}
        {requiredAnswerMissing ? (
          <div
            id={`${this.props.data.id}_error`}
            aria-labelledby={`${this.props.data.id}_label`}
            className="invalid-feedback d-block"
          >
            {this.props.tr("validationAnswerCompulsoryButMissing")}
          </div>
        ) : null}
      </React.Fragment>
    );
  }

  private getRankingCountText(
    tr: Translation["tr"],
    data: FormQuestionDataTyped<"ranking">
  ) {
    const { minSelectedOptions: minSel, maxSelectedOptions: maxSel } = data;
    return minSel && maxSel
      ? minSel !== maxSel
        ? tr("formComponentQuestionViewSelectOptionRange", minSel, maxSel)
        : tr("formComponentQuestionViewSelectOptionExact", minSel)
      : minSel
      ? tr("formComponentQuestionViewSelectOptionMin", minSel)
      : maxSel
      ? tr("formComponentQuestionViewSelectOptionMax", maxSel)
      : tr("formComponentQuestionViewSelectOptionNoRequirement");
  }

  private renderRanking(
    data: FormQuestionDataTyped<"ranking">,
    answer?: gqltypes.AnswerRanking
  ) {
    const { tr, translation, translating } = this.props;
    const translationOptionMap =
      translation && translation.options
        ? translation.options!.reduce((out, item) => {
            out[item.id] = item;
            return out;
          }, {} as { [id: string]: { id: string; label: string } })
        : {};

    const unpickedOptions = data.options
      .filter((opt) =>
        answer ? !answer.rankings.map((r) => r.value).includes(opt.id) : true
      )
      .map((opt) => ({
        ...opt,
        value: opt.id,
        label:
          translating && translationOptionMap[opt.id]
            ? translationOptionMap[opt.id].label
            : opt.label,
      }));

    const pickedOptions = answer ? answer.rankings.map((r) => r.value) : [];

    const minSel = data.compulsory ? data.minSelectedOptions || 1 : undefined;
    const maxSel = data.maxSelectedOptions;

    const placeholder = this.getRankingCountText(tr, data);

    const sel = pickedOptions.length;
    const invalidAnswer =
      sel > (maxSel || Number.MAX_SAFE_INTEGER) ||
      sel < (minSel || Number.MIN_SAFE_INTEGER);

    return (
      <fieldset
        id={this.props.data.id}
        aria-labelledby={`${this.props.data.id}_label`}
      >
        <p>{tr("formComponentQuestionViewRankingDescription")}</p>
        <FlipMove
          duration={350}
          easing="ease-out"
          appearAnimation="fade"
          enterAnimation="fade"
          leaveAnimation="fade"
        >
          {pickedOptions.map((option, i) => {
            const optionData = data.options.find((o) => o.id === option);
            if (!optionData) {
              throw new Error("no option");
            }
            const optionTranslation = translationOptionMap[option];
            const label = translating
              ? optionTranslation
                ? optionTranslation.label
                : ""
              : optionData.label;

            const currentAns = answer
              ? answer.rankings.find((r) => r.value === option)
              : undefined;
            const currentOrder = currentAns ? currentAns.order : -1;

            return (
              <div
                key={option}
                className="p-2 my-1 border-bottom d-flex flex-dir-row"
              >
                <span className="ml-1">
                  <strong>{currentOrder + 1}</strong>.{" "}
                  {label || this.props.tr("formOption") + (i + 1)}
                </span>
                {!this.props.readOnly ? (
                  <div className="ml-auto d-flex">
                    <IconButton
                      onClick={() =>
                        this.handleRankingChange(data.id, option, i - 1)
                      }
                      iconClass="fas fa-chevron-up"
                      disabled={i === 0}
                      aria-label={tr(
                        "formComponentQuestionViewRankingMoveOptionUp",
                        label
                      )}
                    />
                    <IconButton
                      onClick={() =>
                        this.handleRankingChange(data.id, option, i + 1)
                      }
                      iconClass="fas fa-chevron-down"
                      disabled={i === pickedOptions.length - 1}
                      aria-label={tr(
                        "formComponentQuestionViewRankingMoveOptionDown",
                        label
                      )}
                    />
                    <IconButton
                      onClick={() => this.handleRankingRemove(data.id, option)}
                      iconClass="fas  fa-times"
                      aria-label={tr(
                        "formComponentQuestionViewRankingRemoveOption",
                        label
                      )}
                    />
                  </div>
                ) : null}
              </div>
            );
          })}
        </FlipMove>
        {!this.props.readOnly ? (
          <React.Fragment>
            <div className="row mt-3">
              <div className="col-12 col-md-6">
                <FormSearchSelect
                  aria-labelledby={`${this.props.data.id}_label`}
                  menuShouldScrollIntoView
                  placeholder={
                    unpickedOptions.length
                      ? placeholder
                      : this.props.tr(
                          "formComponentQuestionViewAllOptionsSelected"
                        )
                  }
                  noOptionsMessage={() =>
                    this.props.tr("formComponentQuestionViewAllOptionsSelected")
                  }
                  maxMenuHeight={320}
                  closeMenuOnSelect={false}
                  blurInputOnSelect={false}
                  isDisabled={this.props.disableAnswer}
                  options={unpickedOptions}
                  value={null}
                  onChange={(option: any) => {
                    this.handleRankingChange(
                      data.id,
                      option.id,
                      pickedOptions.length
                    );
                  }}
                />
              </div>
            </div>
            <div
              className={"invalid-feedback " + (invalidAnswer ? "d-block" : "")}
            >
              {this.props.validateAnswers
                ? tr("validationAnswerRankingWrongAmount", placeholder)
                : null}
            </div>
          </React.Fragment>
        ) : null}
      </fieldset>
    );
  }

  private renderLongText(
    data: FormQuestionDataTyped<"longText">,
    answer?: gqltypes.AnswerLongText
  ) {
    const requiredAnswerMissing =
      !(answer && nonEmptyString(answer.longText)) &&
      this.props.validateAnswers &&
      data.compulsory;
    return (
      <FormTextArea
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
          this.handleLongTextChange(data.id, event.target.value)
        }
        id={data.id}
        aria-labelledby={`${this.props.data.id}_label`}
        disabled={this.props.disableAnswer}
        invalid={requiredAnswerMissing}
        validationMessage={() =>
          this.props.tr("validationAnswerCompulsoryButMissing")
        }
        required={data.compulsory}
        value={answer ? answer.longText : ""}
      />
    );
  }

  private renderShortText(
    data: FormQuestionDataTyped<"shortText">,
    answer?: gqltypes.AnswerShortText
  ) {
    const { tr } = this.props;
    const input = answer ? answer.shortText : null;

    const errors: string[] = [];

    if (!nonEmptyString(input) && data.compulsory) {
      errors.push(tr("validationAnswerCompulsoryButMissing"));
    }

    let type: FormInputType = "text";

    switch (data.validationType) {
      case "email":
        type = "email";
        if (input && !isValidEmail(input)) {
          errors.push(tr("validationAnswerInvalidEmail"));
        }
        break;
      case "nationalId":
        if (input && !isValidNationalId(input)) {
          errors.push(tr("validationAnswerInvalidNationalId"));
        }
        break;
      case "phone":
        type = "tel";
        if (input && !isValidPhone(input)) {
          errors.push(tr("validationAnswerInvalidPhoneNumber"));
        }
        break;
    }

    return (
      <FormInput
        id={data.id}
        aria-labelledby={`${this.props.data.id}_label`}
        disabled={this.props.disableAnswer}
        type={type}
        feedback={
          this.props.validateAnswers && errors.length
            ? errors.join(", ")
            : undefined
        }
        value={answer ? answer.shortText : ""}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          this.handleShortTextChange(data.id, event.target.value)
        }
        onBlur={
          data.validationType === "nationalId"
            ? () => {
                const { normalizeNationalId, readableNationalId } =
                  settings.regionSettings;
                const value = answer ? answer.shortText : "";
                const formated = readableNationalId(
                  normalizeNationalId(value, { invalidReturnInput: true })
                );
                if (value !== formated) {
                  this.handleShortTextChange(data.id, formated);
                }
              }
            : undefined
        }
        required={data.compulsory}
      />
    );
  }

  private renderYesOrNo(
    data: FormQuestionDataTyped<"yesOrNo">,
    answer?: gqltypes.AnswerYesOrNo
  ) {
    const requiredAnswerMissing =
      !definedNotNull(answer) && this.props.validateAnswers && data.compulsory;
    const yesId = data.id + "-yes";
    const noId = data.id + "-no";
    return (
      <React.Fragment>
        <fieldset
          id={this.props.data.id}
          role="radiogroup"
          aria-labelledby={`${this.props.data.id}_label`}
          className={
            "form-group option-row " + (requiredAnswerMissing ? "invalid" : "")
          }
        >
          <Radiobutton
            checked={answer ? answer.yesOrNo === true : false}
            disabled={this.props.disableAnswer}
            id={yesId}
            invalid={requiredAnswerMissing}
            label={this.props.tr("formYes")}
            name={data.id}
            onChange={() => this.handleYesNoChange(data.id, true)}
            required={data.compulsory}
            value={yesId}
          />
          <Radiobutton
            checked={answer ? answer.yesOrNo === false : false}
            disabled={this.props.disableAnswer}
            id={noId}
            invalid={requiredAnswerMissing}
            label={this.props.tr("formNo")}
            name={data.id}
            onChange={() => this.handleYesNoChange(data.id, false)}
            required={data.compulsory}
            value={noId}
          />
        </fieldset>
        <div
          className={
            "invalid-feedback " + (requiredAnswerMissing ? "d-block" : "")
          }
        >
          {this.props.tr("validationAnswerCompulsoryButMissing")}
        </div>
      </React.Fragment>
    );
  }

  private renderDate(
    data: FormQuestionDataTyped<"date">,
    answer?: gqltypes.AnswerDate
  ) {
    const requiredAnswerMissing =
      (!definedNotNull(answer) || !answer.date) &&
      this.props.validateAnswers &&
      data.compulsory;

    return (
      <DatePickerWithoutTranslation
        aria-labelledby={`${this.props.data.id}_label`}
        tr={this.props.tr}
        uiLanguage={this.props.uiLanguage}
        id={data.id}
        day={answer && answer.date ? new Date(answer.date) : ""}
        handleSelectDay={(day) => {
          this.handleDateChange(data.id, day);
        }}
        disabled={this.props.disableAnswer}
        readOnly={this.props.readOnly}
        feedback={
          requiredAnswerMissing
            ? this.props.tr("validationAnswerCompulsoryButMissing")
            : undefined
        }
      />
    );
  }

  private renderQuestion() {
    const answer = this.props.answer;
    switch (this.props.data.type) {
      case gqltypes.FormQuestionType.multiChoice:
        return this.renderMultiChoice(
          this.props.data as any,
          answer as gqltypes.AnswerMultiChoice
        );
      case gqltypes.FormQuestionType.checkboxes:
        return this.renderCheckboxes(
          this.props.data as any,
          answer as gqltypes.AnswerCheckboxes
        );
      case gqltypes.FormQuestionType.ranking:
        return this.renderRanking(
          this.props.data as any,
          answer as gqltypes.AnswerRanking
        );
      case gqltypes.FormQuestionType.shortText:
        return this.renderShortText(
          this.props.data as any,
          answer as gqltypes.AnswerShortText
        );
      case gqltypes.FormQuestionType.longText:
        return this.renderLongText(
          this.props.data as any,
          answer as gqltypes.AnswerLongText
        );
      case gqltypes.FormQuestionType.yesOrNo:
        return this.renderYesOrNo(
          this.props.data as any,
          answer as gqltypes.AnswerYesOrNo
        );
      case gqltypes.FormQuestionType.date:
        return this.renderDate(
          this.props.data as any,
          answer as gqltypes.AnswerDate
        );
      default:
        assertUnreachable(this.props.data.type);
    }
  }
}
