import { findIndex } from "lodash";
import * as React from "react";
import { SYMBOLS, availableTranslationLanguages } from "../../Admin/form";
import { useTranslation } from "../../App/reducer";
import {
  definedNotNull,
  replaceInArray,
  withDefault,
} from "../../Utils/functional";
import * as gqltypes from "../../gqltypes";
import { getComponentPermissionDisplayName } from "../../permissions";
import { getTr } from "../../translation";
import { useRandomId } from "../hooks";
import {
  componentIsValid,
  predicateComponentIsValid,
  richTextIsValid,
  translationComponentIsValid,
  translationPredicateComponentIsValid,
} from "../validation";
import { Checkbox, FormComponentQuestionView, RTViewer } from "..";

interface Props {
  getPredicateComponent: (id: string) => gqltypes.FormPredicateComponent;
  getPredicateComponentTranslation?: (
    id: string
  ) =>
    | NonNullable<
        gqltypes.FullTranslation["componentData"]["predicateComponents"]
      >[0]
    | null;
  actionBlock?: JSX.Element;
  isFormCreator?: boolean;
  validateComponent?: boolean;
  removeComponent?: (e: React.MouseEvent<HTMLDivElement>) => void;
  startEditingComponent?: (event: React.MouseEvent<HTMLElement>) => void;
  validateAnswers?: boolean;
  updateComponentAnswer?: (component: gqltypes.ComponentAnswerInput) => void;
  answer?: gqltypes.FormComponentAnswer;
  component: gqltypes.FormComponent;
  formLanguage: gqltypes.ISO6391Language;
  translation?:
    | gqltypes.FullTranslation["componentData"]["components"][0]
    | null;
  translationLanguage?: keyof availableTranslationLanguages | null;
  translating?: boolean;
  readOnly?: boolean;
  response?: gqltypes.FormAnswer;
  isEditable?: boolean | null;
}

export const answerForQuestion = (
  q: gqltypes.FormQuestion,
  answer: gqltypes.ComponentAnswerInput | undefined
) => answer && answer.questions.find((qAnswer) => qAnswer.questionId === q.id);

export const FormComponentView = (props: Props) => {
  const {
    component,
    translation,
    translating,
    translationLanguage,
    formLanguage,
  } = props;
  const overrideLanguage = translating
    ? translation
      ? translation.language
      : null
    : (component as gqltypes.FormComponent).language;
  const language =
    overrideLanguage ||
    (translating ? translationLanguage || formLanguage : formLanguage);

  const handleChangeSensitiveConsentAnswer = (_: unknown, checked: boolean) => {
    if (props.updateComponentAnswer === undefined) {
      return;
    }
    props.updateComponentAnswer({
      componentId: props.component.id,
      questions: [],
      sensitiveInformationConsent: checked,
      redacted: props.answer?.redacted ?? null,
    });
  };

  const updateAnswer = (answer: gqltypes.QuestionAnswerUnion) => {
    if (props.updateComponentAnswer === undefined) {
      return;
    }

    if (!definedNotNull(props.answer)) {
      return props.updateComponentAnswer({
        componentId: props.component.id,
        questions: [answer],
      });
    }

    const questions = props.answer.questions || [];
    const indexToUpdate = findIndex(
      questions,
      (q) => q.questionId === answer.questionId
    );
    const sensitiveInformationConsent = withDefault(
      props.answer.sensitiveInformationConsent,
      false
    );

    if (indexToUpdate === -1) {
      return props.updateComponentAnswer({
        componentId: props.component.id,
        questions: [...questions, answer],
        sensitiveInformationConsent,
        redacted: props.answer?.redacted ?? null,
      });
    }
    props.updateComponentAnswer({
      componentId: props.component.id,
      questions: replaceInArray(questions, indexToUpdate, answer),
      sensitiveInformationConsent,
      redacted: props.answer?.redacted ?? null,
    });
  };

  const tr = getTr(language);
  const uiTranslation = useTranslation();
  // Trick to get the parser to find string
  const uiLang = { tr: uiTranslation.tr };
  const className = "box";
  const sensitiveConsentId = useRandomId();
  const sensitiveConsent = props.answer
    ? withDefault(props.answer.sensitiveInformationConsent, false)
    : false;
  const newComponentPlaceholder = tr("formComponentNewComponentPlaceholder");
  const predicateComponents = component.questions
    .reduce((predComponentIds, q) => {
      if (q.predicates) {
        predComponentIds.push(...q.predicates.map((p) => p.componentId));
      }
      return predComponentIds;
    }, [] as string[])
    .map((id) => ({
      trans:
        props.getPredicateComponentTranslation &&
        props.getPredicateComponentTranslation(id),
      base: props.getPredicateComponent(id),
    }));

  const indicateInvalid =
    props.validateComponent &&
    (translating
      ? translation
        ? translationComponentIsValid(translation, component) &&
          predicateComponents.every((c) =>
            translationPredicateComponentIsValid(c.trans!, c.base)
          )
        : false
      : componentIsValid(component) &&
        predicateComponents.every((c) => predicateComponentIsValid(c.base))) ===
      false;

  const displayValues = translating
    ? {
        title: translation ? translation.title : "",
        description: translation ? translation.description : "",
        attachments: translation ? translation.attachments : [],
      }
    : {
        title: component.title,
        description: component.description,
        attachments: component.attachments || [],
      };

  const sensitiveAndOther =
    props.component.sensitive && Boolean(props.actionBlock || overrideLanguage);

  const redactedComponent = props.answer?.redacted ? true : false;

  return (
    <>
      <div
        onClick={props.startEditingComponent}
        className={className + (indicateInvalid ? " invalid" : "")}
      >
        {redactedComponent && (
          <div
            className="d-flex justify-content-center w-100 p-3"
            style={{
              backgroundColor: "#E5E5E5",
              borderRadius: "8px 8px 0 0",
              fontWeight: 600,
            }}
          >
            {props.isEditable
              ? uiLang.tr(
                  "formComponentCreateAnswerNoAccessRequiresPermission",
                  getComponentPermissionDisplayName(
                    props.component.permission,
                    tr
                  )
                )
              : uiLang.tr(
                  "formComponentNoAccessRequiresPermission",
                  getComponentPermissionDisplayName(
                    props.component.permission,
                    tr
                  )
                )}
          </div>
        )}
        <div className="p-4">
          <React.Fragment>
            <div className="d-flex flex-column flex-md-row flex-wrap">
              {props.component.sensitive && (
                <div className="mr-auto order-2 order-md-1 pb-3">
                  <i>{tr("formComponentSensitiveInformationTitle")}</i>

                  <Checkbox
                    id={sensitiveConsentId}
                    label={tr("formComponentSensitiveInformationAccept")}
                    onChange={handleChangeSensitiveConsentAnswer}
                    checked={
                      props.answer
                        ? withDefault(
                            props.answer.sensitiveInformationConsent,
                            false
                          )
                        : false
                    }
                  />
                </div>
              )}
              {(props.actionBlock || overrideLanguage) && (
                <div className="pb-content pb-md-0 order-1 order-md-2">
                  {overrideLanguage && (
                    <span className="text-info">
                      {uiLang.tr(
                        "formComponentNotTranslatedToSelectedLanguage"
                      )}
                    </span>
                  )}
                  {props.actionBlock}
                </div>
              )}
              <div
                className={`header order-3 order-md-${
                  sensitiveAndOther ? "3" : "1"
                } mr-auto ${props.component.sensitive ? "w-100" : ""}`}
              >
                <h3>{displayValues.title || newComponentPlaceholder}</h3>
              </div>
            </div>
            <RTViewer value={displayValues.description} />
          </React.Fragment>
          {component.questions.map((q, i) => {
            const translatedQuestion =
              translation && translation.questions.find((tq) => tq.id === q.id);
            return (
              <React.Fragment key={q.id}>
                {(i !== 0 || richTextIsValid(displayValues.description)) && (
                  <hr />
                )}
                <FormComponentQuestionView
                  response={props.response}
                  tr={tr}
                  uiLanguage={language}
                  validateAnswers={props.validateAnswers}
                  getPredicateComponent={props.getPredicateComponent}
                  getPredicateComponentTranslation={
                    props.getPredicateComponentTranslation
                  }
                  answer={answerForQuestion(q, props.answer)}
                  disableAnswer={
                    props.isEditable === true
                      ? false
                      : props.isEditable === undefined
                      ? false
                      : true
                  }
                  updateAnswer={updateAnswer}
                  updateComponentAnswer={props.updateComponentAnswer}
                  data={q}
                  key={q.id}
                  translationLanguage={props.translationLanguage}
                  translation={translatedQuestion}
                  translating={translating}
                  readOnly={props.readOnly}
                />
              </React.Fragment>
            );
          })}
          {displayValues.attachments && displayValues.attachments.length ? (
            <div>
              <h3>{tr("formAttachedFiles")}</h3>
              <ul>
                {displayValues.attachments.map((attachment) => (
                  <li key={attachment.key}>
                    <a href={attachment.url} target="_blank" rel="noreferrer">
                      {attachment.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
