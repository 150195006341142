import _ from "lodash";
import * as React from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from "../../App/reducer";
import { Button } from "../../Common";
import { Time } from "../../Common/components/Time";
import {
  CREATE_RESPONSE_MUTATION,
  CREATE_RESPONSE_MUTATION_TYPES,
} from "../../Common/mutations";
import { ComplexSet, getCombinedIdCustomerId } from "../../Common/utils";
import { definedNotNull } from "../../Utils/functional";
import * as gqltypes from "../../gqltypes";

type Response = gqltypes.ChildItemPublicationRecipientResponse;
const getResponsePath = (recipientId: string, response: Response) => {
  return `/form/${recipientId}/${response.id}`;
};

const ResponseStatus = gqltypes.PublicationResponseStatus;

interface Props {
  currentUserId: string | null | undefined;
  child: gqltypes.ChildItem;
  recipient: gqltypes.ChildItemRecipient;
  myEducloudUserIds: { id: string; customerId: string }[] | null;
}

export const FormCard = (props: Props) => {
  const { recipient } = props;
  const { tr } = useTranslation();
  const navigate = useNavigate();
  const [loadingResponse, setLoadingResponse] = React.useState(false);

  const [createResponse] = useMutation<
    CREATE_RESPONSE_MUTATION_TYPES["data"],
    CREATE_RESPONSE_MUTATION_TYPES["variables"]
  >(CREATE_RESPONSE_MUTATION);

  const recipientId = recipient.id;
  const responses = recipient.responses;

  const completeAndSigned = responses.find(
    (r) => r.status === ResponseStatus.valid
  );
  const draftResponse = responses.find(
    (r) => r.status !== ResponseStatus.valid
  );
  const partiallySignedResponse = responses.find(
    (r) => r.status === ResponseStatus.partially_signed
  );

  const statusText: React.ReactNode[] = [];

  const signers = new ComplexSet(
    getCombinedIdCustomerId,
    partiallySignedResponse?.signatures
      ? partiallySignedResponse.signatures.map((s) => ({
          id: s.educloudUserId,
          customerId: s.customerId,
        }))
      : []
  );

  const youApprovedResponse =
    partiallySignedResponse &&
    props.myEducloudUserIds &&
    definedNotNull(partiallySignedResponse.signatures)
      ? props.myEducloudUserIds.some((eid) => signers.has(eid))
      : false;

  if (completeAndSigned) {
    statusText.push(
      <React.Fragment>
        {tr("formCardAccepted")}{" "}
        <Time date={completeAndSigned.created} type="relative" />
      </React.Fragment>
    );
  }

  if (partiallySignedResponse) {
    const signed =
      draftResponse && draftResponse.signatures
        ? draftResponse.signatures
            .map((sig) =>
              sig.educloudUser ? sig.educloudUser.name : "<Okänd>"
            )
            .join(", ")
        : "Någon";
    const response = youApprovedResponse
      ? tr("formCardNeedAnotherGuardianAccept")
      : tr("formCardNeedGuardianAccept", signed);

    statusText.push(
      <div
        className={`font-weight-bold p-2 my-2 px-content alert-${
          youApprovedResponse ? "info" : "warning"
        }`}
        style={{ marginLeft: "-1.25rem", marginRight: "-1.25rem" }}
      >
        {response +
          (completeAndSigned ? tr("formCardPrevAnswerStillValid") : "")}
      </div>
    );
  }

  if (!statusText.length) {
    statusText.push(tr("formCardNotAnswered"));
  }

  const respondText = partiallySignedResponse
    ? tr("formCardShowDraft")
    : completeAndSigned
    ? tr("editDraft")
    : tr("formCardEditDraft");

  const displayButtonSpacer =
    completeAndSigned || (!completeAndSigned && !draftResponse);

  return (
    <div className="col-12 col-md-6 col-xl-4 p-md-3 pb-3">
      <div className={"card " + (completeAndSigned ? "done" : "")}>
        <div className="card-header">
          <span>{props.child.name}</span>
          {completeAndSigned && (
            <span>
              &nbsp;
              <i className="fa fa-check" />
            </span>
          )}
        </div>
        <div className="card-body text-primary">
          <h5 className="card-title">{props.recipient.form.name}</h5>
          <div className="card-text pb-2">
            <small className="text-muted">
              {tr("formCardSent")}{" "}
              <Time date={props.recipient.created} type="relative" />
              <span>
                <br />
                {statusText.map((e, i) => (
                  <React.Fragment key={i}>{e}</React.Fragment>
                ))}
              </span>
            </small>
          </div>

          <p className="card-text float-right">
            {draftResponse && (
              <Link
                to={getResponsePath(recipientId, draftResponse)}
                className={`btn btn-${
                  completeAndSigned ? "secondary" : "primary"
                }`}
              >
                {respondText}
                <span className="sr-only">
                  {respondText} {tr("formCardAnsweredFor")}{" "}
                  {props.recipient.form.name}
                </span>
              </Link>
            )}

            {displayButtonSpacer && <React.Fragment>&nbsp;</React.Fragment>}

            {completeAndSigned && (
              <Link
                to={getResponsePath(recipientId, completeAndSigned)}
                className="btn btn-primary"
              >
                {completeAndSigned
                  ? tr("formCardViewAnswer")
                  : tr("formCardAnswer")}
                <span className="sr-only">
                  {tr(
                    "formCardAnswerFor",
                    props.recipient.form.name,
                    props.child.name
                  )}
                </span>
              </Link>
            )}

            {!completeAndSigned && !draftResponse && (
              <Button
                label={
                  loadingResponse ? tr("formCardOpening") : tr("formCardAnswer")
                }
                icon={
                  loadingResponse ? (
                    <i className="fa fa-spinner fa-spin mr-2" />
                  ) : undefined
                }
                onClick={async () => {
                  setLoadingResponse(true);
                  try {
                    const result = await createResponse({
                      variables: {
                        recipientId,
                        language: props.recipient.form.language,
                        response: { components: [] },
                      },
                    });
                    if (!result || !result.data) {
                      throw new Error("Result missing");
                    }
                    navigate(
                      `/form/${recipientId}/${result.data.createPublicationResponse.id}`
                    );
                  } catch (e) {
                    console.error(
                      "Error encountered when attempting to create empty response: ",
                      e
                    );
                    setLoadingResponse(false);
                  }
                }}
              />
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export const FormCardContainer = FormCard;
