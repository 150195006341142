import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";
import { AdminContext } from "../App/reducer";
import * as gqltypes from "../gqltypes";
import { Optionalize } from "../types";
import { fullHistoryRecordFragment, fullResponseFragment } from "./fragments";

export interface DataAndVariables<D, V> {
  data: D;
  variables: V;
}

export const withRecordFormHistory = graphql<
  { input: gqltypes.FormHistoryRecordInput } & AdminContext,
  gqltypes.recordFormHistory,
  gqltypes.recordFormHistoryVariables
>(
  gql`
    mutation recordFormHistory(
      $input: FormHistoryRecordInput!
      $context: Context!
    ) {
      recordFormHistory(input: $input, context: $context) {
        ...FullHistoryRecord
      }
    }
    ${fullHistoryRecordFragment}
  `,
  {
    name: "recordFormHistory",
    options: (props) => ({
      variables: {
        context: props.adminContext,
        input: props.input,
      },
    }),
  }
);

export const withRecordClassResponsesHistory = graphql<
  { input: gqltypes.ClassResponsesHistoryRecordInput } & AdminContext,
  gqltypes.recordClassResponsesHistory,
  gqltypes.recordClassResponsesHistoryVariables
>(
  gql`
    mutation recordClassResponsesHistory(
      $input: ClassResponsesHistoryRecordInput!
      $context: Context!
    ) {
      recordClassResponsesHistory(input: $input, context: $context) {
        ...FullHistoryRecord
      }
    }
    ${fullHistoryRecordFragment}
  `,
  {
    name: "recordClassResponsesHistory",
    options: (props) => ({
      variables: {
        context: props.adminContext,
        input: props.input,
      },
    }),
  }
);

export type DELETE_FAVORITE_MUTATION_TYPES = DataAndVariables<
  gqltypes.deleteFavorite,
  gqltypes.deleteFavoriteVariables
>;
export const DELETE_FAVORITE_MUTATION = gql`
  mutation deleteFavorite($id: ID!, $context: Context) {
    deleteFavorite(id: $id, context: $context) {
      id
    }
  }
`;
export const withUnmarkFavorite = graphql<
  { id: string } & Optionalize<AdminContext>,
  gqltypes.deleteFavorite,
  gqltypes.deleteFavoriteVariables
>(DELETE_FAVORITE_MUTATION, {
  name: "deleteFavorite",
  options: (props) => ({
    id: props.id,
    context: props.adminContext,
  }),
});

export type REMOVE_DRAFT_MUTATION_TYPES = DataAndVariables<
  gqltypes.RemoveResponseDraft,
  gqltypes.RemoveResponseDraftVariables
>;
export const REMOVE_DRAFT_MUTATION = gql`
  mutation RemoveResponseDraft($responseId: ID!, $context: Context) {
    removeResponseDraft(responseId: $responseId, context: $context) {
      id
    }
  }
`;

export type REMOVE_SIGNATURES_FROM_DRAFT_MUTATION_TYPES = DataAndVariables<
  gqltypes.RemovePublicationResponseSignatures,
  gqltypes.RemovePublicationResponseSignaturesVariables
>;
export const REMOVE_SIGNATURES_FROM_DRAFT_MUTATION = gql`
  mutation RemovePublicationResponseSignatures($id: ID!, $context: Context) {
    removePublicationResponseSignatures(id: $id, context: $context) {
      ...FullResponse
    }
  }
  ${fullResponseFragment}
`;

export type CREATE_RESPONSE_MUTATION_TYPES = DataAndVariables<
  gqltypes.CreateResponse,
  gqltypes.CreateResponseVariables
>;
export const CREATE_RESPONSE_MUTATION = gql`
  mutation CreateResponse(
    $recipientId: ID!
    $response: FormAnswerInput!
    $language: ISO6391Language
    $context: Context
  ) {
    createPublicationResponse(
      recipientId: $recipientId
      response: $response
      language: $language
      context: $context
    ) {
      ...FullResponse
    }
  }
  ${fullResponseFragment}
`;

export const withCreateResponse = graphql<
  {},
  gqltypes.CreateResponse,
  gqltypes.CreateResponseVariables
>(CREATE_RESPONSE_MUTATION, {
  name: "createResponse",
});

export type SAVE_RESPONSE_MUTATION_TYPES = DataAndVariables<
  gqltypes.SaveResponse,
  gqltypes.SaveResponseVariables
>;
export const SAVE_RESPONSE_MUTATION = gql`
  mutation SaveResponse(
    $responseId: ID!
    $response: FormAnswerInput!
    $language: ISO6391Language!
    $context: Context
  ) {
    savePublicationResponse(
      responseId: $responseId
      response: $response
      language: $language
      context: $context
    ) {
      ...FullResponse
    }
  }
  ${fullResponseFragment}
`;

export type SIGN_PUBLICATION_RESPONSE_MUTATION_TYPES = DataAndVariables<
  gqltypes.SignPublicationResponse,
  gqltypes.SignPublicationResponseVariables
>;
export const SIGN_PUBLICATION_RESPONSE_MUTATION = gql`
  mutation SignPublicationResponse(
    $id: ID!
    $response: String!
    $context: Context
  ) {
    signPublicationResponse(id: $id, response: $response, context: $context) {
      ...FullResponse
    }
  }
  ${fullResponseFragment}
`;

export type TRY_TAKE_PUBLICATION_RESPONSE_LOCK_MUTATION_TYPES =
  DataAndVariables<
    gqltypes.TryTakePublicationResponseLock,
    gqltypes.TryTakePublicationResponseLockVariables
  >;
export const TRY_TAKE_PUBLICATION_RESPONSE_LOCK_MUTATION = gql`
  mutation TryTakePublicationResponseLock($id: ID!, $context: Context) {
    tryTakePublicationResponseLock(responseId: $id, context: $context) {
      ...FullResponse
    }
  }
  ${fullResponseFragment}
`;

export type RELEASE_PUBLICATION_RESPONSE_LOCK_MUTATION_TYPES = DataAndVariables<
  gqltypes.ReleasePublicationResponseLock,
  gqltypes.ReleasePublicationResponseLockVariables
>;
export const RELEASE_PUBLICATION_RESPONSE_LOCK_MUTATION = gql`
  mutation ReleasePublicationResponseLock($id: ID!, $context: Context) {
    releasePublicationResponseLock(responseId: $id, context: $context) {
      ...FullResponse
    }
  }
  ${fullResponseFragment}
`;
