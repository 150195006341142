import * as React from "react";
import * as ReactRedux from "react-redux";
import * as ReactRouter from "react-router-dom";
import { Dispatch } from "redux";
import { StoreState } from "../../store";
import { userManager } from "../userManager";

interface StateProps {}

interface OutProps {}

interface Props extends StateProps, OutProps {
  dispatch: Dispatch<any>;
}

interface State {
  redirectUrl?: string | null;
  error: boolean;
}

export class SignOutCallbackView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { redirectUrl: undefined, error: false };
  }

  public render() {
    if (this.state.error) {
      return <ReactRouter.Navigate to={this.state.redirectUrl || "/"} />;
    }

    if (this.state.redirectUrl === undefined) {
      return null;
    }

    return <ReactRouter.Navigate to={this.state.redirectUrl || "/"} />;
  }

  public componentDidMount() {
    userManager
      .signoutRedirectCallback()
      .then((user) => {
        this.setState({
          redirectUrl: user ? (user.state ? user.state.path : null) : null,
        });
      })
      .catch((e) => {
        this.setState({ error: true });
      });
  }
}

export const SignOutCallbackViewContainer = ReactRedux.connect<
  StateProps,
  any,
  OutProps,
  StoreState
>((state) => ({}))(SignOutCallbackView);
