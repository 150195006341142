import gql from "graphql-tag";
import _ from "lodash";
import * as React from "react";
import { Query } from "@apollo/client/react/components";
import { Collapse } from "reactstrap";
import { availableTranslationLanguageEnum } from "../../Admin/form";
import { Translation, withTranslation } from "../../App/reducer";
import {
  Button,
  FormComponentEditor,
  FormComponentView,
  FormInput,
  FormSelect,
  ISTContainer,
  Loading,
} from "../../Common";
import { fullFormQuestionFragment } from "../../Common/fragments";
import {
  showAlertDialog,
  showConfirmDialog,
} from "../../Utils/dialogs/showDialog";
import { deepOmit, noop } from "../../Utils/functional";
import { client } from "../../api";
import * as gqltypes from "../../gqltypes";
import { defaultDialogCancel } from "../../translation/strings";

interface Props {
  tr: Translation["tr"];
  templates: gqltypes.superadminTemplates["templates"];
  refetch: () => Promise<void>;
}

interface State {
  open: { [id: string]: boolean };
  templateStates: {
    [id: string]: gqltypes.superadminTemplates["templates"][0];
  };
  translationState: {
    [id: string]: {
      [
        language: string
      ]: gqltypes.superadminTemplates["templates"][0]["translations"][0];
    };
  };
  activeTemplate?: string;
  language?: gqltypes.ISO6391Language;
  saving: boolean;
  dirty: boolean;
  newTemplateName: string;
}

class Templates extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      templateStates: {},
      translationState: {},
      open: {},
      saving: false,
      dirty: false,
      newTemplateName: "",
    };
  }

  public componentDidMount() {
    this.mapTemplates();
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props !== prevProps) {
      this.mapTemplates();
    }
  }

  public render() {
    return (
      <React.Fragment>
        <ISTContainer header="Mallar">
          <div className="p-content">
            <FormSelect
              label="Språk"
              value={this.state.language || ""}
              defaultOption="Standardspråk"
              options={Object.values(availableTranslationLanguageEnum).map(
                (l: string) => ({
                  value: l,
                  label: l,
                })
              )}
              onChange={(e) => {
                const language = e.currentTarget
                  .value as gqltypes.ISO6391Language;
                this.setState({ language, activeTemplate: undefined });
              }}
            />
            <div className="row">
              <div className="col-6">
                <FormInput
                  id="newTemplateFormInputName"
                  label="Namn på ny mall"
                  value={this.state.newTemplateName}
                  onChange={(e) =>
                    this.setState({ newTemplateName: e.currentTarget.value })
                  }
                />
              </div>
              <div className="col-6 d-flex">
                <div className="form-group align-self-end">
                  <Button
                    label="Skapa ny"
                    disabled={!this.state.newTemplateName}
                    onClick={() => {
                      if (!this.state.language) {
                        alert("sätt språk i valet högst upp");
                        return;
                      }
                      this.createTemplate(
                        this.state.newTemplateName,
                        "no",
                        this.state.language
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </ISTContainer>
        {Object.values(this.state.templateStates).map((template) => {
          const translations = Object.values(
            this.state.translationState[template.id] || {}
          );
          // console.log("translations: ", translations);
          const translation = translations.find(
            (t) => t.language === this.state.language
          );
          const translating = Boolean(translation);
          const languages = [template.language].concat(
            translations.map((t) => t.language)
          );

          return (
            <form key={template.id} id="form-viewer">
              <Button
                label={`${template.componentData.title} (${languages.join(
                  ", "
                )})`}
                className="mb-2"
                onClick={() =>
                  this.setState((state) => ({
                    open: {
                      ...state.open,
                      [template.id]: state.open[template.id] ? false : true,
                    },
                  }))
                }
              />
              <Collapse isOpen={this.state.open[template.id]}>
                {this.state.activeTemplate === template.id ? (
                  <FormComponentEditor
                    component={template.componentData}
                    disabled={
                      translating
                        ? translation
                          ? translation.published
                          : false
                        : template.published
                    }
                    formLanguage={template.language}
                    formType={gqltypes.FormType.publication}
                    updateComponent={
                      template.published ? noop : this.updateTemplate
                    }
                    updateTranslation={
                      translation && translation.published
                        ? noop
                        : this.updateTranslation
                    }
                    validateComponent
                    actionBlock={
                      <React.Fragment>
                        <Button
                          label="print"
                          onClick={() => this.printState(template.id)}
                        />
                        <Button
                          label="spara"
                          disabled={
                            this.state.saving ||
                            !this.state.dirty ||
                            (translating
                              ? translation
                                ? translation.published
                                : true
                              : template.published)
                          }
                          onClick={() => {
                            if (translating) {
                              this.saveTemplateTranslation(
                                template.id,
                                this.state.language!
                              );
                              return;
                            }
                            this.saveTemplate(template.id);
                          }}
                        />
                        <Button
                          label="Stäng"
                          onClick={() => this.setActive(undefined)}
                        />
                      </React.Fragment>
                    }
                    translating={translating}
                    translation={
                      translation && (translation.componentData as any)
                    }
                    translationLanguage={
                      translating ? translation!.language : undefined
                    }
                    advancedMode
                  />
                ) : (
                  <React.Fragment>
                    <div>id: {template.id}</div>
                    {this.state.language &&
                      template.language !== this.state.language &&
                      !translation && (
                        <Button
                          label={`Skapa översättning till ${this.state.language}`}
                          onClick={() => {
                            this.createTemplateTranslation(
                              template.id,
                              this.state.language!
                            );
                          }}
                        />
                      )}
                    {this.state.language &&
                      (translating
                        ? translation
                          ? !translation.published && template.published
                          : false
                        : !template.published) && (
                        <Button
                          label={`Publicera (${this.state.language})`}
                          onClick={() => {
                            if (translating) {
                              this.publishTemplateTranslation(
                                template.id,
                                this.state.language!
                              );
                              return;
                            }
                            this.publishTemplate(template.id);
                          }}
                        />
                      )}
                    {translating
                      ? translation!.published
                      : template.published && (
                          <div>
                            Går ej att ändra då den är låst på valt språk
                          </div>
                        )}
                    <FormComponentView
                      getPredicateComponent={() => {
                        throw Error("This shouldn't be called.");
                      }}
                      translating={translating}
                      translation={
                        translation && (translation.componentData as any)
                      }
                      translationLanguage={
                        translating ? (translation!.language as any) : undefined
                      }
                      component={template.componentData}
                      formLanguage={
                        translating ? translation!.language : template.language
                      }
                      validateComponent
                      actionBlock={
                        <Button
                          label="Editera"
                          // disabled={
                          //   translating
                          //     ? translation
                          //       ? translation.published
                          //       : false
                          //     : template.published
                          // }
                          onClick={() => this.setActive(template.id)}
                        />
                      }
                    />
                  </React.Fragment>
                )}
              </Collapse>
              <hr />
            </form>
          );
        })}
      </React.Fragment>
    );
  }

  private printState = (id: string) => {
    const translation =
      this.state.translationState[id] &&
      this.state.translationState[id][this.state.language as string];
    const base = this.state.templateStates[id];

    const data = translation ? translation.componentData : base.componentData;

    const componentData = deepOmit(data, (key: string) => key === "__typename");

    showAlertDialog({
      title: base.language,
      content: <p>{JSON.stringify(componentData, null, 2)}</p>,
      proceedText: "OK",
      cancelText: "Avbryt",
    });
  };

  private updateTemplate = (componentData: gqltypes.FormComponent) => {
    const id = componentData.id;

    this.setState(
      (state) =>
        ({
          templateStates: {
            ...state.templateStates,
            [id]: { ...state.templateStates[id], componentData },
          },
          dirty: true,
        } as any)
    );
  };

  private updateTranslation = (
    componentData: gqltypes.TranslationComponentTemplate
  ) => {
    // console.log("translationUpdate", componentData);
    const id = componentData.id;
    const language = this.state.language!;

    this.setState((state) => {
      const translationState = {
        ...state.translationState,
        [id]: {
          ...state.translationState[id],
          [language]: {
            ...state.translationState[id][language],
            componentData: {
              ...(componentData as any),
            },
          },
        },
      };
      return {
        translationState,
        dirty: true,
      };
    });
  };

  private createTemplate = async (
    title: string,
    category: string,
    language: gqltypes.ISO6391Language
  ) => {
    await client.mutate<
      gqltypes.AdminCreateTemplate,
      gqltypes.AdminCreateTemplateVariables
    >({
      mutation: gql`
        mutation AdminCreateTemplate(
          $title: String!
          $category: String!
          $language: ISO6391Language!
        ) {
          adminCreateTemplate(
            input: { title: $title, category: $category, language: $language }
          ) {
            id
          }
        }
      `,
      variables: {
        title,
        category,
        language,
      },
    });
    this.setState({ saving: false, dirty: false });
    this.props.refetch();
  };

  private saveTemplate = async (id: string) => {
    const componentData = this.state.templateStates[id].componentData;
    if (!componentData) {
      throw new Error("no component data");
    }
    this.setState({ saving: true });
    const res = await client.mutate<
      gqltypes.AdminUpdateTemplate,
      gqltypes.AdminUpdateTemplateVariables
    >({
      mutation: gql`
        mutation AdminUpdateTemplate(
          $id: ID!
          $componentData: ComponentTemplateInput!
        ) {
          adminUpdateTemplate(
            input: { id: $id, componentData: $componentData }
          ) {
            id
            componentData {
              id
              title
              description
              sensitive
              permission
              questions {
                ...FullComponentQuestion
              }
              attachments {
                name
                key
                url
              }
            }
          }
        }
        ${fullFormQuestionFragment}
      `,
      variables: {
        id,
        componentData,
      },
    });
    this.setState({ saving: false, dirty: false });
  };

  private publishTemplate = async (id: string) => {
    const confirmed = await showConfirmDialog({
      title: this.props.tr("superadminTemplatePublishTemplateTitle"),
      content: (
        <p>{this.props.tr("superadminTemplatePublishTemplateMessage")}</p>
      ),
      proceedText: this.props.tr("superadminTemplatePublishTemplateConfirm"),
      cancelText: defaultDialogCancel(this.props.tr),
    });
    if (!confirmed) {
      return;
    }

    const res = await client.mutate<
      gqltypes.AdminPublishTemplate,
      gqltypes.AdminPublishTemplateVariables
    >({
      mutation: gql`
        mutation AdminPublishTemplate($id: ID!) {
          adminPublishTemplate(id: $id) {
            id
          }
        }
        ${fullFormQuestionFragment}
      `,
      variables: {
        id,
      },
    });
    this.setState({ saving: false, dirty: false });
    this.props.refetch();
  };

  private createTemplateTranslation = async (
    id: string,
    language: gqltypes.ISO6391Language
  ) => {
    const res = await client.mutate<
      gqltypes.AdminCreateTemplateTranslation,
      gqltypes.AdminCreateTemplateTranslationVariables
    >({
      mutation: gql`
        mutation AdminCreateTemplateTranslation(
          $id: ID!
          $language: ISO6391Language!
        ) {
          adminCreateTemplateTranslation(
            input: { id: $id, language: $language }
          ) {
            id
          }
        }
      `,
      variables: {
        id,
        language,
      },
    });
    this.setState({ saving: false, dirty: false });
    this.props.refetch();
  };

  private saveTemplateTranslation = async (
    id: string,
    language: gqltypes.ISO6391Language
  ) => {
    const componentData =
      this.state.translationState[id][language].componentData;
    if (!componentData) {
      throw new Error("no component data");
    }
    this.setState({ saving: true });
    const res = await client.mutate<
      gqltypes.AdminUpdateTemplateTranslation,
      gqltypes.AdminUpdateTemplateTranslationVariables
    >({
      mutation: gql`
        mutation AdminUpdateTemplateTranslation(
          $id: ID!
          $language: ISO6391Language!
          $componentData: ComponentTemplateTranslationInput!
        ) {
          adminUpdateTemplateTranslation(
            input: {
              id: $id
              language: $language
              componentData: $componentData
            }
          ) {
            id
            language
            componentData {
              id
              title
              description
              questions {
                id
                question
                shortName
                options {
                  id
                  label
                }
              }
              attachments {
                name
                key
                url
              }
            }
          }
        }
        ${fullFormQuestionFragment}
      `,
      variables: {
        id,
        language,
        componentData,
      },
    });
    this.setState({ saving: false, dirty: false });
  };

  private publishTemplateTranslation = async (
    id: string,
    language: gqltypes.ISO6391Language
  ) => {
    const confirmed = await showConfirmDialog({
      title: this.props.tr("superadminTemplatePublishTemplateTitle"),
      content: (
        <p>
          {this.props.tr("superadminTemplatePublishTemplateTranslationMessage")}
        </p>
      ),
      proceedText: this.props.tr("superadminTemplatePublishTemplateConfirm"),
      cancelText: defaultDialogCancel(this.props.tr),
    });
    if (!confirmed) {
      return;
    }

    const res = await client.mutate<
      gqltypes.AdminPublishTemplateTranslation,
      gqltypes.AdminPublishTemplateTranslationVariables
    >({
      mutation: gql`
        mutation AdminPublishTemplateTranslation(
          $id: ID!
          $language: ISO6391Language!
        ) {
          adminPublishTemplateTranslation(id: $id, language: $language) {
            id
          }
        }
      `,
      variables: {
        id,
        language,
      },
    });
    this.setState({ saving: false, dirty: false });
    this.props.refetch();
  };

  private mapTemplates = () => {
    const templateMap = this.props.templates.reduce((out, template) => {
      // console.log("template", template);
      out[template.id] = template;
      return out;
    }, {} as State["templateStates"]);
    const translationMap = this.props.templates.reduce((out, template) => {
      template.translations.forEach((translation) => {
        if (!out[template.id]) {
          out[template.id] = {};
        }
        out[template.id][translation.language] = {
          ...translation,
        };
      });
      return out;
    }, {} as State["translationState"]);
    this.setState({
      templateStates: templateMap,
      translationState: deepOmit(
        translationMap,
        (key: string) => key === "__typename"
      ),
    });
  };

  private setActive = (id: string | undefined) => {
    this.setState({ activeTemplate: id });
  };
}

export const TemplatesContainer = withTranslation((props: Translation) => {
  return (
    <Query<gqltypes.superadminTemplates>
      query={gql`
        query superadminTemplates {
          templates(filter: all) {
            id
            language
            published
            componentData {
              id
              title
              description
              sensitive
              permission
              questions {
                ...FullComponentQuestion
              }
              attachments {
                name
                key
                url
              }
            }
            translations(filter: all) {
              id
              published
              language
              componentData {
                id
                title
                description
                questions {
                  id
                  question
                  shortName
                  options {
                    id
                    label
                  }
                }
                attachments {
                  name
                  key
                  url
                }
              }
            }
          }
        }
        ${fullFormQuestionFragment}
      `}
      fetchPolicy="cache-first"
    >
      {({ loading, error, data, refetch }) => {
        if (loading) {
          return <Loading />;
        }
        if (!data || !data.templates) {
          return null;
        }
        return (
          <Templates
            templates={data.templates}
            refetch={refetch as any}
            tr={props.tr}
          />
        );
      }}
    </Query>
  );
});
