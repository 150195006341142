import * as React from "react";
import { Button } from "./Button";

interface Props {
  id?: string;
  className?: string;
  iconClass: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  title?: string;
  "aria-label": string | null;
}

export function IconButton(props: Props) {
  const { id, iconClass, onClick, disabled, autoFocus } = props;
  const className = [
    props.className,
    "icon-button p-1 flat",
    disabled ? "disabled" : "",
  ].join(" ");

  return (
    <button
      id={id}
      ref={(r) => autoFocus && r && r!.focus()}
      className={className}
      onClick={!disabled ? onClick : undefined}
      type="button"
      disabled={disabled}
      autoFocus={autoFocus}
    >
      <i
        title={props.title}
        className={iconClass}
        aria-label={props["aria-label"] || ""}
      />
    </button>
  );
}
