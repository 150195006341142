import * as React from "react";
import * as ReactRedux from "react-redux";
import * as ReactRouter from "react-router-dom";
import { Dispatch } from "redux";
import * as sentry from "../../sentry";
import { StoreState } from "../../store";
import * as appActions from "../actions";
import { userManager } from "../userManager";

interface StateProps {
  hasAuthenticated: boolean;
}

interface OutProps {}

interface State {
  error: boolean;
  redirectUrl: string | null | undefined;
}

interface Props extends StateProps, OutProps {
  dispatch: Dispatch<any>;
}

export class SignInCallbackView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { redirectUrl: undefined, error: false };
  }

  public render() {
    if (this.state.error) {
      return <ReactRouter.Navigate to={this.state.redirectUrl || "/"} />;
    }

    if (this.state.redirectUrl === undefined) {
      return null;
    }

    if (this.props.hasAuthenticated) {
      return <ReactRouter.Navigate to={this.state.redirectUrl || "/"} />;
    }

    return null;
  }

  public componentDidMount() {
    userManager
      .signinRedirectCallback()
      .then((user) => {
        this.setState(
          {
            redirectUrl: user ? (user.state ? user.state.path : null) : null,
          },
          () => {
            this.props.dispatch(appActions.userChanged(user));
          }
        );
      })
      .catch((e) => {
        if (
          e.message.startsWith("exp is in the past") ||
          e.message.startsWith("iat is in the future")
        ) {
          this.props.dispatch(appActions.flagTimeSkewError());
        } else {
          sentry.captureException(e);
        }
        this.setState({ error: true });
      });
  }
}

export const SignInCallbackViewContainer = ReactRedux.connect<
  StateProps,
  any,
  OutProps,
  StoreState
>((state) => ({
  hasAuthenticated: !!state.app.user && state.app.isInitialized,
}))(SignInCallbackView);
