import gql from "graphql-tag";
import * as _ from "lodash";
import React, { useState } from "react";
import { QueryResult } from "@apollo/client";
import { graphql, DataValue } from "@apollo/client/react/hoc";
import { RouterProps, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {
  AdminContext,
  Translation,
  withAdminContext,
  withTranslation,
} from "../../App/reducer";
import { Button, FormInput, ISTContainer, Loading } from "../../Common";
import { showConfirmDialog } from "../../Utils/dialogs/showDialog";
import { client, getAdminContext } from "../../api";
import * as gqltypes from "../../gqltypes";
import { defaultDialogCancel } from "../../translation/strings";
import { links } from "../links";
import { ApplicationTable } from "./ApplicationTable";
import { PublicationApplicationMetaInfo } from "./PublicationMetaInfo";

interface Response {
  orgData: DataValue<gqltypes.viewApplicationsData> & QueryResult;
}

interface Props extends RouterProps, AdminContext, Response, Translation {}

const ViewApplications = (props: Props) => {
  const [selectedApplicationId, setSelectedApplicationId] = useState<string>();

  const { tr } = props;

  const applications = props.orgData.organisation
    ? props.orgData.organisation.applications
    : null;

  const selectedApplication =
    applications && selectedApplicationId
      ? applications.find((p) => p.id === selectedApplicationId)
      : undefined;

  const selectedAppId = selectedApplication
    ? selectedApplication.id
    : undefined;

  return (
    <React.Fragment>
      <h1>{tr("viewApplicationTitle")}</h1>
      <p className="col-12 col-md-9 p-0 pb-3">
        {tr("viewApplicationDescription")}
      </p>
      <ISTContainer header={tr("viewApplicationHeader")}>
        {props.orgData.loading && !applications ? (
          <Loading />
        ) : !applications || applications.length === 0 ? (
          <div className="alert alert-info m-content">
            {tr("viewApplicationNoPublicationsAvailable")}
          </div>
        ) : (
          <ApplicationTable
            tr={tr}
            applications={applications}
            selectedAppId={selectedAppId}
            onSelectApp={setSelectedApplicationId}
            scrollToElementId="application-view"
          />
        )}
      </ISTContainer>

      {selectedApplication !== undefined ? (
        <ViewApplicationDetails tr={tr} application={selectedApplication} />
      ) : null}
    </React.Fragment>
  );
};

interface DetailProps {
  tr: Translation["tr"];
  application: gqltypes.viewApplicationsData["organisation"]["applications"][0];
}

const ViewApplicationDetails = (props: DetailProps) => {
  const { tr } = props;
  const applicationId = props.application.id;
  const navigate = useNavigate();
  const fillUrl = `${window.location.origin}/application/${applicationId}`;

  return (
    <ISTContainer header={props.application.name} id="application-view">
      <section>
        <PublicationApplicationMetaInfo
          publication={props.application}
          fields={[
            "created",
            "modified",
            "creator",
            "sendDate",
            "validFrom",
            "validTo",
          ]}
        />
      </section>
      <section className="p-content">
        <div>
          <Button
            label={tr("viewApplicationEditApplication")}
            onClick={() => {
              navigate(links.admin.application.edit(applicationId));
            }}
          />
        </div>
      </section>
      <section className="p-content">
        <hr />
        <h3 className="pt-content">
          {tr("viewApplicationApplicationFillLink")}
        </h3>
        <FormInput id="application-url" value={fillUrl} disabled />
        <Link to={`/application/${applicationId}`} target="_blank">
          {tr("viewApplicationApplicationFillLink")}
        </Link>
      </section>
    </ISTContainer>
  );
};

const handleDeleteApplication = async (
  tr: Translation["tr"],
  id: string,
  onDone: () => void
) => {
  await showConfirmDialog({
    title: tr("viewApplicationRemoveConfirmTitle"),
    content: tr("viewApplicationRemoveConfirmMessage"),
    proceedText: tr("delete"),
    cancelText: defaultDialogCancel(tr),
    proceedFunc: async (hostingComponent) => {
      const context = getAdminContext();

      const res = await client.mutate<
        gqltypes.DeleteApplication,
        gqltypes.DeleteApplicationVariables
      >({
        mutation: gql`
          mutation DeleteApplication($id: ID!, $context: Context!) {
            deleteApplication(id: $id, context: $context) {
              id
            }
          }
        `,
        variables: { id, context },
      });

      onDone();
    },
    proceedFuncFooter: (
      <span>
        <i className="fa fa-spinner fa-spin mr-2" />
        {tr("viewApplicationDeletingApplication")}
      </span>
    ),
  });
};

const orgDataQuery = gql`
  query viewApplicationsData($context: Context!) {
    organisation(context: $context) {
      id
      applications {
        id
        name
        form {
          id
          name
          subType
        }
        creator {
          id
          name
        }
        created
        modified
        sendDate
        singleGuardianSend
        singleGuardianAcceptOffer
        closed
        sent
        stats {
          answered
          open
          closed
        }
      }
    }
  }
`;

const withApplications = graphql<
  Props,
  gqltypes.viewApplicationsData,
  gqltypes.viewApplicationsDataVariables,
  any
>(orgDataQuery, {
  name: "orgData",
  options: (props) => ({
    variables: {
      context: props.adminContext,
    },
  }),
});

export const ViewApplicationsContainer = _.flowRight(
  withTranslation,
  withAdminContext,
  withApplications
)(ViewApplications);
