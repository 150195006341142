import React from "react";
import { Translation } from "../../App/reducer";
import * as gqltypes from "../../gqltypes";
import { getApplicationStatusLabel } from "../utils";
import FormSearchSelect from "./FormSearchSelect";

interface Props {
  tr: Translation["tr"];
  value: { value: gqltypes.ApplicationResponseStatus; label: string }[];
  onChange: (selected: any) => void;
}

export const ApplicationStatusFilter = (props: Props) => {
  const { tr, value } = props;

  const options = React.useMemo(
    () =>
      Object.keys(gqltypes.ApplicationResponseStatus).map((s) => ({
        value: s,
        label: getApplicationStatusLabel(
          tr,
          s as gqltypes.ApplicationResponseStatus
        ),
      })),
    [tr]
  );

  return (
    <FormSearchSelect
      aria-label={tr("viewApplicationAnswersFilterPlaceholder")}
      menuShouldScrollIntoView
      placeholder={tr("viewApplicationAnswersFilterPlaceholder")}
      noOptionsMessage={() => tr("viewApplicationAnswersAllOptionsSelected")}
      maxMenuHeight={320}
      isMulti
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      options={options}
      value={value}
      onChange={props.onChange}
    />
  );
};
