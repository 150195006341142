import gql from "graphql-tag";
import _ from "lodash";
import * as React from "react";
import { useQuery } from "@apollo/client";
import { definedNotNull } from "../../Utils/functional";
import * as gqltypes from "../../gqltypes";

type Maybe<T> = T | null | undefined;
const replaceAll = (
  input: string,
  ...replacements: Maybe<{ [key: string]: Maybe<string> }>[]
) => {
  return input.replace(/%([^%\s<>]+)%/g, (match, g1: string) => {
    const replacement = replacements.find((r) => definedNotNull(r?.[g1]))?.[g1];
    if (!definedNotNull(replacement)) {
      return match;
    }

    const value = _.escape(replacement).replace(/\n/g, "<br />");
    return value;
  });
};

const fallbackStrings = {
  name: "Johan Exempelsson",
  child: "Barn Exempelsson",
  guardianName: "Åsa",
  sender: "Norreka kommun",
  formName: "Medgivande Borsskola 2018 Allmänt",
  org: "norreka-kommun-com.ist.home.consent-api-5RyHP2mEA",
  actionUrl: "http://svara.ist.se",
  serviceUrl: "http://svara.ist.se",
  serviceName: "IST Medgivande",
  message: "msg",
};

const GET_EMAIL_TEMPLATE = gql`
  query EmailTemplate($language: String!, $type: EmailTemplateType!) {
    emailTemplate(language: $language, type: $type) {
      subject
      htmlContent
    }
  }
`;
const GET_EMAIL_TEMPLATE_SUBSTITUTIONS = gql`
  query EmailTemplateSubstituions(
    $org: ID!
    $role: EmailTemplateSubstitutionsRole
  ) {
    emailSubstitutions(org: $org, role: $role) {
      org
      sender
      serviceName
      serviceUrl
      actionUrl
    }
  }
`;
interface Props {
  type: gqltypes.EmailTemplateType;
  language: string;
  substitutions?: { [key in keyof typeof fallbackStrings]?: string };
  disableCache?: boolean;
  org?: string | null;
  role?: gqltypes.EmailTemplateSubstitutionsRole;
}

export function EmailPreview(props: Props) {
  const { data, loading, error } = useQuery<
    gqltypes.EmailTemplate,
    gqltypes.EmailTemplateVariables
  >(GET_EMAIL_TEMPLATE, {
    variables: {
      type: props.type,
      language: props.language,
    },
    fetchPolicy: props.disableCache ? "cache-and-network" : "cache-first",
  });

  const {
    data: substitutions,
    loading: substitutionsLoading,
    error: substitutionsError,
  } = useQuery<
    gqltypes.EmailTemplateSubstituions,
    gqltypes.EmailTemplateSubstituionsVariables
  >(GET_EMAIL_TEMPLATE_SUBSTITUTIONS, {
    variables: {
      org: props.org!,
      role: props.role,
    },
    skip: !props.org,
  });

  if (loading || !data || substitutionsLoading) {
    return null;
  }
  const orgSubstitions = substitutions?.emailSubstitutions as Maybe<{
    [key: string]: string;
  }>;
  const subject = replaceAll(
    data.emailTemplate.subject,
    orgSubstitions,
    props.substitutions,
    fallbackStrings
  );
  const html = replaceAll(
    data.emailTemplate.htmlContent,
    orgSubstitions,
    props.substitutions,
    fallbackStrings
  );
  return (
    <React.Fragment>
      <hr />
      <h4>{subject}</h4>
      <iframe
        title={props.type}
        height="750px"
        style={{
          width: "100%",
          resize: "vertical",
        }}
        frameBorder={0}
        srcDoc={html}
      />
    </React.Fragment>
  );
}
