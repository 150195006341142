import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { Dispatch } from "redux";
import * as appActions from "../App/actions";
import { Footer } from "../App/components/Footer";
import { Header, HeaderProps } from "../App/components/Header";
import { PageNotFound } from "../Common/components/PageNotFound";
import { UserProfileContainer } from "../Common/components/Profile";
import { StartViewContainer } from "../Start/StartViewContainer";
import { OIDCUser } from "../types";
import {
  ApplicationViewContainer,
  ApplicationViewWithResponseContainer,
} from "./components/ApplicationView";
import { GuardianContainer } from "./components/Guardian";
import { PublicationResponseViewContainer } from "./components/PublicationResponseView";

interface GuardianRouterProps {}

class GuardianRouterInner extends React.PureComponent<GuardianRouterProps, {}> {
  public render() {
    return (
      <Routes>
        <Route path="/" element={<GuardianContainer />} />
        <Route
          path="/form/:recipientId/:responseId/"
          element={<PublicationResponseViewContainer />}
        />
        <Route
          path="/application/:applicationId/:responseId/"
          element={<ApplicationViewWithResponseContainer />}
        />
        <Route
          path="/application/:applicationId/"
          element={<ApplicationViewContainer />}
        />
        <Route path="/profile" element={<UserProfileContainer />} />
        <Route element={<PageNotFound startPageUrl="/" />} />
      </Routes>
    );
  }
}
const GuardianRouter = GuardianRouterInner;

interface StateProps {
  isInitialized: boolean;
  user: OIDCUser | null;
  fatalError: string | null;
}
interface OutProps {}
interface Props extends StateProps, OutProps, HeaderProps {
  dispatch: Dispatch<any>;
}
export class GuardianApp extends React.Component<Props, {}> {
  public render() {
    return (
      <div className="app">
        <Header
          {...(this.props as HeaderProps)}
          menuItems={[]}
          availableOrgs={null}
          onLogin={this.onLogin}
          onLogout={this.onLogout}
        />
        <div className="mainLayout">
          <main className="mainContentNoSideBar">
            <ProtectedPaths {...this.props}>
              <GuardianRouter />
            </ProtectedPaths>
          </main>
        </div>
        <Footer />
      </div>
    );
  }
  private onLogin = () => {
    this.props.dispatch(appActions.loginRequested());
  };

  private onLogout = () => {
    this.props.dispatch(appActions.logoutRequested());
  };
}

export const ProtectedPaths = (props: React.PropsWithChildren<Props>) => {
  // Protected paths require the application to be fully initialized (for instance the loading of an eventual user must have finished)
  if (!props.isInitialized) {
    return null;
  }

  if (!props.user) {
    return <StartViewContainer mode="user" />;
  }

  return <div>{props.children}</div>;
};
