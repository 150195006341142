import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { createPseudoID } from "../Utils/pseudoID";
import * as persistance from "../persistance";

interface MutexLockHandlerSettings {
  tryTakeLock: () => Promise<boolean>;
  releaseLock: () => Promise<any>;
  isHoldingLock: () => boolean;
  lockTimeRemaining: number;
  minCheckInterval?: number; // ms, default 5000
  onAquireLock?: () => void;
  onLockRelase?: () => void;
  onLockTimeout?: () => void;
  automaticallyTakeLock?: boolean; // default true
  holdLock?: boolean; // default true
}

const noop = () => {
  //
};

export const useMutexLockHandler = (settings: MutexLockHandlerSettings) => {
  const {
    tryTakeLock,
    releaseLock,
    isHoldingLock,
    lockTimeRemaining,
    minCheckInterval = 5000,
    onAquireLock = noop,
    onLockRelase = noop,
    onLockTimeout = noop,
    holdLock = true,
  } = settings;

  const lockExpireTimer = useRef<any>();
  const internalHoldsLock = useRef(false);
  const [holdsLock, setHoldsLock] = useState(false);
  const deleted = useRef(false);
  const startTimerRef = useRef<typeof startTimer>();
  const lockHandlerRef = useRef<typeof takeLockHandler>();
  const releaseLockHandlerRef = useRef<typeof releaseLockHandler>();

  const startTimer = () => {
    if (deleted.current) return;

    // console.log("starting timer", isHoldingLock(), holdsLock);
    const ms = isHoldingLock()
      ? lockTimeRemaining
      : Math.min(minCheckInterval, Math.max(lockTimeRemaining, 200));

    // console.log("start timer", ms);
    clearTimeout(lockExpireTimer.current);
    lockExpireTimer.current = setTimeout(() => {
      lockHandlerRef.current!(true);
    }, ms);
  };

  const takeLockHandler = async (asTimer = false) => {
    let aquiredLock = false;

    if (!internalHoldsLock.current) {
      aquiredLock = await tryTakeLock();
      if (aquiredLock) {
        internalHoldsLock.current = true;
        setHoldsLock(true);
        onAquireLock();
      }
    } else {
      // Lock was lost
      setHoldsLock(false);
      internalHoldsLock.current = false;
      onLockRelase();
      if (asTimer) onLockTimeout();
      return;
    }

    startTimerRef.current!();
  };

  const releaseLockHandler = () => {
    if (lockExpireTimer.current !== undefined)
      clearTimeout(lockExpireTimer.current);
    releaseLock();
    onLockRelase();
    internalHoldsLock.current = false;
  };

  startTimerRef.current = startTimer;
  lockHandlerRef.current = takeLockHandler;
  releaseLockHandlerRef.current = releaseLockHandler;

  const onBeforeUnload = () => {
    releaseLockHandlerRef.current!();
  };

  useEffect(() => {
    if (holdsLock) {
      startTimerRef.current!();
    }
  }, [holdsLock]);

  useEffect(() => {
    if (holdLock) {
      lockHandlerRef.current!();
    } else {
      if (holdsLock) {
        releaseLockHandlerRef.current!();
      }
    }
    // TODO: Seems to work fine but might want to fix this lint issue some day
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdLock]);

  useEffect(() => {
    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
      deleted.current = true;

      releaseLockHandlerRef.current!();
    };
  }, []);

  return {
    tryTakeLock: lockHandlerRef.current,
    release: releaseLockHandlerRef.current,
    holdsLock,
  };
};

export function usePrevious(value: any) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useDidChangeThisRender(value: any) {
  const ref = useRef();

  const changed = ref.current !== value;
  ref.current = value;

  return changed;
}

/**
 * Uses location state to save state
 * Makes navigating back with same search query and filters possible
 * @param key should be unique within page
 * @param initialValue
 */

export function useLocationState<T>(key: string, initialValue: T) {
  const navigate = useNavigate();
  const location = useLocation();

  const locState: any = location.state || {};

  const state = useRef<T>(locState[key] ?? initialValue);

  const setState = (newState: T) => {
    state.current = newState;
    navigate(location.pathname, {
      state: {
        ...((location.state as any) || {}),
        [key]: newState,
      },
      replace: true,
    });
  };

  return [state.current, setState] as [T, typeof setState];
}

/**
 * Uses local storage to persist state
 * @param key Must be globally unique
 * @param initialValue initial value to use
 */
export function usePersistedStringState(key: string, initialValue: string) {
  const persistanceKey = `state_${key}`;
  const currentPersistanceValue = persistance.get(persistanceKey);
  const [value, setValue] = useState(currentPersistanceValue ?? initialValue);

  const setState = (newState: string) => {
    setValue(newState);
    persistance.set(persistanceKey, newState);
  };

  return [value, setState] as [string, typeof setState];
}

/**
 * Uses local storage to persist state
 * @param key Must be globally unique
 * @param initialValue initial value to use
 */
export function usePersistedBooleanState(key: string, initialValue: boolean) {
  const [state, internalSetState] = usePersistedStringState(
    key,
    initialValue ? "true" : "false"
  );

  const setState = (newState: boolean) => {
    internalSetState(newState ? "true" : "false");
  };

  return [state === "true", setState] as [boolean, typeof setState];
}

export function useRandomId(prefix = "random") {
  const id = useRef(prefix + "-" + createPseudoID());

  return id.current;
}
