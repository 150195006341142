import _ from "lodash";
import * as React from "react";
import { OperationVariables, useMutation } from "@apollo/client";
import { useTranslation } from "../../App/reducer";
import * as sentry from "../../sentry";
import { Button } from "./Button";

interface Props<T, V> {
  mutation: any;
  variables?: V;
  label: string;
  workingLabel?: string;
  successLabel?: string;
  disabled?: boolean;
  onlyOnce?: boolean;
  className?: string;
  onClick?: () => Promise<boolean> | void;
  onSuccess?: (result: T) => void;
}

export function MutationButton<T = any, V = OperationVariables>(
  props: Props<T, V>
) {
  const {
    label,
    workingLabel,
    successLabel,
    disabled,
    mutation,
    variables,
    onlyOnce,
    className
    // children
  } = props;
  const [success, setSuccess] = React.useState(false);
  const [mutate, result] = useMutation<T, V>(mutation, { variables });
  const { tr } = useTranslation();
  React.useEffect(() => {
    setSuccess(false);
  }, [props.variables]);
  if (result.error) {
    sentry.captureException(result.error);
    return (
      <div className="alert alert-warning">
        {tr("mutationButtonFailedTryAgain")}
      </div>
    );
  }
  return (
    <Button
    className={className}
      label={
        success && successLabel
          ? successLabel
          : workingLabel && result.loading
          ? workingLabel
          : label
      }
      disabled={disabled || result.loading || (onlyOnce && success)}
      onClick={async () => {
        if (props.onClick) {
          const abort = await props.onClick();
          if (abort === false) {
            return;
          }
        }
        const res = await mutate({
          variables,
        });

        setSuccess(true);
        if (props.onSuccess && res) {
          props.onSuccess(res.data!);
        }
      }}
    />
  );
}
