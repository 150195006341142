import * as _ from "lodash";
import * as React from "react";

// Bootstrap breakpoints
export enum Breakpoint {
  xs = 0,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
}

const getBreakpoint = (width: number) => {
  if (width >= Breakpoint.xl) {
    return Breakpoint.xl;
  } else if (width >= Breakpoint.lg) {
    return Breakpoint.lg;
  } else if (width >= Breakpoint.md) {
    return Breakpoint.md;
  } else if (width >= Breakpoint.sm) {
    return Breakpoint.sm;
  } else {
    return Breakpoint.xs;
  }
};

export const useMediaWidth = () => {
  const [size, setSize] = React.useState(getBreakpoint(window.innerWidth));
  React.useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setSize(getBreakpoint(width));
    };

    const debounced = _.debounce(handleResize, 200, {
      leading: true,
      trailing: true,
      maxWait: 300,
    });

    window.addEventListener("resize", debounced);
    return () => {
      window.removeEventListener("resize", debounced);
    };
  }, []);

  return [size];
};
