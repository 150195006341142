import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { ContentState, RawDraftContentState, convertToRaw } from "draft-js";
import * as React from "react";
import { Editor as DraftEditor } from "react-draft-wysiwyg";
import { nonEmptyString } from "../../Utils/functional";

const toolbarConfigs = {
  mini: {
    options: ["inline", "list", "link", "history"],
    inline: {
      options: ["bold", "italic", "underline", "strikethrough"],
    },
    link: {
      defaultTargetOption: "_blank",
    },
  },
  all: {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "fontFamily",
      "list",
      "textAlign",
      "colorPicker",
      "link",
      "embedded",
      "emoji",
      "image",
      "remove",
      "history",
    ],
  },
};

interface Props {
  className?: string;
  feedback?: string;
  ariaDescribedBy?: string;
  initialContentState?: string | null;
  toolbarLayout?: keyof typeof toolbarConfigs;
  onChange?: (value: string) => void;
  onChangeRaw?: (value: any) => void;
  disabled?: boolean;
  readOnly?: boolean;
}

interface State {
  initialState: ContentState;
}

export class RTEditor extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    const { initialContentState } = props;

    let initialState: ContentState = convertToRaw(
      ContentState.createFromText(initialContentState || "")
    ) as any;

    if (initialContentState) {
      try {
        initialState = JSON.parse(initialContentState);
      } catch (e) {
        // eaten
      }
    }

    this.state = { initialState };
  }

  public render() {
    const { className = "" } = this.props;

    if (!this.state.initialState) {
      return null;
    }
    const readOnly = this.props.readOnly || this.props.disabled;
    const invalid = nonEmptyString(this.props.feedback);
    const editorClassNames = `draftjs-editor ${invalid ? "invalid" : ""} ${
      readOnly ? "disabled" : ""
    }`;

    return (
      <React.Fragment>
        <DraftEditor
          readOnly={this.props.readOnly || this.props.disabled}
          initialContentState={this.state.initialState}
          wrapperClassName={`draftjs-wrapper ${className}`}
          editorClassName={editorClassNames}
          toolbarHidden={readOnly}
          ariaDescribedBy={this.props.ariaDescribedBy}
          onChange={(data: RawDraftContentState) => {
            if (this.props.onChange) {
              this.props.onChange(JSON.stringify(data));
            }
            if (this.props.onChangeRaw) {
              this.props.onChangeRaw(data);
            }
          }}
          toolbar={toolbarConfigs[this.props.toolbarLayout || "all"]}
        />
        <div className={"invalid-feedback" + (invalid ? " d-block" : "")}>
          {this.props.feedback}
        </div>
      </React.Fragment>
    );
  }
}
