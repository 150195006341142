import gql from "graphql-tag";
import React, { ComponentClass } from "react";
import { QueryResult } from "@apollo/client";
import { graphql, DataValue } from "@apollo/client/react/hoc";
import { scroller } from "react-scroll";
import { AdminContext, Translation, withAdminContext } from "../../App/reducer";
import { Loading, Table } from "../../Common";
import { HelpTooltip } from "../../Common/components/HelpTooltip";
import { Time } from "../../Common/components/Time";
import * as gqltypes from "../../gqltypes";

type Form = Pick<gqltypes.Form, "id" | "name" | "subType">;
type Stats = Pick<
  gqltypes.ApplicationStatistics,
  "answered" | "open" | "closed"
>;
export type ApplicationTableApplication = Pick<
  gqltypes.Application,
  "id" | "name" | "sendDate" | "closed"
> & {
  form: Form;
  stats: Stats;
};

interface Props {
  tr: Translation["tr"];
  applications: ApplicationTableApplication[];
  selectedAppId?: string;
  onSelectApp?: (selectedAppId: string) => void;
  scrollToElementId?: string;
}

export const ApplicationTable = (props: Props) => {
  const { tr, applications, selectedAppId, onSelectApp } = props;
  return (
    <Table
      clickableRows
      noEndLine
      initialOrder="publicationDate"
      headers={[
        {
          key: "status",
          element: null,
          usingSortValue: true,
        },
        {
          key: "applicationName",
          element: tr("viewApplicationColumnApplicationName"),
        },
        {
          key: "formName",
          element: tr("viewApplicationColumnFormName"),
        },
        {
          key: "formType",
          element: tr("viewApplicationColumnType"),
        },
        {
          key: "publicationDate",
          element: tr("viewApplicationColumnPublicationDate"),
          usingSortValue: true,
        },
        {
          key: "answerCount",
          element: tr("viewApplicationColumnAnswerCount"),
        },
        {
          key: "openCount",
          element: tr("viewApplicationColumnOpenCount"),
        },
        {
          key: "closedCount",
          element: tr("viewApplicationColumnClosedCount"),
        },
      ]}
      rows={applications.map((app) => {
        return {
          key: app.id,
          onClick: () => {
            if (onSelectApp) onSelectApp(app.id);

            setTimeout(() => {
              if (props.scrollToElementId) {
                scroller.scrollTo(props.scrollToElementId, {
                  duration: 600,
                  delay: 70,
                  smooth: true,
                  offset: -30,
                });
              }
            });
          },
          selected: selectedAppId === app.id,
          columns: {
            status: {
              content: app.closed ? (
                <HelpTooltip
                  tooltipText={tr("viewApplicationClosedTooltip")}
                  faIconClass="fas fa-times text-danger"
                />
              ) : null,
              sortValue: app.closed,
            },
            applicationName: { content: app.name },
            formName: { content: app.form.name },
            formType: {
              content:
                app.form.subType === gqltypes.FormSubType.outMigration
                  ? tr("viewApplicationColumnTypeOutMigration")
                  : tr("viewApplicationColumnTypeGeneral"),
            },
            publicationDate: {
              content: <Time date={app.sendDate} />,
              sortValue: app.sendDate,
            },
            answerCount: { content: app.stats.answered },
            openCount: { content: app.stats.open },
            closedCount: { content: app.stats.closed },
          },
        };
      })}
    />
  );
};

interface WrapperOutProps {
  tr: Translation["tr"];
  selectedAppId?: string;
  onSelectApp?: (application: ApplicationTableApplication | undefined) => void;
}

interface WrapperProps extends AdminContext, WrapperOutProps {
  applications: DataValue<gqltypes.viewOrgApplicationAnswer> & QueryResult;
}

const orgAppQuery = gql`
  query applicationTableOrgApplications($context: Context!) {
    organisation(context: $context) {
      id
      applications {
        id
        name
        closed
        form {
          id
          name
          subType
        }
        creator {
          id
          name
        }
        created
        sendDate
        singleGuardianSend
        singleGuardianAcceptOffer
        sent
        stats {
          answered
          open
          closed
        }
      }
    }
  }
`;

const withApplications = graphql<
  WrapperProps,
  gqltypes.applicationTableOrgApplications,
  gqltypes.applicationTableOrgApplicationsVariables,
  any
>(orgAppQuery, {
  name: "applications",
  options: (props) => ({
    variables: {
      context: props.adminContext,
    },
  }),
});

const ApplicationTableWrapper = (props: WrapperProps) => {
  if (!props.applications) return null;

  const { tr, selectedAppId, onSelectApp } = props;
  if (props.applications.loading) return <Loading />;

  const applications = props.applications.organisation!.applications;

  if (!applications || applications.length === 0) {
    return (
      <div className="alert alert-info m-content">
        {tr("viewApplicationNoPublicationsAvailable")}
      </div>
    );
  }

  return (
    <ApplicationTable
      tr={tr}
      applications={applications}
      selectedAppId={selectedAppId}
      onSelectApp={(id: string) => {
        if (onSelectApp) {
          onSelectApp(applications.find((app) => app.id === id));
        }
      }}
    />
  );
};

export const WrappedApplicationTable: ComponentClass<WrapperOutProps> =
  withAdminContext(withApplications(ApplicationTableWrapper)) as any;
