import gql from "graphql-tag";
import _ from "lodash";
import * as React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import {
  Translation,
  useAdminContext,
  useTranslation,
} from "../../App/reducer";
import { Checkbox, Table } from "../../Common";
import { GenericError } from "../../Common/components/GenericError";
import { ISTContainer } from "../../Common/components/ISTContainer";
import { IconButton } from "../../Common/components/IconButton";
import { Loading } from "../../Common/components/Loading";
import { Time } from "../../Common/components/Time";
import { fullHistoryRecordFragment } from "../../Common/fragments";
import { useLocationState, usePersistedBooleanState } from "../../Common/hooks";
import {
  DELETE_FAVORITE_MUTATION,
  DELETE_FAVORITE_MUTATION_TYPES,
} from "../../Common/mutations";
import { getUserInfoUrl } from "../../Common/utils";
import * as gqltypes from "../../gqltypes";
import { links } from "../links";

const CHANGES_LAST_DAYS = 7;

type PartialHistoryRecord = Pick<
  gqltypes.HistoryRecord,
  "label" | "id" | "type" | "data"
>;

const getFavoriteEntryLabel = (
  tr: Translation["tr"],
  entry: PartialHistoryRecord
) => {
  const typeName =
    entry.type === gqltypes.RecordHistoryType.form
      ? " (" + tr("form") + ")"
      : "";
  return entry.label + typeName;
};

export const Start = () => {
  const { tr } = useTranslation();
  return (
    <div>
      <h1>{tr("servicePortal")}</h1>
      <p className="col-12 col-md-9 p-0 pb-3">{tr("adminStartDescription")}</p>
      <ISTContainer header={tr("adminStartHeader")}>
        <HistoryAndFavorites tr={tr} />
        <NewChangedAnswers tr={tr} />
      </ISTContainer>
    </div>
  );
};

const HistoryAndFavorites = (props: { tr: Translation["tr"] }) => {
  const { tr } = props;
  const adminContext = useAdminContext();

  const { data, loading, error, refetch } = useQuery<
    gqltypes.UserHistory,
    gqltypes.UserHistoryVariables
  >(
    gql`
      query UserHistory($context: Context!) {
        me {
          id
          history(context: $context) {
            ...FullHistoryRecord
          }
          favorites(context: $context) {
            ...FullHistoryRecord
          }
        }
      }
      ${fullHistoryRecordFragment}
    `,
    { variables: { context: adminContext }, fetchPolicy: "cache-and-network" }
  );

  const [deleteFavorite] = useMutation<
    DELETE_FAVORITE_MUTATION_TYPES["data"],
    DELETE_FAVORITE_MUTATION_TYPES["variables"]
  >(DELETE_FAVORITE_MUTATION);

  const library = data?.me;
  if (loading) return <Loading />;
  if (!library || error) return <GenericError />;

  const mdWidth = library.favorites.length && library.history.length ? 6 : 12;

  if (library.history.length === 0 && library.favorites.length === 0) {
    return <div className="alert alert-info ">{tr("adminStartNoHistory")}</div>;
  }

  return (
    <div className="row">
      {library.favorites.length > 0 && (
        <div className={`col-md-${mdWidth}`}>
          <h3 className="pb-0 mb-0 p-content">{tr("adminStartFavorites")}</h3>
          <Table
            unsortable
            headers={[
              {
                key: "name",
                element: tr("adminStartFavoriteNameColumn"),
              },
              {
                key: "favorite",
                element: tr("adminStartFavoriteFavoriteColumn"),
              },
            ]}
            rows={library.favorites.map((entry) => ({
              key: entry.id,
              columns: {
                name: {
                  content: (
                    <Link to={links.toFavorite(entry)}>
                      {getFavoriteEntryLabel(tr, entry)}
                    </Link>
                  ),
                },
                favorite: {
                  content: (
                    <IconButton
                      iconClass="fas fa-star"
                      title={tr("adminStartFavoriteRemoveTitle")}
                      onClick={async () => {
                        await deleteFavorite({
                          variables: { id: entry.id, context: adminContext },
                        });
                        refetch();
                      }}
                      aria-label={tr(
                        "adminStartFavoriteRemoveAriaLabel",
                        entry.label
                      )}
                    />
                  ),
                },
              },
            }))}
          />
        </div>
      )}

      {library.history.length > 0 && (
        <div className={`col-md-${mdWidth}`}>
          <h3 className="pb-0 mb-0 p-content">{tr("adminStartLastShown")}</h3>
          <Table
            unsortable
            headers={[
              {
                key: "name",
                element: tr("adminStartLastShownNameColumn"),
              },
            ]}
            rows={library.history.map((entry) => ({
              key: entry.id,
              columns: {
                name: {
                  content: (
                    <Link to={links.toFavorite(entry)}>
                      {getFavoriteEntryLabel(tr, entry)}
                    </Link>
                  ),
                },
              },
            }))}
          />
        </div>
      )}
    </div>
  );
};

const NewChangedAnswers = (props: { tr: Translation["tr"] }) => {
  const { tr } = props;
  const adminContext = useAdminContext();

  const [showAllNewResponseStats, setShowAllNewResponseStats] =
    useLocationState("showAllNewResponseStats", false);
  const [showAllUpdatedResponseStats, setShowAllUpdatedResponseStats] =
    useLocationState("showAllUpdatedResponseStats", false);

  const [onlyFavorites, setOnlyFavorites] = usePersistedBooleanState(
    "adminStartUpdatedResponsesOnlyFavorites",
    true
  );

  const since = new Date();
  since.setDate(since.getDate() - CHANGES_LAST_DAYS);
  since.setHours(0, 0, 0, 0);

  const { data, loading, error } = useQuery<
    gqltypes.newResponseStats,
    gqltypes.newResponseStatsVariables
  >(newResponseStatsQuery, {
    variables: { context: adminContext, since, onlyFavorites },
    fetchPolicy: "cache-first",
  });

  if (error) return <GenericError />;

  const { newResponseStats, updatedResponses } = data || {};

  return (
    <div>
      <div className="p-content">
        <Checkbox
          id="onlyFavorites"
          label={tr("adminStartOnlyFavoritesLabel")}
          checked={onlyFavorites}
          onChange={(id, checked) => setOnlyFavorites(checked)}
        />
      </div>
      <div>
        <h3 className="pb-0 mb-0 p-content">
          {tr("adminStartUpdatedResponses", CHANGES_LAST_DAYS)}
        </h3>
        {updatedResponses?.length ? (
          <Table
            headers={[
              {
                key: "user",
                element: tr("adminStartUpdatedResponsesUserColumn"),
              },
              {
                key: "groups",
                element: tr("adminStartNewResponseStatsGroupNameColumn"),
              },
              {
                key: "publicationName",
                element: tr("adminStartNewResponseStatsPublicationNameColumn"),
              },
              {
                key: "lastActivity",
                element: tr("adminStartUpdatedResponsesLastModifiedColumn"),
                usingSortValue: true,
              },
              { key: "show_answer", element: null, unsortable: true },
            ]}
            rows={updatedResponses.map((entry) => ({
              key: entry.id,
              columns: {
                user: {
                  content: entry.user ? entry.user.name : "??",
                  href: entry.user
                    ? getUserInfoUrl({
                        id: entry.user.id,
                        source: gqltypes.UserSource.educloud,
                        customerId: entry.user.customerId,
                      })
                    : undefined,
                },
                groups: {
                  content: entry.publicationRecipient.groups
                    .map((g) => g.displayName)
                    .join(", "),
                },
                publicationName: {
                  content: entry.publicationRecipient.publication.name,
                },
                lastActivity: {
                  content: <Time date={entry.modified} type="calendar" />,
                  sortValue: entry.modified,
                },
                show_answer: {
                  content: (
                    <Link
                      title={tr("viewAnswerShowForm")}
                      aria-label={tr(
                        "viewAnswerAriaShowForm",
                        entry.user ? entry.user.name : "??"
                      )}
                      to={links.admin.publication.response(entry.id)}
                    >
                      <i
                        aria-hidden="true"
                        className="fas fa-chevron-circle-right"
                      />
                    </Link>
                  ),
                },
              },
            }))}
            initialOrder={"lastActivity"}
            limit={10}
            overrideLimit={showAllUpdatedResponseStats}
            onOverrideLimitClick={() => {
              setShowAllUpdatedResponseStats(!showAllUpdatedResponseStats);
            }}
          />
        ) : loading ? (
          <Loading />
        ) : (
          <div className="alert alert-info ">
            {tr("adminStartNoUpdatedResponses", CHANGES_LAST_DAYS)}
          </div>
        )}
      </div>
      <div>
        <h3 className="pb-0 mb-0 p-content">
          {tr("adminStartChanges", CHANGES_LAST_DAYS)}
        </h3>
        {newResponseStats?.length ? (
          <Table
            headers={[
              {
                key: "groupName",
                element: tr("adminStartNewResponseStatsGroupNameColumn"),
              },
              {
                key: "publicationName",
                element: tr("adminStartNewResponseStatsPublicationNameColumn"),
              },
              {
                key: "changes",
                element: tr("adminStartNewResponseStatsChangesColumn"),
              },
              {
                key: "lastActivity",
                element: tr("adminStartNewResponseStatsLastActivityColumn"),
                usingSortValue: true,
              },
            ]}
            rows={newResponseStats.map((entry) => ({
              key: entry.group.id + entry.publication.id,
              columns: {
                groupName: {
                  content: entry.group.displayName,
                  href: links.admin.publication.answers({
                    customerId: entry.group.node.schoolUnit.customerId,
                    schoolUnitId: entry.group.node.schoolUnit.id,
                    studentGroupId: entry.group.node.id,
                    publicationId: entry.publication.id,
                  }),
                },
                publicationName: { content: entry.publication.name },
                changes: { content: entry.count },
                lastActivity: {
                  content: <Time date={entry.lastActivity} type="calendar" />,
                  sortValue: entry.lastActivity,
                },
              },
            }))}
            initialOrder={"lastActivity"}
            limit={10}
            overrideLimit={showAllNewResponseStats}
            onOverrideLimitClick={() => {
              setShowAllNewResponseStats(!showAllNewResponseStats);
            }}
            noEndLine
          />
        ) : loading ? (
          <Loading />
        ) : (
          <div className="alert alert-info ">
            {tr("adminStartNoChanges", CHANGES_LAST_DAYS)}
          </div>
        )}
      </div>
    </div>
  );
};

const newResponseStatsQuery = gql`
  query newResponseStats(
    $since: DateTime
    $onlyFavorites: Boolean!
    $context: Context!
  ) {
    newResponseStats(
      since: $since
      onlyFavorites: $onlyFavorites
      context: $context
    ) {
      count
      lastActivity
      publication {
        id
        name
      }
      group {
        ... on PublicationGroupStudentGroup {
          id
          displayName
          type
          node {
            id
            customerId
            schoolUnit {
              id
              customerId
              displayName
            }
          }
        }
      }
    }

    updatedResponses(
      since: $since
      onlyFavorites: $onlyFavorites
      context: $context
    ) {
      id
      modified
      user {
        id
        customerId
        name
      }
      publicationRecipient {
        id
        publication {
          id
          name
        }
        groups {
          ... on PublicationGroupStudentGroup {
            id
            customerId
            displayName
          }
        }
      }
    }
  }
`;

export const StartContainer = Start;
