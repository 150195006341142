import * as React from "react";
import Spinner from "react-spinkit";
import { useTranslation } from "../../App/reducer";

interface Props {
  fadeIn?: number;
  className?: string;
  message?: string;
}

const style = {
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column" as const,
  padding: "10px",
};

export function Loading(props: Props) {
  const { message, fadeIn = 250 } = props;
  const [show, setShow] = React.useState(fadeIn <= 0);
  const { tr } = useTranslation();

  const timeoutRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (fadeIn > 0) {
      timeoutRef.current = window.setTimeout(() => {
        setShow(true);
      }, fadeIn);
    }

    return () => {
      if (timeoutRef.current) clearInterval(timeoutRef.current);
    };
    // TODO: Seems to work fine but might want to fix this lint issue some day
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!show) {
    return null;
  }

  return (
    <div className={props.className} style={style}>
      <h1>{tr("loading")}</h1>
      {message && <span>{message}</span>}
      <Spinner name="three-bounce" {...{ fadeIn: "none" }} />
    </div>
  );
}
