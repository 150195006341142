import React, { useCallback } from "react";

interface ChangeType {
  id: string;
  name: string;
  value: string;
}

interface Props {
  checked: boolean;
  containerClassName?: string;
  disabled?: boolean;
  invalid?: boolean;
  id: string;
  label?: string;
  name: string;
  onChange?: (response: ChangeType) => void;
  readOnly?: boolean;
  required?: boolean;
  value: string;
  tabIndex?: number;
}

export const Radiobutton = (props: Props) => {
  const { label, containerClassName, invalid, onChange, ...attributes } = props;

  const handleChange = useCallback(() => {
    if (onChange) {
      onChange({
        id: props.id,
        name: props.name,
        value: props.value,
      });
    }
  }, [onChange, props.id, props.name, props.value]);

  const invalidClass = invalid ? " is-invalid" : "";
  return (
    <div className={`custom-control custom-radio ${containerClassName || ""}`}>
      <input
        {...attributes}
        type="radio"
        className={"custom-control-input" + invalidClass}
        onChange={handleChange}
      />
      <label
        className={"custom-control-label" + invalidClass}
        htmlFor={attributes.id}
      >
        {label}
      </label>
    </div>
  );
};
