import "react-day-picker/lib/style.css";

import { defaultTo } from "lodash";
import moment from "moment";
import * as React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import { Translation, useTranslation } from "../../App/reducer";
import { FormInput } from "./FormInput";
import { HelpTooltip } from "../../Common/components/HelpTooltip";

interface Props {
  id: string;
  containerClass?: string;
  disabled?: boolean;
  disabledDays?: (day: Date) => boolean;
  feedback?: string;
  handleSelectDay: (day?: Date) => void;
  label?: string;
  day?: "" | Date;
  allowPast?: boolean;
  disableToday?: boolean;
  minSelectable?: Date;
  maxSelectable?: Date;
  readOnly?: boolean;
  "aria-labelledby"?: string;
  tooltipMessage?: string;
}

export const DatePickerWithoutTranslation = (props: Props & Translation) => {
  const {
    id,
    containerClass,
    label,
    disableToday,
    minSelectable,
    maxSelectable,
  } = props;

  const { tr, uiLanguage } = useTranslation();

  const handleSelectDay = React.useCallback(
    (day: Date) => {
      props.handleSelectDay(day);
    },
    // TODO: Seems to work fine but might want to fix this lint issue some day
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.handleSelectDay]
  );

  return (
    <div className={containerClass}>
      {label && <label htmlFor={id}>{label}</label>}{" "}
      {props.tooltipMessage && (
        <HelpTooltip
          tooltipText={(props.tooltipMessage)}
        />
      )}
      <DayPickerInput
        id={id}
        component={FormInput}
        format={"YYYY-MM-DD"}
        formatDate={formatDate}
        onDayChange={handleSelectDay}
        parseDate={parseDate}
        placeholder={tr("YYYYMMDDString")}
        selectedDays={props.day}
        value={props.day}
        inputProps={{
          id,
          disabled: defaultTo(props.disabled || props.readOnly, false),
          feedback: props.feedback,
          "aria-labelledby": props["aria-labelledby"],
        }}
        clickUnselectsDay={true}
        dayPickerProps={{
          firstDayOfWeek: 1,
          showWeekNumbers: true,
          disabledDays: defaultTo(props.disabledDays, (d: Date) => {
            if (props.readOnly) return true;

            const day = moment(d);

            if (disableToday && moment().isSame(day, "day")) return true;

            if (maxSelectable && moment(day).isAfter(maxSelectable, "day"))
              return true;

            if (minSelectable && moment(day).isBefore(minSelectable, "day"))
              return true;

            return false;
          }),
          fromMonth: minSelectable ? minSelectable : undefined,
          toMonth: maxSelectable ? maxSelectable : undefined,
          locale: uiLanguage,
          localeUtils: MomentLocaleUtils,
        }}
      />
      <div className="invalid-feedback">{props.feedback}</div>
    </div>
  );
};
export const DatePicker = (props: Props) => {
  const translation = useTranslation();
  return <DatePickerWithoutTranslation {...translation} {...props} />;
};
