import { convertFromRaw } from "draft-js";
import { sum } from "lodash";
import { definedNotNull, nonEmptyString } from "../Utils/functional";
import { assertUnreachable } from "../Utils/typeHelpers";
import * as gqltypes from "../gqltypes";
import { getSettings } from "../settings";

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9])+(\.[a-zA-Z]{2,})*))$/;
const nationalIdRegex = getSettings().regionSettings.nationalIdRegex;

export function componentIsValid(component: gqltypes.ComponentInput): boolean {
  const titleValid = nonEmptyString(component.title);
  const questionsValid = component.questions.every(questionIsValid);
  return titleValid && questionsValid;
}

export function predicateComponentIsValid(
  component: gqltypes.FormPredicateComponentInput
): boolean {
  const questionsValid = component.questions.every(questionIsValid);
  return questionsValid;
}

export function rankingSettingsAreValid(q: gqltypes.FormQuestionInput) {
  if (q.minSelectedOptions) {
    if (q.minSelectedOptions < 0) {
      return false;
    }

    if (!q.compulsory) {
      return false;
    }

    // min > options len
    if (q.minSelectedOptions > q.options!.length) {
      return false;
    }

    // max < min
    if (q.maxSelectedOptions && q.maxSelectedOptions < q.minSelectedOptions) {
      return false;
    }
  }

  if (q.maxSelectedOptions) {
    if (q.maxSelectedOptions < 0) {
      return false;
    }
  }

  return true;
}

export function questionIsValid(question: gqltypes.FormQuestionInput): boolean {
  const questionNameValid = nonEmptyString(question.question);
  if (!questionNameValid) {
    return false;
  }
  switch (question.type) {
    case gqltypes.FormQuestionType.ranking:
      if (!rankingSettingsAreValid(question)) {
        return false;
      }
    /* falls through */
    case gqltypes.FormQuestionType.checkboxes:
    case gqltypes.FormQuestionType.multiChoice:
      return (
        definedNotNull(question.options) &&
        question.options.every((o) => nonEmptyString(o.label))
      );
    case gqltypes.FormQuestionType.yesOrNo:
    case gqltypes.FormQuestionType.shortText:
    case gqltypes.FormQuestionType.longText:
    case gqltypes.FormQuestionType.date:
      // These are valid if the question name is valid, which is checked above
      return true;
    default:
      assertUnreachable(question.type);
      return false;
  }
}

export function richTextIsValid(content?: string | null): boolean {
  if (content === undefined || content === null || content === "") {
    return false;
  }
  try {
    const rawDraftContentState = JSON.parse(content);
    const contentState = convertFromRaw(rawDraftContentState);
    const numCharacters = sum(
      contentState.getBlocksAsArray().map((block) => block.getLength())
    );
    return numCharacters > 0;
  } catch {
    return content.length > 0;
  }
}

export function translationIsValid(
  translation: gqltypes.FullTranslation,
  baseComponents: gqltypes.ComponentInput[],
  predicateComponents: gqltypes.FormPredicateComponentInput[]
) {
  const validHeader =
    nonEmptyString(translation.name) &&
    richTextIsValid(translation.description);

  if (!validHeader) {
    return false;
  }

  const result =
    baseComponents.every((baseComponent) => {
      const translationComponent = translation.componentData.components.find(
        (tc) => tc.id === baseComponent.id
      );
      if (translationComponent) {
        return translationComponentIsValid(translationComponent, baseComponent);
      }
      return false;
    }) &&
    predicateComponents.every((basePC) => {
      const translationComponent = (
        translation.componentData.predicateComponents || []
      ).find((tc) => tc.id === basePC.id);
      if (translationComponent) {
        return translationPredicateComponentIsValid(
          translationComponent,
          basePC
        );
      }
      return false;
    });

  return result;
}

export function translationComponentIsValid(
  component: gqltypes.TranslateComponent,
  baseComponent: gqltypes.ComponentInput
) {
  const titleValid = nonEmptyString(component.title);
  const questionsValid = baseComponent.questions.every((baseQuestion) => {
    const translationQuestion = component.questions.find(
      (tq) => tq.id === baseQuestion.id
    );
    if (translationQuestion) {
      return translationQuestionIsValid(translationQuestion, baseQuestion);
    }
    return false;
  });
  return titleValid && questionsValid;
}

export function translationPredicateComponentIsValid(
  component: gqltypes.TranslatePredicateComponent,
  baseComponent: gqltypes.FormPredicateComponentInput
) {
  const questionsValid = baseComponent.questions.every((baseQuestion) => {
    const translationQuestion = component.questions.find(
      (tq) => tq.id === baseQuestion.id
    );
    if (translationQuestion) {
      return translationQuestionIsValid(translationQuestion, baseQuestion);
    }
    return false;
  });
  return questionsValid;
}

export function translationQuestionIsValid(
  question: gqltypes.TranslateComponent["questions"][0],
  baseQuestion: gqltypes.FormQuestionInput
): boolean {
  const questionNameValid = nonEmptyString(question.question);
  if (!questionNameValid) {
    return false;
  }
  switch (baseQuestion.type) {
    case gqltypes.FormQuestionType.checkboxes:
    case gqltypes.FormQuestionType.multiChoice:
    case gqltypes.FormQuestionType.ranking:
      return (
        definedNotNull(question.options) &&
        definedNotNull(baseQuestion.options) &&
        baseQuestion.options.every((bo) => {
          const to = question.options!.find(
            (transOption) => transOption.id === bo.id
          );
          return to !== undefined && nonEmptyString(to.label);
        })
      );
    case gqltypes.FormQuestionType.yesOrNo:
    case gqltypes.FormQuestionType.shortText:
    case gqltypes.FormQuestionType.longText:
    case gqltypes.FormQuestionType.date:
      // These are valid if the question name is valid, which is checked above
      return true;
    default:
      assertUnreachable(baseQuestion.type);
      return false;
  }
}

/*
 * Answers
 */

export function isValidEmail(value: string) {
  return emailRegex.test(value);
}

export function isValidNationalId(value: string) {
  return nationalIdRegex.test(value);
}

export function isValidPhone(value: string) {
  const matches = value.match(/\d/g);
  return matches ? matches.length >= 7 : false;
}

export function isValidRankAnswer(
  q: gqltypes.FormQuestionRanking,
  answer: gqltypes.AnswerRanking
) {
  const min = q.minSelectedOptions;
  const max = q.maxSelectedOptions;
  const opts = answer.rankings.length;

  if (min) {
    if (min > opts) {
      return false;
    }
  }
  if (max) {
    if (opts > max) {
      return false;
    }
  }
  return true;
}

export function isValidShortAnswer(
  q: gqltypes.FormQuestionShortText,
  answer: gqltypes.AnswerShortText
) {
  if (q.compulsory && !answer.shortText) {
    return false;
  }

  if (q.validationType) {
    switch (q.validationType) {
      case gqltypes.ShortTextValidationType.email:
        return isValidEmail(answer.shortText);
      case gqltypes.ShortTextValidationType.nationalId:
        return isValidNationalId(answer.shortText);
      case gqltypes.ShortTextValidationType.phone:
        return isValidPhone(answer.shortText);

      default:
        assertUnreachable(q.validationType);
    }
  }

  return true;
}

export function isValidQuestionAnswer(
  q: gqltypes.FormQuestion,
  answer: gqltypes.Answer | null | undefined
) {
  if (!answer) return !q.compulsory;
  switch (q.type) {
    case gqltypes.FormQuestionType.ranking:
      return isValidRankAnswer(
        q as gqltypes.FormQuestionRanking,
        answer as gqltypes.AnswerRanking
      );

    case gqltypes.FormQuestionType.checkboxes:
      return true;

    case gqltypes.FormQuestionType.multiChoice:
      return q.compulsory
        ? (answer as gqltypes.AnswerMultiChoice).multiChoice.length > 0
        : true;

    case gqltypes.FormQuestionType.yesOrNo:
      return q.compulsory
        ? definedNotNull((answer as gqltypes.AnswerYesOrNo).yesOrNo)
        : true;

    case gqltypes.FormQuestionType.shortText:
      return (
        (!q.compulsory &&
          (answer as gqltypes.AnswerShortText).shortText === "") ||
        isValidShortAnswer(q, answer as gqltypes.AnswerShortText)
      );

    case gqltypes.FormQuestionType.longText:
      return q.compulsory
        ? (answer as gqltypes.AnswerLongText).longText.length > 0
        : true;

    case gqltypes.FormQuestionType.date:
      return q.compulsory
        ? definedNotNull((answer as gqltypes.AnswerDate).date)
        : true;

    default:
      assertUnreachable(q.type);
      throw new Error("Not valid");
  }
}
