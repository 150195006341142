import * as React from "react";
import Select, { createFilter } from "react-select";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";

interface Props extends StateManagerProps {}

const filter = createFilter({
  matchFrom: "any",
  stringify: (option: FilterOptionOption<unknown>) => `${option.label}`,
});

export default function FormSearchSelect(props: Props) {
  return (
    <Select {...props} classNamePrefix="search-select" filterOption={filter} />
  );
}
