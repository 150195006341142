import gql from "graphql-tag";
import * as _ from "lodash";
import * as React from "react";
import { graphql, DataValue } from "@apollo/client/react/hoc";
import {
  AdminContext,
  Translation,
  withAdminContext,
  withTranslation,
} from "../../App/reducer";
import { Button, ISTContainer, Loading } from "../../Common";
import { IconButton } from "../../Common/components/IconButton";
import { Time } from "../../Common/components/Time";
import { showConfirmDialog } from "../../Utils/dialogs/showDialog";
import { client } from "../../api";
import * as gqltypes from "../../gqltypes";
import * as persistance from "../../persistance";
import { defaultDialogCancel } from "../../translation/strings";
import { links } from "../links";
import { getFormTypeName } from "./CreateForm";
import { Location, NavigateFunction } from "react-router";
import withRouter from "../../Utils/withRouter";

interface StateProps extends AdminContext {}

interface Props extends StateProps, Translation {
  data: DataValue<gqltypes.forms>;
  deleteForm: (options: {
    variables: gqltypes.DeleteFormVariables;
  }) => Promise<gqltypes.DeleteForm>;
  location: Location;
  navigate: NavigateFunction;
}

enum OrderableFields {
  name = "name",
  created = "created",
  modified = "modified",
  locked = "locked",
  subType = "subType",
}

interface State {
  orderField: OrderableFields;
  orderDirection: "asc" | "desc";
  type: gqltypes.FormType;
}

class Forms extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.props.data.refetch();
    const path = this.props.location.pathname;
    const orderDirection = persistance.get(`${path}_order`) || ("asc" as any);
    const orderField = persistance.get(`${path}_field`) || ("modified" as any);
    const type =
      (persistance.get(`${path}_type`) as gqltypes.FormType) ||
      gqltypes.FormType.publication;

    this.state = {
      orderDirection,
      orderField,
      type,
    };
  }

  public render() {
    if (!this.props.data.organisation) {
      return (
        <main className="top content">
          <Loading />
        </main>
      );
    }

    const forms = _.orderBy(
      this.props.data.organisation.forms,
      this.state.orderField,
      this.state.orderDirection
    );

    const orderDirectionIcon = (
      <i
        className={`ml-1 fas fa-${
          this.state.orderDirection === "asc" ? "caret-down" : "caret-up"
        }`}
      />
    );
    const iconFiller = <i className="ml-1 fas fa-caret-down invisible" />;

    const { tr } = this.props;

    const currentTypeName = getFormTypeName(tr, this.state.type);

    const SelectFormTypeButton = (props: { type: gqltypes.FormType }) => (
      <Button
        label={getFormTypeName(tr, props.type)}
        level={this.state.type === props.type ? "primary" : "secondary"}
        onClick={() => {
          const path = this.props.location.pathname;
          this.setState({ type: props.type });
          persistance.set(`${path}_type`, props.type);
        }}
      />
    );

    return (
      <main>
        <h1>{tr("listFormsTitle")}</h1>
        <p className="col-12 col-md-9 p-0 pb-3">{tr("listFormsDescription")}</p>
        <ISTContainer header={tr("listFormsAllForms", currentTypeName)}>
          <div className="p-content">
            <SelectFormTypeButton type={gqltypes.FormType.publication} />{" "}
            <SelectFormTypeButton type={gqltypes.FormType.application} />
          </div>
          {forms.length === 0 ? (
            <div>
              <div className="alert alert-info m-content">
                {tr("listFormsNoFormsAvailable")}
              </div>
              <Button
                className="m-content"
                onClick={this.handleCreateNewForm}
                label={tr("listFormsCreateNewForm")}
              />
            </div>
          ) : (
            <table
              className={`table ${
                forms.length >= 10 ? "table-sticky-header" : ""
              } table-hover table-no-end-line table-responsive-sm mt-content mb-5`}
            >
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="clickable"
                    aria-label={tr("listFormsOrderByName")}
                    onClick={() => this.changeOrderField(OrderableFields.name)}
                  >
                    {tr("listFormsColumnName")}
                    {this.state.orderField === OrderableFields.name
                      ? orderDirectionIcon
                      : iconFiller}
                  </th>
                  {this.state.type === gqltypes.FormType.application && (
                    <th
                      scope="col"
                      className="clickable"
                      aria-label={tr("listFormsOrderByApplicationType")}
                      onClick={() =>
                        this.changeOrderField(OrderableFields.subType)
                      }
                    >
                      {tr("listFormsColumnApplicationType")}
                      {this.state.orderField === OrderableFields.created
                        ? orderDirectionIcon
                        : iconFiller}
                    </th>
                  )}
                  {this.state.type === gqltypes.FormType.publication && (
                    <th
                      scope="col"
                      className="clickable"
                      aria-label={tr("listFormsOrderByPublicationType")}
                      onClick={() =>
                        this.changeOrderField(OrderableFields.subType)
                      }
                    >
                      {tr("listFormsColumnPublicationType")}
                      {this.state.orderField === OrderableFields.created
                        ? orderDirectionIcon
                        : iconFiller}
                    </th>
                  )}
                  <th
                    scope="col"
                    className="clickable"
                    aria-label={tr("listFormsOrderByCreateDate")}
                    onClick={() =>
                      this.changeOrderField(OrderableFields.created)
                    }
                  >
                    {tr("listFormsColumnCreated")}
                    {this.state.orderField === OrderableFields.created
                      ? orderDirectionIcon
                      : iconFiller}
                  </th>
                  <th
                    scope="col"
                    className="clickable"
                    aria-label={tr("listFormsOrderByChangedDate")}
                    onClick={() =>
                      this.changeOrderField(OrderableFields.modified)
                    }
                  >
                    {tr("listFormsColumnChanged")}
                    {this.state.orderField === OrderableFields.modified
                      ? orderDirectionIcon
                      : iconFiller}
                  </th>
                  <th
                    scope="col"
                    className="clickable text-center text-nowrap"
                    aria-label={tr("listFormsOrderByLockStatus")}
                    onClick={() =>
                      this.changeOrderField(OrderableFields.locked)
                    }
                  >
                    {tr("listFormsColumnLocked")}
                    {this.state.orderField === OrderableFields.locked
                      ? orderDirectionIcon
                      : iconFiller}
                  </th>
                  <th scope="col" className="text-center">
                    {tr("listFormsColumnCopy")}
                  </th>
                  <th scope="col" className="text-center">
                    {tr("listFormsColumnShowEdit")}
                  </th>
                  <th scope="col" className="text-center">
                    {tr("listFormsColumnArchive")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {forms
                  .filter((form) => form.type === this.state.type)
                  .map((form) => (
                    <tr key={form.id}>
                      <th
                        scope="row"
                        onClick={() => {
                          form.locked
                            ? this.viewForm(form.id)
                            : this.editForm(form.id);
                        }}
                        className="clickable"
                      >
                        {form.name}
                      </th>
                      <td>{getFormTypeName(tr, form.subType)}</td>
                      <td>
                        <Time date={form.created} />
                      </td>
                      <td>
                        <Time date={form.modified} />
                      </td>
                      <td className="text-center">
                        {form.locked ? (
                          <React.Fragment>
                            <i
                              aria-hidden
                              className="fas fa-lock"
                              title={tr("listFormsLocked")}
                            />
                            <span className="sr-only">
                              {tr("listFormsLockedSr")}
                            </span>
                          </React.Fragment>
                        ) : (
                          <span className="sr-only">
                            {tr("listFormsUnlockedSr")}
                          </span>
                        )}
                      </td>
                      <td className="text-center">
                        <IconButton
                          onClick={() => this.copyForm(form.id)}
                          iconClass="far fa-copy"
                          title={tr("copy")}
                          aria-label={tr("listFormCopyFormSr", form.name)}
                        />
                      </td>
                      <td className="text-center">
                        {form.locked ? (
                          <IconButton
                            onClick={() => this.viewForm(form.id)}
                            iconClass="fas fa-eye"
                            title={tr("show")}
                            aria-label={tr("listFormsShowFormSr", form.name)}
                          />
                        ) : (
                          <IconButton
                            onClick={() => this.editForm(form.id)}
                            iconClass="fas fa-pencil-alt"
                            title={tr("edit")}
                            aria-label={tr("listFormsEditFormSr", form.name)}
                          />
                        )}
                      </td>
                      <td className="text-center">
                        <IconButton
                          onClick={() => this.deleteForm(form)}
                          iconClass="fas fa-trash"
                          title={tr("archive")}
                          aria-label={tr("listFormsArchiveFormSr", form.name)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </ISTContainer>
      </main>
    );
  }

  private changeOrderField = (key: OrderableFields) => {
    this.setState((state) => {
      const path = this.props.location.pathname;
      if (state.orderField === key) {
        const orderDirection = state.orderDirection === "asc" ? "desc" : "asc";
        persistance.set(`${path}_order`, orderDirection);
        return {
          orderDirection,
        } as any; // complains for some reason, tries to pick both fields
      } else {
        persistance.set(`${path}_field`, key);
        return { orderField: key };
      }
    });
  };

  private handleCreateNewForm = () => {
    this.props.navigate(links.admin.form.create());
  };

  private deleteForm = async (form: { name: string; id: string }) => {
    const deleteForm = await showConfirmDialog({
      title: this.props.tr("listFormsArchiveFormConfirmTitle"),
      content: (
        <React.Fragment>
          <p>
            {this.props.tr("listFormsArchiveFormConfirmContent1")}{" "}
            <strong>{form.name}</strong>?
          </p>
          <p className="font-italic text-muted">
            {this.props.tr("listFormsArchiveFormConfirmContent2")}
          </p>
        </React.Fragment>
      ),
      proceedText: this.props.tr("archive"),
      cancelText: defaultDialogCancel(this.props.tr),
    });
    if (deleteForm) {
      await this.props.deleteForm({
        variables: { id: form.id },
      });
      this.props.data.refetch();
    }
  };

  private copyForm = async (formId: string) => {
    await client.mutate({
      mutation: gql`
        mutation CopyForm($id: ID!) {
          copyForm(id: $id) {
            id
            name
          }
        }
      `,
      variables: {
        id: formId,
      },
    });
    this.props.data.refetch();
  };

  private editForm = (formId: string) => {
    this.props.navigate(links.admin.form.edit(formId));
  };

  private viewForm = (formId: string) => {
    this.props.navigate(links.admin.form.preview(formId));
  };
}

const withDeleteForm = graphql<
  Props,
  gqltypes.DeleteForm,
  gqltypes.DeleteFormVariables,
  any
>(
  gql`
    mutation DeleteForm($id: ID!) {
      deleteForm(id: $id) {
        code
        message
      }
    }
  `,
  {
    name: "deleteForm",
  }
);

export const LIST_FORMS_QUERY = gql`
  query forms($context: Context!) {
    organisation(context: $context) {
      id
      forms {
        id
        name
        type
        subType
        locked
        created
        modified
      }
    }
  }
`;

const withFormsData = graphql<Props, gqltypes.forms, gqltypes.formsVariables>(
  LIST_FORMS_QUERY,
  {
    options: (props) => ({
      variables: {
        context: props.adminContext,
      },
      fetchPolicy: "cache-and-network",
    }),
  }
);

export const FormsContainer = _.flowRight(
  withTranslation,
  withAdminContext,
  withRouter,
  withFormsData,
  withDeleteForm
)(Forms);
