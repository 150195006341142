import * as gqltypes from "../gqltypes";

// Tiny engine for dealing with predicates
// Example usage:
// let json = '{"pred": ["=", "in.option", "option"], "option": \"\\\"123\\\"\"}';
// let parsed = JSON.parse(json);
// console.log("hehe:", resolvePred(parsed, {option: '"123"'}));

// console.log("huh:", resolvePred(["and", ["=", 1, 1], ["=", "hej", 2]], {hej: 2}));
// => huh: true

enum StdVariables {
  eq = "eq",
  dataMatch = "dataMatch",
  and = "and",
  var = "var",
  call = "call",
  matchingOption = "matchingOption",
  matchingYesNo = "matchingYesNo",
}

const convenience = {
  call: (fName: string, ...args: any[]) => ({
    kind: StdVariables.call,
    name: fName,
    args,
  }),
  var: (vName: string) => ({
    kind: StdVariables.var,
    name: vName,
  }),
  dataMatch: (name: string) =>
    convenience.call(
      StdVariables.eq,
      convenience.var("input." + name),
      convenience.var("preset." + name)
    ),
};

const c = convenience;

const funcs = {
  eq: function eq(a: any, b: any): boolean {
    return a === b;
  },

  and: function and(a: any, b: any): boolean {
    return a && b;
  },

  dataMatch: function dataMatch(name: string, env: any): boolean {
    try {
      return (
        resolveVar("input." + name, env) === resolveVar("preset." + name, env)
      );
    } catch (e) {
      return false;
    }
  },

  matchingOption: function matchingOption(env: any): boolean {
    return funcs.dataMatch("option.id", env);
  },

  matchingYesNo: function matchingYesNo(env: any): boolean {
    return funcs.dataMatch("question.id", env) && funcs.dataMatch("value", env);
  },
};

function getFunction(name: string): (...args: any[]) => any {
  const f = (funcs as any)[name];
  if (f !== null && f !== undefined) {
    return f;
  }

  throw Error("Function `" + name + "` not defined.");
}

const resolveVar = (name: string, env: any): string => {
  try {
    const v = name.split(".").reduce((o: any, k: string) => o[k], env);
    if (v === undefined) {
      throw Error(name + " is undefined in env " + JSON.stringify(env));
    } else {
      return v;
    }
  } catch (e) {
    throw Error(name + " is undefined in env " + JSON.stringify(env));
  }
};

function resolveExpression(expr: any, env: any): any {
  if (typeof expr === "object" && expr.kind) {
    switch (expr.kind) {
      case StdVariables.var:
        return resolveExpression(resolveVar(expr.name, env), env);
      case StdVariables.call: {
        const f = getFunction(expr.name);
        const args = (expr.args || []).map((e: any) =>
          resolveExpression(e, env)
        );
        return f.apply(null, [...args, env]);
      }
      default:
        throw Error("Expression of kind " + expr.kind + " is not defined.");
    }
  } else {
    return expr;
  }
}

export function resolvePred(
  predMaybeJson: string | { pred: any; preset: any },
  input: any,
  extraEnv?: {}
): any {
  let parsed = predMaybeJson;

  if (typeof predMaybeJson === "string") {
    try {
      parsed = JSON.parse(predMaybeJson);
    } catch (e) {
      console.error(
        "-- Failed parsing string as JSON:\n",
        '"' + predMaybeJson + '"',
        "\n-- Got error:\n",
        e
      );
      return false;
    }
  }

  // eslint-disable-next-line prefer-const
  let { pred, preset } = parsed as { pred: any; preset: any };

  const env = extraEnv
    ? { ...funcs, ...extraEnv, preset, input }
    : { ...funcs, preset, input };

  pred = typeof pred === "string" ? c.call(pred) : pred;

  try {
    return resolveExpression(pred, env);
  } catch (e) {
    console.error("When resolving pred", pred, "in env", env, "got error: ", e);
    return false;
  }
}

/*
const matchingOption = {
  pred: ShortHands.matchingOption,
  preset: { option: { id: "123" } },
  input: { option: { id: "123" } }
};

console.log("reslving...", resolvePred(matchingOption, null));
*/

type MatchingOptionPredicateType = {
  pred: StdVariables.matchingOption;
  preset: { option: { id: string } };
};
type MatchingYesNoPredicateType = {
  pred: StdVariables.matchingYesNo;
  preset: {
    question: { id: string };
    value: boolean;
  };
};

export const createMatchingOptionPredicate = (
  preset: MatchingOptionPredicateType["preset"]
): MatchingOptionPredicateType => ({
  pred: StdVariables.matchingOption,
  preset,
});

export const createMatchingYesNoPredicate = (
  preset: MatchingYesNoPredicateType["preset"]
): MatchingYesNoPredicateType => ({
  pred: StdVariables.matchingYesNo,
  preset,
});

export const parseMatchingOptionPredicateJson = (
  pred: gqltypes.FormQuestionPredicate
): MatchingOptionPredicateType => {
  return JSON.parse(pred.predicate);
};
