import * as React from "react";
import { SYMBOLS } from "../Admin/form";
import { Translation } from "../App/reducer";
import { Tooltip } from "./components/Tooltip";

export const predicateComponentUnseenTooltip = (
  tr: Translation["tr"],
  key: string
) => (
  <Tooltip
    target={"tooltip_" + key}
    content={SYMBOLS.UNANSWERED}
    message={tr("viewAnswerUnseenDescription")}
  />
);
