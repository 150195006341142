import gql from "graphql-tag";
import * as _ from "lodash";
import * as React from "react";
import { MutationFunction, QueryResult } from "@apollo/client";
import { graphql, DataValue } from "@apollo/client/react/hoc";
import { Params } from "react-router";
import { Link } from "react-router-dom";
import {
  AdminContext,
  Translation,
  withAdminContext,
  withTranslation,
} from "../../App/reducer";
import {
  Checkbox,
  ISTContainer,
  ISTContainerInnerButton,
  ISTContainerInnerButtonProcessing,
  Loading,
  Table,
} from "../../Common";
import { BadgeButton } from "../../Common/components/BadgeButton";
import { Tooltip } from "../../Common/components/Tooltip";
import {
  getSourceFromShortSource,
  getUserInfoUrl,
  isSkolidIstOrg as isSkolidIstAccount,
} from "../../Common/utils";
import * as gqltypes from "../../gqltypes";
import {
  Permission,
  PermissionSource,
  PermissionType,
  SchoolUnitValues,
  formComponentPermissions,
  getComponentPermissionDisplayName,
  getPermissionDescription,
  getPermissionDisplayName,
  permissionInheritance,
  permissionOnOrgOnly,
  permissionOrder,
} from "../../permissions";
import withRouter from "../../Utils/withRouter";
import { ORG_PERMISSION_QUERY } from "./Permissions";
import { Prompt } from "../../Common/components/RouterPrompt";
import { UserNameAndRoles } from "../../Common/components/UsernameAndRoles";

const DELIMITER = "__";

export const AggregatedReadEditView = (props: {
  tr: Translation["tr"];
  mode: "org" | "su";
  prefixId: string;
  permission: Permission;
  handlePermissionChange: (id: string, checked: boolean) => void;
  disabled?: boolean;
}) => {
  const items = formComponentPermissions.map((role) => {
    const permission = props.permission[role];
    const checked = Boolean(permission);
    const notOnlyDirectSource = permission
      ? permission.some((i) => i !== PermissionSource.DIRECT)
      : false;
    return (
      <BadgeButton
        key={role}
        label={getComponentPermissionDisplayName(role, props.tr)}
        color={props.permission[role] ? "success" : "light"}
        className={props.permission[role] ? "mx-1" : "text-muted mx-1"}
        onClick={() => {
          props.handlePermissionChange(props.prefixId + role, !checked);
        }}
        disabled={props.disabled || notOnlyDirectSource}
      />
    );
  });

  const readAllRole = gqltypes.PermissionType.read_all;
  const allPermission = props.permission[readAllRole];
  const allNotOnlyDirectSource = allPermission
    ? allPermission.some((i) => i !== PermissionSource.DIRECT)
    : false;

  const allButton = (
    <BadgeButton
      key={readAllRole}
      label={props.tr("permissionNameAllLabel")}
      title={getPermissionDescription(readAllRole, props.tr)}
      color={props.permission[readAllRole] ? "success" : "light"}
      className={props.permission[readAllRole] ? "mx-1" : "text-muted mx-1"}
      onClick={() => {
        props.handlePermissionChange(
          props.prefixId + readAllRole,
          !allPermission
        );
      }}
      disabled={props.disabled || allNotOnlyDirectSource}
    />
  );

  if (props.mode === "su")
    return (
      <React.Fragment>
        {allButton}
        {items}
      </React.Fragment>
    );

  return (
    <td className="text-center td-border">
      {allButton}
      {items}
    </td>
  );
};

interface StateProps extends AdminContext {}

interface Props extends Response, Translation, StateProps {
  params: Params;
}

interface State {
  orgValues: Permission;
  initialOrgValues: Permission;
  suValues: SchoolUnitValues;
  initialSuValues: SchoolUnitValues;
  saving: boolean;
}

const AlertBox = (props: {
  tr: Translation["tr"];
  show: boolean;
  type: "AccountOutOfOrg" | "NoSkolidUser";
  noBottomMargin?: boolean;
}) => {
  if (!props.show) return null;
  return (
    <>
      {props.type === "AccountOutOfOrg" ? (
        <div
          className={`alert alert-warning ${
            props.noBottomMargin ? "mb-0" : ""
          }`}
          role="alert"
          style={{ borderRadius: "0" }}
        >
          {props.tr("userPermissionSkolidNotOrgAccount")}
        </div>
      ) : null}

      {props.type === "NoSkolidUser" ? (
        <div
          className={`alert alert-danger ${props.noBottomMargin ? "mb-0" : ""}`}
          role="alert"
          style={{ borderRadius: "0" }}
        >
          {props.tr("userPermissionNoSkolidDescription")}
        </div>
      ) : null}
    </>
  );
};

class UserPermissions extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      orgValues: {},
      initialOrgValues: {},
      suValues: {},
      initialSuValues: {},
      saving: false,
    };
  }

  private mainRef: any = React.createRef();

  public componentDidMount() {
    if (this.props.user && this.props.user.user) {
      this.updateStateFromServer(this.props);
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      (this.props.user.user && !prevProps.user.user) ||
      (this.props.user.user &&
        prevProps.user.user &&
        this.props.user.user.permissions !== prevProps.user.user.permissions)
    ) {
      this.updateStateFromServer(this.props);
    }
  }

  public render() {
    const { tr } = this.props;
    if (this.props.user.loading) {
      return (
        <main className="top content">
          <Loading />
        </main>
      );
    }

    if (!this.props.user.me || !this.props.user.user) {
      return null;
    }

    const { user } = this.props.user;

    const userSource = user.source;

    const pristine = !this.isStateDirty();
    const isEducloudUser = userSource === gqltypes.UserSource.educloud;
    const requestorIsOrgAdmin =
      this.props.user.me.permissions.organisation.roles.some(
        (role) => role.roleId === gqltypes.PermissionType.admin
      );

    const isISTOwnedSkolidAccount = isSkolidIstAccount(
      user.skolidOrganization?.id
    );
    const isSkolidAccountOutOfOrganisationAndIST = isISTOwnedSkolidAccount
      ? false
      : userSource === gqltypes.UserSource.skolid &&
        this.props.user.organisation?.skolidOrgId
      ? user.skolidOrganization?.id !==
        this.props.user.organisation?.skolidOrgId
      : false;

    const permissionTypes = permissionOrder;

    const schoolUnits = this.props.user.user.permissions.schoolUnits;

    const hasOrgReadPermission = formComponentPermissions.some(
      (readPerm) => this.state.orgValues[readPerm] !== undefined
    );

    const saveButton = (
      <button
        className="btn btn-primary float-right"
        disabled={pristine || this.state.saving}
        onClick={this.handleSave}
      >
        {this.state.saving ? tr("saving") : tr("save")}
      </button>
    );

    const ISTContainerInnerButtonProcessing = {
      processingState: this.state.saving,
      processingLabel: tr("saving"),
    } as ISTContainerInnerButtonProcessing;

    const ISTContainerInnerButton = {
      buttonLabel: tr("save"),
      active: pristine,
      processing: ISTContainerInnerButtonProcessing,
      onClick: this.handleSave,
    } as ISTContainerInnerButton;

    return (
      <main ref={this.mainRef}>
        <Prompt
          when={!pristine}
          message={tr("createFormUnsavedChangesWarning")}
        />
        <h1>{tr("userPermissionsTitle")}</h1>
        <p className="col-12 col-md-9 p-0 pb-3">
          {tr("userPermissionsDescription")}
        </p>

        <p>
          <Link to={getUserInfoUrl(user)}>
            {tr("userPermissionUserInfoLink", this.props.user.user.name!)}
          </Link>
        </p>
        <ISTContainer
          header={
            this.props.user.user.name! +
            ", " +
            this.props.user.user.permissions.organisation.displayName!
          }
          button={ISTContainerInnerButton}
        >
          <AlertBox
            tr={tr}
            show={isSkolidAccountOutOfOrganisationAndIST}
            type="AccountOutOfOrg"
            noBottomMargin
          />
          <AlertBox
            tr={tr}
            show={isEducloudUser}
            type="NoSkolidUser"
            noBottomMargin
          />{" "}
          <div style={{ overflowX: "auto", maxHeight: "80vh" }}>
            <h3 className="pl-4 mt-4">
              {tr("userPermissionsTitleOnOrganisation")}
            </h3>
            <table
              className="table mb-content table-no-end-line table-responsive-sm"
              style={{ minWidth: "1080px" }}
            >
              <thead>
                <tr>
                  {permissionTypes.map((type) => (
                    <th
                      key={`th${DELIMITER}${type}`}
                      scope="col"
                      className={`${
                        type === gqltypes.PermissionType.manage_application
                          ? "text-right"
                          : "text-center"
                      } no-upper ${
                        type === gqltypes.PermissionType.admin
                          ? "th-border-bottom"
                          : "th-border"
                      }`}
                      style={
                        type === gqltypes.PermissionType.read ||
                        type === gqltypes.PermissionType.manage_application
                          ? type === gqltypes.PermissionType.read
                            ? { width: "360px" }
                            : { width: "260px" }
                          : { width: "58px" }
                      }
                    >
                      <Tooltip
                        target={`tooltip${DELIMITER}org${DELIMITER}${type}`}
                        content={getPermissionDisplayName(type, tr)}
                        message={getPermissionDescription(type, tr)}
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {permissionTypes.map((type) =>
                    type === gqltypes.PermissionType.read ? (
                      <AggregatedReadEditView
                        key={`org${DELIMITER}${type}`}
                        tr={tr}
                        mode="org"
                        prefixId={`org${DELIMITER}`}
                        permission={this.state.orgValues}
                        handlePermissionChange={this.handleOrgPermissionCheck}
                        disabled={!requestorIsOrgAdmin || isEducloudUser}
                      />
                    ) : (
                      <td
                        key={`org${DELIMITER}${type}`}
                        className={`${
                          type === gqltypes.PermissionType.manage_application
                            ? "text-right"
                            : "text-center"
                        } ${
                          type === gqltypes.PermissionType.admin
                            ? "td-border-bottom"
                            : "td-border"
                        }`}
                      >
                        <Checkbox
                          id={`org${DELIMITER}${type}`}
                          containerClassName="d-inline-block"
                          checked={this.state.orgValues[type] !== undefined}
                          // label={
                          //   this.state.orgValues[type]
                          //     ? this.state.orgValues[type]!.join(" ")
                          //     : ""
                          // }
                          disabled={
                            !requestorIsOrgAdmin ||
                            isEducloudUser ||
                            (type ===
                              gqltypes.PermissionType
                                .write_publication_response &&
                              !hasOrgReadPermission) ||
                            (this.state.orgValues[type] &&
                              this.state.orgValues[type]!.some(
                                (source) => source !== PermissionSource.DIRECT
                              ))
                          }
                          onChange={this.handleOrgPermissionCheck}
                        />
                      </td>
                    )
                  )}
                </tr>
              </tbody>
            </table>

            <h3 className="pl-4 mt-4">{tr("userPermissionsTitleOnUnit")}</h3>
            <Table
              initialOrder="schoolUnitName"
              initialOrderDir="asc"
              thClassName="no-upper"
              headers={[
                {
                  key: "schoolUnitName",
                  element: tr("schoolUnit"),
                  usingSortValue: true,
                  style: { width: "260px" },
                },
              ].concat(
                permissionTypes
                  .filter((type) => !permissionOnOrgOnly[type])
                  .map((type) => {
                    return {
                      key: type,
                      element: (
                        <Tooltip
                          target={`tooltip${DELIMITER}su${DELIMITER}${type}`}
                          content={getPermissionDisplayName(type, tr)}
                          message={getPermissionDescription(type, tr)}
                        />
                      ) as any,
                      usingSortValue: true,
                      style:
                        type === gqltypes.PermissionType.read ||
                        type === gqltypes.PermissionType.manage_application
                          ? type === gqltypes.PermissionType.manage_application
                            ? { width: "260px" }
                            : { width: "360px" }
                          : { width: "58px" },
                      sortArrowOwnLine: true,
                      className: `text-center
                      ${
                        type === gqltypes.PermissionType.admin
                          ? "th-border-bottom"
                          : "th-border"
                      }`,
                    };
                  })
              )}
              rows={schoolUnits.map((su) => {
                const hasSuReadPermission = formComponentPermissions.some(
                  (readPerm) =>
                    this.state.suValues[su.resourceId]?.[readPerm] !== undefined
                );
                return {
                  key: su.resourceId,
                  columns: {
                    schoolUnitName: {
                      content: (
                        <UserNameAndRoles
                          tr={tr}
                          id={su.resourceId}
                          name={su.displayName}
                          roles={su.employmentRoles}
                        />
                      ),
                      sortValue: su.displayName,
                      className: "text-truncate",
                      style: { maxWidth: "260px" },
                    },
                    ...permissionTypes
                      .filter((type) => !permissionOnOrgOnly[type])
                      .reduce((acc, type) => {
                        const checked = this.state.suValues[su.resourceId]
                          ? this.state.suValues[su.resourceId][type] !==
                            undefined
                          : false;
                        acc[type] = {
                          sortValue: checked,
                          className: "text-center",
                          content:
                            type === gqltypes.PermissionType.read ? (
                              <AggregatedReadEditView
                                tr={tr}
                                prefixId={`${su.resourceId}${DELIMITER}`}
                                mode="su"
                                permission={
                                  this.state.suValues[su.resourceId] || {}
                                }
                                handlePermissionChange={
                                  this.handleSUPermissionCheck
                                }
                                disabled={isEducloudUser}
                              />
                            ) : (
                              <Checkbox
                                id={`${su.resourceId}${DELIMITER}${type}`}
                                containerClassName="d-inline-block"
                                // label={
                                //   this.state.suValues[su.resourceId] &&
                                //   this.state.suValues[su.resourceId][type]
                                //     ? this.state.suValues[su.resourceId][type]!.join(
                                //         " "
                                //       )
                                //     : ""
                                // }
                                checked={checked}
                                disabled={
                                  isEducloudUser ||
                                  (type ===
                                    gqltypes.PermissionType
                                      .write_publication_response &&
                                    !hasSuReadPermission) ||
                                  (this.state.suValues[su.resourceId] &&
                                    this.state.suValues[su.resourceId][type] &&
                                    this.state.suValues[su.resourceId][
                                      type
                                    ]!.some(
                                      (source) =>
                                        source !== PermissionSource.DIRECT
                                    ))
                                }
                                onChange={this.handleSUPermissionCheck}
                              />
                            ),
                        };
                        return acc;
                      }, {} as any),
                  },
                };
              })}
            />
          </div>
        </ISTContainer>
      </main>
    );
  }

  private updateStateFromServer = (props: Props) => {
    const ns: Partial<State> = {};

    if (!props.user.user) {
      throw new Error("updateStateFromServer with empty user");
    }

    ns.orgValues = {} as Permission;

    props.user.user.permissions.organisation.roles.forEach((role) => {
      ns.orgValues![role.roleId] = role.sources;
    });
    ns.initialOrgValues = _.cloneDeep(ns.orgValues);

    ns.suValues = {} as SchoolUnitValues;

    props.user.user.permissions.schoolUnits.forEach((su) => {
      ns.suValues![su.resourceId] = {};
      su.roles.forEach((role) => {
        ns.suValues![su.resourceId][role.roleId] = role.sources;
      });
    });

    ns.initialSuValues = _.cloneDeep(ns.suValues);

    this.setState(ns as any);
  };

  private permissionOrgHelper = (
    mode: "add" | "remove",
    ns: State,
    roleId: gqltypes.PermissionType,
    permissionSource: gqltypes.PermissionSource
  ) => {
    const inheritance = permissionInheritance[roleId] || [];
    if (mode === "add") {
      if (!ns.orgValues[roleId]) {
        ns.orgValues[roleId] = [];
      }
      ns.orgValues[roleId]!.push(permissionSource);
      this.permissionAllSUHelper(
        mode,
        ns.suValues,
        roleId,
        PermissionSource.HIERARCHY
      );
    } else if (mode === "remove") {
      if (
        ns.orgValues[roleId] &&
        ns.orgValues[roleId]!.some((source) => source === permissionSource)
      ) {
        if (ns.orgValues[roleId]!.length === 1) {
          delete ns.orgValues[roleId];
        } else {
          ns.orgValues[roleId] = ns.orgValues[roleId]!.filter(
            (source) => source !== permissionSource
          );
        }
        this.permissionAllSUHelper(
          mode,
          ns.suValues,
          roleId,
          PermissionSource.HIERARCHY
        );
      }
    }
    inheritance.forEach((inheritRole) => {
      this.permissionOrgHelper(
        mode,
        ns as any,
        inheritRole,
        gqltypes.PermissionSource.INHERIT
      );
    });
  };

  private permissionSUHelper = (
    mode: "add" | "remove",
    suValues: SchoolUnitValues,
    suid: string,
    roleId: gqltypes.PermissionType,
    permissionSource: PermissionSource
  ) => {
    const inheritance = permissionInheritance[roleId] || [];
    if (mode === "add") {
      if (!suValues[suid]) {
        suValues[suid] = {};
      }
      if (!suValues[suid][roleId]) {
        suValues[suid][roleId] = [permissionSource];
      } else {
        if (suValues[suid][roleId]!.indexOf(permissionSource) === -1) {
          suValues[suid][roleId]!.push(permissionSource);
        }
      }
      inheritance.forEach((inheritRole) => {
        this.permissionSUHelper(
          mode,
          suValues,
          suid,
          inheritRole,
          PermissionSource.INHERIT
        );
      });
    } else if (mode === "remove") {
      if (!suValues[suid][roleId]) {
        return;
      }
      if (
        suValues[suid][roleId]!.some((source) => source === permissionSource)
      ) {
        if (suValues[suid][roleId]!.length === 1) {
          delete suValues[suid][roleId];
          inheritance.forEach((inheritRole) => {
            this.permissionSUHelper(
              mode,
              suValues,
              suid,
              inheritRole,
              PermissionSource.INHERIT
            );
          });
        } else {
          suValues[suid][roleId] = suValues[suid][roleId]!.filter(
            (source) => source !== permissionSource
          );
        }
      }
    }
  };

  private permissionAllSUHelper = (
    mode: "add" | "remove",
    suValues: SchoolUnitValues,
    roleId: gqltypes.PermissionType,
    permissionSource: PermissionSource
  ) => {
    if (!this.props.user.user) {
      throw new Error("permissionAllSUHelper with empty user");
    }
    const suids = this.props.user.user.permissions.schoolUnits.map(
      (su) => su.resourceId
    );

    suids.forEach((suid) => {
      this.permissionSUHelper(mode, suValues, suid, roleId, permissionSource);
    });
  };

  private handleOrgPermissionCheck = (id: string, checked: boolean) => {
    const roleId = id.slice(
      id.indexOf(DELIMITER) + DELIMITER.length
    ) as gqltypes.PermissionType;
    const ns: Partial<State> = {};
    ns.orgValues = _.cloneDeep(this.state.orgValues);
    ns.suValues = _.cloneDeep(this.state.suValues);

    // const inheritance = permissionInheritance[roleId] || [];
    const mode = checked ? "add" : "remove";

    this.permissionOrgHelper(
      mode,
      ns as any,
      roleId,
      gqltypes.PermissionSource.DIRECT
    );

    // Logic to remove write_publication_response when all reads are removed
    const hasRead = formComponentPermissions.some(
      (readPerm) => ns.orgValues?.[readPerm] !== undefined
    );

    if (mode === "remove" && !hasRead) {
      this.permissionOrgHelper(
        "remove",
        ns as any,
        gqltypes.PermissionType.write_publication_response,
        PermissionSource.DIRECT
      );
    }

    this.setState(ns as any);
  };

  private handleSUPermissionCheck = (id: string, checked: boolean) => {
    const index = id.indexOf(DELIMITER);
    const suid = id.slice(0, index);
    const roleId = id.slice(
      index + DELIMITER.length
    ) as gqltypes.PermissionType;
    const ns: Partial<State> = {};
    ns.suValues = _.cloneDeep(this.state.suValues);

    // const inheritance = permissionInheritance[roleId] || [];
    const mode = checked ? "add" : "remove";

    this.permissionSUHelper(
      mode,
      ns.suValues,
      suid,
      roleId,
      PermissionSource.DIRECT
    );

    // Logic to remove write_publication_response when all reads are removed
    const hasRead = formComponentPermissions.some(
      (readPerm) => ns.suValues?.[suid]?.[readPerm] !== undefined
    );

    if (mode === "remove" && !hasRead) {
      this.permissionSUHelper(
        "remove",
        ns.suValues,
        suid,
        gqltypes.PermissionType.write_publication_response,
        PermissionSource.DIRECT
      );
    }

    this.setState(ns as any);
  };

  private isPermissionDirty = (
    perm: Permission,
    initialPerm: Permission | undefined
  ) => {
    if (!initialPerm) return true;

    const permKeys = Object.keys(perm) as gqltypes.PermissionType[];
    const setPermissions = permKeys.filter((permKey) =>
      perm[permKey]!.some((source) => source === PermissionSource.DIRECT)
    );

    const initialpermKeys = Object.keys(
      initialPerm
    ) as gqltypes.PermissionType[];
    const initialSetPermissions = new Set<string>(
      initialpermKeys.filter((permKey) =>
        initialPerm[permKey]!.some(
          (source) => source === PermissionSource.DIRECT
        )
      )
    );

    const sizeDiffers = setPermissions.length !== initialSetPermissions.size;
    const samePermissions = !setPermissions.every((setPerm) =>
      initialSetPermissions.has(setPerm)
    );

    return sizeDiffers || samePermissions;
  };

  private isStateDirty = () => {
    if (
      this.isPermissionDirty(this.state.orgValues, this.state.initialOrgValues)
    ) {
      return true;
    }

    const resourceIds = [
      ...new Set(
        Object.keys(this.state.suValues).concat(
          Object.keys(this.state.initialSuValues)
        )
      ),
    ];
    return resourceIds.some((resourceId) =>
      this.isPermissionDirty(
        this.state.suValues[resourceId],
        this.state.initialSuValues[resourceId]
      )
    );
  };

  private handleSave = async () => {
    const source = getSourceFromShortSource(this.props.params.source!);
    const userid = decodeURIComponent(this.props.params.id!);
    if (source === gqltypes.UserSource.educloud) {
      throw new Error("cannot update permissions on educloud users");
    }
    const orgRoles = (
      Object.keys(this.state.orgValues) as gqltypes.PermissionType[]
    ).filter((roleId) =>
      this.state.orgValues[roleId]?.some(
        (src) => src === PermissionSource.DIRECT
      )
    ) as PermissionType[];
    const permissions: gqltypes.SetUserPermissionInput = {
      userid,
      source,
      permissions: [],
    };
    const resourceIds = [
      ...new Set(
        Object.keys(this.state.suValues).concat(
          Object.keys(this.state.initialSuValues)
        )
      ),
    ];
    resourceIds.forEach((resourceId) => {
      if (
        this.isPermissionDirty(
          this.state.suValues[resourceId],
          this.state.initialSuValues[resourceId]
        )
      ) {
        permissions.permissions.push({
          resourceId,
          resourceTypeId: gqltypes.ResourceType.schoolunit,
          roleIds: (
            Object.keys(
              this.state.suValues[resourceId]
            ) as gqltypes.PermissionType[]
          ).filter((roleId) =>
            this.state.suValues[resourceId]?.[roleId]?.some(
              (src) => src === PermissionSource.DIRECT
            )
          ) as PermissionType[],
        });
      }
    });
    if (
      this.isPermissionDirty(this.state.orgValues, this.state.initialOrgValues)
    ) {
      if (!this.props.adminContext.org) {
        throw new Error("adminContext missing org");
      }
      permissions.permissions.push({
        resourceId: this.props.adminContext.org,
        resourceTypeId: gqltypes.ResourceType.organisation,
        roleIds: orgRoles,
      });
    }
    if (permissions.permissions.length > 0) {
      this.setState({ saving: true });
      try {
        await this.props.setUserPermission({
          variables: {
            input: permissions,
            context: this.props.adminContext,
          },
        });
        // Update state to reflect that the current state is no longer dirty
        this.setState({
          initialOrgValues: _.cloneDeep(this.state.orgValues),
          initialSuValues: _.cloneDeep(this.state.suValues),
        });
      } catch (error) {
        console.error("Error saving permissions:", error);
      } finally {
        this.setState({ saving: false });
      }
    }
  };
}

interface Response {
  user: DataValue<gqltypes.UserWithPermissions> &
    QueryResult<gqltypes.UserWithPermissionsVariables>;
  setUserPermission: MutationFunction<
    gqltypes.SetUserPermission,
    gqltypes.SetUserPermissionVariables
  >;
}

export const fullSubjectPermissionsFragment = gql`
  fragment FullSubjectPermissions on SubjectPermissions {
    id
    organisation {
      resourceId
      displayName
      roles {
        roleId
        sources
      }
    }
    schoolUnits {
      resourceId
      displayName
      employmentRoles
      roles {
        roleId
        sources
      }
    }
  }
`;

const withOrgPermissions = graphql<
  Props,
  gqltypes.UserWithPermissions,
  gqltypes.UserWithPermissionsVariables
>(
  gql`
    query UserWithPermissions($input: UserIdInput!, $context: Context!) {
      user(input: $input) {
        id
        source
        customerId
        idp
        idpSub
        name
        skolidOrganization {
          id
        }
        permissions(context: $context) {
          ...FullSubjectPermissions
        }
      }
      me {
        id
        name
        permissions(context: $context) {
          ...FullSubjectPermissions
        }
      }
      organisation(context: $context) {
        id
        skolidOrgId
      }
    }
    ${fullSubjectPermissionsFragment}
  `,
  {
    name: "user",
    options: (props) => {
      const src = getSourceFromShortSource(props.params.source!);
      const userid = decodeURIComponent(props.params.id!);
      const customerId = props.params.customerId;

      return {
        variables: {
          input: {
            id: userid,
            source: src,
            customerId,
          },
          context: props.adminContext,
        },
      };
    },
  }
);

const withSetUserPermission = graphql<
  Props,
  gqltypes.SetUserPermission,
  gqltypes.SetUserPermissionVariables
>(
  gql`
    mutation SetUserPermission(
      $input: SetUserPermissionInput!
      $context: Context!
    ) {
      setUserPermission(input: $input, context: $context) {
        ...FullSubjectPermissions
      }
    }
    ${fullSubjectPermissionsFragment}
  `,
  {
    name: "setUserPermission",
    options: (props) => ({
      update: (cache, { data }) => {
        if (!data || !data.setUserPermission) {
          return;
        }

        const { setUserPermission } = data;

        // Read the current data from the cache
        const existingData: {
          organisation: {
            permissions: {
              organisation: gqltypes.SubjectPermissionElement[];
              schoolUnits: gqltypes.SchoolUnitPermissions[];
            };
          };
        } | null = cache.readQuery({
          query: ORG_PERMISSION_QUERY,
          variables: { context: props.adminContext },
        });

        if (!existingData) {
          return;
        }

        // Update organisation permissions
        const updatedOrgPermissions = Array.isArray(existingData.organisation.permissions.organisation)
          ? existingData.organisation.permissions.organisation.map(
              (permission) =>
                permission.user && permission.user.id === setUserPermission.id
                  ? {
                      ...permission,
                      ...setUserPermission,
                      __typename: "SubjectPermissionElement",
                    }
                  : permission
            )
          : existingData.organisation.permissions.organisation;

        // Update school unit permissions
        const updatedSchoolUnitPermissions = Array.isArray(existingData.organisation.permissions.schoolUnits)
          ? existingData.organisation.permissions.schoolUnits.map(
              (schoolUnit) => {
                const updatedPermissions = schoolUnit.permissions.map(
                  (permission) =>
                    permission.userId === setUserPermission.id
                      ? {
                          ...permission,
                          ...setUserPermission,
                          __typename: "SchoolUnitPermissions",
                        }
                      : permission
                );
                return { ...schoolUnit, permissions: updatedPermissions };
              }
            )
          : existingData.organisation.permissions.schoolUnits;

        // Create a new object with the updated permissions
        const updatedData = {
          ...existingData,
          organisation: {
            ...existingData.organisation,
            permissions: {
              ...existingData.organisation.permissions,
              organisation: updatedOrgPermissions,
              schoolUnits: updatedSchoolUnitPermissions,
            },
          },
        };

        // Write the updated data back to the cache
        cache.writeQuery({
          query: ORG_PERMISSION_QUERY,
          variables: { context: props.adminContext },
          data: updatedData,
        });
      },
      onError: (error) => {
        console.error("Error setting user permission:", error);
      },
    }),
  }
);

export const UserPermissionsContainer = withAdminContext(
  _.flowRight(
    withRouter,
    withTranslation,
    withOrgPermissions,
    withSetUserPermission
  )(UserPermissions)
);
