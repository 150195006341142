import _ from "lodash";
import React from "react";
import { Translation } from "../../App/reducer";
import { Checkbox } from "../../Common";
import {
  getCombinedIdCustomerId,
  isIdCustomerIdSame,
} from "../../Common/utils";
import * as gqltypes from "../../gqltypes";
import { ClassCheckboxArray } from "./ClassCheckboxArray";

export interface ChangeSelected {
  suid: { id: string; customerId: string };
  sgs: { id: string; customerId: string }[];
}

interface Props {
  tr: Translation["tr"];
  filteredSchoolUnits: gqltypes.SchoolUnits[];
  selectedStudentGroups: {
    [suid: string]: { id: string; customerId: string }[];
  };
  lockedStudentGroups: { [suid: string]: { id: string; customerId: string }[] };
  studentGroupYearFilters: { [sgid: string]: number[] | null };
  lockedStudentGroupYearFilter: { [sgid: string]: number[] | null };

  addSelectedStudentGroups: (sgs: ChangeSelected[]) => void;
  removeSelectedStudentGroups: (sgs: ChangeSelected[]) => void;
  changeStudentGroupYearFilter: (
    changes: { sgid: string; customerId: string; filter: number[] | null }[]
  ) => void;
}

interface State {}

export class SchoolUnitTable extends React.PureComponent<Props, State> {
  public readonly state = {};

  public render() {
    const { tr } = this.props;
    let numTotalFiltered = 0;
    let numSelectedFiltered = 0;
    let numLockedFiltered = 0;
    for (const su of this.props.filteredSchoolUnits) {
      for (const sg of su.classes) {
        numTotalFiltered++;

        const combinedSuId = getCombinedIdCustomerId(su);

        const lockedGroups = this.props.lockedStudentGroups[combinedSuId];
        if (
          lockedGroups &&
          lockedGroups.some((lg) => isIdCustomerIdSame(sg, lg))
        ) {
          numLockedFiltered++;
        }
        if (
          this.props.selectedStudentGroups[combinedSuId] &&
          this.props.selectedStudentGroups[combinedSuId].some((selSg) =>
            isIdCustomerIdSame(selSg, sg)
          )
        ) {
          numSelectedFiltered++;
        }
      }
    }

    if (this.props.filteredSchoolUnits.length === 0) {
      return (
        <div className="alert alert-info m-content">{tr("SutNoMatch")}</div>
      );
    }

    return (
      <table className="table table-sticky-header table-no-end-line table-responsive-sm">
        <thead>
          <tr>
            <th scope="col">
              <Checkbox
                id="allSgCheck"
                label={tr("schoolUnit")}
                checked={
                  numSelectedFiltered + numLockedFiltered === numTotalFiltered
                }
                indeterminate={
                  numSelectedFiltered + numLockedFiltered !==
                    numTotalFiltered &&
                  numSelectedFiltered + numLockedFiltered > 0
                }
                disabled={numLockedFiltered === numTotalFiltered}
                onChange={this.handleCheckAllFiltered}
              />
            </th>
            <th scope="col">{tr("SutColumnShowing")}</th>
            <th scope="col">{tr("SutColumnAddedToPublication")}</th>
            <th scope="col">{tr("SutColumnShowClasses")}</th>
          </tr>
        </thead>
        <tbody>
          {this.props.filteredSchoolUnits.map((su) => (
            <ClassCheckboxArray
              key={getCombinedIdCustomerId(su)}
              tr={tr}
              schoolUnit={su}
              studentGroupYearFilters={this.props.studentGroupYearFilters}
              lockedStudentGroupYearFilter={
                this.props.lockedStudentGroupYearFilter
              }
              selectedLocked={
                this.props.lockedStudentGroups[getCombinedIdCustomerId(su)]
              }
              selected={
                this.props.selectedStudentGroups[getCombinedIdCustomerId(su)]
              }
              addSelectedStudentGroups={(change) => {
                this.props.addSelectedStudentGroups([change]);
              }}
              removeSelectedStudentGroups={(change) => {
                this.props.removeSelectedStudentGroups([change]);
              }}
              changeSgYearFilter={(change) => {
                this.props.changeStudentGroupYearFilter([change]);
              }}
            />
          ))}
        </tbody>
      </table>
    );
  }

  private handleCheckAllFiltered = (id: string, checked: boolean) => {
    const changes: ChangeSelected[] = [];

    this.props.filteredSchoolUnits.forEach((su) => {
      changes.push({
        suid: su,
        sgs: su.classes,
      });
    });

    if (checked) {
      this.props.addSelectedStudentGroups(changes);
    } else {
      this.props.removeSelectedStudentGroups(changes);
    }
  };
}
