import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, {
  lazy,
  memo,
  Suspense,
  useState,
  useMemo,
  useCallback,
} from "react";
import * as gqltypes from "../../gqltypes";
import { getPartsFromCombinedIdCustomerId } from "../../Common/utils";
import { Loading } from "../../Common/components/Loading";
import { useAdminContext, useTranslation } from "../../App/reducer";

const LazySchoolUnitUsersPermissions = lazy(
  () => import("./SchoolUnitUsersPermissions")
);
// GraphQL query to fetch school units and their permissions within a given context
export const SCHOOL_UNITS_QUERY = gql`
  query SchoolUnits($context: Context!) {
    organisation(context: $context) {
      id
      skolidOrgId
      permissions {
        schoolUnits {
          resourceId
          schoolUnit {
            id
            organisationCode
            schoolUnitCode
          }
          displayName
          permissions {
            employmentRoles
            displayName
            source
            userId
            client {
              id
              clientId
              name
            }
            roles {
              roleId
              sources
            }
          }
        }
      }
    }
  }
`;

export interface SchoolUnitPermission {
  employmentRoles: gqltypes.EmploymentRole[];
  displayName: string | null;
  userId: string | null;
  user?: gqltypes.OrganisationPermissions_organisation_permissions_organisation_user | null;
  client: gqltypes.OrganisationPermissions_organisation_permissions_organisation_client | null;
  roles: gqltypes.OrganisationPermissions_organisation_permissions_organisation_user[];
}

interface SchoolUnitPermissionsProps {
  suNameFilter: RegExp;
  personNameFilter: RegExp;
  employmentRoleFilter: EmploymentRole[];
}

interface EmploymentRole {
  value: string;
  label: string;
}

export interface SchoolUnit {
  permissions: SchoolUnitPermission[];
  educloudUserIds: string[];
  skolidUserIds: string[];
  __typename: "SchoolUnitPermissions";
  resourceId: string;
  schoolUnit: gqltypes.SchoolUnit;
  displayName: string;
}

// Maps the school unit data to a more usable format and filters permissions by person name
const mapSchoolUnit = (
  su: gqltypes.SchoolUnitPermissions,
  personNameFilter: RegExp
) => {
  const educloudUserIds: string[] = [];
  const skolidUserIds: string[] = [];

  const permissions = su.permissions.map(
    (permission: gqltypes.SubjectPermissionElement) => {
      if (permission.userId && permission.source) {
        switch (permission.source) {
          case gqltypes.UserSource.educloud:
            educloudUserIds.push(permission.userId);
            break;
          case gqltypes.UserSource.skolid:
            skolidUserIds.push(permission.userId);
            break;
        }
      }
      return {
        ...permission,
        displayName: permission.displayName ?? null,
      };
    }
  );

  return {
    ...su,
    permissions: permissions
      .filter((perm: gqltypes.SubjectPermissionElement) =>
        personNameFilter.test(perm.displayName || "")
      )
      .sort((a, b) => (a.displayName || "").localeCompare(b.displayName || "")),
    educloudUserIds,
    skolidUserIds,
  };
};

// Filters permissions by selected employment roles
const filterByEmploymentRoles = (
  permissions: SchoolUnitPermission[],
  selectedEmploymentRoles: string[]
): boolean => {
  if (selectedEmploymentRoles.length === 0) return true;

  const employmentRoles: string[] = permissions.reduce<string[]>(
    (roles, su) => {
      if (su.employmentRoles.length > 0) {
        roles.push(...su.employmentRoles);
      }
      return roles;
    },
    []
  );

  const filteredRoles = employmentRoles.filter((role) =>
    selectedEmploymentRoles.includes(role)
  );

  return filteredRoles.length > 0;
};

const SchoolUnitPermissions: React.FC<SchoolUnitPermissionsProps> = ({
  suNameFilter,
  personNameFilter,
  employmentRoleFilter,
}) => {
  const context: gqltypes.Context = useAdminContext(); // Get the context from the admin context
  const { tr } = useTranslation();
  const { loading, error, data } = useQuery(SCHOOL_UNITS_QUERY, {
    variables: { context },
    fetchPolicy: "cache-and-network",
  });

  const [expandAll, setExpandAll] = useState(false);
  const [expandedSchoolUnits, setExpandedSchoolUnits] = useState<{
    [key: string]: boolean;
  }>({});
  const [page, setPage] = useState(1);
  const itemsPerPage = 15;

  // Toggles the expanded state of a school unit
  const toggleSchoolUnitExpanded = useCallback((resourceId: string) => {
    setExpandedSchoolUnits((prevState) => ({
      ...prevState,
      [resourceId]: !prevState[resourceId],
    }));
  }, []);

  // Memoizes the selected employment roles
  const selectedEmploymentRoles: string[] = useMemo(
    () => employmentRoleFilter.map((role: EmploymentRole) => role.value),
    [employmentRoleFilter]
  );

  // Filters and sorts the school units based on the provided filters
  const filteredSchoolUnits: SchoolUnit[] = useMemo(() => {
    if (!data) return [];
    return data.organisation.permissions.schoolUnits
      .filter(
        (su: SchoolUnitPermission) =>
          su.displayName && suNameFilter.test(su.displayName)
      )
      .map((su: gqltypes.SchoolUnitPermissions) =>
        mapSchoolUnit(su, personNameFilter)
      )
      .filter((su: ReturnType<typeof mapSchoolUnit>) => su.permissions.length)
      .filter((su: SchoolUnit) =>
        filterByEmploymentRoles(su.permissions, selectedEmploymentRoles)
      )
      .sort((a: SchoolUnitPermission, b: SchoolUnitPermission) => {
        const displayNameA = a.displayName || "";
        const displayNameB = b.displayName || "";
        return displayNameA.localeCompare(displayNameB);
      });
  }, [data, suNameFilter, personNameFilter, selectedEmploymentRoles]);

  // Paginates the filtered school units
  const paginatedSchoolUnits: SchoolUnit[] = useMemo(() => {
    return filteredSchoolUnits.slice(0, page * itemsPerPage);
  }, [filteredSchoolUnits, page]);

  if (loading) {
    return <Loading className="w-100" />;
  }
  if (error) {
    return (
      <div className="alert alert-warning m-content">
        {tr("failedToFetchData")}
      </div>
    );
  }

  return (
    <>
      {paginatedSchoolUnits.map((suPerm: SchoolUnit, i: number) => {
        const expanded: boolean =
          expandAll || expandedSchoolUnits[suPerm.resourceId];

        const { customerId } = getPartsFromCombinedIdCustomerId(
          suPerm.resourceId
        );

        const cardStyle = expandedSchoolUnits[suPerm.resourceId]
          ? {
              background: "var(--blue-xx-light)",
              borderTop: "1px solid var(--primary)",
            }
          : {
              background: "var(--light)",
              borderTop: "1px solid var(--primary)",
            };

        return (
          <div key={suPerm.resourceId} style={cardStyle}>
            <div
              className="d-flex align-items-center clickable"
              style={{ minHeight: "45px" }}
              onClick={() => toggleSchoolUnitExpanded(suPerm.resourceId)}
            >
              <h4
                style={{ fontWeight: expanded ? "bold" : "normal" }}
                className="px-content pt-2 pb-2 clickable m-0"
              >
                {suPerm.displayName}
                {suPerm.schoolUnit.organisationCode
                  ? ` (${suPerm.schoolUnit.organisationCode})`
                  : ""}{" "}
                <i className={`fas fa-caret-${expanded ? "up" : "down"}`} />
              </h4>
            </div>

            {expanded && (
              <Suspense fallback={<Loading className="w-100" />}>
                <LazySchoolUnitUsersPermissions
                  selectedEmploymentRoles={selectedEmploymentRoles}
                  personNameFilter={personNameFilter}
                  suPerm={suPerm}
                  educloudUserIds={suPerm.educloudUserIds}
                  skolidUserIds={suPerm.skolidUserIds}
                  customerId={customerId}
                  schoolUnitId={suPerm.schoolUnit.id}
                  subjectPerm={suPerm.permissions}
                />
              </Suspense>
            )}
          </div>
        );
      })}
      {filteredSchoolUnits.length > paginatedSchoolUnits.length && (
        <div
          className="d-flex align-items-center justify-content-center clickable"
          style={{ minHeight: "45px" }}
          onClick={() => setPage((prevPage) => prevPage + 1)}
        >
          <h4 className="px-content pt-2 pb-2 clickable m-0 text-center">
            {tr(
              "schoolUnitShowMoreUnits",
              paginatedSchoolUnits.length,
              filteredSchoolUnits.length
            )}
          </h4>
        </div>
      )}
    </>
  );
};

export default memo(SchoolUnitPermissions);
